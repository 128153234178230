import moment from "moment";
import { getDateWisePaymentRealizationRecord } from "../../../store/actions/accountsAction";

export const dateWisePaymentRealizationApiCall = (
  dispatch,
  startDate,
  endDate,
  paymentMethod,
  shopIds,
  dateType
) => {
  const dataPost = {
    startDate: startDate ? startDate : "",
    endDate: endDate ? endDate : "",
    paymentMethodId: paymentMethod ? paymentMethod : 0,
    shopId: shopIds ? shopIds : 0,
    dateType: dateType ? dateType : 1
  }
  dispatch(
    getDateWisePaymentRealizationRecord(dataPost)
  );
};

export const dateWisePaymentRealizationReportExcel = (dataFromAPI) => {
  let formattedData = [];
  dataFromAPI &&
    dataFromAPI.map((data, i) => {
      let singleData = {
        "MP Invoice": data?.invoiceNo
          ? data?.invoiceNo.toString()
          : "-",
        "POS Invoice": data?.posInvoiceNo ? data?.posInvoiceNo.toString() : "-",
        "Invoice/Order Date": data?.orderDate
          ? moment(data?.orderDate).format("DD-MMMM-YYYY")
          : "-",
        "Vendor Name": data?.shopName ? data?.shopName.toString() : "-",
        "Payment Channel": data?.paymentMethod ? data?.paymentMethod.toString() : "-",
        "Delivery Status": data?.deliveryStatus ? data?.deliveryStatus.toString() : "-",
        "Delivery Date": data?.deliveryDate
          ? moment(data?.deliveryDate).format("DD-MMMM-YYYY")
          : "-",
        "Invoice Amount": data?.invoiceAmount ? parseFloat(data?.invoiceAmount) : 0,
        "Paid Amount": data?.paidAmount ? parseFloat(data?.paidAmount) : 0,
        "Remarks": data?.remarks ? data?.remarks.toString() : "-",
        "Shipping Charge": data?.shippingCharge ? parseFloat(data?.shippingCharge) : 0,
        "Transaction No.": data?.tranNo ? data?.tranNo.toString() : "-",
        "Transaction Date": data?.tranDate
          ? moment(data?.tranDate).format("DD-MMMM-YYYY")
          : "-",
      };
      formattedData.push(Object.assign({}, singleData));
    });
  return formattedData;
};
