/* eslint-disable react-hooks/exhaustive-deps */
import html2pdf from "html2pdf.js";
import moment from "moment";
import React, { useEffect } from "react";
import convertNumberToWords from "../../../utils/Functions/convertNumberToWords";
import "../css/BankAdvisoryGenerateView.css";
import logo from "./../../../assets/images/Report_Logo.jpg";

const ProductsList = [
  {
    productType: "Wallet",
    NumberOfOrder: 4,
    SalesAmount: 4000,
    DiscountAmount: 0,
    VoucherAmount: 0,
    BurnAmount: 0,
    CommissionAmount: 700,
    NetPayableAmount: 3608,
  },
  {
    productType: "Phone",
    NumberOfOrder: 3,
    SalesAmount: 357,
    DiscountAmount: 0,
    VoucherAmount: 0,
    BurnAmount: 0,
    CommissionAmount: 49,
    NetPayableAmount: 260,
  },
];

const totalData = {
  NumberOfOrder: 7,
  SalesAmount: 4357,
  DiscountAmount: 0,
  VoucherAmount: 0,
  BurnAmount: 0,
  CommissionAmount: 749,
  NetPayableAmount: 2824.21,
};

const BankAdvisoryGenerateView = ({ format, setShowQuickView }) => {
  const convertToPDF = () => {
    setShowQuickView(true);
    const element = document.getElementById("bank_advisory__generate_items");

    // Get the current date and time
    const currentDateTime = new Date();
    const formattedDate = currentDateTime.toLocaleDateString();
    const formattedTime = currentDateTime.toLocaleTimeString();

    html2pdf()
      .set({
        html2canvas: {
          scale: 5,
        },
        margin: [10, 10, 18, 10],
      })
      .from(element)
      .toContainer()
      .toCanvas()
      .toImg()
      .toPdf()
      .get("pdf")
      .then((pdf) => {
        var totalPages = pdf.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(11);
          pdf.setTextColor(100);
          pdf.text(
            "Page " + i + " of " + totalPages,
            pdf.internal.pageSize.getWidth() / 1.18,
            pdf.internal.pageSize.getHeight() / 1.02
          );
          // pdf.text(
          //   "Note: It's a computer generated report, no signature is required. ",
          //   pdf.internal.pageSize.getWidth() / 17,
          //   pdf.internal.pageSize.getHeight() / 1.02
          // );
          //   if (i === 1) {
          //     pdf.setFontSize(8);
          //     pdf.text(
          //       `Print Date: ${moment(formattedDate).format("DD-MMM-YYYY")}`,
          //       pdf.internal.pageSize.getWidth() / 1.25,
          //       33
          //     );
          //     pdf.text(
          //       `Print Time: ${formattedTime}`,
          //       pdf.internal.pageSize.getWidth() / 1.255,
          //       37
          //     );
          //   }
        }
      })
      // .save()
      .output("dataurlstring")
      .then((pdfDataUrl) => {
        const newTab = window.open();
        newTab.document.body.innerHTML = `<embed src="${pdfDataUrl}" width="100%" height="100%" type="application/pdf">`;
      });
  };

  useEffect(() => {
    if (format === 2) {
      convertToPDF();
      setShowQuickView(false);
    }
  }, [format]);

  return (
    <>
      <div className="bank_advisory__generate_buttons_container">
        <button onClick={convertToPDF} className="btn btn-primary">
          Convert to PDF
        </button>
      </div>

      <div
        className="bank_advisory__generate_container"
        id="bank_advisory__generate_items"
      >
        <div className="bank_advisory__generate_header">
          <div style={{ width: "15%" }}>
            <img
              style={{ width: "100%", marginLeft: "10px", marginTop: "20px" }}
              src={logo}
              alt="logo"
            />
          </div>

          <div style={{ width: "70%" }}>
            <div className="bank_advisory__generate_header_text1">
              SaRa Lifestyle Ltd.
            </div>
            <div className="bank_advisory__generate_header_text2">
              Address: Plot No # Shilpa/01, Section # 07, Milk Vita Road, Mirpur
              PS,
            </div>
            <div className="bank_advisory__generate_header_text2">
              Dhaka-1216, Bangladesh.
            </div>
            <div className="bank_advisory__generate_header_text3">
              BIN: 001168308-0401
            </div>
            <div className="bank_advisory__generate_header_text3">
              Email:{" "}
              <a
                href={`mailto:accounts@saralifestyle.com.bd`}
                style={{ color: "#000", borderBottom: "1px solid #000" }}
              >
                accounts@saralifestyle.com.bd
              </a>
            </div>
            <div className="bank_advisory__generate_header_box">
              <div className="bank_advisory__generate_header_box_item">
                Payment Advice of Marketplace
              </div>
            </div>
          </div>
          <div style={{ width: "15%" }}></div>
        </div>

        <div className="bank_advisory__generate_header_data">
          <div className="bank_advisory__generate_header_data_row">
            <div
              style={{ width: "22%" }}
              className="bank_advisory__generate_header_data_text1"
            >
              Reference No
            </div>
            <div
              style={{ width: "2%" }}
              className="bank_advisory__generate_header_data_separator"
            >
              {" "}
              :
            </div>
            <div
              style={{ width: "76%" }}
              className="bank_advisory__generate_header_data_text2"
            >
              SaRa_MP/Urbaland/2023/December/000002
            </div>
          </div>
          <div className="bank_advisory__generate_header_data_row">
            <div
              style={{ width: "22%" }}
              className="bank_advisory__generate_header_data_text1"
            >
              Payment Advice Date
            </div>
            <div
              style={{ width: "2%" }}
              className="bank_advisory__generate_header_data_separator"
            >
              {" "}
              :
            </div>
            <div
              style={{ width: "76%" }}
              className="bank_advisory__generate_header_data_text2"
            >
              {moment("2023-12-31").format("D-MMM-YYYY")}
            </div>
          </div>
          <div className="bank_advisory__generate_header_data_row">
            <div
              style={{ width: "22%" }}
              className="bank_advisory__generate_header_data_text1"
            >
              Period
            </div>
            <div
              style={{ width: "2%" }}
              className="bank_advisory__generate_header_data_separator"
            >
              {" "}
              :
            </div>
            <div
              style={{ width: "76%" }}
              className="bank_advisory__generate_header_data_text2"
            >
              {moment("2024-01-01").format("D-MMM-YYYY")} to{" "}
              {moment("2024-12-31").format("D-MMM-YYYY")}
            </div>
          </div>

          <div className="bank_advisory__generate_Vendor_Information_header">
            <p>Vendor Information</p>
          </div>

          <div className="bank_advisory__generate_Vendor_Information_detail">
            <div
              style={{ width: "50%" }}
              className="bank_advisory__generate_Vendor_Information_detail_left"
            >
              <div className="bank_advisory__generate_Vendor_Information_detail_left_row">
                <div
                  style={{ width: "44%" }}
                  className="bank_advisory__generate_header_data_text1"
                >
                  Vendor Name
                </div>
                <div
                  style={{ width: "5%" }}
                  className="bank_advisory__generate_header_data_separator"
                >
                  {" "}
                  :
                </div>
                <div
                  style={{ width: "51%" }}
                  className="bank_advisory__generate_header_data_text2"
                >
                  URBALAND
                </div>
              </div>
              <div className="bank_advisory__generate_Vendor_Information_detail_left_row">
                <div
                  style={{ width: "44%" }}
                  className="bank_advisory__generate_header_data_text1"
                >
                  Brand Name
                </div>
                <div
                  style={{ width: "5%" }}
                  className="bank_advisory__generate_header_data_separator"
                >
                  {" "}
                  :
                </div>
                <div
                  style={{ width: "51%" }}
                  className="bank_advisory__generate_header_data_text2"
                >
                  N/A
                </div>
              </div>
              <div className="bank_advisory__generate_Vendor_Information_detail_left_row">
                <div
                  style={{ width: "44%" }}
                  className="bank_advisory__generate_header_data_text1"
                >
                  Vendor Address
                </div>
                <div
                  style={{ width: "5%" }}
                  className="bank_advisory__generate_header_data_separator"
                >
                  {" "}
                  :
                </div>
                <div
                  style={{ width: "51%" }}
                  className="bank_advisory__generate_header_data_text5"
                >
                  Shop 25, Lauren Vista shopping Complex, 169, Dr.
                  Kudrat-E-Khuda Road, Dhaka 1205; Shahbagh PS; Dhaka-1205;
                  Bangladesh
                </div>
              </div>
              <div
                className="bank_advisory__generate_Vendor_Information_detail_left_row"
                style={{ marginTop: "5px" }}
              >
                <div
                  style={{ width: "44%" }}
                  className="bank_advisory__generate_header_data_text1"
                >
                  BIN
                </div>
                <div
                  style={{ width: "5%" }}
                  className="bank_advisory__generate_header_data_separator"
                >
                  {" "}
                  :
                </div>
                <div
                  style={{ width: "51%" }}
                  className="bank_advisory__generate_header_data_text2"
                >
                  005435458-0201
                </div>
              </div>
              <div className="bank_advisory__generate_Vendor_Information_detail_left_row">
                <div
                  style={{ width: "44%" }}
                  className="bank_advisory__generate_header_data_text1"
                >
                  TIN
                </div>
                <div
                  style={{ width: "5%" }}
                  className="bank_advisory__generate_header_data_separator"
                >
                  {" "}
                  :
                </div>
                <div
                  style={{ width: "51%" }}
                  className="bank_advisory__generate_header_data_text2"
                >
                  210135545690
                </div>
              </div>
              <div className="bank_advisory__generate_Vendor_Information_detail_left_row">
                <div
                  style={{ width: "44%" }}
                  className="bank_advisory__generate_header_data_text1"
                >
                  Trade License Number
                </div>
                <div
                  style={{ width: "5%" }}
                  className="bank_advisory__generate_header_data_separator"
                >
                  {" "}
                  :
                </div>
                <div
                  style={{ width: "51%" }}
                  className="bank_advisory__generate_header_data_text2"
                >
                  TRAD/DSCC/034355/2022
                </div>
              </div>
              <div className="bank_advisory__generate_Vendor_Information_detail_left_row">
                <div
                  style={{ width: "44%" }}
                  className="bank_advisory__generate_header_data_text1"
                >
                  Contact Person Name
                </div>
                <div
                  style={{ width: "5%" }}
                  className="bank_advisory__generate_header_data_separator"
                >
                  {" "}
                  :
                </div>
                <div
                  style={{ width: "51%" }}
                  className="bank_advisory__generate_header_data_text2"
                >
                  Mr. Eamin
                </div>
              </div>
              <div className="bank_advisory__generate_Vendor_Information_detail_left_row">
                <div
                  style={{ width: "44%" }}
                  className="bank_advisory__generate_header_data_text1"
                >
                  Contact Person Number
                </div>
                <div
                  style={{ width: "5%" }}
                  className="bank_advisory__generate_header_data_separator"
                >
                  {" "}
                  :
                </div>
                <div
                  style={{ width: "51%" }}
                  className="bank_advisory__generate_header_data_text2"
                >
                  8801670-854482
                </div>
              </div>
              <div
                style={{ marginBottom: "3px" }}
                className="bank_advisory__generate_Vendor_Information_detail_left_row"
              >
                <div
                  style={{ width: "44%" }}
                  className="bank_advisory__generate_header_data_text1"
                >
                  Email
                </div>
                <div
                  style={{ width: "5%" }}
                  className="bank_advisory__generate_header_data_separator"
                >
                  {" "}
                  :
                </div>
                <div
                  style={{ width: "51%" }}
                  className="bank_advisory__generate_header_data_text2"
                >
                  <a
                    href={`mailto:urbalandbd@gmail.com`}
                    style={{ color: "#000", borderBottom: "1px solid #000" }}
                  >
                    urbalandbd@gmail.com
                  </a>
                </div>
              </div>
            </div>
            <div
              style={{ width: "50%" }}
              className="bank_advisory__generate_Vendor_Information_detail_right"
            >
              <div className="bank_advisory__generate_Vendor_Information_detail_right_Header">
                <span
                  style={{
                    padding: "0px 2px",
                    color: "#000",
                    borderBottom: "1px solid #000",
                  }}
                >
                  Bank Details
                </span>
              </div>
              <div className="bank_advisory__generate_Vendor_Information_detail_right_row">
                <div
                  style={{ width: "44%" }}
                  className="bank_advisory__generate_header_data_text1"
                >
                  Account Name
                </div>
                <div
                  style={{ width: "5%" }}
                  className="bank_advisory__generate_header_data_separator"
                >
                  {" "}
                  :
                </div>
                <div
                  style={{ width: "51%" }}
                  className="bank_advisory__generate_header_data_text2"
                >
                  Urbaland
                </div>
              </div>
              <div
                className="bank_advisory__generate_Vendor_Information_detail_right_row"
                style={{ margin: "10px 0px" }}
              >
                <div
                  style={{ width: "44%" }}
                  className="bank_advisory__generate_header_data_text1"
                >
                  Account Number
                </div>
                <div
                  style={{ width: "5%" }}
                  className="bank_advisory__generate_header_data_separator"
                >
                  {" "}
                  :
                </div>
                <div
                  style={{ width: "51%" }}
                  className="bank_advisory__generate_header_data_text2"
                >
                  1223806877001
                </div>
              </div>
              <div className="bank_advisory__generate_Vendor_Information_detail_right_row">
                <div
                  style={{ width: "44%" }}
                  className="bank_advisory__generate_header_data_text1"
                >
                  Bank
                </div>
                <div
                  style={{ width: "5%" }}
                  className="bank_advisory__generate_header_data_separator"
                >
                  {" "}
                  :
                </div>
                <div
                  style={{ width: "51%" }}
                  className="bank_advisory__generate_header_data_text2"
                >
                  The City Bank Ltd.
                </div>
              </div>
              <div className="bank_advisory__generate_Vendor_Information_detail_right_row">
                <div
                  style={{ width: "44%" }}
                  className="bank_advisory__generate_header_data_text1"
                >
                  Branch
                </div>
                <div
                  style={{ width: "5%" }}
                  className="bank_advisory__generate_header_data_separator"
                >
                  {" "}
                  :
                </div>
                <div
                  style={{ width: "51%" }}
                  className="bank_advisory__generate_header_data_text2"
                >
                  New market Branch
                </div>
              </div>
              <div className="bank_advisory__generate_Vendor_Information_detail_right_row">
                <div
                  style={{ width: "44%" }}
                  className="bank_advisory__generate_header_data_text1"
                >
                  Routing Number
                </div>
                <div
                  style={{ width: "5%" }}
                  className="bank_advisory__generate_header_data_separator"
                >
                  {" "}
                  :
                </div>
                <div
                  style={{ width: "51%" }}
                  className="bank_advisory__generate_header_data_text2"
                >
                  225263527
                </div>
              </div>
              <div className="bank_advisory__generate_Vendor_Information_detail_right_row">
                <div
                  style={{ width: "44%" }}
                  className="bank_advisory__generate_header_data_text1"
                >
                  SWIFT Code
                </div>
                <div
                  style={{ width: "5%" }}
                  className="bank_advisory__generate_header_data_separator"
                >
                  {" "}
                  :
                </div>
                <div
                  style={{ width: "51%" }}
                  className="bank_advisory__generate_header_data_text2"
                ></div>
              </div>
            </div>
          </div>

          <div>
            <div className="bank_advisory__generate_Sales_Information_header">
              <p>Sales Information</p>
            </div>
            <div className="bank_advisory__generate_Sales_Information_table_header_data_row">
              <div
                style={{
                  width: "11%",
                  borderRight: "1px solid #000",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: "1px",
                }}
                className="bank_advisory__generate_header_data_text1"
              >
                SL. No.
              </div>
              <div
                style={{
                  width: "12%",
                  borderRight: "1px solid #000",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: "1px",
                }}
                className="bank_advisory__generate_header_data_text1"
              >
                Product Type/Category
              </div>
              <div
                style={{
                  width: "11%",
                  borderRight: "1px solid #000",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: "1px",
                }}
                className="bank_advisory__generate_header_data_text1"
              >
                Number of Order
              </div>
              <div
                style={{
                  width: "11%",
                  borderRight: "1px solid #000",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: "1px",
                }}
                className="bank_advisory__generate_header_data_text1"
              >
                Sales Amount
              </div>
              <div
                style={{
                  width: "11%",
                  borderRight: "1px solid #000",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: "1px",
                }}
                className="bank_advisory__generate_header_data_text1"
              >
                Discount Amount
              </div>
              <div
                style={{
                  width: "11%",
                  borderRight: "1px solid #000",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: "1px",
                }}
                className="bank_advisory__generate_header_data_text1"
              >
                Voucher Amount
              </div>
              <div
                style={{
                  width: "11%",
                  borderRight: "1px solid #000",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: "1px",
                }}
                className="bank_advisory__generate_header_data_text1"
              >
                Burn Amount
              </div>
              <div
                style={{
                  width: "11%",
                  borderRight: "1px solid #000",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: "1px",
                }}
                className="bank_advisory__generate_header_data_text1"
              >
                Commission Amount
              </div>
              <div
                style={{
                  width: "11%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: "1px",
                }}
                className="bank_advisory__generate_header_data_text1"
              >
                Net Payable Amount
              </div>
            </div>
            {ProductsList.map((data, index) => (
              <div className="bank_advisory__generate_Sales_Information_table_products_data_row">
                <div
                  style={{
                    width: "11%",
                    borderRight: "1px solid #000",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    padding: "1px",
                  }}
                  className="bank_advisory__generate_header_data_text1"
                >
                  {index + 1}
                </div>
                <div
                  style={{
                    width: "12%",
                    borderRight: "1px solid #000",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    padding: "1px",
                  }}
                  className="bank_advisory__generate_header_data_text1"
                >
                  {data.productType}
                </div>
                <div
                  style={{
                    width: "11%",
                    borderRight: "1px solid #000",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    padding: "1px",
                  }}
                  className="bank_advisory__generate_header_data_text1"
                >
                  {data.NumberOfOrder}
                </div>
                <div
                  style={{
                    width: "11%",
                    borderRight: "1px solid #000",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    padding: "1px",
                  }}
                  className="bank_advisory__generate_header_data_text1"
                >
                  {data.SalesAmount.toFixed(2).replace(
                    /\d(?=(\d{3})+\.)/g,
                    "$&,"
                  )}
                </div>
                <div
                  style={{
                    width: "11%",
                    borderRight: "1px solid #000",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    padding: "1px",
                  }}
                  className="bank_advisory__generate_header_data_text1"
                >
                  {data.DiscountAmount.toFixed(2).replace(
                    /\d(?=(\d{3})+\.)/g,
                    "$&,"
                  )}
                </div>
                <div
                  style={{
                    width: "11%",
                    borderRight: "1px solid #000",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    padding: "1px",
                  }}
                  className="bank_advisory__generate_header_data_text1"
                >
                  {data.VoucherAmount.toFixed(2).replace(
                    /\d(?=(\d{3})+\.)/g,
                    "$&,"
                  )}
                </div>
                <div
                  style={{
                    width: "11%",
                    borderRight: "1px solid #000",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    padding: "1px",
                  }}
                  className="bank_advisory__generate_header_data_text1"
                >
                  {data.BurnAmount.toFixed(2).replace(
                    /\d(?=(\d{3})+\.)/g,
                    "$&,"
                  )}
                </div>
                <div
                  style={{
                    width: "11%",
                    borderRight: "1px solid #000",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    padding: "1px",
                  }}
                  className="bank_advisory__generate_header_data_text1"
                >
                  {data.CommissionAmount.toFixed(2).replace(
                    /\d(?=(\d{3})+\.)/g,
                    "$&,"
                  )}
                </div>
                <div
                  style={{
                    width: "11%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    padding: "1px",
                  }}
                  className="bank_advisory__generate_header_data_text1"
                >
                  {data.NetPayableAmount.toFixed(2).replace(
                    /\d(?=(\d{3})+\.)/g,
                    "$&,"
                  )}
                </div>
              </div>
            ))}

            <div className="bank_advisory__generate_Sales_Information_table_Total_data_row">
              <div
                style={{
                  width: "23%",
                  borderRight: "1px solid #000",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: "1px",
                }}
                className="bank_advisory__generate_header_data_text1"
              >
                Grand Total =
              </div>
              <div
                style={{
                  width: "11%",
                  borderRight: "1px solid #000",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: "1px",
                }}
                className="bank_advisory__generate_header_data_text1"
              >
                {totalData.NumberOfOrder}
              </div>
              <div
                style={{
                  width: "11%",
                  borderRight: "1px solid #000",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: "1px",
                }}
                className="bank_advisory__generate_header_data_text1"
              >
                {totalData.SalesAmount.toFixed(2).replace(
                  /\d(?=(\d{3})+\.)/g,
                  "$&,"
                )}
              </div>
              <div
                style={{
                  width: "11%",
                  borderRight: "1px solid #000",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: "1px",
                }}
                className="bank_advisory__generate_header_data_text1"
              >
                {totalData.DiscountAmount.toFixed(2).replace(
                  /\d(?=(\d{3})+\.)/g,
                  "$&,"
                )}
              </div>
              <div
                style={{
                  width: "11%",
                  borderRight: "1px solid #000",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: "1px",
                }}
                className="bank_advisory__generate_header_data_text1"
              >
                {totalData.VoucherAmount.toFixed(2).replace(
                  /\d(?=(\d{3})+\.)/g,
                  "$&,"
                )}
              </div>
              <div
                style={{
                  width: "11%",
                  borderRight: "1px solid #000",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: "1px",
                }}
                className="bank_advisory__generate_header_data_text1"
              >
                {totalData.BurnAmount.toFixed(2).replace(
                  /\d(?=(\d{3})+\.)/g,
                  "$&,"
                )}
              </div>
              <div
                style={{
                  width: "11%",
                  borderRight: "1px solid #000",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: "1px",
                }}
                className="bank_advisory__generate_header_data_text1"
              >
                {totalData.CommissionAmount.toFixed(2).replace(
                  /\d(?=(\d{3})+\.)/g,
                  "$&,"
                )}
              </div>
              <div
                style={{
                  width: "11%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: "1px",
                }}
                className="bank_advisory__generate_header_data_text1"
              >
                {totalData.NetPayableAmount.toFixed(2).replace(
                  /\d(?=(\d{3})+\.)/g,
                  "$&,"
                )}
              </div>
            </div>
            <div className="bank_advisory__generate_Sales_Information_Total_In_Word">
              <p>
                In Words (Taka):{" "}
                {convertNumberToWords(totalData.NetPayableAmount)}
              </p>
            </div>

            <div className="bank_advisory__generate_Sales_Information_Signature_container">
              <div
                style={{ width: "25%", marginRight: "3vw" }}
                className="bank_advisory__generate_signature_1"
              >
                Prepared by: <br /> Key Account Manager
              </div>
              <div
                style={{ width: "25%", marginRight: "3vw" }}
                className="bank_advisory__generate_signature_1"
              >
                Checked by: <br /> Marketplace Operation
              </div>
              <div
                style={{ width: "25%", marginRight: "3vw" }}
                className="bank_advisory__generate_signature_1"
              >
                Checked by: <br /> Accounts & Finance
              </div>
              <div
                style={{ width: "25%" }}
                className="bank_advisory__generate_signature_1"
              >
                Approved by: <br /> Head of E-Commerce
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BankAdvisoryGenerateView;
