/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import React, {useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import * as Icon from "react-feather";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import * as yup from "yup";

const CreateAdsSchema = yup.object().shape({
    countryName: yup.string().required(),
    phoneCode: yup.string().required(),
    currency: yup.string().required(),
    currencyName: yup.string().required(),
    currencySymbol: yup.string().required(),
    region: yup.string().required(),
    subregion: yup.string().required(),
});

const CreateCountryContainer = () => {
   
    const [countryName, setCountryName] = useState('');
    const [phoneCode, setphoneCode] = useState('');
    const [currency, setCurrency] = useState('');
    const [currencyName, setCurrencyName] = useState('');
    const [currencySymbol, setCurrencySymbol] = useState('');
    const [region, setRegion] = useState('');
    const [subregion, setSubRegion] = useState('');
    const [isActive, setIsActive]= useState(true);

    const isSellerPanel = false;

    // const dispatch = useDispatch();
    // const history = useHistory();

    const handleChange = (e) => {
        e.preventDefault()
        const { target } = e
        switch (target.name) {
            case 'countryName':
                setCountryName(target.value)
                break
            case 'phoneCode':
                setphoneCode(target.value)
                break
            case 'currency':
                setCurrency(target.value)
                break
            case 'currencyName':
                setCurrencyName(target.value)
                break
            case 'currencySymbol':
                setCurrencySymbol(target.value)
                break
            case 'region':
                setRegion(target.value)
                break
            case 'subregion':
                setSubRegion(target.value)
                break
            
            default:
        }
    }

    const handleIsActiveCheck = (e) => {
        setIsActive(e.target.checked)
    }
    const handleCreateCountry = async (e) => {
        e.preventDefault()
        console.log('click the create button')
        const data = {
            countryName,
            phoneCode, 
            currency,
            currencyName,
            currencySymbol,
            region,
            subregion,
            status:isActive
        }
        console.log({data})
        // dispatch(createOrUpdateStoreBannerRecord(data));


    }

    const { register, handleSubmit, formState, errors } = useForm({ mode: "onChange", resolver: yupResolver(CreateAdsSchema) });

    const onSubmit = (data, e) => {
        e.target.reset();
    };

    return (
        <>
            <div className="page-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="panel panel-success">
                                <div className="panel-heading">
                                    Create Country
                                    <span style={{ float: "right" }}>
                                        {isSellerPanel ?
                                            <Link to="">
                                                <Icon.List className="text-light" />
                                            </Link>
                                            :
                                            <Link to="/AllCountryList">
                                                <Icon.List className="text-light" />
                                            </Link>}
                                    </span>
                                </div>
                                <div className="panel-wrapper collapse in" aria-expanded="true">
                                    <div className="panel-body">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="form-body">
                    
                                                <div className="p-fluid p-formgrid p-grid">
                                                    <div className="p-fluid p-col-12 p-md-6">
                                                        <div className="form-group">
                                                            <label className="control_label">
                                                                Country Name
                                                                <span
                                                                    aria-hidden="true"
                                                                    style={{ color: "red", fontWeight: "bold" }}
                                                                >
                                                                    *
                                                                </span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                placeholder="Enter Contry Name"
                                                                name="countryName"
                                                                value={countryName}
                                                                onChange={handleChange}
                                                                className="form-control"
                                                                ref={register}
                                                            />
                                                            {errors?.countryName && (
                                                                <span className="error">
                                                                    {errors?.countryName?.message}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="p-fluid p-col-12 p-md-6">
                                                        <div className="form-group">
                                                            <label className="control_label">
                                                                Phone Code
                                                                <span
                                                                    aria-hidden="true"
                                                                    style={{ color: "red", fontWeight: "bold" }}
                                                                >
                                                                    *
                                                                </span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                placeholder="Enter phone code"
                                                                name="phoneCode"
                                                                value={phoneCode}
                                                                onChange={handleChange}
                                                                className="form-control"
                                                                ref={register}
                                                            />
                                                            {errors?.phoneCode && (
                                                                <span className="error">
                                                                    {errors?.phoneCode?.message}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-fluid p-formgrid p-grid">
                                                    <div className="p-fluid p-col-12 p-md-4">
                                                        <div className="form-group">
                                                            <label className="control_label">
                                                                Currency
                                                                <span
                                                                    aria-hidden="true"
                                                                    style={{ color: "red", fontWeight: "bold" }}
                                                                >
                                                                    *
                                                                </span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                placeholder="Enter currency short form"
                                                                name="currency"
                                                                value={currency}
                                                                onChange={handleChange}
                                                                className="form-control"
                                                                ref={register}
                                                            />
                                                            {errors?.currency && (
                                                                <span className="error">
                                                                    {errors?.currency?.message}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="p-fluid p-col-12 p-md-4">
                                                        <div className="form-group">
                                                            <label className="control_label">
                                                                Currency Name
                                                                <span
                                                                    aria-hidden="true"
                                                                    style={{ color: "red", fontWeight: "bold" }}
                                                                >
                                                                    *
                                                                </span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                placeholder="Enter currency name"
                                                                name="currencyName"
                                                                value={currencyName}
                                                                onChange={handleChange}
                                                                className="form-control"
                                                                ref={register}
                                                            />
                                                            {errors?.currencyName && (
                                                                <span className="error">
                                                                    {errors?.currencyName?.message}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                   
                                                    <div className="p-fluid p-col-12 p-md-4">
                                                        <div className="form-group">
                                                            <label className="control_label">
                                                                Currency Symbol
                                                                <span
                                                                    aria-hidden="true"
                                                                    style={{ color: "red", fontWeight: "bold" }}
                                                                >
                                                                    *
                                                                </span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                placeholder="Enter Currency Symbol"
                                                                name="currencySymbol"
                                                                value={currencySymbol}
                                                                onChange={handleChange}
                                                                className="form-control"
                                                                ref={register}
                                                            />
                                                            {errors?.currencySymbol && (
                                                                <span className="error">
                                                                    {errors?.currencySymbol?.message}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-fluid p-formgrid p-grid">
                                                    <div className="p-fluid p-col-12 p-md-4">
                                                        <div className="form-group">
                                                            <label className="control_label">
                                                                Region
                                                                <span
                                                                    aria-hidden="true"
                                                                    style={{ color: "red", fontWeight: "bold" }}
                                                                >
                                                                    *
                                                                </span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                placeholder="Enter the Region Name"
                                                                name="region"
                                                                value={region}
                                                                onChange={handleChange}
                                                                className="form-control"
                                                                ref={register}
                                                            />
                                                            {errors?.region && (
                                                                <span className="error">
                                                                    {errors?.region?.message}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="p-fluid p-col-12 p-md-4">
                                                        <div className="form-group">
                                                            <label className="control_label">
                                                                Sub Region
                                                                <span
                                                                    aria-hidden="true"
                                                                    style={{ color: "red", fontWeight: "bold" }}
                                                                >
                                                                    *
                                                                </span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                placeholder="Enter sub region"
                                                                name="subregion"
                                                                value={subregion}
                                                                onChange={handleChange}
                                                                className="form-control"
                                                                ref={register}
                                                            />
                                                            {errors?.subregion && (
                                                                <span className="error">
                                                                    {errors?.subregion?.message}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className='p-fluid p-col-12 p-md-4 flex justify-center'>
                                                        <div className='form-group'>
                                                            <label className='control_label'>Active</label>
                                                            <div className='checkbox checkbox-success d-flex align-items-center'>
                                                                <input
                                                                    id='isActive'
                                                                    type='checkbox'
                                                                    name='isActive'
                                                                    checked={isActive}
                                                                    onChange={handleIsActiveCheck}
                                                                />
                                                                <label htmlFor='isActive'> &nbsp;Yes </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                            <div className="form-footer">
                                                <div className="form-group row">
                                                    <div className="text-center">
                                                        <div className="btn-group text-center">
                                                            <button
                                                                className="btn btn-success"
                                                                onClick={handleCreateCountry}
                                                                disabled={!formState?.isValid}
                                                                style={{ cursor: "pointer" }}
                                                            >
                                                                Create
                                                            </button>
                                                            <Link to="/Home">
                                                                <button
                                                                    className="btn btn-danger"
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    Cancel
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer autoClose={1500} />
        </>

    )
}

export default CreateCountryContainer;