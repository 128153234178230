/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { FestivalViewProductListTable } from "../../components/festival/FestivalViewProductListTable";
import { getBrandRecord } from "../../store/actions/brandAction";
import { getCategoryRecord } from "../../store/actions/categoryAction";
import { festivalProductStatusChangeRecord, festivalProductStatusChangeReset, getFestivalProductsRecord } from "../../store/actions/festiveAction";
import authenticationService from "../../store/services/authenticationService";

const ViewFestivalProductListContainer = () => {
  const history = useHistory();
  const location = useLocation();
  const prevState = location?.state?.state;
  const dispatch = useDispatch();

  const urlSearchParams = new URLSearchParams(location.search);
  const selectedFestivalCatId = Number(urlSearchParams.get('festivalCatId'));

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(null);
  const [itemPerPage, setItemPerPage] = useState(prevState?.itemPerPage ? prevState?.itemPerPage : 15);
  const [totalItems, setTotalItems] = useState(null);

  const [searchKeyword, setSearchKeyword] = useState(prevState?.searchKeyword ? prevState?.searchKeyword : "");

  const [category, setCategory] = useState(prevState?.category ? prevState?.category : null);
  const [brand, setBrand] = useState(prevState?.brand ? prevState?.brand : null);
  const [vendor, setVendor] = useState(prevState?.vendor ? prevState?.vendor : null);
  const [isFilter, setIsFilter] = useState(false);

  const { getFestivalProductsData, getFestivalProductsDataLoading, festivalProductStatusChangeData, festivalProductStatusChangeLoading } = useSelector((state) => state.festivalReducer);

  const categories = useSelector((state) => state.categoryReducer.categories);
  const brands = useSelector((state) => state.brandReducer.brands);

  const [loaderCall, setLoaderCall] = useState(false);
  useEffect(() => {
    setLoaderCall(true)
    const timeoutId = setTimeout(async () => {
      setLoaderCall(false);
    }, 2500);

    return () => clearTimeout(timeoutId);
  }, [isFilter, currentPage]);

  useEffect(() => {
    dispatch(getCategoryRecord());
    dispatch(getBrandRecord());
  }, []);

  const handleItemPerPage = (pagePerItems) => {
    setCurrentPage(1);
    setItemPerPage(pagePerItems);
  };

  const handleCurrentPage = (currentPage) => {
    setCurrentPage(currentPage);
  };

  useEffect(() => {
    if (history?.location?.state) {
      setCurrentPage(history.location?.state.state.currentPage);
      setItemPerPage(history.location?.state.state.itemPerPage);
    }
  }, []);
  const callApi = () => {
    const productInfo = {
      itemsPerPage: itemPerPage,
      currentPage: currentPage,
      festivalId: selectedFestivalCatId,
      festivalProductStatus: 'Y',
    }
    dispatch(getFestivalProductsRecord(productInfo))
  }
  useEffect(() => {
    callApi()
  }, [currentPage])

  useEffect(() => {
    const selectedShopId = vendor?.map(item => item?.value).join(',');

    const productInfo = {
      itemsPerPage: itemPerPage,
      currentPage: currentPage,
      festivalId: selectedFestivalCatId,
      festivalProductStatus: 'Y',
      categoryId: category?.categoryId,
      shopId: selectedShopId,
      brandId: brand?.brandId,
    }

    if (searchKeyword) {
      productInfo.keywords = searchKeyword;
      productInfo.currentPage = 1;
    }

    if (festivalProductStatusChangeData.data) {
      dispatch(getFestivalProductsRecord(productInfo))
    }

    if (isFilter) {
      dispatch(getFestivalProductsRecord(productInfo))
      const url = `/View-Festival-ProductsList?festivalCatId=${selectedFestivalCatId}`;
      history.push(url, { state: { searchKeyword, brand, category, vendor } })
    }
    return () => setIsFilter(false)
  }, [isFilter, festivalProductStatusChangeData])

  const handleStatusChange = (data) => {
    const dataStatus = {
      productId: data.productId,
      festivalId: selectedFestivalCatId,
      isActive: data.isActive === 'Y' ? 'N' : 'Y'
    }
    dispatch(festivalProductStatusChangeRecord(dataStatus))
  }


  useEffect(() => {
    if (festivalProductStatusChangeData.data) {
      if (festivalProductStatusChangeData && festivalProductStatusChangeData?.data?.succeed === true) {
        toast.success('Product Status Changed Successfully')
        dispatch(festivalProductStatusChangeReset())
      } else if (festivalProductStatusChangeData && festivalProductStatusChangeData?.data?.message === "Data updated Successfully") {
        toast.success('Product Status Changed Successfully')
        dispatch(festivalProductStatusChangeReset())
      } else if (festivalProductStatusChangeData && festivalProductStatusChangeData?.data?.succeed === false) {
        toast.error('Something went wrong, Please try again')
        dispatch(festivalProductStatusChangeReset())
      }
    }
  }, [festivalProductStatusChangeData.data])

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="p-text-bold table-heading-style">
          View Products in Festival
        </div>
      </React.Fragment>
    );
  }

  const rightToolbarTemplate = () => {
    let roleId = authenticationService.getRoleId();
    return (
      <React.Fragment>
        <Link to={roleId == 1 ? "/FestiveList?status=active" : "/FestiveList?status=active"}>
          <div className="button-demo">
            <Button
              icon="pi pi-times"
              className="p-button-rounded p-button-danger p-button-outlined"
            />
          </div>
        </Link>
      </React.Fragment>
    );
  }

  return (
    <>
      <ToastContainer autoClose={2000} />
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div className="datatable-doc-demo datatable-responsive-demo">
                  <div className="card">
                    <Toolbar
                      className="p-mb-4"
                      right={rightToolbarTemplate}
                      left={leftToolbarTemplate}
                    ></Toolbar>

                    {
                      <FestivalViewProductListTable
                        product_list={getFestivalProductsData}
                        handleItemPerPage={handleItemPerPage}
                        handleCurrentPage={handleCurrentPage}
                        currentPage={currentPage}
                        totalPage={totalPage}
                        setTotalPage={setTotalPage}
                        itemPerPage={itemPerPage}
                        totalItems={totalItems}
                        setTotalItems={setTotalItems}
                        loading={loaderCall}
                        handleStatusChange={handleStatusChange}

                        setSearchKeyword={setSearchKeyword}
                        searchKeyword={searchKeyword}
                        categories={categories}
                        setCategory={setCategory}
                        category={category}
                        brands={brands}
                        setBrand={setBrand}
                        brand={brand}
                        vendor={vendor}
                        setVendor={setVendor}
                        setIsFilter={setIsFilter}
                        setCurrentPage={setCurrentPage}
                      />
                    }

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewFestivalProductListContainer