/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { Switch } from "antd";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from "react-router-dom";
import LoadingCard from "../../../components/shared/LoadingCard";
import Message from "../../../components/shared/Message";
import { getAllCityRecord, getAllCountryRecord } from "../../../store/actions/addressAction";
import httpClient from "../../../utils/httpClient";
import { toast, ToastContainer } from "react-toastify";
import baseUrl from "../../../utils/baseUrl";


const CityListContainer = () => {
    const dispatch = useDispatch();
    const navigateTo = useHistory();
    const [globalFilter, setGlobalFilter] = useState('')
    
    const { allCities, loading: cityLoading, success: citySuccess, error } = useSelector((state) => state?.addressReducer);

    const handleStatus = async (rowData) => {
      
        try {
         
            const res= await httpClient.post(`${baseUrl}/api/DivisionCitiesArea/CreateOrUpdateCity`, rowData);
            if (res?.data?.succeed) {
                toast.success(res?.data?.message);

                setTimeout(() => {
                    dispatch(getAllCityRecord(19));
                }, 2000);
            }
            else{
                toast.error(res?.data?.message)
            }
            
        } catch (error) {
            console.log({error})
            
        }
        // dispatch(createOrUpdateStoreBannerRecord(data));

    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="p-text-bold table-heading-style">
                    All City List
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Link to="/CreateCity">
                    <div className="button-demo">
                        <Button
                            icon="pi pi-times"
                            className="p-button-rounded p-button-danger p-button-outlined"
                        />
                    </div>
                </Link>
            </React.Fragment>
        );
    };

    const onIndexTemplate = (rowData, props) => {
        return props.rowIndex + 1;
    };

    const cityNameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {rowData.cityName}
            </React.Fragment>
        )
    }

    const countryCodeBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {rowData.countryCode}
            </React.Fragment>
        )
    }

    const changeStatusBodyTemplate = (rowData) => {
        return (
          <>
            <Switch
              style={{
                backgroundColor: rowData?.flag === 1 ? '#1f5da0' : '',
              }}
              checked={rowData?.flag === 1 ? true : false}
              onClick={rowData?.flag === 1 ? () => handleStatus({...rowData, flag:0}) : () => handleStatus({...rowData, flag:1})}
            />
          </>
        );
      }

    const editActionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    onClick={() => {
                        navigateTo.push({
                            pathname: "/EditCity",
                            state: { data: rowData },
                        });
                    }}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-success p-mr-2"
                />
            </React.Fragment>
        );
    };

    const renderHeader = () => {
        return (
            <>
                <div className="table-header">
                    <span className="p-input-icon-left">
                        <InputText
                            type="search"
                            className="form-control text-center text-field"
                            onInput={(e) => setGlobalFilter(e.target.value)}
                            placeholder="Search by city name"
                        />
                    </span>
                </div>
            </>
        )
    }

    useEffect(() => {
        dispatch(getAllCountryRecord())
        dispatch(getAllCityRecord(19));
    }, [dispatch]);

    return (
        <>
          <div className="page-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="white-box">
                            <div className="datatable-doc-demo datatable-responsive-demo">
                                <div className="card">
                                    <Toolbar
                                        className="p-mb-4"
                                        left={leftToolbarTemplate}
                                        right={rightToolbarTemplate}
                                    ></Toolbar>
                                    {cityLoading ? (
                                        <LoadingCard count={1} />
                                    ) : error ? (
                                        <Message variant="danger">{error}</Message>
                                    ) : (
                                        <DataTable
                                            header={renderHeader}
                                            value={allCities}
                                            className="p-datatable-customers p-datatable-responsive-demo"
                                            dataKey="id"
                                            rowHover
                                            globalFilter={globalFilter}
                                            paginator
                                            rows={10}
                                            rowsPerPageOptions={[10, 25, 50]}
                                            emptyMessage="No Store Banner found"
                                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                            loading={cityLoading}
                                        >
                                            <Column
                                                field="Index"
                                                header="SN"
                                                name=""
                                                body={onIndexTemplate}
                                            />

                                            <Column
                                                sortField="cityName"
                                                filterField="cityName"
                                                header="City"
                                                body={cityNameBodyTemplate}
                                                sortable
                                            />

                                            <Column
                                                sortField='countryCode'
                                                header='Country Code'
                                                field="countryCode"
                                                body={countryCodeBodyTemplate}
                                                sortable
                                            />

                                        
                                            <Column
                                                sortField="flag"
                                                header="Status"
                                                sortable
                                                body={changeStatusBodyTemplate}
                                            />

                                            <Column
                                                field="action"
                                                header="Edit"
                                                body={editActionBodyTemplate}
                                            />
                                        </DataTable>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <ToastContainer autoClose={1500} />
        </>
      
    )
}

export default CityListContainer