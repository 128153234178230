import http from "../../utils/httpClient"

class purchaseOrderService {

    getPOProductList = async (query) => {
        return await http.get("/api/PurchaseOrder/GetPOProductList", { params: query })
    }

    createPO = async (data) => {
        return await http.post("/api/PurchaseOrder/CreatePO", data)
    }
    
    getPOList = async (query) => {
        return await http.get("/api/PurchaseOrder/GetPOList", { params: query })
    }
}

export default new purchaseOrderService()