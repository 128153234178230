import {
    ADD_OR_EDIT_CAMPAIGN_PRODUCT_LIST,
    ADD_OR_EDIT_CAMPAIGN_PRODUCT_LIST_ERROR,
    ADD_OR_EDIT_CAMPAIGN_PRODUCT_LIST_RESET,
    ADD_OR_EDIT_CAMPAIGN_PRODUCT_LIST_SUCCESS,
    CREATE_OR_UPDATE_CAMPAIGN,
    CREATE_OR_UPDATE_CAMPAIGN_ERROR,
    CREATE_OR_UPDATE_CAMPAIGN_RESET,
    CREATE_OR_UPDATE_CAMPAIGN_SUCCESS,
    GET_CAMPAIGN,
    GET_CAMPAIGN_ERROR,
    GET_CAMPAIGN_SUCCESS,
    GET_ADDIBLE_CAMPAIGN_PRODUCTS,
    GET_ADDIBLE_CAMPAIGN_PRODUCTS_ERROR,
    GET_ADDIBLE_CAMPAIGN_PRODUCTS_SUCCESS,
    GET_PENDING_CAMPAIGN_PRODUCTS,
    GET_PENDING_CAMPAIGN_PRODUCTS_ERROR,
    GET_PENDING_CAMPAIGN_PRODUCTS_SUCCESS,
    GET_APPROVED_CAMPAIGN_PRODUCTS,
    GET_APPROVED_CAMPAIGN_PRODUCTS_ERROR,
    GET_APPROVED_CAMPAIGN_PRODUCTS_SUCCESS,
    GET_STATUS_WISE_CAMPAIGN_PRODUCTS,
    GET_STATUS_WISE_CAMPAIGN_PRODUCTS_ERROR,
    GET_STATUS_WISE_CAMPAIGN_PRODUCTS_SUCCESS,
    CAMPAIGN_PRODUCT_STATUS_CHANGE,
    CAMPAIGN_PRODUCT_STATUS_CHANGE_ERROR,
    CAMPAIGN_PRODUCT_STATUS_CHANGE_RESET,
    CAMPAIGN_PRODUCT_STATUS_CHANGE_SUCCESS,
} from "../actions/campaignAction"

const initialState = {
    campaignData: {},
    campaignProductListData: {},
    getAddibleCampaignProductsListData: {},
    getPendingCampaignProductsListData: {},
    getApprovedCampaignProductsListData: {},
    getStatusWiseCampaignProductsListData: {},
    campaignProductStatusChangeData: {},
    
    data: {},
    campaignLoading: false,
    campaignLoaded: false,
    campaignError: null,

    campaignProductListLoading: false,
    campaignProductListLoaded: false,
    campaignProductListError: null,

    getAddibleCampaignProductsListLoading: false,
    getAddibleCampaignProductsListLoaded: false,
    getAddibleCampaignProductsListError: null,

    getPendingCampaignProductsListLoading: false,
    getPendingCampaignProductsListLoaded: false,
    getPendingCampaignProductsListError: null,

    getApprovedCampaignProductsListLoading: false,
    getApprovedCampaignProductsListLoaded: false,
    getApprovedCampaignProductsListError: null,

    getStatusWiseCampaignProductsListLoading: false,
    getStatusWiseCampaignProductsListLoaded: false,
    getStatusWiseCampaignProductsListError: null,

    campaignProductStatusChangeLoading: false,
    campaignProductStatusChangeLoaded: false,
    campaignProductStatusChangeError: null,

    campaignSaving: false,
    campaignSaved: false,
    campaignUpdating: false,
    campaignUpdated: false,
    campaignDeleting: false,
    campaignDeleted: false,

}

export default function campaignReducer(state = initialState, action) {
    switch (action.type) {
        case CREATE_OR_UPDATE_CAMPAIGN:
            return {
                ...state,
                campaignLoading: true,
                campaignLoaded: false,
                campaignError: null,
            }

        case CREATE_OR_UPDATE_CAMPAIGN_SUCCESS:
            return {
                ...state,
                campaignLoading: false,
                campaignLoaded: true,
                campaignData: action.payload.success,
            }

        case CREATE_OR_UPDATE_CAMPAIGN_ERROR:
            return {
                ...state,
                campaignLoading: false,
                campaignLoaded: false,
                campaignError: action.payload.error,
                campaignData: [],
            }

        case CREATE_OR_UPDATE_CAMPAIGN_RESET:
            return {
                ...state,
                campaignLoading: false,
                campaignLoaded: false,
                campaignError: null,
                campaignData: {},
            }

        case GET_CAMPAIGN:
            return {
                ...state,
                campaignLoading: true,
                campaignLoaded: false,
                campaignError: null,
            }

        case GET_CAMPAIGN_SUCCESS:
            return {
                ...state,
                campaignLoading: false,
                campaignLoaded: true,
                campaignData: action.payload.success,
            }

        case GET_CAMPAIGN_ERROR:
            return {
                ...state,
                campaignLoading: false,
                campaignLoaded: false,
                campaignError: action.payload.error,
                campaignData: [],
            }

        case ADD_OR_EDIT_CAMPAIGN_PRODUCT_LIST:
            return {
                ...state,
                campaignProductListLoading: true,
                campaignProductListLoaded: false,
                campaignProductListError: null,
            }

        case ADD_OR_EDIT_CAMPAIGN_PRODUCT_LIST_SUCCESS:
            return {
                ...state,
                campaignProductListLoading: false,
                campaignProductListLoaded: true,
                campaignProductListData: action.payload.success,
            }

        case ADD_OR_EDIT_CAMPAIGN_PRODUCT_LIST_ERROR:
            return {
                ...state,
                campaignProductListLoading: false,
                campaignProductListLoaded: false,
                campaignProductListError: action.payload.error,
                campaignProductListData: [],
            }

        case ADD_OR_EDIT_CAMPAIGN_PRODUCT_LIST_RESET:
            return {
                ...state,
                campaignProductListLoading: false,
                campaignProductListLoaded: false,
                campaignProductListError: null,
                campaignProductListData: {},
            }

            
        case GET_ADDIBLE_CAMPAIGN_PRODUCTS:
            return {
                ...state,
                getAddibleCampaignProductsListLoading: true,
                getAddibleCampaignProductsListLoaded: false,
                getAddibleCampaignProductsListError: null,
            }

        case GET_ADDIBLE_CAMPAIGN_PRODUCTS_SUCCESS:
            return {
                ...state,
                getAddibleCampaignProductsListLoading: false,
                getAddibleCampaignProductsListLoaded: true,
                getAddibleCampaignProductsListData: action.payload.success,
            }

        case GET_ADDIBLE_CAMPAIGN_PRODUCTS_ERROR:
            return {
                ...state,
                getAddibleCampaignProductsListLoading: false,
                getAddibleCampaignProductsListLoaded: false,
                getAddibleCampaignProductsListError: action.payload.error,
                getAddibleCampaignProductsListData: [],
            }
            
            
        case GET_PENDING_CAMPAIGN_PRODUCTS:
            return {
                ...state,
                getPendingCampaignProductsListLoading: true,
                getPendingCampaignProductsListLoaded: false,
                getPendingCampaignProductsListError: null,
            }

        case GET_PENDING_CAMPAIGN_PRODUCTS_SUCCESS:
            return {
                ...state,
                getPendingCampaignProductsListLoading: false,
                getPendingCampaignProductsListLoaded: true,
                getPendingCampaignProductsListData: action.payload.success,
            }

        case GET_PENDING_CAMPAIGN_PRODUCTS_ERROR:
            return {
                ...state,
                getPendingCampaignProductsListLoading: false,
                getPendingCampaignProductsListLoaded: false,
                getPendingCampaignProductsListError: action.payload.error,
                getPendingCampaignProductsListData: [],
            }
            
        case GET_APPROVED_CAMPAIGN_PRODUCTS:
            return {
                ...state,
                getApprovedCampaignProductsListLoading: true,
                getApprovedCampaignProductsListLoaded: false,
                getApprovedCampaignProductsListError: null,
            }

        case GET_APPROVED_CAMPAIGN_PRODUCTS_SUCCESS:
            return {
                ...state,
                getApprovedCampaignProductsListLoading: false,
                getApprovedCampaignProductsListLoaded: true,
                getApprovedCampaignProductsListData: action.payload.success,
            }

        case GET_APPROVED_CAMPAIGN_PRODUCTS_ERROR:
            return {
                ...state,
                getApprovedCampaignProductsListLoading: false,
                getApprovedCampaignProductsListLoaded: false,
                getApprovedCampaignProductsListError: action.payload.error,
                getApprovedCampaignProductsListData: [],
            }

            case GET_STATUS_WISE_CAMPAIGN_PRODUCTS:
                return {
                    ...state,
                    getStatusWiseCampaignProductsListLoading: true,
                    getStatusWiseCampaignProductsListLoaded: false,
                    getStatusWiseCampaignProductsListError: null,
                }
    
            case GET_STATUS_WISE_CAMPAIGN_PRODUCTS_SUCCESS:
                return {
                    ...state,
                    getStatusWiseCampaignProductsListLoading: false,
                    getStatusWiseCampaignProductsListLoaded: true,
                    getStatusWiseCampaignProductsListData: action.payload.success,
                }
    
            case GET_STATUS_WISE_CAMPAIGN_PRODUCTS_ERROR:
                return {
                    ...state,
                    getStatusWiseCampaignProductsListLoading: false,
                    getStatusWiseCampaignProductsListLoaded: false,
                    getStatusWiseCampaignProductsListError: action.payload.error,
                    getStatusWiseCampaignProductsListData: [],
                }

                case CAMPAIGN_PRODUCT_STATUS_CHANGE:
                    return {
                        ...state,
                        campaignProductStatusChangeLoading: true,
                        campaignProductStatusChangeLoaded: false,
                        campaignProductStatusChangeError: null,
                    }
        
                case CAMPAIGN_PRODUCT_STATUS_CHANGE_SUCCESS:
                    return {
                        ...state,
                        campaignProductStatusChangeLoading: false,
                        campaignProductStatusChangeLoaded: true,
                        campaignProductStatusChangeData: action.payload.success,
                    }
        
                case CAMPAIGN_PRODUCT_STATUS_CHANGE_ERROR:
                    return {
                        ...state,
                        campaignProductStatusChangeLoading: false,
                        campaignProductStatusChangeLoaded: false,
                        campaignProductStatusChangeError: action.payload.error,
                        campaignProductStatusChangeData: [],
                    }
        
                case CAMPAIGN_PRODUCT_STATUS_CHANGE_RESET:
                    return {
                        ...state,
                        campaignProductStatusChangeLoading: false,
                        campaignProductStatusChangeLoaded: false,
                        campaignProductStatusChangeError: null,
                        campaignProductStatusChangeData: {},
                    }
        default:
            return state
    }
}
