import {
    GET_ORDER_ACCOUNTS_TRANSACTION,
    GET_ORDER_ACCOUNTS_TRANSACTION_ERROR,
    GET_ORDER_ACCOUNTS_TRANSACTION_SUCCESS,
  } from "../actions/transactionAction";

  const initialState = {
    accountsTransactionReportData: [], //THIS IS UESED FOR TO HOLD ARRAY DATA
  
    loading: false,
    loaded: false,
    error: null,
  }

  export default function (state = initialState, action) {
    switch (action.type) {
      case GET_ORDER_ACCOUNTS_TRANSACTION:
        return {
          ...state,
          loading: true,
          loaded: false,
          error: null,
        }
  
      case GET_ORDER_ACCOUNTS_TRANSACTION_SUCCESS:
        return {
          ...state,
          loading: false,
          loaded: true,
          accountsTransactionReportData: action.payload.success,
        }
  
      case GET_ORDER_ACCOUNTS_TRANSACTION_ERROR:
        return {
          ...state,
          loading: false,
          loaded: false,
          error: action.payload.error,
          accountsTransactionReportData: [],
        }
  
      default:
        return state
    }
  }