import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import authenticationService from "../../store/services/authenticationService";
import * as profileAction from "../../store/actions/profileAction";
import authService from "../../store/services/authService";

import { VerifiedSeller } from "../../components/seller/VerifiedSeller";
import * as productAction from "../../store/actions/productAction";
import * as sellerAction from "../../store/actions/sellerAction";

import PageLoading from "../shared/PageLoading";
import HomeSeller from "./Dashboard/HomeSeller";

class SellerHomeNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeSellers: null,
            activeProducts: null,
            pendingOrders: null,
            orders: false,
        };
        this.verifiedSellerService = new VerifiedSeller();
    }

    componentDidMount = async () => {
        let userId = authService.getEmployeeId();
        //Begin Temporary Authentication
        let roleId = authenticationService.getRoleId();
        if (roleId === "2") {
            this.setState({
                authenticated: true,
                loginSuccessful: true,
            });
        } else {
            this.setState({
                authenticated: false,
                loginSuccessful: false,
            });
            this.props.history.push("/SellerLogin");
        }
        //End Temporary Authentication
        this.verifiedSellerService
            .getVerifiedSeller()
            .then((data) => this.setState({ activeSellers: data }));

        this.setState({
            activeProducts: this.props.products,
        });
        await this.props.getProfileByIdRecord(userId);
    };

    render() {
        return (
            <Fragment>
                {this.props.ProfileLoading &&
                    !this.props?.profileById[0]?.designationId ? (
                    <PageLoading />
                ) : (
                    <HomeSeller />
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    sellers: state.sellerReducer.sellers,
    products: state.productReducer.verifiedProducts,
    profileById: state.profileReducer.profileById,
    ProfileLoading: state.profileReducer.loading,
    profileByIdLoading: state.profileReducer.loading,
});

// Making available in  props
const mapDispatchToProps = (dispatch) => {
    return {
        getVerifiedShopRecord: () => dispatch(sellerAction.getVerifiedShopRecord()),
        getVerifiedProductsRecord: () =>
            dispatch(productAction.getVerifiedProductsRecord()),
        getProfileByIdRecord: (index) =>
            dispatch(profileAction.getProfileByIdRecord(index)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SellerHomeNew);

