import { Modal } from "antd";
import moment from "moment";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import FetchCancelOrderTrackingAdmin from "../../components/orders/FetchCancelOrderTrackingAdmin";
import RefundedOrdersModal from "../../components/orders/RefundedOrdersModal";
import { Paginator } from "../../components/paginator/Paginator";
import LoadingCard from "../../components/shared/LoadingCard";
import useViewPort from "../../hooks/useViewPort";
import { POST_CANCEL_ORDER_RESET, fetchCancelOrdersRecord } from "../../store/actions/orderAction";
import { getPaymentMethodRecord } from "../../store/actions/paymentMethodAction";
import { fetchRefundedDataRecord } from "../../store/actions/refundActions";
import { getAllSellerRecord } from "../../store/actions/sellerAction";
import authenticationService from "../../store/services/authenticationService";
import toIsoString from "../../utils/Functions/toIsoString";
import CancelRefundModal from "./../../components/refund/CancelRefundModal";
import { ToastContainer, toast } from "react-toastify";

// const paymentStatusArr = [
//   { paymentStatus: "Initial", code: 1 },
//   { paymentStatus: "Pending", code: 2 },
//   { paymentStatus: "Success", code: 3 },
//   { paymentStatus: "Partial", code: 4 },
//   { paymentStatus: "Failed", code: 5 },
//   { paymentStatus: "Cancel", code: 6 },
// ];

export default function CancelOrdersListContainer() {
  const { viewportWidth } = useViewPort();

  // const [globalFilter, setGlobalFilter] = useState("");
  const [visible, setVisible] = useState(false);
  const [refundVisible, setRefundVisible] = useState(false);
  const [refundedVisible, setRefundedVisible] = useState(false);
  const [orderInfo, setOrderInfo] = useState(null);
  const [counts, setCounts] = useState(1);
  const [selectedOrders, setSelectedOrders] = useState(null);
  const [pathnameOptions, setPathnameOptions] = useState(null);
  const [totalRefundAmount, setTotalRefundAmount] = useState(null);
  const [statusId, setStatusId] = useState(null);
  const [statusName, setStatusName] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(30);
  const [totalPage, setTotalPage] = useState(null);
  const [totalItems, setTotalItems] = useState(null);
  const [cancelDate, setCancelDate] = useState(null);
  const [selectVendor, setSelectVendor] = useState(null);
  const [selectPaymentMethod, setSelectPaymentMethod] = useState(null);

  const [invoiceNo, setInvoiceNo] = useState("");
  const [tranNo, setTranNo] = useState("");
  const [shopWiseOrderNo, setShopWiseOrderNo] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [filterOrder, setFilterOrder] = useState(false);
  const [urlParams, setUrlParams] = useState("");

  const dispatch = useDispatch();
  let history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const status = searchParams.get("status");

  const invoiceType = 6;

  const vendorList = useSelector((state) => state.sellerReducer.sellerList);
  const paymentMethods = useSelector(
    (state) => state.paymentMethodReducer.paymentMethods
  );



  const onVendorChange = (e) => {
    setSelectVendor(e.value);
  };
  // const onPaymentStatusChange = (e) => {
  //   setSelectPaymentStatus(e.value);
  // };
  const onPaymentMethodChange = (e) => {
    setSelectPaymentMethod(e.value);
  };

  const refunded_loading = useSelector((state) => state.refundReducer.loading);
  const order_filter_tab = history?.location?.search?.substring(8);
  const status_for_api_call = order_filter_tab.replaceAll("_", " ");
  const status_arr = status_for_api_call.split(" ");
  for (var i = 0; i < status_arr.length; i++) {
    status_arr[i] =
      status_arr[i].charAt(0).toUpperCase() + status_arr[i].slice(1);
  }
  const reformed_status = status_arr.join(" ");
  const cancel_orders = useSelector(
    (state) => state.orderReducer.fetchCancelOrders
  );

  const loading = useSelector((state) => state.orderReducer.loading);
  const handleItemPerPage = (pagePerItems) => {
    setCurrentPage(1);
    setItemPerPage(pagePerItems);
  };
  const handleCurrentPage = (currentPage) => {
    setUrlParams(status);
    if (filterOrder || urlParams !== status) {
      setCurrentPage(1);
    } else {
      setCurrentPage(currentPage);
    }
  };

  useEffect(() => {
    let roleId = authenticationService.getRoleId();
    if (roleId === "1") {
    } else {
      history.push("/Login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setStatusId(null);
    setStatusName(null);
  }, [visible]);

  useEffect(() => {
    if (cancel_orders?.headers?.pagination && order_filter_tab !== "refund") {
      var paginated_data_to_parse = cancel_orders.headers.pagination;
      const paginated_data = JSON.parse(paginated_data_to_parse);
      // setCurrentPage(paginated_data.currentPage);
      setTotalPage(paginated_data.totalPages);
      setTotalItems(paginated_data.totalItems);
      // setItemPerPage(paginated_data.itemsPerPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancel_orders?.headers?.pagination]);

  //reformed status
  useEffect(() => {
    if (order_filter_tab !== "refund") {
      dispatch(
        fetchCancelOrdersRecord(
          reformed_status,
          currentPage,
          itemPerPage,
          shopWiseOrderNo,
          invoiceNo,
          customerName,
          selectPaymentMethod?.methodName,
          selectVendor?.shopId,
          cancelDate !== null ? toIsoString(cancelDate).substring(0, 10) : null
        )
      );

      return () => {
        setFilterOrder(false);
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOrder, reformed_status, counts, currentPage, itemPerPage]);

  // const date= new Date(cancelDate).toISOString().substring(0,10)

  useEffect(() => {
    dispatch(getPaymentMethodRecord());
    dispatch(getAllSellerRecord());
  }, [dispatch]);

  const cancel_order_msg = useSelector(
    (state) => state.orderReducer.cancelOrderStatus
  );

  useEffect(() => {
    if (cancel_order_msg?.succeed === true && !visible) {
      toast.success('Order cancel Successfully!', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
      dispatch({ type: POST_CANCEL_ORDER_RESET });
    }
    if (cancel_order_msg?.succeed === false && !visible) {
      toast.error(cancel_order_msg.errors.at(0), {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
      dispatch({ type: POST_CANCEL_ORDER_RESET });
    }
  }, [dispatch, cancel_order_msg, visible]);

  const cancelInvoiceNoBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <ul className="" style={{ listStyleType: "none", paddingLeft: 0 }}>
          <li>{rowData.cancelInvoiceNo}</li>
          <li>{rowData.orderInvoiceNo}</li>
        </ul>
      </React.Fragment>
    );
  };
  // const orderNoBodyTemplate = (rowData) => {
  //   return (
  //     <React.Fragment>
  //       <span>{rowData.orderInvoiceNo}</span>
  //     </React.Fragment>
  //   )
  // }
  const customerNameBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span>{rowData?.customerName}</span>
      </React.Fragment>
    );
  };
  const cancelOrderDateTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span>{moment(rowData.cancelDate).format("Do MMMM YYYY, h:mm A")}</span>
      </React.Fragment>
    );
  };
  const refundTateBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span>{moment(rowData.refundDate).format("Do MMMM YYYY, h:mm A")}</span>
      </React.Fragment>
    );
  };
  const shopNameBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span>{rowData.shopName}</span>
      </React.Fragment>
    );
  };
  const paymentMethodTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span>
          {rowData.paymentMethodName
            ? rowData.paymentMethodName === "COD"
              ? "Cash On Delivery"
              : rowData.paymentMethodName
            : "Not Selected Yet"}
        </span>
      </React.Fragment>
    );
  };
  const paymentStatusTemplate = (rowData) => {
    return (
      <React.Fragment>
        {rowData.paymentStatus === "Pending" ? (
          <Tag
            style={{ width: "80px" }}
            severity="navy"
            value={rowData.paymentStatus}
            rounded
          ></Tag>
        ) : rowData.paymentStatus === "Failed" ? (
          <Tag
            style={{ width: "80px" }}
            severity="danger"
            value={rowData.paymentStatus}
            rounded
          ></Tag>
        ) : (rowData?.paymentStatus === "Cancel" || rowData?.paymentStatus === "Canceled") ? (
          <Tag
            style={{ width: "80px" }}
            severity="warning"
            value={rowData.paymentStatus}
            rounded
          ></Tag>
        ) : (
          <Tag
            style={{ width: "80px" }}
            severity="success"
            value="Done"
            rounded
          ></Tag>
        )}
      </React.Fragment>
    );
  };

  const productAmountBodyTemplate = (rowData) => {
    const total_amount = rowData?.totalAmount;

    return (
      <React.Fragment>
        <span>{total_amount}</span>
      </React.Fragment>
    );
  };

  const productDetailsTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span>
          <Button
            onClick={() => {
              setOrderInfo(rowData);
              setVisible(true);
            }}
            style={{
              fontSize: "14px",
              padding: "4px 10px",
              marginRight: "5px",
            }}
          >
            Details
          </Button>
          {order_filter_tab === "refund_processing" && (
            <Button
              onClick={() => {
                setOrderInfo(rowData);
                setRefundVisible(true);
              }}
              className="p-button-success"
              style={{
                fontSize: "14px",
                padding: "4px 10px",
              }}
            >
              Refund
            </Button>
          )}
        </span>
      </React.Fragment>
    );
  };
  const refundDetailsBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span>
          <Button
            onClick={() => {
              setOrderInfo(rowData);
              setRefundedVisible(true);
            }}
            style={{
              fontSize: "14px",
              padding: "4px 10px",
              marginRight: "5px",
            }}
          >
            Details
          </Button>
        </span>
      </React.Fragment>
    );
  };
  const refundTotalBodyTateBodyTemplate = (rowData) => {
    const total_amount = rowData.productDetails.reduce(
      (total, currentValue) => (total = total + currentValue.amount),
      0
    );
    setTotalRefundAmount(total_amount);
    return (
      <React.Fragment>
        <span>{total_amount}</span>
      </React.Fragment>
    );
  };

  useEffect(() => {
    if (history.location.pathname === "/CancelOrders")
      setPathnameOptions("cancel");
  }, [history.location.pathname]);

  useEffect(() => {
    if (pathnameOptions !== null && status === "refund") {
      dispatch(
        fetchRefundedDataRecord(
          pathnameOptions,
          currentPage,
          itemPerPage,
          invoiceNo,
          customerName,
          selectPaymentMethod?.methodName,
          tranNo
        )
      );
    }

    return () => {
      setFilterOrder(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterOrder,
    pathnameOptions,
    order_filter_tab,
    currentPage,
    itemPerPage,
  ]);
  const refunded_data = useSelector(
    (state) => state.refundReducer.get_refunded_data
  );
  useEffect(() => {
    if (refunded_data?.headers?.pagination && status === "refund") {
      var paginated_data_to_parse = refunded_data.headers.pagination;
      const paginated_data = JSON.parse(paginated_data_to_parse);

      setTotalPage(paginated_data.totalPages);
      setTotalItems(paginated_data.totalItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refunded_data?.headers?.pagination, status === "refund"]);
  return (

    <div className="page-wrapper">
      <div className="container-fluid">
        <div className="col-md-12">
          <div className="white-box">
            <div className="datatable-doc-demo datatable-responsive-demo">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                className="card"
              >
                <ul className="nav nav-tabs seller-tabs">
                  <li>
                    <Link
                      to={`/CancelOrders?status=cancel_request`}
                      style={
                        order_filter_tab === "cancel_request"
                          ? { color: "#1F5DA0", borderBottom:'2px solid #1F5DA0', fontWeight: "700" }
                          : null
                      }
                    >
                      Cancel Request
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/CancelOrders?status=refund_processing`}
                      style={
                        order_filter_tab === "refund_processing"
                          ? { color: "#1F5DA0", borderBottom:'2px solid #1F5DA0', fontWeight: "700" }
                          : null
                      }
                    >
                      Refund Processing
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/CancelOrders?status=refund`}
                      style={
                        order_filter_tab === "refund"
                          ? { color: "#1F5DA0", borderBottom:'2px solid #1F5DA0', fontWeight: "700" }
                          : null
                      }
                    >
                      Refund
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/CancelOrders?status=canceled`}
                      style={
                        order_filter_tab === "canceled"
                          ? { color: "#1F5DA0", borderBottom:'2px solid #1F5DA0', fontWeight: "700" }
                          : null
                      }
                    >
                      Canceled
                    </Link>
                  </li>
                </ul>
              </div>

              <div
                style={{
                  display: "flex",

                  flexDirection: viewportWidth < 992 ? "column-reverse" : "row",
                  justifyContent: "space-between",
                  padding: "20px 0px",
                  gap: 15,
                }}
              >
                <div>
                  {order_filter_tab !== "refund" && (
                    <Link
                      to={{
                        pathname: `/invoiceAdmin`,
                        selectedOrders,
                        invoiceType,
                        order_filter_tab,
                      }}
                    >
                      <button
                        className="btn btn-info"
                        style={{
                          padding: "4px 20px",
                          fontSize: "13px ",
                          marginRight: "5px",
                        }}
                        disabled={
                          selectedOrders === null ||
                          selectedOrders?.length === 0
                        }
                      >
                        Print
                      </button>
                    </Link>
                  )}
                </div>

                <div
                  style={{
                    // float: 'right',
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    justifyContent: "spaceBetween",
                  }}
                  className="order_filter__section"
                >
                  {order_filter_tab === "refund" && (
                    <div
                      style={{
                        margin: "0 2px",
                        width: viewportWidth < 992 ? "190px" : "250px",
                        position: "relative",
                      }}
                    >
                      <InputText
                        style={{
                          width: "100%",
                        }}
                        className="manage_product__search"
                        value={tranNo}
                        // onChange={(e) => setSearchKeyword(e.target.value)}
                        onChange={(e) => setTranNo(e.target.value)}
                        placeholder="Tran No"
                      />
                      {tranNo !== "" ? (
                        <button
                          style={{
                            position: "absolute",
                            top: "6px",
                            right: "8px",
                            borderRadius: "100%",
                            border: "1px solid #ced4da",
                            height: "25px",
                            width: "25px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onClick={() => {
                            // setSearchKeyword("");
                            setTranNo("");
                            // setSearchKeywordApi("");
                            setCurrentPage(1);
                          }}
                        >
                          <i className="pi pi-times"></i>
                        </button>
                      ) : null}
                    </div>
                  )}

                  <div
                    style={{
                      margin: "0 2px",
                      width: viewportWidth < 992 ? "190px" : "250px",
                      position: "relative",
                    }}
                  >
                    <InputText
                      style={{
                        width: "100%",
                      }}
                      className="manage_product__search"
                      value={invoiceNo}
                      // onChange={(e) => setSearchKeyword(e.target.value)}
                      onChange={(e) => setInvoiceNo(e.target.value)}
                      placeholder="Order Invoice No"
                    />
                    {invoiceNo !== "" ? (
                      <button
                        style={{
                          position: "absolute",
                          top: "6px",
                          right: "8px",
                          borderRadius: "100%",
                          border: "1px solid #ced4da",
                          height: "25px",
                          width: "25px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={() => {
                          // setSearchKeyword("");
                          setInvoiceNo("");
                          // setSearchKeywordApi("");
                          setCurrentPage(1);
                        }}
                      >
                        <i className="pi pi-times"></i>
                      </button>
                    ) : null}
                  </div>

                  {order_filter_tab !== "refund" && (
                    <div
                      style={{
                        margin: "0 2px",
                        width: viewportWidth < 992 ? "190px" : "250px",
                        position: "relative",
                      }}
                    >
                      <InputText
                        style={{
                          width: "100%",
                        }}
                        className="manage_product__search"
                        value={shopWiseOrderNo}
                        onChange={(e) => setShopWiseOrderNo(e.target.value)}
                        placeholder="Cancel Invoice No"
                      />
                      {shopWiseOrderNo !== "" ? (
                        <button
                          style={{
                            position: "absolute",
                            top: "6px",
                            right: "8px",
                            borderRadius: "100%",
                            border: "1px solid #ced4da",
                            height: "25px",
                            width: "25px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onClick={() => {
                            setShopWiseOrderNo("");
                            setCurrentPage(1);
                          }}
                        >
                          <i className="pi pi-times"></i>
                        </button>
                      ) : null}
                    </div>
                  )}
                  <div
                    style={{
                      margin: "0 2px",
                      width: "190px",
                      position: "relative",
                    }}
                  >
                    <InputText
                      style={{
                        width: "100%",
                      }}
                      className="manage_product__search"
                      value={customerName}
                      onChange={(e) => setCustomerName(e.target.value)}
                      placeholder="Customer Name"
                    />
                    {customerName !== "" ? (
                      <button
                        style={{
                          position: "absolute",
                          top: "6px",
                          right: "8px",
                          borderRadius: "100%",
                          border: "1px solid #ced4da",
                          height: "25px",
                          width: "25px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={() => {
                          // setSearchKeyword("");
                          setCustomerName("");
                          // setSearchKeywordApi("");
                          setCurrentPage(1);
                        }}
                      >
                        <i className="pi pi-times"></i>
                      </button>
                    ) : null}
                  </div>
                  {order_filter_tab !== "refund" && (
                    <div
                      style={{
                        margin: "0 2px",
                        width: "155px",
                        position: "relative",
                      }}
                    >
                      <Calendar
                        id="icon"
                        // maxDate={endDate}
                        value={cancelDate}
                        onChange={(e) => setCancelDate(e.value)}
                        showIcon
                        placeholder="Cancel Date"
                        readOnlyInput
                      />
                      {cancelDate !== null ? (
                        <button
                          style={{
                            position: "absolute",
                            top: "6px",
                            right: "33px",
                            borderRadius: "100%",
                            border: "1px solid #ced4da",
                            height: "25px",
                            width: "25px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onClick={() => {
                            setCancelDate(null);
                            // setStartDateApi(null);
                            setCurrentPage(1);
                          }}
                        >
                          <i className="pi pi-times"></i>
                        </button>
                      ) : null}
                    </div>
                  )}
                  {/* <div
                    style={{
                      margin: "0 2px",
                      width: "155px",
                      position: "relative",
                    }}
                  >
                    <Calendar
                      id="icon disableddays"
                      minDate={startDate}
                      value={endDate}
                      onChange={(e) => setEndDate(e.value)}
                      showIcon
                      placeholder="End Date"
                      readOnlyInput
                    />
                    {endDate !== null ? (
                      <button
                        style={{
                          position: "absolute",
                          top: "6px",
                          right: "33px",
                          borderRadius: "100%",
                          border: "1px solid #ced4da",
                          height: "25px",
                          width: "25px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={() => {
                          setEndDate(null);
                          setEndDateApi(null);
                          setCurrentPage(1);
                        }}
                      >
                        <i className="pi pi-times"></i>
                      </button>
                    ) : null}
                  </div> */}
                  {order_filter_tab !== "refund" && (
                    <div
                      style={{
                        margin: "0 2px",
                        width: "155px",
                        position: "relative",
                      }}
                    >
                      <Dropdown
                        style={{
                          width: "100%",
                        }}
                        value={selectVendor}
                        options={vendorList?.data}
                        onChange={onVendorChange}
                        optionLabel="shopName"
                        placeholder="Select a Seller"
                        filter
                        showClear
                      />
                      {selectVendor !== null ? (
                        <button
                          style={{
                            position: "absolute",
                            top: "6px",
                            right: "8px",
                            borderRadius: "100%",
                            border: "1px solid #ced4da",
                            height: "25px",
                            width: "25px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onClick={() => {
                            setSelectVendor(null);
                            // setSelectVendorApi(null);
                            setCurrentPage(1);
                          }}
                        >
                          <i className="pi pi-times"></i>
                        </button>
                      ) : null}
                    </div>
                  )}
                  {/* <div
                    style={{
                      margin: "0 2px",
                      width: "160px",
                      position: "relative",
                    }}
                  >
                    <Dropdown
                      style={{
                        width: "100%",
                      }}
                      value={selectPaymentStatus}
                      options={paymentStatusArr}
                      onChange={onPaymentStatusChange}
                      optionLabel="paymentStatus"
                      placeholder="Payment Status"
                    />
                    {selectPaymentStatus !== null ? (
                      <button
                        style={{
                          position: "absolute",
                          top: "6px",
                          right: "8px",
                          borderRadius: "100%",
                          border: "1px solid #ced4da",
                          height: "25px",
                          width: "25px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={() => {
                          setSelectPaymentStatus(null);
                          setSelectPaymentStatusApi(null);
                          setCurrentPage(1);
                        }}
                      >
                        <i className="pi pi-times"></i>
                      </button>
                    ) : null}
                  </div> */}
                  <div
                    style={{
                      margin: "0 2px",
                      width: "160px",
                      position: "relative",
                    }}
                  >
                    <Dropdown
                      style={{
                        width: "100%",
                      }}
                      value={selectPaymentMethod}
                      options={paymentMethods}
                      onChange={onPaymentMethodChange}
                      optionLabel="methodName"
                      placeholder="Payment Method"
                      filter
                      showClear
                    />
                    {selectPaymentMethod !== null ? (
                      <button
                        style={{
                          position: "absolute",
                          top: "6px",
                          right: "8px",
                          borderRadius: "100%",
                          border: "1px solid #ced4da",
                          height: "25px",
                          width: "25px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={() => {
                          setSelectPaymentMethod(null);
                          // setSelectPaymentMethodApi(null);
                          setCurrentPage(1);
                        }}
                      >
                        <i className="pi pi-times"></i>
                      </button>
                    ) : null}
                  </div>
                  <div style={{ position: "relative" }}>
                    <Button
                      onClick={() => {
                        setFilterOrder(true);
                        setCurrentPage(1);
                      }}
                      style={{ marginLeft: "3px" }}
                      label="Search"
                    // disabled={startDate?.getTime() > endDate?.getTime()}
                    />
                    {/* {startDate?.getTime() > endDate?.getTime() ? (
                      <>
                        <div
                          style={{
                            position: "absolute",
                            width: "230px",
                            background: "#A596F1",
                            color: "#fff",
                            padding: "4px",
                            left: "-165px",
                            top: "-29px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Start Date Should Be Older Than End Date
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            height: "10px",
                            width: "10px",
                            background: "#A596F1",
                            transform: "rotate(45deg)",
                            top: "-13px",
                            right: "27px",
                          }}
                        ></div>
                      </>
                    ) : null} */}
                  </div>
                </div>
              </div>

              {order_filter_tab === "refund" ? (
                <div className="card">
                  {refunded_loading ? (
                    <LoadingCard count={1} />
                  ) : (
                    <>
                      <DataTable
                        className="p-datatable-customers p-datatable-responsive-demo"
                        value={refunded_data?.data}
                        rowHover
                        // globalFilter={globalFilter}
                        emptyMessage="No order(s) found"
                        selection={selectedOrders}
                        onSelectionChange={(e) => setSelectedOrders(e.value)}
                      >
                        {order_filter_tab !== "refund" && (
                          <Column selectionMode="multiple" />
                        )}
                        <Column
                          field="refundTranNo"
                          filterField="refundTranNo"
                          header="Transaction No"
                          sortable
                        />
                        <Column
                          field="refundDate"
                          filterField="refundDate"
                          header="Transaction Date"
                          body={refundTateBodyTemplate}
                          sortable
                        />
                        <Column
                          field="orderInvoiceNo"
                          filterField="orderInvoiceNo"
                          header="Order No"
                          sortable
                        />
                        <Column
                          field="customerName"
                          filterField="customerName"
                          header="Customer"
                          sortable
                        />
                        <Column
                          field="methodName"
                          filterField="methodName"
                          header="Payment Method"
                          sortable
                        />
                        <Column
                          field="accountNo"
                          filterField="accountNo"
                          header="Account No."
                          sortable
                        />
                        <Column
                          header="Amount"
                          body={refundTotalBodyTateBodyTemplate}
                        />
                        <Column
                          header="Action"
                          body={refundDetailsBodyTemplate}
                        />
                      </DataTable>
                      <Paginator
                        totalPage={totalPage}
                        currentPage={currentPage}
                        itemPerPage={itemPerPage}
                        totalItems={totalItems}
                        items={refunded_data?.data}
                        itemsPerPageOptions={[30, 60, 90, 100, 200, 300]}
                        handleItemPerPage={handleItemPerPage}
                        handleCurrentPage={handleCurrentPage}
                      />
                    </>
                  )}
                </div>
              ) : (
                <div className="card">
                  {loading ? (
                    <LoadingCard count={1} />
                  ) : (
                    <>
                      <DataTable
                        className="p-datatable-customers p-datatable-responsive-demo"
                        value={cancel_orders?.data}
                        // value={order_list}
                        rowHover
                        // globalFilter={globalFilter}
                        emptyMessage="No order(s) found"
                        selection={selectedOrders}
                        onSelectionChange={(e) => setSelectedOrders(e.value)}
                      >
                        <Column selectionMode="multiple" />
                        <Column
                          field="cancelInvoiceNo"
                          filterField="cancelInvoiceNo"
                          header="Cancel & Order Invoice No."
                          body={cancelInvoiceNoBodyTemplate}
                          sortable
                        />
                        <Column
                          field="cancelDate"
                          filterField="cancelDate"
                          header="Cancel Date"
                          body={cancelOrderDateTemplate}
                          sortable
                        />
                        {/* <Column
                          field='orderInvoiceNo'
                          filterField='orderInvoiceNo'
                          header='Invoice No'
                          body={orderNoBodyTemplate}
                          sortable
                        /> */}
                        <Column
                          field="customerName"
                          filterField="customerName"
                          header="Customer Name"
                          body={customerNameBodyTemplate}
                          sortable
                        />
                        <Column
                          field="shopName"
                          filterField="shopName"
                          header="Shop Name"
                          body={shopNameBodyTemplate}
                          sortable
                        />
                        <Column
                          field="paymentMethodName"
                          filterField="paymentMethodName"
                          header="Payment Method"
                          body={paymentMethodTemplate}
                          sortable
                        />
                        <Column
                          field="paymentStatus"
                          filterField="paymentStatus"
                          header="Payment Status"
                          body={paymentStatusTemplate}
                          sortable
                        />
                        <Column
                          field="totalAmount"
                          filterField="totalAmount"
                          header="Amount"
                          body={productAmountBodyTemplate}
                          sortable
                        />
                        <Column header="Action" body={productDetailsTemplate} />
                      </DataTable>
                      <Paginator
                        totalPage={totalPage}
                        currentPage={currentPage}
                        itemPerPage={itemPerPage}
                        totalItems={totalItems}
                        items={cancel_orders?.data}
                        itemsPerPageOptions={[30, 60, 90, 100, 200, 300]}
                        handleItemPerPage={handleItemPerPage}
                        handleCurrentPage={handleCurrentPage}
                      />
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Cancel Order Details"
        centered
        open={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width={1500}
      >
        <FetchCancelOrderTrackingAdmin
          orderInfo={orderInfo}
          setVisible={setVisible}
          counts={counts}
          setCounts={setCounts}
          order_filter_tab={order_filter_tab}
          page={history.location.pathname}
          setStatusId={setStatusId}
          setStatusName={setStatusName}
          statusId={statusId}
          statusName={statusName}
        />
      </Modal>
      <Modal
        title="Refund Cancel Orders"
        centered
        open={refundVisible}
        onOk={() => setRefundVisible(false)}
        onCancel={() => setRefundVisible(false)}
        width={1500}
      >
        <CancelRefundModal
          orderInfo={orderInfo}
          counts={counts}
          setCounts={setCounts}
          setRefundVisible={setRefundVisible}
        />
      </Modal>
      <Modal
        title="Refunded Cancel Orders"
        centered
        open={refundedVisible}
        onOk={() => setRefundedVisible(false)}
        onCancel={() => setRefundedVisible(false)}
        width={1500}
      >
        <RefundedOrdersModal
          orderInfo={orderInfo}
          totalRefundAmount={totalRefundAmount}
        />
      </Modal>

      <ToastContainer autoClose={1500} />
    </div>
  );
}
