/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from "react-router-dom";
import LoadingCard from "../../../components/shared/LoadingCard";
import Message from "../../../components/shared/Message";
import { getAllCityRecord, getAreaRecord } from "../../../store/actions/addressAction";
import httpClient from "../../../utils/httpClient";
import baseUrl from "../../../utils/baseUrl";
import { toast, ToastContainer } from "react-toastify";
import { Switch } from "antd";
import { Dropdown } from "primereact/dropdown";
import { FilterMatchMode } from 'primereact/api';

const AreaListContainer = () => {
    const dispatch = useDispatch();
    const navigateTo = useHistory();
    const [globalFilter, setGlobalFilter] = useState('');  // For area search (input field)
    const [selectCity, setSelectCity] = useState(null);    // For city selection (dropdown)
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        cityName: { value: null, matchMode: FilterMatchMode.EQUALS },
        areaName: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });

    const { areas: allAreas, loading: areaLoading, success: areaSuccess, error } = useSelector((state) => state?.addressReducer);
    const { allCities, loading: cityLoading, success: citySuccess } = useSelector((state) => state?.addressReducer);

    const handleGlobalFilter = (e) => {
        setGlobalFilter(e.target.value);  // Keep updating the area input value
    };

    const handleCityFilter = (city) => {
        setSelectCity(city);  // Set selected city
    };

   
    const applyFilters = () => {
        // Update filters state with both area and city filters
        setFilters({
            global: { value: globalFilter, matchMode: FilterMatchMode.CONTAINS },  // Search area by 'contains'
            areaName: { value: globalFilter, matchMode: FilterMatchMode.CONTAINS }, // Filter area name
            cityName: { value: selectCity?.cityName || null, matchMode: FilterMatchMode.EQUALS } // Filter city by 'equals'
        });
    };

    const resetCityFilter = () => {
        setSelectCity(null);  // Reset city dropdown
        setFilters(prevFilters => ({
            ...prevFilters,
            cityName: { value: null, matchMode: FilterMatchMode.EQUALS } // Remove city filter
        }));
    };

    useEffect(() => {
        dispatch(getAllCityRecord(19)); 
        dispatch(getAreaRecord());      
    }, [dispatch]);

    const handleStatus = async (rowData) => {
        try {
            const res = await httpClient.post(`${baseUrl}/api/DivisionCitiesArea/CreateOrUpdateArea`, rowData);
            if (res?.data?.succeed) {
                toast.success(res?.data?.message);
                setTimeout(() => {
                    dispatch(getAreaRecord());
                }, 2000);
            } else {
                toast.error(res?.data?.message);
            }
        } catch (error) {
            console.log({ error });
        }
    };

    const leftToolbarTemplate = () => (
        <React.Fragment>
            <div className="p-text-bold table-heading-style">All Area List</div>
        </React.Fragment>
    );

    const rightToolbarTemplate = () => (
        <React.Fragment>
            <Link to="/CreateArea">
                <div className="button-demo">
                    <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-outlined" />
                </div>
            </Link>
        </React.Fragment>
    );

    const onIndexTemplate = (rowData, props) => props.rowIndex + 1;

    const changeStatusBodyTemplate = (rowData) => (
        <Switch
            style={{ backgroundColor: rowData?.status === 1 ? '#1f5da0' : '' }}
            checked={rowData?.status === 'Y'}
            onClick={() => handleStatus({ ...rowData, status: rowData?.status === 'Y' ? 'N' : 'Y' })}
        />
    );

    const editActionBodyTemplate = (rowData) => (
        <React.Fragment>
            <Button
                onClick={() => {
                    navigateTo.push({
                        pathname: "/EditArea",
                        state: { data: rowData },
                    });
                }}
                icon="pi pi-pencil"
                className="p-button-rounded p-button-success p-mr-2"
            />
        </React.Fragment>
    );

    const renderHeader = () => (
        <div className="table-header">
            <div className="flex flex-column">
                <div style={{ width: "220px", position: "relative" }}>
                    <InputText
                        style={{ width: "100%" }}
                        className="manage_product__search"
                        onChange={handleGlobalFilter}  // Update input for area search
                        placeholder="Search by Area"
                        value={globalFilter}
                    />
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "0px 20px" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ margin: "0 2px", width: "230px", position: "relative" }}>
                            <Dropdown
                                style={{ width: "100%", height: "36.7px" }}
                                value={selectCity}
                                options={allCities}
                                onChange={(e) => handleCityFilter(e.value)}  // Handle city selection
                                optionLabel="cityName"
                                placeholder="Select City"
                                showClear
                            />
                            {selectCity !== null && (
                                <button
                                    style={{
                                        position: "absolute",
                                        top: "6px",
                                        right: "8px",
                                        borderRadius: "100%",
                                        border: "1px solid #ced4da",
                                        height: "25px",
                                        width: "25px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                    onClick={resetCityFilter}  // Clear city filter only
                                >
                                    <i className="pi pi-times"></i>
                                </button>
                            )}
                        </div>
                        <Button
                            onClick={applyFilters}  // Trigger search on button click (both area and city)
                            style={{ marginLeft: "3px" }}
                            label="Search"
                        />
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <>
            <div className="page-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                                <div className="datatable-doc-demo datatable-responsive-demo">
                                    <div className="card">
                                        <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                                        {areaLoading ? (
                                            <LoadingCard count={1} />
                                        ) : error ? (
                                            <Message variant="danger">{error}</Message>
                                        ) : (
                                            <DataTable
                                                header={renderHeader}
                                                value={allAreas}
                                                className="p-datatable-customers p-datatable-responsive-demo"
                                                dataKey="id"
                                                rowHover
                                                paginator
                                                rows={10}
                                                rowsPerPageOptions={[10, 25, 50]}
                                                emptyMessage="No Area Found"
                                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                                loading={areaLoading}
                                                filters={filters}  // Apply combined filters
                                            >
                                                <Column field="Index" header="SN" body={onIndexTemplate} />
                                                <Column sortField="areaName" filterField="areaName" header="Area" field="areaName" sortable />
                                                <Column sortField='cityName' filterField="cityName" header='City Name' field="cityName" sortable />
                                                <Column sortField="status" header="Status" body={changeStatusBodyTemplate} sortable />
                                                <Column field="action" header="Edit" body={editActionBodyTemplate} />
                                            </DataTable>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer autoClose={1500} />
        </>
    );
};

export default AreaListContainer;
