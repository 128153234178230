import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { updateProductRecord, updateProductReset } from '../../store/actions/productAction';
import LoadingCard from '../shared/LoadingCard';
import CampaignPriceAndStock from './CampaignPriceAndStock';

const ProductPriceStockUpdateModal = ({ getProductDetails, loading, isAdmin }) => {

    const dispatch = useDispatch();

    const [productId, setProductId] = useState('');
    const [shopId, setShopId] = useState(null);
    const [selectedShopId, setSelectedShopId] = useState(null);
    const [shopName, setShopName] = useState(null);
    const [categoryId, setCategoryId] = useState(null);
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);
    const [categoryName, setCategoryName] = useState(null);
    const [brandId, setBrandId] = useState(null);
    const [selectedBrandId, setSelectedBrandId] = useState(null);
    const [brandName, setBrandName] = useState(null);
    const [unitId, setUnitId] = useState(null);
    const [selectedUnitId, setSelectedUnitId] = useState(null);
    const [unitName, setUnitName] = useState(null);
    const [productName, setProductName] = useState("");
    const [sku, setSku] = useState("");
    const [productVideoUrl, setProductVideoUrl] = useState("");
    const [productStatus, setProductStatus] = useState("Y");
    const [warrantyTypeId, setWarrantyTypeId] = useState(0);
    const [warrantyPeriodId, setWarrantyPeriodId] = useState(0);
    const [warrantyPolicy, setWarrantyPolicy] = useState("");
    const [packageWeight, setPackageWeight] = useState("");
    const [packageLength, setPackageLength] = useState("");
    const [packageWidth, setPackageWidth] = useState("");
    const [packageHeight, setPackageHeight] = useState("");
    const [boxInsideElement, setBoxInsideElement] = useState("");
    const [maxPrice, setMaxPrice] = useState(0);
    const [commissionPercentage, setCommissionPercentage] = useState(0);
    const [metaKeywords, setMetaKeywords] = useState("");
    const [thumbnailImage, setThumbnailImage] = useState("");
    const [thumbnailImage2, setThumbnailImage2] = useState("");
    const [productDescription, setProductDescription] = useState("");
    const [productSpecification, setProductSpecification] = useState("");
    const [isReturnable, setIsReturnable] = useState(null);
    const [returnDuration, setReturnDuration] = useState("");
    const [returnPolicy, setReturnPolicy] = useState("");
    const [productDetails, setProductDetails] = useState("");
    const [productUrl, setProductUrl] = useState("");
    const [remarks, setRemarks] = useState("");
    const [selectedAttributes, setSelectedAttributes] = useState([]);

    const [selectedSizeChart, setSelectedSizeChart] = useState({});
    const [isShopChanged, setIsShopChanged] = useState(false);
    const [sellerCommissionPercentage, setSellerCommissionPercentage] = useState(0);
    const [isDpPriceFixManual, setIsDpPriceFixManual] = useState('');
    const [hasEMI, setHasEMI] = useState('');

    const [productVariantMap0, setProductVariantMap0] = useState([]);
    const [productVariantMap1, setProductVariantMap1] = useState([]);
    const [productVariantMap2, setProductVariantMap2] = useState([]);
    const [productVariantMap3, setProductVariantMap3] = useState([]);

    const [productVariantMapArray0, setProductVariantMapArray0] = useState([]);
    const [productVariantMapArray1, setProductVariantMapArray1] = useState([]);
    const [productVariantMapArray2, setProductVariantMapArray2] = useState([]);
    const [productVariantMapArray3, setProductVariantMapArray3] = useState([]);

    const [productVariantAdded, setProductVariantAdded] = useState(null);
    const [combineVariantImages, setCombineVariantImages] = useState(null);
    const [allProductVariants, setAllProductVariants] = useState(null);

    const [newProductDetails, setNewProductDetails] = useState("");
    const [newCategoryId, setNewCategoryId] = useState(null);
    const [visibleDialog, setVisibleDialog] = useState(false);

    const [variants, setVariants] = useState([]);

    const productVariant = useSelector(
        (state) => state.productVariantReducer.variantsWithCategoryId
    );

    const { updating, updatedData } = useSelector((state) => state?.productReducer);

    useEffect(() => {
        if (updatedData?.data?.message === "success") {
            toast.success("Product Updated Successfully");
            dispatch(updateProductReset());
            //   setTimeout(() => {
            //     history.push(navigateBackUrl, {
            //       state: {
            //         ...props?.location?.state,
            //         currentPage,
            //         itemPerPage,
            //       },
            //     });
            //   }, 1000);
        } else if (updatedData?.data?.errors?.length === 0) {
            toast.error("Something went wrong, Please try again");
        }
    }, [dispatch, updatedData]);


    useEffect(() => {
        let data = getProductDetails?.data;
        data?.sizeCharts && setSelectedSizeChart(data?.sizeCharts[0])
        setShopId(data?.shopId);
        setShopName(data?.shopName);
        setCategoryId(data?.categoryId);
        setNewCategoryId(data?.categoryId);
        setCategoryName(data?.categoryName);
        setBrandId(data?.brandId);
        setBrandName(data?.brandName);
        setBoxInsideElement(data?.boxInsideElement);
        setMaxPrice(data?.maxPrice);
        setMetaKeywords(data?.metaKeywords);
        setPackageHeight(data?.packageHeight);
        setPackageLength(data?.packageLength);
        setPackageWeight(data?.packageWeight);
        setPackageWidth(data?.packageWidth);
        setProductDescription(data?.productDescription);
        setProductDetails(data?.productDetails);
        setProductId(data?.productId);
        setProductName(data?.productName);
        setProductSpecification(data?.productSpecification);
        setProductStatus(data?.productStatus);
        setSku(data?.sku);
        setProductUrl(data?.productUrl);
        setProductVideoUrl(data?.productVideoUrl);
        setThumbnailImage(data?.thumbnailImage);
        setThumbnailImage2(data?.thumbnailImage2);
        setUnitId(data?.unitId);
        setUnitName(data?.unitId === "1" ? "PCS" : "KG");
        setWarrantyPeriodId(data?.warrantyPeriodId);
        setWarrantyPolicy(data?.warrantyPolicy);
        setWarrantyTypeId(data?.warrantyTypeId);
        setHasEMI(data?.hasEmi === "Y" ? true : false);
        setIsReturnable(data?.isReturnable === "Y" ? true : false);
        setReturnDuration(data?.returnDuration);
        setReturnPolicy(data?.returnPolicy);
        setRemarks(data?.remarks ? data?.remarks : "");
        setSelectedAttributes(
            data?.productAttributes
                ?.map((att) =>
                    att?.productAttributes?.map((att_item) => ({
                        filterName: att?.variantName,
                        label: att_item?.variantName,
                        value: att_item?.variantOptionId,
                    }))
                )
                .flatMap((att) => [...att])
        );
    }, [getProductDetails]);

    const newVariantsCalculation = (dataAdded) => {
        const details = [];
        const variants = [];

        dataAdded.forEach((item) => {
            details.push({
                productDetailsId: 0,
                productId: Number(productId),
                shopId: Number(shopId),
                productPrice: Number(item.productPrice),
                productQuantity: Number(item.productQuantity),
                sellerProductSku: item.sellerProductSku,
                inventoryTypeId: Number(item.inventoryTypeId),
                shopProductSku: item.shopProductSku,
                productDetailsNote: "PROD DETAILS",
                productDetailsStatus: "Y",
                isAvailable: "Y",
                commissionPercentage: commissionPercentage,
                commissionAmount:
                    (item.productPrice * 1 * commissionPercentage) / 100,
                discountPercentage: item.discountPercentage
                    ? Number(item.discountPercentage)
                    : 0,
                discountAmount: item.discountAmount ? Number(item.discountAmount) : 0,
                discountStartDate: item.discountStartDate,
                discountEndDate: item.discountEndDate,
                dpPricePercentage: item.dpPercentage
                    ? Number(item.dpPercentage)
                    : 0,
                dpPrice: item.dpAmount ? Number(item.dpAmount) : 0,
                burnAmountPercentage: item.burnPercentage
                    ? Number(item.burnPercentage)
                    : 0,
                burnAmount: item.burnAmount ? Number(item.burnAmount) : 0,
                burnStartDate: item.burnStartDate,
                burnEndDate: item.burnEndDate,
                isDpPriceFixManual: item.isDpPriceFixManual,
            });

            const variantOptionId0 = item.hasOwnProperty("variantOptionId0");
            const variantOptionId1 = item.hasOwnProperty("variantOptionId1");
            const variantOptionId2 = item.hasOwnProperty("variantOptionId2");
            const variantOptionId3 = item.hasOwnProperty("variantOptionId3");

            if (
                variantOptionId0 &&
                !variantOptionId1 &&
                !variantOptionId2 &&
                !variantOptionId3
            ) {
                variants.push({
                    variationWiseProductId: 0,
                    variantOptionId0: Number(item.variantOptionId0),
                    sellerProductSku: item.sellerProductSku,
                    shopProductSku: item.shopProductSku,
                    productId: Number(productId),
                    shopId: Number(shopId),
                    status: "Y",
                });
            }

            if (
                variantOptionId0 &&
                variantOptionId1 &&
                !variantOptionId2 &&
                !variantOptionId3
            ) {
                variants.push({
                    variationWiseProductId: 0,
                    variantOptionId0: Number(item.variantOptionId0),
                    variantOptionId1: Number(item.variantOptionId1),
                    sellerProductSku: item.sellerProductSku,
                    shopProductSku: item.shopProductSku,
                    productId: Number(productId),
                    shopId: Number(shopId),
                    status: "Y",
                });
            }

            if (
                variantOptionId0 &&
                variantOptionId1 &&
                variantOptionId2 &&
                !variantOptionId3
            ) {
                variants.push({
                    variationWiseProductId: 0,
                    variantOptionId0: item.variantOptionId0 * 0,
                    variantOptionId1: item.variantOptionId1 * 1,
                    variantOptionId2: item.variantOptionId1 * 2,
                    sellerProductSku: item.sellerProductSku,
                    shopProductSku: item.shopProductSku,
                    productId: Number(productId),
                    shopId: Number(shopId),
                    status: "Y",
                });
            }

            if (
                variantOptionId0 &&
                variantOptionId1 &&
                variantOptionId2 &&
                variantOptionId3
            ) {
                variants.push({
                    variationWiseProductId: 0,
                    variantOptionId0: item.variantOptionId0 * 0,
                    variantOptionId1: item.variantOptionId1 * 1,
                    variantOptionId2: item.variantOptionId1 * 2,
                    variantOptionId3: item.variantOptionId1 * 3,
                    sellerProductSku: item.sellerProductSku,
                    shopProductSku: item.shopProductSku,
                    productId: Number(productId),
                    shopId: Number(shopId),
                    status: "Y",
                });
            }
        });

        const variantOptionId0 = variants
            .map((o) => o.variantOptionId0 && true)
            .includes(true);
        const variantOptionId1 = variants
            .map((o) => o.variantOptionId1 && true)
            .includes(true);
        const variantOptionId2 = variants
            .map((o) => o.variantOptionId2 && true)
            .includes(true);
        const variantOptionId3 = variants
            .map((o) => o.variantOptionId3 && true)
            .includes(true);

        const multiVariants = [];
        const variantCombined = [];

        if (
            variantOptionId0 &&
            !variantOptionId1 &&
            !variantOptionId2 &&
            !variantOptionId3
        ) {
            variants.forEach((item) => {
                multiVariants.push({
                    variationWiseProductId: item.variationWiseProductId,
                    variantOptions: [{ variantOptionId: item.variantOptionId0 }],
                    sellerProductSku: item.sellerProductSku,
                    shopProductSku: item.shopProductSku,
                    productId: Number(productId),
                    shopId: Number(shopId),
                    status: "Y",
                });
            });
            const flatten = (array) =>
                array.reduce(
                    (results, item) => [
                        ...results,
                        ...item.variantOptions.map((data) => ({
                            variationWiseProductId: item.variationWiseProductId,
                            id: data,
                            sellerProductSku: item.sellerProductSku,
                            shopProductSku: item.shopProductSku,
                            productId: Number(productId),
                            shopId: Number(shopId),
                            status: "Y",
                        })),
                    ],
                    []
                );

            flatten(multiVariants).map((item) => {
                variantCombined.push({
                    variationWiseProductId: item.variationWiseProductId,
                    variantOptionId: item.id.variantOptionId,
                    sellerProductSku: item.sellerProductSku,
                    shopProductSku: item.shopProductSku,
                    productId: Number(productId),
                    shopId: Number(shopId),
                    status: "Y",
                });
            });
        }

        if (
            variantOptionId0 &&
            variantOptionId1 &&
            !variantOptionId2 &&
            !variantOptionId3
        ) {
            variants.forEach((item) => {
                multiVariants.push({
                    variationWiseProductId: item.variationWiseProductId,
                    variantOptions: [
                        { variantOptionId: item.variantOptionId0 },
                        { variantOptionId: item.variantOptionId1 },
                    ],
                    sellerProductSku: item.sellerProductSku,
                    shopProductSku: item.shopProductSku,
                    productId: Number(productId),
                    shopId: Number(shopId),
                    status: "Y",
                });
            });
            const flatten = (array) =>
                array.reduce(
                    (results, item) => [
                        ...results,
                        ...item.variantOptions.map((data) => ({
                            variationWiseProductId: item.variationWiseProductId,
                            id: data,
                            sellerProductSku: item.sellerProductSku,
                            shopProductSku: item.shopProductSku,
                            productId: Number(productId),
                            shopId: Number(shopId),
                            status: "Y",
                        })),
                    ],
                    []
                );

            flatten(multiVariants).map((item) => {
                variantCombined.push({
                    variationWiseProductId: item.variationWiseProductId,
                    variantOptionId: item.id.variantOptionId,
                    sellerProductSku: item.sellerProductSku,
                    shopProductSku: item.shopProductSku,
                    productId: Number(productId),
                    shopId: Number(shopId),
                    status: "Y",
                });
            });
        }

        if (
            variantOptionId0 &&
            variantOptionId1 &&
            variantOptionId2 &&
            !variantOptionId3
        ) {
            variants.forEach((item) => {
                multiVariants.push({
                    variationWiseProductId: item.variationWiseProductId,
                    variantOptions: [
                        { variantOptionId: item.variantOptionId0 },
                        { variantOptionId: item.variantOptionId1 },
                        { variantOptionId: item.variantOptionId2 },
                    ],
                    sellerProductSku: item.sellerProductSku,
                    shopProductSku: item.shopProductSku,
                    productId: Number(productId),
                    shopId: Number(shopId),
                    status: "Y",
                });
            });
            const flatten = (array) =>
                array.reduce(
                    (results, item) => [
                        ...results,
                        ...item.variantOptions.map((data) => ({
                            id: data,
                            variationWiseProductId: item.variationWiseProductId,
                            sellerProductSku: item.sellerProductSku,
                            shopProductSku: item.shopProductSku,
                            productId: Number(productId),
                            shopId: Number(shopId),
                            status: "Y",
                        })),
                    ],
                    []
                );

            flatten(multiVariants).map((item) => {
                variantCombined.push({
                    variationWiseProductId: item.variationWiseProductId,
                    variantOptionId: item.id.variantOptionId,
                    sellerProductSku: item.sellerProductSku,
                    shopProductSku: item.shopProductSku,
                    productId: Number(productId),
                    shopId: Number(shopId),
                    status: "Y",
                });
            });
        }

        if (
            variantOptionId0 &&
            variantOptionId1 &&
            variantOptionId2 &&
            variantOptionId3
        ) {
            variants.forEach((item) => {
                multiVariants.push({
                    variationWiseProductId: item.variationWiseProductId,
                    variantOptions: [
                        { variantOptionId: item.variantOptionId0 },
                        { variantOptionId: item.variantOptionId1 },
                        { variantOptionId: item.variantOptionId2 },
                        { variantOptionId: item.variantOptionId3 },
                    ],
                    sellerProductSku: item.sellerProductSku,
                    shopProductSku: item.shopProductSku,
                    productId: Number(productId),
                    shopId: Number(shopId),
                    status: "Y",
                });
            });
            const flatten = (array) =>
                array.reduce(
                    (results, item) => [
                        ...results,
                        ...item.variantOptions.map((data) => ({
                            id: data,
                            variationWiseProductId: item.variationWiseProductId,
                            sellerProductSku: item.sellerProductSku,
                            shopProductSku: item.shopProductSku,
                            productId: Number(productId),
                            shopId: Number(shopId),
                            status: "Y",
                        })),
                    ],
                    []
                );

            flatten(multiVariants).map((item) => {
                variantCombined.push({
                    variationWiseProductId: item.variationWiseProductId,
                    variantOptionId: item.id.variantOptionId,
                    sellerProductSku: item.sellerProductSku,
                    shopProductSku: item.shopProductSku,
                    productId: Number(productId),
                    shopId: Number(shopId),
                    status: "Y",
                });
            });
        }

        const variantWithImage = allProductVariants.map((variant) => {
            return {
                ...variant,
                image: combineVariantImages.find(
                    (img) => img.variantOptionText === variant.variantOptionText0
                ),
            };
        });

        const flatten = (array) =>
            array.reduce(
                (results, item) => [
                    ...results,
                    ...item.image.images.map((data) => ({
                        variantId: item.variantId,
                        variantOptionId: item.variantOptionId0,
                        variantOptionText: item.variantOptionText0,
                        shopProductSku: item.shopProductSku,
                        image: data,
                        productId: Number(productId),
                        shopId: Number(shopId),
                    })),
                ],
                []
            );

        // variantImages create
        let variantImages = [];
        flatten(variantWithImage).map((item) => {
            variantImages.push({
                variantId: item.variantId,
                variantOptionId: item.variantOptionId,
                variantOptionText: item.variantOptionText,
                shopProductSku: item.shopProductSku,
                imageSeoName: item.image.name,
                imageUrl: item.image.path,
                productImageDetails: "productImageDetails",
                status: "Y",
                productId: Number(productId),
                shopId: Number(shopId),
            });
        });
        const productDetailsCreated = [...details];
        const newVariationWiseProducts = [...variantCombined];
        for (var i = 0; i < productDetailsCreated.length; i++) {
            for (var j = 0; j < selectedAttributes.length; j++) {
                const variant = {
                    variantOptionId: selectedAttributes[j].value,
                    sellerProductSku: productDetailsCreated[i]?.sellerProductSku,
                    shopProductSku: productDetailsCreated[i]?.shopProductSku,
                    productId: productId,
                    shopId: shopId,
                    status: "Y",
                };
                newVariationWiseProducts.push(variant);
            }
        }
        const newProductImages = [...variantImages];

        const modifiedNew = { productDetailsCreated, newProductImages, newVariationWiseProducts }

        return modifiedNew
    }

    const updateProduct = async (e) => {
        if (productName === "") {
            let msg = "Product Name is required!!!";
            toast.error(msg);
            return;
        }
        if (sku === "") {
            let msg = "Product SKU/Style is required!!!";
            toast.error(msg);
            setTimeout(() => { }, 3000);
            return;
        }
        if (newCategoryId === null) {
            let msg = "Category is required!!!";
            toast.error(msg);
            setTimeout(() => { }, 3000);
            return;
        }
        if (brandId === null) {
            let msg = "Brand is required!!!";
            toast.error(msg);
            setTimeout(() => { }, 3000);
            return;
        }
        if (unitId === "") {
            let msg = "Unit is required!!!";
            toast.error(msg);
            setTimeout(() => { }, 3000);
            return;
        }
        if (maxPrice === "") {
            let msg = "Maximum Price is required!!!";
            toast.error(msg);
            setTimeout(() => { }, 3000);
            return;
        }
        if (boxInsideElement === "") {
            let msg = "Box Inside Element is required!!!";
            toast.error(msg);
            setTimeout(() => { }, 3000);
            return;
        }
        if (metaKeywords === "") {
            let msg = "Meta Keywords is required!!!";
            toast.error(msg);
            setTimeout(() => { }, 3000);
            return;
        }
        if (thumbnailImage === "") {
            let msg = "Product Image is required!!!";
            toast.error(msg);
            setTimeout(() => { }, 3000);
            return;
        }
        if (productDescription === "") {
            let msg = "Product Description is required!!!";
            toast.error(msg);
            setTimeout(() => { }, 3000);
            return;
        }
        if (warrantyPolicy === "") {
            let msg = "Warranty Policy is required!!!";
            toast.error(msg);
            setTimeout(() => { }, 3000);
            return;
        }
        if (packageWeight === "") {
            let msg = "Package Weight is required!!!";
            toast.error(msg);
            setTimeout(() => { }, 3000);
            return;
        }
        if (packageLength === "") {
            let msg = "Package Length is required!!!";
            toast.error(msg);
            setTimeout(() => { }, 3000);
            return;
        }
        if (packageWidth === "") {
            let msg = "Package Width is required!!!";
            toast.error(msg);
            setTimeout(() => { }, 3000);
            return;
        }
        if (packageHeight === "") {
            let msg = "Package Height is required!!!";
            toast.error(msg);
            setTimeout(() => { }, 3000);
            return;
        }

        let details = [];
        let variants = [];
        let images = [];

        if (newProductDetails.length != 0) {
            const calculated = newVariantsCalculation(newProductDetails)
            const { productDetailsCreated, newProductImages, newVariationWiseProducts } = calculated

            details = [...productDetailsCreated];
            variants = [...newVariationWiseProducts];
            images = [...newProductImages];
        }

        if (productDetails) {
            productDetails.forEach((items) => {
                details.push({
                    productDetailsId: 0,
                    productId: Number(productId),
                    shopId: Number(shopId),
                    productPrice: Number(items.productPrice),
                    baseCurrency: "BDT",
                    productQuantity: Number(items.productQuantity),
                    sellerProductSku: items.sellerProductSku,
                    inventoryTypeId: Number(items.inventoryTypeId),
                    shopProductSku: items.shopProductSku,
                    productDetailsNote: "PROD DETAILS",
                    productDetailsStatus: "Y",
                    isAvailable: "Y",
                    commissionPercentage: commissionPercentage,
                    commissionAmount: Number(
                        (items.productPrice * 1 * commissionPercentage) / 100
                    ),
                    discountAmount: items.discountPercentage
                        ? 0
                        : items.discountAmount
                            ? Number(items.discountAmount)
                            : 0,
                    discountPercentage: items.discountPercentage
                        ? Number(items.discountPercentage)
                        : 0,
                    discountStartDate: items.discountStartDate,
                    discountEndDate: items.discountEndDate,
                    dpPrice: items.dpPricePercentage
                        ? 0
                        : items.dpPrice
                            ? Number(items.dpPrice)
                            : 0,
                    dpPricePercentage: items.dpPricePercentage
                        ? Number(items.dpPricePercentage)
                        : 0,
                    burnAmount: items.burnAmountPercentage
                        ? 0
                        : items.burnAmount
                            ? Number(items.burnAmount)
                            : 0,
                    burnAmountPercentage: items.burnAmountPercentage
                        ? Number(items.burnAmountPercentage)
                        : 0,
                    burnStartDate: items.burnStartDate,
                    burnEndDate: items.burnEndDate,
                    isDpPriceFixManual: items.isDpPriceFixManual ? items.isDpPriceFixManual : 'M',
                });

                items.productVariants.forEach((item) => {
                    variants.push({
                        variationWiseProductId: item.variationWiseProductId,
                        variantOptionId: item.variantOptionId,
                        sellerProductSku: items.sellerProductSku,
                        shopProductSku: item.shopProductSku,
                        productId: Number(productId),
                        shopId: Number(shopId),
                        status: item.status,
                        productVariantId: item.productVariantId,
                        variantOptionText: item.variantOptionText,
                        variantName: item.variantName,
                    });
                });

                items.productImages.forEach((item) => {
                    images.push({
                        productImageId: item.productImageId,
                        variantId: item.variantId,
                        variantOptionId: item.variantOptionId,
                        imageUrl: item.imageUrl,
                        imageSeoName: item.imageSeoName,
                        productImageDetails: item.productImageDetails,
                        productId: Number(productId),
                        shopId: Number(shopId),
                        status: item.status,
                        shopProductSku: item.shopProductSku,
                    });
                });
            });
        }


        const newVariantArr = [];

        variants &&
            variants.forEach((v_item) =>
                selectedAttributes.forEach((s_atr_item) =>
                    newVariantArr.push({
                        variationWiseProductId: v_item?.variationWiseProductId,
                        sellerProductSku: v_item?.sellerProductSku,
                        shopProductSku: v_item?.shopProductSku,
                        productId: Number(productId),
                        shopId: Number(shopId),
                        status: v_item?.status,
                        productVariantId: v_item?.productVariantId,

                        variantOptionId: s_atr_item?.value,
                        variantOptionText: s_atr_item?.label,
                        variantName: s_atr_item?.filterName,
                    })
                )
            );

        newVariantArr.push(...variants);

        if (details?.length === 0) {
            let msg = "There is no variant found. Please Add At least one.!!!";
            toast.error(msg);
            setTimeout(() => { }, 3000);
            return;
        }

        let UpdatedData = {
            productId: productId,
            shopId: shopId,
            baseCurrency: "BDT",
            productName: productName,
            productTitle: productName?.slice(0, 50),
            sku: sku,
            productUrl: productUrl,
            categoryId: newCategoryId,
            productVideoUrl: productVideoUrl,
            productDescription: productDescription,
            productSpecification: productSpecification,
            boxInsideElement: boxInsideElement,
            brandId: brandId,
            unitId: unitId,
            warrantyTypeId: warrantyTypeId,
            warrantyPeriodId: warrantyPeriodId,
            warrantyPolicy: warrantyPolicy,
            packageWeight: Number(packageWeight),
            packageLength: Number(packageLength),
            packageWidth: Number(packageWidth),
            packageHeight: Number(packageHeight),
            productStatus: productStatus,
            maxPrice: Number(maxPrice),
            metaKeywords: metaKeywords,
            thumbnailImage: thumbnailImage,
            thumbnailImage2: thumbnailImage2,
            remarks: remarks,
            productDetails: details,
            variationWiseProducts: newVariantArr,
            productImages: images,
            hasEmi: hasEMI === true ? "Y" : "N",
            isReturnable: getProductDetails?.data?.shopId === shopId ? isReturnable === true ? "Y" : "N" : "N",
            returnDuration: getProductDetails?.data?.shopId === shopId ? Number(isReturnable === true ? returnDuration : 0) : 0,
            returnPolicy: getProductDetails?.data?.shopId === shopId ? isReturnable === true ? returnPolicy : "" : "",
            sizeChartId: selectedSizeChart?.sizeChartId ? selectedSizeChart?.sizeChartId : 0
        };

        dispatch(updateProductRecord(UpdatedData));
    };

    return (
        <div
            className="modal fade"
            id="manageProductPriceStock"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-xl" role="document" style={{ width: '85%' }}>
                <div className="modal-content">
                    <div className="panel-wrapper collapse in" aria-expanded="true">

                        <div className="form-body">
                            <h3 className="box-title add-product-title">
                                Manage Product Stock and Price
                                <div className="pull-right">
                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            </h3>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                {updating === false ? <button
                                    style={{ width: "32.4%" }}
                                    className="btn btn-info"
                                    onClick={updateProduct}
                                >
                                    Update
                                </button> : <div style={{ textAlign: "center" }}>
                                    Updating...
                                </div>}
                            </div>

                            {loading ? <LoadingCard count={1} /> :
                                <CampaignPriceAndStock
                                    productDetails={productDetails}
                                    setProductDetails={setProductDetails}
                                    isAdmin={isAdmin}
                                    sellerCommissionPercentage={sellerCommissionPercentage}
                                    setIsDpPriceFixManual={setIsDpPriceFixManual}
                                    isDpPriceFixManual={isDpPriceFixManual}
                                    productVariant={productVariant}
                                    productVariantMapArray0={productVariantMapArray0}
                                    productVariantMapArray1={productVariantMapArray1}
                                    productVariantMapArray2={productVariantMapArray2}
                                    productVariantMapArray3={productVariantMapArray3}

                                    selectedCategoryId={selectedCategoryId}
                                    productVariantAdded={productVariantAdded}
                                    setNewProductDetails={setNewProductDetails}
                                    setVariants={setVariants}
                                    variants={variants}
                                />
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductPriceStockUpdateModal