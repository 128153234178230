import React from "react";
import "../css/DateWiseSalesSummarySmallData.css";

const DateWiseSalesSummaryNBRSmallData = ({ arrayDatas }) => {
  return (
    <div className="report_DateWiseSalesSummary_small_data_container">
      <div style={{ borderBottom: "1px solid black" }}>
        <div className="report_DateWiseSalesSummary_small_data_container_items_header">
          <div
            style={{
              width: "24%",
              textAlign: "left",
              paddingLeft: "10px",
              paddingBottom: "2px",
            }}
          >
            Sl. No.
          </div>
          <div
            style={{ width: "34%", textAlign: "center", paddingBottom: "2px" }}
          >
            Vendor Name
          </div>
          <div
            style={{ width: "14%", textAlign: "center", paddingBottom: "2px" }}
          >
            Total Payable Amount
          </div>
          <div
            style={{ width: "14%", textAlign: "center", paddingBottom: "2px" }}
          >
            Sales Commission
          </div>
          <div
            style={{ width: "14%", textAlign: "center", paddingBottom: "2px" }}
          >
            Net Payable Amount{" "}
          </div>
        </div>
        {arrayDatas &&
          arrayDatas?.map((info, i) => (
            <div className="report_DateWiseSalesSummary_small_data_container_items">
              {i + 1 !== arrayDatas?.length && (
                <div
                  style={{
                    width: "24%",
                    textAlign: "left",
                    paddingLeft: "10px",
                    paddingBottom: '2px'
                  }}
                >
                  {i + 1}
                </div>
              )}

              <div
                style={{
                  width: i + 1 !== arrayDatas?.length ? "34%" : "58%",
                  textAlign: "left",
                  fontWeight: i + 1 !== arrayDatas?.length ? "500" : "700",
                  paddingBottom: '2px'
                }}
              >
                {info?.shopName}
              </div>
              <div
                style={{
                  width: "14%",
                  textAlign: "right",
                  paddingRight: "5px",
                  fontWeight: i + 1 !== arrayDatas?.length ? "500" : "700",
                  paddingBottom: '2px'
                }}
              >
                {info?.totalPayableAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
              </div>
              <div
                style={{
                  width: "14%",
                  textAlign: "right",
                  paddingRight: "5px",
                  fontWeight: i + 1 !== arrayDatas?.length ? "500" : "700",
                  paddingBottom: '2px'
                }}
              >
                {info?.salesCommission.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
              </div>
              <div
                style={{
                  width: "14%",
                  textAlign: "right",
                  paddingRight: "5px",
                  fontWeight: i + 1 !== arrayDatas?.length ? "500" : "700",
                  paddingBottom: '2px'
                }}
              >
                {info?.netPayableAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
              </div>
            </div>
          ))}
      </div>
      <br />
    </div>
  );
};

export default DateWiseSalesSummaryNBRSmallData;
