import {
    CREATE_OR_UPDATE_OUTLET,
    CREATE_OR_UPDATE_OUTLET_ERROR,
    CREATE_OR_UPDATE_OUTLET_RESET,
    CREATE_OR_UPDATE_OUTLET_SUCCESS,

    GET_OUTLET,
    GET_OUTLET_ERROR,
    GET_OUTLET_SUCCESS,
} from "../actions/outletAction"

const initialState = {
    outletData: {},
    outletCreateOrUpdateData: {},

    outletDataLoading: false,
    outletDataLoaded: false,
    outletDataError: null,

    outletCreateOrUpdateDataLoading: false,
    outletCreateOrUpdateDataLoaded: false,
    outletCreateOrUpdateDataError: null,

}

export default function outletReducer(state = initialState, action) {
    switch (action.type) {

        case GET_OUTLET:
            return {
                ...state,
                outletDataLoading: true,
                outletDataLoaded: false,
                outletDataError: null,
            }

        case GET_OUTLET_SUCCESS:
            return {
                ...state,
                outletDataLoading: false,
                outletDataLoaded: true,
                outletData: action.payload.success,
            }

        case GET_OUTLET_ERROR:
            return {
                ...state,
                outletDataLoading: false,
                outletDataLoaded: false,
                outletDataError: action.payload.error,
                outletData: [],
            }

        case CREATE_OR_UPDATE_OUTLET:
            return {
                ...state,
                outletCreateOrUpdateDataLoading: true,
                outletCreateOrUpdateDataLoaded: false,
                outletCreateOrUpdateDataError: null,
            }

        case CREATE_OR_UPDATE_OUTLET_SUCCESS:
            return {
                ...state,
                outletCreateOrUpdateDataLoading: false,
                outletCreateOrUpdateDataLoaded: true,
                outletCreateOrUpdateData: action.payload.success,
            }

        case CREATE_OR_UPDATE_OUTLET_ERROR:
            return {
                ...state,
                outletCreateOrUpdateDataLoading: false,
                outletCreateOrUpdateDataLoaded: false,
                outletCreateOrUpdateDataError: action.payload.error,
                outletCreateOrUpdateData: [],
            }

        case CREATE_OR_UPDATE_OUTLET_RESET:
            return {
                ...state,
                outletCreateOrUpdateDataLoading: false,
                outletCreateOrUpdateDataLoaded: false,
                outletCreateOrUpdateDataError: null,
                outletCreateOrUpdateData: {},
            }


        default:
            return state
    }
}