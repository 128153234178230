/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { Switch } from "antd";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import MultiSelectShop from "../../component/molecules/MultiSelectShop";
import { getProductDetailsRecord } from "../../store/actions/productAction";
import authenticationService from "../../store/services/authenticationService";
import baseUrl from "../../utils/baseUrl";
import { Paginator } from "../paginator/Paginator";
import { ProductDetailsModal } from "../product/ProductDetailsModal";
import LoadingCard from "../shared/LoadingCard";


export const FestivalViewProductListTable = ({
    product_list,
    handleItemPerPage,
    handleCurrentPage,
    currentPage,
    setCurrentPage,
    totalPage,
    setTotalPage,
    itemPerPage,
    totalItems,
    setTotalItems,
    loading,
    isPending,
    handleStatusChange,

    setSearchKeyword,
    searchKeyword,
    categories,
    setCategory,
    category,
    brands,
    setBrand,
    brand,
    vendor,
    setVendor,

    setIsFilter,
}) => {
    const [productImageHoverImage, setProductImageHoverImage] = useState(null);

    // const [globalFilter, setGlobalFilter] = useState('')

    const [viewDetailsProductId, setViewDetailsProductId] = useState(null);

    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const currentLocation = history?.location?.pathname;

    const urlSearchParams = new URLSearchParams(location.search);
    const product_status = urlSearchParams.get('campStatus');

    const {
        getProductDetails,
        productDetailsLoading
    } = useSelector((state) => state.productReducer);

    let roleId = authenticationService.getRoleId();



    useEffect(() => {
        if (viewDetailsProductId !== null) {
            dispatch(
                getProductDetailsRecord(viewDetailsProductId, "BDT", "Y")
            );
        }
    }, [dispatch, viewDetailsProductId]);

    const imageBodyTemplate = (rowData) => {
        const handleMouseEnter = (data) => {
            setProductImageHoverImage(data.thumbnailImage);
        };

        return (
            <div style={{ position: "relative" }}>
                <img
                    onMouseEnter={() => handleMouseEnter(rowData)}
                    className="product_list__img"
                    style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "10px",
                        cursor: "pointer",
                        border: "1px solid #ddd",
                        objectFit: "contain",
                    }}
                    src={baseUrl.concat(rowData.thumbnailImage)}
                    alt="IMG"
                />
                <div className="product_list__tooltip">
                    <div className="tooltip__img">
                        <img
                            style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                                objectFit: "contain",
                            }}
                            src={baseUrl.concat(productImageHoverImage)}
                            alt="product image"
                        />
                    </div>
                </div>
            </div>
        );
    };

    const productNameTemplate = (rowData) => {
        return (
            <>{rowData.productName}</>
        );
    };

    const productStatusTemplate = (rowData) => {
        return (
            <>
                <Switch
                    checked={rowData?.isActive === 'Y' ? true : false}
                    onClick={() => handleStatusChange(rowData)}
                />
            </>
        );
    };


    useEffect(() => {
        if (product_list?.headers?.pagination) {
            var paginated_data_to_parse = product_list?.headers?.pagination;
            const paginated_data = JSON.parse(paginated_data_to_parse);
            setTotalPage(paginated_data.totalPages);
            setTotalItems(paginated_data.totalItems);
        }
    }, [
        product_list?.headers?.pagination,
    ]);

    const detailsBodyTemplate = (rowData) => {
        return (
            <>
                <Button
                    label="View Details"
                    className="p-button-success"
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                    onClick={() => setViewDetailsProductId(rowData.productId)}
                />
            </>
        );
    };


    return (
        <>
            <div
                className="table-header"
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space between",
                    padding: "8px 16px 8px 16px",
                    borderBottom: "1px solid #ddd",
                    borderTop: "1px solid #ddd",
                }}
            >
                <div className="button-demo">


                </div>
                <div style={{ display: "flex" }}>
                    <div
                        style={{ margin: "0 5px", width: "240px", position: "relative" }}
                    >
                        <InputText
                            style={{
                                width: "100%",
                            }}
                            className="manage_product__search"
                            value={searchKeyword}
                            onChange={(e) => setSearchKeyword(e.target.value)}
                            placeholder="Keyword"
                        />
                        {searchKeyword !== "" ? (
                            <button
                                style={{
                                    position: "absolute",
                                    top: "6px",
                                    right: "8px",
                                    borderRadius: "100%",
                                    border: "1px solid #ced4da",
                                    height: "25px",
                                    width: "25px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                                onClick={() => {
                                    setSearchKeyword("");
                                }}
                            >
                                <i className="pi pi-times"></i>
                            </button>
                        ) : null}
                    </div>
                    <div
                        style={{ margin: "0 5px", width: "300px", position: "relative" }}
                    >
                        <Dropdown
                            style={{
                                width: "100%",
                            }}
                            value={category}
                            options={categories}
                            filter
                            onChange={(e) => setCategory(e.value)}
                            optionLabel="breadcrumbCategory"
                            placeholder="Select a Category"
                        />
                        {category !== null ? (
                            <button
                                style={{
                                    position: "absolute",
                                    top: "6px",
                                    right: "8px",
                                    borderRadius: "100%",
                                    border: "1px solid #ced4da",
                                    height: "25px",
                                    width: "25px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                                onClick={() => {
                                    setCategory(null);
                                }}
                            >
                                <i className="pi pi-times"></i>
                            </button>
                        ) : null}
                    </div>
                    <div
                        style={{ margin: "0 5px", width: "175px", position: "relative" }}
                    >
                        <Dropdown
                            style={{
                                width: "100%",
                            }}
                            value={brand}
                            options={brands}
                            filter
                            showClear
                            onChange={(e) => setBrand(e.value)}
                            optionLabel="brandName"
                            placeholder="Select a Brand"
                        />
                        {brand !== null ? (
                            <button
                                style={{
                                    position: "absolute",
                                    top: "6px",
                                    right: "8px",
                                    borderRadius: "100%",
                                    border: "1px solid #ced4da",
                                    height: "25px",
                                    width: "25px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                                onClick={() => {
                                    setBrand(null);
                                }}
                            >
                                <i className="pi pi-times"></i>
                            </button>
                        ) : null}
                    </div>

                    {roleId == 1 && <div
                        style={{
                            margin: "0 2px",
                            width: "200px",
                            position: "relative",
                        }}
                    >
                        <MultiSelectShop selectShop={vendor} setSelectShop={setVendor} />
                    </div>}

                    <Button
                        onClick={() => {
                            setIsFilter(true);
                            setCurrentPage(1);
                        }}
                        style={{ marginLeft: "5px" }}
                        label="Search"
                    />
                </div>
            </div>
            {
                loading ? (
                    <LoadingCard count={1} />
                ) :
                    (
                        <DataTable
                            // header={renderHeader}
                            // globalFilter={globalFilter}
                            className="product_list_table"
                            responsiveLayout="scroll"
                            value={product_list.data}
                            emptyMessage="No Product found!"
                        >
                            <Column
                                field="productName"
                                header="Product Name"
                                body={productNameTemplate}
                                style={{ maxWidth: '200px', wordWrap: "break-word" }}
                                sortable
                            />
                            <Column
                                field="sku"
                                header="Product Style / SKU"
                                style={{ maxWidth: '200px', wordWrap: "break-word" }}
                                sortable
                            />

                            <Column
                                field="shopName"
                                header="Shop Name"
                                sortable
                            />
                            <Column
                                field="brandName"
                                header="Brand"
                                sortable
                            />

                            <Column
                                field="categoryName"
                                header="Category"
                                sortable
                            />

                            <Column
                                sortField="thumbnailImage"
                                header="Image"
                                body={imageBodyTemplate}
                            />
                            <Column
                                field="maxPrice"
                                header="Price"
                                sortable
                            />
                            <Column
                                field="view"
                                header="Product Details"
                                body={detailsBodyTemplate}
                            />

                            {!isPending && <Column
                                field="isActive"
                                header="Status"
                                body={productStatusTemplate}
                                sortable
                            />}
                        </DataTable>
                    )}
            <Paginator
                totalPage={totalPage}
                currentPage={currentPage}
                itemPerPage={itemPerPage}
                totalItems={totalItems}
                items={product_list.data}
                itemsPerPageOptions={[]}
                handleItemPerPage={handleItemPerPage}
                handleCurrentPage={handleCurrentPage}
            />
            <ProductDetailsModal
                currentLocation={currentLocation}
                products={getProductDetails?.data}
                loading={productDetailsLoading}
                product_status={product_status}
            />
        </>
    );
};
