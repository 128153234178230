import { yupResolver } from "@hookform/resolvers/yup";
import { Dropdown } from "primereact/dropdown";
import React from 'react';
import * as Icon from "react-feather";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { Loader } from "../../containers";

const CreateAdsSchema = yup.object().shape({
    bannerName: yup.string().required(),
    bannerTitle: yup.string(),
    // bannerLink: yup.string().required(),
    bannerLocationId: yup.string().required(),
    bannerDisplayOrder: yup.string().required(),
});


const CreateStoreBanner = (props) => {
    const { register, handleSubmit, formState, errors } = useForm({
        mode: "onChange",
        resolver: yupResolver(CreateAdsSchema),
    });

    const onSubmit = (data, e) => {
        e.target.reset();
    };

    return (
        <div className="page-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="panel panel-success">
                            <div className="panel-heading">
                                Create Store Banner{" "}
                                <span style={{ float: "right" }}>
                                    {props?.isSellerPanel ?
                                        <Link to="/SellerStoreBannerList">
                                            <Icon.List className="text-light" />
                                        </Link>
                                        :
                                        <Link to="/AllStoreBannerList">
                                            <Icon.List className="text-light" />
                                        </Link>}
                                </span>
                            </div>
                            <div className="panel-wrapper collapse in" aria-expanded="true">
                                <div className="panel-body">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="form-body">
                                            {!props?.isSellerPanel && <div className="p-fluid p-formgrid p-grid">
                                                <div className="p-fluid p-col-12 p-md-12">
                                                    <div className="form-group">
                                                        <label className="control_label">
                                                            Select Shop Name{" "}
                                                            <span
                                                                aria-hidden="true"
                                                                style={{
                                                                    color: "red",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                *
                                                            </span>
                                                        </label>
                                                        <div className="dropdown-demo">
                                                            <Dropdown
                                                                optionLabel="shopName"
                                                                options={props.shops}
                                                                filter
                                                                showClear
                                                                filterBy="shopName"
                                                                placeholder="Select Shop"
                                                                className={
                                                                    // props.errorShopName.length !== 0
                                                                    //     ? "errorClass form-control"
                                                                    // : 
                                                                    "form-control"
                                                                }
                                                                name="shopName"
                                                                value={props.shopName}
                                                                onChange={props.handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}


                                            <div className="p-fluid p-formgrid p-grid">
                                                <div className="p-fluid p-col-12 p-md-6">
                                                    <div className="form-group">
                                                        <label className="control_label">
                                                            Store Banner Name{" "}
                                                            <span
                                                                aria-hidden="true"
                                                                style={{ color: "red", fontWeight: "bold" }}
                                                            >
                                                                *
                                                            </span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            placeholder="Enter Store Banner Name"
                                                            name="bannerName"
                                                            value={props?.bannerName}
                                                            onChange={props?.handleChange}
                                                            className="form-control"
                                                            ref={register}
                                                        />
                                                        {errors?.bannerName && (
                                                            <span className="error">
                                                                {errors?.bannerName?.message}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="p-fluid p-col-12 p-md-6">
                                                    <div className="form-group">
                                                        <label className="control_label">
                                                            Store Banner Title{" "}
                                                        </label>
                                                        <input
                                                            type="text"
                                                            placeholder="Enter Store Banner Title"
                                                            name="bannerTitle"
                                                            value={props?.bannerTitle}
                                                            onChange={props?.handleChange}
                                                            className="form-control"
                                                            ref={register}
                                                        />
                                                        {errors?.bannerTitle && (
                                                            <span className="error">
                                                                {errors?.bannerTitle?.message}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="p-fluid p-formgrid p-grid">
                                                <div className="p-fluid p-col-12 p-md-4">
                                                    <div className="form-group">
                                                        <label className="control_label">
                                                            Store Banner Link{" "}
                                                        </label>
                                                        <input
                                                            type="text"
                                                            placeholder={props.shopName !== "" ? `Default: /store/${props.shopName.shopId}/${props.shopName.shopName}/products?&page=1&itemsPerPage=36&lnk=0` : "Enter Store Banner Link"}
                                                            name="bannerLink"
                                                            value={props?.bannerLink}
                                                            onChange={props?.handleChange}
                                                            className="form-control"
                                                            ref={register}
                                                        />
                                                        {errors?.bannerLink && (
                                                            <span className="error">
                                                                {errors?.bannerLink?.message}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="p-field p-col-12 p-md-4">
                                                    <div className="form-group">
                                                        <label className="control_label">
                                                            Position on Store Page{" "}
                                                            <span
                                                                aria-hidden="true"
                                                                style={{ color: "red", fontWeight: "bold" }}
                                                            >
                                                                *
                                                            </span>
                                                        </label>
                                                        <select
                                                            name="bannerLocationId"
                                                            value={props?.bannerLocationId}
                                                            onChange={props?.handleChange}
                                                            className="form-control"
                                                            ref={register}
                                                        >
                                                            <option value="">Select Location</option>
                                                            <option value="1">Store Page Slider</option>
                                                            <option value="2">Store Top Right Side</option>
                                                            <option value="3">Store Top Left Side</option>
                                                            <option value="4">Store Mid</option>
                                                            <option value="5">Store Bottom</option>
                                                        </select>
                                                        {errors?.bannerLocationId && (
                                                            <span className="error">
                                                                {errors?.bannerLocationId?.message}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="p-fluid p-col-12 p-md-4">
                                                    <div className="form-group">
                                                        <label className="control_label">
                                                            Display Order{" "}
                                                            <span
                                                                aria-hidden="true"
                                                                style={{ color: "red", fontWeight: "bold" }}
                                                            >
                                                                *
                                                            </span>
                                                        </label>
                                                        <input
                                                            type="number"
                                                            placeholder="Enter Display Order"
                                                            name="bannerDisplayOrder"
                                                            value={props?.bannerDisplayOrder}
                                                            onChange={props?.handleChange}
                                                            className="form-control"
                                                            ref={register}
                                                        />
                                                        {errors?.bannerDisplayOrder && (
                                                            <span className="error">
                                                                {errors?.bannerDisplayOrder?.message}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className='col-sm-12 col-md-9'>
                                                    <div className='row'>
                                                        <div className='col-sm-7 col-md-7'>
                                                            <div className='form-group file-area'>
                                                                <label className='control_label'>
                                                                    Store Banner Image{' '}<span
                                                                        aria-hidden='true'
                                                                        style={{
                                                                            color: '#1f5da0',
                                                                            fontWeight: 'bold',
                                                                        }}
                                                                    >
                                                                        (1614 x 444 px)
                                                                    </span>{' '}
                                                                    <span
                                                                        aria-hidden='true'
                                                                        style={{
                                                                            color: 'red',
                                                                            fontWeight: 'bold',
                                                                        }}
                                                                    >
                                                                        *
                                                                    </span>
                                                                </label>
                                                                <input
                                                                    type='file'
                                                                    accept="image/*"
                                                                    name='image'
                                                                    required='required'
                                                                    className='form-control'
                                                                    onChange={props.bannerFileSelectedHandler}
                                                                />
                                                                {props.bannerImage === '' ? (
                                                                    <div className='file-dummy'>
                                                                        <div className='default'>
                                                                            Select to Upload Store Banner Image
                                                                            (1614 x 444 px)
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div className='file-dummy'>
                                                                        <div className='success'>
                                                                            Store Banner Image Uploaded Successfully
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className='col-sm-5 col-md-5'>
                                                            {props.bannerImageShowFile ? (
                                                                <img
                                                                    src={props.bannerImageShowFile}
                                                                    alt=""
                                                                    className='thumb-md product-image'
                                                                    style={{
                                                                        marginTop: '30px',
                                                                        width: '250px',
                                                                        height: '70px',
                                                                    }}
                                                                />
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-sm-12 col-md-3'>
                                                    <div className='form-group'>
                                                        <label className='control_label'>Active</label>
                                                        <div className='checkbox checkbox-success d-flex align-items-center'>
                                                            <input
                                                                id='isActive'
                                                                type='checkbox'
                                                                name='isActive'
                                                                checked={props.bannerImageIsActive}
                                                                onChange={props.handleIsActiveCheck}
                                                            />
                                                            <label htmlFor='isActive'> &nbsp;Yes </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="form-footer">
                                            <div className="form-group row">
                                                <div className="text-center">
                                                    <div className="btn-group text-center">
                                                        {(props?.storeLoading) ?
                                                            <div style={{ width: '65%', textAlign: 'center' }}>
                                                                <Loader />
                                                            </div>
                                                            : <>
                                                                <button
                                                                    className="btn btn-success"
                                                                    onClick={props?.saveStoreBanner}
                                                                    disabled={!formState?.isValid}
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    Create
                                                                </button>
                                                                <Link to="/Home">
                                                                    <button
                                                                        className="btn btn-danger"
                                                                        style={{ cursor: "pointer" }}
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                </Link>
                                                            </>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateStoreBanner