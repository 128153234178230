import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { getShopRecord } from "../../store/actions/shopAction";


const MultiSelectShop = ({setSelectShop, selectShop }) => {
 
  const [shopNames, setShopNames] = useState(selectShop || null);
  const [shopLists, setShopLists] = useState([]);
  
  const dispatch = useDispatch();


  const { shops, loading: isLoading } = useSelector((state) => state?.shopReducer);

  
  const handleShopMultiSelectChange = (selectedShop, event) => {

    if (event?.action === 'select-option') {
      const newShopLists = shopLists.map(shop => ({
        ...shop, isDisabled: event?.option?.value === -2 ? true : shop.value === -2 ? true : false,
      }))
      setShopLists(newShopLists)
    }

    if (event?.action === 'remove-value') {
      const newShopLists = shopLists.map(shop => ({
        ...shop, isDisabled: event?.removedValue?.value === -2 ? false : selectedShop?.length ? (shop.value === -2 ? true : false) : false,
      }))
      setShopLists(newShopLists)
    }
    setSelectShop(selectedShop)
    setShopNames(selectedShop);

  };

  const animatedComponents = makeAnimated();

  useEffect(() => {
    if (shops) {
      setShopLists([
        {
          label: "All Stores Except SaRa",
          value: -2,
        },
        ...shops.map((data) => ({
          label: data?.shopName,
          value: data?.shopId,
          // isDisabled:true,
        })),
      ]);
    }

  }, [shops]);

  useEffect(() => {
    if (shopNames && shopNames[0].value === -2) {
      setShopLists(
        shopLists.map((option) => ({
          ...option,
          isDisabled:
            shopNames && shopNames[0].value === -2 && option.value !== -2,
        }))
      );
    } else if (shopNames && shopNames[0].value !== -2) {
      setShopLists(
        shopLists.map((option) => ({
          ...option,
          isDisabled:
            shopNames && shopNames[0].value !== -2 && option.value === -2,
        }))
      );
    } else {
      setShopLists(
        shopLists.map((option) => ({
          ...option,
          isDisabled: false,
        }))
      );
    }
  }, [shopNames]);

  useEffect(() => {
    dispatch(getShopRecord());
  }, [dispatch]);

  return (
    <>
      {!isLoading && shopLists.length &&

        <Select
          style={{
            width: "100%",
            color: "#000000",
          }}
          // defaultValue={[shopLists[4], shopLists[7]]}
          options={shopLists}
          components={animatedComponents}
          placeholder={"Select Shop Name"}
          isMulti
          isSearchable
          onChange={handleShopMultiSelectChange}
          value={shopNames}
          closeMenuOnSelect={false}
          isDisabled={shops?.length === 1 ? true : false}
        />
      }
    </>
  );
};

export default MultiSelectShop;
