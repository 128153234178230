import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import SellerCommission from "../../components/seller/SellerCommission";
import authenticationService from "../../store/services/authenticationService";

const SellerCommissionContainer = () => {
  const history = useHistory();

  useEffect(() => {
    const checkAuthentication = async () => {
      const roleId = authenticationService.getRoleId();
      if (roleId !== "2") {
        history.push("/sellerLogin")
        return;
      }
    };

    checkAuthentication();
  }, [history]);

  return (
    <div className="page-wrapper">
      <div className="container-fluid">
        <div className="pdf-container">
          <SellerCommission />
        </div>
      </div>
    </div>
  );
};

export default SellerCommissionContainer;
