/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import LoadingCard from "../../components/shared/LoadingCard";
import Message from "../../components/shared/Message";
import { getWarrantyPeriodRecord } from "../../store/actions/warrantyAction";

const productStatusArr = [
  { status: "Active", code: "Y" },
  { status: "Deactive", code: "N" },
];

const WarrantyPeriodList = () => {
  const [statusInfo, setStatusInfo] = useState('');
  const [keyword, setKeyword] = useState("");
  const [isFilter, setIsFilter] = useState(false);

  const dispatch = useDispatch();
  const navigateTo = useHistory();

  const { warrantyPeriodData, loading, error } = useSelector(
    (state) => state.warrantyReducer
  );

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Link to="/warrantyPeriod">
          <div className="button-demo">
            <Button
              icon="pi pi-times"
              className="p-button-rounded p-button-danger p-button-outlined"
            />
          </div>
        </Link>
      </React.Fragment>
    );
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="p-text-bold table-heading-style">
          List of Warranty Period
        </div>
      </React.Fragment>
    );
  };

  const onIndexTemplate = (rowData, props) => {
    return props.rowIndex + 1;
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          onClick={() => {
            navigateTo.push({
              pathname: "/warrantyPeriod",
              state: { data: rowData },
            });
          }}
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success p-mr-2"
        />
      </React.Fragment>
    );
  };

  useEffect(() => {
    dispatch(
      getWarrantyPeriodRecord({
        isActive: statusInfo?.code,
        warrantyPeriodName: keyword,
      })
    );
    return () => setIsFilter(false);
  }, [dispatch, isFilter]);

  // useEffect(() => {
  //  if(keyword === '' || isFilter){

  //   dispatch(
  //     getWarrantyPeriodRecord({
  //       isActive: statusInfo?.code,
  //       warrantyPeriodName: keyword,
  //     })
  //   );
  //  }
  //   return () => setIsFilter(false);
  // }, [dispatch, keyword,  isFilter]);

  // useEffect(() => {
  //  if(statusInfo === '' || isFilter){

  //   dispatch(
  //     getWarrantyPeriodRecord({
  //       isActive: statusInfo?.code,
  //       warrantyPeriodName: keyword,
  //     })
  //   );
  //  }

  //  if(keyword === '' || isFilter){

  //   dispatch(
  //     getWarrantyPeriodRecord({
  //       isActive: statusInfo?.code,
  //       warrantyPeriodName: keyword,
  //     })
  //   );
  //  }

  //   return () => setIsFilter(false);
  // }, [dispatch, statusInfo, keyword, isFilter]);


  return (
    <>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div className="datatable-doc-demo datatable-responsive-demo">
                  <div className="card">
                    <Toolbar
                      className="p-mb-4"
                      right={rightToolbarTemplate}
                      left={leftToolbarTemplate}
                    ></Toolbar>
                    <div
                      className="d-flex justify-end"
                      style={{ display: "flex", justifyContent: "end", margin:'20px 20px' }}
                    >
                      <div
                        style={{
                          margin: "0 5px",
                          width: "210px",
                          position: "relative",
                        }}
                      >
                        <InputText
                          style={{
                            width: "100%",
                          }}
                          className="manage_product__search"
                          value={keyword}
                          onChange={(e) => setKeyword(e.target.value)}
                          placeholder="Search Keyword"
                        />
                        {keyword !== "" ? (
                          <button
                            style={{
                              position: "absolute",
                              top: "6px",
                              right: "8px",
                              borderRadius: "100%",
                              border: "1px solid #ced4da",
                              height: "25px",
                              width: "25px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            onClick={() => {
                              setKeyword('');
                            }}
                          >
                            <i className="pi pi-times"></i>
                          </button>
                        ) : null}
                      </div>
                      <div
                        style={{
                          margin: "0 5px",
                          width: "175px",
                          position: "relative",
                        }}
                      >
                        <Dropdown
                          style={{
                            width: "100%",
                          }}
                          value={statusInfo}
                          options={productStatusArr}
                          onChange={(e) => setStatusInfo(e.value)}
                          optionLabel="status"
                          placeholder="Select a Status"
                        />
                        {statusInfo !== '' ? (
                          <button
                            style={{
                              position: "absolute",
                              top: "6px",
                              right: "8px",
                              borderRadius: "100%",
                              border: "1px solid #ced4da",
                              height: "25px",
                              width: "25px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            onClick={() => {
                              // setStatusInfo(null);
                              setStatusInfo('')
                            }}
                          >
                            <i className="pi pi-times"></i>
                          </button>
                        ) : null}
                      </div>
                      <Button
                        onClick={() => setIsFilter(true)}
                        style={{ marginLeft: "5px" }}
                        label="Search"
                      />
                    </div>
                    {loading ? (
                      <LoadingCard count={1} />
                    ) : error ? (
                      <Message variant="danger">{error}</Message>
                    ) : (
                      <DataTable
                        // header={header}
                        // ref={(el) => (this.dt = el)}
                        value={warrantyPeriodData?.data}
                        className="p-datatable-customers p-datatable-responsive-demo"
                        dataKey="id"
                        rowHover
                        paginator
                        rows={10}
                        rowsPerPageOptions={[10, 25, 50]}
                        emptyMessage="No warranty period found"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        loading={loading}
                      >
                        <Column
                          field="Index"
                          header="SN"
                          name=""
                          body={onIndexTemplate}
                        />

                        <Column
                          sortField="warrantyPeriodName"
                          filterField="warrantyPeriodName"
                          header="Name"
                          field="warrantyPeriodName"
                          sortable
                        />

                        <Column
                          sortField="createDate"
                          header="Details"
                          field="warrantyPeriodDetails"
                        />

                        <Column
                          sortField="isActive"
                          header="Status"
                          body={(rowData, props) => {
                            return rowData.isActive === "N"
                              ? "Deactive"
                              : "Active";
                          }}
                          sortable
                        />
                        <Column
                          field="action"
                          header="Edit"
                          body={actionBodyTemplate}
                        />
                      </DataTable>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={1000} />
    </>
  );
};

export default WarrantyPeriodList;
