import React, { useState } from 'react'
import * as Icon from 'react-feather'
import { Link } from 'react-router-dom'
import AddProduct from './AddProduct'
import CheckCustomerAvailability from './CheckCustomerAvailability'
import CreateCustomerAddress from './CreateCustomerAddress'
import CreateNewCustomer from './CreateNewCustomer'

const CreateOrder = (props) => {
  const [confirmAddress, setConfirmAddress] = useState(false)
  const [addressChange, setAddressChange] = useState(false)
  return (
    <div className='page-wrapper'>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='panel panel-success'>
              <div className='panel-heading'>
                Create Order
                <span style={{ float: 'right' }}>
                  <Link to='/ManageOrdersAdmin?status=pending'>
                    <Icon.List className='text-light' />
                  </Link>
                </span>
              </div>
              <div className='panel-wrapper collapse in' aria-expanded='true'>
                <div className='panel-body'>
                  <CheckCustomerAvailability check={props} />
                  <CreateNewCustomer createCustomer={props} />
                  <CreateCustomerAddress
                    createAddress={props}
                    setConfirmAddress={setConfirmAddress}
                    confirmAddress={confirmAddress}
                    setAddressChange={setAddressChange}
                    deleteAddress={props.deleteAddress}
                  />
                  <AddProduct
                    addProduct={props}
                    confirmAddress={confirmAddress}
                    deliveryAddress={props?.customerAddress[0]}
                    addressChange={addressChange}
                    saving={props?.saving}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default CreateOrder