import moment from "moment";
import { getDateWiseSalesSummaryRecord } from "../../../store/actions/accountsAction";

export const dateWiseSalesSummaryApiCall = (
  dispatch,
  startDate,
  endDate,
  statusId,
  shopIds
) => {
  dispatch(
    getDateWiseSalesSummaryRecord(
      startDate ? startDate : "",
      endDate ? endDate : "",
      statusId ? statusId : "",
      shopIds ? shopIds : 0
    )
  );
};

export const dateWiseSalesSummaryReportExcel = (dataFromAPI) => {
  let formattedData = [];
  dataFromAPI &&
    dataFromAPI.map((data, i) => {
      let singleData = {
        Date: data?.orderDate
          ? moment(data?.orderDate).format("DD-MMMM-YYYY")
          : "-",
        "Vendor Name": data?.shopName ? data?.shopName.toString() : "-",
        "Number of Invoices": data?.cntDelivered
          ? parseInt(data?.cntDelivered)
          : 0,
        "Sales Qty.": data?.deliveredQty ? parseInt(data?.deliveredQty) : 0,
        "Total DP": data?.dpPrice ? parseFloat(data?.dpPrice) : 0,
        "Total MRP": data?.deliveredAmount
          ? parseFloat(data?.deliveredAmount)
          : 0,
        "Discount Amount": data?.discountAmount
          ? parseFloat(data?.discountAmount)
          : 0,
        "Burn Amount": data?.burnAmount ? parseFloat(data?.burnAmount) : 0,
        "Voucher Amount": data?.voucherAmount ? parseFloat(data?.voucherAmount) : 0,
        "Gross Sales": data?.grossAmount ? parseFloat(data?.grossAmount) : 0,
        Commission: data?.commissionAmount
          ? parseFloat(data?.commissionAmount)
          : 0,
        "Net Payable": data?.netAmount ? parseFloat(data?.netAmount) : 0,
      };
      formattedData.push(Object.assign({}, singleData));
    });
  return formattedData;
};

export const dateWiseSalesSummaryReportGrouping = (
  dataFromAPI,
  setIsDataFormatting
) => {
  setIsDataFormatting(true);
  const groupedData = {};
  for (const obj of dataFromAPI) {
    const fieldValue = obj.shopName;
    if (!groupedData[fieldValue]) {
      groupedData[fieldValue] = [];
    }
    groupedData[fieldValue].push(obj);
  }

  const uniqueValues = [...new Set(dataFromAPI.map((obj) => obj.shopName))];

  const grandTotal = dataFromAPI.reduce(
    (accumulator, currentValue) =>
      accumulator + parseFloat(currentValue?.netAmount),
    0
  );
  const grandTotals = {
    cntDelivered: dataFromAPI.reduce(
      (accumulator, currentValue) =>
        accumulator + parseFloat(currentValue?.cntDelivered),
      0
    ),
    deliveredQty: dataFromAPI.reduce(
      (accumulator, currentValue) =>
        accumulator + parseFloat(currentValue?.deliveredQty),
      0
    ),
    deliveredAmount: dataFromAPI.reduce(
      (accumulator, currentValue) =>
        accumulator + parseFloat(currentValue?.deliveredAmount),
      0
    ),
    discountAmount: dataFromAPI.reduce(
      (accumulator, currentValue) =>
        accumulator + parseFloat(currentValue?.discountAmount),
      0
    ),
    burnAmount: dataFromAPI.reduce(
      (accumulator, currentValue) =>
        accumulator + parseFloat(currentValue?.burnAmount),
      0
    ),
    voucherAmount: dataFromAPI.reduce(
      (accumulator, currentValue) =>
        accumulator + parseFloat(currentValue?.voucherAmount),
      0
    ),
    grossAmount: dataFromAPI.reduce(
      (accumulator, currentValue) =>
        accumulator + parseFloat(currentValue?.grossAmount),
      0
    ),
    commissionAmount: dataFromAPI.reduce(
      (accumulator, currentValue) =>
        accumulator + parseFloat(currentValue?.commissionAmount),
      0
    ),
    netAmount: dataFromAPI.reduce(
      (accumulator, currentValue) =>
        accumulator + parseFloat(currentValue?.netAmount),
      0
    ),
  };

  setIsDataFormatting(false);
  return [groupedData, uniqueValues, grandTotals];
};
