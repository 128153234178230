import React from "react";

const DeleteAddressModal = ({
  deleteAddress,
  setDeleteAddressModal,
  addressDeleteId,
  addressDeleteData,
}) => {
  const deleteConfirm = (e, addressId) => {
    deleteAddress(e, addressId);
    setDeleteAddressModal(false);
  };
  return (
    <div>
      <strong style={{ fontSize: "15px" }}>
        Confirm to delete this address?
      </strong>
      <div>
        <hr />
        <strong>{addressDeleteData?.recipientName}</strong>
        <br />
        <b>
          <i>{addressDeleteData?.contactNumber}</i>
        </b>
        <br />
        <i>
          {addressDeleteData?.areaName}, {addressDeleteData?.cityName},{" "}
          {addressDeleteData?.countryName}
        </i>
        {addressDeleteData?.postCode !== "" && (
          <i> - {addressDeleteData?.postCode}</i>
        )}
        <br />
        {addressDeleteData?.address}
        <hr />
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <button
          className="btn btn-primary btn-lg"
          onClick={() => setDeleteAddressModal(false)}
        >
          Cancel
        </button>
        <button
          className="btn btn-danger btn-lg"
          onClick={(e) => deleteConfirm(e, addressDeleteId)}
        >
          Confirm
        </button>
      </div>
    </div>
  );
};

export default DeleteAddressModal;
