/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Resizer from "react-image-file-resizer";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import CreateFestive from '../../components/festival/CreateFestive';
import {
    createOrUpdateFestivalCategoryRecord,
    createOrUpdateFestivalCategoryReset,
    getFestivalCategoryRecord
} from '../../store/actions/festiveAction';
import authenticationService from '../../store/services/authenticationService';

const CreateFestiveContainer = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const [parentCategory, setParentCategory] = useState("")
    const [parentCategoryId, setParentCategoryId] = useState(0)
    const [festivalCategoryName, setFestivalCategoryName] = useState('')
    const [errorFestivalCategoryName, setErrorFestivalCategoryName] = useState('')
    const [festivalDescription, setFestivalDescription] = useState('')
    const [festivalMetaDescription, setFestivalMetaDescription] = useState('')
    const [festivalStartDate, setFestivalStartDate] = useState('')
    const [festivalEndDate, setFestivalEndDate] = useState('')
    const [displayOrder, setDisplayOrder] = useState('')
    const [errorDisplayOrder, setErrorDisplayOrder] = useState('')
    const [categoryUrl, setCategoryUrl] = useState('')
    const [metaKeywords, setMetaKeywords] = useState('')
    const [metaTitle, setMetaTitle] = useState('')
    const [bannerUrl, setBannerUrl] = useState('')
    const [isDeleted, setIsDeleted] = useState(false)
    const [isActive, setIsActive] = useState(true)
    const [festivalImagePath, setFestivalImagePath] = useState('')
    const [showImage, setShowImage] = useState('')

    useEffect(() => {
        let roleId = authenticationService.getRoleId();
        if (roleId !== "1") {
            history.push("/Login");
        }
        dispatch(createOrUpdateFestivalCategoryReset())
        dispatch(getFestivalCategoryRecord({
            getAll: 'Y',
        }))
    }, [dispatch, history]);

    const { festivalCategoryData, festivalCreateOrUpdateData, festivalCreateOrUpdateDataLoading } = useSelector((state) => state.festivalReducer);

    useEffect(() => {
        if (festivalCreateOrUpdateData.data) {
            if (festivalCreateOrUpdateData && festivalCreateOrUpdateData?.data?.succeed === true) {
                toast.success('Festival Category Created Successfully')
                dispatch(createOrUpdateFestivalCategoryReset())
                setTimeout(() => {
                    history.push('/FestiveList?status=active')
                }, 2500)
                resetForm()
            } else if (festivalCreateOrUpdateData && festivalCreateOrUpdateData?.data?.message === "Save Success") {
                toast.success('Festival Category Created Successfully')
                dispatch(createOrUpdateFestivalCategoryReset())
                setTimeout(() => {
                    history.push('/FestiveList?status=active')
                }, 2500)
                resetForm()
            } else if (festivalCreateOrUpdateData && festivalCreateOrUpdateData?.data?.succeed === false) {
                toast.error('Something went wrong, Please try again')
                dispatch(createOrUpdateFestivalCategoryReset())
                setTimeout(() => {
                    resetForm()
                }, 2500)
            }
        }
    }, [festivalCreateOrUpdateData.data])

    const resetForm = () => {
        setParentCategory('')
        setParentCategoryId(0)
        setFestivalCategoryName('')
        setErrorFestivalCategoryName('')
        setFestivalDescription('')
        setFestivalMetaDescription('')
        setFestivalMetaDescription('')
        setFestivalStartDate('')
        setFestivalEndDate('')
        setDisplayOrder('')
        setErrorDisplayOrder('')
        setCategoryUrl('')
        setMetaKeywords('')
        setMetaTitle('')
        setBannerUrl('')
        setIsDeleted(false)
        setIsActive(true)
        setFestivalImagePath('')
        setShowImage('')
    }

    const handleChange = (e) => {
        const { target } = e
        const { value } = e.target

        switch (target.name) {
            case 'parentCategory':
                setParentCategory(target.value)
                setParentCategoryId(target.value.festivalCategoryId)
                break
            case 'festivalCategoryName':
                setFestivalCategoryName(target.value)
                setErrorFestivalCategoryName(value.length < 2 ? 'At least 2 characters required' : '')
                break
            case 'festivalDescription':
                setFestivalDescription(target.value)
                break
            case 'festivalMetaDescription':
                setFestivalMetaDescription(target.value)
                break
            case 'festivalStartDate':
                setFestivalStartDate(target.value)
                break
            case 'festivalEndDate':
                setFestivalEndDate(target.value)
                break
            case 'displayOrder':
                setDisplayOrder(target.value)
                setErrorDisplayOrder(value.length < 1 ? 'Display Order required' : '')
                break
            case 'categoryUrl':
                setCategoryUrl(target.value)
                break
            case 'metaKeywords':
                setMetaKeywords(target.value)
                break
            case 'metaTitle':
                setMetaTitle(target.value)
                break
            case 'bannerUrl':
                setBannerUrl(target.value)
                break
            default:
        }
    }

    const handleIsActiveCheck = (e) => {
        setIsActive(e.target.checked)
    }
    const handleIsDeletedCheck = (e) => {
        setIsDeleted(e.target.checked)
    }

    const festivalImagePathHandler = (event) => {

        var width = 452;
        var height = 452;

        const imageFile = event.target.files[0];
        //1)    CHECK IF IT'S A IMAGE
        var fileInput = false;
        if (imageFile) {
            if (!imageFile.name.match(/\.(jpg|jpeg|png|gif|webp)$/)) {
                toast.error("Select a valid image.");
                return false;
            }
            fileInput = true;
            if (fileInput) {
                try {
                    Resizer.imageFileResizer(
                        imageFile,
                        width,
                        height,
                        "JPEG",
                        100,
                        0,
                        (uri) => {
                            setFestivalImagePath(uri)
                            setShowImage(URL.createObjectURL(imageFile))
                            toast.success("Festival Image Selected.");
                        },
                        "base64",
                        width,
                        height
                    );
                } catch (err) {
                    toast.error("Something went wrong!");
                }
            }
        }
    };

    const saveFestival = async (e) => {
        e.preventDefault()

        if (festivalCategoryName === '') {
            let msg = 'Festival Category Name is Required!!!'
            toast.error(msg)
            setTimeout(() => { }, 3000)
            return
        }
        if (festivalStartDate === '') {
            let msg = 'Festival Start Date is Required!!!'
            toast.error(msg)
            setTimeout(() => { }, 3000)
            return
        }
        if (festivalEndDate === '') {
            let msg = 'Festival End Date is Required!!!'
            toast.error(msg)
            setTimeout(() => { }, 3000)
            return
        }
        if (displayOrder === '') {
            let msg = 'Display Order is Required!!!'
            toast.error(msg)
            setTimeout(() => { }, 3000)
            return
        }
        if (festivalImagePath === '') {
            let msg = 'Festival Image is Required!!!'
            toast.error(msg)
            setTimeout(() => { }, 3000)
            return
        }
        if (categoryUrl === '') {
            let msg = 'Category Url is Required!!!'
            toast.error(msg)
            setTimeout(() => { }, 3000)
            return
        }
        const data = {
            festivalCategoryName: festivalCategoryName,
            bannerUrl: bannerUrl,
            metaKeywords: metaKeywords,
            metaTitle: metaTitle,
            description: festivalDescription,
            metaDescription: festivalMetaDescription,
            parentCategoryId: Number(parentCategoryId),
            festivalImagePath: festivalImagePath,
            isDeleted: isDeleted === true ? 'Y' : 'N',
            isActive: isActive === true ? 'Y' : 'N',
            displayOrder: Number(displayOrder),
            categoryUrl: categoryUrl,
            festivalStartDate: festivalStartDate,
            festivalEndDate: festivalEndDate,
        }

        dispatch(createOrUpdateFestivalCategoryRecord(data))
    }
    return (
        <div id='wrapper'>
            <CreateFestive
                key='CreateCampaign'
                name='Add Campaign'
                saveFestival={saveFestival}
                festivalCategoryData={festivalCategoryData}
                handleChange={handleChange}
                parentCategory={parentCategory}
                parentCategoryId={parentCategoryId}
                festivalCategoryName={festivalCategoryName}
                errorFestivalCategoryName={errorFestivalCategoryName}
                festivalDescription={festivalDescription}
                festivalMetaDescription={festivalMetaDescription}
                festivalStartDate={festivalStartDate}
                festivalEndDate={festivalEndDate}
                displayOrder={displayOrder}
                errorDisplayOrder={errorDisplayOrder}
                categoryUrl={categoryUrl}
                metaKeywords={metaKeywords}
                metaTitle={metaTitle}
                bannerUrl={bannerUrl}
                handleIsActiveCheck={handleIsActiveCheck}
                isActive={isActive}
                handleIsDeletedCheck={handleIsDeletedCheck}
                isDeleted={isDeleted}
                festivalImagePath={festivalImagePath}
                showImage={showImage}
                festivalImagePathHandler={festivalImagePathHandler}
                festivalCreateOrUpdateDataLoading={festivalCreateOrUpdateDataLoading}
            />
            <ToastContainer autoClose={1500} />
        </div>
    )
}

export default CreateFestiveContainer