

import {GET_COMMISSION_TYPE, GET_COMMISSION_TYPE_ERROR, GET_COMMISSION_TYPE_SUCCESS } from "../actions/commissionAction"

  const initialState = {
   
    commissionTypeData: [],
    loading: false,
    loaded: false,
    error: null,
  }

  export default function (state = initialState, action) {
    switch (action.type) {
     
      case GET_COMMISSION_TYPE:
        return {
          ...state,
          loading: true,
          loaded: false,
          error: null,
        }
  
      case GET_COMMISSION_TYPE_SUCCESS:
        return {
          ...state,
          loading: false,
          loaded: true,
          commissionTypeData: action.payload.success,
        }
  
      case GET_COMMISSION_TYPE_ERROR:
        return {
          ...state,
          loading: false,
          loaded: false,
          error: action.payload.error,
          commissionTypeData: [],
        }

  
      default:
        return state
    }
  }