import commissionService from "../services/commissionService";

export const GET_COMMISSION_TYPE = "GET_COMMISSION_TYPE";
export const GET_COMMISSION_TYPE_SUCCESS =
  "GET_COMMISSION_TYPE_SUCCESS";
export const GET_COMMISSION_TYPE_ERROR =
  "GET_COMMISSION_TYPE_ERROR";

export function getCommissionTypeRecord({  
    isActive='',
    commissionTypeName=''
   
  }) {
  return async (dispatch) => {
    dispatch(getCommissionType());
    return await commissionService
      .getCommissionType(
        isActive,
        commissionTypeName
      )

      .then((response) =>
        dispatch(getCommissionTypeSuccess(response))
      )
      .catch((error) => dispatch(getCommissionTypeError(error)));
  };
}

export function getCommissionType() {
  return {
    type: GET_COMMISSION_TYPE,
  };
}

export function getCommissionTypeSuccess(success) {
  return {
    type: GET_COMMISSION_TYPE_SUCCESS,
    payload: { success },
  };
}

export function getCommissionTypeError(error) {
  return {
    type: GET_COMMISSION_TYPE_ERROR,
    payload: { error },
  };
}




