/* eslint-disable react-hooks/exhaustive-deps */
import { Calendar } from "primereact/calendar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { getBussinessTypeRecord } from "../../store/actions/bussinessTypeAction";
import { getPaymentMethodRecord } from "../../store/actions/paymentMethodAction";
import { getShopByBusinessTypeIdsRecord } from "../../store/actions/shopAction";
import MultipleShopSelect from "../shared/common_used/MultipleShopSelection";
import "./report.css";

const SalesReportFilterProperties = (props) => {
  const animatedComponents = makeAnimated();

  const {
    handleChange,
    option,
    orderStatus,
    orderSource,
    paymentStatus,
    paymentMethod,
    startDate,
    endDate,
    handleDateChange,
    order_filter_tab,
    shopType,
    handleMultiPaymentMethodSelectChange,
    paymentMethodNames,
    setShopNameIds,
    dateType,
    setDateType
  } = props;

  const [paymentMethodLists, setPaymentMethodLists] = useState([]);
  const dispatch = useDispatch();

  let year = new Date().toLocaleDateString("en-us", {
    year: "numeric",
  });

  const { bussinessTypes } = useSelector(
    (state) => state?.bussinessTypeReducer
  );

  const { paymentMethods } = useSelector(
    (state) => state?.paymentMethodReducer
  );

  useEffect(() => {
    dispatch(getPaymentMethodRecord());
    dispatch(getShopByBusinessTypeIdsRecord(""));
    dispatch(getBussinessTypeRecord());
  }, [dispatch, option]);


  useEffect(() => {
    setPaymentMethodLists(
      paymentMethods.map((data) => ({
        label: data?.methodName,
        value: data?.paymentMethodId,
      }))
    );
  }, [paymentMethods]);

  useEffect(() => {
    dispatch(getShopByBusinessTypeIdsRecord(shopType?.bussinessTypeId));
  }, [shopType]);

  return (
    <>
      <div className="option-attribute-container" style={{ minHeight: "50vh" }}>
        {/* Date Type */}
        {option === 6 && <div className="option-attributes">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="" style={{ marginBottom: '10px' }}>
                <label className="control_label" htmlFor="basic">
                  {order_filter_tab === "order" ? "Order " : ""}Date Type{" "}
                  <span
                    aria-hidden="true"
                    style={{
                      color: "red",
                      fontWeight: "bold",
                    }}
                  >
                    *
                  </span>
                </label>
                <div className="flex">
                  <section className="" style={{ marginLeft: '5vw' }}>
                    <input
                      type="radio"
                      id="OrderDate"
                      name="dateType"
                      value={1}
                      onChange={() => setDateType(1)}
                      checked={dateType === 1}
                      style={{
                        cursor: 'pointer'
                      }} />
                    <label for="OrderDate" style={{ marginLeft: '10px', cursor: 'pointer', fontSize: '16px' }}>Order Date</label>
                  </section>
                  <section className="" style={{ marginLeft: '5vw' }}>
                    <input
                      type="radio"
                      id="DeliveredDate"
                      name="dateType"
                      value={2}
                      onChange={() => setDateType(2)}
                      checked={dateType === 2}
                      style={{
                        cursor: 'pointer'
                      }} />
                    <label for="DeliveredDate" style={{ marginLeft: '10px', cursor: 'pointer', fontSize: '16px'  }}>Delivered Date</label>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>}


        {/* Start Date Picker */}
        <div className="option-attributes">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="form-group">
                <label className="control_label" htmlFor="basic">
                  {order_filter_tab === "order" ? "Order " : ""}Start Date{" "}
                  <span
                    aria-hidden="true"
                    style={{
                      color: "red",
                      fontWeight: "bold",
                    }}
                  >
                    *
                  </span>
                </label>
                <div className="calender-container">
                  <Calendar
                    monthNavigator
                    yearNavigator
                    yearRange={`2016:${year}`}
                    id="basic"
                    maxDate={endDate}
                    value={startDate}
                    onChange={(e) => handleDateChange(e.value, "start")}
                    showIcon
                    placeholder={`Pick ${order_filter_tab === "order" ? "Order " : ""
                      }Start Date`}
                  />
                  {startDate !== null ? (
                    <button
                      style={{
                        position: "absolute",
                        top: "6px",
                        right: "33px",
                        borderRadius: "100%",
                        border: "1px solid #ced4da",
                        height: "25px",
                        width: "25px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => {
                        handleDateChange(null, "start");
                      }}
                    >
                      <i className="pi pi-times"></i>
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* End Date Picker */}
        <div className="option-attributes">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="form-group">
                <label className="control_label">
                  {order_filter_tab === "order" ? "Order " : ""}End Date{" "}
                  <span
                    aria-hidden="true"
                    style={{
                      color: "red",
                      fontWeight: "bold",
                    }}
                  >
                    *
                  </span>
                </label>
                <div className="calender-container">
                  <Calendar
                    monthNavigator
                    yearNavigator
                    yearRange={`2016:${year}`}
                    id="icon"
                    minDate={startDate}
                    value={endDate}
                    onChange={(e) => handleDateChange(e.value, "end")}
                    showIcon
                    placeholder={`Pick ${order_filter_tab === "order" ? "Order " : ""
                      }End Date`}
                  />

                  {endDate !== null ? (
                    <button
                      style={{
                        position: "absolute",
                        top: "6px",
                        right: "33px",
                        borderRadius: "100%",
                        border: "1px solid #ced4da",
                        height: "25px",
                        width: "25px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => {
                        handleDateChange(null, "end");
                      }}
                    >
                      <i className="pi pi-times"></i>
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Seller type Dropdown */}
        {/* <div className="option-attributes">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="form-group">
                <label className="control_label">Seller Type</label>
                <div
                  style={{
                    margin: "0 2px",
                    // width: "155px",
                    position: "relative",
                  }}
                >
                  <Dropdown
                    showClear
                    value={shopType}
                    options={bussinessTypes}
                    onChange={handleChange}
                    optionLabel="bussinessTypeName"
                    className="drop-control"
                    name="bussinessTypeName"
                    placeholder={"Select Seller Type"}
                    filter
                    filterBy="bussinessTypeName"
                  />
                  {shopType !== null ? (
                    <button
                      style={{
                        position: "absolute",
                        top: "6px",
                        right: "8px",
                        borderRadius: "100%",
                        border: "1px solid #ced4da",
                        height: "25px",
                        width: "25px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => {
                        setShopType(null);
                      }}
                    >
                      <i className="pi pi-times"></i>
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* Shop Name Dropdown */}
        <div className="option-attributes">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="form-group">
                <label className="control_label">Shop Name </label>
                <div>
                  <MultipleShopSelect setShopNameIds={setShopNameIds} />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Payment Method Dropdown */}

        {(option === 3 || option === 4 || option === 6) &&
          order_filter_tab === "sales_report" && (
            <div className="option-attributes">
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className="form-group">
                    <label className="control_label">
                      Payment Method Name{" "}
                    </label>
                    <div>
                      <Select
                        options={paymentMethodLists}
                        components={animatedComponents}
                        placeholder={"Select Payment Method Name"}
                        isMulti
                        isSearchable
                        onChange={handleMultiPaymentMethodSelectChange}
                        value={paymentMethodNames}
                        closeMenuOnSelect={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

        {/* Payment Status Dropdown */}
        {/* {order_filter_tab === "sales_report" && (
          <div className="option-attributes">
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="form-group">
                  <label className="control_label">
                    Payment Status{" "}
                    {option === 4 ? (
                      <span
                        aria-hidden="true"
                        style={{
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        *
                      </span>
                    ) : (
                      <></>
                    )}
                  </label>
                  <div>
                    <Dropdown
                      value={paymentStatus}
                      options={allPaymentStatus}
                      onChange={handleChange}
                      optionLabel="PaymentStatusName"
                      className="drop-control"
                      name="paymentStatusName"
                      placeholder={
                        option === 4 || option === 7 || option === 0
                          ? "Select Payment Status"
                          : "Payment Status Disabled"
                      }
                      filter
                      filterBy="PaymentStatusName"
                      disabled={
                        option === 4 || option === 7 || option === 0
                          ? false
                          : true
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )} */}
      </div>
    </>
  );
};

export default SalesReportFilterProperties;
