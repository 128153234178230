/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import moment from "moment";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Paginator } from "../../components/paginator/Paginator";
import LoadingCard from "../../components/shared/LoadingCard";
import Message from "../../components/shared/Message";
import { getCampaignRecord } from "../../store/actions/campaignAction";
import authenticationService from "../../store/services/authenticationService";
import baseUrl from "../../utils/baseUrl";

const SellerCampaignListContainer = () => {

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const urlSearchParams = new URLSearchParams(location.search);
  const selectedCampaignListStatus = urlSearchParams.get('status');

  const prevState = location?.state?.state;

  const [globalFilter, setGlobalFilter] = useState('')

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(null);
  const [itemPerPage, setItemPerPage] = useState(prevState?.itemPerPage ? prevState?.itemPerPage : 30);
  const [totalItems, setTotalItems] = useState(null);

  const { campaignData, campaignLoading, campaignError } = useSelector((state) => state.campaignReducer);

  const [campStatus, setCampStatus] = useState('');
  const [getAll, setGetAll] = useState('');

  useEffect(() => {
    let roleId = authenticationService.getRoleId();
    if (roleId !== "2") {
      history.push("/SellerLogin");
    }
    if (history?.location?.state) {
      setCurrentPage(history.location?.state.state.currentPage);
      setItemPerPage(history.location?.state.state.itemPerPage);
    }
  }, [dispatch, history]);

  useEffect(() => {
    if (selectedCampaignListStatus === "active") {
      dispatch(getCampaignRecord({
        isActive: 'Y',
        itemsPerPage: itemPerPage,
        currentPage: currentPage,
      }))
    } else if (selectedCampaignListStatus === "inactive") {
      dispatch(getCampaignRecord({
        isActive: 'N',
        itemsPerPage: itemPerPage,
        currentPage: currentPage,
      }))
    } else if (selectedCampaignListStatus === "all") {
      dispatch(getCampaignRecord({
        getAll: 'Y',
        itemsPerPage: itemPerPage,
        currentPage: currentPage,
      }))
    }
  }, [selectedCampaignListStatus, campStatus, getAll, itemPerPage, currentPage])

  useEffect(() => {
    if (campaignData?.headers?.pagination) {
      var paginated_data_to_parse = campaignData?.headers?.pagination;
      const paginated_data = JSON.parse(paginated_data_to_parse);
      setTotalPage(paginated_data.totalPages);
      setTotalItems(paginated_data.totalItems);
    }
  }, [campaignData?.headers?.pagination]);

  const handleItemPerPage = (pagePerItems) => {
    setCurrentPage(1);
    setItemPerPage(pagePerItems);
  };

  const handleCurrentPage = (currentPage) => {
    setCurrentPage(currentPage);
  };

  const campaignNameBodyTemplate = (rowData) => {
    return <React.Fragment>{rowData?.campaignName}</React.Fragment>;
  };

  const displayOrderBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {rowData?.displayOrder ? rowData?.displayOrder : "N/A"}
        </div>
      </React.Fragment>
    );
  }

  const campaignRegEndDateBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {rowData?.campaignRegStartDate && rowData.campaignRegStartDate !== null
          ? moment(rowData?.campaignRegStartDate).format("D MMM, YYYY, h:mm A")
          : "N/A"}
      </React.Fragment>
    );
  }

  const campaignStartDateBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {rowData?.campaignStartDate && rowData.campaignStartDate !== null
          ? moment(rowData?.campaignStartDate).format("D MMM, YYYY, h:mm A")
          : "N/A"}
      </React.Fragment>
    );
  }

  const campaignEndDateBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {rowData?.campaignEndDate && rowData.campaignEndDate !== null
          ? moment(rowData?.campaignEndDate).format("D MMM, YYYY, h:mm A")
          : "N/A"}
      </React.Fragment>
    );
  }

  const campaignBannerUrlTemplate = (rowData) => {
    var width = ''
    var height = ''
    if (rowData.displayOrder % 2 !== 0) {
      width = '161px'
      height = '30px'
    } else {
      width = '50px'
      height = '50px'
    }
    return (
      <React.Fragment>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {rowData?.displayOrder && rowData.campaignBannerUrl ? (
            <img
              src={baseUrl.concat(rowData?.campaignBannerUrl)}
              className="product-image"
              alt="img"
              style={{ verticalAlign: "middle", objectFit: "contain", width: width, height: height }}
            />
          ) : (
            "N/A"
          )}
        </div>
      </React.Fragment>
    );
  };

  const modifyProductsBodyTemplate = (rowData) => {
    return (
      <Link to={`/Modify-Seller-Campaign-ProductsList?campId=${rowData?.campaignId}`}>
        <div className="button-demo">
          <Button
            label="Add to List"
            className="p-button-primary"
          />
        </div>
      </Link>
    );
  };

  const manageProductsBodyTemplate = (rowData) => {
    return (
      <Link to={`/Manage-Seller-Campaign-ProductsList?campId=${rowData?.campaignId}&campStatus=pending`}>
        <div className="button-demo">
          <Button
            label="View List"
            className="p-button-primary"
          />
        </div>
      </Link>
    );
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span
          className={
            rowData?.isActive === "Y"
              ? "p-tag p-tag-success"
              : "p-tag p-tag-danger"
          }
        >
          {rowData?.isActive === "Y" ? "ACTIVE" : "INACTIVE"}
        </span>
      </React.Fragment>
    );
  }

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Link to="/SellerHome">
          <div className="button-demo">
            <Button
              icon="pi pi-times"
              className="p-button-rounded p-button-danger p-button-outlined"
            />
          </div>
        </Link>
      </React.Fragment>
    );
  }

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="p-text-bold table-heading-style">
          List of Campaigns
        </div>
      </React.Fragment>
    );
  }

  const renderHeader = () => {
    return (
      <div className="table-header">
        <span className="p-input-icon-left">
          <InputText
            type="search"
            className="form-control text-center text-field"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Search In Campaign List"
          />
        </span>
      </div>
    );
  }

  return (
    <>
      <ToastContainer autoClose={1500} />
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div className="datatable-responsive-demo">
                  <div className="card">
                    <Toolbar
                      className="p-mb-4"
                      right={rightToolbarTemplate}
                      left={leftToolbarTemplate}
                    ></Toolbar>
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}>
                      <ul className="nav nav-tabs seller-tabs">
                        <li
                          onClick={() => {
                            setCampStatus("Y");
                            setGetAll('');
                          }}
                        >
                          <Link to={`/SellerCampaignList?status=active`}
                            style={
                              selectedCampaignListStatus === "active"
                                ? { color: "#1F5DA0", borderBottom: '2px solid #1F5DA0', fontWeight: "700" }
                                : null
                            }>
                            Active Campaign
                          </Link>
                        </li>
                        <li
                          onClick={() => {
                            setCampStatus("N");
                            setGetAll('');
                          }}
                        >
                          <Link to={`/SellerCampaignList?status=inactive`}
                            style={
                              selectedCampaignListStatus === "inactive"
                                ? { color: "#1F5DA0", borderBottom: '2px solid #1F5DA0', fontWeight: "700" }
                                : null
                            }>
                            Inactive Campaign
                          </Link>
                        </li>
                        <li
                          onClick={() => {
                            setCampStatus("");
                            setGetAll('Y');
                          }}
                        >
                          <Link to={`/SellerCampaignList?status=all`}
                            style={
                              selectedCampaignListStatus === "all"
                                ? { color: "#1F5DA0", borderBottom: '2px solid #1F5DA0', fontWeight: "700" }
                                : null
                            }>
                            All Campaigns
                          </Link>
                        </li>
                      </ul>
                    </div>
                    {campaignLoading ? (
                      <LoadingCard count={1} />
                    ) : campaignError ? (
                      <Message variant="danger">{campaignError}</Message>
                    ) :
                      <DataTable
                        header={renderHeader}
                        value={campaignData?.data}
                        dataKey="campaignId"
                        rowHover
                        globalFilter={globalFilter}
                        emptyMessage="No Campaign found!"
                      >
                        <Column
                          header="Campaign Name"
                          sortField="campaignName"
                          filterField="campaignName"
                          body={campaignNameBodyTemplate}
                          sortable
                        />
                        <Column
                          header="Display Order"
                          sortField="displayOrder"
                          filterField="displayOrder"
                          body={displayOrderBodyTemplate}
                          sortable
                        />
                        <Column
                          header="Campaign Banner"
                          sortField="campaignBannerUrl"
                          body={campaignBannerUrlTemplate}
                        />
                        <Column
                          header="Reg. End Date"
                          sortField="campaignRegStartDate"
                          body={campaignRegEndDateBodyTemplate}
                          sortable
                        />
                        <Column
                          header="Campaign Start Date"
                          sortField="campaignStartDate"
                          body={campaignStartDateBodyTemplate}
                          sortable
                        />
                        <Column
                          header="Campaign End Date"
                          sortField="campaignEndDate"
                          body={campaignEndDateBodyTemplate}
                          sortable
                        />
                        {selectedCampaignListStatus === "all" && <Column
                          sortField="isActive"
                          header="Status"
                          body={statusBodyTemplate}
                          sortable
                        />}

                        <Column
                          header="View Products"
                          sortField="campaignStartDate"
                          body={manageProductsBodyTemplate}
                        />

                        <Column
                          header="Add Product"
                          sortField="campaignStartDate"
                          body={modifyProductsBodyTemplate}
                        />
                      </DataTable>
                    }

                    <Paginator
                      totalPage={totalPage}
                      currentPage={currentPage}
                      itemPerPage={itemPerPage}
                      totalItems={totalItems}
                      items={campaignData?.data}
                      itemsPerPageOptions={[30, 60, 90]}
                      handleItemPerPage={handleItemPerPage}
                      handleCurrentPage={handleCurrentPage}
                    />

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SellerCampaignListContainer