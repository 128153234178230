import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as profileAction from "../../store/actions/profileAction";
import authService from "../../store/services/authService";
import authenticationService from "../../store/services/authenticationService";

import { VerifiedSeller } from "../../components/seller/VerifiedSeller";
import * as productAction from "../../store/actions/productAction";
import * as sellerAction from "../../store/actions/sellerAction";

import PageLoading from "../shared/PageLoading";
import HomeAccounts from "./AccountsDashboard/HomeAccounts";
import HomeAdmin from "./Dashboard/HomeAdmin";

class HomeTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSellers: null,
      activeProducts: null,
      pendingOrders: null,
      orders: false,
    };
    this.verifiedSellerService = new VerifiedSeller();
  }

  componentDidMount = async () => {
    let userId = authService.getEmployeeId();
    //Begin Temporary Authentication
    let roleId = authenticationService.getRoleId();
    if (roleId === "1") {
      this.setState({
        authenticated: true,
        loginSuccessful: true,
      });
    } else {
      this.setState({
        authenticated: false,
        loginSuccessful: false,
      });
      this.props.history.push("/Login");
    }
    //End Temporary Authentication
    this.verifiedSellerService
      .getVerifiedSeller()
      .then((data) => this.setState({ activeSellers: data }));

    this.setState({
      activeProducts: this.props.products,
    });
    await this.props.getProfileByIdRecord(userId);
  };

  render() {
    return (
      <Fragment>
        {this.props.ProfileLoading &&
          !this.props?.profileById[0]?.designationId ? (
          <PageLoading />
        ) : (
          <>
            {this.props?.profileById[0]?.designationId !== 2 ? (
              <HomeAdmin />
            ) : (
              <HomeAccounts />
            )}
          </>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  sellers: state.sellerReducer.sellers,
  products: state.productReducer.verifiedProducts,
  profileById: state.profileReducer.profileById,
  ProfileLoading: state.profileReducer.loading,
  profileByIdLoading: state.profileReducer.loading,
});

// Making available in  props
const mapDispatchToProps = (dispatch) => {
  return {
    getVerifiedShopRecord: () => dispatch(sellerAction.getVerifiedShopRecord()),
    getVerifiedProductsRecord: () =>
      dispatch(productAction.getVerifiedProductsRecord()),
    getProfileByIdRecord: (index) =>
      dispatch(profileAction.getProfileByIdRecord(index)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeTest);
