/* eslint-disable react-hooks/exhaustive-deps */

import { Modal } from "antd";
import moment from "moment";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import FetchReturnOrderTrackingSeller from "../../components/orders/FetchReturnOrderTrackingSeller";
import RefundedOrdersModal from "../../components/orders/RefundedOrdersModal";
import { Paginator } from "../../components/paginator/Paginator";
import CancelRefundModal from "../../components/refund/CancelRefundModal";
import LoadingCard from "../../components/shared/LoadingCard";
import { fetchReturnOrderByIdRecord } from "../../store/actions/orderAction";
import { fetchRefundedDataRecord } from "../../store/actions/refundActions";
import authenticationService from "../../store/services/authenticationService";

export default function ReturnSellerOrdersListContainer() {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [visible, setVisible] = useState(false);
  const [orderInfo, setOrderInfo] = useState(null);
  const [counts, setCounts] = useState(1);
  const [refundVisible, setRefundVisible] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [pathnameOptions, setPathnameOptions] = useState(null);
  const [totalRefundAmount, setTotalRefundAmount] = useState(null);
  const [refundedVisible, setRefundedVisible] = useState(false);
  const [statusId, setStatusId] = useState(null);
  const [statusName, setStatusName] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(30);
  const [totalPage, setTotalPage] = useState(null);
  const [totalItems, setTotalItems] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const invoiceType = 7;

  const refunded_loading = useSelector((state) => state.refundReducer.loading);
  const return_orders = useSelector((state) => state.orderReducer);
  const { fetchReturnOrderById, loading } = return_orders;

  const refunded_data = useSelector(
    (state) => state.refundReducer.get_refunded_data
  );
  const sellerProfile = useSelector(
    (state) => state.sellerProfileReducer.shopDetails
  );

  const searchParams = new URLSearchParams(location?.search);
  const order_filter_tab = searchParams.get("status");

  const status_for_api_call = order_filter_tab.replaceAll("_", " ");
  const status_arr = status_for_api_call.split(" ");
  for (var i = 0; i < status_arr.length; i++) {
    status_arr[i] =
      status_arr[i].charAt(0).toUpperCase() + status_arr[i].slice(1);
  }
  const reformed_status = status_arr.join(" ");


  const handleItemPerPage = (pagePerItems) => {
    setCurrentPage(1);
    setItemPerPage(pagePerItems);
  };
  const handleCurrentPage = (currentPage) => {
    setCurrentPage(currentPage);
  };

  const returnOrderDateTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span>{moment(rowData.returnDate).format("Do MMMM YYYY, h:mm A")}</span>
      </React.Fragment>
    );
  };
  const paymentStatusBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {rowData?.paymentStatus === "Pending" ? (
          <Tag severity="danger" value={rowData?.paymentStatus} rounded></Tag>
        ) : (
          <Tag severity="success" value={rowData?.paymentStatus} rounded></Tag>
        )}
      </React.Fragment>
    );
  };
  const productDetailsTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span>
          <Button
            onClick={() => {
              setOrderInfo(rowData);
              setVisible(true);
            }}
            style={{
              fontSize: "14px",
              padding: "4px 10px",
              marginRight: "5px",
            }}
          >
            Details
          </Button>
        </span>
      </React.Fragment>
    );
  };

  const refundTateBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span>{moment(rowData.refundDate).format("Do MMMM YYYY, h:mm A")}</span>
      </React.Fragment>
    );
  };
  const refundDetailsBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span>
          <Button
            onClick={() => {
              setOrderInfo(rowData);
              setRefundedVisible(true);
            }}
            style={{
              fontSize: "14px",
              padding: "4px 10px",
              marginRight: "5px",
            }}
          >
            Details
          </Button>
        </span>
      </React.Fragment>
    );
  };
  const refundTotalBodyTateBodyTemplate = (rowData) => {
    const total_amount = rowData.productDetails.reduce(
      (total, currentValue) => (total = total + currentValue.amount),
      0
    );
    setTotalRefundAmount(total_amount);
    return (
      <React.Fragment>
        <span>{total_amount}</span>
      </React.Fragment>
    );
  };
  const paymentMethodTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span>
          {rowData.paymentMethodName
            ? rowData.paymentMethodName === "COD"
              ? "Cash On Delivery"
              : rowData.paymentMethodName
            : "Not Selected Yet"}
        </span>
      </React.Fragment>
    );
  };

  useEffect(() => {
    let roleId = authenticationService.getRoleId();
    if (roleId === "2") {
    } else {
      history.push("/SellerLogin");
    }
  }, []);

  useEffect(() => {
    setStatusId(null);
    setStatusName(null);
  }, [visible]);

 
  useEffect(() => {
    if (fetchReturnOrderById?.headers?.pagination) {
      var paginated_data_to_parse = fetchReturnOrderById.headers.pagination;
      const paginated_data = JSON.parse(paginated_data_to_parse);
      setCurrentPage(paginated_data.currentPage);
      setTotalPage(paginated_data.totalPages);
      setTotalItems(paginated_data.totalItems);
      setItemPerPage(paginated_data.itemsPerPage);
    }
  }, [fetchReturnOrderById?.headers?.pagination]);

  useEffect(() => {
    if (sellerProfile[0]?.shopId) {
      dispatch(
        fetchReturnOrderByIdRecord(
          reformed_status,
          sellerProfile[0]?.shopId,
          currentPage,
          itemPerPage
        )
      );
    }
  }, [dispatch, reformed_status, currentPage, itemPerPage, sellerProfile]);


  useEffect(() => {
    if (location.pathname === "/ReturnSellerOrders")
      setPathnameOptions("return");
  }, [location.pathname]);

  useEffect(() => {
    if (pathnameOptions !== null && order_filter_tab === "refund") {
      dispatch(
        fetchRefundedDataRecord(pathnameOptions, currentPage, itemPerPage)
      );
    }
  }, [dispatch, pathnameOptions, order_filter_tab, currentPage, itemPerPage]);

  useEffect(() => {
    if (refunded_data?.headers?.pagination) {
      var paginated_data_to_parse = refunded_data.headers.pagination;
      const paginated_data = JSON.parse(paginated_data_to_parse);
      setCurrentPage(paginated_data.currentPage);
      setTotalPage(paginated_data.totalPages);
      setTotalItems(paginated_data.totalItems);
      setItemPerPage(paginated_data.itemsPerPage);
    }
  }, [refunded_data?.headers?.pagination]);

  return (
    <div className="page-wrapper">
      <div className="container-fluid">
        <div className="col-md-12">
          <div className="white-box">
            <div className="datatable-doc-demo datatable-responsive-demo">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                className="card"
              >
                <ul class="nav nav-tabs seller-tabs">
                  <li>
                    <Link
                      to={`/ReturnSellerOrders?status=return_request`}
                      style={
                        order_filter_tab === "return_request"
                          ? { color: "#1F5DA0", borderBottom: '2px solid #1F5DA0', fontWeight: "700" }
                          : null
                      }
                    >
                      Return Request
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/ReturnSellerOrders?status=parcel_pickup_pending`}
                      style={
                        order_filter_tab === "parcel_pickup_pending"
                          ? { color: "#1F5DA0", borderBottom: '2px solid #1F5DA0', fontWeight: "700" }
                          : null
                      }
                    >
                      Parcel Pickup Pending
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/ReturnSellerOrders?status=parcel_returning`}
                      style={
                        order_filter_tab === "parcel_returning"
                          ? { color: "#1F5DA0", borderBottom: '2px solid #1F5DA0', fontWeight: "700" }
                          : null
                      }
                    >
                      Parcel Returning
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/ReturnSellerOrders?status=refund_processing`}
                      style={
                        order_filter_tab === "refund_processing"
                          ? { color: "#1F5DA0", borderBottom: '2px solid #1F5DA0', fontWeight: "700" }
                          : null
                      }
                    >
                      Refund Processing
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/ReturnSellerOrders?status=refund`}
                      style={
                        order_filter_tab === "refund"
                          ? { color: "#1F5DA0", borderBottom: '2px solid #1F5DA0', fontWeight: "700" }
                          : null
                      }
                    >
                      Refund
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/ReturnSellerOrders?status=returned`}
                      style={
                        order_filter_tab === "returned"
                          ? { color: "#1F5DA0", borderBottom: '2px solid #1F5DA0', fontWeight: "700" }
                          : null
                      }
                    >
                      Returned
                    </Link>
                  </li>
                </ul>

                <Link
                  to={{
                    pathname: `/SellerInvoice`,
                    selectedOrders,
                    invoiceType,
                    order_filter_tab,
                  }}
                >
                  <button
                    className="btn btn-info"
                    style={{
                      padding: "4px 20px",
                      fontSize: "13px ",
                      marginRight: "5px",
                      marginLeft: "10px",
                    }}
                    disabled={
                      selectedOrders === null || selectedOrders?.length === 0
                    }
                  >
                    Print
                  </button>
                </Link>

                <InputText
                  style={{ width: "25%" }}
                  className="form-control text-center"
                  type="search"
                  value={globalFilter}
                  onChange={(e) => setGlobalFilter(e.target.value)}
                  placeholder="Search"
                />
              </div>
              {order_filter_tab === "refund" ? (
                <div className="card">
                  {refunded_loading ? (
                    <LoadingCard count={1} />
                  ) : (
                    <>
                      <DataTable
                        className="p-datatable-customers p-datatable-responsive-demo"
                        // value={refunded_data?.data}
                        rowHover
                        globalFilter={globalFilter}
                        emptyMessage="No order(s) found"
                        selection={selectedOrders}
                        onSelectionChange={(e) => setSelectedOrders(e.value)}
                      >
                        <Column selectionMode="multiple" />
                        <Column
                          field="refundTranNo"
                          filterField="refundTranNo"
                          header="Transaction No"
                          sortable
                        />
                        <Column
                          field="refundDate"
                          filterField="refundDate"
                          header="Transaction Date"
                          body={refundTateBodyTemplate}
                          sortable
                        />
                        <Column
                          field="orderInvoiceNo"
                          filterField="orderInvoiceNo"
                          header="Invoice No"
                          sortable
                        />
                        <Column
                          field="customerName"
                          filterField="customerName"
                          header="Customer"
                          sortable
                        />
                        <Column
                          field="methodName"
                          filterField="methodName"
                          header="Payment Method"
                          body={paymentMethodTemplate}
                          sortable
                        />
                        <Column
                          field="accountNo"
                          filterField="accountNo"
                          header="Account No."
                          sortable
                        />
                        <Column
                          header="Amount"
                          body={refundTotalBodyTateBodyTemplate}
                        />
                        <Column
                          header="Action"
                          body={refundDetailsBodyTemplate}
                        />
                      </DataTable>
                      <Paginator
                        totalPage={totalPage}
                        currentPage={currentPage}
                        itemPerPage={itemPerPage}
                        totalItems={totalItems}
                        items={refunded_data.data}
                        itemsPerPageOptions={[30, 60, 90, 100, 200, 300]}
                        handleItemPerPage={handleItemPerPage}
                        handleCurrentPage={handleCurrentPage}
                      />
                    </>
                  )}
                </div>
              ) : (
                <div className="card">
                  {loading && !fetchReturnOrderById?.data?.length ? (
                    <LoadingCard count={1} />
                  ) : (
                    <>
                      <DataTable
                        className="p-datatable-customers p-datatable-responsive-demo"
                        value={fetchReturnOrderById?.data}
                        rowHover
                        globalFilter={globalFilter}
                        emptyMessage="No order(s) found"
                        selection={selectedOrders}
                        onSelectionChange={(e) => setSelectedOrders(e.value)}
                      >
                        <Column selectionMode="multiple" />
                        <Column
                          field="returnInvoiceNo"
                          filterField="returnInvoiceNo"
                          header="Return Invoice No"
                          sortable
                        />
                        <Column
                          field="returnDate"
                          filterField="returnDate"
                          header="Return Date"
                          body={returnOrderDateTemplate}
                          sortable
                        />
                        <Column
                          field="orderInvoiceNo"
                          filterField="orderInvoiceNo"
                          header="Invoice No"
                          sortable
                        />
                        <Column
                          field="shopName"
                          filterField="shopName"
                          header="Shop Name"
                          sortable
                        />
                        <Column
                          field="paymentMethodName"
                          filterField="paymentMethodName"
                          header="Payment Method"
                          body={paymentMethodTemplate}
                          sortable
                        />
                        <Column
                          field="paymentStatus"
                          filterField="paymentStatus"
                          header="Payment Status"
                          body={paymentStatusBodyTemplate}
                          sortable
                        />
                        <Column
                          field="totalAmount"
                          filterField="totalAmount"
                          header="Amount"
                          sortable
                        />
                        <Column header="Action" body={productDetailsTemplate} />
                      </DataTable>
                      <Paginator
                        totalPage={totalPage}
                        currentPage={currentPage}
                        itemPerPage={itemPerPage}
                        totalItems={totalItems}
                        items={fetchReturnOrderById?.data}
                        itemsPerPageOptions={[30, 60, 90, 100, 200, 300]}
                        handleItemPerPage={handleItemPerPage}
                        handleCurrentPage={handleCurrentPage}
                      />
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Return Order Details"
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width={1500}
      >
        <FetchReturnOrderTrackingSeller
          orderInfo={orderInfo}
          setVisible={setVisible}
          // status_for_api_call={status_for_api_call}
          setCounts={setCounts}
          counts={counts}
          setStatusId={setStatusId}
          setStatusName={setStatusName}
          statusId={statusId}
          statusName={statusName}
        />
      </Modal>
      <Modal
        title="Refund Returned Orders"
        centered
        visible={refundVisible}
        onOk={() => setRefundVisible(false)}
        onCancel={() => setRefundVisible(false)}
        width={1500}
      >
        <CancelRefundModal
          orderInfo={orderInfo}
          counts={counts}
          setCounts={setCounts}
          setRefundVisible={setRefundVisible}
        />
      </Modal>
      <Modal
        title="Refunded Cancel Orders"
        centered
        visible={refundedVisible}
        onOk={() => setRefundedVisible(false)}
        onCancel={() => setRefundedVisible(false)}
        width={1500}
      >
        <RefundedOrdersModal
          orderInfo={orderInfo}
          totalRefundAmount={totalRefundAmount}
        />
      </Modal>
    </div>
  );
}
