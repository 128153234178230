import http from "../../utils/httpClient"

class outletService {

    getAllOutlet = async () => {
        return await http.get("/api/Outlet/GetOutletAddress?getAll=Y")
    }

    createOrUpdateOutlet = async (data) => {
        return await http.post("/api/Outlet/CreateOrUpdateOutletAddress", data)
    }

}

export default new outletService()