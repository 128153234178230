import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getSellerOrderInvoiceRecord,
  getShopWiseOrderDetailsRecord,
} from "../../store/actions/orderAction";
import "./Invoice.css";
import SellerInvoiceAdminAllDisplay from "./SellerInvoiceAdminAllDisplay";
import SellerInvoiceAllDisplay from "./SellerInvoiceAllDisplay";
import SellerInvoiceCancelAdminAllDisplay from "./SellerInvoiceCancelAdminAllDisplay";
import SellerInvoiceCancelAllDisplay from "./SellerInvoiceCancelAllDisplay";
import SellerInvoiceReturnAdminAllDisplay from "./SellerInvoiceReturnAdminAllDisplay";
import SellerInvoiceReturnAllDisplay from "./SellerInvoiceReturnAllDisplay";
import SaRaLoader from "../shared/SaRaLoader/SaRaLoader";

export default function SellerInvoiceAll(props) {
  const dispatch = useDispatch();
  const [shopWiseOrderNoList, setShopWiseOrderNoList] = useState("");

  const invoiceTypeValue = props?.location?.invoiceType
    ? props?.location?.invoiceType
    : 0;

  const { getShopWiseOrderDetails, sellerOrderInvoice, invoiceLoading } =
    useSelector((state) => state.orderReducer);

  const AllInvoiceData = props?.location?.selectedOrders
    ? props?.location?.selectedOrders
    : "";

  const currencyFormat = (num) => {
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  const order_invoice_data = props?.location?.data;

  const filterTab = props?.location?.order_filter_tab;

  useEffect(
    () =>
      invoiceTypeValue === 1 &&
      order_invoice_data &&
      setShopWiseOrderNoList(
        order_invoice_data?.map(({ orderNo }) => orderNo).join(",")
      ),
    [invoiceTypeValue, order_invoice_data]
  );

  useEffect(() => {
    invoiceTypeValue === 1 &&
      order_invoice_data &&
      shopWiseOrderNoList &&
      dispatch(getSellerOrderInvoiceRecord(shopWiseOrderNoList));

    invoiceTypeValue !== 1 &&
      order_invoice_data &&
      dispatch(
        getShopWiseOrderDetailsRecord(
          order_invoice_data?.status,
          order_invoice_data?.invoiceNo,
          order_invoice_data?.ordersNoString
        )
      );
  }, [dispatch, invoiceTypeValue, order_invoice_data, shopWiseOrderNoList]);

  // For Back Button

  const navigate = () => {
    if (invoiceTypeValue === 1) {
      window.location.href = `/ManageOrder?status=${filterTab}`;
    }
    if (invoiceTypeValue === 2) {
      window.location.href = `/CancelSellerOrders?status=${filterTab}`;
    }
    if (invoiceTypeValue === 3) {
      window.location.href = `/ReturnOrders?status=${filterTab}`;
    }
    if (invoiceTypeValue === 4) {
      window.location.href = `/ManageOrdersAdmin?status=${filterTab}`;
    }
    if (invoiceTypeValue === 5) {
      window.location.href = `/ManageOrdersAdmin?status=${filterTab}`;
    }
    if (invoiceTypeValue === 6) {
      window.location.href = `/CancelOrders?status=${filterTab}`;
    }
    if (invoiceTypeValue === 7) {
      window.location.href = `/ReturnSellerOrders?status=${filterTab}`;
    }
  };

  return (
    <>
      <span className="seller_invoice__print_btn2">
        <button className="btn btn-primary" onClick={() => window.print()}>
          Print
        </button>
        <button className="btn btn-danger" onClick={navigate}>
          Close
        </button>
      </span>
      <div className="seller__print">
        {invoiceTypeValue === 1
          ? sellerOrderInvoice &&
            sellerOrderInvoice?.data?.map((invoice, index) => (
              // Seller Panel Manage Order
              <SellerInvoiceAllDisplay
                key={index}
                invoice={invoice}
                currencyFormat={currencyFormat}
              />
            ))
          : invoiceTypeValue === 2
          ? AllInvoiceData &&
            AllInvoiceData?.map((invoice, index) => (
              <SellerInvoiceCancelAllDisplay
                key={index}
                invoice={invoice}
                currencyFormat={currencyFormat}
              />
            ))
          : invoiceTypeValue === 3
          ? AllInvoiceData &&
            AllInvoiceData?.map((invoice, index) => (
              <SellerInvoiceReturnAdminAllDisplay
                invoice={invoice}
                key={invoice.orderProfileId}
                currencyFormat={currencyFormat}
              />
            ))
          : invoiceTypeValue === 4
          ? getShopWiseOrderDetails?.data &&
            getShopWiseOrderDetails?.data?.map((invoice, index) => (
              <SellerInvoiceAdminAllDisplay
                key={index}
                invoice={invoice}
                currencyFormat={currencyFormat}
              />
            ))
          : invoiceTypeValue === 5
          ? getShopWiseOrderDetails?.data &&
            getShopWiseOrderDetails?.data?.map((invoice, index) => (
              <SellerInvoiceAdminAllDisplay
                key={index}
                invoice={invoice}
                currencyFormat={currencyFormat}
              />
            ))
          : invoiceTypeValue === 7
          ? AllInvoiceData &&
            AllInvoiceData?.map((invoice, index) => (
              <SellerInvoiceReturnAllDisplay
                key={index}
                invoice={invoice}
                currencyFormat={currencyFormat}
              />
            ))
          : AllInvoiceData &&
            AllInvoiceData?.map((invoice, index) => (
              <SellerInvoiceCancelAdminAllDisplay
                key={index}
                invoice={invoice}
                currencyFormat={currencyFormat}
              />
            ))}
      </div>
      {invoiceLoading && <SaRaLoader />}
    </>
  );
}
