import http from "../../utils/httpClient"

class campaignService {
    createOrUpdateCampaign = async (data) => {
        return await http.post("/api/Campaign/CreateOrUpdatePromotionalCampaign", data)
    }

    getCampaign = async (query) => {
        return await http.get("/api/Campaign/GetPromotionalCampaign",{params: query})
    }

    addOrEditCampaignProductList = async (data) => {
        return await http.post(`/api/Campaign/AddProductsInCampaign?productIds=${data.selectedCampaignProductIds}&campaignId=${data.selectedCampaignId}&isActive=${data.isActive}&approveSellerRequest=${data.approveSellerRequest}`)
    }

    getAddibleCampaignProducts = async (query) => {
        return await http.get(
            '/api/Campaign/GetCampaignProductListByStatus', { params: query }
        )
            .then((response) => {
                return response;
            })
            .catch((err) => Promise.reject("Failed!", err))
    }

    getPendingCampaignProducts = async (query) => {
        return await http.get(
            '/api/Campaign/GetCampaignProductListByStatus', { params: query }
        )
            .then((response) => {
                return response;
            })
            .catch((err) => Promise.reject("Failed!", err))
    }

    getApprovedCampaignProducts = async (query) => {
        return await http.get(
            '/api/Campaign/GetCampaignProductListByStatus', { params: query }
        )
            .then((response) => {
                return response;
            })
            .catch((err) => Promise.reject("Failed!", err))
    }

    getStatusWiseCampaignProducts = async (query) => {
        return await http.get(
            '/api/Campaign/GetCampaignProductListByStatus', { params: query }
        )
            .then((response) => {
                return response;
            })
            .catch((err) => Promise.reject("Failed!", err))
    }

    campaignProductStatusChange = async (data) => {
        return await http.post(`/api/Campaign/CampaignProductActiveInactive?campaignId=${data?.campaignId}&productId=${data?.productId}&isActive=${data?.targetStatus}`)
    }

}
export default new campaignService()
