import {
    ADD_OR_EDIT_FESTIVAL_PRODUCT_LIST,
    ADD_OR_EDIT_FESTIVAL_PRODUCT_LIST_ERROR,
    ADD_OR_EDIT_FESTIVAL_PRODUCT_LIST_RESET,
    ADD_OR_EDIT_FESTIVAL_PRODUCT_LIST_SUCCESS,
    CREATE_OR_UPDATE_FESTIVAL_CATEGORY,
    CREATE_OR_UPDATE_FESTIVAL_CATEGORY_ERROR,
    CREATE_OR_UPDATE_FESTIVAL_CATEGORY_RESET,
    CREATE_OR_UPDATE_FESTIVAL_CATEGORY_SUCCESS,
    FESTIVAL_PRODUCT_STATUS_CHANGE,
    FESTIVAL_PRODUCT_STATUS_CHANGE_ERROR,
    FESTIVAL_PRODUCT_STATUS_CHANGE_RESET,
    FESTIVAL_PRODUCT_STATUS_CHANGE_SUCCESS,
    GET_ADDIBLE_FESTIVAL_PRODUCTS,
    GET_ADDIBLE_FESTIVAL_PRODUCTS_ERROR,
    GET_ADDIBLE_FESTIVAL_PRODUCTS_SUCCESS,
    GET_FESTIVAL_CATEGORY,
    GET_FESTIVAL_CATEGORY_ERROR,
    GET_FESTIVAL_CATEGORY_SUCCESS,
    GET_FESTIVAL_PRODUCTS,
    GET_FESTIVAL_PRODUCTS_ERROR,
    GET_FESTIVAL_PRODUCTS_SUCCESS,
} from "../actions/festiveAction"

const initialState = {
    festivalCategoryData: {},
    festivalCreateOrUpdateData: {},
    getAddibleFestivalProductsListData: {},
    getFestivalProductsData: {},
    festivalProductStatusChangeData: {},
    festivalProductListData: {},

    festivalCategoryLoading: false,
    festivalCategoryLoaded: false,
    festivalCategoryError: null,

    festivalCreateOrUpdateDataLoading: false,
    festivalCreateOrUpdateDataLoaded: false,
    festivalCreateOrUpdateDataError: null,

    getAddibleFestivalProductsListDataLoading: false,
    getAddibleFestivalProductsListDataLoaded: false,
    getAddibleFestivalProductsListDataError: null,

    getFestivalProductsDataLoading: false,
    getFestivalProductsDataLoaded: false,
    getFestivalProductsDataError: null,

    festivalProductStatusChangeLoading: false,
    festivalProductStatusChangeLoaded: false,
    festivalProductStatusChangeError: null,

    festivalProductListLoading: false,
    festivalProductListLoaded: false,
    festivalProductListError: null,
}

export default function festivalReducer(state = initialState, action) {
    switch (action.type) {

        case GET_FESTIVAL_CATEGORY:
            return {
                ...state,
                festivalCategoryLoading: true,
                festivalCategoryLoaded: false,
                festivalCategoryError: null,
            }

        case GET_FESTIVAL_CATEGORY_SUCCESS:
            return {
                ...state,
                festivalCategoryLoading: false,
                festivalCategoryLoaded: true,
                festivalCategoryData: action.payload.success,
            }

        case GET_FESTIVAL_CATEGORY_ERROR:
            return {
                ...state,
                festivalCategoryLoading: false,
                festivalCategoryLoaded: false,
                festivalCategoryError: action.payload.error,
                festivalCategoryData: [],
            }

        case CREATE_OR_UPDATE_FESTIVAL_CATEGORY:
            return {
                ...state,
                festivalCreateOrUpdateDataLoading: true,
                festivalCreateOrUpdateDataLoaded: false,
                festivalCreateOrUpdateDataError: null,
            }

        case CREATE_OR_UPDATE_FESTIVAL_CATEGORY_SUCCESS:
            return {
                ...state,
                festivalCreateOrUpdateDataLoading: false,
                festivalCreateOrUpdateDataLoaded: true,
                festivalCreateOrUpdateData: action.payload.success,
            }

        case CREATE_OR_UPDATE_FESTIVAL_CATEGORY_ERROR:
            return {
                ...state,
                festivalCreateOrUpdateDataLoading: false,
                festivalCreateOrUpdateDataLoaded: false,
                festivalCreateOrUpdateDataError: action.payload.error,
                festivalCreateOrUpdateData: [],
            }

        case CREATE_OR_UPDATE_FESTIVAL_CATEGORY_RESET:
            return {
                ...state,
                festivalCreateOrUpdateDataLoading: false,
                festivalCreateOrUpdateDataLoaded: false,
                festivalCreateOrUpdateDataError: null,
                festivalCreateOrUpdateData: {},
            }

        case ADD_OR_EDIT_FESTIVAL_PRODUCT_LIST:
            return {
                ...state,
                festivalProductListLoading: true,
                festivalProductListLoaded: false,
                festivalProductListError: null,
            }

        case ADD_OR_EDIT_FESTIVAL_PRODUCT_LIST_SUCCESS:
            return {
                ...state,
                festivalProductListLoading: false,
                festivalProductListLoaded: true,
                festivalProductListData: action.payload.success,
            }

        case ADD_OR_EDIT_FESTIVAL_PRODUCT_LIST_ERROR:
            return {
                ...state,
                festivalProductListLoading: false,
                festivalProductListLoaded: false,
                festivalProductListError: action.payload.error,
                festivalProductListData: [],
            }

        case ADD_OR_EDIT_FESTIVAL_PRODUCT_LIST_RESET:
            return {
                ...state,
                festivalProductListLoading: false,
                festivalProductListLoaded: false,
                festivalProductListError: null,
                festivalProductListData: {},
            }

        case GET_ADDIBLE_FESTIVAL_PRODUCTS:
            return {
                ...state,
                getAddibleFestivalProductsListDataLoading: true,
                getAddibleFestivalProductsListDataLoaded: false,
                getAddibleFestivalProductsListDataError: null,
            }

        case GET_ADDIBLE_FESTIVAL_PRODUCTS_SUCCESS:
            return {
                ...state,
                getAddibleFestivalProductsListDataLoading: false,
                getAddibleFestivalProductsListDataLoaded: true,
                getAddibleFestivalProductsListData: action.payload.success,
            }

        case GET_ADDIBLE_FESTIVAL_PRODUCTS_ERROR:
            return {
                ...state,
                getAddibleFestivalProductsListDataLoading: false,
                getAddibleFestivalProductsListDataLoaded: false,
                getAddibleFestivalProductsListDataError: action.payload.error,
                getAddibleFestivalProductsListData: [],
            }

        case GET_FESTIVAL_PRODUCTS:
            return {
                ...state,
                getFestivalProductsDataLoading: true,
                getFestivalProductsDataLoaded: false,
                getFestivalProductsDataError: null,
            }

        case GET_FESTIVAL_PRODUCTS_SUCCESS:
            return {
                ...state,
                getFestivalProductsDataLoading: false,
                getFestivalProductsDataLoaded: true,
                getFestivalProductsData: action.payload.success,
            }

        case GET_FESTIVAL_PRODUCTS_ERROR:
            return {
                ...state,
                getFestivalProductsDataLoading: false,
                getFestivalProductsDataLoaded: false,
                getFestivalProductsDataError: action.payload.error,
                getFestivalProductsData: [],
            }

        case FESTIVAL_PRODUCT_STATUS_CHANGE:
            return {
                ...state,
                festivalProductStatusChangeLoading: true,
                festivalProductStatusChangeLoaded: false,
                festivalProductStatusChangeError: null,
            }

        case FESTIVAL_PRODUCT_STATUS_CHANGE_SUCCESS:
            return {
                ...state,
                festivalProductStatusChangeLoading: false,
                festivalProductStatusChangeLoaded: true,
                festivalProductStatusChangeData: action.payload.success,
            }

        case FESTIVAL_PRODUCT_STATUS_CHANGE_ERROR:
            return {
                ...state,
                festivalProductStatusChangeLoading: false,
                festivalProductStatusChangeLoaded: false,
                festivalProductStatusChangeError: action.payload.error,
                festivalProductStatusChangeData: [],
            }

        case FESTIVAL_PRODUCT_STATUS_CHANGE_RESET:
            return {
                ...state,
                festivalProductStatusChangeLoading: false,
                festivalProductStatusChangeLoaded: false,
                festivalProductStatusChangeError: null,
                festivalProductStatusChangeData: {},
            }
        default:
            return state
    }
}