import React from "react";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";
import { Loader } from "../../containers";
import baseUrl from "../../utils/baseUrl";

const CreateOrUpdateOutlet = (props) => {
    return (
        <div className="page-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="panel panel-success">
                            <div className="panel-heading">
                                {" "}
                                {props.type === 1 ? 'Create' : 'Update'} Outlet Address{" "}
                                <span style={{ float: "right" }}>
                                    <Link to="/OutletList">
                                        <Icon.List className="text-light" />
                                    </Link>
                                </span>
                            </div>
                            <div className="panel-wrapper collapse in" aria-expanded="true">
                                <div className="panel-body">
                                    <form className="form-horizontal">
                                        <div className="form-body">

                                            <div className="row">
                                                <div className="col-md-4 col-sm-12">
                                                    <div className="form-group">
                                                        <label className="control_label">
                                                            Outlet Name{" "}
                                                            <span
                                                                aria-hidden="true"
                                                                style={{
                                                                    color: "red",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                *
                                                            </span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            placeholder="Enter Outlet Name Name"
                                                            name="outletName"
                                                            value={props?.outletName}
                                                            onChange={props?.handleChange}
                                                            className={
                                                                props?.errorOutletName?.length !== 0
                                                                    ? "errorClass form-control"
                                                                    : "form-control" && "form-control"
                                                            }
                                                        />
                                                        {props?.errorOutletName && (
                                                            <span className="error">
                                                                {props?.errorOutletName}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-sm-12">
                                                    <div className="form-group">
                                                        <label className="control_label">
                                                            Business Hours{" "}
                                                            <span
                                                                aria-hidden="true"
                                                                style={{
                                                                    color: "red",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                *
                                                            </span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            placeholder="Enter Business Hours"
                                                            name="businessHours"
                                                            value={props?.businessHours}
                                                            onChange={props?.handleChange}
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-sm-12">
                                                    <div className="form-group">
                                                        <label className="control_label">
                                                            Business Hours Note{" "}
                                                            <span
                                                                aria-hidden="true"
                                                                style={{
                                                                    color: "red",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                *
                                                            </span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            placeholder="Enter Business Hours Note"
                                                            name="businessHoursNote"
                                                            value={props?.businessHoursNote}
                                                            onChange={props?.handleChange}
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="form-group">
                                                        <label className="control_label">
                                                            Outlet Address
                                                            <span
                                                                aria-hidden="true"
                                                                style={{
                                                                    color: "red",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                *
                                                            </span>
                                                        </label>
                                                        <textarea
                                                            rows={3}
                                                            type='text'
                                                            placeholder='Enter Outlet Address'
                                                            name='outletAddress'
                                                            value={props.outletAddress}
                                                            onChange={props.handleChange}
                                                            className={'form-control'}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-6 col-sm-12">
                                                    <div className="form-group">
                                                        <label className="control_label">
                                                            Outlet Direction
                                                            <span
                                                                aria-hidden="true"
                                                                style={{
                                                                    color: "red",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                *
                                                            </span>
                                                        </label>
                                                        <textarea
                                                            rows={3}
                                                            type='text'
                                                            placeholder='Enter Outlet Direction'
                                                            name='outletDirection'
                                                            value={props.outletDirection}
                                                            onChange={props.handleChange}
                                                            className={'form-control'}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-4 col-sm-12">
                                                    <div className="form-group">
                                                        <label className="control_label">
                                                            Contact No. 1{" "}
                                                            <span
                                                                aria-hidden="true"
                                                                style={{
                                                                    color: "red",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                *
                                                            </span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            placeholder="Enter Contact No. 1"
                                                            name="contactNumOne"
                                                            value={props?.contactNumOne}
                                                            onChange={props?.handleChange}
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-sm-12">
                                                    <div className="form-group">
                                                        <label className="control_label">
                                                            Contact No. 2{" "}
                                                        </label>
                                                        <input
                                                            type="text"
                                                            placeholder="Enter Contact No. 2"
                                                            name="contactNumTwo"
                                                            value={props?.contactNumTwo}
                                                            onChange={props?.handleChange}
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-sm-12">
                                                    <div className="form-group">
                                                        <label className="control_label">
                                                            Contact No. 3{" "}
                                                        </label>
                                                        <input
                                                            type="text"
                                                            placeholder="Enter Contact No. 3"
                                                            name="contactNumThree"
                                                            value={props?.contactNumThree}
                                                            onChange={props?.handleChange}
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="form-group file-area">
                                                        <label className="control_label">
                                                            Outlet Image (650px x 650px){" "}
                                                            <span
                                                                aria-hidden="true"
                                                                style={{
                                                                    color: "red",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                *
                                                            </span>
                                                        </label>
                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <input
                                                                    type="file"
                                                                    accept="image/*"
                                                                    name="outletImage"
                                                                    required="required"
                                                                    className="form-control"
                                                                    onChange={props.outletImageHandler}
                                                                />

                                                                {props.outletImage === "" ? (
                                                                    <div className="file-dummy">
                                                                        <div className="default">
                                                                            Please Upload Outlet Image <br />
                                                                            Resolution: ( 650 x 650 )
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div className="file-dummy">
                                                                        <div className="success">
                                                                            Outlet Image Uploaded Successfully
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>

                                                            <div className="col-md-6 col-sm-6">
                                                                {props.showImage !== '' ? (
                                                                    <>
                                                                        <img
                                                                            src={props.showImage}
                                                                            className="thumb-md product-image"
                                                                            style={{ width: "100px", height: "100px" }}
                                                                            alt="showImage"
                                                                        />
                                                                    </>
                                                                ) : (
                                                                    <img
                                                                        src={baseUrl.concat(props.outletImage)}
                                                                        className="thumb-md product-image"
                                                                        style={{
                                                                            width: "100px",
                                                                            height: "100px",
                                                                            display: props.type === 1 ? "none" : ""
                                                                        }}
                                                                        alt="outletImage"
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-3 col-sm-12">
                                                    <div className="form-group">
                                                        <label className="control_label">Active </label>
                                                        <div className="checkbox checkbox-success">
                                                            <input
                                                                id="isActive"
                                                                type="checkbox"
                                                                name="isActive"
                                                                checked={props.isActive}
                                                                onChange={props.handleIsActiveCheck}
                                                            />
                                                            <label htmlFor="isActive"> &nbsp;Yes </label>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="form-footer ">
                                                <div className="form-group row">
                                                    <div className="text-center">
                                                        <div className="btn-group text-center">
                                                            {(props?.outletCreateOrUpdateDataLoading) ?
                                                                <div style={{ width: '65%', textAlign: 'center' }}>
                                                                    <Loader />
                                                                </div>
                                                                : <>
                                                                    <button
                                                                        type="submit"
                                                                        className="btn btn-success"
                                                                        onClick={props.saveOutlet}
                                                                    >
                                                                        {props.type === 1 ? 'Create' : 'Update'}
                                                                    </button>
                                                                    <Link to="/OutletList">
                                                                        <button
                                                                            className="btn btn-danger"
                                                                            style={{ cursor: "pointer" }}
                                                                        >
                                                                            Cancel
                                                                        </button>
                                                                    </Link>
                                                                </>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateOrUpdateOutlet