/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Navbar from '../../components/shared/navbar/Navbar';
import { getProfileByIdRecord } from '../../store/actions/profileAction';
import authService from '../../store/services/authService';
import authenticationService from '../../store/services/authenticationService';
import { removeLocalStorageAdmin } from '../../utils/Functions/removeLocalStorageAdmin';

const NavBarContainer = () => {
  const dispatch = useDispatch();
  let history = useHistory();

  const {
    profileById,
    error
  } = useSelector((state) => state.profileReducer);

  useEffect(() => {
    let userId = authService.getEmployeeId();
    let roleId = authenticationService.getRoleId();

    if (roleId !== "1") {
      removeLocalStorageAdmin()
      history?.push("/Login");
    }

    dispatch(getProfileByIdRecord(userId));
  }, [dispatch, history])

  useEffect(() => {
    if (error && profileById?.length == 0) {
      removeLocalStorageAdmin()
      history?.push("/Login");
    }
  }, [profileById, error])

  return (
    <div id="wrapper">
      <Navbar
        key="Navbar"
        profileById={profileById}
      />
    </div>
  )
}

export default NavBarContainer