/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import * as Icon from "react-feather";
import { useForm } from "react-hook-form";
import { Link, useHistory, useLocation } from "react-router-dom";
import * as yup from "yup";
import baseUrl from "../../../utils/baseUrl";
import httpClient from "../../../utils/httpClient";

const CreateAdsSchema = yup.object().shape({
    cityName: yup.string().required(),
    // countryCode: yup.string().required(),
    // stateCode: yup.string().required(),
    // stateId: yup.string().required(),
});

const EditCityContainer = () => {

    const navigateTo= useHistory();
    const { state } = useLocation();

    const [cityName, setCityName] = useState(state?.data?.cityName || '');
    const [stateCode, setStateCode] = useState(state?.data?.stateCode || '');
    const [stateId, setStateId] = useState(state?.data?.stateId || '');
    const [isActive, setIsActive] = useState(state?.data?.flag === 1 ? true : false);

    const isSellerPanel = false;
    
    // const dispatch = useDispatch();

    const handleChange = (e) => {
        e.preventDefault()
        const { target } = e
        switch (target.name) {
            case 'cityName':
                setCityName(target.value)
                break
            // case 'countryCode':
            //     setCountryCode(target.value)
            //     break
            case 'stateCode':
                setStateCode(target.value)
                break
            case 'stateId':
                setStateId(parseInt(target.value))
                break
            // case 'currencySymbol':
            //     setCurrencySymbol(target.value)
            //     break
            // case 'region':
            //     setRegion(target.value)
            //     break
            // case 'subregion':
            //     setSubRegion(target.value)
            //     break

            default:
        }
    }

    const handleIsActiveCheck = (e) => {
        setIsActive(e.target.checked)
    }
    const handleCityUpdate = async (e) => {
        e.preventDefault();

        try {
            const data = {
                countryId: 19,
                countryCode:"BD",
                cityId: state?.data?.cityId,
                cityName,
                stateId: 0,
                stateCode,
                stateId,
                flag: isActive ? 1 : 0
            }

            const res= await httpClient.post(`${baseUrl}/api/DivisionCitiesArea/CreateOrUpdateCity`, data);
            if(res?.data?.succeed){
                toast.success(res?.data?.message);
    
                setTimeout(() => {
                    navigateTo.push('/AllCityList');
                }, 2000);
            }
            
        } catch (error) {
            console.log({error})
            
        }
        // dispatch(createOrUpdateStoreBannerRecord(data));

    }

    const { register, handleSubmit, formState, errors } = useForm({ mode: "onChange", resolver: yupResolver(CreateAdsSchema) });

    const onSubmit = (data, e) => {
        e.target.reset();
    };


    return (
        <>
            <div className="page-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="panel panel-success">
                                <div className="panel-heading">
                                    Edit City
                                    <span style={{ float: "right" }}>
                                        {isSellerPanel ?
                                            <Link to="">
                                                <Icon.List className="text-light" />
                                            </Link>
                                            :
                                            <Link to="/AllCityList">
                                                <Icon.List className="text-light" />
                                            </Link>}
                                    </span>
                                </div>
                                <div className="panel-wrapper collapse in" aria-expanded="true">
                                    <div className="panel-body">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="form-body">

                                                <div className="p-fluid p-formgrid p-grid">
                                                    <div className="p-fluid p-col-12 ">
                                                        <div className="form-group">
                                                            <label className="control_label">
                                                                City Name
                                                                <span
                                                                    aria-hidden="true"
                                                                    style={{ color: "red", fontWeight: "bold" }}
                                                                >
                                                                    *
                                                                </span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                placeholder="Enter City Name"
                                                                name="cityName"
                                                                value={cityName}
                                                                onChange={handleChange}
                                                                className="form-control"
                                                                ref={register}
                                                            />
                                                            {errors?.cityName && (
                                                                <span className="error">
                                                                    {errors?.cityName?.message}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {/* <div className="p-fluid p-col-12 p-md-6">
                                                        <div className="form-group">
                                                            <label className="control_label">
                                                                Country Code
                                                                <span
                                                                    aria-hidden="true"
                                                                    style={{ color: "red", fontWeight: "bold" }}
                                                                >
                                                                    *
                                                                </span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                placeholder="Enter country code"
                                                                name="countryCode"
                                                                value={countryCode}
                                                                onChange={handleChange}
                                                                className="form-control"
                                                                ref={register}
                                                            />
                                                            {errors?.countryCode && (
                                                                <span className="error">
                                                                    {errors?.countryCode?.message}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div> */}
                                                </div>

                                                <div className="p-fluid p-formgrid p-grid">
                                                    <div className="p-fluid p-col-12 p-md-4">
                                                        <div className="form-group">
                                                            <label className="control_label">
                                                                State Code
                                                            </label>
                                                            <input
                                                                type="text"
                                                                placeholder="Enter state code"
                                                                name="stateCode"
                                                                value={stateCode}
                                                                onChange={handleChange}
                                                                className="form-control"
                                                                ref={register}
                                                            />
                                                            {errors?.stateCode && (
                                                                <span className="error">
                                                                    {errors?.stateCode?.message}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="p-fluid p-col-12 p-md-4">
                                                        <div className="form-group">
                                                            <label className="control_label">
                                                                State ID
                                                            </label>
                                                            <input
                                                                type="number"
                                                                placeholder="Enter state ID"
                                                                name="stateId"
                                                                value={stateId}
                                                                onChange={handleChange}
                                                                className="form-control"
                                                                ref={register}
                                                            />
                                                            {errors?.stateId && (
                                                                <span className="error">
                                                                    {errors?.stateId?.message}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* <div className="p-fluid p-col-12 p-md-4">
                                                        <div className="form-group">
                                                            <label className="control_label">
                                                                Currency Symbol
                                                                <span
                                                                    aria-hidden="true"
                                                                    style={{ color: "red", fontWeight: "bold" }}
                                                                >
                                                                    *
                                                                </span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                placeholder="Enter Currency Symbol"
                                                                name="currencySymbol"
                                                                value={currencySymbol}
                                                                onChange={handleChange}
                                                                className="form-control"
                                                                ref={register}
                                                            />
                                                            {errors?.currencySymbol && (
                                                                <span className="error">
                                                                    {errors?.currencySymbol?.message}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div> */}

                                                    <div className='p-fluid p-col-12 p-md-4 flex justify-center'>
                                                        <div className='form-group'>
                                                            <label className='control_label'>Active</label>
                                                            <div className='checkbox checkbox-success d-flex align-items-center'>
                                                                <input
                                                                    id='isActive'
                                                                    type='checkbox'
                                                                    name='isActive'
                                                                    checked={isActive}
                                                                    onChange={handleIsActiveCheck}
                                                                />
                                                                <label htmlFor='isActive'> &nbsp;Yes </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <div className="p-fluid p-formgrid p-grid">
                                                    <div className="p-fluid p-col-12 p-md-4">
                                                        <div className="form-group">
                                                            <label className="control_label">
                                                                Region
                                                                <span
                                                                    aria-hidden="true"
                                                                    style={{ color: "red", fontWeight: "bold" }}
                                                                >
                                                                    *
                                                                </span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                placeholder="Enter the Region Name"
                                                                name="region"
                                                                value={region}
                                                                onChange={handleChange}
                                                                className="form-control"
                                                                ref={register}
                                                            />
                                                            {errors?.region && (
                                                                <span className="error">
                                                                    {errors?.region?.message}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="p-fluid p-col-12 p-md-4">
                                                        <div className="form-group">
                                                            <label className="control_label">
                                                                Sub Region
                                                                <span
                                                                    aria-hidden="true"
                                                                    style={{ color: "red", fontWeight: "bold" }}
                                                                >
                                                                    *
                                                                </span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                placeholder="Enter sub region"
                                                                name="subregion"
                                                                value={subregion}
                                                                onChange={handleChange}
                                                                className="form-control"
                                                                ref={register}
                                                            />
                                                            {errors?.subregion && (
                                                                <span className="error">
                                                                    {errors?.subregion?.message}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className='p-fluid p-col-12 p-md-4 flex justify-center'>
                                                        <div className='form-group'>
                                                            <label className='control_label'>Active</label>
                                                            <div className='checkbox checkbox-success d-flex align-items-center'>
                                                                <input
                                                                    id='isActive'
                                                                    type='checkbox'
                                                                    name='isActive'
                                                                    checked={isActive}
                                                                    onChange={handleIsActiveCheck}
                                                                />
                                                                <label htmlFor='isActive'> &nbsp;Yes </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}


                                            </div>
                                            <div className="form-footer">
                                                <div className="form-group row">
                                                    <div className="text-center">
                                                        <div className="btn-group text-center">
                                                            <button
                                                                className="btn btn-success"
                                                                onClick={handleCityUpdate}
                                                                disabled={!formState?.isValid}
                                                                style={{ cursor: "pointer" }}
                                                            >
                                                                Create
                                                            </button>
                                                            <Link to="/Home">
                                                                <button
                                                                    className="btn btn-danger"
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    Cancel
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer autoClose={1500} />
        </>

    )
}

export default EditCityContainer;