import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingCard from "../../components/shared/LoadingCard";
import Message from "../../components/shared/Message";
import EditShippingCostModal from "../../components/shipping/shippingOptions/EditShippingCostModal";

import authenticationService from "../../store/services/authenticationService";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getShippingCostRecord, getShippingOptionsRecord, getShippingTypeRecord, updateShippingCostRecord } from "../../store/actions/shippingAction";
import { getAllCountryRecord } from "../../store/actions/addressAction";
import { getActiveBreadcrumbsProductCategoryRecord } from "../../store/actions/activeBreadcrumbsCategoryAction";
import { getUnitRecord } from "../../store/actions/unitAction";
import { getVerifiedShopRecord } from "../../store/actions/sellerAction";
import { getShopRecord } from "../../store/actions/shopAction";

const ShippingCostListContainer = () => {
  // const [shippingCost, setShippingCost] = useState(null);
  const [selectedShippingCost, setSelectedShippingCost] = useState(null);
  // const [shippingTypes, setShippingTypes] = useState("");
  const [shippingTypeId, setShippingTypeId] = useState(0);
  const [typeName, setTypeName] = useState("");
  const [getShippingType, setGetShippingType] = useState("");
  // const [shippingOptions, setShippingOptions] = useState("");
  const [shippingOptionsId, setShippingOptionsId] = useState(0);
  const [optionName, setOptionName] = useState("");
  const [getShippingOption, setGetShippingOption] = useState("");
  // const [allCountries, setAllCountries] = useState("");
  const [categories, setCategories] = useState("");
  // const [shops, setShops] = useState("");
  // const [units, setUnits] = useState("");
  const [isOpen,setIsOpen]=useState(false)
  const [singleRow, setSingleRow] = useState("");
  const [globalFilter, setGlobalFilter] = useState(null);
  // const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  

  const ref = useRef(null)
  const dispatch = useDispatch();
  const history= useHistory();

  const {shippingCost,shippingType,shippingOptions,loading, error}=useSelector((state)=>(state.shippingReducer))
 
  const {allCountries}=useSelector((state)=>(state.addressReducer));
  const {activeBreadcrumbsProductCategories}=useSelector((state)=>(state.activeBreadcrumbsCategoryReducer));
  const {units}=useSelector((state)=>(state.unitReducer));
  const { shops } = useSelector((state) => state?.shopReducer);
  const {sellers}=useSelector((state)=>(state.sellerReducer));

  
  useEffect(()=>{
    dispatch(getShippingCostRecord());
    dispatch(getShippingTypeRecord());
    dispatch(getShippingOptionsRecord());
    dispatch(getAllCountryRecord());
    dispatch(getActiveBreadcrumbsProductCategoryRecord());
    dispatch(getUnitRecord());
    dispatch(getVerifiedShopRecord());
    dispatch(getShopRecord());
  
  },[dispatch])

  useEffect(() => {
    //Begin Temporary Authentication
    let roleId = authenticationService.getRoleId();
    if (roleId === "1") {
      // setAuthenticated(true);
      // setLoginSuccessful(true);
    } else {
      // setAuthenticated(false);
      // setLoginSuccessful(false);
      history.push("/Login");
    }
    //End Temporary Authentication

    // fetchData();
  }, []);

  // const fetchData = async () => {
  //   await props.getShippingCostRecord();
  //   setShippingCost(props.data.sort((a, b) => (a.timeM > b.timeM ? 1 : -1)));

  //   await props.getShippingTypeRecord();
  //   setShippingTypes(
  //     props.shippingType.filter((item) => item.isActive === "Y")
  //   );

  //   await props.getShippingOptionsRecord();
  //   setShippingOptions(
  //     props.shippingOptions.filter((item) => item.isActive === "Y")
  //   );

  //   await props.getAllCountryRecord();
  //   setAllCountries(props.allCountries);

  //   await props.getActiveBreadcrumbsProductCategoryRecord();
  //   setCategories(
  //     props.categories.activeBreadcrumbsProductCategories
  //   );

  //   await props.getUnitRecord();
  //   setUnits(props.units);

  //   await props.getVerifiedShopRecord();
  //   setShops(props.shops);
  // };

  const handleChange = (e) => {
    const { target } = e;
    switch (target.name) {
      case "typeName":
        setTypeName(target.value);
        setShippingTypeId(target.value.shippingTypeId);
        break;
      case "optionName":
        setOptionName(target.value);
        setShippingOptionsId(target.value.shippingOptionsId);
        break;
      default:
    }
  };

  const filterData = async () => {
     await dispatch (getShippingCostRecord(shippingTypeId, shippingOptionsId));
  };

  const typeNameBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {rowData.typeName ? rowData.typeName : "N/A"}
      </React.Fragment>
    );
  };

  const optionNameBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {rowData.optionName ? rowData.optionName : "N/A"}
      </React.Fragment>
    );
  };

  const minValueBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {rowData.minValue
          ? rowData.minValue
          : rowData.shippingOptionsId === 2
          ? 0
          : "N/A"}
      </React.Fragment>
    );
  };

  const maxValueBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {rowData.maxValue
          ? rowData.maxValue
          : rowData.shippingOptionsId === 2
          ? 0
          : "N/A"}
      </React.Fragment>
    );
  };

  const actualCostBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {rowData.actualCost ? rowData.actualCost : 0}
      </React.Fragment>
    );
  };

  const shippingTotalCostBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {rowData.shippingTotalCost ? rowData.shippingTotalCost : 0}
      </React.Fragment>
    );
  };

  const countryNameBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {rowData.countryName ? rowData.countryName : "N/A"}
      </React.Fragment>
    );
  };

  const cityNameBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {rowData.cityName ? rowData.cityName : "N/A"}
      </React.Fragment>
    );
  };

  const areaNameBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {rowData.areaName ? rowData.areaName : "N/A"}
      </React.Fragment>
    );
  };

  const categoryNameBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {rowData?.categoryName ? rowData?.categoryName : "N/A"}
      </React.Fragment>
    );
  };

  const shopNameBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {rowData?.shopName ? rowData.shopName : "N/A"}
      </React.Fragment>
    );
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span
          className={
            rowData?.isActive === "Y"
              ? "p-tag p-tag-primary"
              : "p-tag p-tag-warning"
          }
        >
          {rowData?.isActive === "Y" ? "ACTIVE" : "INACTIVE"}
        </span>
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
       
        <Toast ref={ref} />
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success p-mr-2"
          onClick={() => {
            setSingleRow(rowData);
            setIsOpen(true)
           
            
          }}
          // editModal
          data-toggle="modal"
          data-target="#editModal"
          
        />
      </React.Fragment>
    );
  };

 

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Link to="/CreateShippingCost">
          <div className="button-demo">
            <Button
              icon="pi pi-times"
              className="p-button-rounded p-button-danger p-button-outlined"
            />
          </div>
        </Link>
      </React.Fragment>
    );
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="p-text-bold table-heading-style">
          List of Shipping Cost
        </div>
      </React.Fragment>
    );
  };

  const renderHeader = () => {
    return (
      <>
        <div className="table-header">
          <span className="p-input-icon-left">
            <InputText
              type="search"
              className="form-control text-center text-field"
              onInput={(e) => setGlobalFilter(e?.target?.value)}
              placeholder="Search here"
            />
          </span>
        </div>
      </>
    );
  };

  const updateShippingCost = async (data) => {
    const result = await  dispatch(updateShippingCostRecord(data));
  
    if (result && result.payload.success.succeed === true) {

      toast.success("Shipping Cost Updated Successfully");
      
      setTimeout(() => {
        history.push("/ShippingCostList");
      }, 2500);
      setSuccess(true);
      await dispatch(getShippingCostRecord(0, 0));
      history.push("/ShippingCostList");
      // setShippingCost(props.data);
      setSuccess(false);
    } else if (result && result.payload.success.succeed === false) {
   
      toast.error("Something went wrong, Please try again");
      setTimeout(() => {}, 2500);
    } else if (result.type === "UPDATE_SHIPPING_COST_SUCCESS") {
      toast.success("Shipping Cost Updated Successfully");
      setTimeout(() => {
      history.push("/ShippingCostList");
      }, 2500);
      setSuccess(true);
      await dispatch(getShippingCostRecord(0, 0));
      // await props.getShippingCostRecord(0, 0);
      // setShippingCost(props.data);
      setSuccess(false);
    } else {
      toast.error("Something went wrong, Please try again");
      setTimeout(() => {}, 2500);
    }
  };

 

  return (
    <>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div className="datatable-doc-demo">
                  <div className="card">
                    <Toolbar
                      className="p-mb-4"
                      right={rightToolbarTemplate}
                      left={leftToolbarTemplate}
                    ></Toolbar>

                    <Row>
                      <Col xs={12} md={4}>
                        <div className="form-group">
                          <label className="control_label">
                            Shipping Type Name
                          </label>
                          <div className="dropdown-demo">
                            <Dropdown
                              optionLabel="typeName"
                              options={shippingType}
                              filter
                              showClear
                              filterBy="typeName"
                              placeholder="Select Shipping Type"
                              name="typeName"
                              value={typeName}
                              onChange={handleChange}
                              className="form-control"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} md={4}>
                        <div className="form-group">
                          <label className="control_label">
                            Shipping Option Name
                          </label>
                          <div className="dropdown-demo">
                            <Dropdown
                              optionLabel="optionName"
                              options={shippingOptions}
                              filter
                              showClear
                              filterBy="optionName"
                              placeholder="Select Option Name"
                              name="optionName"
                              value={optionName}
                              onChange={handleChange}
                              className="form-control"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} md={1}>
                        <div className="form-group">
                          <label className="control_label">{""}</label>
                          <div className="dropdown-demo">
                            <button
                              type="submit"
                              className="btn btn-success"
                              onClick={filterData}
                            >
                              Filter
                            </button>
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} md={2}>
                        <div className="form-group">
                          <label className="control_label">{""}</label>
                          <InputText
                            type="search"
                            className="form-control text-center text-field"
                            onInput={(e) => setGlobalFilter(e.target.value)}
                            placeholder="Search Here"
                          />
                        </div>
                      </Col>
                    </Row>
                    {loading ? (
                      <LoadingCard count={1} />
                    ) : error ? (
                      <Message variant="danger">{error}</Message>
                    ) : (
                      <>
                        <DataTable
                          // ref={ref}
                          value={shippingCost}
                          className="p-datatable-customers"
                          dataKey="id"
                          rowHover
                          globalFilter={globalFilter}
                          paginator
                          rows={10}
                          emptyMessage="No Shipping Option found"
                          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                          rowsPerPageOptions={[10, 25, 50]}
                        >
                          <Column
                            field="Index"
                            header="SN"
                            body={(data, options) => options.rowIndex + 1}
                          />

                          <Column
                            sortField="typeName"
                            filterField="typeName"
                            header="Type Name"
                            body={typeNameBodyTemplate}
                            sortable
                          />
                          <Column
                            sortField="optionName"
                            filterField="optionName"
                            header="Option Name"
                            body={optionNameBodyTemplate}
                            sortable
                          />
                          <Column
                            sortField="categoryName"
                            filterField="categoryName"
                            header="Category Name"
                            body={categoryNameBodyTemplate}
                            sortable
                            style={{
                              display:
                                shippingOptionsId === 5 ? "block" : "none",
                            }}
                          />
                          <Column
                            sortField="shopName"
                            filterField="shopName"
                            header="Shop Name"
                            body={shopNameBodyTemplate}
                            sortable
                            style={{
                              display:
                                shippingOptionsId === 6 ? "block" : "none",
                            }}
                          />

                          <Column
                            sortField="countryName"
                            filterField="countryName"
                            header="Country"
                            body={countryNameBodyTemplate}
                            sortable
                          />
                          <Column
                            sortField="cityName"
                            filterField="cityName"
                            header="City"
                            body={cityNameBodyTemplate}
                            sortable
                          />
                          <Column
                            sortField="areaName"
                            filterField="areaName"
                            header="Area"
                            body={areaNameBodyTemplate}
                            sortable
                          />
                          <Column
                            sortField="minValue"
                            filterField="minValue"
                            header="Min Value"
                            body={minValueBodyTemplate}
                            sortable
                          />
                          <Column
                            sortField="maxValue"
                            filterField="maxValue"
                            header="Max Value"
                            body={maxValueBodyTemplate}
                            sortable
                          />
                          <Column
                            sortField="actualCost"
                            filterField="actualCost"
                            header="Cost"
                            body={actualCostBodyTemplate}
                            sortable
                          />
                          <Column
                            sortField="shippingTotalCost"
                            filterField="shippingTotalCost"
                            header="Total Cost"
                            body={shippingTotalCostBodyTemplate}
                            sortable
                          />
                          <Column
                            sortField="isActive"
                            filterField="isActive"
                            header="Status"
                            body={statusBodyTemplate}
                            sortable
                          />
                          <Column header="Action" body={actionBodyTemplate} />
                        </DataTable>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <EditShippingCostModal
            singleRow={singleRow}
            shippingOptions={shippingOptions}
            shippingTypes={shippingType}
            allCountries={allCountries}
            units={units}
            sellers={sellers}
            shops={shops}
            categories={activeBreadcrumbsProductCategories}
            updateShippingCost={updateShippingCost}
          />
        </div>
      </div>
      <ToastContainer autoClose={1500} />
    </>
  );
};

// const mapStateToProps = (state) => {
//   return {
//     data: state.shippingCost.data,
//     error: state.shippingCost.error,
//     shippingType: state.shippingType.data,
//     shippingOptions: state.shippingOptions.data,
//     allCountries: state.allCountries.data,
//     categories: state.categories,
//     units: state.unit.data,
//     shops: state.shops.data,
//     success: state.shippingCost.success,
//     loading: state.loading.loading,
//     message: state.shippingCost.message,
//   };
// };

// const mapDispatchToProps = (dispatch) => {
//   return {
//     getShippingCostRecord: (shippingTypeId, shippingOptionsId) =>
//       dispatch(
//         shippingAction.getShippingCostRecord(
//           shippingTypeId,
//           shippingOptionsId
//         )
//       ),
//     getShippingTypeRecord: () =>
//       dispatch(shippingAction.getShippingTypeRecord()),
//     getShippingOptionsRecord: () =>
//       dispatch(shippingAction.getShippingOptionsRecord()),
//     getAllCountryRecord: () => dispatch(addressAction.getAllCountryRecord()),
//     getActiveBreadcrumbsProductCategoryRecord: () =>
//       dispatch(
//         activeBreadcrumbsCategoryAction.getActiveBreadcrumbsProductCategoryRecord()
//       ),
//     getUnitRecord: () => dispatch(unitAction.getUnitRecord()),
//     getVerifiedShopRecord: () => dispatch(sellerAction.getVerifiedShopRecord()),
//     updateShippingCostRecord: (shippingCost) =>
//       dispatch(shippingAction.updateShippingCostRecord(shippingCost)),
//   };
// };

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(ShippingCostListContainer);

export default ShippingCostListContainer;