/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-loop-func */
/* eslint-disable no-lone-blocks */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAccountsDashboardDataRecord } from "../../../store/actions/accountsAction";
import toIsoString from "../../../utils/Functions/toIsoString";
import SaRaLoader from "../../shared/SaRaLoader/SaRaLoader";
import { accountsData } from "../accountsDashboard";
import "./HomeAccounts.css";
const HomeAccounts = () => {
  const dispatch = useDispatch();

  const [marketplaceData, setMarketplaceData] = useState([]);
  const [revenueData, setRevenueData] = useState([]);

  var currentDate = new Date();

  useEffect(() => {
    currentDate &&
      dispatch(
        getAccountsDashboardDataRecord(
          toIsoString(currentDate).substring(0, 10),
          toIsoString(currentDate).substring(0, 10),
          5
        )
      );
  }, []);

  const { accountsDashboardData, loading } = useSelector(
    (state) => state?.accountsReducer
  );

  useEffect(() => {
    accountsDashboardData?.data &&
      setMarketplaceData(accountsDashboardData?.data[0]?.marketplaceOrderData);
    accountsDashboardData?.data &&
      setRevenueData(
        accountsDashboardData?.data[0]?.marketplaceSummaryOrderData
      );
  }, [accountsDashboardData]);

  return (
    <div className="page-wrapper">
      <div className="container-fluid">
        <>
          {loading ? (
            <SaRaLoader />
          ) : (
            <div>
              <div className="accounts_dashboard__header_section">
                <div className="accounts_dashboard__header_big">
                  <span>SaRa Lifestyle Ltd.</span>
                </div>
                <div className="accounts_dashboard__header_medium">
                  <span>E-Commerce & Market Place Dashboard</span>
                </div>
                <div className="accounts_dashboard__header_date">
                  <span>Today ({moment(currentDate).format("D-MMMM-YYYY")})</span>
                </div>
              </div>
              <div className="accounts_dashboard__body_section">
                <div className="accounts_dashboard__ecommerce_section">
                  <div className="accounts_dashboard__ecommerce__header">
                    <div className="accounts_dashboard__ecommerce__header_part">
                      <span>E-Commerce</span>
                    </div>

                    <table className="accounts_table" style={{ width: "100%" }}>
                      <tr>
                        <th
                          style={{ backgroundColor: "#bdd7ee", width: "11%" }}
                          rowSpan={2}
                          className="accounts_dashboard__ecommerce__table_text accounts_table-th"
                        >
                          Category
                        </th>
                        <th
                          style={{ backgroundColor: "#bdd7ee" }}
                          colSpan={4}
                          className="accounts_dashboard__ecommerce__table_text_big accounts_table-th"
                        >
                          Order Qty.
                        </th>
                        <th
                          style={{ backgroundColor: "#bdd7ee" }}
                          colSpan={7}
                          className="accounts_dashboard__ecommerce__table_text_big accounts_table-th"
                        >
                          PoS Info
                        </th>
                      </tr>
                      <tr>
                        <td
                          style={{ backgroundColor: "#ddebf6", width: "7%" }}
                          className="accounts_dashboard__ecommerce__table_text"
                        >
                          Placed
                        </td>
                        <td
                          style={{ backgroundColor: "#ddebf6", width: "9%" }}
                          className="accounts_dashboard__ecommerce__table_text"
                        >
                          Cancelled
                        </td>
                        <td
                          style={{ backgroundColor: "#ddebf6", width: "7%" }}
                          className="accounts_dashboard__ecommerce__table_text"
                        >
                          Picked
                        </td>
                        <td
                          style={{ backgroundColor: "#ddebf6", width: "9%" }}
                          className="accounts_dashboard__ecommerce__table_text"
                        >
                          Delivered
                        </td>
                        <td
                          style={{ backgroundColor: "#ddebf6", width: "7%" }}
                          className="accounts_dashboard__ecommerce__table_text"
                        >
                          Sales Qty.
                        </td>
                        <td
                          style={{ backgroundColor: "#ddebf6", width: "9%" }}
                          className="accounts_dashboard__ecommerce__table_text"
                        >
                          Total MRP
                        </td>
                        <td
                          style={{ backgroundColor: "#ddebf6", width: "9%" }}
                          className="accounts_dashboard__ecommerce__table_text"
                        >
                          Discount Amount
                        </td>
                        <td
                          style={{ backgroundColor: "#ddebf6", width: "8%" }}
                          className="accounts_dashboard__ecommerce__table_text"
                        >
                          Exchange Amount
                        </td>
                        <td
                          style={{ backgroundColor: "#ddebf6", width: "8%" }}
                          className="accounts_dashboard__ecommerce__table_text"
                        >
                          Gross Sales
                        </td>
                        <td
                          style={{ backgroundColor: "#ddebf6", width: "8%" }}
                          className="accounts_dashboard__ecommerce__table_text"
                        >
                          VAT @ 7.5%
                        </td>
                        <td
                          style={{ backgroundColor: "#ddebf6", width: "8%" }}
                          className="accounts_dashboard__ecommerce__table_text"
                        >
                          Net Sales
                        </td>
                      </tr>
                      {accountsData[0]?.accountsEcommerce.map((data) => (
                        <tr>
                          <td
                            style={{
                              backgroundColor:
                                data.categoryName === "Total"
                                  ? "#bdd7ee"
                                  : "#ffffff",
                            }}
                            className="accounts_dashboard__ecommerce__table_text"
                          >
                            {data?.categoryName ? data?.categoryName : ""}
                          </td>
                          <td
                            style={{
                              backgroundColor:
                                data.categoryName === "Total"
                                  ? "#bdd7ee"
                                  : "#ffffff",
                            }}
                            className="accounts_dashboard__ecommerce__table_text"
                          >
                            {data?.placed ? data?.placed : "-"}
                          </td>
                          <td
                            style={{
                              backgroundColor:
                                data.categoryName === "Total"
                                  ? "#bdd7ee"
                                  : "#ffffff",
                            }}
                            className="accounts_dashboard__ecommerce__table_text"
                          >
                            {data?.cancelled ? data?.cancelled : "-"}
                          </td>
                          <td
                            style={{
                              backgroundColor:
                                data.categoryName === "Total"
                                  ? "#bdd7ee"
                                  : "#ffffff",
                            }}
                            className="accounts_dashboard__ecommerce__table_text"
                          >
                            {data?.picked ? data?.picked : "-"}
                          </td>
                          <td
                            style={{
                              backgroundColor:
                                data.categoryName === "Total"
                                  ? "#bdd7ee"
                                  : "#ffffff",
                            }}
                            className="accounts_dashboard__ecommerce__table_text"
                          >
                            {data?.delivered ? data?.delivered : "-"}
                          </td>
                          <td
                            style={{
                              backgroundColor:
                                data.categoryName === "Total"
                                  ? "#bdd7ee"
                                  : "#ffffff",
                            }}
                            className="accounts_dashboard__ecommerce__table_text"
                          >
                            {data?.salesQty ? data?.salesQty : "-"}
                          </td>
                          <td
                            style={{
                              backgroundColor:
                                data.categoryName === "Total"
                                  ? "#bdd7ee"
                                  : "#ffffff",
                            }}
                            className="accounts_dashboard__ecommerce__table_text"
                          >
                            {data?.totalMRP ? data?.totalMRP : "-"}
                          </td>
                          <td
                            style={{
                              backgroundColor:
                                data.categoryName === "Total"
                                  ? "#bdd7ee"
                                  : "#ffffff",
                            }}
                            className="accounts_dashboard__ecommerce__table_text"
                          >
                            {data?.discountAmount ? data?.discountAmount : "-"}
                          </td>
                          <td
                            style={{
                              backgroundColor:
                                data.categoryName === "Total"
                                  ? "#bdd7ee"
                                  : "#ffffff",
                            }}
                            className="accounts_dashboard__ecommerce__table_text"
                          >
                            {data?.exchangeAmount ? data?.exchangeAmount : "-"}
                          </td>
                          <td
                            style={{
                              backgroundColor:
                                data.categoryName === "Total"
                                  ? "#bdd7ee"
                                  : "#ffffff",
                            }}
                            className="accounts_dashboard__ecommerce__table_text"
                          >
                            {data?.grossSales ? data?.grossSales : "-"}
                          </td>
                          <td
                            style={{
                              backgroundColor:
                                data.categoryName === "Total"
                                  ? "#bdd7ee"
                                  : "#ffffff",
                            }}
                            className="accounts_dashboard__ecommerce__table_text"
                          >
                            {data?.vat75 ? data?.vat75 : "-"}
                          </td>
                          <td
                            style={{
                              backgroundColor:
                                data.categoryName === "Total"
                                  ? "#bdd7ee"
                                  : "#ffffff",
                            }}
                            className="accounts_dashboard__ecommerce__table_text"
                          >
                            {data?.netSales ? data?.netSales : "-"}
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>
                </div>

                <div className="accounts_dashboard__marketplace_section">
                  <div className="accounts_dashboard__marketplace__header">
                    <div className="accounts_dashboard__marketplace__header_part">
                      <span>Market Place</span>
                    </div>

                    <table className="accounts_table" style={{ width: "100%" }}>
                      <tr>
                        <th
                          style={{ backgroundColor: "#bdd7ee", width: "11%" }}
                          rowSpan={2}
                          className="accounts_dashboard__marketplace__table_text accounts_table-th"
                        >
                          Vendor
                        </th>
                        <th
                          style={{ backgroundColor: "#bdd7ee" }}
                          colSpan={11}
                          className="accounts_dashboard__marketplace__table_text_big accounts_table-th"
                        >
                          Sales Information
                        </th>
                      </tr>
                      <tr>
                        <td
                          style={{ backgroundColor: "#ddebf6", width: "7%" }}
                          className="accounts_dashboard__ecommerce__table_text"
                        >
                          Placed
                        </td>
                        <td
                          style={{ backgroundColor: "#ddebf6", width: "9%" }}
                          className="accounts_dashboard__ecommerce__table_text"
                        >
                          Cancelled
                        </td>
                        <td
                          style={{ backgroundColor: "#ddebf6", width: "7%" }}
                          className="accounts_dashboard__ecommerce__table_text"
                        >
                          Picked
                        </td>
                        <td
                          style={{ backgroundColor: "#ddebf6", width: "9%" }}
                          className="accounts_dashboard__ecommerce__table_text"
                        >
                          Delivered
                        </td>
                        <td
                          style={{ backgroundColor: "#ddebf6", width: "7%" }}
                          className="accounts_dashboard__ecommerce__table_text"
                        >
                          Sales Qty.
                        </td>
                        <td
                          style={{ backgroundColor: "#ddebf6", width: "9%" }}
                          className="accounts_dashboard__ecommerce__table_text"
                        >
                          Total MRP
                        </td>
                        <td
                          style={{ backgroundColor: "#ddebf6", width: "9%" }}
                          className="accounts_dashboard__ecommerce__table_text"
                        >
                          Discount Amount
                        </td>
                        <td
                          style={{ backgroundColor: "#ddebf6", width: "8%" }}
                          className="accounts_dashboard__ecommerce__table_text"
                        >
                          SaRa Exp. (Burn)
                        </td>
                        <td
                          style={{ backgroundColor: "#ddebf6", width: "8%" }}
                          className="accounts_dashboard__ecommerce__table_text"
                        >
                          Gross Sales
                        </td>
                        <td
                          style={{ backgroundColor: "#ddebf6", width: "8%" }}
                          className="accounts_dashboard__ecommerce__table_text"
                        >
                          Commission
                        </td>
                        <td
                          style={{ backgroundColor: "#ddebf6", width: "8%" }}
                          className="accounts_dashboard__ecommerce__table_text"
                        >
                          Net Payable
                        </td>
                      </tr>

                      {marketplaceData.map((data) => (
                        <tr>
                          <td
                            style={{
                              backgroundColor:
                                data.shopName === "Total" ? "#bdd7ee" : "#ffffff",
                            }}
                            className="accounts_dashboard__ecommerce__table_text"
                          >
                            {data?.shopName ? data?.shopName : ""}
                          </td>
                          <td
                            style={{
                              backgroundColor:
                                data.shopName === "Total" ? "#bdd7ee" : "#ffffff",
                            }}
                            className="accounts_dashboard__ecommerce__table_text"
                          >
                            {data?.cntConfirm !== null ? data?.cntConfirm : "-"}
                          </td>
                          <td
                            style={{
                              backgroundColor:
                                data.shopName === "Total" ? "#bdd7ee" : "#ffffff",
                            }}
                            className="accounts_dashboard__ecommerce__table_text"
                          >
                            {data?.cntCanceled !== null ? data?.cntCanceled : "-"}
                          </td>
                          <td
                            style={{
                              backgroundColor:
                                data.shopName === "Total" ? "#bdd7ee" : "#ffffff",
                            }}
                            className="accounts_dashboard__ecommerce__table_text"
                          >
                            {data?.cntPicked !== null ? data?.cntPicked : "-"}
                          </td>
                          <td
                            style={{
                              backgroundColor:
                                data.shopName === "Total" ? "#bdd7ee" : "#ffffff",
                            }}
                            className="accounts_dashboard__ecommerce__table_text"
                          >
                            {data?.cntDelivered !== null ? data?.cntDelivered : "-"}
                          </td>
                          <td
                            style={{
                              backgroundColor:
                                data.shopName === "Total" ? "#bdd7ee" : "#ffffff",
                            }}
                            className="accounts_dashboard__ecommerce__table_text"
                          >
                            {data?.salesQty !== null ? data?.salesQty : "-"}
                          </td>
                          <td
                            style={{
                              backgroundColor:
                                data.shopName === "Total" ? "#bdd7ee" : "#ffffff",
                            }}
                            className="accounts_dashboard__ecommerce__table_text"
                          >
                            {data?.deliveredAmount !== null
                              ? data?.deliveredAmount
                              : "-"}
                          </td>
                          <td
                            style={{
                              backgroundColor:
                                data.shopName === "Total" ? "#bdd7ee" : "#ffffff",
                            }}
                            className="accounts_dashboard__ecommerce__table_text"
                          >
                            {data?.discountAmount !== null
                              ? data?.discountAmount
                              : "-"}
                          </td>
                          <td
                            style={{
                              backgroundColor:
                                data.shopName === "Total" ? "#bdd7ee" : "#ffffff",
                            }}
                            className="accounts_dashboard__ecommerce__table_text"
                          >
                            {data?.burnAmount !== null ? data?.burnAmount : "-"}
                          </td>
                          <td
                            style={{
                              backgroundColor:
                                data.shopName === "Total" ? "#bdd7ee" : "#ffffff",
                            }}
                            className="accounts_dashboard__ecommerce__table_text"
                          >
                            {data?.grossAmount !== null ? data?.grossAmount : "-"}
                          </td>
                          <td
                            style={{
                              backgroundColor:
                                data.shopName === "Total" ? "#bdd7ee" : "#ffffff",
                            }}
                            className="accounts_dashboard__ecommerce__table_text"
                          >
                            {data?.commissionAmount !== null
                              ? data?.commissionAmount
                              : "-"}
                          </td>
                          <td
                            style={{
                              backgroundColor:
                                data.shopName === "Total" ? "#bdd7ee" : "#ffffff",
                            }}
                            className="accounts_dashboard__ecommerce__table_text"
                          >
                            {data?.netAmount !== null ? data?.netAmount : "-"}
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>
                </div>

                <div className="accounts_dashboard__total_section">
                  <table className="accounts_table" style={{ width: "100%" }}>
                    <tr>
                      <td
                        className="accounts_table-td"
                        style={{ width: "68%", padding: "3px" }}
                      >
                        <span className="accounts_total_text">
                          {" "}
                          Total Revenue from E-Commerce & Market Place (Today)
                        </span>
                      </td>
                      <td className="accounts_table-td">
                        <span className="accounts_total_value">
                          {revenueData[0]?.revenue !== null
                            ? revenueData[0]?.revenue
                            : "-"}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="accounts_table-td"
                        style={{ width: "68%", padding: "3px" }}
                      >
                        <span className="accounts_total_text">
                          {" "}
                          Total Revenue from E-Commerce & Market Place (Last 7 Days)
                        </span>
                      </td>
                      <td className="accounts_table-td">
                        <span className="accounts_total_value">
                          {revenueData[1]?.revenue !== null
                            ? revenueData[1]?.revenue
                            : "-"}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="accounts_table-td"
                        style={{ width: "68%", padding: "3px" }}
                      >
                        <span className="accounts_total_text">
                          {" "}
                          Total Revenue from E-Commerce & Market Place (Monthly)
                        </span>
                      </td>
                      <td className="accounts_table-td">
                        <span className="accounts_total_value">
                          {revenueData[2]?.revenue !== null
                            ? revenueData[2]?.revenue
                            : "-"}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="accounts_table-td"
                        style={{ width: "68%", padding: "3px" }}
                      >
                        <span className="accounts_total_text">
                          {" "}
                          Total Revenue from E-Commerce & Market Place (Yearly)
                        </span>
                      </td>
                      <td className="accounts_table-td">
                        <span className="accounts_total_value">
                          {revenueData[3]?.revenue !== null
                            ? revenueData[3]?.revenue
                            : "-"}
                        </span>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          )}
        </>
      </div>
    </div>
  );
};

export default HomeAccounts;
