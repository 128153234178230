import React from "react";
import "../css/SalesReportPrintAdmin.css";
import PaymentRealizationGeneratePDF from "./PaymentRealizationGeneratePDF";

const SalesReportPrintAdmin = (props) => {
  const navigate = () => {
    window.location.href = `/accounts?type=sales_report`;
  };

  return (
    <>
      <span className="SalesReport__print_btn">
        <button className="btn btn-primary" onClick={() => window.print()}>
          Print
        </button>
        <button className="btn btn-danger" onClick={navigate}>
          Close
        </button>
      </span>
      <div
        className="SalesReport__print_container"
        style={{ marginRight: "1vw" }}
      >
        {props.location.option === 6 && props.location.arrayDatas && (
          <PaymentRealizationGeneratePDF
            arrayDatas={props.location.arrayDatas}
            startDate={props.location.startDate}
            endDate={props.location.endDate}
          />
        )}
      </div>
    </>
  );
};

export default SalesReportPrintAdmin;
