/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import * as Icon from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { getCategoryRecord } from "../../../store/actions/categoryAction";
import { getPaymentMethodRecord } from "../../../store/actions/paymentMethodAction";
import { createPORecord, createPOReset, getPOProductListRecord } from "../../../store/actions/purchaseOrderAction";
import { getShopRecord } from "../../../store/actions/shopAction";
import SaRaLoader from "../../shared/SaRaLoader/SaRaLoader";
import PurchaseOrderAddProductListTable from "./PurchaseOrderAddProductListTable";
import PurchaseOrderAddedListTable from "./PurchaseOrderAddedListTable";

const PurchaseOrderNew = () => {

    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const prevState = location?.state?.state;

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(null);
    const [itemPerPage, setItemPerPage] = useState(prevState?.itemPerPage ? prevState?.itemPerPage : 15);
    const [totalItems, setTotalItems] = useState(null);

    const [searchKeyword, setSearchKeyword] = useState(prevState?.searchKeyword ? prevState?.searchKeyword : "");

    const [category, setCategory] = useState(prevState?.category ? prevState?.category : null);
    const [shop, setShop] = useState(prevState?.shop ? prevState?.shop : null);
    const [isFilter, setIsFilter] = useState(false);

    const [selectedPOProducts, setSelectedPOProducts] = useState([]);

    const [POAddedProduct, setPOAddedProduct] = useState([]);
    const [PODataWithPOQty, setPODataWithPOQty] = useState([]);
    const [PONote, setPONote] = useState('');

    const [selectPaymentMethod, setSelectPaymentMethod] = useState(null);

    const [POAddStatus, setPOAddStatus] = useState(false);

    const categories = useSelector((state) => state.categoryReducer.categories);
    const shops = useSelector((state) => state.shopReducer.shops);
    const paymentMethods = useSelector(
        (state) => state.paymentMethodReducer.paymentMethods
    );
    const { getPOProductsData, getPOProductsDataLoading, POCreateData, POCreateDataLoading } = useSelector(
        (state) => state.purchaseOrderReducer
    );

    useEffect(() => {
        if (history?.location?.state) {
            setCurrentPage(history.location?.state.state.currentPage);
            setItemPerPage(history.location?.state.state.itemPerPage);
        }
    }, []);

    const handleItemPerPage = (pagePerItems) => {
        setCurrentPage(1);
        setItemPerPage(pagePerItems);
    };

    const handleCurrentPage = (currentPage) => {
        if (isFilter) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage);
        }
    };

    useEffect(() => {
        dispatch(getCategoryRecord());
        dispatch(getShopRecord());
        dispatch(getPaymentMethodRecord());
    }, []);

    const onPaymentMethodChange = (e) => {
        setSelectPaymentMethod(e.value);
    };

    useEffect(() => {

        const productInfo = {
            categoryId: category?.categoryId,
            shopId: shop?.shopId,
            itemsPerPage: itemPerPage,
            currentPage: currentPage,
        }
        if (searchKeyword) {
            productInfo.keyword = searchKeyword;
            productInfo.currentPage = 1;
        }

        dispatch(getPOProductListRecord(productInfo))

        if (isFilter) {
            const url = `/CreatePurchaseOrder`;
            history.push(url, { state: { searchKeyword, category, shop } })
        }
        return () => setIsFilter(false)

    }, [dispatch, isFilter, currentPage, itemPerPage])

    useEffect(() => {
        setPODataWithPOQty(getPOProductsData.data)
    }, [dispatch, getPOProductsData])

    let tempPOAddedProduct = POAddedProduct;
    useEffect(() => {
        if (POAddStatus === true) {
            if (POAddedProduct.length == 0) {
                const uniqueShopIds = new Set();
                selectedPOProducts.forEach(obj => {
                    uniqueShopIds.add(obj.shopId);
                });
                const uniqueShopIdsArray = Array.from(uniqueShopIds);

                if (uniqueShopIdsArray.length !== 1) {
                    toast.error("Must Add same Vendor Product");
                } else {
                    POAddStatus === true && selectedPOProducts.map(data => {
                        if (data.productQuantity !== 0) {
                            tempPOAddedProduct.push(data)
                        } else {
                            toast.error("Product Quantity must be more then 0");
                        }
                    })
                }

            } else {
                POAddStatus === true && selectedPOProducts.map(data => {
                    if (POAddedProduct[0].shopId == data.shopId) {
                        if (data.productQuantity !== 0) {
                            if (hasSellerProductSku(POAddedProduct, data.sellerProductSku)) {
                                toast.error(`'${data.sellerProductSku}' Seller SKU Product already in list.`);
                            } else {
                                tempPOAddedProduct.push(data)
                            }
                        } else {
                            toast.error("Product Quantity must be more then 0");
                        }
                    } else {
                        toast.error("Must Add same Vendor Product");
                    }
                })
            }
        }

        POAddStatus === true && setPOAddedProduct(tempPOAddedProduct);
        POAddStatus === true && setPOAddStatus(false)
    }, [POAddStatus === true])

    function hasSellerProductSku(array, sellerProductSku) {
        return array.some(obj => obj.sellerProductSku === sellerProductSku);
    }

    useEffect(() => {
        setSelectedPOProducts([])
    }, [POAddStatus === false])

    const handleInputChange = (index, e) => {
        const newData = PODataWithPOQty.map((obj, idx) => {
            if (index === idx) {
                if (e.target.name === "productQuantity") {
                    return { ...obj, productQuantity: Number(e.target.value) };
                }
            }
            return obj;
        });

        setPODataWithPOQty(newData)
    }

    const POSubmitHandler = () => {
        const POData =
        {
            shopId: POAddedProduct[0]?.shopId,
            paymentMethodId: selectPaymentMethod?.paymentMethodId,
            remarks: PONote,
            items: POAddedProduct.map(data => (
                {
                    productId: data?.productId,
                    productName: data?.productName,
                    shopProductSku: data?.shopProductSku,
                    sellerProductSku: data?.sellerProductSku,
                    productVariant: data?.productVariant,
                    productQuantity: Number(data.productQuantity),
                    productPrice: data?.price,
                }
            ))
        }

        dispatch(createPORecord(POData))
    }

    useEffect(() => {
        if (POCreateData.data) {
            if (POCreateData && POCreateData?.data?.succeed === true) {
                toast.success('Purchase Order Created Successfully')
                dispatch(createPOReset())
                setTimeout(() => {
                    history.push('/PurchaseOrderList')
                }, 2500)

            } else if (POCreateData && POCreateData?.data?.message === "Save Success") {
                toast.success('Purchase Order Created Successfully')
                dispatch(createPOReset())
                setTimeout(() => {
                    history.push('/PurchaseOrderList')
                }, 2500)

            } else if (POCreateData && POCreateData?.data?.succeed === false) {
                toast.error('Something went wrong, Please try again')
                dispatch(createPOReset())

            }
        }
    }, [POCreateData.data])

    const handlePOAddedProductInputChange = (index, e) => {
        const newData = POAddedProduct.map((obj, idx) => {
            if (index === idx) {
                if (e.target.name === "productQuantity") {
                    return { ...obj, productQuantity: Number(e.target.value) };
                }
            }
            return obj;
        });

        setPOAddedProduct(newData)
    }

    return (
        <div className="page-wrapper">
            <ToastContainer autoClose={2000} />
            {POCreateDataLoading ? <SaRaLoader /> :
                <div className="container-fluid1" style={{ paddingTop: '20px' }}>
                    <div className="col-md-12">
                        <div className="white-box">
                            <div className="panel panel-success">
                                <div className="panel-heading">
                                    {" "}
                                    Create Purchase Order{" "}
                                    <span style={{ float: "right" }}>
                                        <Link to="/PurchaseOrderList?page=1">
                                            <Icon.List className="text-light" />
                                        </Link>
                                    </span>
                                </div>
                                <div className="datatable-doc-demo">
                                    <div className="card1">
                                        <PurchaseOrderAddProductListTable
                                            handleItemPerPage={handleItemPerPage}
                                            handleCurrentPage={handleCurrentPage}
                                            currentPage={currentPage}
                                            totalPage={totalPage}
                                            setTotalPage={setTotalPage}
                                            itemPerPage={itemPerPage}
                                            totalItems={totalItems}
                                            setTotalItems={setTotalItems}
                                            loading={getPOProductsDataLoading}

                                            setSearchKeyword={setSearchKeyword}
                                            searchKeyword={searchKeyword}
                                            categories={categories}
                                            setCategory={setCategory}
                                            category={category}
                                            setIsFilter={setIsFilter}
                                            setCurrentPage={setCurrentPage}

                                            shops={shops}
                                            shop={shop}
                                            setShop={setShop}
                                            PODataWithPOQty={PODataWithPOQty}
                                            product_list={getPOProductsData}
                                            selectedPOProducts={selectedPOProducts}
                                            setSelectedPOProducts={setSelectedPOProducts}
                                            handleInputChange={handleInputChange}
                                            setPOAddStatus={setPOAddStatus}
                                        />
                                    </div>
                                </div>
                            </div>

                            <PurchaseOrderAddedListTable
                                POAddedProduct={POAddedProduct}
                                setPOAddedProduct={setPOAddedProduct}
                                handlePOAddedProductInputChange={handlePOAddedProductInputChange}
                            />

                            <div className="row" style={{ marginTop: '20px' }}>
                                <div className="col-md-2 col-sm-12">
                                    <div className="form-group">
                                        <label className="control_label">
                                            Payment Method <span
                                                aria-hidden="true"
                                                style={{
                                                    color: "red",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                *
                                            </span>
                                        </label>
                                        <div
                                            style={{
                                                margin: "0 2px",
                                                width: "220px",
                                                position: "relative",
                                            }}
                                        >
                                            <Dropdown
                                                style={{
                                                    width: "100%",
                                                }}
                                                value={selectPaymentMethod}
                                                options={paymentMethods}
                                                onChange={onPaymentMethodChange}
                                                optionLabel="methodName"
                                                placeholder="Select Payment Method"
                                                filter
                                                showClear
                                            />
                                            {selectPaymentMethod !== null ? (
                                                <button
                                                    style={{
                                                        position: "absolute",
                                                        top: "6px",
                                                        right: "8px",
                                                        borderRadius: "100%",
                                                        border: "1px solid #ced4da",
                                                        height: "25px",
                                                        width: "25px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                    }}
                                                    onClick={() => {
                                                        setSelectPaymentMethod(null);
                                                    }}
                                                >
                                                    <i className="pi pi-times"></i>
                                                </button>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label className="control_label">
                                            P.O Note
                                        </label>
                                        <textarea
                                            rows={3}
                                            type='text'
                                            placeholder='Enter P.O Note'
                                            name='PONote'
                                            value={PONote}
                                            onChange={(e) => setPONote(e.target.value)}
                                            className={'form-control'}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-4 col-sm-12">
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Button
                                            disabled={POAddedProduct.length == 0 || !selectPaymentMethod?.paymentMethodId}
                                            style={{
                                                padding: "7px 20px",
                                                fontSize: "17px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                width: "80%",
                                                marginTop: '3vh'
                                            }}
                                            onClick={POSubmitHandler}
                                        >
                                            Confirm P.O{" "}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            }
        </div>
    )
}

export default PurchaseOrderNew
