import React from "react";
import "../css/PaymentTypeWiseSalesSummarySmallData.css";

const PaymentTypeWiseSalesSummarySmallData = ({ data, methodName }) => {
  const sumWithInitial = data.reduce(
    (accumulator, currentValue) =>
      accumulator + parseFloat(currentValue?.netAmount),
    0
  );
  return (
    <div className="report_PaymentTypeWiseSalesSummary_small_data_container">
      <div className="report_PaymentTypeWiseSalesSummary_small_data_container_payment_method">
        {methodName}
      </div>
      <div>
        {data.map((info, i) => (
          <div className="report_PaymentTypeWiseSalesSummary_small_data_container_items">
            <div style={{ width: "5%", textAlign: "center" }}>{i + 1}</div>
            <div style={{ width: "50%", textAlign: "center" }}>
              {info?.shopName}
            </div>
            <div style={{ width: "25%", textAlign: "center" }}>
              {info?.netAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
            </div>
          </div>
        ))}
      </div>
      <div className="report_PaymentTypeWiseSalesSummary_small_data_container_payment_method_total_container">
        <div className="report_PaymentTypeWiseSalesSummary_small_data_container_payment_method_total">
          {" "}
          <div style={{ width: "65%", textAlign: "center" }}>
            {methodName} Total:
          </div>
          <div style={{ width: "35%", textAlign: "center" }}>
            {sumWithInitial.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
          </div>
        </div>
      </div>
      <br />
    </div>
  );
};

export default PaymentTypeWiseSalesSummarySmallData;
