import moment from "moment";
import React from "react";
import convertNumberToWords from "../../../utils/Functions/convertNumberToWords";
import Meta from "../../../utils/Meta";
import "../css/PurchaseOrderGenerateViewNew.css";

const PurchaseOrderGeneratePDFView = ({ data }) => {
  const currentDateTime = new Date();

  return (
    <>
      <Meta title={`${data.sellerName}-${moment(data?.poDate).format("D-MMM-YYYY")}-PO`} />
      <div
        className="seller_invoice_container"
        style={{ marginBottom: "100vh", position: "relative", height: "114%" }}
      >
        <div className="purchase_order__generate_header">
          <div style={{ width: "100%" }}>
            <div
              className="purchase_order__report_header_text1"
              style={{ marginTop: "2vh", marginBottom: "2px" }}
            >
              SaRa Lifestyle Ltd.
            </div>
            <div className="purchase_order__generate_header_text2">
              Address: House-966, Avenue-2, Mirpur DOHS, Dhaka - 1216,
              Bangladesh.
            </div>
            <div className="purchase_order__generate_header_text2">
              Phone: +88-01885-998899, E-mail : operation@saralifestyle.com,
              Web: www.saralifestyle.com
            </div>
          </div>
        </div>

        <div className="purchase_order__generate_body_data_row_center">
          <div
            className="purchase_order__generate_body_data_text1"
            style={{ fontWeight: 700, textDecoration: "underline" }}
          >
            <span style={{ paddingBottom: "1px" }}>Purchase Order (PO)</span>
          </div>
        </div>

        <div className="purchase_order__generate_supplier_info">
          <div className="purchase_order__generate_body_data_row_container">
            <div className="purchase_order__generate_body_data_row_left">
              <div
                style={{ width: "27%" }}
                className="purchase_order__generate_body_data_text1"
              >
                Date:
              </div>
              <div
                style={{ width: "63%" }}
                className="purchase_order__generate_body_data_text2"
              >
                {moment(data?.poDate).format("D-MMM-YYYY")}
              </div>
            </div>
            <div className="purchase_order__generate_body_data_row_right_container">
              <div className="purchase_order__generate_body_data_row_right">
                <div className="purchase_order__generate_body_data_text1">
                  PO Number:
                </div>
                <div>&nbsp;</div>
                <div className="purchase_order__generate_body_data_text2">
                  {data?.poNo}
                </div>
              </div>
            </div>
          </div>

          <div className="purchase_order__generate_body_data_row">
            <div
              style={{ width: "100%" }}
              className="purchase_order__generate_body_data_text_header"
            >
              Supplier Details
            </div>
          </div>

          <div className="purchase_order__generate_body_data_row">
            <div
              style={{ width: "7.5%" }}
              className="purchase_order__generate_body_data_text1"
            >
              Name:
            </div>
            <div
              style={{ width: "92.5%" }}
              className="purchase_order__generate_body_data_text2"
            >
              {data.sellerName}
            </div>
          </div>

          <div className="purchase_order__generate_body_data_row">
            <div
              style={{ width: "7.5%" }}
              className="purchase_order__generate_body_data_text1"
            >
              Address:
            </div>
            <div
              style={{ width: "92.5%" }}
              className="purchase_order__generate_body_data_text2"
            >
              {data.sellerAddress}
            </div>
          </div>

          <div className="purchase_order__generate_body_data_row_container">
            <div className="purchase_order__generate_body_data_row_left">
              <div
                style={{ width: "27%" }}
                className="purchase_order__generate_body_data_text1"
              >
                Contact:
              </div>
              <div
                style={{ width: "63%" }}
                className="purchase_order__generate_body_data_text2"
              >
                {data?.sellerContactNo}
              </div>
            </div>

            <div className="purchase_order__generate_body_data_row_right_container">
              <div className="purchase_order__generate_body_data_row_right">
                <div className="purchase_order__generate_body_data_text1">
                  Created By:
                </div>
                <div>&nbsp;</div>
                <div className="purchase_order__generate_body_data_text2">
                  {data?.createBy}
                </div>
              </div>
            </div>
          </div>

          <div className="purchase_order__generate_body_data_row">
            <div
              style={{ width: "7.5%" }}
              className="purchase_order__generate_body_data_text1"
            >
              Note:
            </div>
            <div
              style={{ width: "92.5%" }}
              className="purchase_order__generate_body_data_text2"
            >
              {data?.remarks}
            </div>
          </div>
        </div>

        <div className="purchase_order__generate_products_Information_table">
          <div className="purchase_order__generate_products_Information_table_header_data_row">
            <div
              style={{
                width: "5%",
                borderRight: "1px solid #000",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                padding: "1px",
              }}
              className="purchase_order__generate_products_Information_table_header_text1"
            >
              SL.
            </div>
            <div
              style={{
                width: "26%",
                borderRight: "1px solid #000",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // textAlign: "center",
                padding: "1px",
              }}
              className="purchase_order__generate_products_Information_table_header_text1"
            >
              Product Name
            </div>
            <div
              style={{
                minWidth: "15%",
                maxWidth: "15%",
                borderRight: "1px solid #000",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                padding: "1px",
              }}
              className="purchase_order__generate_products_Information_table_header_text1"
            >
              Seller Product SKU
            </div>
            <div
              style={{
                width: "14%",
                borderRight: "1px solid #000",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                padding: "1px",
              }}
              className="purchase_order__generate_products_Information_table_header_text1"
            >
              Variant
            </div>
            <div
              style={{
                width: "10%",
                borderRight: "1px solid #000",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                padding: "1px",
              }}
              className="purchase_order__generate_products_Information_table_header_text1"
            >
              Qty
            </div>
            <div
              style={{
                width: "15%",
                borderRight: "1px solid #000",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                padding: "1px",
              }}
              className="purchase_order__generate_products_Information_table_header_text1"
            >
              Unit Price
            </div>
            <div
              style={{
                width: "15%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                padding: "1px",
              }}
              className="purchase_order__generate_products_Information_table_header_text1"
            >
              Total Price
            </div>
          </div>
          {data.items.map((data, index) => (
            <div className="purchase_order__generate_products_Information_table_products_data_row">
              <div
                style={{
                  minWidth: "5%",
                  maxWidth: "5%",
                  borderRight: "1px solid #000",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: "3px 2px",
                }}
                className="purchase_order__generate_products_Information_table_products_text1"
              >
                {index + 1}
              </div>
              <div
                style={{
                  minWidth: "26%",
                  maxWidth: "26%",
                  borderRight: "1px solid #000",
                  // display: "flex",
                  // alignItems: "center",
                  // justifyContent: "center",
                  textAlign: "center",
                  padding: "3px 5px",
                  fontSize: "11px",
                  wordWrap: "break-word",
                }}
                className="purchase_order__generate_products_Information_table_products_text1"
              >
                {data?.productName}
              </div>
              <div
                style={{
                  minWidth: "15%",
                  maxWidth: "15%",
                  borderRight: "1px solid #000",
                  // display: "flex",
                  // alignItems: "center",
                  // justifyContent: "center",
                  textAlign: "center",
                  padding: "2px",
                  wordWrap: "break-word",
                }}
                className="purchase_order__generate_products_Information_table_products_text1"
              >
                {data?.sellerProductSku}
              </div>
              <div
                style={{
                  minWidth: "14%",
                  maxWidth: "14%",
                  borderRight: "1px solid #000",
                  display: "flex",
                  alignItems: "center",
                  // justifyContent: "center",
                  textAlign: "center",
                  padding: "3px 5px",
                }}
                className="purchase_order__generate_products_Information_table_products_text1"
              >
                {data?.productVariant}
              </div>
              <div
                style={{
                  width: "10%",
                  borderRight: "1px solid #000",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: "1px",
                }}
                className="purchase_order__generate_products_Information_table_products_text1"
              >
                {data?.productQuantity}
              </div>
              <div
                style={{
                  width: "15%",
                  borderRight: "1px solid #000",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: "1px",
                }}
                className="purchase_order__generate_products_Information_table_products_text1"
              >
                {data?.productPrice
                  ?.toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
              </div>
              <div
                style={{
                  width: "15%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: "1px",
                }}
                className="purchase_order__generate_products_Information_table_products_text1"
              >
                {data?.amount?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
              </div>
            </div>
          ))}
        </div>

        <div className="purchase_order__generate_products_Information_total_table">
          <div className="purchase_order__generate_products_Information_table_header_data_row">
            <div
              style={{
                width: "60%",
                borderRight: "1px solid #000",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                padding: "1px",
              }}
              className="purchase_order__generate_products_Information_table_header_text1"
            >
              Total Quantity
            </div>
            <div
              style={{
                width: "10%",
                borderRight: "1px solid #000",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                padding: "1px",
              }}
              className="purchase_order__generate_products_Information_table_header_text1"
            >
              {data?.items.reduce(
                (acc, product) => acc + Number(product.productQuantity),
                0
              )}
            </div>
            <div
              style={{
                width: "15%",
                borderRight: "1px solid #000",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                padding: "1px",
              }}
              className="purchase_order__generate_products_Information_table_header_text1"
            >
              Total Amount
            </div>
            <div
              style={{
                width: "15%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                padding: "1px",
              }}
              className="purchase_order__generate_products_Information_table_header_text1"
            >
              {data?.items
                .reduce((acc, product) => acc + Number(product.amount), 0)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
            </div>
          </div>
        </div>

        <div className="purchase_order__generate_payment_detail">
          <div className="purchase_order__generate_payment_detail_data_row">
            <div
              style={{ width: "100%" }}
              className="purchase_order__generate_body_data_text1"
            >
              In Words:{" "}
              <span style={{ fontWeight: 700 }}>
                {convertNumberToWords(
                  data.items.reduce(
                    (acc, product) => acc + Number(product.amount),
                    0
                  )
                )}
              </span>
            </div>
            <div
              style={{ width: "100%" }}
              className="purchase_order__generate_body_data_text1"
            >
              Payment Method: {data.paymentMethodName}
            </div>
          </div>
        </div>

        {/* <div className="purchase_order__generate_payment_signature">
          <div className="purchase_order__generate_payment_signature_data_row">
            <div
              style={{ width: "100%" }}
              className="purchase_order__generate_body_data_text1"
            >
              Thank You
            </div>
          </div>
          <div className="purchase_order__generate_payment_signature_gap">
            <div
              style={{ width: "100%" }}
              className="purchase_order__generate_body_data_text1"
            ></div>
          </div>
          <div className="purchase_order__generate_payment_signature_info">
            <div style={{ borderTop: "1px solid #000", width: "15vw" }}></div>
            <div
              style={{ width: "100%" }}
              className="purchase_order__generate_body_data_text1"
            >
              Md. Hasan Ahmed
            </div>
            <div
              style={{ width: "100%" }}
              className="purchase_order__generate_body_data_text1"
            >
              Asst. Manager
            </div>
            <div
              style={{ width: "100%" }}
              className="purchase_order__generate_body_data_text1"
            >
              SaRa Lifestyle Ltd.
            </div>
          </div>
        </div> */}

        <div style={{ position: "absolute", bottom: 0 }}>
          <div className="purchase_order__generate_body_footer_container"></div>
          <div className="purchase_order__generate_body_footer">
            <div className="purchase_order__generate_body_footer_left">
              {/* <div className="purchase_order__generate_body_data_row_right">
                <div className="purchase_order__generate_body_data_text1">
                  Print Date & Time:
                </div>
                <div>&nbsp;</div>
                <div className="purchase_order__generate_body_data_text2">
                  {`${moment(currentDateTime.toLocaleDateString()).format(
                    "D-MMM-YYYY"
                  )} ${currentDateTime.toLocaleTimeString()}`}
                </div>
              </div> */}
            </div>

            <div className="purchase_order__generate_body_footer_right">
              {/* Note: It's a computer generated report, no signature is required. */}
              <div className="purchase_order__generate_body_data_row_right">
                <div className="purchase_order__generate_body_data_text1">
                  Print Date & Time:
                </div>
                <div>&nbsp;</div>
                <div className="purchase_order__generate_body_data_text2">
                  {`${moment(currentDateTime.toLocaleDateString()).format(
                    "D-MMM-YYYY"
                  )} ${currentDateTime.toLocaleTimeString()}`}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PurchaseOrderGeneratePDFView;

