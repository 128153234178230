import React from "react";

const Footer = () => {
    return (
        <div>
            {/* <footer className="footer t-a-c" style={{position: "sticky", left: 0, bottom: 0, width: "100%"}}>
                  © 2021 SaRa Lifestyle Ltd
              </footer> */}
        </div>
    );
};

export default Footer;
