/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from "react-router-dom";
// import { getStoreBannerRecord } from '../../store/actions/storeAction';
import LoadingCard from "../../../components/shared/LoadingCard";
import Message from "../../../components/shared/Message";
import { getAllCountryRecord } from "../../../store/actions/addressAction";


const CountryListContainer = () => {
  const dispatch = useDispatch();
  const navigateTo = useHistory();

  const [globalFilter, setGlobalFilter] = useState('')
  const [productImageHoverImage, setProductImageHoverImage] = useState(null);

  useEffect(() => {
    dispatch(getAllCountryRecord())
  }, [dispatch]);

  const countries = useSelector((state) => state.addressReducer.allCountries);

  const { getAllBannerData, loading, error } = useSelector(
    (state) => state.storeReducer
  );

  console.log({ countries })

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="p-text-bold table-heading-style">
          All Cotuntry List
        </div>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Link to="/CreateCountry">
          <div className="button-demo">
            <Button
              icon="pi pi-times"
              className="p-button-rounded p-button-danger p-button-outlined"
            />
          </div>
        </Link>
      </React.Fragment>
    );
  };

  const onIndexTemplate = (rowData, props) => {
    return props.rowIndex + 1;
  };

  const shopNameBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {rowData.countryName}
      </React.Fragment>
    )
  }

  const phoneCodeBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        +{rowData.phoneCode}
      </React.Fragment>
    )
  }

  // const imageBodyTemplate2 = (rowData) => {
  //   const handleMouseEnter = (data) => {
  //     setProductImageHoverImage(data.sellerStoreBannerImageUrl);
  //   };
  //   return (
  //     <div style={{ position: "relative" }}>
  //       <img
  //         onMouseEnter={() => handleMouseEnter(rowData)}
  //         className="product_list__img"
  //         style={{
  //           width: '100px',
  //           height: '50px',
  //           borderRadius: "10px",
  //           cursor: "pointer",
  //           // border: "1px solid #ddd",
  //           objectFit: "contain",
  //         }}
  //         src={baseUrl.concat(rowData.sellerStoreBannerImageUrl)}
  //         alt="IMG"
  //       />
  //       <div className="product_list__tooltip_banner">
  //         <div className="tooltip__img">
  //           <img
  //             style={{
  //               maxWidth: "100%",
  //               maxHeight: "100%",
  //               objectFit: "contain",
  //             }}
  //             src={baseUrl.concat(productImageHoverImage)}
  //             alt="product image"
  //           />
  //         </div>
  //       </div>
  //       <div className="image_tooltip__indicator"></div>
  //     </div>
  //   );
  // };

  // const imageBodyTemplate = (rowData) => {
  //   return (
  //     <React.Fragment>
  //       <span>
  //         <img
  //           style={{
  //             width: '100px',
  //             height: '50px',
  //             borderRadius: '10px',
  //             objectFit: 'contain',
  //           }}
  //           src={baseUrl.concat(rowData.sellerStoreBannerImageUrl)}
  //           alt='IMG'
  //         />
  //       </span>
  //     </React.Fragment>
  //   )
  // }

  const editActionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          onClick={() => {
            navigateTo.push({
              pathname: "/EditCountry",
              state: { data: rowData },
            });
          }}
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success p-mr-2"
        />
      </React.Fragment>
    );
  };


  const renderHeader = () => {
    return (
      <>
        <div className="table-header">
          <span className="p-input-icon-left">
            <InputText
              type="search"
              className="form-control text-center text-field"
              onInput={(e) => setGlobalFilter(e.target.value)}
              placeholder="Search by country name"
            />
          </span>
        </div>
      </>
    )
  }
  return (
    <div className="page-wrapper">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="white-box">
              <div className="datatable-doc-demo datatable-responsive-demo">
                <div className="card">
                  <Toolbar
                    className="p-mb-4"
                    left={leftToolbarTemplate}
                    right={rightToolbarTemplate}
                  ></Toolbar>
                  {loading ? (
                    <LoadingCard count={1} />
                  ) : error ? (
                    <Message variant="danger">{error}</Message>
                  ) : (
                    <DataTable
                      header={renderHeader}
                      value={countries}
                      className="p-datatable-customers p-datatable-responsive-demo"
                      dataKey="id"
                      rowHover
                      globalFilter={globalFilter}
                      paginator
                      rows={10}
                      rowsPerPageOptions={[10, 25, 50]}
                      emptyMessage="No Store Banner found"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                      loading={loading}
                    >
                      <Column
                        field="Index"
                        header="SN"
                        name=""
                        body={onIndexTemplate}
                      />

                      <Column
                        sortField="countryName"
                        filterField="countryName"
                        header="Country"
                        body={shopNameBodyTemplate}
                        sortable
                      />

                      <Column
                        sortField="currency"
                        filterField="currency"
                        header="Currency"
                        field="currency"
                        sortable
                      />

                      <Column
                        sortField='phoneCode'
                        header='Phone Code'
                        field="phoneCode"
                        body={phoneCodeBodyTemplate}
                        sortable
                      />

                      <Column
                        sortField="status"
                        header="Status"
                        body={(rowData, props) => {
                          return rowData.status == "Y"
                            ? <Tag
                              className="mr-2"
                              severity="success"
                              value="Active"
                              rounded
                              fontSize="1rem"
                            ></Tag> :
                            <Tag
                              className="mr-2"
                              severity="danger"
                              value="In-Active"
                              rounded
                              fontSize="1rem"
                            ></Tag>;
                        }}
                        sortable
                      />

                      <Column
                        field="action"
                        header="Edit"
                        body={editActionBodyTemplate}
                      />
                    </DataTable>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CountryListContainer