import React from "react";
import { Link } from "react-router-dom";

const LoginHeader = () => {
  return (
    <nav id="navigation" className="navbar scrollspy">
      <div className="container">
        <div className="navbar-brand" style={{ paddingTop: "25px" }}>
          <Link to="/">
            <img src="./assets/plugins/images/sarawhite.png" alt="Logo" />
          </Link>
        </div>

        <div className="menu-btn" style={{ float: "right" }}>
          <span>
            <a
              href="tel:+8801885998899"
              style={{
                color: "#fff",
                fontSize: "14px",
                fontWeight: "600",
              }}
            >
              +88 01885 998899
            </a>
          </span>
        </div>
      </div>
    </nav>
  );
};

export default LoginHeader;
