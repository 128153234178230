import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import authenticationService from "../../store/services/authenticationService";
import VendorPayment from "./VendorPayment";
import TransactionHistoryContainer from "./transactionHistory";
import CustomerOrderPayment from "./CustomerOrderPayment";
import SalesReport from "./SalesReport";
import BankAdvisory from "./BankAdvisory";

const AccountsContainer = () => {
  let history = useHistory();
  useEffect(() => {
    let roleId = authenticationService?.getRoleId();
    if (roleId === "1") {
    } else {
      history.push("/Login");
    }
  }, [history]);

  let location = useHistory();
  const order_filter_tab = location?.location?.search?.substring(6);

  return (
    <>
      <ToastContainer autoClose={2500} />
      {order_filter_tab === "transaction_history" && (
        <TransactionHistoryContainer />
      )}
      {order_filter_tab === "vendor_payment" && <VendorPayment />}
      {order_filter_tab === "customer_order_payment" && <CustomerOrderPayment />}
      {order_filter_tab === "sales_report" && <SalesReport />}
      {order_filter_tab === "bank_advisory" && <BankAdvisory />}
    </>
  );
};

export default AccountsContainer;
