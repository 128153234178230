/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import cancel from "../../../assets/images/Cancel.svg";
import products from "../../../assets/images/Product.svg";
import refund from "../../../assets/images/Refund.svg";
import returned from "../../../assets/images/Return.svg";
import vendor from "../../../assets/images/Seller.svg";
import cart from "../../../assets/images/cart.svg";
import takaSymbol from "../../../assets/images/taka_symbol_blk.svg";
import { getAllSummariesDashboardDataRecord } from "../../../store/actions/adminDashboardAction";
import toIsoString from "../../../utils/Functions/toIsoString";
import PageLoading from "../../shared/PageLoading";
import MultipleShopSelect from "../../shared/common_used/MultipleShopSelection";
import "./HomeAdmin.css";
import SingleDisplayCard from "./SingleDisplayCard";

const HomeAdmin = () => {
  const dispatch = useDispatch();

  const [orderSummaryFont, setOrderSummaryFont] = useState(70); // 3.646
  const [amountSummaryFont, setAmountSummaryFont] = useState(30); // 1.563
  const [vendorSummaryFont, setVendorSummaryFont] = useState(70); // 3.646
  const [productsSummaryFont, setProductSummaryFont] = useState(70); // 3.646

  const [adminOrderSummary, setAdminOrderSummary] = useState([]);
  const [amountOrderSummary, setAmountOrderSummary] = useState([]);
  const [vendorOrderSummary, setVendorOrderSummary] = useState([]);
  const [productOrderSummary, setProductOrderSummary] = useState([]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filterOrder, setFilterOrder] = useState(false);
  const [shopNameIds, setShopNameIds] = useState(0);

  var currentDate = new Date();

  useEffect(() => {
    currentDate &&
      dispatch(
        getAllSummariesDashboardDataRecord(
          "",
          toIsoString(currentDate).substring(0, 10),
          0
        )
      );
  }, []);

  useEffect(() => {
    filterOrder &&
      dispatch(
        getAllSummariesDashboardDataRecord(
          startDate ? toIsoString(startDate).substring(0, 10) : "",
          endDate ? toIsoString(endDate).substring(0, 10) : "",
          shopNameIds ? shopNameIds : 0
        )
      );
    setFilterOrder(false);
  }, [filterOrder]);

  const { allSummariesData, allSummariesLoading } = useSelector(
    (state) => state?.adminDashboardReducer
  );

  useEffect(() => {
    allSummariesData?.data?.orderSummary &&
      allSummariesData?.data?.orderSummary[0] &&
      setAdminOrderSummary(
        Object.values(allSummariesData?.data?.orderSummary[0])
      );

    allSummariesData?.data?.orderAmountSummary &&
      allSummariesData?.data?.orderAmountSummary[0] &&
      setAmountOrderSummary(
        Object.values(allSummariesData?.data?.orderAmountSummary[0])
      );

    allSummariesData?.data?.vendorSummary &&
      allSummariesData?.data?.vendorSummary[0] &&
      setVendorOrderSummary(
        Object.values(allSummariesData?.data?.vendorSummary[0])
      );

    allSummariesData?.data?.productSummary &&
      allSummariesData?.data?.productSummary[0] &&
      setProductOrderSummary(
        Object.values(allSummariesData?.data?.productSummary[0])
      );
  }, [allSummariesData?.data]);

  useEffect(() => {
    let maxLength = 0;
    adminOrderSummary &&
      adminOrderSummary.map((data) => {
        if (data?.toString()?.length > maxLength) {
          maxLength = data?.toString()?.length;
        }
      });
    maxLength > 6
      ? setOrderSummaryFont(38) // 1.979
      : maxLength > 5
      ? setOrderSummaryFont(45) // 2.344
      : maxLength > 4
      ? setOrderSummaryFont(50) // 2.604
      : maxLength > 3
      ? setOrderSummaryFont(65) // 3.385
      : setOrderSummaryFont(70); // 3.646
  }, [adminOrderSummary]);

  useEffect(() => {
    let maxLength = 0;
    vendorOrderSummary &&
      vendorOrderSummary.map((data) => {
        if (data?.toString()?.length > maxLength) {
          maxLength = data?.toString()?.length;
        }
      });
    maxLength > 6
      ? setVendorSummaryFont(38) // 1.979
      : maxLength > 5
      ? setVendorSummaryFont(45) // 2.344
      : maxLength > 4
      ? setVendorSummaryFont(50) // 2.604
      : maxLength > 3
      ? setVendorSummaryFont(65) // 3.385
      : setVendorSummaryFont(70); // 3.646
  }, [vendorOrderSummary]);

  useEffect(() => {
    let maxLength = 0;
    productOrderSummary &&
      productOrderSummary.map((data) => {
        if (data?.toString()?.length > maxLength) {
          maxLength = data?.toString()?.length;
        }
      });
    maxLength > 6
      ? setProductSummaryFont(38) // 1.979
      : maxLength > 5
      ? setProductSummaryFont(45) // 2.344
      : maxLength > 4
      ? setProductSummaryFont(50) // 2.604
      : maxLength > 3
      ? setProductSummaryFont(65) // 3.385
      : setProductSummaryFont(70); // 3.646
  }, [productOrderSummary]);

  useEffect(() => {
    let maxLength = 0;
    amountOrderSummary &&
      amountOrderSummary.map((data) => {
        if (data?.length > maxLength) {
          maxLength = data?.length;
        }
      });
    maxLength > 11
      ? setAmountSummaryFont(23) // 1.198
      : maxLength > 10
      ? setAmountSummaryFont(25) // 1.302
      : maxLength > 9
      ? setAmountSummaryFont(26) // 1.354
      : maxLength > 8
      ? setAmountSummaryFont(28) // 1.458
      : maxLength > 7
      ? setAmountSummaryFont(38) // 1.667
      : maxLength > 6
      ? setAmountSummaryFont(35) // 1.823
      : maxLength > 5
      ? setAmountSummaryFont(45) // 2.344
      : maxLength > 4
      ? setAmountSummaryFont(50) // 2.604
      : maxLength > 3
      ? setAmountSummaryFont(62) // 3.229
      : setAmountSummaryFont(70); // 3.646
  }, [amountOrderSummary]);

  const setNumberFont = (value) => {
    let fontSize;
    value?.length > 6
      ? (fontSize = 38) // 1.979
      : value?.length > 5
      ? (fontSize = 45) // 2.344
      : value?.length > 4
      ? (fontSize = 50) // 2.604
      : value?.length > 3
      ? (fontSize = 65) // 3.385
      : (fontSize = 70); // 3.646

    return fontSize;
  };

  const setAmountFont = (value) => {
    let fontSize;

    value?.length > 11
      ? (fontSize = 23) // 1.198
      : value?.length > 10
      ? (fontSize = 25) // 1.302
      : value?.length > 9
      ? (fontSize = 26) // 1.354
      : value?.length > 8
      ? (fontSize = 28) // 1.458
      : value?.length > 7
      ? (fontSize = 38) // 1.667
      : value?.length > 6
      ? (fontSize = 35) // 1.823
      : value?.length > 5
      ? (fontSize = 45) // 2.344
      : value?.length > 4
      ? (fontSize =  50) // 2.604
      : value?.length > 3
      ? (fontSize = 62) // 3.229
      : (fontSize = 70); // 3.646

    return fontSize;
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div>
            <div className="allSummaries__filter_items">
              <div></div>
              <div className="allSummaries__filter_item">
                <div className="order_filter__section allSummaries__filter_items_section">
                  <div className="calender_date_selection_section">
                    <Calendar
                      id="icon"
                      maxDate={endDate}
                      value={startDate}
                      onChange={(e) => setStartDate(e.value)}
                      showIcon
                      placeholder="Start Date"
                      readOnlyInput
                      className="admin_dash-calendar"
                    />
                    {startDate !== null ? (
                      <button
                        style={{
                          position: "absolute",
                          top: "6px",
                          right: "38px",
                          borderRadius: "100%",
                          border: "1px solid #ced4da",
                          height: "25px",
                          width: "25px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={() => {
                          setStartDate(null);
                        }}
                      >
                        <i className="pi pi-times"></i>
                      </button>
                    ) : null}
                  </div>

                  <div className="calender_date_selection_section">
                    <Calendar
                      id="icon disableddays"
                      minDate={startDate}
                      value={endDate}
                      onChange={(e) => setEndDate(e.value)}
                      showIcon
                      placeholder="End Date"
                      readOnlyInput
                      className="admin_dash-calendar"
                    />
                    {endDate !== null ? (
                      <button
                        style={{
                          position: "absolute",
                          top: "6px",
                          right: "38px",
                          borderRadius: "100%",
                          border: "1px solid #ced4da",
                          height: "25px",
                          width: "25px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={() => {
                          setEndDate(null);
                        }}
                      >
                        <i className="pi pi-times"></i>
                      </button>
                    ) : null}
                  </div>

                  <div className="MultipleShopSelect_selection_section">
                    <MultipleShopSelect setShopNameIds={setShopNameIds} />
                  </div>

                  <div style={{ position: "relative" }}>
                    <Button
                      onClick={() => setFilterOrder(true)}
                      className="MultipleShopSelect_button_selection_section"
                      label="Search"
                      disabled={startDate?.getTime() > endDate?.getTime()}
                    />
                    {startDate?.getTime() > endDate?.getTime() ? (
                      <>
                        <div
                          style={{
                            position: "absolute",
                            width: "230px",
                            background: "#A596F1",
                            color: "#fff",
                            padding: "4px",
                            left: "-165px",
                            top: "-29px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Start Date Should Be Older Than End Date
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            height: "10px",
                            width: "10px",
                            background: "#A596F1",
                            transform: "rotate(45deg)",
                            top: "-13px",
                            right: "27px",
                          }}
                        ></div>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {allSummariesLoading ? (
            <PageLoading />
          ) : (
            <>
              <div>
                <div className="allSummaries__group_header">
                  <div className="symbol_logo">
                    <img src={cart} alt="" />
                  </div>
                  <div className="allSummaries__header">ORDER SUMMARY</div>
                </div>
                <div className="allSummaries__container">
                  <div>
                    <SingleDisplayCard
                      c1="#2a0845"
                      c2="#6441a5"
                      text="Total New Order"
                      isPrice={false}
                      number={
                        allSummariesData?.data?.orderSummary &&
                        allSummariesData?.data?.orderSummary[0]?.pendingQty
                          ? allSummariesData?.data?.orderSummary[0]?.pendingQty
                          : 0
                      }
                      fontsz={orderSummaryFont}
                      linkNavigate="/ManageOrdersAdmin?status=order_placed"
                    />
                  </div>
                  <div>
                    <SingleDisplayCard
                      c1="#7B4397"
                      c2="#DC2430"
                      text="Total Confirmed Order"
                      isPrice={false}
                      number={
                        allSummariesData?.data?.orderSummary &&
                        allSummariesData?.data?.orderSummary[0]?.confirmQty
                          ? allSummariesData?.data?.orderSummary[0]?.confirmQty
                          : 0
                      }
                      fontsz={orderSummaryFont}
                      linkNavigate="/ManageOrdersAdmin?status=order_confirm"
                    />
                  </div>
                  <div>
                    <SingleDisplayCard
                      c1="#DF2F44"
                      c2="#E86049"
                      text="Total Ready to Ship Order"
                      isPrice={false}
                      number={
                        allSummariesData?.data?.orderSummary &&
                        allSummariesData?.data?.orderSummary[0]?.shippedQty
                          ? allSummariesData?.data?.orderSummary[0]?.shippedQty
                          : 0
                      }
                      fontsz={orderSummaryFont}
                      linkNavigate="/ManageOrdersAdmin?status=ready_to_ship"
                    />
                  </div>
                  <div>
                    <SingleDisplayCard
                      c1="#E0553E"
                      c2="#DE9A39"
                      text="Total Picked Order"
                      isPrice={false}
                      number={
                        allSummariesData?.data?.orderSummary &&
                        allSummariesData?.data?.orderSummary[0]?.pickedQty
                          ? allSummariesData?.data?.orderSummary[0]?.pickedQty
                          : 0
                      }
                      fontsz={orderSummaryFont}
                      linkNavigate="/ManageOrdersAdmin?status=picked"
                    />
                  </div>
                  <div>
                    <SingleDisplayCard
                      c1="#C38326"
                      c2="#FFD66B"
                      text="Total Delivered Order"
                      isPrice={false}
                      number={
                        allSummariesData?.data?.orderSummary &&
                        allSummariesData?.data?.orderSummary[0]?.deliveredQty
                          ? allSummariesData?.data?.orderSummary[0]
                              ?.deliveredQty
                          : 0
                      }
                      fontsz={orderSummaryFont}
                      linkNavigate="/ManageOrdersAdmin?status=delivered"
                    />
                  </div>
                </div>
              </div>

              <div>
                <div className="allSummaries__group_header">
                  <div className="symbol_logo">
                    <img src={takaSymbol} alt="" />
                  </div>
                  <div className="allSummaries__header">AMOUNT SUMMARY</div>
                </div>

                <div className="allSummaries__container">
                  <div>
                    <SingleDisplayCard
                      c1="#373B44"
                      c2="#4286F4"
                      text="Total New Order Amount"
                      isPrice={true}
                      number={
                        allSummariesData?.data?.orderAmountSummary &&
                        allSummariesData?.data?.orderAmountSummary[0]
                          ?.pendingAmount
                          ? Number(
                              allSummariesData?.data?.orderAmountSummary[0]
                                ?.pendingAmount
                            )
                          : 0
                      }
                      fontsz={amountSummaryFont}
                      linkNavigate="/ManageOrdersAdmin?status=order_placed"
                    />
                  </div>
                  <div>
                    <SingleDisplayCard
                      c1="#4286F4"
                      c2="#00A2C5"
                      text="Total Confirmed Order Amount"
                      isPrice={true}
                      number={
                        allSummariesData?.data?.orderAmountSummary &&
                        allSummariesData?.data?.orderAmountSummary[0]
                          ?.confirmAmount
                          ? Number(
                              allSummariesData?.data?.orderAmountSummary[0]
                                ?.confirmAmount
                            )
                          : 0
                      }
                      fontsz={amountSummaryFont}
                      linkNavigate="/ManageOrdersAdmin?status=order_confirm"
                    />
                  </div>
                  <div>
                    <SingleDisplayCard
                      c1="#4CA2CD"
                      c2="#2F0743"
                      text="Total Ready to Ship Order Amount"
                      isPrice={true}
                      number={
                        allSummariesData?.data?.orderAmountSummary &&
                        allSummariesData?.data?.orderAmountSummary[0]
                          ?.shippedAmount
                          ? Number(
                              allSummariesData?.data?.orderAmountSummary[0]
                                ?.shippedAmount
                            )
                          : 0
                      }
                      fontsz={amountSummaryFont}
                      linkNavigate="/ManageOrdersAdmin?status=ready_to_ship"
                    />
                  </div>
                  <div>
                    <SingleDisplayCard
                      c1="#3F055B"
                      c2="#CD3199"
                      text="Total Picked Order Amount"
                      isPrice={true}
                      number={
                        allSummariesData?.data?.orderAmountSummary &&
                        allSummariesData?.data?.orderAmountSummary[0]
                          ?.pickedAmount
                          ? Number(
                              allSummariesData?.data?.orderAmountSummary[0]
                                ?.pickedAmount
                            )
                          : 0
                      }
                      fontsz={amountSummaryFont}
                      linkNavigate="/ManageOrdersAdmin?status=picked"
                    />
                  </div>
                  <div>
                    <SingleDisplayCard
                      c1="#B00E7A"
                      c2="#512DA8"
                      text="Total Sales Order Amount"
                      isPrice={true}
                      number={
                        allSummariesData?.data?.orderAmountSummary &&
                        allSummariesData?.data?.orderAmountSummary[0]
                          ?.deliveredAmount
                          ? Number(
                              allSummariesData?.data?.orderAmountSummary[0]
                                ?.deliveredAmount
                            )
                          : 0
                      }
                      fontsz={amountSummaryFont}
                      linkNavigate="/ManageOrdersAdmin?status=delivered"
                    />
                  </div>
                </div>
              </div>

              <div>
                <div className="allSummaries__group_header">
                  <div className="symbol_logo">
                    <img src={vendor} alt="" />
                  </div>
                  <div className="allSummaries__header">VENDOR SUMMARY</div>
                </div>

                <div className="allSummaries__container">
                  <div>
                    <SingleDisplayCard
                      c1="#001510"
                      c2="#00BF8F"
                      text="Total Active Vendor"
                      isPrice={false}
                      number={
                        allSummariesData?.data?.vendorSummary &&
                        allSummariesData?.data?.vendorSummary[0]?.activeVendors
                          ? allSummariesData?.data?.vendorSummary[0]
                              ?.activeVendors
                          : 0
                      }
                      fontsz={vendorSummaryFont}
                      linkNavigate="/approvedSeller"
                    />
                  </div>
                  <div>
                    <SingleDisplayCard
                      c1="#00A97E"
                      c2="#1A94C0"
                      text="Total Pending Vendor"
                      isPrice={false}
                      number={
                        allSummariesData?.data?.vendorSummary &&
                        allSummariesData?.data?.vendorSummary[0]?.pendingVendors
                          ? allSummariesData?.data?.vendorSummary[0]
                              ?.pendingVendors
                          : 0
                      }
                      fontsz={vendorSummaryFont}
                      linkNavigate="/pendingSeller"
                    />
                  </div>
                  <div>
                    <SingleDisplayCard
                      c1="#0082B0"
                      c2="#155799"
                      text="Total Rejected Vendor"
                      isPrice={false}
                      number={
                        allSummariesData?.data?.vendorSummary &&
                        allSummariesData?.data?.vendorSummary[0]?.rejectVendors
                          ? allSummariesData?.data?.vendorSummary[0]
                              ?.rejectVendors
                          : 0
                      }
                      fontsz={vendorSummaryFont}
                      linkNavigate="/"
                    />
                  </div>
                  <div>
                    <SingleDisplayCard
                      c1="#0A4784"
                      c2="#002688"
                      text="Total New Vendor"
                      isPrice={false}
                      number={
                        allSummariesData?.data?.vendorSummary &&
                        allSummariesData?.data?.vendorSummary[0]?.newVendors
                          ? allSummariesData?.data?.vendorSummary[0]?.newVendors
                          : 0
                      }
                      fontsz={vendorSummaryFont}
                      linkNavigate="/approvedSeller"
                    />
                  </div>
                  <div>
                    <SingleDisplayCard
                      c1="#002688"
                      c2="#001446"
                      text="Total Listed Vendor"
                      isPrice={false}
                      number={
                        allSummariesData?.data?.vendorSummary &&
                        allSummariesData?.data?.vendorSummary[0]?.listedVendors
                          ? allSummariesData?.data?.vendorSummary[0]
                              ?.listedVendors
                          : 0
                      }
                      fontsz={vendorSummaryFont}
                      linkNavigate="/"
                    />
                  </div>
                </div>
              </div>

              <div>
                <div className="allSummaries__group_header">
                  <div className="symbol_logo">
                    <img src={products} alt="" />
                  </div>
                  <div className="allSummaries__header">PRODUCTS SUMMARY</div>
                </div>

                <div className="allSummaries__container">
                  <div>
                    <SingleDisplayCard
                      c1="#4C0027"
                      c2="#87000C "
                      text="Total Active Products"
                      isPrice={false}
                      number={
                        allSummariesData?.data?.productSummary &&
                        allSummariesData?.data?.productSummary[0]
                          ?.activeProducts
                          ? allSummariesData?.data?.productSummary[0]
                              ?.activeProducts
                          : 0
                      }
                      fontsz={productsSummaryFont}
                      linkNavigate="/manage-products?status=approved"
                    />
                  </div>
                  <div>
                    <SingleDisplayCard
                      c1="#87000C"
                      c2="#461010"
                      text="Total Pending Products"
                      isPrice={false}
                      number={
                        allSummariesData?.data?.productSummary &&
                        allSummariesData?.data?.productSummary[0]
                          ?.pendingProducts
                          ? allSummariesData?.data?.productSummary[0]
                              ?.pendingProducts
                          : 0
                      }
                      fontsz={productsSummaryFont}
                      linkNavigate="/manage-products?status=pending"
                    />
                  </div>
                  <div>
                    <SingleDisplayCard
                      c1="#461111"
                      c2="#AF650A"
                      text="Total Rejected Products"
                      isPrice={false}
                      number={
                        allSummariesData?.data?.productSummary &&
                        allSummariesData?.data?.productSummary[0]
                          ?.rejectProducts
                          ? parseFloat(
                              allSummariesData?.data?.productSummary[0]
                                ?.rejectProducts
                            )
                          : 0
                      }
                      fontsz={productsSummaryFont}
                      linkNavigate="/manage-products?status=rejected"
                    />
                  </div>
                  <div>
                    <SingleDisplayCard
                      c1="#B5680A"
                      c2="#B1820A"
                      text="Total New Products"
                      isPrice={false}
                      number={
                        allSummariesData?.data?.productSummary &&
                        allSummariesData?.data?.productSummary[0]?.newProducts
                          ? parseFloat(
                              allSummariesData?.data?.productSummary[0]
                                ?.newProducts
                            )
                          : 0
                      }
                      fontsz={productsSummaryFont}
                      linkNavigate="/manage-products?status=approved"
                    />
                  </div>
                  <div>
                    <SingleDisplayCard
                      c1="#B28208"
                      c2="#3C2C04"
                      text="Total Listed Products"
                      isPrice={false}
                      number={
                        allSummariesData?.data?.productSummary &&
                        allSummariesData?.data?.productSummary[0]
                          ?.listedProducts
                          ? parseFloat(
                              allSummariesData?.data?.productSummary[0]
                                ?.listedProducts
                            )
                          : 0
                      }
                      fontsz={productsSummaryFont}
                      linkNavigate="/"
                    />
                  </div>
                </div>
              </div>

              <div className="cancel_refund_summary_group">
                <div className="cancel_refund_summary__group">
                  <div className="allSummaries__group_header">
                    <div className="symbol_logo">
                      <img src={cancel} alt="" />
                    </div>
                    <div className="allSummaries__header">CANCEL SUMMARY</div>
                  </div>

                  <div className="cancel_refund_summary__container">
                    <div>
                      <SingleDisplayCard
                        c1="#114357"
                        c2="#F29492 "
                        text="Total Cancel Products"
                        isPrice={false}
                        number={
                          allSummariesData?.data?.cancelSummary &&
                          allSummariesData?.data?.cancelSummary[0]?.cancelledQty
                            ? allSummariesData?.data?.cancelSummary[0]
                                ?.cancelledQty
                            : 0
                        }
                        fontsz={setNumberFont(
                          allSummariesData?.data?.cancelSummary &&
                            allSummariesData?.data?.cancelSummary[0]
                              ?.cancelledQty
                        )}
                        linkNavigate="/CancelOrders?status=canceled"
                      />
                    </div>
                    <div>
                      <SingleDisplayCard
                        c1="#F29492"
                        c2="#F05053"
                        text="Total Cancel Amount"
                        isPrice={true}
                        number={
                          allSummariesData?.data?.cancelSummary &&
                          allSummariesData?.data?.cancelSummary[0]
                            ?.cancelledAmount
                            ? Number(
                                allSummariesData?.data?.cancelSummary[0]
                                  ?.cancelledAmount
                              )
                            : 0
                        }
                        fontsz={setAmountFont(
                          allSummariesData?.data?.cancelSummary &&
                            allSummariesData?.data?.cancelSummary[0]
                              ?.cancelledAmount
                        )}
                        linkNavigate="/CancelOrders?status=canceled"
                      />
                    </div>
                  </div>
                </div>
                <div className="cancel_refund_summary__group">
                  <div className="allSummaries__group_header">
                    <div className="symbol_logo">
                      <img src={refund} alt="" />
                    </div>
                    <div className="allSummaries__header">REFUND SUMMARY</div>
                  </div>

                  <div className="cancel_refund_summary__container">
                    <div>
                      <SingleDisplayCard
                        c1="#F05053"
                        c2="#E1EEC3 "
                        text="Total Refund Products"
                        isPrice={false}
                        number={
                          allSummariesData?.data?.refundSummary &&
                          allSummariesData?.data?.refundSummary[0]?.refundQty
                            ? allSummariesData?.data?.refundSummary[0]
                                ?.refundQty
                            : 0
                        }
                        fontsz={setNumberFont(
                          allSummariesData?.data?.refundSummary &&
                            allSummariesData?.data?.refundSummary[0]?.refundQty
                        )}
                        linkNavigate="/"
                      />
                    </div>
                    <div>
                      <SingleDisplayCard
                        c1="#E1EEC3"
                        c2="#1C92D2"
                        text="Total Refund Amount"
                        isPrice={true}
                        number={
                          allSummariesData?.data?.refundSummary &&
                          allSummariesData?.data?.refundSummary[0]?.refundAmount
                            ? Number(
                                allSummariesData?.data?.refundSummary[0]
                                  ?.refundAmount
                              )
                            : 0
                        }
                        fontsz={setAmountFont(
                          allSummariesData?.data?.refundSummary &&
                            allSummariesData?.data?.refundSummary[0]
                              ?.refundAmount
                        )}
                        linkNavigate="/"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="allSummaries__group_header">
                  <div className="symbol_logo">
                    <img src={returned} alt="" />
                  </div>
                  <div className="allSummaries__header">RETURN SUMMARY</div>
                </div>

                <div className="allSummaries__container">
                  <div>
                    <SingleDisplayCard
                      c1="#283C86"
                      c2="#45A247 "
                      text="Total Return Products"
                      isPrice={false}
                      number={
                        allSummariesData?.data?.returnSummary &&
                        allSummariesData?.data?.returnSummary[0]?.returnedQty
                          ? allSummariesData?.data?.returnSummary[0]
                              ?.returnedQty
                          : 0
                      }
                      fontsz={setNumberFont(
                        allSummariesData?.data?.returnSummary &&
                          allSummariesData?.data?.returnSummary[0]?.returnedQty
                      )}
                      linkNavigate="/ReturnOrders?status=returned"
                    />
                  </div>
                  <div>
                    <SingleDisplayCard
                      c1="#45A247"
                      c2="#1CB5E0"
                      text="Total Return Amount"
                      isPrice={true}
                      number={
                        allSummariesData?.data?.returnSummary &&
                        allSummariesData?.data?.returnSummary[0]?.returnedAmount
                          ? Number(
                              allSummariesData?.data?.returnSummary[0]
                                ?.returnedAmount
                            )
                          : 0
                      }
                      fontsz={setAmountFont(
                        allSummariesData?.data?.returnSummary &&
                          allSummariesData?.data?.returnSummary[0]
                            ?.returnedAmount
                      )}
                      linkNavigate="/ReturnOrders?status=returned"
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <ToastContainer autoClose={2500} />
    </>
  );
};

export default HomeAdmin;
