/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SellerNavbar from '../../components/shared/sellernavbar/SellerNavbar';
import { getSellerProfileByIdRecord, getShopDetailsBySellerIdRecord } from '../../store/actions/sellerProfileAction';
import sellerService from '../../store/services/sellerService'
import authenticationService from '../../store/services/authenticationService';
import { removeLocalStorageAdmin } from '../../utils/Functions/removeLocalStorageAdmin';

const NavBarContainer = () => {
  const dispatch = useDispatch();
  let history = useHistory();

  const {
    sellerProfileById,
    error
  } = useSelector((state) => state.sellerProfileReducer);

  useEffect(() => {
    let userId = sellerService.getEmployeeId();
    let roleId = authenticationService.getRoleId();

    if (roleId !== "2") {
      removeLocalStorageAdmin()
      history?.push("/sellerLogin");
    }

    dispatch(getSellerProfileByIdRecord(userId));
    dispatch(getShopDetailsBySellerIdRecord(userId));
  }, [dispatch, history])

  useEffect(() => {
    if (error && sellerProfileById?.length == 0) {
      removeLocalStorageAdmin()
      history?.push("/sellerLogin");
    }
  }, [sellerProfileById, error])

  return (
    <div id="wrapper">
      <SellerNavbar
        key="Navbar"
        sellerProfileById={sellerProfileById}
      />
    </div>
  )
}

export default NavBarContainer