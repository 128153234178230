/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { useEffect } from "react";
import { Paginator } from '../../paginator/Paginator';
import LoadingCard from "../../shared/LoadingCard";

const PurchaseOrderAddProductListTable = ({
  PODataWithPOQty,
  product_list,
  handleItemPerPage,
  handleCurrentPage,
  currentPage,
  setCurrentPage,
  totalPage,
  setTotalPage,
  itemPerPage,
  totalItems,
  setTotalItems,
  loading,

  setSearchKeyword,
  searchKeyword,
  categories,
  setCategory,
  category,

  setIsFilter,

  shops,
  shop,
  setShop,

  selectedPOProducts,
  setSelectedPOProducts,
  handleInputChange,
  setPOAddStatus
}) => {

  const productNameTemplate = (rowData) => {
    return (
      <>{rowData.productName}</>
    );
  };

  const POQtyInputTemplate = (rowData, props) => {

    return (
      <React.Fragment>
        <input
          type="number"
          value={rowData.productQuantity}
          onChange={(e) => handleInputChange(props.rowIndex, e)}
          placeholder="Input PO Qty"
          name="productQuantity"
          min="1"
          style={{
            maxWidth: '100px',
            borderRadius: '5px',
            paddingLeft: '5px',
            textAlign: 'center'
          }}
          title="Edit to change quantity of the product"
        />
      </React.Fragment>
    );
  };


  useEffect(() => {
    if (product_list?.headers?.pagination) {
      var paginated_data_to_parse = product_list?.headers?.pagination;
      const paginated_data = JSON.parse(paginated_data_to_parse);
      setTotalPage(paginated_data.totalPages);
      setTotalItems(paginated_data.totalItems);
    }
  }, [
    product_list?.headers?.pagination,
  ]);

  return (
    <>
      <div
        className="table-header"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "8px 16px 8px 16px",
          borderBottom: "1px solid #ddd",
        }}
      >
        <div className="button-demo">
          <Button
            label={`Add ${selectedPOProducts.length} Item to List`}
            className="p-button-success"
            style={{ marginBottom: '10px' }}
            disabled={selectedPOProducts.length == 0}
            onClick={() => setPOAddStatus(true)}
            title="Click to Add in Purchase Order Items Table"
          />
        </div>

        <div style={{ display: "flex" }}>
          <div
            style={{ margin: "0 5px", width: "240px", position: "relative" }}
          >
            <InputText
              style={{
                width: "100%",
              }}
              className="manage_product__search"
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
              placeholder="Keyword"
            />
            {searchKeyword !== "" ? (
              <button
                style={{
                  position: "absolute",
                  top: "6px",
                  right: "8px",
                  borderRadius: "100%",
                  border: "1px solid #ced4da",
                  height: "25px",
                  width: "25px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => {
                  setSearchKeyword("");
                }}
              >
                <i className="pi pi-times"></i>
              </button>
            ) : null}
          </div>

          <div
            style={{ margin: "0 5px", width: "175px", position: "relative" }}
          >
            <Dropdown
              style={{
                width: "100%",
              }}
              value={shop}
              options={shops}
              filter
              showClear
              onChange={(e) => setShop(e.value)}
              optionLabel="shopName"
              placeholder="Select a Shop"
            />
            {shop !== null ? (
              <button
                style={{
                  position: "absolute",
                  top: "6px",
                  right: "8px",
                  borderRadius: "100%",
                  border: "1px solid #ced4da",
                  height: "25px",
                  width: "25px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => {
                  setShop(null);
                }}
              >
                <i className="pi pi-times"></i>
              </button>
            ) : null}
          </div>

          <div
            style={{ margin: "0 5px", width: "300px", position: "relative" }}
          >
            <Dropdown
              style={{
                width: "100%",
              }}
              value={category}
              options={categories}
              filter
              onChange={(e) => setCategory(e.value)}
              optionLabel="breadcrumbCategory"
              placeholder="Select a Category"
            />
            {category !== null ? (
              <button
                style={{
                  position: "absolute",
                  top: "6px",
                  right: "8px",
                  borderRadius: "100%",
                  border: "1px solid #ced4da",
                  height: "25px",
                  width: "25px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => {
                  setCategory(null);
                }}
              >
                <i className="pi pi-times"></i>
              </button>
            ) : null}
          </div>

          <Button
            onClick={() => {
              setIsFilter(true);
              setCurrentPage(1);
            }}
            style={{ marginLeft: "5px" }}
            // disabled={!shop}
            label="Search"
          />

        </div>
      </div>

      {
        loading ? (
          <LoadingCard count={1} />
        ) :
          (
            <div style={{
              maxHeight: '30vh',
              overflow: 'auto'
            }}>
              <DataTable
                className="product_list_table"
                responsiveLayout="scroll"
                value={PODataWithPOQty}
                selectionMode="checkbox"
                selection={selectedPOProducts}
                onSelectionChange={(e) => setSelectedPOProducts(e.value)}
                emptyMessage="No Product found!"
              >
                <Column selectionMode="multiple" />
                <Column
                  field="productName"
                  header="Product Name"
                  body={productNameTemplate}
                  style={{
                    maxWidth: '300px',
                    wordWrap: "break-word"
                  }}
                  sortable
                />

                <Column
                  field="sellerName"
                  header="Shop Name"
                  style={{
                    maxWidth: '200px',
                    wordWrap: "break-word"
                  }}
                  sortable
                />

                <Column
                  field="sellerProductSku"
                  header="Seller Product SKU"
                  style={{
                    maxWidth: '200px',
                    wordWrap: "break-word"
                  }}
                  sortable
                />

                <Column
                  field="productVariant"
                  header="Variant"
                  style={{
                    maxWidth: '250px',
                    wordWrap: "break-word"
                  }}
                />

                <Column
                  field="price"
                  header="Unit Price"
                  style={{
                    maxWidth: '100px',
                    wordWrap: "break-word"
                  }}
                  sortable
                />

                <Column
                  header="P.O Qty"
                  body={POQtyInputTemplate}
                />

              </DataTable>
            </div>

          )}

      <Paginator
        totalPage={totalPage}
        currentPage={currentPage}
        itemPerPage={itemPerPage}
        totalItems={totalItems}
        items={product_list.data}
        itemsPerPageOptions={[]}
        handleItemPerPage={handleItemPerPage}
        handleCurrentPage={handleCurrentPage}
      />

    </>
  );
};


export default PurchaseOrderAddProductListTable