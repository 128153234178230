import { Dropdown } from "primereact/dropdown";
import React from "react";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";
import baseUrl from "../../utils/baseUrl";
import { Loader } from "../../containers";

const CreateFestive = (props) => {
  return (
    <div className="page-wrapper">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="panel panel-success">
              <div className="panel-heading">
                {" "}
                Create Festival Category{" "}
                <span style={{ float: "right" }}>
                  <Link to="/FestiveList?status=active">
                    <Icon.List className="text-light" />
                  </Link>
                </span>
              </div>
              <div className="panel-wrapper collapse in" aria-expanded="true">
                <div className="panel-body">
                  <form className="form-horizontal">
                    <div className="form-body">

                      <div className="row">
                        <div className="col-md-6 col-sm-12">
                          <div className="form-group">
                            <label className="control_label">
                              Parent Festival Category{" "}
                            </label>
                            <div className="dropdown-demo">
                              <Dropdown
                                optionLabel="breadcrumbFestivalCategory"
                                options={props?.festivalCategoryData?.data}
                                filter
                                showClear
                                filterBy="breadcrumbFestivalCategory"
                                placeholder="Select Parent Festival Category"
                                name="parentCategory"
                                value={props?.parentCategory}
                                onChange={props?.handleChange}
                                className="form-control"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6 col-sm-12">
                          <div className="form-group">
                            <label className="control_label">
                              Festival Category Name{" "}
                              <span
                                aria-hidden="true"
                                style={{
                                  color: "red",
                                  fontWeight: "bold",
                                }}
                              >
                                *
                              </span>
                            </label>
                            <input
                              type="text"
                              placeholder="Enter Festival Category Name"
                              name="festivalCategoryName"
                              value={props?.festivalCategoryName}
                              onChange={props?.handleChange}
                              className={
                                props?.errorFestivalCategoryName?.length !== 0
                                  ? "errorClass form-control"
                                  : "form-control" && "form-control"
                              }
                            />
                            {props?.errorFestivalCategoryName && (
                              <span className="error">
                                {props?.errorFestivalCategoryName}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-2 col-sm-12">
                          <div className="form-group">
                            <label className="control_label">
                              Festival Start Date{" "}
                              <span
                                aria-hidden="true"
                                style={{
                                  color: "red",
                                  fontWeight: "bold",
                                }}
                              >
                                *
                              </span>
                            </label>
                            <input
                              id=""
                              type="datetime-local"
                              placeholder="Select End Date & Time"
                              className="form-control"
                              name="festivalStartDate"
                              value={props?.festivalStartDate}
                              onChange={props.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-2 col-sm-12">
                          <div className="form-group">
                            <label className="control_label">
                              Festival End Date{" "}
                              <span
                                aria-hidden="true"
                                style={{
                                  color: "red",
                                  fontWeight: "bold",
                                }}
                              >
                                *
                              </span>
                            </label>
                            <input
                              id=""
                              type="datetime-local"
                              placeholder="Select End Date & Time"
                              className="form-control"
                              name="festivalEndDate"
                              value={props?.festivalEndDate}
                              onChange={props.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                          <div className="form-group">
                            <label className="control_label">
                              Display Order{" "}
                              <span
                                aria-hidden="true"
                                style={{
                                  color: "red",
                                  fontWeight: "bold",
                                }}
                              >
                                *
                              </span>
                            </label>
                            <input
                              type="number"
                              placeholder="Enter Display Order"
                              name="displayOrder"
                              value={props.displayOrder}
                              onChange={props.handleChange}
                              className={
                                props.errorDisplayOrder.length !== 0
                                  ? "errorClass form-control"
                                  : "form-control" && "form-control"
                              }
                            />
                            {props.errorDisplayOrder && (
                              <span className="error">
                                {props.errorDisplayOrder}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-5 col-sm-12">
                          <div className="form-group">
                            <label className="control_label">
                              Category Url{" "}<span
                                aria-hidden="true"
                                style={{
                                  color: "red",
                                  fontWeight: "bold",
                                }}
                              >
                                *
                              </span>
                            </label>
                            <input
                              type="text"
                              placeholder="Enter Category Url"
                              name="categoryUrl"
                              value={props.categoryUrl ? props.categoryUrl : props?.parentCategory?.festivalCategoryName ?
                                `${props?.parentCategory?.festivalCategoryName?.toLowerCase().replaceAll(' ', '-').replaceAll('\'', '')}-${props?.festivalCategoryName?.toLowerCase().replaceAll(' ', '-').replaceAll('\'', '')}` :
                                props?.festivalCategoryName?.toLowerCase().replaceAll(' ', '-').replaceAll('\'', '')}
                              onChange={props.handleChange}
                              className={"form-control"}
                            />

                          </div>
                        </div>

                      </div>

                      <div className="row">
                        <div className="col-md-6 col-sm-12">
                          <div className="form-group">
                            <label className="control_label">
                              Description
                            </label>
                            <textarea
                              rows={3}
                              type='text'
                              placeholder='Enter Description'
                              name='festivalDescription'
                              value={props.festivalDescription}
                              onChange={props.handleChange}
                              className={'form-control'}
                            />
                          </div>
                        </div>

                        <div className="col-md-6 col-sm-12">
                          <div className="form-group">
                            <label className="control_label">
                              Meta Description
                            </label>
                            <textarea
                              rows={3}
                              type='text'
                              placeholder='Enter Meta Description'
                              name='festivalMetaDescription'
                              value={props.festivalMetaDescription}
                              onChange={props.handleChange}
                              className={'form-control'}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4 col-sm-12">
                          <div className="form-group">
                            <label className="control_label">
                              Meta Keywords{" "}
                            </label>
                            <input
                              type="text"
                              placeholder="Enter Meta Keywords"
                              name="metaKeywords"
                              value={props.metaKeywords}
                              onChange={props.handleChange}
                              className={"form-control"}
                            />
                            {props.errorDisplayOrder && (
                              <span className="error">
                                {props.errorDisplayOrder}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                          <div className="form-group">
                            <label className="control_label">
                              Meta Title{" "}
                            </label>
                            <input
                              type="text"
                              placeholder="Enter Meta Title"
                              name="metaTitle"
                              value={props.metaTitle}
                              onChange={props.handleChange}
                              className={"form-control"}
                            />

                          </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                          <div className="form-group">
                            <label className="control_label">
                              Banner Url{" "}
                            </label>
                            <input
                              type="text"
                              placeholder="Enter Banner Url"
                              name="bannerUrl"
                              value={props.bannerUrl}
                              onChange={props.handleChange}
                              className={"form-control"}
                            />

                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6 col-sm-12">
                          <div className="form-group file-area">
                            <label className="control_label">
                              Festival Image (452px x 452px){" "}
                              <span
                                aria-hidden="true"
                                style={{
                                  color: "red",
                                  fontWeight: "bold",
                                }}
                              >
                                *
                              </span>
                            </label>
                            <div className="row">
                              <div className="col-sm-6">
                                <input
                                  type="file"
                                  accept="image/*"
                                  name="festivalImagePath"
                                  required="required"
                                  className="form-control"
                                  onChange={props.festivalImagePathHandler}
                                />

                                {props.festivalImagePath === "" ? (
                                  <div className="file-dummy">
                                    <div className="default">
                                      Please Upload Festival Image <br />
                                      Resolution: ( 452 x 452 )
                                    </div>
                                  </div>
                                ) : (
                                  <div className="file-dummy">
                                    <div className="success">
                                      Festival Image Uploaded Successfully
                                    </div>
                                  </div>
                                )}
                              </div>

                              <div className="col-md-6 col-sm-6">
                                {props.showImage ? (
                                  <>
                                    <img
                                      src={props.showImage}
                                      className="thumb-md product-image"
                                      style={{ width: "100px", height: "100px" }}
                                      alt="showImage"
                                    />
                                  </>
                                ) : (
                                  <img
                                    src={baseUrl.concat(props.festivalImagePath)}
                                    className="thumb-md product-image"
                                    style={{
                                      width: "100px",
                                      height: "100px",
                                      display: "none",
                                    }}
                                    alt="featureImage"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3 col-sm-12">
                          <div className="form-group">
                            <label className="control_label">Active </label>
                            <div className="checkbox checkbox-success">
                              <input
                                id="isActive"
                                type="checkbox"
                                name="isActive"
                                checked={props.isActive}
                                onChange={props.handleIsActiveCheck}
                              />
                              <label htmlFor="isActive"> &nbsp;Yes </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                          <div className="form-group">
                            <label className="control_label">Deleted </label>
                            <div className="checkbox checkbox-success">
                              <input
                                id="isDeleted"
                                type="checkbox"
                                name="isDeleted"
                                checked={props.isDeleted}
                                onChange={props.handleIsDeletedCheck}
                              />
                              <label htmlFor="isDeleted"> &nbsp;Yes </label>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="form-footer ">
                        <div className="form-group row">
                          <div className="text-center">
                            <div className="btn-group text-center">
                            {(props?.festivalCreateOrUpdateDataLoading) ?
                                <div style={{ width: '65%', textAlign: 'center' }}>
                                  <Loader />
                                </div>
                                : <>
                              <button
                                type="submit"
                                className="btn btn-success"
                                onClick={props.saveFestival}
                              >
                                Create
                              </button>
                              <Link to="/FestiveList?status=active">
                                <button
                                  className="btn btn-danger"
                                  style={{ cursor: "pointer" }}
                                >
                                  Cancel
                                </button>
                              </Link>
                              </>}
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateFestive