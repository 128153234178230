import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout"; // install this library
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import React from "react";
import docUrl from "../../assets/pdf/40432_35501.pdf";

export default function SellerCommission() {
  const defaultLayoutPluginInstance = defaultLayoutPlugin()

  /*To Prevent right click on screen*/
  document.addEventListener("contextmenu", (event) => {
    event.preventDefault()
  })

  return (
    <>
      <div className="main">
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
          <Viewer
            fileUrl={docUrl}
            plugins={[defaultLayoutPluginInstance]}
          />
        </Worker>

      </div>
    </>
  )
}
