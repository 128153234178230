import { getPaymentTypeWiseSalesSummaryRecord } from "../../../store/actions/accountsAction";

export const paymentTypeWiseSalesSummaryApiCall = (
  dispatch,
  startDate,
  endDate,
  statusId,
  shopIds,
  paymentMethodIds
) => {
  dispatch(
    getPaymentTypeWiseSalesSummaryRecord(
      startDate ? startDate : "",
      endDate ? endDate : "",
      statusId ? statusId : "",
      shopIds ? shopIds : 0,
      paymentMethodIds ? paymentMethodIds : 0
    )
  );
};

export const paymentTypeWiseSalesSummaryReportExcel = (dataFromAPI) => {
  let formattedData = [];
  dataFromAPI &&
    dataFromAPI.map((data, i) => {
      let singleData = {
        "Payment Method": data?.paymentMethodName
          ? data?.paymentMethodName?.toString()
          : "-",
        "Vendor Name": data?.shopName ? data?.shopName.toString() : "-",
        // "Number of Invoices": data?.cntDelivered
        //   ? parseInt(data?.cntDelivered)
        //   : 0,
        // "Sales Qty.": data?.deliveredQty ? parseInt(data?.deliveredQty) : "-",
        // "Total MRP": data?.deliveredAmount
        //   ? parseFloat(data?.deliveredAmount)
        //   : 0,
        // "Discount Amount": data?.discountAmount
        //   ? parseFloat(data?.discountAmount)
        //   : 0,
        // "Burn Amount": data?.burnAmount ? parseFloat(data?.burnAmount) : 0,
        // Commission: data?.commissionAmount
        //   ? parseFloat(data?.commissionAmount)
        //   : 0,
        "Net Payable": data?.netAmount ? parseFloat(data?.netAmount) : 0,
      };
      formattedData.push(Object.assign({}, singleData));
    });
  return formattedData;
};

export const paymentTypeWiseSalesSummaryReportGrouping = (
  dataFromAPI,
  setIsDataFormatting
) => {
  setIsDataFormatting(true);
  const groupedData = {};
  for (const obj of dataFromAPI) {
    const fieldValue = obj.paymentMethodName;
    if (!groupedData[fieldValue]) {
      groupedData[fieldValue] = [];
    }
    groupedData[fieldValue].push(obj);
  }

  const uniqueValues = [
    ...new Set(dataFromAPI.map((obj) => obj.paymentMethodName)),
  ];

  const grandTotal = dataFromAPI.reduce(
    (accumulator, currentValue) =>
      accumulator + parseFloat(currentValue?.netAmount),
    0
  );

  setIsDataFormatting(false);
  return [groupedData, uniqueValues, grandTotal];
};
