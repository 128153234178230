import festivalService from "../services/festivalService"

export const GET_FESTIVAL_CATEGORY = "GET_FESTIVAL_CATEGORY"
export const GET_FESTIVAL_CATEGORY_SUCCESS = "GET_FESTIVAL_CATEGORY_SUCCESS"
export const GET_FESTIVAL_CATEGORY_ERROR = "GET_FESTIVAL_CATEGORY_ERROR"

export const CREATE_OR_UPDATE_FESTIVAL_CATEGORY = "CREATE_OR_UPDATE_FESTIVAL_CATEGORY"
export const CREATE_OR_UPDATE_FESTIVAL_CATEGORY_SUCCESS = "CREATE_OR_UPDATE_FESTIVAL_CATEGORY_SUCCESS"
export const CREATE_OR_UPDATE_FESTIVAL_CATEGORY_ERROR = "CREATE_OR_UPDATE_FESTIVAL_CATEGORY_ERROR"
export const CREATE_OR_UPDATE_FESTIVAL_CATEGORY_RESET = "CREATE_OR_UPDATE_FESTIVAL_CATEGORY_RESET"

export const ADD_OR_EDIT_FESTIVAL_PRODUCT_LIST = "ADD_OR_EDIT_FESTIVAL_PRODUCT_LIST"
export const ADD_OR_EDIT_FESTIVAL_PRODUCT_LIST_SUCCESS = "ADD_OR_EDIT_FESTIVAL_PRODUCT_LIST_SUCCESS"
export const ADD_OR_EDIT_FESTIVAL_PRODUCT_LIST_ERROR = "ADD_OR_EDIT_FESTIVAL_PRODUCT_LIST_ERROR"
export const ADD_OR_EDIT_FESTIVAL_PRODUCT_LIST_RESET = "ADD_OR_EDIT_FESTIVAL_PRODUCT_LIST_RESET"

export const GET_ADDIBLE_FESTIVAL_PRODUCTS = "GET_ADDIBLE_FESTIVAL_PRODUCTS"
export const GET_ADDIBLE_FESTIVAL_PRODUCTS_SUCCESS = "GET_ADDIBLE_FESTIVAL_PRODUCTS_SUCCESS"
export const GET_ADDIBLE_FESTIVAL_PRODUCTS_ERROR = "GET_ADDIBLE_FESTIVAL_PRODUCTS_ERROR"

export const GET_FESTIVAL_PRODUCTS = "GET_FESTIVAL_PRODUCTS"
export const GET_FESTIVAL_PRODUCTS_SUCCESS = "GET_FESTIVAL_PRODUCTS_SUCCESS"
export const GET_FESTIVAL_PRODUCTS_ERROR = "GET_FESTIVAL_PRODUCTS_ERROR"

export const FESTIVAL_PRODUCT_STATUS_CHANGE = "FESTIVAL_PRODUCT_STATUS_CHANGE"
export const FESTIVAL_PRODUCT_STATUS_CHANGE_SUCCESS = "FESTIVAL_PRODUCT_STATUS_CHANGE_SUCCESS"
export const FESTIVAL_PRODUCT_STATUS_CHANGE_ERROR = "FESTIVAL_PRODUCT_STATUS_CHANGE_ERROR"
export const FESTIVAL_PRODUCT_STATUS_CHANGE_RESET = "FESTIVAL_PRODUCT_STATUS_CHANGE_RESET"

export function getFestivalCategoryRecord(query) {
    return async (dispatch) => {
        dispatch(getFestivalCategory())
        return await festivalService
            .getFestivalCategory(query)
            .then((response) => dispatch(getFestivalCategorySuccess(response)))
            .catch((error) => dispatch(getFestivalCategoryError(error)))
    }
}

export function getFestivalCategory() {
    return {
        type: GET_FESTIVAL_CATEGORY,
    }
}

export function getFestivalCategorySuccess(success) {
    return {
        type: GET_FESTIVAL_CATEGORY_SUCCESS,
        payload: { success },
    }
}

export function getFestivalCategoryError(error) {
    return {
        type: GET_FESTIVAL_CATEGORY_ERROR,
        payload: { error },
    }
}


export function createOrUpdateFestivalCategoryRecord(festivalData) {
    return async (dispatch) => {
        dispatch(createOrUpdateFestivalCategory())
        return await festivalService
            .createOrUpdateFestivalCategory(festivalData)

            .then((response) => dispatch(createOrUpdateFestivalCategorySuccess(response)))
            .catch((error) => dispatch(createOrUpdateFestivalCategoryError(error)))
    }
}

export function createOrUpdateFestivalCategory() {
    return {
        type: CREATE_OR_UPDATE_FESTIVAL_CATEGORY,
    }
}

export function createOrUpdateFestivalCategorySuccess(success) {
    return {
        type: CREATE_OR_UPDATE_FESTIVAL_CATEGORY_SUCCESS,
        payload: { success },
    }
}

export function createOrUpdateFestivalCategoryError(error) {
    return {
        type: CREATE_OR_UPDATE_FESTIVAL_CATEGORY_ERROR,
        payload: { error },
    }
}

export function createOrUpdateFestivalCategoryReset() {
    return {
        type: CREATE_OR_UPDATE_FESTIVAL_CATEGORY_RESET,
    }
}

export function addOrEditFestivalProductListRecord(festivalData) {
    return async (dispatch) => {
        dispatch(addOrEditFestivalProductList())
        return await festivalService
            .addOrEditFestivalProductList(festivalData)
            .then((response) => dispatch(addOrEditFestivalProductListSuccess(response)))
            .catch((error) => dispatch(addOrEditFestivalProductListError(error)))
    }
}

export function addOrEditFestivalProductList() {
    return {
        type: ADD_OR_EDIT_FESTIVAL_PRODUCT_LIST,
    }
}

export function addOrEditFestivalProductListSuccess(success) {
    return {
        type: ADD_OR_EDIT_FESTIVAL_PRODUCT_LIST_SUCCESS,
        payload: { success },
    }
}

export function addOrEditFestivalProductListError(error) {
    return {
        type: ADD_OR_EDIT_FESTIVAL_PRODUCT_LIST_ERROR,
        payload: { error },
    }
}

export function addOrEditFestivalProductListReset() {
    return {
        type: ADD_OR_EDIT_FESTIVAL_PRODUCT_LIST_RESET,
    }
}

export function getAddibleFestivalProductsRecord(query) {
    return async (dispatch) => {
        dispatch(getAddibleFestivalProducts())
        return await festivalService
            .getAddibleFestivalProducts(query)
            .then((response) => dispatch(getAddibleFestivalProductsSuccess(response)))
            .catch((error) => dispatch(getAddibleFestivalProductsError(error)))
    }
}

export function getAddibleFestivalProducts() {
    return {
        type: GET_ADDIBLE_FESTIVAL_PRODUCTS,
    }
}

export function getAddibleFestivalProductsSuccess(success) {
    return {
        type: GET_ADDIBLE_FESTIVAL_PRODUCTS_SUCCESS,
        payload: { success },
    }
}

export function getAddibleFestivalProductsError(error) {
    return {
        type: GET_ADDIBLE_FESTIVAL_PRODUCTS_ERROR,
        payload: { error },
    }
}


export function getFestivalProductsRecord(query) {
    return async (dispatch) => {
        dispatch(getFestivalProducts())
        return await festivalService
            .getFestivalProducts(query)
            .then((response) => dispatch(getFestivalProductsSuccess(response)))
            .catch((error) => dispatch(getFestivalProductsError(error)))
    }
}

export function getFestivalProducts() {
    return {
        type: GET_FESTIVAL_PRODUCTS,
    }
}

export function getFestivalProductsSuccess(success) {
    return {
        type: GET_FESTIVAL_PRODUCTS_SUCCESS,
        payload: { success },
    }
}

export function getFestivalProductsError(error) {
    return {
        type: GET_FESTIVAL_PRODUCTS_ERROR,
        payload: { error },
    }
}


export function festivalProductStatusChangeRecord(data) {
    return async (dispatch) => {
        dispatch(festivalProductStatusChange())
        return await festivalService
            .festivalProductStatusChange(data)
            .then((response) => dispatch(festivalProductStatusChangeSuccess(response)))
            .catch((error) => dispatch(festivalProductStatusChangeError(error)))
    }
}

export function festivalProductStatusChange() {
    return {
        type: FESTIVAL_PRODUCT_STATUS_CHANGE,
    }
}

export function festivalProductStatusChangeSuccess(success) {
    return {
        type: FESTIVAL_PRODUCT_STATUS_CHANGE_SUCCESS,
        payload: { success },
    }
}

export function festivalProductStatusChangeError(error) {
    return {
        type: FESTIVAL_PRODUCT_STATUS_CHANGE_ERROR,
        payload: { error },
    }
}

export function festivalProductStatusChangeReset() {
    return {
        type: FESTIVAL_PRODUCT_STATUS_CHANGE_RESET,
    }
}