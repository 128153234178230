import http from "../../utils/httpClient";

class accountsService {
  // Accounts Transaction Service Start
  getExcelAccountsTransaction = async (
    startDate,
    endDate,
    paymentStatus,
    paymentMethod,
    shopIds
  ) => {
    return await http.get(
      `/api/Order/GetVendorOrderReport?startDate=${startDate}&endDate=${endDate}&paymentMethodId=${paymentMethod}&statusName=${paymentStatus}&shopId=${shopIds}&getAll=Y`
    );
  };
  // Accounts Transaction Service End

  // Accounts Dashboard Data Service Start
  getAccountsDashboardData = async (startDate, endDate, shopNumber) => {
    return await http.get(
      `/api/Order/GetSalesReportSummaryByShopwiseForDashboard?startDate=${startDate}&endDate=${endDate}&displayNumberOfShops=${shopNumber}`
    );
  };
  // Accounts Dashboard Data Service End

  // Accounts DATE WISE SALES SUMMARY NBR DATA Service Start
  getDateWiseSalesSummaryNBR = async (startDate, endDate, statusId, shopIds) => {
    return await http.get(
      `/api/Account/GetSalesReportSummaryShopwiseNbr?startDate=${startDate}&endDate=${endDate}&statusId=${statusId}&shopId=${shopIds}`
    );
  };
  // Accounts DATE WISE SALES SUMMARY NBR DATA Service End

  // Accounts DATE WISE SALES SUMMARY DATA Service Start
  getDateWiseSalesSummary = async (startDate, endDate, statusId, shopIds) => {
    return await http.get(
      `/api/Order/GetSalesReportSummaryShopwise?startDate=${startDate}&endDate=${endDate}&statusId=${statusId}&shopId=${shopIds}`
    );
  };
  // Accounts DATE WISE SALES SUMMARY DATA Service End

  // Accounts Payment Type WISE SALES SUMMARY DATA Service Start
  getPaymentTypeWiseSalesSummary = async (
    startDate,
    endDate,
    statusId,
    shopIds,
    paymentMethodIds
  ) => {
    return await http.get(
      `/api/Order/GetSalesReportSummaryPaymentStatusWise?startDate=${startDate}&endDate=${endDate}&statusId=${statusId}&shopId=${shopIds}&paymentMethodId=${paymentMethodIds}`
    );
  };
  // Accounts Payment Type WISE SALES SUMMARY DATA Service End

  // Accounts Payment Type WISE SALES SUMMARY DATA Service Start
  getPaymentTypeWiseSalesDetails = async (
    startDate,
    endDate,
    statusId,
    shopIds,
    paymentMethodIds
  ) => {
    return await http.get(
      `/api/Order/GetSalesReportDetailPaymentStatusDateWise?startDate=${startDate}&endDate=${endDate}&statusId=${statusId}&shopId=${shopIds}&paymentMethodId=${paymentMethodIds}`
    );
  };
  // Accounts Payment Type WISE SALES SUMMARY DATA Service End


  // Accounts DATE WISE PAYMENT Realization DATA Service Start
  getDateWisePaymentRealization = async (dataPost) => {
    return await http.post(
      `/api/Account/GetPaymentRealizationRecords`, dataPost
    );
  };
  // Accounts DATE WISE PAYMENT Realization Service End
}

export default new accountsService();
