import React from "react";

const SellerFooter = (props) => {
  return (
    // <div id="wrapper">
    <div>
    {/* <!--Start footer--> */}
    {/* <footer className="footer t-a-c" style={{position: "sticky", left: 0, bottom: 0, width: "100%"}}>
                  © 2021 SaRa Lifestyle Ltd
              </footer> */}
    {/* <!--End footer--> */}
      </div>
  );
};

export default SellerFooter;
