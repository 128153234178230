import http from "../../utils/httpClient";

class blogPostService {
  createOrUpdateBlogPost = async (data) => {
    return await http.post(
      "/api/Content/AddOrUpdateBlogPost",
      data
    );
  };

  getBlogPost = async (data) => {
    return await http.get("/api/Content/GetBlogPost", {
      params: { ...data, getAll: "Y" },
    });
  };
}

export default new blogPostService();
