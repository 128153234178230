import {
    CREATE_OR_UPDATE_STORE_BANNER,
    CREATE_OR_UPDATE_STORE_BANNER_ERROR,
    CREATE_OR_UPDATE_STORE_BANNER_RESET,
    CREATE_OR_UPDATE_STORE_BANNER_SUCCESS,
    GET_STORE_BANNER,
    GET_STORE_BANNER_BY_SHOP_ID,
    GET_STORE_BANNER_BY_SHOP_ID_ERROR,
    GET_STORE_BANNER_BY_SHOP_ID_SUCCESS,
    GET_STORE_BANNER_ERROR,
    GET_STORE_BANNER_SUCCESS
} from "../actions/storeAction";

const initialState = {
    createOrUpdateBannerData: {},
    getAllBannerData: {},
    getShopWiseBannerData: {},
    loading: false,
    created: true,
    posted: true,
    error: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CREATE_OR_UPDATE_STORE_BANNER:
            return {
                ...state,
                loading: true,
                created: false,
                error: null,
            };
        case CREATE_OR_UPDATE_STORE_BANNER_SUCCESS:
            return {
                ...state,
                loading: false,
                created: true,
                createOrUpdateBannerData: action.payload.success,
            };

        case CREATE_OR_UPDATE_STORE_BANNER_ERROR:
            return {
                ...state,
                loading: false,
                created: true,
                error: action.payload.error,
                createOrUpdateBannerData: {},
            };
        case CREATE_OR_UPDATE_STORE_BANNER_RESET:
            return {
                ...state,
                loading: false,
                created: true,
                error: null,
                createOrUpdateBannerData: {},
            };
        case GET_STORE_BANNER:
            return {
                ...state,
                loading: true,
                error: null,
            }

        case GET_STORE_BANNER_SUCCESS:
            return {
                ...state,
                loading: false,
                getAllBannerData: action.payload.success,
            }

        case GET_STORE_BANNER_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                getAllBannerData: [],
            }
        case GET_STORE_BANNER_BY_SHOP_ID:
            return {
                ...state,
                loading: true,
                error: null,
            }

        case GET_STORE_BANNER_BY_SHOP_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                getShopWiseBannerData: action.payload.success,
            }

        case GET_STORE_BANNER_BY_SHOP_ID_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                getShopWiseBannerData: [],
            }
        default:
            return state;
    }
}