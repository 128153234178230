/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Resizer from "react-image-file-resizer";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import PersonalInfo from "../../components/seller/PersonalInfo";
import SellerCredential from "../../components/seller/SellerCredential";
import SellerShopInfo from "../../components/seller/SellerShopInfo";
import { getAllAreaRecord, getAllCityRecord, getAllCountryRecord } from "../../store/actions/addressAction";
import { getBussinessTypeRecord } from "../../store/actions/bussinessTypeAction";
import { createSellerRecord, createSellerReset, createSellerTokenRecord, sellerTokenVerifyRecord } from "../../store/actions/sellerAction";
const CreateSellerContainerV2 = () => {
    const [step, setStep] = useState(1);

    // step 1
    const [sellerName, setSellerName] = useState("");
    const [userContactNo, setUserContactNo] = useState("");
    const [userEmail, setUserEmail] = useState("");

    // step 2
    const [tokenId, setTokenId] = useState("");
    const [tokenCode, setTokenCode] = useState("");

    // step 3
    const [sellerPassword, setSellerPassword] = useState("");
    const [sellerTypeId, setSellerTypeId] = useState(1);
    const [ownerName, setOwnerName] = useState("");
    const [shopName, setShopName] = useState("");
    const [shopDescription, setShopDescription] = useState("");
    const [binNo, setBinNo] = useState("");
    const [shopCity, setShopCity] = useState("");
    const [shopState, setShopState] = useState("");
    const [shopAddress, setShopAddress] = useState("");
    const [shopZipCode, setShopZipCode] = useState("");

    const [shopLogoUrl, setShopLogoUrl] = useState("");

    const [ownerNidUrl, setOwnerNidUrl] = useState(null);
    const [ownerNidName, setOwnerNidName] = useState("");

    const [ownerBINUrl, setOwnerBINUrl] = useState(null);
    const [ownerBINName, setOwnerBINName] = useState("");

    const [ownerTradeLicenseUrl, setOwnerTradeLicenseUrl] = useState(null);
    const [ownerTradeLicenseName, setOwnerTradeLicenseName] = useState("");

    const [businessDocUrl, setBusinessDocUrl] = useState(null);
    const [businessDocName, setBusinessDocName] = useState("");

    const [ownerTINDocUrl, setOwnerTINDocUrl] = useState(null);
    const [ownerTINDocName, setOwnerTINDocName] = useState("");

    const [ownerDBIDDocUrl, setOwnerDBIDDocUrl] = useState(null);
    const [ownerDBIDDocName, setOwnerDBIDDocName] = useState("");

    const [isSellerDelivered, setIsSellerDelivered] = useState(false);

    const [countryId, setCountryId] = useState("");
    const [countryList, setCountryList] = useState("");

    const [cityList, setCityList] = useState("");

    const [areaList, setAreaList] = useState("");

    const [businessTypeList, setBusinessTypeList] = useState("");

    const [showShopLogoFile, setShowShopLogoFile] = useState("");

    const [isEmailAvailable, setIsEmailAvailable] = useState(true);
    const [isContactAvailable, setIsContactAvailable] = useState(true);

    const [businessTypeIdsMulti, setBusinessTypeIdsMulti] = useState(0);

    const [additionalDocNum, setAdditionalDocNum] = useState(0);

    const [additionalDocumentsArray, setAdditionalDocumentsArray] = useState([]);

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(getBussinessTypeRecord())
        dispatch(getAllCountryRecord())
    }, [dispatch])

    const { bussinessTypes } = useSelector((state) => state?.bussinessTypeReducer);
    const { allCountries, allCities, allAreas } = useSelector((state) => state?.addressReducer);
    const sellerData = useSelector((state) => state?.sellerReducer);
    const {
        sellerTokenData,
        sellerTokenDataSuccess,
        sellerTokenDataLoading: sellerInfoFromReducer,
        tokenVerifyData,
        tokenVerifyDataSuccess,
        createSellerData,
        createSellerDataSuccess
    } = sellerData

    useEffect(() => {
        const businessTypeListData = bussinessTypes.map(
            ({ bussinessTypeName: label, bussinessTypeId: value }) => ({
                label,
                value,
            })
        )
        setBusinessTypeList(businessTypeListData)
    }, [bussinessTypes])

    useEffect(() => {
        const countryListData = allCountries.map(
            ({ countryName: label, countryId: value }) => ({
                label,
                value,
            })
        )
        setCountryList(countryListData)
    }, [allCountries])

    const handleChange = (e) => {
        const { target } = e;

        switch (target.name) {
            case "ownerName":
                setOwnerName(target.value)
                break;

            case "shopName":
                setShopName(target.value)
                break;

            case "shopState":
                setShopState(target.value)
                break;

            case "shopDescription":
                setShopDescription(target.value)
                break;

            case "shopAddress":
                setShopAddress(target.value)
                break;

            case "shopZipCode":
                setShopZipCode(target.value)
                break;

            case "binNo":
                setBinNo(target.value)
                break;

            default:
        }
    };

    /// ---------- Step 1 ----------
    const nextStep = async (e) => {
        setSellerName(e.sellerName)
        setUserContactNo(e.userContactNo)
        setUserEmail(e.userEmail)

        const data = {
            userContactNo: e.userContactNo,
            userEmail: e.userEmail,
            actionFor: "r",
            userType: "seller",
        };

        dispatch(createSellerTokenRecord(data))
    }

    useEffect(() => {
        if (
            sellerTokenData?.result?.errors[0] ==
            "This email or contact no. is already used!"
        ) {
            toast.error("Seller Already Exists!");
            setTimeout(() => { }, 2500);
            return false;
        } else if (sellerTokenData?.result?.succeed === false) {
            toast.error("Something Went Wrong!");
            setTimeout(() => { }, 2500);
            return false;
        } else if (sellerTokenData?.result?.errors[0]?.message ==
            "success!") {
            setStep(2)
            setIsEmailAvailable(true)
            setIsContactAvailable(true)
        }
        else if (sellerTokenData?.result?.succeed === true) {
            setStep(2)
            setIsEmailAvailable(true)
            setIsContactAvailable(true)
        }
    }, [sellerTokenDataSuccess])

    /// ---------- Step 2 ----------
    const nextStepCredential = async (e) => {
        const tokenId = localStorage.getItem("x-access-tokenId");

        setSellerPassword(e.sellerPassword)

        const data = {
            tokenId: tokenId,
            tokenCode: e.tokenCode,
        };

        dispatch(sellerTokenVerifyRecord(data))
    }

    useEffect(() => {
        if (tokenVerifyData?.succeed === false) {
            toast.warning("Token code is not correct!");
            return false;
        }
        else if (tokenVerifyData?.succeed === true) {
            setStep(3)
        }
    }, [tokenVerifyDataSuccess])

    /// ---------- Step 3 ----------
    const saveSellerInfo = async (e) => {
        e.preventDefault();
        if (sellerName === "") {
            toast.error("Seller Name is required!");
            return
        }

        if (userContactNo === "") {
            toast.error("Seller Contact Number is required!");
            return
        }

        if (userEmail === "") {
            toast.error("Seller Email Address is required!");
            return
        }
        if (businessTypeIdsMulti == 0) {
            toast.error("Business Type is required!");
            return
        }

        if (sellerPassword === "") {
            toast.error("Password is required!");
            return
        }

        if (shopName === "") {
            toast.error("Shop Name is required!");
            return
        }
        if (ownerName === "") {
            toast.error("Shop Owner Name is required!");
            return
        }
        if (shopCity === "") {
            toast.error("District/City Name is required!");
            return
        }

        if (shopState === "") {
            toast.error("Area/Thana/Upazila/State is required!");
            return
        }

        if (shopZipCode === "") {
            toast.error("Zip Code is required!");
            return
        }

        if (shopDescription === "") {
            toast.error("Shop Description is required!");
            return
        }

        if (shopAddress === "") {
            toast.error("Shop Address is required!");
            return
        }

        if (shopLogoUrl === "") {
            toast.error("Shop Logo is required!");
            return
        }

        if (ownerNidUrl === null) {
            toast.error("Owner NID is required!");
            return
        }

        if (businessDocUrl === null) {
            toast.error("Business Document is required!");
            return
        }

        if (ownerTradeLicenseUrl === null) {
            toast.error("Trade License is required!");
            return
        }

        if (additionalDocumentsArray.length > 0) {
            additionalDocumentsArray.map((data, i) => {
                if (data.docName === "" || data.docPath === "") {
                    toast.error("Both Document Name and File required!");
                    return
                }
            })
        }

        const data = {
            sellerName: sellerName,
            sellerContactNo: userContactNo,
            sellerEmail: userEmail,
            bussinessTypeId: businessTypeIdsMulti, // Take Multiple Id number in array
            sellerPassword: sellerPassword,
            shopName: shopName,
            ownerName: ownerName,
            binNo: binNo,
            sellerTypeId: 1,
            sellerCurrency:
                countryId === 19
                    ? "BDT"
                    : countryId === 45
                        ? "CNY"
                        : countryId === 101
                            ? "INR"
                            : countryId === 232
                                ? "GBP"
                                : countryId === 233
                                    ? "USD"
                                    : "BDT",
            shopCity: shopCity,
            shopState: shopState,
            shopZipCode: shopZipCode,
            shopDescription: shopDescription,
            shopAddress: shopAddress,
            shopLogoUrl: shopLogoUrl ? shopLogoUrl : "", // Image
            ownerNidUrl: ownerNidUrl ? ownerNidUrl : "", // Image or File
            bussinessDocUrl: businessDocUrl ? businessDocUrl : "", // Image or File
            tradeLicenseDoc: ownerTradeLicenseUrl ? ownerTradeLicenseUrl : "", // Image or File
            binNoDoc: ownerBINUrl ? ownerBINUrl : "", // Image or File (optional)
            tinNoDoc: ownerTINDocUrl ? ownerTINDocUrl : "", // Image or File (optional)
            dbIdDoc: ownerDBIDDocUrl ? ownerDBIDDocUrl : "", // Image or File (optional)
            additionalDocuments: additionalDocumentsArray.length !== 0 ? additionalDocumentsArray : "",
            isSellerDelivered: "N",
            nidBackDoc: "", // Image or File (optional)
        };

        dispatch(createSellerRecord(data))
    }

    useEffect(() => {

        if (createSellerData?.errors && createSellerData?.errors[0] === "Email or Contact No Already Exist") {
            toast.error("Email or Contact No Already Exist");
            setTimeout(() => {
                setStep(1)
            }, 2500);
        }
        else if (createSellerData?.succeed === false) {
            toast.error("Something went wrong, Please try again");
            setTimeout(() => {
                setStep(1)
            }, 2500);
        }
        else if (createSellerData?.succeed === true) {
            toast.info("Registration Successful! Redirection to Login");
            dispatch(createSellerReset())
            setTimeout(() => {
                history.push("/SellerLogin");
            }, 2500);
        }
        else if (createSellerData?.message === "success") {
            toast.info("Registration Successful! Redirection to Login");
            dispatch(createSellerReset())
            setTimeout(() => {
                history.push("/SellerLogin");
            }, 2500);
        }
    }, [createSellerDataSuccess])


    const handleBusinessTypesMultiChange = (businessLists) => {
        const valuesArray = businessLists.map(item => item.value);
        setBusinessTypeIdsMulti(valuesArray);
    }

    const handleCountryChange = async (field, e) => {
        dispatch(getAllCityRecord(e.value))

        setCountryId(e.value)
    };

    useEffect(() => {
        setCityList(allCities.map(
            ({ cityName: label, cityId: value }) => ({
                label,
                value,
            })
        ))
    }, [allCities])

    const handleCityChange = async (field, e) => {
        setShopCity(e.label)

        dispatch(getAllAreaRecord(e.value))

    };

    useEffect(() => {
        setAreaList(allAreas.map(
            ({ areaName: label, areaId: value }) => ({
                label,
                value,
            })
        ))
    }, [allAreas])

    const handleAreaChange = async (field, e) => {
        setShopState(e.label)
    };

    const fileSelectedHandlerLogo = (e) => {
        const ShopLogoImageFile = e.target.files[0];
        //1)    CHECK IF IT'S A IMAGE
        let ShopLogoFileInput = false;
        if (ShopLogoImageFile) {
            if (!ShopLogoImageFile.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
                toast.error("Select a valid image.");
                return false;
            }
            ShopLogoFileInput = true;
            if (ShopLogoFileInput) {
                try {
                    Resizer.imageFileResizer(
                        ShopLogoImageFile,
                        250,
                        250,
                        "JPEG",
                        100,
                        0,
                        (uri) => {
                            setShopLogoUrl(uri)
                            setShowShopLogoFile(URL.createObjectURL(ShopLogoImageFile))
                            toast.success("Shop Logo Image Selected.");
                        },
                        "base64",
                        250,
                        250
                    );
                } catch (err) {
                    toast.error("Something went wrong!");
                }
            }
        }
    };
    //----------------------------------------------------------

    const fileSelectedNIDHandler = (e) => {
        const NIDFrontFile = e?.target?.files[0];

        if (NIDFrontFile?.size / 1048576 > 3) {
            toast.error("File size must be within 3 Mb.");
            e.target.value = null
            setOwnerNidUrl(null)
            return
        }

        setOwnerNidName(NIDFrontFile?.name);
        // FileReader function for read the file.
        var fileReader = new FileReader();
        var base64;
        // Onload of file read the file content
        fileReader.onload = function (fileLoadedEvent) {
            base64 = fileLoadedEvent.target.result;
            // Set Data
            setOwnerNidUrl(base64);
        };
        // Convert data to base64
        // fileReader?.readAsDataURL(CVorResumeFile);
        const unusedVariable = fileReader?.readAsDataURL(NIDFrontFile);
        // e.target.value = null;
    };

    const fileSelectedBINHandler = (e) => {
        const BINDocFile = e?.target?.files[0];

        if (BINDocFile?.size / 1048576 > 3) {
            toast.error("File size must be within 3 Mb.");
            e.target.value = null
            setOwnerBINUrl(null)
            return
        }

        setOwnerBINName(BINDocFile?.name);
        // FileReader function for read the file.
        var fileReader = new FileReader();
        var base64;
        // Onload of file read the file content
        fileReader.onload = function (fileLoadedEvent) {
            base64 = fileLoadedEvent.target.result;
            // Set Data
            setOwnerBINUrl(base64);
        };
        // Convert data to base64
        // fileReader?.readAsDataURL(CVorResumeFile);
        const unusedVariable = fileReader?.readAsDataURL(BINDocFile);
        // e.target.value = null;
    };

    const fileSelectedTradeLicenseHandler = (e) => {
        const TradeLicense = e?.target?.files[0];

        if (TradeLicense?.size / 1048576 > 3) {
            toast.error("File size must be within 3 Mb.");
            e.target.value = null
            setOwnerTradeLicenseUrl(null)
            return
        }

        setOwnerTradeLicenseName(TradeLicense?.name);
        // FileReader function for read the file.
        var fileReader = new FileReader();
        var base64;
        // Onload of file read the file content
        fileReader.onload = function (fileLoadedEvent) {
            base64 = fileLoadedEvent.target.result;
            // Set Data
            setOwnerTradeLicenseUrl(base64);
        };
        // Convert data to base64
        // fileReader?.readAsDataURL(CVorResumeFile);
        const unusedVariable = fileReader?.readAsDataURL(TradeLicense);
        // e.target.value = null;
    };

    const fileSelectedBusinessDocHandler = (e) => {
        const BusinessDoc = e?.target?.files[0];

        if (BusinessDoc?.size / 1048576 > 3) {
            toast.error("File size must be within 3 Mb.");
            e.target.value = null
            setBusinessDocUrl(null)
            return
        }

        setBusinessDocName(BusinessDoc?.name);
        // FileReader function for read the file.
        var fileReader = new FileReader();
        var base64;
        // Onload of file read the file content
        fileReader.onload = function (fileLoadedEvent) {
            base64 = fileLoadedEvent.target.result;
            // Set Data
            setBusinessDocUrl(base64);
        };
        // Convert data to base64
        // fileReader?.readAsDataURL(CVorResumeFile);
        const unusedVariable = fileReader?.readAsDataURL(BusinessDoc);
        // e.target.value = null;
    };

    const fileSelectedTINHandler = (e) => {
        const TINDocFile = e?.target?.files[0];

        if (TINDocFile?.size / 1048576 > 3) {
            toast.error("File size must be within 3 Mb.");
            e.target.value = null
            setOwnerTINDocUrl(null)
            return
        }

        setOwnerTINDocName(TINDocFile?.name);
        // FileReader function for read the file.
        var fileReader = new FileReader();
        var base64;
        // Onload of file read the file content
        fileReader.onload = function (fileLoadedEvent) {
            base64 = fileLoadedEvent.target.result;
            // Set Data
            setOwnerTINDocUrl(base64);
        };
        // Convert data to base64
        // fileReader?.readAsDataURL(CVorResumeFile);
        const unusedVariable = fileReader?.readAsDataURL(TINDocFile);
        // e.target.value = null;
    };

    const fileSelectedDBIDHandler = (e) => {
        const DBIDDocFile = e?.target?.files[0];

        if (DBIDDocFile?.size / 1048576 > 3) {
            toast.error("File size must be within 3 Mb.");
            e.target.value = null
            setOwnerDBIDDocUrl(null)
            return
        }

        setOwnerDBIDDocName(DBIDDocFile?.name);
        // FileReader function for read the file.
        var fileReader = new FileReader();
        var base64;
        // Onload of file read the file content
        fileReader.onload = function (fileLoadedEvent) {
            base64 = fileLoadedEvent.target.result;
            // Set Data
            setOwnerDBIDDocUrl(base64);
        };
        // Convert data to base64
        // fileReader?.readAsDataURL(CVorResumeFile);
        const unusedVariable = fileReader?.readAsDataURL(DBIDDocFile);
        // e.target.value = null;
    };

    const removeAdditionalDocNum = (e, index) => {
        e.preventDefault()
        setAdditionalDocNum(additionalDocNum - 1)
    }

    const handleAddAdditional = () => {
        setAdditionalDocNum(additionalDocNum + 1)
        setAdditionalDocumentsArray([...additionalDocumentsArray, { docName: '', docPath: '' }]);
    }

    useEffect(() => {
        if (additionalDocNum === 5) {
            toast.info("Max 5 additional documents can be upload!");
            return
        }
    }, [additionalDocNum])

    const handleRemoveAdditional = (e, index) => {
        setAdditionalDocNum(additionalDocNum - 1)
        const list = [...additionalDocumentsArray];
        list.splice(index, 1);
        setAdditionalDocumentsArray(list);
    }

    const handleOnBlur = (e, index) => {
        const { name, value } = e.target;
        const list = [...additionalDocumentsArray];
        list[index][name] = value;
        setAdditionalDocumentsArray(list);
    }

    const additionalFileSelectedHandler = (e, index) => {
        const additionalFile = e?.target?.files[0];

        const { name } = e.target;
        const list = [...additionalDocumentsArray];

        if (additionalFile?.size / 1048576 > 3) {
            toast.error("File size must be within 3 Mb.");
            e.target.value = null
            list[index][name] = null
            return
        }

        // setAdditionalDocumentsArray(list);

        // setOwnerTradeLicenseName(additionalFile?.name);
        // FileReader function for read the file.
        var fileReader = new FileReader();
        var base64;
        // Onload of file read the file content
        fileReader.onload = function (fileLoadedEvent) {
            base64 = fileLoadedEvent.target.result;
            // Set Data
            list[index][name] = base64;
        };
        // Convert data to base64
        // fileReader?.readAsDataURL(CVorResumeFile);
        const unusedVariable = fileReader?.readAsDataURL(additionalFile);
        // e.target.value = null;
        // list[index][name] = value;
        setAdditionalDocumentsArray(list);
    };

    const showStep = () => {
        if (step === 1) {
            return (
                <>
                    <PersonalInfo
                        nextStep={nextStep}
                        handleChange={handleChange}
                        sellerName={sellerName}
                        userContactNo={userContactNo}
                        userEmail={userEmail}
                        isEmailAvailable={isEmailAvailable}
                        isContactAvailable={isContactAvailable}
                        sellerLoadingInfoFromReducer={sellerInfoFromReducer}
                    />
                    <ToastContainer autoClose={2500} />
                </>
            );
        }
        if (step === 2) {
            return (
                <>
                    <SellerCredential
                        nextStepCredential={nextStepCredential}
                        handleChange={handleChange}
                        tokenId={tokenId}
                        tokenCode={tokenCode}
                        userInfo={{
                            userContactNo,
                            userEmail,
                            actionFor: "r",
                            userType: "seller",
                        }}
                    />
                    <ToastContainer autoClose={2500} />
                </>
            );
        }
        if (step === 3) {
            return (
                <>
                    <SellerShopInfo
                        saveSellerInfo={saveSellerInfo}
                        businessTypes={bussinessTypes}
                        businessTypeList={businessTypeList}

                        allCountries={allCountries}
                        countryList={countryList}
                        handleChange={handleChange}

                        fileSelectedHandlerLogo={fileSelectedHandlerLogo}
                        showShopLogoFile={showShopLogoFile}
                        shopLogoUrl={shopLogoUrl}

                        handleCountryChange={handleCountryChange}
                        handleCityChange={handleCityChange}
                        handleAreaChange={handleAreaChange}
                        cityList={cityList}
                        areaList={areaList}

                        fileSelectedNIDHandler={fileSelectedNIDHandler}
                        setOwnerNidUrl={setOwnerNidUrl}
                        ownerNidUrl={ownerNidUrl}
                        ownerNidName={ownerNidName}

                        fileSelectedBINHandler={fileSelectedBINHandler}
                        setOwnerBINUrl={setOwnerBINUrl}
                        ownerBINUrl={ownerBINUrl}
                        ownerBINName={ownerBINName}

                        fileSelectedTradeLicenseHandler={fileSelectedTradeLicenseHandler}
                        setOwnerTradeLicenseUrl={setOwnerTradeLicenseUrl}
                        ownerTradeLicenseUrl={ownerTradeLicenseUrl}
                        ownerTradeLicenseName={ownerTradeLicenseName}

                        fileSelectedBusinessDocHandler={fileSelectedBusinessDocHandler}
                        setBusinessDocUrl={setBusinessDocUrl}
                        businessDocUrl={businessDocUrl}
                        businessDocName={businessDocName}

                        fileSelectedTINHandler={fileSelectedTINHandler}
                        setOwnerTINDocUrl={setOwnerTINDocUrl}
                        ownerTINDocUrl={ownerTINDocUrl}
                        ownerTINDocName={ownerTINDocName}

                        fileSelectedDBIDHandler={fileSelectedDBIDHandler}
                        setOwnerDBIDDocUrl={setOwnerDBIDDocUrl}
                        ownerDBIDDocUrl={ownerDBIDDocUrl}
                        ownerDBIDDocName={ownerDBIDDocName}

                        setAdditionalDocNum={setAdditionalDocNum}
                        additionalDocNum={additionalDocNum}
                        removeAdditionalDocNum={removeAdditionalDocNum}

                        additionalDocumentsArray={additionalDocumentsArray}
                        setAdditionalDocumentsArray={setAdditionalDocumentsArray}
                        handleAddAdditional={handleAddAdditional}
                        handleOnBlur={handleOnBlur}
                        handleRemoveAdditional={handleRemoveAdditional}
                        additionalFileSelectedHandler={additionalFileSelectedHandler}

                        handleBusinessTypesMultiChange={handleBusinessTypesMultiChange}
                    />
                    <ToastContainer autoClose={1500} />
                </>
            );
        }
    }

    return (
        <>{showStep()}</>
    )
}

export default CreateSellerContainerV2
