/* eslint-disable react-hooks/exhaustive-deps */
import html2pdf from "html2pdf.js";
import moment from "moment";
import React, { useEffect } from "react";
import "../css/DateWiseSalesSummaryView.css";
import logo from "./../../../assets/images/Report_Logo.jpg";
import DateWiseSalesSummarySmallData from "./DateWiseSalesSummarySmallData";

const DateWiseSalesSummaryView = ({
  startDate,
  endDate,
  arrayDatas,
  format,
  setShowQuickView,
  dateWiseSalesSummaryData,
}) => {
  const convertToPDF = () => {
    setShowQuickView(true);
    const element = document.getElementById("accounts__report_items");

    // Get the current date and time
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString();
    const formattedTime = currentDate.toLocaleTimeString();

    const scaleValue =
      dateWiseSalesSummaryData?.data?.length > 250
        ? 0.7
        : dateWiseSalesSummaryData?.data?.length > 200
        ? 0.8
        : dateWiseSalesSummaryData?.data?.length > 150
        ? 0.9
        : dateWiseSalesSummaryData?.data?.length > 100
        ? 1
        : dateWiseSalesSummaryData?.data?.length > 20
        ? 1.5
        : 2;
  
    html2pdf()
      .set({
        html2canvas: {
          scale: scaleValue,
        },
        margin: [10, 10, 18, 10],
      })
      .from(element)
      .toContainer()
      .toCanvas()
      .toImg()
      .toPdf()
      .get("pdf")
      .then((pdf) => {
        var totalPages = pdf.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(11);
          pdf.setTextColor(100);
          pdf.text(
            "Page " + i + " of " + totalPages,
            pdf.internal.pageSize.getWidth() / 1.18,
            pdf.internal.pageSize.getHeight() / 1.02
          );
          pdf.text(
            "Note: It's a computer generated report, no signature is required. ",
            pdf.internal.pageSize.getWidth() / 17,
            pdf.internal.pageSize.getHeight() / 1.02
          );
          if (i === 1) {
            pdf.setFontSize(8);
            pdf.text(
              `Print Date: ${moment(formattedDate).format("DD-MMM-YYYY")}`,
              pdf.internal.pageSize.getWidth() / 1.25,
              33
            );
            pdf.text(
              `Print Time: ${formattedTime}`,
              pdf.internal.pageSize.getWidth() / 1.255,
              37
            );
          }
        }
      })
      // .save()
      .output("dataurlstring")
      .then((pdfDataUrl) => {
        window.open().document.body.innerHTML = `<embed src="${pdfDataUrl}" width="100%" height="100%" type="application/pdf">`;
      });
  };

  useEffect(() => {
    if (format === 2) {
      convertToPDF();
      setShowQuickView(false);
    }
  }, [format, arrayDatas]);

  return (
    <>
      <div className="accounts_DateWiseSalesSummary__report_buttons_container">
        <button onClick={convertToPDF} className="btn btn-primary">
          Convert to PDF
        </button>
      </div>

      <div
        className="accounts_DateWiseSalesSummary__report_container"
        id="accounts__report_items"
      >
        <div className="accounts_DateWiseSalesSummary__report_header">
          <div style={{ width: "15%" }}>
            <img
              style={{ width: "100%", marginLeft: "10px", marginTop: "20px" }}
              src={logo}
              alt="logo"
            />
          </div>

          <div style={{ width: "70%" }}>
            <div className="accounts_DateWiseSalesSummary__report_header_text1">
              SaRa Lifestyle Ltd.-Marketplace
            </div>
            <div className="accounts_DateWiseSalesSummary__report_header_text2">
              Plot No # Shilpa/01, Section #07, Milk Vita Road; Mirpur, Dhaka –
              1216
            </div>
            <div className="accounts_DateWiseSalesSummary__report_header_text3">
              Central BIN: 001168308-0401
            </div>
            <div className="accounts_DateWiseSalesSummary__report_header_text3">
              Date Wise Sales Summary
            </div>
            <div className="accounts_DateWiseSalesSummary__report_header_text3">
              From {moment(startDate).format("D-MMM-YYYY")} to{" "}
              {moment(endDate).format("D-MMM-YYYY")}
            </div>
          </div>
          <div style={{ width: "15%" }}></div>
        </div>
        
        <div className="accounts_DateWiseSalesSummary__report_body">
          <div className="accounts_DateWiseSalesSummary__report_body_sections">
            {arrayDatas[1].map((dt) => (
              <DateWiseSalesSummarySmallData
                vendorName={dt}
                data={arrayDatas[0][dt]}
              />
            ))}
          </div>
          <div className="report_DateWiseSalesSummary_data_container_payment_method_total_container">
            <div className="report_DateWiseSalesSummary_data_container_payment_method_grand_total">
              {" "}
              <div style={{ width: "18%", textAlign: "center" }}>
                Grand Total:
              </div>
              <div style={{ width: "10%", textAlign: "center" }}>
                {arrayDatas[2].cntDelivered.toLocaleString()}
              </div>
              <div style={{ width: "8%", textAlign: "center" }}>
                {arrayDatas[2].deliveredQty.toLocaleString()}
              </div>
              <div style={{ width: "10%", textAlign: "center" }}>
                {arrayDatas[2].deliveredAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
              </div>
              <div style={{ width: "10%", textAlign: "center" }}>
                {arrayDatas[2].discountAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
              </div>
              <div style={{ width: "10%", textAlign: "center" }}>
                {arrayDatas[2].burnAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
              </div>
              <div style={{ width: "10%", textAlign: "center" }}>
                {arrayDatas[2].voucherAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
              </div>
              <div style={{ width: "10%", textAlign: "center" }}>
                {arrayDatas[2].grossAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
              </div>
              <div style={{ width: "12%", textAlign: "center" }}>
                {arrayDatas[2].commissionAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
              </div>
              <div style={{ width: "12%", textAlign: "center" }}>
                {arrayDatas[2].netAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DateWiseSalesSummaryView;
