import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import { Dropdown } from "primereact/dropdown";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import * as Icon from "react-feather";
import baseUrl from "../../../utils/baseUrl";
import httpClient from "../../../utils/httpClient";
import { getAllCityRecord } from "../../../store/actions/addressAction";

const CreateAdsSchema = yup.object().shape({
    // areaName: yup.string().required('Area name is required'),
});

const EditAreaContainer = () => {

    const nevigateTo = useHistory();
    const { state } = useLocation();
    const dispatch = useDispatch();

    const [selectedCity, setSelectedCity] = useState( null);
    const [cityId, setCityId] = useState(state?.data?.cityId || '');
    const [areaName, setAreaName] = useState(state?.data?.areaName || '');
    const [postalCode, setPostalCode] = useState(state?.data?.postalCode || '');
    const [displayOrder, setDisplayOrder] = useState(state?.data?.orderNo || '');
    const [isActive, setIsActive] = useState(state?.data?.status === 'Y' ? true : false);



    const { allCities, loading: cityLoading, success: citySuccess, error } = useSelector((state) => state?.addressReducer);

    const handleChange = (e) => {
        e.preventDefault();
        const { target } = e;

        switch (target.name) {
            case 'cityName':
                setSelectedCity(target.value);
                setCityId(target.value.cityId);
                break;

            case 'areaName':
                setAreaName(target.value);
                break;

            case 'displayOrder':
                setDisplayOrder(target.value);
                break;

            case 'postalCode':
                setPostalCode(target.value);
                break;

            default:
        }
    };

    const handleIsActiveCheck = (e) => {
        setIsActive(e.target.checked);
    };

    const handleCreateArea = async (e) => {
        e.preventDefault();

        try {
            const data = {
                cityId: parseInt(cityId),
                areaId:parseInt(cityId ? state?.data?.areaId : 0),
                areaName,
                postalCode,
                orderNo: parseInt(displayOrder),
                status: isActive ? "Y" : "N"
            };

            const res = await httpClient.post(`${baseUrl}/api/DivisionCitiesArea/CreateOrUpdateArea`, data);
            if (res?.data?.succeed) {
                toast.success(res?.data?.message);
                setTimeout(() => {
                    nevigateTo.push('/AllAreaList');
                }, 2000);
            }else{
                toast.error(res?.data?.message)
            }

        } catch (error) {
            console.log({ error });
        }
    };

    const { register, handleSubmit, formState, errors } = useForm({ mode: "onChange", resolver: yupResolver(CreateAdsSchema) });

    const onSubmit = (data, e) => {
        e.target.reset();
    };

    useEffect(() => {
        if (allCities?.length && cityId) {
            const defaultCity = allCities.find(city => city.cityId === cityId);
            setSelectedCity(defaultCity);
        }
    }, [allCities, cityId]);

    useEffect(() => {
        dispatch(getAllCityRecord(19));
    }, [dispatch]);

    return (
        <>
            <div className="page-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="panel panel-success">
                                <div className="panel-heading">
                                    Create Area
                                    <span style={{ float: "right" }}>
                                        <Link to="/AllAreaList">
                                            <Icon.List className="text-light" />
                                        </Link>
                                    </span>
                                </div>
                                <div className="panel-wrapper collapse in" aria-expanded="true">
                                    <div className="panel-body">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="form-body">
                                                <div className="p-fluid p-formgrid p-grid">
                                                    <div className="p-fluid p-col-12 ">
                                                        <div className="form-group">
                                                            <label className="control_label">
                                                                Select District/City Name
                                                                <span aria-hidden="true" style={{ color: "red", fontWeight: "bold" }}>*</span>
                                                            </label>
                                                            <div className="dropdown-demo">
                                                                <Dropdown
                                                                    optionLabel="cityName"
                                                                    options={allCities}
                                                                    filter
                                                                    showClear
                                                                    filterBy="cityName"
                                                                    name="cityName"
                                                                    value={selectedCity}
                                                                    onChange={handleChange}
                                                                    ref={register}
                                                                    // onChange={(e) => setSelectedCity(e.value)}
                                                                    placeholder="Select city"
                                                                />
                                                                {errors?.cityName && (
                                                                    <span className="error">
                                                                        {errors?.cityName?.message}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-fluid p-formgrid p-grid">
                                                    <div className="p-fluid p-col-12 p-md-6">
                                                        <div className="form-group">
                                                            <label className="control_label">
                                                                Area Name
                                                                <span aria-hidden="true" style={{ color: "red", fontWeight: "bold" }}>*</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                placeholder="Enter Area Name"
                                                                name="areaName"
                                                                value={areaName}
                                                                onChange={handleChange}
                                                                className="form-control"
                                                                ref={register}
                                                            />
                                                            {errors?.areaName && (
                                                                <span className="error">
                                                                    {errors?.areaName?.message}
                                                                </span>
                                                            )}
                                                        </div>

                                                    </div>
                                                    <div className="p-fluid p-col-12 p-md-6">
                                                        <div className="form-group">
                                                            <label className="control_label">
                                                                Display Order
                                                            </label>
                                                            <input
                                                                type="number"
                                                                placeholder="Enter Dispay Order"
                                                                name="displayOrder"
                                                                value={displayOrder}
                                                                onChange={handleChange}
                                                                className="form-control"
                                                                ref={register}
                                                            />
                                                            {errors?.displayOrder && (
                                                                <span className="error">
                                                                    {errors?.displayOrder?.message}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="p-fluid p-col-12 p-md-6">
                                                        <div className="form-group">
                                                            <label className="control_label">
                                                                Postal Code
                                                            </label>
                                                            <input
                                                                type="text"
                                                                placeholder="Enter Postal Code"
                                                                name="postalCode"
                                                                value={postalCode}
                                                                onChange={handleChange}
                                                                className="form-control"
                                                                ref={register}
                                                            />
                                                            {errors?.postalCode && (
                                                                <span className="error">
                                                                    {errors?.postalCode?.message}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className='p-fluid p-col-12 p-md-6 flex justify-center'>
                                                        <div className='form-group'>
                                                            <label className='control_label'>Active</label>
                                                            <div className='checkbox checkbox-success d-flex align-items-center'>
                                                                <input
                                                                    id='isActive'
                                                                    type='checkbox'
                                                                    name='isActive'
                                                                    checked={isActive}
                                                                    onChange={handleIsActiveCheck}
                                                                />
                                                                <label htmlFor='isActive'> &nbsp;Yes </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-footer">
                                                <div className="form-group row">
                                                    <div className="text-center">
                                                        <div className="btn-group text-center">
                                                            <button
                                                                className="btn btn-success"
                                                                onClick={handleCreateArea}
                                                                disabled={!formState?.isValid || !areaName || !cityId}
                                                                style={{ cursor: "pointer" }}
                                                            >
                                                                Create
                                                            </button>
                                                            <Link to="/Home">
                                                                <button
                                                                    className="btn btn-danger"
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    Cancel
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer autoClose={1500} />
        </>
    );
};

export default EditAreaContainer;


