/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/saga-blue/theme.css";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { calculateShippingCostAllRecord } from "../../store/actions/shippingAction";
import { checkVoucher } from "../../store/actions/voucherAction";
import baseUrl from "../../utils/baseUrl";
import ApplyCoupon from "./ApplyCoupon";


export default function AddProduct(props) {
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 40,
        color: "#1F5DA0",
      }}
      spin
    />
  );

  const smallAntIcon = (<LoadingOutlined
    style={{
      fontSize: 18,
      // color: "#1F5DA0",
    }}
    spin
  />);

  const dropDownOptions = [
    {
      OrderSource: "Facebook",
    },
    {
      OrderSource: "Free Delivery",
    },
    {
      OrderSource: "Instagram",
    },
    {
      OrderSource: "Email",
    },
    {
      OrderSource: "Whats App",
    },
    {
      OrderSource: "Over Phone Call",
    },
    {
      OrderSource: "SaRa Office Employee",
    },
    {
      OrderSource: "Snowtex Office Employee",
    },
    {
      OrderSource: "Shop Employee",
    },
    {
      OrderSource: "Other",
    },
  ];

  const { addProduct, confirmAddress, deliveryAddress } = props;

  const [voucher, setVoucher] = useState("");

  const [orderSource, setOrderSource] = useState(null);
  const [inputQty, setInputQty] = useState(null);
  const [displayButton, setDisplayButton] = useState(false);
  const [voucherCode, setVoucherCode] = useState('')

  const dispatch = useDispatch();
  const getVoucher = (data) => {
    setVoucher(data);
  };

  let emptyProduct = {
    sellerProductSku: "",
    shopProductSku: "",
    productId: "",
    productName: "",
    productTitle: "",
    productUrl: "",
    thumbnailImage: "",
    categoryName: "",
    productPrice: "",
    shopId: "",
    shopName: "",
    commissionPercentage: "",
    commissionAmount: "",
    unitId: "",
    unit: "",
    rootCategoryId: "",
    quantity: 0,
    variantToSend1: "",
    variantToSend2: "",
  };

  let initialProduct = {
    sellerProductSku: addProduct?.singleProduct?.sellerProductSku,
    shopProductSku: addProduct?.singleProduct?.shopProductSku,
    productId: addProduct?.singleProduct?.productId,
    productName: addProduct?.singleProduct?.productName,
    productTitle: addProduct?.singleProduct?.productTitle,
    productUrl: addProduct?.singleProduct?.productUrl,
    quantity: addProduct?.singleProduct?.productQuantity,
    thumbnailImage: addProduct?.singleProduct?.thumbnailImage,
    categoryName: addProduct?.singleProduct?.categoryName,
    burnAmount: addProduct?.singleProduct.burnAmount,
    productDiscount: addProduct?.singleProduct?.discountAmount,
    productPrice: addProduct?.singleProduct?.productPrice,
    shopId: addProduct?.singleProduct?.shopId,
    shopName: addProduct?.singleProduct?.shopName,
    commissionPercentage: addProduct?.singleProduct?.commissionPercentage,
    commissionAmount: addProduct?.singleProduct?.commissionAmount,
    unitId: addProduct?.singleProduct?.unitId,
    unit: addProduct?.singleProduct?.unit,
    rootCategoryId: addProduct?.singleProduct?.rootCategoryId,
    variantToSend1: addProduct?.singleProduct?.productColorAndSizes
      ? addProduct?.singleProduct?.productColorAndSizes?.find(item => item?.variantName === 'Color')
      : null,
    variantToSend2: addProduct?.singleProduct?.productColorAndSizes
      ? addProduct?.singleProduct?.productColorAndSizes?.find(item => item?.variantName === 'Size')
      : null,
  };

  const [products, setProducts] = useState([]);
  const [productDialog, setProductDialog] = useState(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [product, setProduct] = useState(
    addProduct.productFound ? initialProduct : emptyProduct
  );
  const [selectedProducts, setSelectedProducts] = useState([]);

  const [customerId, setCustomerId] = useState(0);
  const [customerName, setCustomerName] = useState(null);
  const [customerContactNo, setCustomerContactNo] = useState(null);

  const [subTotal, setSubTotal] = useState(0);
  const [voucherDiscount, setVoucherDiscount] = useState(0);
  const [productDiscount, setProductDiscount] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [bilLoading, setBillLoading] = useState(false);

  const { calculateShippingCostAll,
    loading: shippingLoading
  } = useSelector((state) => state.shippingReducer);

  const selectedSizeColor = addProduct?.singleProduct?.productColorAndSizes && addProduct?.singleProduct?.productColorAndSizes?.filter(item => item.variantName === 'Color' || item.variantName === 'Size').map(c_item => `${c_item?.variantName}: ${c_item.variantOptionText}`).join(',');

  const { voucherValidity } = useSelector((state) => state.voucherValidity);

  useEffect(() => {
    dispatch(checkVoucher(""))
    setVoucherDiscount(0)
    // setVoucher("")
    setVoucherCode("")
  }, [])

  useEffect(() => {
    if (products.length) {
      setBillLoading(true);
      const sub_total = products.reduce((accumulator, currentValue) => accumulator + currentValue.productPrice * currentValue.quantity, 0);
      setSubTotal(sub_total);
      const product_discount = products.reduce((accumulator, currentValue) => accumulator + (currentValue.productDiscount + currentValue.burnAmount) * currentValue.quantity, 0);
      setProductDiscount(parseInt(product_discount));

      let calculateVoucherAmt = 0
      if (calculateShippingCostAll?.data?.shippingCost >= 0) {
        if (voucherValidity?.voucherDiscountAmount) {
          setVoucherDiscount(voucherValidity?.voucherDiscountAmount || 0);
        }
        if (voucherValidity?.voucherDiscountPercent) {
          calculateVoucherAmt = ((sub_total - parseInt(product_discount)) * voucherValidity?.voucherDiscountPercent) / 100;
          if (calculateVoucherAmt <= voucherValidity.voucherMaximumAmount || voucherValidity.voucherMaximumAmount === 0) {
            setVoucherDiscount(calculateVoucherAmt || 0);
          } else {
            setVoucherDiscount(voucherValidity.voucherMaximumAmount || 0);
          }
        }
        if (voucherValidity?.length == 0) {
          calculateVoucherAmt = 0;
          setVoucherDiscount(0);
          // setVoucher("")
          setVoucherCode("")
        }

        setShippingCost(calculateShippingCostAll?.data?.shippingCost);

        const grand_total = sub_total - parseInt(product_discount) - parseInt(voucherValidity?.voucherDiscountAmount || calculateVoucherAmt || 0) + parseInt(calculateShippingCostAll?.data?.shippingCost);
        setGrandTotal(grand_total);

        setBillLoading(false);
      }
    }
  }, [products, voucherValidity, calculateShippingCostAll?.data?.shippingCost]);

  useEffect(() => {
    let shipping_cost_data = {
      countryId: deliveryAddress?.countryId,
      cityId: deliveryAddress?.cityId,
      areaId: deliveryAddress?.areaId,
      orderSource: orderSource?.OrderSource,
      products: products?.map((data) => {
        return {
          productId: data?.productId,
          orderQty: Number(data?.quantity),
          sellerProductSku: data?.sellerProductSku,
          shopProductSku: data?.shopProductSku,
        }
      }),
    };
    if (products.length) {
      dispatch(calculateShippingCostAllRecord(shipping_cost_data));
    }
  }, [dispatch, products, orderSource])

  useEffect(() => {
    setCustomerId(addProduct.customerId);
    setCustomerName(addProduct.cusName);
    setCustomerContactNo(addProduct.contactNo);
  }, [addProduct]);

  const toast = useRef(null);
  const dt = useRef(null);

  const openNew = () => {
    setProduct(initialProduct);
    // setSubmitted(false);
    setProductDialog(true);
    setInputQty(null);
  };

  const formatCurrency = (value) => {
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "BDT",
    });
  };

  const hideDialog = () => {
    setProductDialog(false);
  };

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };

  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialog(false);
  };

  const saveProduct = () => {
    if (product.productName.trim()) {
      let _products = [...products];
      let _product = { ...product };
      if (product.id) {
        const index = findIndexById(product.id);
        _products[index] = _product;
        toast.current.show({
          severity: "info",
          summary: "Modified",
          detail: "Product Info Updated",
          life: 3000,
        });
      } else {
        _product.id = product.sellerProductSku;
        _products.push(_product);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "New Product Added to List",
          life: 3000,
        });
      }

      setProducts(_products);
      setProductDialog(false);
      setProduct(initialProduct);
      addProduct.handler();
    }
  };


  const editProduct = (product) => {
    setProduct({ ...product });
    setProductDialog(true);
  };

  const confirmDeleteProduct = (product) => {
    setProduct(product);
    setDeleteProductDialog(true);
  };

  const deleteProduct = () => {
    let _products = products.filter(
      (val) => val.sellerProductSku !== product.sellerProductSku
    );
    setProducts(_products);
    setDeleteProductDialog(false);
    setProduct(initialProduct);
    toast.current.show({
      severity: "warn",
      summary: "Deleted",
      detail: "Product Removed from List",
      life: 3000,
    });
  };

  const findIndexById = (id) => {
    let index = -1;
    for (let i = 0; i < products.length; i++) {
      if (products[i].id === id) {
        index = i;
        break;
      }
    }
    return index;
  };

  // const confirmDeleteSelected = () => {
  //   setDeleteProductsDialog(true);
  // };

  const deleteSelectedProducts = () => {
    let _products = products.filter((val) => !selectedProducts.includes(val));
    setProducts(_products);
    setDeleteProductsDialog(false);
    setSelectedProducts(null);
    toast.current.show({
      severity: "danger",
      summary: "Successful",
      detail: "Products Deleted",
      life: 3000,
    });
  };

  const deleteDiscount = () => {
    dispatch(checkVoucher(""))
    setVoucherDiscount(0)
    // setVoucher("")
    setVoucherCode("")
    toast.current.show({
      severity: "danger",
      summary: "Voucher/Coupon Deleted",
      life: 1500,
    });
  }

  const onInputNumberChange = (e, name, stock) => {

    setInputQty(e.target.value);
    if (stock >= e.target.value) {
      const val = e.target.value || 0;
      let _product = { ...product };
      _product[`${name}`] = val;
      setProduct(_product);
    }
  };
  useEffect(() => {
    if (confirmAddress === false) {
      setProducts([]);
      // setGreatestShippingCost(0);
      // setGreatestActualShippingCost(0);
    }
  }, [confirmAddress]);


  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        {addProduct.productFound && (
          <>
            {setDisplayButton(false)}
            {products.map((data) => (
              <>
                {data?.sellerProductSku ===
                  addProduct?.singleProduct?.sellerProductSku ? (
                  <b style={{ fontSize: "14px", color: "#db1b1b" }}>
                    {" "}
                    Already Added this Product SKU !!!
                    {setDisplayButton(true)}
                  </b>
                ) : (
                  <></>
                )}
              </>
            ))}

            {addProduct?.singleProduct?.productQuantity === 0 ? (
              <b style={{ fontSize: "15px", color: "#db1b1b" }}>
                {" "}
                Out Of Stock !!!
              </b>
            ) : (
              <>
                {!displayButton && (
                  <>
                    {" "}
                    <Button
                      label="New"
                      icon="pi pi-plus"
                      className="p-button-success p-mr-2"
                      onClick={openNew}
                    />
                    &nbsp;&nbsp;&nbsp;
                    <b style={{ fontSize: "12px", color: "rgb(102 104 106)" }}>
                      ({addProduct?.singleProduct?.productQuantity} Left)
                    </b>
                  </>
                )}
              </>
            )}
          </>
        )}
      </React.Fragment>
    );
  };

  const imageBodyTemplate = (rowData) => {
    return (
      <img
        src={baseUrl.concat(rowData.thumbnailImage)}
        alt={rowData.productName}
        className="product-image"
        style={{ height: "50px", width: "50px" }}
      />
    );
  };

  const priceBodyTemplate = (rowData) => {
    return formatCurrency(rowData.productPrice);
  };

  const variantBodyTemplate = (rowData) => {

    return (
      <React.Fragment>
        <p>
          <span>{rowData?.variantToSend1?.variantName}</span> :{" "}
          <span>{rowData?.variantToSend1?.variantOptionText}</span> <br />
          {rowData?.variantToSend2?.variantName && (
            <>
              <span>{rowData?.variantToSend2?.variantName}</span> :{" "}
            </>
          )}
          {rowData?.variantToSend2?.variantOptionText && (
            <>
              <span>{rowData?.variantToSend2?.variantOptionText}</span>
            </>
          )}
        </p>
      </React.Fragment>
    );
  };

  const quantityBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <p>{rowData?.quantity}</p>
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success p-mr-2"
          onClick={() => editProduct(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger"
          onClick={() => confirmDeleteProduct(rowData)}
        />
      </React.Fragment>
    );
  };

  const productDialogFooter = (id, qty) => {
    return (
      <React.Fragment>
        <Button
          label="Cancel"
          icon="pi pi-times"
          className="p-button-text"
          onClick={hideDialog}
        />
        <Button
          label="Add"
          icon="pi pi-check"
          className="p-button-text"
          onClick={() => {
            if (orderSource === null) {
              toast.current.show({ severity: 'error', summary: 'Error', detail: 'Select Order Source!' });
            } else {
              saveProduct();
            }

            // setProductSingleId(id);
            // setProductSingleQty(qty);
            // handleDuplicate(id);
          }}
          disabled={
            inputQty < 1 ||
            inputQty > addProduct?.singleProduct?.productQuantity
          }
        />
      </React.Fragment>
    );
  };

  const deleteProductDialogFooter = (productId) => {
    return (
      <React.Fragment>
        <Button
          label="No"
          icon="pi pi-times"
          className="p-button-text"
          onClick={hideDeleteProductDialog}
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          className="p-button-text"
          onClick={() => {
            deleteProduct();
            // handleRemovedForShipping(productId);
          }}
        />
      </React.Fragment>
    );
  };

  const deleteProductsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteProductsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteSelectedProducts}
      />
    </React.Fragment>
  );

  const result = products.reduce(
    (h, obj) =>
      Object.assign(h, { [obj.shopId]: (h[obj.shopId] || []).concat(obj) }),
    {}
  );

  let dataArray = Object.keys(result).map((k) => result[k]);

  let shopToSendObject = [];

  dataArray.forEach((item) => {
    shopToSendObject.push({
      shopId: item[0].shopId,
      shopName: item[0].shopName,
      voucherCode: voucher?.voucherCode || "",
      orderDetails: item.map((product) => ({
        shopId: product.shopId,
        productPrice: product.productPrice,
        productQuantity: product.quantity,
        productTitle: product.productName,
        productImage: product.thumbnailImage,
        productId: product.productId,
        shopProductSku: product?.shopProductSku,
        rootCategoryId: product?.rootCategoryId,
        productVariant: `${product?.variantToSend1?.variantName}: ${product?.variantToSend1?.variantOptionText}, ${product?.variantToSend2?.variantName}: ${product?.variantToSend2?.variantOptionText}`,
        sku: product?.productTitle,
      })),
    });
  });

  let dataToSendApi = {
    customerId,
    customerName,
    customerContactNo,
    shippingAddress: addProduct.addressData,
    customerCurrencyCode: "BDT",
    orderSubtotalAmt: subTotal,
    orderSubtotalDiscountAmt: productDiscount,
    totalShippingCharge: shippingCost,
    orderSource: orderSource?.OrderSource,
    totalPayableAmount: grandTotal,
    paymentStatusId: 0,
    voucherAmount: voucherDiscount || 0,
    shopWiseOrders: shopToSendObject,
    shippingCostModel: {
      countryId: deliveryAddress?.countryId,
      cityId: deliveryAddress?.cityId,
      areaId: deliveryAddress?.areaId,
      ...orderSource,
      products: products?.map((data) => {
        return {
          productId: data?.productId,
          orderQty: Number(data?.quantity),
          sellerProductSku: data?.sellerProductSku,
          shopProductSku: data?.shopProductSku,
        }
      })
    }
  };

  const placeOrder = () => {
    addProduct.handleOrder(dataToSendApi);
  };

  const handleOrderSourceChange = (e) => {
    setOrderSource(e.value);
  };

  return (
    <div
      className="row"
      style={{
        marginTop: "10px",
        display: confirmAddress === true ? "block" : "none",
      }}
    >
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <div className="datatable-crud-demo">
            <Toast ref={toast} />
            <div className="" style={{ padding: "10px", width: "19%", margin: "20px 15px" }}>
              <span style={{ fontSize: "20px", fontWeight: "600" }}>
                Order Source <span className="text-danger">*</span>
              </span>
              <Dropdown
                style={{ fontSize: "14px", minWidth: "100%" }}
                value={orderSource}
                options={dropDownOptions}
                onChange={handleOrderSourceChange}
                optionLabel="OrderSource"
                name="OrderSource"
                placeholder="Select Order Source"
                filter
                filterBy="OrderSource"
              />
            </div>
            <div className="card">
              <div
                style={{
                  border: "1px solid #ddd",
                  padding: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <div style={{ fontSize: "17px", fontWeight: "600" }}>
                  Manage Products
                </div>
                <Toolbar
                  style={{ margin: "0", padding: "0" }}
                  left={leftToolbarTemplate}
                ></Toolbar>
                <div style={{ width: "40%", position: "relative" }}>
                  <input
                    autoFocus
                    type="text"
                    id="productSku"
                    className="form-control"
                    placeholder="Enter Product SKU"
                    name="productSku"
                    value={addProduct.productSku}
                    onChange={addProduct.handleChange}
                    autoComplete="off"
                  />

                  <button
                    type="submit"
                    className="btn btn-success"
                    onClick={addProduct.getProduct}
                    style={{
                      position: "absolute",
                      top: "1px",
                      right: "-6px",
                    }}
                  >
                    <i className="fa fa-search"></i>
                  </button>
                </div>
              </div>
              <DataTable
                ref={dt}
                value={products}
                dataKey="sellerProductSku"
                responsiveLayout="scroll"
              >
                <Column
                  field="thumbnailImage"
                  header="Image"
                  body={imageBodyTemplate}
                ></Column>
                <Column
                  field="shopName"
                  header="Shop Name"
                  sortable
                ></Column>
                <Column
                  field="productName"
                  header="Product Name"
                  sortable
                ></Column>
                <Column
                  field="sellerProductSku"
                  header="Seller Product SKU"
                  sortable
                ></Column>

                <Column
                  field="categoryName"
                  header="Category"
                  sortable
                ></Column>
                <Column
                  field="productTitle"
                  header="SKU/Style"
                  sortable
                ></Column>
                <Column
                  field="productPrice"
                  header="Variants"
                  body={variantBodyTemplate}
                ></Column>

                <Column
                  field="productPrice"
                  header="Price"
                  body={priceBodyTemplate}
                  sortable
                ></Column>
                <Column
                  field="quantity"
                  header="Qty"
                  sortable
                  body={quantityBodyTemplate}
                ></Column>
                <Column
                  header="Action"
                  body={actionBodyTemplate}
                  exportable={false}
                  style={{ minWidth: "8rem" }}
                ></Column>
              </DataTable>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div />
              <div>
                <figure className={{ float: "right" }}>
                  <figcaption
                    style={{
                      paddingTop: "5px",
                      paddingBottom: "5px",
                    }}
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <strong style={{ textTransform: "uppercase" }}>
                          Sub Total
                        </strong>
                      </div>
                      <div className="col-md-6">
                        <b>TK {bilLoading ? <Spin indicator={smallAntIcon} /> : subTotal}</b>


                      </div>
                    </div>
                  </figcaption>

                  <figcaption
                    style={{
                      paddingTop: "5px",
                      paddingBottom: "10px",
                      borderBottom: "1px solid #eaeaea",
                    }}
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <strong style={{ textTransform: "uppercase" }}>
                          Discount
                        </strong>
                      </div>
                      <div className="col-md-6">
                        <b>TK {bilLoading ? <Spin indicator={smallAntIcon} /> : productDiscount}</b>
                      </div>
                    </div>
                  </figcaption>

                  <figcaption
                    style={{
                      paddingTop: "5px",
                      paddingBottom: "10px",
                    }}
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <strong style={{ textTransform: "uppercase" }}>
                          Voucher/Coupon Amount
                        </strong>
                      </div>
                      <div className="col-md-6" style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}>
                        <b>TK {bilLoading ? <Spin indicator={smallAntIcon} /> : voucherDiscount}</b>
                        {voucherDiscount && voucherDiscount !== 0 ? <Button
                          icon="pi pi-trash"
                          className="p-button-rounded p-button-danger"
                          onClick={deleteDiscount}
                        /> : <></>}

                      </div>
                    </div>
                  </figcaption>

                  <figcaption
                    style={{
                      paddingTop: "5px",
                      paddingBottom: "20px",
                      borderBottom: "1px solid #eaeaea",
                    }}
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <strong style={{ textTransform: "uppercase" }}>
                          Shipping Cost
                        </strong>
                      </div>
                      <div className="col-md-6">
                        <b>TK {bilLoading ? <Spin indicator={smallAntIcon} /> : shippingCost}</b>
                      </div>
                    </div>
                  </figcaption>

                  <figcaption
                    style={{
                      paddingTop: "5px",
                      paddingBottom: "5px",
                    }}
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <strong style={{ textTransform: "uppercase" }}>
                          Grand Total
                        </strong>
                      </div>
                      <div className="col-md-6">
                        <b>
                          TK {bilLoading ? <Spin indicator={smallAntIcon} /> : grandTotal}
                        </b>
                      </div>
                    </div>
                  </figcaption>
                </figure>

                <ApplyCoupon
                  applyCoupon={props}
                  getVoucher={getVoucher}
                  productSize={products?.length}
                  setVoucherCode={setVoucherCode}
                  voucherCode={voucherCode}
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {props?.saving ? (
                <Spin indicator={antIcon} />
              ) : (
                <Button
                  disabled={!(products?.length > 0 && orderSource !== null && !shippingLoading)}
                  style={{
                    padding: "7px 20px",
                    fontSize: "17px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "30%",
                  }}
                  onClick={() => placeOrder()}
                >
                  Create Order{" "}
                </Button>
              )}
            </div>
            <Dialog
              visible={productDialog}
              style={{ width: "450px" }}
              header="Product Details"
              modal
              className="p-fluid"
              footer={productDialogFooter(
                addProduct?.singleProduct?.productId,
                product.quantity
              )}
              onHide={hideDialog}
            >
              {addProduct?.singleProduct?.thumbnailImage && (
                <img
                  src={baseUrl.concat(
                    addProduct?.singleProduct?.thumbnailImage
                  )}
                  alt={`${addProduct?.singleProduct?.productName}`}
                  className="product-image p-d-block p-m-auto p-pb-3"
                  style={{ height: "120px", width: "120px" }}

                />
              )}
              <div className="form-group">
                <label className="control_label">
                  Product Name
                  <span
                    aria-hidden="true"
                    style={{
                      color: "red",
                      fontWeight: "bold",
                    }}
                  >
                    *
                  </span>
                </label>
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <input
                      type="text"
                      id="productName"
                      className="form-control"
                      name="productName"
                      value={addProduct?.singleProduct?.productName}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="control_label">
                  Category{" "}
                  <span
                    aria-hidden="true"
                    style={{
                      color: "red",
                      fontWeight: "bold",
                    }}
                  >
                    *
                  </span>
                </label>
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <input
                      type="text"
                      id="categoryName"
                      className="form-control"
                      name="categoryName"
                      value={addProduct?.singleProduct?.categoryName}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="control_label">
                  Variant{" "}
                  <span
                    aria-hidden="true"
                    style={{
                      color: "red",
                      fontWeight: "bold",
                    }}
                  >
                    *
                  </span>
                </label>
                <div className="row">
                  <div className="col-md-12 col-sm-12">



                    {addProduct?.singleProduct?.productColorAndSizes && (
                      <input
                        type="text"
                        id="Variant"
                        className="form-control"
                        name="variantName"
                        value={selectedSizeColor}
                        readOnly
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label className="control_label">
                      Price{" "}
                      <span
                        aria-hidden="true"
                        style={{
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="text"
                      id="productPrice"
                      className="form-control"
                      name="productPrice"
                      value={addProduct?.singleProduct?.productPrice}
                      readOnly
                    />
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <label className="control_label">
                      Quantity{" "}
                      <span
                        aria-hidden="true"
                        style={{
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      autoFocus
                      type="number"
                      id="quantity"
                      className="form-control"
                      name="quantity"
                      placeholder={`${addProduct?.singleProduct?.productQuantity} Left`}
                      onChange={(e) =>
                        onInputNumberChange(
                          e,
                          "quantity",
                          addProduct?.singleProduct?.productQuantity
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </Dialog>

            <Dialog
              visible={deleteProductDialog}
              style={{ width: "450px" }}
              header="Confirm"
              modal
              footer={deleteProductDialogFooter(product.productId)}
              onHide={hideDeleteProductDialog}
            >
              <div className="confirmation-content">
                {product && (
                  <>
                    <i
                      className="pi pi-exclamation-triangle p-mr-3"
                      style={{ fontSize: "2.1rem" }}
                    />
                    <span style={{ fontSize: "13px" }}>
                      Are you sure you want to delete{" "}
                      <b>{product.productName}</b>?
                    </span>
                  </>
                )}
              </div>
            </Dialog>

            <Dialog
              visible={deleteProductsDialog}
              style={{ width: "450px" }}
              header="Confirm"
              modal
              footer={deleteProductsDialogFooter}
              onHide={hideDeleteProductsDialog}
            >
              <div className="confirmation-content">
                <i
                  className="pi pi-exclamation-triangle p-mr-3"
                  style={{ fontSize: "2rem" }}
                />
                {product && (
                  <span>
                    Are you sure you want to delete the selected products?
                  </span>
                )}
              </div>
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  );
}


