import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import ShowQuantity from '../../component/molecules/ShowQuantity'
import { ProductDetailsModal } from '../../components/product/ProductDetailsModal'
import { ProductListTable } from '../../components/product/ProductListTable'
import useProductQuantity from '../../hooks/useProductQuantity'
import { getBrandRecord } from '../../store/actions/brandAction'
import { getCategoryRecord } from '../../store/actions/categoryAction'
import {
  GET_PRODUCTS_LIST_RESET,
  approveProductRecord,
  getLowstockProductListRecord,
  getProductDetailsRecord,
  getProductListRecord,
  rejectProductRecord,
} from '../../store/actions/productAction'
import { getAllSellerRecord } from "../../store/actions/sellerAction"

export const ManageSellerProducts = () => {

  const history = useHistory();
  const location = useLocation();

  const prevState = location?.state?.state;

  const [currency, setCurrency] = useState('BDT');
  const [isApproved, setIsApproved] = useState();
  const [isDeleted, setIsDeleted] = useState('N');
  const [viewDetailsProductId, setViewDetailsProductId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(null);
  const [itemPerPage, setItemPerPage] = useState(prevState?.itemPerPage ? prevState?.itemPerPage : 30);
  const [totalItems, setTotalItems] = useState(null);


  const [statusCode, setStatusCode] = useState('')

  const [searchKeyword, setSearchKeyword] = useState(prevState?.searchKeyword ? prevState?.searchKeyword : "");
  const [category, setCategory] = useState(prevState?.category ? prevState?.category : null);
  const [brand, setBrand] = useState(prevState?.brand ? prevState?.brand : null);
  const [status, setStatus] = useState(prevState?.status ? prevState?.status : null);
  const [vendor, setVendor] = useState(prevState?.vendor ? prevState?.vendor : null);
  const [isFilter, setIsFilter] = useState(false);


  const dispatch = useDispatch();
  const productQuantity = useProductQuantity();
  const currentLocation = history?.location?.pathname;

  // const {
  //   getProductList,
  //   getLowstockProductList,
  //   loading,
  //   getProductDetails,
  //   approvedProductStatus,
  //   rejectedProductStatus,
  //   productDetailsLoading,
  // } = useSelector((state) => state.productReducer);

  const { loading,
    lowstockLoading,
    getProductList,
    getLowstockProductList,
    getProductDetails,
    approvedProductStatus,
    rejectedProductStatus,
    productDetailsLoading } = useSelector((state) => state.productReducer);

  const categories = useSelector((state) => state.categoryReducer.categories);
  const brands = useSelector((state) => state.brandReducer.brands);
  const vendorList = useSelector((state) => state.sellerReducer.sellerList);


  const handleItemPerPage = (pagePerItems) => {
    setCurrentPage(1)
    setItemPerPage(pagePerItems)
  }
  const handleCurrentPage = (currentPage) => {
    if (isFilter) {
      setCurrentPage(1)
    } else {
      setCurrentPage(currentPage);
    }
  }

  useEffect(() => {
    if (history.location.state) {
      setCurrentPage(history.location?.state.state.currentPage)
      setItemPerPage(history.location?.state.state.itemPerPage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const product_status = history?.location?.search?.substring(8)
  useEffect(() => {
    if (
      product_status !== 'pending' &&
      product_status !== 'approved' &&
      product_status !== 'rejected' &&
      product_status !== "lowstock"
    ) {
      history.push('/manage-products?status=pending')
    }
  }, [history, product_status])
  useEffect(() => {
    if (product_status === 'pending') {
      setIsApproved('N')
    } else if (product_status === 'approved') {
      setIsApproved('Y')
    } else if (product_status === "lowstock") {
      setIsApproved("Y");
    } else {
      setIsApproved('R')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isApproved === undefined])


  useEffect(() => {
    dispatch({ type: GET_PRODUCTS_LIST_RESET });

    const productInfo = {
      categoryId: category?.categoryId,
      shopId: vendor?.sellerId,
      brandId: brand?.brandId,
      productStatus: status?.code,
      isApprove: isApproved,
      isDeleted: isDeleted,
      currency: currency,
      itemsPerPage: itemPerPage,
      currentPage: currentPage,
    }
    if (searchKeyword) {
      productInfo.keywords = searchKeyword;
      productInfo.currentPage = 1;
    }

    if (product_status !== 'lowstock' && (isFilter || isApproved)) {
      dispatch(getProductListRecord(productInfo))

      if (isFilter) {
        const url = `/manage-seller-products?status=${product_status}`;
        history.push(url, { state: { searchKeyword, brand, category, status, vendor } })

      }
    }

    if (product_status === 'lowstock' && (isFilter || isApproved)) {
      dispatch(getLowstockProductListRecord(productInfo));

      if (isFilter) {
        const url = `/manage-seller-products?status=${product_status}`;
        history.push(url, { state: { searchKeyword, brand, category, status, vendor } })

      }
    }

    return () => setIsFilter(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, product_status, isFilter, isApproved, currentPage, itemPerPage]);

  useEffect(() => {
    if (viewDetailsProductId !== null) {
      dispatch(
        getProductDetailsRecord(viewDetailsProductId, currency, isApproved)
      )
    }
  }, [dispatch, viewDetailsProductId, currency, isApproved])



  useEffect(() => {
    dispatch(getCategoryRecord());
    dispatch(getBrandRecord());
    dispatch(getAllSellerRecord());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const handleActiveProduct = (rowData) => {
    const data = {
      productId: rowData.productId,
      isApprove: 'Y',
      isDelete: '',
      productStatus: 'Y',
      operationType: 'productStatus',
    }
    dispatch(rejectProductRecord(data))
  }
  const handleInActiveProduct = (rowData) => {
    const data = {
      productId: rowData.productId,
      isApprove: 'Y',
      isDelete: '',
      productStatus: 'N',
      operationType: 'productStatus',
    }
    dispatch(rejectProductRecord(data))
  }
  const handleApproveProduct = (id) => {
    const data = {
      productId: id,
      isApprove: 'Y',
      isDelete: '',
      productStatus: 'Y',
      operationType: 'isApprove',
    }
    dispatch(approveProductRecord(data))
  }
  useEffect(() => {
    const productInfo = {
      categoryId: category?.categoryId,
      shopId: vendor?.sellerId,
      brandId: brand?.brandId,
      productStatus: status?.code,
      isApprove: isApproved,
      isDeleted: isDeleted,
      currency: currency,
      itemsPerPage: itemPerPage,
      currentPage: currentPage,
    }
    if (searchKeyword) {
      productInfo.keywords = searchKeyword;
    }

    if (approvedProductStatus.length !== 0) {
      if (approvedProductStatus?.data?.succeed === true) {
        toast.success('Product  Approved.')
        dispatch(getProductListRecord(productInfo));

      } else {
        toast.error('Something Went Wrong.')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approvedProductStatus])
  const handleRejectedProduct = (id) => {
    const data = {
      productId: id,
      isApprove: 'R',
      isDelete: 'N',
      productStatus: 'Y',
      operationType: 'isApprove',
    }
    dispatch(rejectProductRecord(data))
  }
  useEffect(() => {

    const productInfo = {
      categoryId: category?.categoryId,
      shopId: vendor?.sellerId,
      brandId: brand?.brandId,
      productStatus: status?.code,
      isApprove: isApproved,
      isDeleted: isDeleted,
      currency: currency,
      itemsPerPage: itemPerPage,
      currentPage: currentPage,
    }
    if (searchKeyword) {
      productInfo.keywords = searchKeyword;
    }

    if (rejectedProductStatus.length !== 0) {
      if (rejectedProductStatus?.succeed === true) {
        toast.success('Product  Rejected.')
        dispatch(getProductListRecord(productInfo));

      } else {
        toast.error('Something Went Wrong.')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rejectedProductStatus])

  return (
    <>
      <ToastContainer autoClose={2000} />
      <div className='page-wrapper'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-sm-12'>
              <div className='white-box'>
                <div className='datatable-doc-demo datatable-responsive-demo'>
                  <div className='card'>
                    <ul className='nav nav-tabs seller-tabs'>
                      <li>
                        <Link to='/createProductSeller'>Create Product</Link>
                      </li>
                      <li
                        onClick={() => {
                          setIsApproved('N')
                          setCurrentPage(1)
                          setItemPerPage(30)
                        }}
                      >
                        <Link to='/manage-seller-products?status=pending' style={
                          product_status === "pending"
                            ? { color: "#1F5DA0", borderBottom: '2px solid #1F5DA0', fontWeight: "700" }
                            : null
                        }>
                          Pending Product <ShowQuantity currentQuantity={productQuantity[0]?.countStatus} />
                        </Link>
                      </li>
                      <li
                        onClick={() => {
                          setIsApproved('Y')
                          setCurrentPage(1)
                          setItemPerPage(30)
                        }}
                      >
                        <Link to='/manage-seller-products?status=approved' style={
                          product_status === "approved"
                            ? { color: "#1F5DA0", borderBottom: '2px solid #1F5DA0', fontWeight: "700" }
                            : null
                        }>
                          Approved Product <ShowQuantity currentQuantity={productQuantity[1]?.countStatus} />
                        </Link>
                      </li>
                      <li
                        onClick={() => {
                          setIsApproved('R')
                          setCurrentPage(1)
                          setItemPerPage(30)
                        }}
                      >
                        <Link to='/manage-seller-products?status=rejected' style={
                          product_status === "rejected"
                            ? { color: "#1F5DA0", borderBottom: '2px solid #1F5DA0', fontWeight: "700" }
                            : null
                        }>
                          Rejected Product <ShowQuantity currentQuantity={productQuantity[2]?.countStatus} />
                        </Link>
                      </li>
                      <li
                        onClick={() => {
                          setIsApproved("Y");
                          setCurrentPage(1);
                          setItemPerPage(30);
                        }}
                      >
                        <Link to="/manage-seller-products?status=lowstock" style={
                          product_status === "lowstock"
                            ? { color: "#1F5DA0", borderBottom: '2px solid #1F5DA0', fontWeight: "700" }
                            : null
                        }>
                          <li style={{
                            display: 'flex',
                            alignItems: 'center'
                          }}>
                            <span style={{ paddingRight: '5px' }}>
                              Low Stock Product <ShowQuantity currentQuantity={productQuantity[3]?.countStatus} />
                            </span>

                          </li>

                        </Link>
                      </li>

                    </ul>
                    <ProductListTable
                      currentLocation={currentLocation}
                      product_status={product_status}
                      // product_list={getProductList}
                      product_list={product_status === 'lowstock' ? getLowstockProductList : getProductList}
                      setViewDetailsProductId={setViewDetailsProductId}
                      handleItemPerPage={handleItemPerPage}
                      handleCurrentPage={handleCurrentPage}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      totalPage={totalPage}
                      setTotalPage={setTotalPage}
                      itemPerPage={itemPerPage}
                      setItemPerPage={setItemPerPage}
                      totalItems={totalItems}
                      setTotalItems={setTotalItems}
                      handleActiveProduct={handleActiveProduct}
                      handleInActiveProduct={handleInActiveProduct}
                      currency={currency}
                      isApproved={isApproved}
                      setSearchKeyword={setSearchKeyword}
                      searchKeyword={searchKeyword}
                      // searchProductList={searchProductList}
                      categories={categories}
                      setCategory={setCategory}
                      category={category}
                      brands={brands}
                      setBrand={setBrand}
                      brand={brand}
                      setStatus={setStatus}
                      status={status}
                      // setBrandId={setBrandId}
                      // brandId={brandId}
                      setStatusCode={setStatusCode}
                      vendorList={vendorList}
                      setVendor={setVendor}
                      vendor={vendor}
                      setIsFilter={setIsFilter}
                      // loading={loading}
                      loading={lowstockLoading || loading}
                    // setSearchKeywordApi={setSearchKeywordApi}
                    // setCategoryApi={setCategoryApi}
                    // setBrandIdApi={setBrandIdApi}
                    // setStatusCodeApi={setStatusCodeApi}

                    />
                  </div>
                  <ProductDetailsModal
                    products={getProductDetails?.data}
                    loading={productDetailsLoading}
                    product_status={product_status}
                    handleApproveProduct={handleApproveProduct}
                    handleRejectedProduct={handleRejectedProduct}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
