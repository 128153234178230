import {
  GET_ACCOUNTS_DASHBOARD_DATA,
  GET_ACCOUNTS_DASHBOARD_DATA_ERROR,
  GET_ACCOUNTS_DASHBOARD_DATA_SUCCESS,
  GET_DATE_WISE_SALES_SUMMARY_NBR_DATA,
  GET_DATE_WISE_SALES_SUMMARY_NBR_DATA_ERROR,
  GET_DATE_WISE_SALES_SUMMARY_NBR_DATA_SUCCESS,
  GET_DATE_WISE_SALES_SUMMARY_DATA,
  GET_DATE_WISE_SALES_SUMMARY_DATA_ERROR,
  GET_DATE_WISE_SALES_SUMMARY_DATA_SUCCESS,
  GET_EXCEL_ACCOUNTS_TRANSACTION,
  GET_EXCEL_ACCOUNTS_TRANSACTION_ERROR,
  GET_EXCEL_ACCOUNTS_TRANSACTION_SUCCESS,
  GET_PAYMENT_TYPE_WISE_SALES_DETAILS_DATA,
  GET_PAYMENT_TYPE_WISE_SALES_DETAILS_DATA_ERROR,
  GET_PAYMENT_TYPE_WISE_SALES_DETAILS_DATA_SUCCESS,
  GET_PAYMENT_TYPE_WISE_SALES_SUMMARY_DATA,
  GET_PAYMENT_TYPE_WISE_SALES_SUMMARY_DATA_ERROR,
  GET_PAYMENT_TYPE_WISE_SALES_SUMMARY_DATA_SUCCESS,
  GET_DATE_WISE_PAYMENT_REALIZATION_DATA,
  GET_DATE_WISE_PAYMENT_REALIZATION_DATA_SUCCESS,
  GET_DATE_WISE_PAYMENT_REALIZATION_DATA_ERROR,
} from "../actions/accountsAction";

const initialState = {
  accountsTransactionReportData: [],
  accountsDashboardData: [],
  dateWiseSalesSummaryNBRData: [],
  dateWiseSalesSummaryData: [],
  paymentTypeWiseSalesSummaryData: [],
  paymentTypeWiseSalesDetailsData: [],
  dateWisePaymentRealizationData: [],
  data: {}, 
  loading: false,
  loaded: false,
  error: null,
  dateWiseSalesSummaryNBRDataLoading: false,
  dateWiseSalesSummaryNBRDataLoaded: false,
  dateWiseSalesSummaryNBRDataError: null,
  dateWiseSalesSummaryDataLoading: false,
  dateWiseSalesSummaryDataLoaded: false,
  dateWiseSalesSummaryDataError: null,
  paymentTypeWiseSalesSummaryDataLoading: false,
  paymentTypeWiseSalesSummaryDataLoaded: false,
  paymentTypeWiseSalesSummaryDataError: null,
  paymentTypeWiseSalesDetailsDataLoading: false,
  paymentTypeWiseSalesDetailsDataLoaded: false,
  paymentTypeWiseSalesDetailsDataError: null,
  dateWisePaymentRealizationDataLoading: false,
  dateWisePaymentRealizationDataLoaded: false,
  dateWisePaymentRealizationDataError: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_EXCEL_ACCOUNTS_TRANSACTION:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };

    case GET_EXCEL_ACCOUNTS_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        accountsTransactionReportData: action.payload.success,
      };

    case GET_EXCEL_ACCOUNTS_TRANSACTION_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload.error,
        accountsTransactionReportData: [],
      };
    case GET_ACCOUNTS_DASHBOARD_DATA:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };

    case GET_ACCOUNTS_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        accountsDashboardData: action.payload.success,
      };

    case GET_ACCOUNTS_DASHBOARD_DATA_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload.error,
        accountsDashboardData: [],
      };
   
      case GET_DATE_WISE_SALES_SUMMARY_NBR_DATA:
      return {
        ...state,
        dateWiseSalesSummaryNBRData: true,
        dateWiseSalesSummaryNBRDataLoaded: false,
        dateWiseSalesSummaryNBRDataError: null,
      };

    case GET_DATE_WISE_SALES_SUMMARY_NBR_DATA_SUCCESS:
      return {
        ...state,
        dateWiseSalesSummaryNBRDataLoading: false,
        dateWiseSalesSummaryNBRDataLoaded: true,
        dateWiseSalesSummaryNBRData: action.payload.success,
      };

    case GET_DATE_WISE_SALES_SUMMARY_NBR_DATA_ERROR:
      return {
        ...state,
        dateWiseSalesSummaryNBRDataLoading: false,
        dateWiseSalesSummaryNBRDataLoaded: false,
        dateWiseSalesSummaryNBRDataError: action.payload.error,
        dateWiseSalesSummaryNBRData: [],
      };
   
   
      case GET_DATE_WISE_SALES_SUMMARY_DATA:
      return {
        ...state,
        dateWiseSalesSummaryDataLoading: true,
        dateWiseSalesSummaryDataLoaded: false,
        dateWiseSalesSummaryDataError: null,
      };

    case GET_DATE_WISE_SALES_SUMMARY_DATA_SUCCESS:
      return {
        ...state,
        dateWiseSalesSummaryDataLoading: false,
        dateWiseSalesSummaryDataLoaded: true,
        dateWiseSalesSummaryData: action.payload.success,
      };

    case GET_DATE_WISE_SALES_SUMMARY_DATA_ERROR:
      return {
        ...state,
        dateWiseSalesSummaryDataLoading: false,
        dateWiseSalesSummaryDataLoaded: false,
        dateWiseSalesSummaryDataError: action.payload.error,
        dateWiseSalesSummaryData: [],
      };
   
    case GET_PAYMENT_TYPE_WISE_SALES_SUMMARY_DATA:
      return {
        ...state,
        paymentTypeWiseSalesSummaryDataLoading: true,
        paymentTypeWiseSalesSummaryDataLoaded: false,
        paymentTypeWiseSalesSummaryDataError: null,
      };

    case GET_PAYMENT_TYPE_WISE_SALES_SUMMARY_DATA_SUCCESS:
      return {
        ...state,
        paymentTypeWiseSalesSummaryDataLoading: false,
        paymentTypeWiseSalesSummaryDataLoaded: true,
        paymentTypeWiseSalesSummaryData: action.payload.success,
      };

    case GET_PAYMENT_TYPE_WISE_SALES_SUMMARY_DATA_ERROR:
      return {
        ...state,
        paymentTypeWiseSalesSummaryDataLoading: false,
        paymentTypeWiseSalesSummaryDataLoaded: false,
        paymentTypeWiseSalesSummaryDataError: action.payload.error,
        paymentTypeWiseSalesSummaryData: [],
      };
    case GET_PAYMENT_TYPE_WISE_SALES_DETAILS_DATA:
      return {
        ...state,
        paymentTypeWiseSalesDetailsDataLoading: true,
        paymentTypeWiseSalesDetailsDataLoaded: false,
        paymentTypeWiseSalesDetailsDataError: null,
      };

    case GET_PAYMENT_TYPE_WISE_SALES_DETAILS_DATA_SUCCESS:
      return {
        ...state,
        paymentTypeWiseSalesDetailsDataLoading: false,
        paymentTypeWiseSalesDetailsDataLoaded: true,
        paymentTypeWiseSalesDetailsData: action.payload.success,
      };

    case GET_PAYMENT_TYPE_WISE_SALES_DETAILS_DATA_ERROR:
      return {
        ...state,
        paymentTypeWiseSalesDetailsDataLoading: false,
        paymentTypeWiseSalesDetailsDataLoaded: false,
        paymentTypeWiseSalesDetailsDataError: action.payload.error,
        paymentTypeWiseSalesDetailsData: [],
      };

      case GET_DATE_WISE_PAYMENT_REALIZATION_DATA:
        return {
          ...state,
          dateWisePaymentRealizationDataLoading: true,
          dateWisePaymentRealizationDataLoaded: false,
          dateWisePaymentRealizationDataError: null,
        };
  
      case GET_DATE_WISE_PAYMENT_REALIZATION_DATA_SUCCESS:
        return {
          ...state,
          dateWisePaymentRealizationDataLoading: false,
          dateWisePaymentRealizationDataLoaded: true,
          dateWisePaymentRealizationData: action.payload.success,
        };
  
      case GET_DATE_WISE_PAYMENT_REALIZATION_DATA_ERROR:
        return {
          ...state,
          dateWisePaymentRealizationDataLoading: false,
          dateWisePaymentRealizationDataLoaded: false,
          dateWisePaymentRealizationDataError: action.payload.error,
          dateWisePaymentRealizationData: [],
        };
    default:
      return state;
  }
}
