import storeService from "../services/storeService";

export const CREATE_OR_UPDATE_STORE_BANNER = "CREATE_OR_UPDATE_STORE_BANNER";
export const CREATE_OR_UPDATE_STORE_BANNER_SUCCESS = "CREATE_OR_UPDATE_STORE_BANNER_SUCCESS";
export const CREATE_OR_UPDATE_STORE_BANNER_ERROR = "CREATE_OR_UPDATE_STORE_BANNER_ERROR";
export const CREATE_OR_UPDATE_STORE_BANNER_RESET = "CREATE_OR_UPDATE_STORE_BANNER_RESET";

export const GET_STORE_BANNER = "GET_STORE_BANNER";
export const GET_STORE_BANNER_SUCCESS = "GET_STORE_BANNER_SUCCESS";
export const GET_STORE_BANNER_ERROR = "GET_STORE_BANNER_ERROR";

export const GET_STORE_BANNER_BY_SHOP_ID = "GET_STORE_BANNER_BY_SHOP_ID";
export const GET_STORE_BANNER_BY_SHOP_ID_SUCCESS = "GET_STORE_BANNER_BY_SHOP_ID_SUCCESS";
export const GET_STORE_BANNER_BY_SHOP_ID_ERROR = "GET_STORE_BANNER_BY_SHOP_ID_ERROR";

// Create Store Banner Start
export function createOrUpdateStoreBannerRecord(data) {
    return async (dispatch) => {
      dispatch(createOrUpdateStoreBanner());
      return await storeService
        .createOrUpdateStoreBanner(data)
  
        .then((response) => dispatch(createOrUpdateStoreBannerSuccess(response)))
        .catch((error) => dispatch(createOrUpdateStoreBannerError(error)));
    };
  }
  
  export function createOrUpdateStoreBanner() {
    return {
      type: CREATE_OR_UPDATE_STORE_BANNER,
    };
  }
  
  export function createOrUpdateStoreBannerSuccess(success) {
    return {
      type: CREATE_OR_UPDATE_STORE_BANNER_SUCCESS,
      payload: { success },
    };
  }
  
  export function createOrUpdateStoreBannerError(error) {
    return {
      type: CREATE_OR_UPDATE_STORE_BANNER_ERROR,
      payload: { error },
    };
  }

  export function createOrUpdateStoreBannerReset() {
    return {
      type: CREATE_OR_UPDATE_STORE_BANNER_RESET,
    };
  }
  // Create Store Banner End

  // Get Store Banner start
export function getStoreBannerRecord() {
  return async (dispatch) => {
    dispatch(getStoreBanner());
    return await storeService
      .getStoreBanner()

      .then((response) => dispatch(getStoreBannerSuccess(response)))
      .catch((error) => dispatch(getStoreBannerError(error)));
  };
}

export function getStoreBanner() {
  return {
    type: GET_STORE_BANNER,
  };
}

export function getStoreBannerSuccess(success) {
  return {
    type: GET_STORE_BANNER_SUCCESS,
    payload: { success },
  };
}

export function getStoreBannerError(error) {
  return {
    type: GET_STORE_BANNER_ERROR,
    payload: { error },
  };
}

// Get Store Banner end

  // Get Store Banner By ShopId start
  export function getStoreBannerByShopIdRecord(shopId) {
    return async (dispatch) => {
      dispatch(getStoreBannerByShopId());
      return await storeService
        .getStoreBannerByShopId(shopId)
  
        .then((response) => dispatch(getStoreBannerByShopIdSuccess(response)))
        .catch((error) => dispatch(getStoreBannerByShopIdError(error)));
    };
  }
  
  export function getStoreBannerByShopId() {
    return {
      type: GET_STORE_BANNER_BY_SHOP_ID,
    };
  }
  
  export function getStoreBannerByShopIdSuccess(success) {
    return {
      type: GET_STORE_BANNER_BY_SHOP_ID_SUCCESS,
      payload: { success },
    };
  }
  
  export function getStoreBannerByShopIdError(error) {
    return {
      type: GET_STORE_BANNER_BY_SHOP_ID_ERROR,
      payload: { error },
    };
  }
  
  // Get Store Banner By ShopId end