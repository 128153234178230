import http from "../../utils/httpClient"

class sellerProfileService {
  //
  createSellerProfile = async (data) => {
    //////debugger;
    return await http
      .post("/api/Seller", data)
      .then((response) => {
        return response.data
      })
      .catch((err) => Promise.reject("Failed!", err))
  }

  createOrUpdateSellerProfile = async (data) => {
    return await http
      .post("/api/Seller/createOrUpdateSeller", data)
      .then((response) => {
        return response.data
      })
      .catch((err) => Promise.reject("Failed!", err))
  }

  //
  createSellerAddress = async (data) => {
    return await http
      .post("/api/Seller/CreateOrUpdateAddress", data)
      .then((response) => {
        return response.data
      })
      .catch((err) => Promise.reject("Failed!", err))
  }

  createSellerBankAccount = async (data) => {
    return await http
      .post("/api/Seller/CreateOrUpdateBankAccount", data)
      .then((response) => {
        return response.data
      })
      .catch((err) => Promise.reject("Failed!", err))
  }

  //
  sellerUpdateShop = async (data) => {
    return await http
      .post("/api/Seller/UpdateShop", data)
      .then((response) => {
        return response.data
      })
      .catch((err) => Promise.reject("Failed!", err))
  }

  //
  updateSellerPassword = async (data) => {
    return await http
      .post("/api/Seller/ChangeSellerPassword", data)
      .then((response) => {
        return response.data
      })
      .catch((err) => Promise.reject("Failed!", err))
  }

  //
  authenticateUser = async () => {
    return (
      (await localStorage.getItem("x-access-token")) &&
      localStorage.getItem("x-access-token-expiration") > Date.now()
    )
  }

  //
  deauthenticateUser = async () => {
    ; (await localStorage.removeItem("x-access-token")) &&
      localStorage.getItem("x-access-token-expiration")
    window.location = window.location.origin + "/"
  }

  //
  saveToken = async (token) => {
    ; (await localStorage.setItem("x-access-token", token)) &&
      localStorage.getItem("x-access-token-expiration")
  }

  //
  getToken = (async) => {
    return localStorage.getItem("x-access-token")
  }

  //
  retriveToken = (async) => {
    return this.getToken()
  }

  //
  retriveEmployeeId = (async) => {
    return this.getEmployeeId()
  }

  retriveRoleId = (async) => {
    return this.getRoleId()
  }

  getEmployeeId = (async) => {
    return localStorage.getItem("x-access-employeeId")
  }

  getRoleId = (async) => {
    return localStorage.getItem("x-access-roleId")
  }

  getSellerProfileById = async (userId) => {
    //////debugger;
    return await http.get(`api/Seller/GetSellerById?sellerId=${userId}`)
  }

  //
  getBankAccountByShopId = async (shopId) => {
    return await http.get(
      `api/Seller/GetAllSellerBankAccount?shopId=${shopId}`
    )
  }

  getCommissionSellerByShopId = async (shopId) => {
    return await http.get(
      `/api/Seller/GetAllSellerCommission?shopId=${shopId}`
    )
  }

  //
  getAddressByShopId = async (shopId, addressTypeId) => {
    return await http.get(
      `/api/Seller/GetAllSellerAddress?shopId=${shopId}&addressTypeId=${addressTypeId}`
    )
  }

  //
  getShopDetailsBySellerId = async (userId) => {
    return await http.get(`api/Seller/GetShopDetails?sellerId=${userId}`)
  }

  //
  checkShopUrlAvailable = async (data) => {
    //////debugger;
    return await http
      .post("api/Seller/ShopUrlAvailable", {
        shopUrl: data.shopUrl,
      })
      .then((response) => {
        return response.data
      })
      .catch((err) => Promise.reject("Failed!", err))
  }

  //Seller Commission Percentage
  createCommissionPercentage = async (data) => {
    return await http.post("/api/Seller/AddOrEditCommissionPercentage", data)
  }

  updateCommissionPercentage = async (data) => {
    return await http.post("/api/Seller/AddOrEditCommissionPercentage", data)
  }

  getCommissionPercentage = async (shopId) => {
    return await http.get(`/api/Seller/GetAllSellerCommission?shopId=${shopId}&getAll=Y`)
  }

  getAllSellerCommission = async () => {
    return await http.get(`/api/Seller/GetAllSellerCommission`)
  }

}
export default new sellerProfileService()
