/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Resizer from "react-image-file-resizer";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import CreateCampaign from '../../components/promotion/CreateCampaign';
import { createOrUpdateCampaignRecord, createOrUpdateCampaignReset } from '../../store/actions/campaignAction';
import authenticationService from "../../store/services/authenticationService";

const CreateCampaignContainer = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const [campaignName, setCampaignName] = useState('')
    const [errorCampaignName, setErrorCampaignName] = useState('')
    const [displayOrder, setDisplayOrder] = useState('')
    const [errorDisplayOrder, setErrorDisplayOrder] = useState('')

    const [campaignBannerUrl, setCampaignBannerUrl] = useState('')
    const [showImage, setShowImage] = useState('')

    const [campaignRegStartDate, setCampaignRegStartDate] = useState('')
    const [campaignRegEndDate, setCampaignRegEndDate] = useState('')
    const [campaignStartDate, setCampaignStartDate] = useState('')
    const [campaignEndDate, setCampaignEndDate] = useState('')

    const [campaignMinDisPer, setCampaignMinDisPer] = useState(0)
    const [errorCampaignMinDisPer, setErrorCampaignMinDisPer] = useState('')
    const [campaignMaxDisPer, setCampaignMaxDisPer] = useState(0)
    const [errorCampaignMaxDisPer, setErrorCampaignMaxDisPer] = useState('')

    const [campaignMinDisAmt, setCampaignMinDisAmt] = useState(0)
    const [errorCampaignMinDisAmt, setErrorCampaignMinDisAmt] = useState('')
    const [campaignMaxDisAmt, setCampaignMaxDisAmt] = useState(0)
    const [errorCampaignMaxDisAmt, setErrorCampaignMaxDisAmt] = useState('')

    const [campaignDescription, setCampaignDescription] = useState('')
    const [isActive, setIsActive] = useState(true)

    useEffect(() => {
        let roleId = authenticationService.getRoleId();
        if (roleId !== "1") {
            history.push("/Login");
        }
        dispatch(createOrUpdateCampaignReset())
    }, [history]);

    const { campaignData, campaignLoading } = useSelector((state) => state.campaignReducer);

    useEffect(() => {
        if (campaignData.data) {

            if (campaignData && campaignData?.data?.succeed === true) {
                toast.success('Campaign Created Successfully')
                dispatch(createOrUpdateCampaignReset())
                setTimeout(() => {
                    history.push('/CampaignList?status=active')
                }, 2500)
                resetForm()
            } else if (campaignData && campaignData?.data?.message === "Save Success") {
                toast.success('Campaign Created Successfully')
                dispatch(createOrUpdateCampaignReset())
                setTimeout(() => {
                    history.push('/CampaignList?status=active')
                }, 2500)
                resetForm()
            } else if (campaignData && campaignData?.data?.succeed === false) {
                toast.error('Something went wrong, Please try again')
                dispatch(createOrUpdateCampaignReset())
                setTimeout(() => {
                    resetForm()
                }, 2500)
            }
        }
    }, [campaignData.data])

    const handleChange = (e) => {
        const { target } = e
        const { value } = e.target

        switch (target.name) {
            case 'campaignName':
                setCampaignName(target.value)
                setErrorCampaignName(value.length < 2 ? 'At least 2 characters required' : '')
                break
            case 'displayOrder':
                setDisplayOrder(target.value)
                setErrorDisplayOrder(value.length < 1 ? 'Display Order required' : '')
                if (target.value == 0) {
                    setCampaignBannerUrl('')
                    setShowImage('')
                }
                break
            case 'campaignRegStartDate':
                setCampaignRegStartDate(target.value)
                break
            case 'campaignRegEndDate':
                setCampaignRegEndDate(target.value)
                break
            case 'campaignStartDate':
                setCampaignStartDate(target.value)
                break
            case 'campaignEndDate':
                setCampaignEndDate(target.value)
                break
            case 'campaignMinDisPer':
                setCampaignMinDisPer(target.value)
                setErrorCampaignMinDisPer(value.length < 1 ? 'Minimum Discount Percent required' : '')
                break
            case 'campaignMaxDisPer':
                setCampaignMaxDisPer(target.value)
                setErrorCampaignMaxDisPer(value.length < 1 ? 'Maximum Discount Percent required' : '')
                break
            case 'campaignMinDisAmt':
                setCampaignMinDisAmt(target.value)
                setErrorCampaignMinDisAmt(value.length < 1 ? 'Minimum Discount Amount required' : '')
                break
            case 'campaignMaxDisAmt':
                setCampaignMaxDisAmt(target.value)
                setErrorCampaignMaxDisAmt(value.length < 1 ? 'Maximum Discount Amount required' : '')
                break
            case 'campaignDescription':
                setCampaignDescription(target.value)
                break
            default:
        }
    }

    const handleParentCheck = (e) => {
        setIsActive(e.target.checked)
    }

    const campaignBannerUrlHandler = (event) => {

        var width = 180;
        var height = 180;

        if (displayOrder % 2 !== 0) {
            width = 1611;
            height = 300;
        } else {
            width = 596;
            height = 596;
        }

        const imageFile = event.target.files[0];
        //1)    CHECK IF IT'S A IMAGE
        var fileInput = false;
        if (imageFile) {
            if (!imageFile.name.match(/\.(jpg|jpeg|png|gif|webp)$/)) {
                toast.error("Select a valid image.");
                return false;
            }
            fileInput = true;
            if (fileInput) {
                try {
                    Resizer.imageFileResizer(
                        imageFile,
                        width,
                        height,
                        "JPEG",
                        100,
                        0,
                        (uri) => {
                            setCampaignBannerUrl(uri)
                            setShowImage(URL.createObjectURL(imageFile))
                            toast.success("Campaign Banner Selected.");
                        },
                        "base64",
                        width,
                        height
                    );
                } catch (err) {
                    toast.error("Something went wrong!");
                }
            }
        }
    };

    const resetForm = () => {
        setCampaignName('')
        setDisplayOrder('')
        setCampaignRegStartDate('')
        setCampaignRegEndDate('')
        setCampaignStartDate('')
        setCampaignEndDate('')

        setCampaignMinDisPer(0)
        setCampaignMaxDisPer(0)
        setCampaignMinDisAmt(0)
        setCampaignMaxDisAmt(0)

        setCampaignDescription('')
        setIsActive(true)
        setCampaignBannerUrl('')
        setErrorCampaignName('')
    }

    const saveCampaign = async (e) => {
        e.preventDefault()

        if (campaignName === '') {
            let msg = 'Campaign Name is Required!!!'
            toast.error(msg)
            setTimeout(() => { }, 3000)
            return
        }
        if (displayOrder === '') {
            let msg = 'Display Order is Required!!!'
            toast.error(msg)
            setTimeout(() => { }, 3000)
            return
        }
        if (campaignRegStartDate === '') {
            let msg = 'Registration Start Date is Required!!!'
            toast.error(msg)
            setTimeout(() => { }, 3000)
            return
        }
        if (campaignRegEndDate === '') {
            let msg = 'Registration End Date is Required!!!'
            toast.error(msg)
            setTimeout(() => { }, 3000)
            return
        }
        if (campaignStartDate === '') {
            let msg = 'Campaign Start Date is Required!!!'
            toast.error(msg)
            setTimeout(() => { }, 3000)
            return
        }
        if (campaignEndDate === '') {
            let msg = 'Campaign End Date is Required!!!'
            toast.error(msg)
            setTimeout(() => { }, 3000)
            return
        }
        if (campaignMinDisPer === '') {
            let msg = 'Minimum Discount Percent is Required!!!'
            toast.error(msg)
            setTimeout(() => { }, 3000)
            return
        }
        if (campaignMaxDisPer === '') {
            let msg = 'Maximum Discount Percent is Required!!!'
            toast.error(msg)
            setTimeout(() => { }, 3000)
            return
        }
        if (campaignMinDisAmt === '') {
            let msg = 'Minimum Discount Amount is Required!!!'
            toast.error(msg)
            setTimeout(() => { }, 3000)
            return
        }
        if (campaignMaxDisAmt === '') {
            let msg = 'Maximum Discount Amount is Required!!!'
            toast.error(msg)
            setTimeout(() => { }, 3000)
            return
        }
        if (displayOrder != 0 && campaignBannerUrl === '') {
            let msg = 'Campaign Banner is Required!!!'
            toast.error(msg)
            setTimeout(() => { }, 3000)
            return
        }
        
        const data = {
            campaignName: campaignName,
            campaignUrl: campaignName.toLowerCase().replaceAll(' ', '-'),
            campaignBannerUrl: campaignBannerUrl,
            campaignRegStartDate: campaignRegStartDate,
            campaignRegEndDate: campaignRegEndDate,
            campaignStartDate: campaignStartDate,
            campaignEndDate: campaignEndDate,
            campaignDescription: campaignDescription,
            campaignMinDisPer: Number(campaignMinDisPer),
            campaignMaxDisPer: Number(campaignMaxDisPer),
            campaignMinDisAmt: Number(campaignMinDisAmt),
            campaignMaxDisAmt: Number(campaignMaxDisAmt),
            isActive: isActive === true ? 'Y' : 'N',
            displayOrder: displayOrder,
        }

        dispatch(createOrUpdateCampaignRecord(data))
    }

    return (
        <div id='wrapper'>
            <CreateCampaign
                key='CreateCampaign'
                name='Add Campaign'
                campaignName={campaignName}
                errorCampaignName={errorCampaignName}
                displayOrder={displayOrder}
                errorDisplayOrder={errorDisplayOrder}
                campaignRegStartDate={campaignRegStartDate}
                campaignRegEndDate={campaignRegEndDate}
                campaignStartDate={campaignStartDate}
                campaignEndDate={campaignEndDate}
                campaignMinDisPer={campaignMinDisPer}
                errorCampaignMinDisPer={errorCampaignMinDisPer}
                campaignMaxDisPer={campaignMaxDisPer}
                errorCampaignMaxDisPer={errorCampaignMaxDisPer}
                campaignMinDisAmt={campaignMinDisAmt}
                errorCampaignMinDisAmt={errorCampaignMinDisAmt}
                campaignMaxDisAmt={campaignMaxDisAmt}
                errorCampaignMaxDisAmt={errorCampaignMaxDisAmt}
                campaignDescription={campaignDescription}
                showImage={showImage}
                isActive={isActive}

                handleChange={handleChange}
                handleParentCheck={handleParentCheck}
                campaignBannerUrlHandler={campaignBannerUrlHandler}
                saveCampaign={saveCampaign}
                campaignLoading={campaignLoading}
            />
            <ToastContainer autoClose={1500} />
        </div>
    )
}

export default CreateCampaignContainer
