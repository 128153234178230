import { Modal } from "antd";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";

import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";

import { useDispatch, useSelector } from "react-redux";
import useViewPort from "../../hooks/useViewPort";
import { getPaymentMethodRecord } from "../../store/actions/paymentMethodAction";
import { getAllSellerRecord } from "../../store/actions/sellerAction";

const vendorData = [{ vendorName: "Loto" }, { vendorName: "Bata" }];

const VendorPayment = () => {
  const { viewportWidth } = useViewPort();

  const [openModal, setOpenModal] = useState(false);
  const [invoiceNo, setInvoiceNo] = useState("");
  // const [tranNo, setTranNo] = useState("");
  // const [shopWiseOrderNo, setShopWiseOrderNo] = useState("");
  const [paymentDate, setPaymentDate] = useState(null);
  const [selectPaymentMethod, setSelectPaymentMethod] = useState(null);

  const [selectVendor, setSelectVendor] = useState(null);
  const dispatch = useDispatch();

  const vendorList = useSelector((state) => state.sellerReducer.sellerList);
  const paymentMethods = useSelector(
    (state) => state.paymentMethodReducer.paymentMethods
  );

  const onVendorChange = (e) => {
    setSelectVendor(e.value);
  };

  const onPaymentMethodChange = (e) => {
    setSelectPaymentMethod(e.value);
  };

  const refundDetailsBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span>
          <Button
            onClick={() => {
              // setOrderInfo(rowData);
              setOpenModal(true);
            }}
            style={{
              fontSize: "14px",
              padding: "4px 10px",
              marginRight: "5px",
            }}
          >
            Details
          </Button>
        </span>
      </React.Fragment>
    );
  };

  useEffect(() => {
    dispatch(getPaymentMethodRecord());
    dispatch(getAllSellerRecord());
  }, [dispatch]);

  return (
    <>
      <ToastContainer autoClose={2500} />
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="col-md-12">
            <div className="white-box white-box-title">
              <div className="datatable-row">
                <div className="card">
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      padding: "10px 20px",
                    }}
                    className="order_filter__section "
                  >
                    <div
                      style={{
                        margin: "0 2px",
                        width: viewportWidth < 992 ? "190px" : "250px",
                        position: "relative",
                      }}
                    >
                      <InputText
                        style={{
                          width: "100%",
                        }}
                        className="manage_product__search"
                        value={invoiceNo}
                        // onChange={(e) => setSearchKeyword(e.target.value)}
                        onChange={(e) => setInvoiceNo(e.target.value)}
                        placeholder="Order Invoice No"
                      />
                      {invoiceNo !== "" ? (
                        <button
                          style={{
                            position: "absolute",
                            top: "6px",
                            right: "8px",
                            borderRadius: "100%",
                            border: "1px solid #ced4da",
                            height: "25px",
                            width: "25px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onClick={() => {
                            // setSearchKeyword("");
                            setInvoiceNo("");
                            // setSearchKeywordApi("");
                            // setCurrentPage(1);
                          }}
                        >
                          <i className="pi pi-times"></i>
                        </button>
                      ) : null}
                    </div>

                    <div
                      style={{
                        margin: "0 2px",
                        width: "155px",
                        position: "relative",
                      }}
                    >
                      <Calendar
                        id="icon"
                        // maxDate={endDate}
                        value={paymentDate}
                        onChange={(e) => setPaymentDate(e.value)}
                        showIcon
                        placeholder="Cancel Date"
                        readOnlyInput
                      />
                      {paymentDate !== null ? (
                        <button
                          style={{
                            position: "absolute",
                            top: "6px",
                            right: "33px",
                            borderRadius: "100%",
                            border: "1px solid #ced4da",
                            height: "25px",
                            width: "25px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onClick={() => {
                            setPaymentDate(null);
                            // setStartDateApi(null);
                            // setCurrentPage(1);
                          }}
                        >
                          <i className="pi pi-times"></i>
                        </button>
                      ) : null}
                    </div>

                    <div
                      style={{
                        margin: "0 2px",
                        width: "155px",
                        position: "relative",
                      }}
                    >
                      <Dropdown
                        style={{
                          width: "100%",
                        }}
                        value={selectVendor}
                        options={vendorList?.data}
                        onChange={onVendorChange}
                        optionLabel="shopName"
                        placeholder="Select a Seller"
                        filter
                        showClear
                      />
                      {selectVendor !== null ? (
                        <button
                          style={{
                            position: "absolute",
                            top: "6px",
                            right: "8px",
                            borderRadius: "100%",
                            border: "1px solid #ced4da",
                            height: "25px",
                            width: "25px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onClick={() => {
                            setSelectVendor(null);
                            // setSelectVendorApi(null);
                            // setCurrentPage(1);
                          }}
                        >
                          <i className="pi pi-times"></i>
                        </button>
                      ) : null}
                    </div>

                    <div
                      style={{
                        margin: "0 2px",
                        width: "160px",
                        position: "relative",
                      }}
                    >
                      <Dropdown
                        style={{
                          width: "100%",
                        }}
                        value={selectPaymentMethod}
                        options={paymentMethods}
                        onChange={onPaymentMethodChange}
                        optionLabel="methodName"
                        placeholder="Payment Method"
                        filter
                        showClear
                      />
                    </div>
                    <div style={{ position: "relative" }}>
                      <Button
                        // onClick={() =>
                        // filterOrders(
                        //   tranNo,
                        //   invoiceNo,
                        //   shopWiseOrderNo,
                        //   customerName,
                        //   selectVendor,
                        //   selectPaymentMethod,
                        //   PaymentDate
                        // )
                        // }
                        style={{ marginLeft: "3px" }}
                        label="Search"
                        // disabled={startDate?.getTime() > endDate?.getTime()}
                      />
                    </div>
                  </div>

                  <DataTable
                    className="p-datatable-customers p-datatable-responsive-demo"
                    value={vendorData}
                    rowHover
                    emptyMessage="No order(s) found"
                    // selection={vendorData}
                    // onSelectionChange={(e) => setSelectedOrders(e.value)}
                  >
                    <Column
                      field="vendorName"
                      filterField="vendorName"
                      header="Vendor Name"
                      sortable
                    />

                    <Column
                      field="refundTranNo"
                      filterField="refundTranNo"
                      header="Transaction No"
                      sortable
                    />
                    <Column
                      field="refundDate"
                      filterField="refundDate"
                      header="Transaction Date"
                      // body={refundTateBodyTemplate}
                      sortable
                    />
                    <Column
                      field="orderInvoiceNo"
                      filterField="orderInvoiceNo"
                      header="Order No"
                      sortable
                    />
                    <Column
                      field="customerName"
                      filterField="customerName"
                      header="Customer"
                      sortable
                    />
                    <Column
                      field="methodName"
                      filterField="methodName"
                      header="Payment Method"
                      sortable
                    />
                    <Column
                      field="accountNo"
                      filterField="accountNo"
                      header="Account No."
                      sortable
                    />
                    <Column
                      header="Amount"
                      // body={refundTotalBodyTateBodyTemplate}
                    />
                    <Column header="Action" body={refundDetailsBodyTemplate} />
                  </DataTable>
                  {/* <Paginator
                        totalPage={totalPage}
                        currentPage={currentPage}
                        itemPerPage={itemPerPage}
                        totalItems={totalItems}
                        items={refunded_data?.data}
                        itemsPerPageOptions={[30, 60, 90, 100, 200, 300]}
                        handleItemPerPage={handleItemPerPage}
                        handleCurrentPage={handleCurrentPage}
                      /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Payment Modal"
        centered
        visible={openModal}
        onOk={() => setOpenModal(false)}
        onCancel={() => setOpenModal(false)}
        width={1500}
      >
        {/* <RefundedOrdersModal
          orderInfo={orderInfo}
          totalRefundAmount={totalRefundAmount}
        /> */}
      </Modal>
    </>
  );
};

export default VendorPayment;
