/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LoadingCard from '../../components/shared/LoadingCard';
import { getAllOutletRecord } from "../../store/actions/outletAction";
import baseUrl from "../../utils/baseUrl";

const OutletListContainer = () => {
    const dispatch = useDispatch();

    const [outletImageHoverImage, setOutletImageHoverImage] = useState(null);
    const [globalFilter, setGlobalFilter] = useState('');
    const { outletData, outletDataLoading } = useSelector((state) => state.outletReducer);

    useEffect(() => {
        dispatch(getAllOutletRecord());
    }, []);


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="p-text-bold table-heading-style">
                    View Outlet Address List
                </div>
            </React.Fragment>
        );
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Link to={"/CreateOutlet"}>
                    <div className="button-demo">
                        <Button
                            icon="pi pi-times"
                            className="p-button-rounded p-button-danger p-button-outlined"
                        />
                    </div>
                </Link>
            </React.Fragment>
        );
    }

    const renderHeader = () => {
        return (
            <>
                <div className="table-header">
                    <span className="p-input-icon-left">
                        <InputText
                            type="search"
                            className="form-control text-center text-field"
                            onInput={(e) => setGlobalFilter(e.target.value)}
                            placeholder="Search Outlet Here"
                        />
                    </span>
                </div>
            </>
        );
    }

    const outletNameTemplate = (rowData) => {
        return (
            <>{rowData.outletName}</>
        );
    };
    const businessHoursTemplate = (rowData) => {
        return (
            <>{rowData.businessHours}</>
        );
    };
    const businessHoursNoteTemplate = (rowData) => {
        return (
            <>{rowData.businessHoursNote}</>
        );
    };
    const contactNumOneTemplate = (rowData) => {
        return (
            <>{rowData.contactNumOne}</>
        );
    };

    const outletDirectionTemplate = (rowData) => {
        return (
            <a href={rowData.outletDirection} target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer' }}>
                <Button
                    label="View"
                    className="p-button-rounded p-button-info p-mr-2"
                />
            </a>
        );
    };

    const imageBodyTemplate = (rowData) => {
        const handleMouseEnter = (data) => {
            setOutletImageHoverImage(data.outletImage);
        };
        return (
            <div style={{ position: "relative" }}>
                <img
                    onMouseEnter={() => handleMouseEnter(rowData)}
                    className="product_list__img"
                    style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "10px",
                        cursor: "pointer",
                        border: "1px solid #ddd",
                        objectFit: "contain",
                    }}
                    src={baseUrl.concat(rowData.outletImage)}
                    alt="IMG"
                />
                <div className="product_list__tooltip">
                    <div className="tooltip__img">
                        <img
                            style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                                objectFit: "contain",
                            }}
                            src={baseUrl.concat(outletImageHoverImage)}
                            alt="product image"
                        />
                    </div>
                </div>
                <div className="image_tooltip__indicator"></div>
            </div>
        );
    };

    const statusBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span
                    className={
                        rowData?.isActive === "Y"
                            ? "p-tag p-tag-success"
                            : "p-tag p-tag-danger"
                    }
                >
                    {rowData?.isActive === "Y" ? "ACTIVE" : "INACTIVE"}
                </span>
            </React.Fragment>
        );
    }

    const OutletEditTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Link
                    to={{
                        pathname: `/UpdateOutlet`,
                        state: { rowData },
                    }}
                >
                    <Button
                        icon="pi pi-pencil" label="Edit"
                        className="p-button-rounded p-button-help p-mr-2"
                    />
                </Link>
            </React.Fragment>
        );
    };

    return (
        <div className="page-wrapper">
            <div className="container-fluid">
                <div className="col-md-12">
                    <div className="white-box">
                        <div className="datatable-doc-demo datatable-responsive-demo">
                            <div className="card">
                                <Toolbar
                                    className="p-mb-4"
                                    right={rightToolbarTemplate}
                                    left={leftToolbarTemplate}
                                ></Toolbar>
                                <>
                                    {
                                        outletDataLoading ? (
                                            <LoadingCard count={1} />
                                        ) :
                                            (
                                                <DataTable
                                                    header={renderHeader}
                                                    globalFilter={globalFilter}
                                                    paginator
                                                    rows={30}
                                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                                    rowsPerPageOptions={[30, 50, 100]}
                                                    outletName="product_list_table"
                                                    responsiveLayout="scroll"
                                                    value={outletData.data}
                                                    emptyMessage="No Outlet Address found!"
                                                >
                                                    <Column
                                                        filterField="outletName"
                                                        field="outletName"
                                                        header="Outlet Name"
                                                        body={outletNameTemplate}
                                                        style={{
                                                            maxWidth: '120px',
                                                            wordWrap: "break-word",
                                                        }}
                                                        sortable
                                                    />
                                                    <Column
                                                        field="businessHours"
                                                        header="Business Hours"
                                                        body={businessHoursTemplate}
                                                        style={{
                                                            maxWidth: '200px',
                                                            wordWrap: "break-word",
                                                        }}
                                                        sortable
                                                    />
                                                    <Column
                                                        field="businessHoursNote"
                                                        header="Business Hours Note"
                                                        body={businessHoursNoteTemplate}
                                                        style={{
                                                            maxWidth: '120px',
                                                            wordWrap: "break-word",
                                                        }}
                                                        sortable
                                                    />
                                                    <Column
                                                        field="contactNumOne"
                                                        header="Contact Number 1"
                                                        body={contactNumOneTemplate}
                                                        style={{
                                                            maxWidth: '120px',
                                                            wordWrap: "break-word",
                                                        }}
                                                        sortable
                                                    />

                                                    <Column
                                                        field="address"
                                                        header="Address"
                                                        style={{
                                                            maxWidth: '200px',
                                                            wordWrap: "break-word",
                                                            textAlign: "center"
                                                        }}
                                                        sortable
                                                    />

                                                    <Column
                                                        sortField="outletDirection"
                                                        header="Direction"
                                                        body={outletDirectionTemplate}
                                                    />
                                                    <Column
                                                        sortField="outletImage"
                                                        header="Image"
                                                        body={imageBodyTemplate}
                                                    />
                                                    <Column
                                                        sortField="isActive"
                                                        header="Status"
                                                        body={statusBodyTemplate}
                                                        sortable
                                                    />

                                                    <Column
                                                        header="Action"
                                                        body={OutletEditTemplate}
                                                    />

                                                </DataTable>
                                            )}
                                </>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OutletListContainer
