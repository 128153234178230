/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-loop-func */
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import React, { useEffect, useState } from "react";
import * as Icon from "react-feather";
import Resizer from "react-image-file-resizer";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-upload-gallery/dist/style.css";
import {
  checkRealTimeInventoryRecord,
  checkRealTimeInventoryReset,
} from "../../store/actions/productAction";
import "./../../containers/product/createProductCss.css";

export default function AddProductVariantTable({
  productVariants,
  duplicate_SKU_Entry,
  category_id,
  isAdmin,
  sellerCommissionPercentage,
  productDetails,
  setNewProductDetails,
  setVariants,
  variants,
  setBurnOK,
  burnOK,
  setDiscountOK,
  discountOK
}) {
  const [sku, setSku] = useState(null);
  const [variantImages, setVariantImages] = useState([]);
  const [multiProductImages, setMultiProductImages] = useState([]);
  const [productVariantMap0, setProductVariantMap0] = useState([]);
  const [productVariantMap1, setProductVariantMap1] = useState([]);
  const [productVariantMap2, setProductVariantMap2] = useState([]);
  const [productVariantMap3, setProductVariantMap3] = useState([]);
  const [status, setStatus] = useState(1);
  const [variantName, setVariantName] = useState([]);
  const [totalQty, setTotalQty] = useState(null);
  const [productPrice, setProductPrice] = useState(null);
  const [rowIndex, setRowIndex] = useState(null);
  const [inventoryOption, setInventoryOption] = useState(null);
  const [notUploaded, setNotUploaded] = useState(0);
  const [discountPercent, setDiscountPercent] = useState(null);
  const [discountFlat, setDiscountFlat] = useState(null);
  const [discountStartDate, setDiscountStartDate] = useState(null);
  const [discountEndDate, setDiscountEndDate] = useState(null);
  const [showDiscount, setShowDiscount] = useState("");
  const [showBurn, setShowBurn] = useState("");
  const [burnPercent, setBurnPercent] = useState(null);
  const [burnFlat, setBurnFlat] = useState(null);
  const [burnStartDate, setBurnStartDate] = useState(null);
  const [burnEndDate, setBurnEndDate] = useState(null);
  const [showDP, setShowDP] = useState("");
  const [dpPercent, setDPPercent] = useState(null);
  const [dpFlat, setDPFlat] = useState(null);
  const [isDpPriceFixManual, setIsDpPriceFixManual] = useState('M');

  const dispatch = useDispatch();

  const dp_radioStyle = {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: '10px',
    height: '3rem'
  };

  const inventoryUpdatedBy = (data, index) => {
    setRowIndex(index);
    setInventoryOption(data);
  };

  useEffect(() => {
    if (inventoryOption == 1) {
      if (variants[rowIndex]?.sellerProductSku.trim() === "") {
        variants[rowIndex].productPrice = "";
        variants[rowIndex].productQuantity = "";
        toast.error("SKU / Barcode field is Empty!");
      } else {
        dispatch(
          checkRealTimeInventoryRecord(variants[rowIndex]?.sellerProductSku)
        );
      }
    }
  }, [
    dispatch,
    variants,
    inventoryOption,
    rowIndex,
    variants[rowIndex]?.sellerProductSku,
  ]);

  useEffect(() => {
    if (rowIndex !== null) {
      let newArr = [...variants];
      if (sku !== null) {
        newArr[rowIndex].sellerProductSku = sku;
      }
      if (inventoryOption !== null) {
        newArr[rowIndex].inventoryTypeId = inventoryOption;
      }
      if (productPrice !== null) {
        newArr[rowIndex].productPrice = productPrice;
      }
      if (totalQty !== null) {
        newArr[rowIndex].productQuantity = totalQty;
      }

      if (dpFlat !== null) {
        newArr[rowIndex].dpAmount = dpFlat !== ""
          ? dpFlat
          : 0;
        setDPFlat(null)
      }
      if (dpPercent !== null) {
        newArr[rowIndex].dpPercentage = isDpPriceFixManual == 'F'
          ? sellerCommissionPercentage
          : dpPercent !== ""
            ? dpPercent
            : 0;
        setDPPercent(null)
      }
      newArr[rowIndex].isDpPriceFixManual = isDpPriceFixManual;
      if (isDpPriceFixManual == 'F') {
        setDPPercent(sellerCommissionPercentage)
        setDPFlat(0)
      }

      if (discountFlat !== null) {
        newArr[rowIndex].discountAmount = discountFlat !== ""
          ? discountFlat
          : 0;
        setDiscountFlat(null)
      }
      if (discountPercent !== null) {
        newArr[rowIndex].discountPercentage =
          discountPercent !== ""
            ? discountPercent
            : 0;
        setDiscountPercent(null)
      }
      if (discountStartDate !== null) {
        newArr[rowIndex].discountStartDate = discountStartDate.trim();
        setDiscountStartDate(null)
      }
      if (discountEndDate !== null) {
        newArr[rowIndex].discountEndDate = discountEndDate.trim();
        setDiscountEndDate(null)
      }

      if (burnFlat !== null) {
        newArr[rowIndex].burnAmount = burnFlat !== ""
          ? burnFlat
          : 0;
        setBurnFlat(null)
      }
      if (burnPercent !== null) {
        newArr[rowIndex].burnPercentage =
          burnPercent !== ""
            ? burnPercent
            : 0;
        setBurnPercent(null)
      }
      if (burnStartDate !== null) {
        newArr[rowIndex].burnStartDate = burnStartDate.trim();
        setBurnStartDate(null)
      }
      if (burnEndDate !== null) {
        newArr[rowIndex].burnEndDate = burnEndDate.trim();
        setBurnEndDate(null)
      }

      setVariants(newArr);
      setNewProductDetails(newArr)
      setSku(null);
      setProductPrice(null);
      setTotalQty(null);
      setInventoryOption(null);
    }
  }, [
    rowIndex,
    productPrice,
    sku,
    totalQty,
    discountPercent,
    discountFlat,
    discountStartDate,
    discountEndDate,
    inventoryOption,
    dpPercent,
    dpFlat,
    burnPercent,
    burnFlat,
    burnStartDate,
    burnEndDate,
    isDpPriceFixManual
  ]);

  const {
    productVariantMapArray0,
    productVariantMapArray1,
    productVariantMapArray2,
    productVariantMapArray3,
    productVariant,
  } = productVariants;

  const { data } = useSelector((state) => state?.productReducer);

  useEffect(() => {
    data?.data?.succeed === true && setVariants([]);
    data?.data?.succeed === true && setNewProductDetails([]);
  }, [data?.data]);

  useEffect(() => {
    setVariantName(productVariant);
  }, [productVariant]);

  const realTimeInventory = useSelector((state) => state?.productReducer);
  const {
    checkRealTimeInventory,
    realTimeLoaded,
    error,
    loading
  } = realTimeInventory;

  useEffect(() => {
    if (checkRealTimeInventory?.length !== 0) {
      setProductPrice(checkRealTimeInventory[0]?.salePrice);
    }
  }, [checkRealTimeInventory]);

  useEffect(() => {
    error && toast.error("SKU / Barcode Not found in System!");
    error && setProductPrice();
    error && setTotalQty();
  }, [error]);

  useEffect(() => {
    realTimeLoaded && dispatch(checkRealTimeInventoryReset());
  }, [realTimeLoaded]);

  useEffect(() => {
    let sum = 0;
    let stock_in_hand = 5;
    if (checkRealTimeInventory?.length !== 0) {
      for (var i = 0; i < checkRealTimeInventory.length; i++) {
        sum = sum + checkRealTimeInventory[i].currentStock;
      }
      if (sum < stock_in_hand) {
        setTotalQty(0);
      } else {
        setTotalQty(sum - stock_in_hand);
      }
    }
  }, [checkRealTimeInventory]);

  const shopProductSkuGenerator = (item) => {
    let date = Date.now();
    let id = item[0]?.variantOptionId0;
    let random = Math.floor(Math.random() * (99999 + 1) + 10000);
    let random2 = Math.floor(Math.random() * (99 + 1) + 100);
    let value = `${date}${random2}${id}${random}`;
    return value;
  };

  //Image Variant create start
  useEffect(() => {
    productVariantMapArray0?.length > 0 &&
      setProductVariantMap0(productVariantMapArray0);
    productVariantMapArray1?.length > 0 &&
      setProductVariantMap1(productVariantMapArray1);
    productVariantMapArray2?.length > 0 &&
      setProductVariantMap2(productVariantMapArray2);
    productVariantMapArray3?.length > 0 &&
      setProductVariantMap3(productVariantMapArray3);
  }, [
    productVariants.productVariantAdded,
    productVariantMapArray0,
    productVariantMapArray1,
    productVariantMapArray2,
    productVariantMapArray3,
  ]);

  let productVariantImages = [];

  useEffect(() => {
    productVariantImages = productVariantMap0.map((obj) => ({
      variantId: variantName.productVariantId,
      variantOptionId: obj.variantOptionId0,
      variantOptionText: obj.variantOptionText0,
      shopProductSku: obj.shopProductSku,
      images: [],
    }));
  }, [productVariantMap0]);

  useEffect(() => {
    productVariantImages.length > 0 && setVariantImages(productVariantImages);
  }, [
    productVariantImages.length > 0,
    productVariantImages
  ]);
  //Image Variant create End

  // Product Variant create Start
  let productVariant0 = [];
  let productVariant1 = [];
  let productVariant2 = [];
  let productVariant3 = [];
  let cartesianVariants = [];

  useEffect(() => {
    productVariant0 = productVariantMap0.map((obj) => ({
      ...obj,
      sellerProductSku: "",
      productPrice: "",
      productQuantity: "",
      inventoryTypeId: "",
    }));
    productVariant1 = productVariantMap1.map((obj) => ({
      ...obj,
      sellerProductSku: "",
      productPrice: "",
      productQuantity: "",
      inventoryTypeId: "",
    }));
    productVariant2 = productVariantMap2.map((obj) => ({
      ...obj,
      sellerProductSku: "",
      productPrice: "",
      productQuantity: "",
      inventoryTypeId: "",
    }));
    productVariant3 = productVariantMap3.map((obj) => ({
      ...obj,
      sellerProductSku: "",
      productPrice: "",
      productQuantity: "",
      inventoryTypeId: "",
    }));
    function cartesianProduct(...arrays) {
      return [...arrays].reduce(
        (a, b) =>
          a
            .map((x) => b.map((y) => x.concat(y)))
            .reduce((a, b) => a.concat(b), []),
        [[]]
      );
    }

    var cartesian = cartesianProduct(productVariant0);
    if (
      productVariant0.length > 0
      && productVariant1.length > 0
    ) {
      cartesian = cartesianProduct(productVariant0, productVariant1);
    }
    if (
      productVariant0.length > 0 &&
      productVariant1.length > 0 &&
      productVariant2.length > 0
    ) {
      cartesian = cartesianProduct(
        productVariant0,
        productVariant1,
        productVariant2
      );
    }
    if (
      productVariant0.length > 0 &&
      productVariant1.length > 0 &&
      productVariant2.length > 0 &&
      productVariant3.length > 0
    ) {
      cartesian = cartesianProduct(
        productVariant0,
        productVariant1,
        productVariant2,
        productVariant3
      );
    }
    const flatten = (obj) => {
      let res = {};
      for (const [key, value] of Object.entries(obj)) {
        if (typeof value === "object") {
          res = {
            ...res,
            ...flatten(value)
          };
        } else {
          res[key] = value;
        }
      }
      return res;
    };

    cartesian.forEach((item) => {
      cartesianVariants.push({
        ...flatten(item),
        shopProductSku: shopProductSkuGenerator(item),
      });
    });
  }, [
    productVariantMap0,
    productVariantMap1,
    productVariantMap2,
    productVariantMap3,
  ]);

  useEffect(() => {
    var cartesianVariantsNew = [];
    cartesianVariants.map((item, i) => {
      productDetails && productDetails.map((el, i) => {
        if (!item?.variantOptionId1) {
          if (el.productVariants[0]?.variantOptionId.toString() === item?.variantOptionId0.toString()) {
            cartesianVariantsNew.push(item)
          }
        } else {
          if (
            el.productVariants[0]?.variantOptionId.toString() === item?.variantOptionId0.toString()
            && el.productVariants[1]?.variantOptionId.toString() === item?.variantOptionId1.toString()
          ) {
            cartesianVariantsNew.push(item)
          }
        }

      })
    })
    const newItemsArray = cartesianVariants.filter(item => !cartesianVariantsNew.includes(item));
    const uniqueArray = [...new Set(newItemsArray)];
    setVariants(uniqueArray);
    setNewProductDetails(uniqueArray);
  }, [
    cartesianVariants.length > 0,
    productVariantMap0,
    productVariantMap1,
    productVariantMap2,
    productVariantMap3,
  ]);

  useEffect(() => {
    if (sellerCommissionPercentage !== 0) {
      setDPPercent(sellerCommissionPercentage)
      setIsDpPriceFixManual('F')
    } else {
      setDPPercent(null)
    }
  }, [sellerCommissionPercentage])

  const onVariantRowEditComplete = (i) => {
    let _variants = [...variants];
    _variants[i] = variants[i];
    setVariants(_variants);
    setNewProductDetails(_variants);
    productVariants.getVariantsHandler(_variants);
  };
  // Product Variant create End

  // Product variant image start
  const imageEditor = (options) => {
    return (
      <>
        <input
          type="file"
          accept="image/*"
          name=""
          id="my-file"
          multiple
          onChange={(e) => {
            fileUploadAndResize(e, options);
          }}
        />
      </>
    );
  };

  const fileUploadAndResize = (e, option) => {
    // resize
    let files = e.target.files;
    if (files) {
      for (let i = 0; i < files.length; i++) {
        const name = files[i].name;

        if (files[i].name.match(/\.(gif|GIF)$/)) {
          // Ensure the selected file is a GIF
          if (files[i].size / 1024 > 3585) {
            toast.error(`GIf Image size must be within 3.5Mb.`);
            return false;
          } else {
            if (files[i].type === "image/gif") {
              const reader = new FileReader();
              reader.onload = function (e) {
                const imageValue = e.target.result;
                setMultiProductImages((prevState) => [
                  ...prevState,
                  {
                    name,
                    path: imageValue,
                    options: option?.rowData
                  },
                ]);
                toast.success("GIF Product Image Selected.");
              };

              reader.readAsDataURL(files[i]);
            }
          }
        } else {
          const reader = new FileReader();
          reader.readAsDataURL(files[i]);
          reader.addEventListener("load", (event) => {
            const _loadedImageUrl = event.target.result;
            const image = document.createElement("img");
            image.src = _loadedImageUrl;

            image.addEventListener("load", () => {
              const { width, height } = image;
              if (width / height !== 1) {
                toast.error(
                  <>
                    <p style={{ fontSize: "18px", padding: "0", margin: "0" }}>
                      Size Ratio must be 1:1
                    </p>{" "}
                    <span style={{ fontSize: "14px" }}>
                      Example: 1000x1000, 720x720 , 500x500
                    </span>
                  </>
                );
                setNotUploaded(notUploaded + 1);
                return false;
              } else if (
                (width > 1000 && height > 1000) ||
                (width < 500 && height < 500)
              ) {
                toast.error(
                  `Image resolution must be (500x500)px to (1000x1000)px.`
                );
                setNotUploaded(notUploaded + 1);
                return false;
              } else if (files[i].size / 1024 > 300) {
                toast.error(`Image size must be within 300Kb.`);
                setNotUploaded(notUploaded + 1);
                return false;
              } else {
                if (files[i]) {
                  try {
                    Resizer.imageFileResizer(
                      files[i],
                      1000,
                      1000,
                      "JPEG",
                      100,
                      0,
                      (path) => {
                        setMultiProductImages((prevState) => [
                          ...prevState,
                          {
                            name,
                            path,
                            options: option?.rowData
                          },
                        ]);
                      },
                      "base64",
                      1000,
                      1000
                    );
                  } catch (err) {
                    toast.error("Something went wrong!");
                  }
                }
              }
            });
          });
        }
      }
    }
  };

  const imageBodyTemplate = (rowData) => {
    return (
      <span>
        {rowData.images.length === 0
          ? (
            <span>Upload Image(s)</span>
          ) : (
            <>
              <span key={rowData}>
                {rowData?.images.map((image, index) => (
                  <div
                    className="col-lg-2 col-md-4 col-6"
                    key={index}
                  >
                    <a>
                      <div className="file-upload-container">
                        <img
                          className="thumb-md product-image"
                          src={image.path}
                          alt={image.name}
                        />
                        <Icon.XCircle
                          className="file-upload__button_close"
                          onClick={() => onImageRemove(image, index, image.name)}
                        />
                      </div>
                    </a>
                  </div>
                ))}
              </span>
              <span>{rowData.images.length} image(s) Uploaded</span>
            </>
          )}
      </span>
    );
  };

  const onImageRemove = (img, ind, name) => {
    setVariantImages((data) =>
      data.map((variant, i) => ({
        ...variant,
        images: variant.images.filter(
          (item, index) =>
            variant?.variantOptionText !== img?.variantOptionText
            && ind !== index
            && name !== item?.name
        ),
      }))
    );
  };

  const onImageRowEditComplete = (e) => {
    let _variantImages = [...variantImages];
    let { newData, index } = e;

    let files = newData.images;

    if (files) {
      for (let i = 0; i < files.length; i++) {
        const name = files[i].name;
        if (files[i].name.match(/\.(gif|GIF)$/)) {
          // Ensure the selected file is a GIF
          if (files[i].size / 1024 > 3585) {
            toast.error(`GIf Image size must be within 3.5Mb.`);
            return false;
          } else {
            if (files[i].type === "image/gif") {
              const reader = new FileReader();
              reader.onload = function (e) {
                const imageValue = e.target.value;
                setMultiProductImages((prevState) => [
                  ...prevState,
                  {
                    name,
                    path: imageValue
                  },
                  Math.random().toString(16).slice(2),
                ]);
              };

              reader.readAsDataURL(files[i]);
            }
          }
        } else {
          if (files[i]) {
            try {
              Resizer.imageFileResizer(
                files[i],
                1000,
                1000,
                "JPEG",
                100,
                0,
                (path) => {
                  setMultiProductImages((prevState) => [
                    ...prevState,
                    {
                      name,
                      path
                    },
                    Math.random().toString(16).slice(2),
                  ]);
                },
                "base64",
                1000,
                1000
              );
              setMultiProductImages([]);
            } catch (err) {
              toast.error("Something went wrong!");
            }
          }
        }
      }
    }
    const updateData = {
      variantId: newData.variantId,
      variantOptionId: newData.variantOptionId,
      variantOptionText: newData.variantOptionText,
      shopProductSku: newData.shopProductSku,
      images: multiProductImages,
    };

    _variantImages[index] = updateData;
    setMultiProductImages([]);
    setVariantImages(_variantImages);
    productVariants.getVariantImagesHandler(_variantImages);
  };
  // Product variant image end

  const handleVariantDelete = (e, shopProductSku) => {
    e.preventDefault();
    for (var i = 0; i < variants.length; i++) {
      if (variants[i]?.shopProductSku === shopProductSku) {
        if (variants.length === 1) {
          toast.error("Can't Remove Last Variant!!");
        } else {
          variants.splice(i, 1);
        }
      }
    }
  };

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();
  };

  const burnCloseHandle = (burnAmount, burnPercent, burnStartDate, burnEndDate) => {
    burnAmount = isNaN(burnAmount)
      ? 0
      : burnAmount;
    burnPercent = isNaN(burnPercent)
      ? 0
      : burnPercent;

    setBurnOK(true);
    // Check if either burnAmount or burnPercent is non-zero
    if (
      burnAmount !== 0
      || burnPercent !== 0
    ) {
      // Check if either burnStartDate or burnEndDate is missing or empty
      if (
        !burnStartDate
        || !burnEndDate
      ) {
        setBurnOK(false)
        toast.error("Both Burn Start and End Date are Required!!");
      } else {
        setBurnOK(true)
        setShowBurn("");
      }
    } else {
      setBurnOK(true)
      setShowBurn("");
    }
  };

  const discountCloseHandle = (discountAmount, discountPercent, discountStartDate, discountEndDate) => {
    discountAmount = isNaN(discountAmount)
      ? 0
      : discountAmount;
    discountPercent = isNaN(discountPercent)
      ? 0
      : discountPercent;

    setDiscountOK(true)
    // Check if either discountAmount or discountPercent is non-zero
    if (
      discountAmount !== 0
      || discountPercent !== 0
    ) {
      // Check if either discountStartDate or discountEndDate is missing or empty
      if (
        !discountStartDate
        || !discountEndDate
      ) {
        setDiscountOK(false)
        toast.error("Both Discount Start and End Date are Required!!");
      } else {
        setDiscountOK(true)
        setShowDiscount("");
      }
    } else {
      setDiscountOK(true)
      setShowDiscount("");
    }
  };

  return (
    <>
      <div style={{
        display:
          productVariants.productVariantAdded === null
            ? "none"
            : productVariants.productVariantAdded
              ? "block"
              : "none",
      }}>
        {variants.length == 0 && <p
          style={{
            color: '#D71110',
            fontWeight: '700',
            fontSize: '21px',
            textAlign: 'center'
          }}>Sorry, All variant combinations are already created before.</p>}
      </div>
      <div
        className="container-fluid container_rug"
        style={{
          marginTop: "10px",
          display:
            productVariants.productVariantAdded === null
              ? "none"
              : productVariants.productVariantAdded
                ? "block"
                : "none",
        }}
      >

        {/* upload images by color */}
        {variants.length != 0 && <div className="row">
          <div className="col-md-12 col-sm-12 image-upload-bottom-card">
            <div className="datatable-editing-demo">
              <div className="card p-fluid image-upload-top-card">
                <DataTable
                  value={variantImages}
                  editMode="row"
                  dataKey="variantOptionId"
                  onRowEditComplete={onImageRowEditComplete}
                  responsiveLayout="scroll"
                >
                  {category_id === 1076
                    ? null
                    : (
                      <Column
                        field="variantOptionText"
                        header="Color"
                      />
                    )}
                  <Column
                    field="images"
                    header="Images"
                    body={imageBodyTemplate}
                    editor={(options) => imageEditor(options)}
                  />
                  <Column
                    rowEditor
                    headerStyle={{
                      width: "10%",
                      minWidth: "4rem"
                    }}
                    bodyStyle={{ textAlign: "center" }}
                  />
                </DataTable>
              </div>
            </div>
            <span
              style={{
                fontSize: "13px",
                color: "#db1b1b",
              }}
            >
              *Image size must be within 300Kb and only 1:1 Ratio Allowed.
            </span>{" "}
            <br />
            <span
              style={{
                fontSize: "13px",
                color: "#db1b1b",
              }}
            >
              *Image resolution must be (500x500)px to (1000x1000)px.
            </span>
            <br />
            <span
              style={{
                fontSize: "13px",
                color: "#db1b1b",
              }}
            >
              *GIF Image size must be within 3.5Mb.
            </span>
          </div>
        </div>}

        {/* price and stock for variants */}
        {variants.length != 0
          && <table className="product_variant__table">
            <thead>
              <tr>
                <th>#</th>
                {category_id === 1076 ? null : (
                  <>
                    <th>Variant</th>
                    <th>Variant</th>
                  </>
                )}
                <th>SKU / Barcode</th>
                <th>Inventory Updated By</th>
                {/* <th>DP Price</th> */}
                <th>Price</th>
                <th>Quantity</th>
                <th>DP Price</th>
                {isAdmin && <th>Burn Amount</th>}
                <th>Discount Amount</th>
              </tr>
            </thead>
            <tbody>
              {variants?.map((data, index) => (
                <tr>
                  <td>{index + 1}</td>
                  {category_id === 1076
                    ? null
                    : (
                      <>
                        <td>{data.variantOptionText0}</td>
                        <td>{data.variantOptionText1}</td>
                      </>
                    )
                  }
                  <td>
                    <input
                      onChange={(e) => {
                        setSku(e.target.value.trim());
                        setRowIndex(index);
                      }}
                      onBlur={() => onVariantRowEditComplete(index)}
                      type="text"
                      defaultValue={data.sellerProductSku}
                    />
                  </td>
                  <td>
                    <select
                      onChange={(e) => inventoryUpdatedBy(e.target.value, index)}
                      onBlur={() => onVariantRowEditComplete(index)}
                      defaultValue="0"
                    >
                      <option value="0">Self</option>
                      <option value="1">Through API</option>
                    </select>
                  </td>
                  <td
                    style={{
                      width: isAdmin
                        ? "10%"
                        : "20%"
                    }}>
                    {inventoryOption == 1 ? (
                      <>
                        {loading
                          && rowIndex === index
                          ? (
                            <i
                              className="pi pi-spin pi-spinner"
                              style={{ fontSize: "1.5em" }}
                            ></i>
                          ) : (
                            <input
                              onBlur={() => onVariantRowEditComplete(index)}
                              value={data?.productPrice}
                              type="number"
                              disabled={loading}
                              onWheel={numberInputOnWheelPreventChange}
                            />
                          )}
                      </>
                    ) : (
                      <input
                        onBlur={() => onVariantRowEditComplete(index)}
                        onChange={(e) => {
                          setProductPrice(e.target.value.trim());
                          setRowIndex(index);
                        }}
                        type="number"
                        defaultValue={data?.productPrice}
                        onWheel={numberInputOnWheelPreventChange}
                      />
                    )}
                  </td>

                  <td style={{
                    width: isAdmin
                      ? "10%"
                      : "20%"
                  }}>
                    {inventoryOption == 1 ? (
                      <>
                        {loading
                          && rowIndex === index
                          ? (
                            <i
                              className="pi pi-spin pi-spinner"
                              style={{ fontSize: "1.5em" }}
                            ></i>
                          ) : (
                            <input
                              onBlur={() => onVariantRowEditComplete(index)}
                              value={data?.productQuantity}
                              type="number"
                              disabled={loading}
                              onWheel={numberInputOnWheelPreventChange}
                            />
                          )
                        }
                      </>
                    ) : (
                      <input
                        onBlur={() => onVariantRowEditComplete(index)}
                        onChange={(e) => {
                          setTotalQty(e.target.value.trim());
                          setRowIndex(index);
                        }}
                        type="number"
                        defaultValue={data?.productQuantity}
                        onWheel={numberInputOnWheelPreventChange}
                      />
                    )}
                  </td>

                  <td
                    className="action__table-section dp_section"
                    style={{ width: "15%" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        justifyContent: "flexStart",
                      }}
                    >
                      <div
                        className="action_discount__btn-section"
                        style={{
                          position: "relative",
                          display: 'flex',
                          flexDirection: 'column'
                        }}
                      >
                        {data?.productPrice
                          ? <span
                            style={{
                              color: 'rgb(219, 27, 27)',
                              textWrap: 'nowrap'
                            }}
                          >
                            {data?.dpPercentage
                              ? `DP ${data?.dpPercentage}% (${data?.productPrice - ((data?.dpPercentage / 100) * data?.productPrice).toFixed(2)})`
                              : data?.dpAmount
                                ? `Amount: ${data?.dpAmount} (${(((data?.productPrice - data?.dpAmount) / data?.productPrice) * 100).toFixed(2)}%)`
                                : "Empty DP value"}
                          </span>
                          : <span>{' '}</span>}
                        <Button
                          className="action_discount__btn"
                          onClick={(e) => {
                            setShowDP(index + 1);
                            setShowBurn("")
                            setShowDiscount("")
                            e.preventDefault();
                          }}
                          disabled={
                            !data?.productPrice
                            || showDP
                            || showDiscount
                            || showBurn
                          }
                        >
                          DP
                        </Button>
                        {showDP === (index + 1) && (
                          <section
                            style={{
                              zIndex: 50,
                              background: "#fff",
                              boxShadow: "0px 0px 2px 1px #c7c7c7",
                              borderRadius: "5px",
                              padding: "15px",
                              right: "94px",
                              top: "-145px",
                              position: "absolute",
                            }}
                          >
                            <div className="dp_close">
                              <button
                                type="button"
                                className="border-0 bg-none py-0"
                                style={{
                                  color: "red",
                                  marginLeft: "auto",
                                }}
                                onClick={
                                  () => setShowDP("")
                                }
                              >
                                X
                              </button>
                            </div>

                            {sellerCommissionPercentage !== 0
                              && <div style={dp_radioStyle}>
                                <section
                                  className="dp_radio_form__section"
                                  style={{
                                    marginTop: '10px'
                                  }}
                                >
                                  <input
                                    onBlur={() => onVariantRowEditComplete(index)}
                                    type="radio"
                                    id="Fixed"
                                    name="isDpPriceFixManual"
                                    value="F"
                                    onChange={(e) => {
                                      setIsDpPriceFixManual(e.target.value)
                                      setRowIndex(index);
                                    }}
                                    checked={isDpPriceFixManual == 'F'} />
                                  <label
                                    for="Fixed"
                                    style={{ marginLeft: '10px' }}
                                  >Fixed</label>
                                </section>
                                <section className="dp_radio_form__section">
                                  <input
                                    onBlur={() => onVariantRowEditComplete(index)}
                                    type="radio"
                                    id="Manual"
                                    name="isDpPriceFixManual"
                                    value="M"
                                    onChange={(e) => {
                                      setIsDpPriceFixManual(e.target.value)
                                      setRowIndex(index);
                                    }}
                                    checked={isDpPriceFixManual == 'M'} />
                                  <label
                                    for="Manual"
                                    style={{ marginLeft: '10px' }}
                                  >Manual</label>
                                </section>
                              </div>}

                            {isDpPriceFixManual == 'F' &&
                              <>
                                <section className="discount_form__section">
                                  <label>DP Price</label>
                                  <input
                                    onBlur={() => onVariantRowEditComplete(index)}
                                    onChange={(e) => {
                                      setDPFlat(e.target.value.trim());
                                      setRowIndex(index);
                                    }}
                                    type="number"
                                    placeholder={
                                      dpPercent
                                        ? "Remove DP Percent"
                                        : "Enter DP Amount"
                                    }
                                    defaultValue={data?.dpAmount}
                                    disabled={
                                      dpPercent
                                      || (sellerCommissionPercentage !== 0
                                        && isDpPriceFixManual == 'F')
                                    }
                                  />
                                </section>
                                <section className="discount_form__section">
                                  <label>DP Percent</label>
                                  <input
                                    onBlur={() => onVariantRowEditComplete(index)}
                                    onChange={(e) => {
                                      setDPPercent(e.target.value.trim());
                                      setRowIndex(index);
                                    }}
                                    type="number"
                                    placeholder={
                                      dpFlat
                                        ? "Remove DP Amount"
                                        : "Enter in Percent"
                                    }
                                    defaultValue={data?.dpPercentage}
                                    disabled={
                                      dpFlat
                                      || (sellerCommissionPercentage !== 0
                                        && isDpPriceFixManual == 'F')
                                    }
                                  />
                                </section>
                              </>
                            }
                            {isDpPriceFixManual == 'M' &&
                              <>
                                <section className="discount_form__section">
                                  <label>DP Price</label>
                                  <input
                                    onBlur={() => onVariantRowEditComplete(index)}
                                    onChange={(e) => {
                                      setDPFlat(e.target.value.trim());
                                      setRowIndex(index);
                                    }}
                                    type="number"
                                    placeholder={
                                      dpPercent
                                        ? "Remove DP Percent"
                                        : "Enter DP Amount"
                                    }
                                    defaultValue={data?.dpAmount}
                                    disabled={
                                      dpPercent
                                      || (sellerCommissionPercentage !== 0
                                        && isDpPriceFixManual == 'F')
                                    }
                                  />
                                </section>
                                <section className="discount_form__section">
                                  <label>DP Percent</label>
                                  <input
                                    onBlur={() => onVariantRowEditComplete(index)}
                                    onChange={(e) => {
                                      setDPPercent(e.target.value.trim());
                                      setRowIndex(index);
                                    }}
                                    type="number"
                                    placeholder={
                                      dpFlat
                                        ? "Remove DP Amount"
                                        : "Enter in Percent"
                                    }
                                    defaultValue={data?.dpPercentage}
                                    disabled={
                                      dpFlat
                                      || (sellerCommissionPercentage !== 0
                                        && isDpPriceFixManual == 'F')}
                                  />
                                </section>
                              </>
                            }
                          </section>
                        )}
                      </div>
                    </div>
                  </td>

                  {isAdmin &&
                    <td
                      className="action__table-section burn_section"
                      style={{ width: "15%" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          justifyContent: "flexStart",
                        }}
                      >
                        <div
                          className="action_discount__btn-section"
                          style={{
                            position: "relative",
                            display: 'flex',
                            flexDirection: 'column'
                          }}
                        >
                          {data?.productPrice
                            ? <span
                              style={{
                                color: 'rgb(219, 27, 27)',
                                textWrap: 'nowrap'
                              }}
                            >
                              {data?.burnPercentage
                                ? `Burn ${data?.burnPercentage}% (${((data?.burnPercentage / 100) * data?.productPrice).toFixed(2)})`
                                : data?.burnAmount
                                  ? `Amount: ${data?.burnAmount} (${((data?.burnAmount / data?.productPrice) * 100).toFixed(2)}%)`
                                  : "Empty Burn value"}
                            </span>
                            : <span>{' '}</span>}
                          <Button
                            className="action_discount__btn"
                            onClick={(e) => {
                              setShowBurn(index + 1);
                              setShowDP("");
                              setShowDiscount("")
                              setBurnOK(false)
                              e.preventDefault();
                            }}
                            disabled={
                              !data?.productPrice
                              || showDP
                              || showDiscount
                              || showBurn
                            }
                          >
                            Burn
                          </Button>
                          {showBurn === (index + 1) && (
                            <section
                              style={{
                                zIndex: 50,
                                background: "#fff",
                                boxShadow: "0px 0px 2px 1px #c7c7c7",
                                borderRadius: "5px",
                                padding: "15px",
                                right: "94px",
                                top: "-145px",
                                position: "absolute",
                              }}
                            >
                              <div className="burn_close">
                                <button
                                  type="button"
                                  className="border-0 bg-none py-0"
                                  style={{
                                    color: "red",
                                    marginLeft: "auto",
                                  }}
                                  onClick={() => burnCloseHandle(data?.burnAmount, data?.burnPercentage, data?.burnStartDate, data?.burnEndDate)}
                                >
                                  X
                                </button>
                              </div>
                              {(!burnOK
                                && (data?.burnAmount
                                  || data?.burnPercentage))
                                ? <span
                                  style={{
                                    color: 'rgb(219, 27, 27)',
                                    textWrap: 'nowrap',
                                    fontStyle: 'italic'
                                  }}
                                >
                                  Must fill Burn Start and End date
                                </span>
                                : <></>
                              }

                              <section className="discount_form__section">
                                <label>Burn Amount</label>
                                <input
                                  onBlur={() => onVariantRowEditComplete(index)}
                                  onChange={(e) => {
                                    setBurnFlat(e.target.value.trim());
                                    setRowIndex(index);
                                  }}
                                  type="number"
                                  placeholder={
                                    burnPercent
                                      ? "Remove Burn Percent"
                                      : "Enter Burn Amount"
                                  }
                                  defaultValue={data?.burnAmount}
                                  disabled={burnPercent}
                                />
                              </section>
                              <section className="discount_form__section">
                                <label>Burn Percent</label>
                                <input
                                  onBlur={() => onVariantRowEditComplete(index)}
                                  onChange={(e) => {
                                    setBurnPercent(e.target.value.trim());
                                    setRowIndex(index);
                                  }}
                                  type="number"
                                  placeholder={
                                    burnFlat
                                      ? "Remove Burn Amount"
                                      : "Enter in Percent"
                                  }
                                  defaultValue={data?.burnPercentage}
                                  disabled={burnFlat}
                                />
                              </section>
                              <section className="discount_form__section">
                                <label>Start Date <span
                                  aria-hidden="true"
                                  style={{
                                    color: "red",
                                    fontWeight: "bold",
                                  }}
                                >
                                  *
                                </span></label>
                                <input
                                  onChange={(e) => {
                                    setBurnStartDate(e.target.value);
                                    setRowIndex(index);
                                  }}
                                  type="datetime-local"
                                  value={data?.burnStartDate}
                                />
                              </section>
                              <section className="discount_form__section">
                                <label>End Date <span
                                  aria-hidden="true"
                                  style={{
                                    color: "red",
                                    fontWeight: "bold",
                                  }}
                                >
                                  *
                                </span></label>
                                <input
                                  onChange={(e) => {
                                    setBurnEndDate(e.target.value);
                                    setRowIndex(index);
                                  }}
                                  type="datetime-local"
                                  value={data?.burnEndDate}
                                />
                              </section>
                            </section>
                          )}
                        </div>
                      </div>
                    </td>
                  }

                  <td
                    className="action__table-section discount_section"
                    style={{
                      width: isAdmin
                        ? "15%"
                        : "20%"
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        justifyContent: "flexStart",
                      }}
                    >
                      <div
                        className="action_discount__btn-section"
                        style={{
                          position: "relative",
                          display: 'flex',
                          flexDirection: 'column'
                        }}
                      >
                        {data?.productPrice
                          ? <span
                            style={{
                              color: 'rgb(219, 27, 27)',
                              textWrap: 'nowrap'
                            }}
                          >
                            {data?.discountPercentage
                              ? `Discount ${data?.discountPercentage}% (${((data?.discountPercentage / 100) * data?.productPrice).toFixed(2)})`
                              : data?.discountAmount
                                ? `Amount: ${data?.discountAmount} (${((data?.discountAmount / data?.productPrice) * 100).toFixed(2)}%)`
                                : "Empty Discount value"}
                          </span>
                          : <span>{' '}</span>}
                        <Button
                          className="action_discount__btn"
                          onClick={(e) => {
                            setShowDiscount(index + 1);
                            setShowDP("");
                            setShowBurn("")
                            setDiscountOK(false)
                            e.preventDefault();
                          }}
                          disabled={
                            !data?.productPrice
                            || showDP
                            || showDiscount
                            || showBurn
                          }
                        >
                          Discount
                        </Button>
                        {showDiscount === (index + 1) && (
                          <section
                            style={{
                              zIndex: 50,
                              background: "#fff",
                              boxShadow: "0px 0px 2px 1px #c7c7c7",
                              borderRadius: "5px",
                              padding: "15px",
                              right: "94px",
                              top: "-145px",
                              position: "absolute",
                            }}
                          >
                            <div className="discount_close">
                              <button
                                type="button"
                                className="border-0 bg-none py-0"
                                style={{
                                  color: "red",
                                  marginLeft: "auto",
                                }}
                                onClick={() => discountCloseHandle(data?.discountAmount, data?.discountPercentage, data?.discountStartDate, data?.discountEndDate)}
                              >
                                X
                              </button>
                            </div>
                            {(!discountOK
                              && (data?.discountAmount
                                || data?.discountPercentage))
                              ? <span
                                style={{
                                  color: 'rgb(219, 27, 27)',
                                  textWrap: 'nowrap',
                                  fontStyle: 'italic'
                                }}
                              >
                                Must fill Discount Start and End date
                              </span>
                              : <></>
                            }

                            <p
                              style={{
                                color: "rgb(219, 27, 27)",
                                fontSize: "12px",
                                marginTop: 5,
                              }}
                            >
                              Combine Flat & Percentage Discount not Allowed
                            </p>

                            <section className="discount_form__section">
                              <label>Flat Discount</label>
                              <input
                                onBlur={() => onVariantRowEditComplete(index)}
                                onChange={(e) => {
                                  setDiscountFlat(e.target.value.trim());
                                  setRowIndex(index);
                                }}
                                type="number"
                                placeholder={
                                  discountPercent
                                    ? "Remove Percent Discount"
                                    : "Enter Flat Amount"
                                }
                                defaultValue={data?.discountAmount}
                                disabled={discountPercent}
                              />
                            </section>
                            <section className="discount_form__section">
                              <label>Percent Discount</label>
                              <input
                                onBlur={() => onVariantRowEditComplete(index)}
                                onChange={(e) => {
                                  setDiscountPercent(e.target.value.trim());
                                  setRowIndex(index);
                                }}
                                type="number"
                                placeholder={
                                  discountFlat
                                    ? "Remove Flat Discount"
                                    : "Enter in Percent"
                                }
                                defaultValue={data?.discountPercentage}
                                disabled={discountFlat}
                              />
                            </section>
                            <section className="discount_form__section">
                              <label>Start Date <span
                                aria-hidden="true"
                                style={{
                                  color: "red",
                                  fontWeight: "bold",
                                }}
                              >
                                *
                              </span></label>
                              <input
                                onBlur={() => onVariantRowEditComplete(index)}
                                onChange={(e) => {
                                  setDiscountStartDate(e.target.value);
                                  setRowIndex(index);
                                }}
                                type="datetime-local"
                                defaultValue={data?.discountStartDate}
                              />
                            </section>
                            <section className="discount_form__section">
                              <label>End Date <span
                                aria-hidden="true"
                                style={{
                                  color: "red",
                                  fontWeight: "bold",
                                }}
                              >
                                *
                              </span></label>
                              <input
                                onBlur={() => onVariantRowEditComplete(index)}
                                onChange={(e) => {
                                  setDiscountEndDate(e.target.value);
                                  setRowIndex(index);
                                }}
                                type="datetime-local"
                                defaultValue={data?.discountEndDate}
                              />
                            </section>
                          </section>
                        )}
                      </div>
                      <button
                        className="action_delete__btn"
                        onClick={(e) => {
                          handleVariantDelete(e, data.shopProductSku);
                          setStatus(status + 1);
                        }}
                        disabled={
                          showDiscount
                          || showBurn
                        }
                      >
                        <i
                          className="pi pi-trash"
                          style={{
                            fontSize: "1em",
                            color: "red"
                          }}
                        ></i>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>}

        <>
          {duplicate_SKU_Entry?.map((data, index) => (
            <div
              key={index}
              style={{
                marginBottom: "1px"
              }}
            >
              {data?.charAt(0) === " "
                ? null
                : (
                  <div
                    style={{
                      width: "100%",
                      padding: "5px",
                      backgroundColor: "rgba(255,0,0,0.2)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      style={{
                        color: "rgb(255,0,0",
                        fontWeight: "600",
                        fontSize: "17px",
                      }}
                    >
                      {data}
                    </p>
                  </div>
                )}
            </div>
          ))}
        </>
        <ToastContainer autoClose={3500} />
      </div>
    </>
  );
}

