import React, { useEffect, useState } from 'react';
import { Col, Form } from "react-bootstrap";
import Resizer from "react-image-file-resizer";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import { Loader } from "../../containers";
import { getAllAreaRecord, getAllCityRecord, getAllCountryRecord } from '../../store/actions/addressAction';
import { getBussinessTypeRecord } from '../../store/actions/bussinessTypeAction';
import { createSellerRecord, createSellerReset } from '../../store/actions/sellerAction';
import baseUrl from '../../utils/baseUrl';

const CreateNewSellerContainer = () => {

    const [sellerName, setSellerName] = useState('')
    const [sellerContact, setSellerContact] = useState('')
    const [sellerEmail, setSellerEmail] = useState('')

    const [shopName, setShopName] = useState('')
    const [ownerName, setOwnerName] = useState('')
    const [binNo, setBinNo] = useState('')
    const [shopZipCode, setShopZipCode] = useState('')
    const [shopDescription, setShopDescription] = useState('')
    const [shopAddress, setShopAddress] = useState('')

    const [shopLogoUrl, setShopLogoUrl] = useState('')
    const [showShopLogoFile, setShowShopLogoFile] = useState('')

    const [sellerPassword, setSellerPassword] = useState("");
    const [sellerConfirmPassword, setSellerConfirmPassword] = useState("");
    const [businessTypeList, setBusinessTypeList] = useState("");
    const [businessTypeIdsMulti, setBusinessTypeIdsMulti] = useState(0);

    const [countryId, setCountryId] = useState("");
    const [countryList, setCountryList] = useState("");

    const [cityList, setCityList] = useState("");

    const [areaList, setAreaList] = useState("");

    const [shopCity, setShopCity] = useState("");
    const [shopState, setShopState] = useState("");

    const [ownerNidUrl, setOwnerNidUrl] = useState(null);
    const [ownerNidName, setOwnerNidName] = useState("");

    const [ownerBINUrl, setOwnerBINUrl] = useState(null);
    const [ownerBINName, setOwnerBINName] = useState("");

    const [ownerTradeLicenseUrl, setOwnerTradeLicenseUrl] = useState(null);
    const [ownerTradeLicenseName, setOwnerTradeLicenseName] = useState("");

    const [businessDocUrl, setBusinessDocUrl] = useState(null);
    const [businessDocName, setBusinessDocName] = useState("");

    const [ownerTINDocUrl, setOwnerTINDocUrl] = useState(null);
    const [ownerTINDocName, setOwnerTINDocName] = useState("");

    const [ownerDBIDDocUrl, setOwnerDBIDDocUrl] = useState(null);
    const [ownerDBIDDocName, setOwnerDBIDDocName] = useState("");

    const [additionalDocNum, setAdditionalDocNum] = useState(0);

    const [additionalDocumentsArray, setAdditionalDocumentsArray] = useState([]);

    const dispatch = useDispatch();
    const history = useHistory();

    const { bussinessTypes } = useSelector((state) => state?.bussinessTypeReducer);
    const { allCountries, allCities, allAreas } = useSelector((state) => state?.addressReducer);
    const { createSellerData, createSellerDataSuccess, createSellerDataLoading } = useSelector((state) => state?.sellerReducer);

    useEffect(() => {
        dispatch(getBussinessTypeRecord())
        dispatch(getAllCountryRecord())
    }, [dispatch])

    useEffect(() => {
        const businessTypeListData = bussinessTypes.map(
            ({ bussinessTypeName: label, bussinessTypeId: value }) => ({
                label,
                value,
            })
        )
        setBusinessTypeList(businessTypeListData)
    }, [bussinessTypes])

    useEffect(() => {
        const countryListData = allCountries.map(
            ({ countryName: label, countryId: value }) => ({
                label,
                value,
            })
        )
        setCountryList(countryListData)
    }, [allCountries])

    const handleBusinessTypesMultiChange = (businessLists) => {
        const valuesArray = businessLists.map(item => item.value);
        setBusinessTypeIdsMulti(valuesArray);
    }

    const handleCountryChange = async (field, e) => {
        dispatch(getAllCityRecord(e.value))

        setCountryId(e.value)
    };

    useEffect(() => {
        setCityList(allCities.map(
            ({ cityName: label, cityId: value }) => ({
                label,
                value,
            })
        ))
    }, [allCities])

    const handleCityChange = async (field, e) => {
        setShopCity(e.label)

        dispatch(getAllAreaRecord(e.value))
    };

    useEffect(() => {
        setAreaList(allAreas.map(
            ({ areaName: label, areaId: value }) => ({
                label,
                value,
            })
        ))
    }, [allAreas])

    const handleAreaChange = async (field, e) => {
        setShopState(e.label)
    };

    const handleChange = (e) => {
        const { target } = e;

        switch (target.name) {
            case "sellerName":
                setSellerName(target.value)
                break;

            case "sellerContact":
                setSellerContact(target.value)
                break;

            case "sellerEmail":
                setSellerEmail(target.value)
                break;

            case "shopName":
                setShopName(target.value)
                break;

            case "ownerName":
                setOwnerName(target.value)
                break;

            case "binNo":
                setBinNo(target.value)
                break;

            case "shopZipCode":
                setShopZipCode(target.value)
                break;

            case "shopDescription":
                setShopDescription(target.value)
                break;

            case "shopAddress":
                setShopAddress(target.value)
                break;

            case "sellerPassword":
                setSellerPassword(target.value)
                break;

            case "sellerConfirmPassword":
                setSellerConfirmPassword(target.value)
                break;

            default:
        }
    }

    const fileSelectedHandlerLogo = (e) => {
        const ShopLogoImageFile = e.target.files[0];
        //1)    CHECK IF IT'S A IMAGE
        let ShopLogoFileInput = false;
        if (ShopLogoImageFile) {
            if (!ShopLogoImageFile.name.match(/\.(jpg|jpeg|png|webp)$/)) {
                toast.error("Select a valid image.");
                return false;
            }
            ShopLogoFileInput = true;
            if (ShopLogoFileInput) {
                try {
                    Resizer.imageFileResizer(
                        ShopLogoImageFile,
                        180,
                        180,
                        "JPEG",
                        100,
                        0,
                        (uri) => {
                            setShopLogoUrl(uri)
                            setShowShopLogoFile(URL.createObjectURL(ShopLogoImageFile))
                            toast.success("Shop Logo Image Selected.");
                        },
                        "base64",
                        180,
                        180
                    );
                } catch (err) {
                    toast.error("Something went wrong!");
                }
            }
        }
    };

    const fileSelectedNIDHandler = (e) => {
        const NIDFrontFile = e?.target?.files[0];

        if (NIDFrontFile?.size / 1048576 > 3) {
            toast.error("File size must be within 3 Mb.");
            e.target.value = null
            setOwnerNidUrl(null)
            return
        }

        setOwnerNidName(NIDFrontFile?.name);
        // FileReader function for read the file.
        var fileReader = new FileReader();
        var base64;
        // Onload of file read the file content
        fileReader.onload = function (fileLoadedEvent) {
            base64 = fileLoadedEvent.target.result;
            // Set Data
            setOwnerNidUrl(base64);
        };
        // Convert data to base64
        // fileReader?.readAsDataURL(CVorResumeFile);
        const unusedVariable = fileReader?.readAsDataURL(NIDFrontFile);
        // e.target.value = null;
    };

    const fileSelectedBINHandler = (e) => {
        const BINDocFile = e?.target?.files[0];

        if (BINDocFile?.size / 1048576 > 3) {
            toast.error("File size must be within 3 Mb.");
            e.target.value = null
            setOwnerBINUrl(null)
            return
        }

        setOwnerBINName(BINDocFile?.name);
        // FileReader function for read the file.
        var fileReader = new FileReader();
        var base64;
        // Onload of file read the file content
        fileReader.onload = function (fileLoadedEvent) {
            base64 = fileLoadedEvent.target.result;
            // Set Data
            setOwnerBINUrl(base64);
        };
        // Convert data to base64
        // fileReader?.readAsDataURL(CVorResumeFile);
        const unusedVariable = fileReader?.readAsDataURL(BINDocFile);
        // e.target.value = null;
    };

    const fileSelectedTradeLicenseHandler = (e) => {
        const TradeLicense = e?.target?.files[0];

        if (TradeLicense?.size / 1048576 > 3) {
            toast.error("File size must be within 3 Mb.");
            e.target.value = null
            setOwnerTradeLicenseUrl(null)
            return
        }

        setOwnerTradeLicenseName(TradeLicense?.name);
        // FileReader function for read the file.
        var fileReader = new FileReader();
        var base64;
        // Onload of file read the file content
        fileReader.onload = function (fileLoadedEvent) {
            base64 = fileLoadedEvent.target.result;
            // Set Data
            setOwnerTradeLicenseUrl(base64);
        };
        // Convert data to base64
        // fileReader?.readAsDataURL(CVorResumeFile);
        const unusedVariable = fileReader?.readAsDataURL(TradeLicense);
        // e.target.value = null;
    };

    const fileSelectedBusinessDocHandler = (e) => {
        const BusinessDoc = e?.target?.files[0];

        if (BusinessDoc?.size / 1048576 > 3) {
            toast.error("File size must be within 3 Mb.");
            e.target.value = null
            setBusinessDocUrl(null)
            return
        }

        setBusinessDocName(BusinessDoc?.name);
        // FileReader function for read the file.
        var fileReader = new FileReader();
        var base64;
        // Onload of file read the file content
        fileReader.onload = function (fileLoadedEvent) {
            base64 = fileLoadedEvent.target.result;
            // Set Data
            setBusinessDocUrl(base64);
        };
        // Convert data to base64
        // fileReader?.readAsDataURL(CVorResumeFile);
        const unusedVariable = fileReader?.readAsDataURL(BusinessDoc);
        // e.target.value = null;
    };

    const fileSelectedTINHandler = (e) => {
        const TINDocFile = e?.target?.files[0];

        if (TINDocFile?.size / 1048576 > 3) {
            toast.error("File size must be within 3 Mb.");
            e.target.value = null
            setOwnerTINDocUrl(null)
            return
        }

        setOwnerTINDocName(TINDocFile?.name);
        // FileReader function for read the file.
        var fileReader = new FileReader();
        var base64;
        // Onload of file read the file content
        fileReader.onload = function (fileLoadedEvent) {
            base64 = fileLoadedEvent.target.result;
            // Set Data
            setOwnerTINDocUrl(base64);
        };
        // Convert data to base64
        // fileReader?.readAsDataURL(CVorResumeFile);
        const unusedVariable = fileReader?.readAsDataURL(TINDocFile);
        // e.target.value = null;
    };

    const fileSelectedDBIDHandler = (e) => {
        const DBIDDocFile = e?.target?.files[0];

        if (DBIDDocFile?.size / 1048576 > 3) {
            toast.error("File size must be within 3 Mb.");
            e.target.value = null
            setOwnerDBIDDocUrl(null)
            return
        }

        setOwnerDBIDDocName(DBIDDocFile?.name);
        // FileReader function for read the file.
        var fileReader = new FileReader();
        var base64;
        // Onload of file read the file content
        fileReader.onload = function (fileLoadedEvent) {
            base64 = fileLoadedEvent.target.result;
            // Set Data
            setOwnerDBIDDocUrl(base64);
        };
        // Convert data to base64
        // fileReader?.readAsDataURL(CVorResumeFile);
        const unusedVariable = fileReader?.readAsDataURL(DBIDDocFile);
        // e.target.value = null;
    };

    const handleAddAdditional = () => {
        setAdditionalDocNum(additionalDocNum + 1)
        setAdditionalDocumentsArray([...additionalDocumentsArray, { docName: '', docPath: '' }]);
    }

    useEffect(() => {
        if (additionalDocNum === 5) {
            toast.info("Max 5 additional documents can be upload!");
            return
        }
    }, [additionalDocNum])

    const handleRemoveAdditional = (e, index) => {
        setAdditionalDocNum(additionalDocNum - 1)
        const list = [...additionalDocumentsArray];
        list.splice(index, 1);
        setAdditionalDocumentsArray(list);
    }

    const handleOnBlur = (e, index) => {
        const { name, value } = e.target;
        const list = [...additionalDocumentsArray];
        list[index][name] = value;
        setAdditionalDocumentsArray(list);
    }

    const additionalFileSelectedHandler = (e, index) => {
        const additionalFile = e?.target?.files[0];

        const { name } = e.target;
        const list = [...additionalDocumentsArray];

        if (additionalFile?.size / 1048576 > 3) {
            toast.error("File size must be within 3 Mb.");
            e.target.value = null
            list[index][name] = null
            return
        }

        // setAdditionalDocumentsArray(list);

        // setOwnerTradeLicenseName(additionalFile?.name);
        // FileReader function for read the file.
        var fileReader = new FileReader();
        var base64;
        // Onload of file read the file content
        fileReader.onload = function (fileLoadedEvent) {
            base64 = fileLoadedEvent.target.result;
            // Set Data
            list[index][name] = base64;
        };
        // Convert data to base64
        // fileReader?.readAsDataURL(CVorResumeFile);
        const unusedVariable = fileReader?.readAsDataURL(additionalFile);
        // e.target.value = null;
        // list[index][name] = value;
        setAdditionalDocumentsArray(list);
    };

    const saveSeller = async (e) => {
        e.preventDefault();

        if (sellerName === "") {
            toast.error("Seller Name is required!");
            return
        }

        if (sellerContact === "") {
            toast.error("Seller Contact Number is required!");
            return
        }

        if (sellerEmail === "") {
            toast.error("Seller Email Address is required!");
            return
        }

        if (businessTypeIdsMulti == 0) {
            toast.error("Business Type is required!");
            return
        }

        if (sellerPassword === "") {
            toast.error("Password is required!");
            return
        }

        if (sellerPassword !== sellerConfirmPassword) {
            toast.error("Password Not Matched!");
            return
        }

        if (shopName === "") {
            toast.error("Shop Name is required!");
            return
        }

        if (ownerName === "") {
            toast.error("Shop Owner Name is required!");
            return
        }

        if (shopCity === "") {
            toast.error("District/City Name is required!");
            return
        }

        if (shopState === "") {
            toast.error("Area/Thana/Upazila/State is required!");
            return
        }

        if (shopZipCode === "") {
            toast.error("Zip Code is required!");
            return
        }

        if (shopDescription === "") {
            toast.error("Shop Description is required!");
            return
        }

        if (shopAddress === "") {
            toast.error("Shop Address is required!");
            return
        }

        if (shopLogoUrl === "") {
            toast.error("Shop Logo is required!");
            return
        }

        if (ownerNidUrl === null) {
            toast.error("Owner NID is required!");
            return
        }

        if (businessDocUrl === null) {
            toast.error("Business Document is required!");
            return
        }

        if (ownerTradeLicenseUrl === null) {
            toast.error("Trade License is required!");
            return
        }

        if (additionalDocumentsArray.length > 0) {
            additionalDocumentsArray.map((data, i) => {
                if (data.docName === "" || data.docPath === "") {
                    toast.error("Both Document Name and File is required!");
                    return
                }
            })
        }

        const sellerData = {
            sellerName: sellerName,
            sellerContactNo: sellerContact,
            sellerEmail: sellerEmail,
            bussinessTypeId: businessTypeIdsMulti, // Take Multiple Id number in array
            sellerPassword: sellerPassword,
            shopName: shopName,
            ownerName: ownerName,
            binNo: binNo,
            sellerTypeId: 1,
            sellerCurrency:
                countryId === 19
                    ? "BDT"
                    : countryId === 45
                        ? "CNY"
                        : countryId === 101
                            ? "INR"
                            : countryId === 232
                                ? "GBP"
                                : countryId === 233
                                    ? "USD"
                                    : "BDT",
            shopCity: shopCity,
            shopState: shopState,
            shopZipCode: shopZipCode,
            shopDescription: shopDescription,
            shopAddress: shopAddress,
            shopLogoUrl: shopLogoUrl ? shopLogoUrl : "", // Image
            ownerNidUrl: ownerNidUrl ? ownerNidUrl : "", // Image or File
            bussinessDocUrl: businessDocUrl ? businessDocUrl : "", // Image or File
            tradeLicenseDoc: ownerTradeLicenseUrl ? ownerTradeLicenseUrl : "", // Image or File
            binNoDoc: ownerBINUrl ? ownerBINUrl : "", // Image or File (optional)
            tinNoDoc: ownerTINDocUrl ? ownerTINDocUrl : "", // Image or File (optional)
            dbIdDoc: ownerDBIDDocUrl ? ownerDBIDDocUrl : "", // Image or File (optional)
            additionalDocuments: additionalDocumentsArray.length !== 0 ? additionalDocumentsArray : "",
            isSellerDelivered: "N",
            nidBackDoc: "", // Image or File (optional)
        };

        dispatch(createSellerRecord(sellerData))
    }

    useEffect(() => {

        if (createSellerData?.errors && createSellerData?.errors[0] === "Email or Contact No Already Exist") {
            toast.error("Email or Contact No Already Exists.");
        }
        else if (createSellerData?.succeed === false) {
            toast.error("Something went wrong, Please try again.");
        }
        else if (createSellerData?.succeed === true) {
            toast.success("Seller Created Successfully!");
            dispatch(createSellerReset())
            setTimeout(() => {
                history.push("/pendingSeller");
            }, 2500);
        }
        else if (createSellerData?.message === "success") {
            toast.success("Seller Created Successfully!");
            dispatch(createSellerReset())
            setTimeout(() => {
                history.push("/pendingSeller");
            }, 2500);
        }
    }, [createSellerData.errors, createSellerData.message, createSellerData.succeed, createSellerDataSuccess, dispatch, history])

    return (
        <div className='page-wrapper'>
            <ToastContainer autoClose={2500} />
            <div className='container-fluid'>

                <div className='col-sm-12'>
                    <div className='white-box'>
                        <div className='datatable-rowexpansion-demo'>
                            <div className='card'>
                                <ul className='nav nav-tabs seller-tabs'>
                                    <li>
                                        <Link
                                            to='/createNewSeller'
                                            style={{ color: "#1F5DA0", borderBottom: '2px solid #1F5DA0', fontWeight: "700" }}
                                        >Create New Seller</Link>
                                    </li>
                                    <li>
                                        <Link to='/pendingSeller'>Pending Seller</Link>
                                    </li>
                                    <li>
                                        <Link to='/approvedSeller'>Approved Seller</Link>
                                    </li>
                                    <li>
                                        <Link to='/pendingReturnPolicy'>Return Policy</Link>
                                    </li>
                                </ul>

                                <div className="row" style={{ margin: '15px', }}>
                                    <div className="col-md-12 col-sm-12">
                                        <form className="form-horizontal">
                                            <div className="row">
                                                <div className="col-md-4 col-sm-12">
                                                    <div className="form-group ">
                                                        <label>
                                                            Seller Name{" "}
                                                            <span
                                                                aria-hidden="true"
                                                                style={{
                                                                    color: "red",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                *
                                                            </span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            placeholder="Enter Seller Name"
                                                            name="sellerName"
                                                            value={sellerName}
                                                            onChange={handleChange}
                                                            className={"form-control"}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-sm-12">
                                                    <div className="form-group ">
                                                        <label>
                                                            Seller Contact Number{" "}
                                                            <span
                                                                aria-hidden="true"
                                                                style={{
                                                                    color: "red",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                *
                                                            </span>
                                                        </label>
                                                        <input
                                                            type="number"
                                                            placeholder="Enter Seller Contact Number"
                                                            name="sellerContact"
                                                            value={sellerContact}
                                                            onChange={handleChange}
                                                            className={"form-control"}
                                                        />
                                                    </div>
                                                </div>


                                                <div className="col-md-4 col-sm-12">
                                                    <div className="form-group ">
                                                        <label>
                                                            Seller Email Address{" "}
                                                            <span
                                                                aria-hidden="true"
                                                                style={{
                                                                    color: "red",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                *
                                                            </span>
                                                        </label>
                                                        <input
                                                            type="email"
                                                            placeholder="Enter Seller Email Address"
                                                            name="sellerEmail"
                                                            value={sellerEmail}
                                                            onChange={handleChange}
                                                            className={"form-control"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-4 col-sm-12">
                                                    <div className="form-group ">
                                                        <label>
                                                            Password{" "}
                                                            <span
                                                                aria-hidden="true"
                                                                style={{
                                                                    color: "red",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                *
                                                            </span>
                                                        </label>
                                                        <input
                                                            type="password"
                                                            name="sellerPassword"
                                                            placeholder="Enter Seller Password"
                                                            value={sellerPassword}
                                                            onChange={handleChange}
                                                            className={"form-control"}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-sm-12">
                                                    <div className="form-group ">
                                                        <label>
                                                            Confirm Password{" "}
                                                            <span
                                                                aria-hidden="true"
                                                                style={{
                                                                    color: "red",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                *
                                                            </span>
                                                        </label>
                                                        <input
                                                            type="password"
                                                            name="sellerConfirmPassword"
                                                            placeholder="Confirm Seller Password"
                                                            value={sellerConfirmPassword}
                                                            onChange={handleChange}
                                                            className={"form-control"}
                                                        />
                                                    </div>
                                                </div>

                                                <Col xs={12} md={4}>
                                                    <Form.Group controlId="bussinessType">
                                                        <Form.Label>Business Type(s){" "}
                                                            <span
                                                                aria-hidden="true"
                                                                style={{
                                                                    color: "red",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                *
                                                            </span></Form.Label>
                                                        <Select
                                                            options={businessTypeList}
                                                            isMulti
                                                            name="bussinessType"
                                                            placeholder="Select Business Type(s)"
                                                            // value={bussinessType}

                                                            onChange={(value) =>
                                                                handleBusinessTypesMultiChange(
                                                                    value
                                                                )
                                                            }
                                                            closeMenuOnSelect={false}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-4 col-sm-12">
                                                    <div className="form-group ">
                                                        <label>
                                                            Shop Name{" "}
                                                            <span
                                                                aria-hidden="true"
                                                                style={{
                                                                    color: "red",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                *
                                                            </span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            placeholder="Enter Shop Name"
                                                            name="shopName"
                                                            value={shopName}
                                                            onChange={handleChange}
                                                            className={"form-control"}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-sm-12">
                                                    <div className="form-group ">
                                                        <label>
                                                            Shop Owner Name{" "}
                                                            <span
                                                                aria-hidden="true"
                                                                style={{
                                                                    color: "red",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                *
                                                            </span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            placeholder="Enter Shop Owner Name"
                                                            name="ownerName"
                                                            value={ownerName}
                                                            onChange={handleChange}
                                                            className={"form-control"}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-sm-12">
                                                    <div className="form-group ">
                                                        <label>
                                                            BIN Number{" "}
                                                        </label>
                                                        <input
                                                            type="text"
                                                            placeholder="Enter BIN number"
                                                            name="binNo"
                                                            value={binNo}
                                                            onChange={handleChange}
                                                            className={"form-control"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <Col xs={12} md={3}>
                                                    <Form.Group controlId="country">
                                                        <Form.Label>Country{" "}<span
                                                            aria-hidden="true"
                                                            style={{
                                                                color: "red",
                                                                fontWeight: "bold",
                                                            }}
                                                        >
                                                            *
                                                        </span></Form.Label>
                                                        <Select
                                                            options={countryList}
                                                            name="country"
                                                            // value={country}
                                                            placeholder="Select Country Name"
                                                            onChange={(value) =>
                                                                handleCountryChange("countryList", value)
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={12} md={3}>
                                                    <Form.Group controlId="city">
                                                        <Form.Label>District/City{" "}<span
                                                            aria-hidden="true"
                                                            style={{
                                                                color: "red",
                                                                fontWeight: "bold",
                                                            }}
                                                        >
                                                            *
                                                        </span></Form.Label>
                                                        <Select
                                                            placeholder="Select District/City Name"
                                                            options={cityList}
                                                            name="city"
                                                            // value={city}
                                                            onChange={(value) =>
                                                                handleCityChange("cityList", value)
                                                            }
                                                        // isLoading={loading}
                                                        />
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={12} md={3}>
                                                    <Form.Group controlId="country">
                                                        <Form.Label>Area/Thana/Upazila/State{" "}<span
                                                            aria-hidden="true"
                                                            style={{
                                                                color: "red",
                                                                fontWeight: "bold",
                                                            }}
                                                        >
                                                            *
                                                        </span></Form.Label>
                                                        <Select
                                                            placeholder="Select Area/Thana/Upazila/State"
                                                            options={areaList}
                                                            name="area"
                                                            // value={area}
                                                            onChange={(value) =>
                                                                handleAreaChange("areaList", value)
                                                            }
                                                        // isLoading={loading}
                                                        />
                                                    </Form.Group>
                                                </Col>

                                                <div className="col-md-3 col-sm-12">
                                                    <div className="form-group ">
                                                        <label>
                                                            Zip Code{" "}
                                                            <span
                                                                aria-hidden="true"
                                                                style={{
                                                                    color: "red",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                *
                                                            </span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            placeholder="Enter Zip Code"
                                                            name="shopZipCode"
                                                            value={shopZipCode}
                                                            onChange={handleChange}
                                                            className={"form-control"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-4 col-sm-12">
                                                    <div className="form-group ">
                                                        <label>
                                                            Shop Description{" "}
                                                            <span
                                                                aria-hidden="true"
                                                                style={{
                                                                    color: "red",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                *
                                                            </span>
                                                        </label>
                                                        <textarea
                                                            rows={3}
                                                            type="text"
                                                            placeholder="Enter Shop Description Here"
                                                            name="shopDescription"
                                                            value={shopDescription}
                                                            onChange={handleChange}
                                                            className={"form-control"}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-sm-12">
                                                    <div className="form-group ">
                                                        <label>
                                                            Shop Address{" "}
                                                            <span
                                                                aria-hidden="true"
                                                                style={{
                                                                    color: "red",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                *
                                                            </span>
                                                        </label>
                                                        <textarea
                                                            rows={3}
                                                            type="text"
                                                            placeholder="Enter Shop Address Here"
                                                            name="shopAddress"
                                                            value={shopAddress}
                                                            onChange={handleChange}
                                                            className={"form-control"}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-sm-12">
                                                    <div className="row">
                                                        <div className="col-sm-12 col-md-9">
                                                            <div className="form-group file-area">
                                                                <label className="control_label">
                                                                    Shop Logo{" "}
                                                                    <span
                                                                        aria-hidden="true"
                                                                        style={{
                                                                            color: "red",
                                                                            fontWeight: "bold",
                                                                        }}
                                                                    >
                                                                        *
                                                                    </span>
                                                                </label>
                                                                <input
                                                                    type="file"
                                                                    name="shopLogoUrl"
                                                                    required="required"
                                                                    className="form-control"
                                                                    onChange={fileSelectedHandlerLogo}
                                                                />
                                                                {shopLogoUrl === "" ? (
                                                                    <div className="file-dummy-small">
                                                                        <div className="default">
                                                                            Select Shop Logo for Upload
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div className="file-dummy-small">
                                                                        <div className="success">
                                                                            Shop Logo Selected
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3 col-sm-12">
                                                            {showShopLogoFile ? (
                                                                <img
                                                                    src={showShopLogoFile}
                                                                    style={{
                                                                        height: "80px",
                                                                        width: "80px",
                                                                        marginTop: "20px",
                                                                    }}
                                                                    alt="showFile"
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={baseUrl.concat(shopLogoUrl)}
                                                                    style={{
                                                                        height: "80px",
                                                                        width: "80px",
                                                                        marginTop: "20px",
                                                                        display: "none",
                                                                    }}
                                                                    alt="ownerNidUrl"
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-4 col-sm-12">
                                                    <div className="row">
                                                        <div className="col-sm-12 col-md-12">
                                                            <div className="form-group file-area">
                                                                <label className="control_label">
                                                                    Owner NID{" "}
                                                                    <span
                                                                        aria-hidden="true"
                                                                        style={{
                                                                            color: "red",
                                                                            fontWeight: "bold",
                                                                        }}
                                                                    >
                                                                        *
                                                                    </span>
                                                                </label>
                                                                <div className="input_file__section">
                                                                    <input
                                                                        className="career_post_form_input_file"
                                                                        type="file"
                                                                        name="NIDFront"
                                                                        title={
                                                                            ownerNidUrl
                                                                                ? ownerNidName
                                                                                : 'Select NID File to Upload'
                                                                        }
                                                                        accept=".pdf,.doc,.docx,.png,.jpg,.jpeg,.PDF,.DOC,.DOCX,.PNG,.JPG,.JPEG"
                                                                        onChange={(e) => fileSelectedNIDHandler(e)}
                                                                    />

                                                                    {ownerNidUrl !== null ? (
                                                                        <>
                                                                            <button
                                                                                className="career_input__cross_btn"
                                                                                title="Click to Remove Selected File."
                                                                                onClick={() => {
                                                                                    setOwnerNidUrl(null);
                                                                                }}
                                                                            >
                                                                                <span>X</span>
                                                                            </button>
                                                                        </>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-sm-12">
                                                    <div className="row">
                                                        <div className="col-sm-12 col-md-12">
                                                            <div className="form-group file-area">
                                                                <label className="control_label">
                                                                    Business Document{" "}
                                                                    <span
                                                                        aria-hidden="true"
                                                                        style={{
                                                                            color: "red",
                                                                            fontWeight: "bold",
                                                                        }}
                                                                    >
                                                                        *
                                                                    </span>
                                                                </label>
                                                                <div className="input_file__section">
                                                                    <input
                                                                        className="career_post_form_input_file"
                                                                        type="file"
                                                                        name="businessDoc"
                                                                        title={
                                                                            businessDocUrl
                                                                                ? businessDocName
                                                                                : 'Select Business Document to Upload'
                                                                        }
                                                                        accept=".pdf,.doc,.docx,.png,.jpg,.jpeg,.PDF,.DOC,.DOCX,.PNG,.JPG,.JPEG"
                                                                        onChange={(e) => fileSelectedBusinessDocHandler(e)}
                                                                    />

                                                                    {businessDocUrl !== null ? (
                                                                        <>
                                                                            <button
                                                                                className="career_input__cross_btn"
                                                                                title="Click to Remove Selected File."
                                                                                onClick={() => {
                                                                                    setBusinessDocUrl(null);
                                                                                }}
                                                                            >
                                                                                <span>X</span>
                                                                            </button>
                                                                        </>
                                                                    ) : null}

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-sm-12">
                                                    <div className="row">
                                                        <div className="col-sm-12 col-md-12">
                                                            <div className="form-group file-area">
                                                                <label className="control_label">
                                                                    Trade License{" "}
                                                                    <span
                                                                        aria-hidden="true"
                                                                        style={{
                                                                            color: "red",
                                                                            fontWeight: "bold",
                                                                        }}
                                                                    >
                                                                        *
                                                                    </span>
                                                                </label>
                                                                <div className="input_file__section">
                                                                    <input
                                                                        className="career_post_form_input_file"
                                                                        type="file"
                                                                        name="TradeLicense"
                                                                        title={
                                                                            ownerTradeLicenseUrl
                                                                                ? ownerTradeLicenseName
                                                                                : 'Select Trade License to Upload'
                                                                        }
                                                                        accept=".pdf,.doc,.docx,.png,.jpg,.jpeg,.PDF,.DOC,.DOCX,.PNG,.JPG,.JPEG"
                                                                        onChange={(e) => fileSelectedTradeLicenseHandler(e)}
                                                                    />

                                                                    {ownerTradeLicenseUrl !== null ? (
                                                                        <>
                                                                            <button
                                                                                className="career_input__cross_btn"
                                                                                title="Click to Remove Selected File."
                                                                                onClick={() => {
                                                                                    setOwnerTradeLicenseUrl(null);
                                                                                }}
                                                                            >
                                                                                <span>X</span>
                                                                            </button>
                                                                        </>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-4 col-sm-12">
                                                    <div className="row">
                                                        <div className="col-sm-12 col-md-12">
                                                            <div className="form-group file-area">
                                                                <label className="control_label">
                                                                    BIN Document{" "}
                                                                </label>
                                                                <div className="input_file__section">
                                                                    <input
                                                                        className="career_post_form_input_file"
                                                                        type="file"
                                                                        name="BINDocument"
                                                                        title={
                                                                            ownerBINUrl
                                                                                ? ownerBINName
                                                                                : 'Select BIN Document to Upload'
                                                                        }
                                                                        accept=".pdf,.doc,.docx,.png,.jpg,.jpeg,.PDF,.DOC,.DOCX,.PNG,.JPG,.JPEG"
                                                                        onChange={(e) => fileSelectedBINHandler(e)}
                                                                    />

                                                                    {ownerBINUrl !== null ? (
                                                                        <>
                                                                            <button
                                                                                className="career_input__cross_btn"
                                                                                title="Click to Remove Selected File."
                                                                                onClick={() => {
                                                                                    setOwnerBINUrl(null);
                                                                                }}
                                                                            >
                                                                                <span>X</span>
                                                                            </button>
                                                                        </>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-sm-12">
                                                    <div className="row">
                                                        <div className="col-sm-12 col-md-12">
                                                            <div className="form-group file-area">
                                                                <label className="control_label">
                                                                    TIN Document{" "}
                                                                </label>
                                                                <div className="input_file__section">
                                                                    <input
                                                                        className="career_post_form_input_file"
                                                                        type="file"
                                                                        name="TINDoc"
                                                                        title={
                                                                            ownerTINDocUrl
                                                                                ? ownerTINDocName
                                                                                : 'Select TIN Document to Upload'
                                                                        }
                                                                        accept=".pdf,.doc,.docx,.png,.jpg,.jpeg,.PDF,.DOC,.DOCX,.PNG,.JPG,.JPEG"
                                                                        onChange={(e) => fileSelectedTINHandler(e)}
                                                                    />

                                                                    {ownerTINDocUrl !== null ? (
                                                                        <>
                                                                            <button
                                                                                className="career_input__cross_btn"
                                                                                title="Click to Remove Selected File."
                                                                                onClick={() => {
                                                                                    setOwnerTINDocUrl(null);
                                                                                }}
                                                                            >
                                                                                <span>X</span>
                                                                            </button>
                                                                        </>
                                                                    ) : null}

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-sm-12">
                                                    <div className="row">
                                                        <div className="col-sm-12 col-md-12">
                                                            <div className="form-group file-area">
                                                                <label className="control_label">
                                                                    DBID Document{" "}
                                                                </label>
                                                                <div className="input_file__section">
                                                                    <input
                                                                        className="career_post_form_input_file"
                                                                        type="file"
                                                                        name="DBIDDoc"
                                                                        title={
                                                                            ownerDBIDDocUrl
                                                                                ? ownerDBIDDocName
                                                                                : 'Select DBID Document to Upload'
                                                                        }
                                                                        accept=".pdf,.doc,.docx,.png,.jpg,.jpeg,.PDF,.DOC,.DOCX,.PNG,.JPG,.JPEG"
                                                                        onChange={(e) => fileSelectedDBIDHandler(e)}
                                                                    />

                                                                    {ownerDBIDDocUrl !== null ? (
                                                                        <>
                                                                            <button
                                                                                className="career_input__cross_btn"
                                                                                title="Click to Remove Selected File."
                                                                                onClick={() => {
                                                                                    setOwnerDBIDDocUrl(null);
                                                                                }}
                                                                            >
                                                                                <span>X</span>
                                                                            </button>
                                                                        </>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-2 col-sm-12">
                                                    <div className="row">
                                                        <div className="col-sm-12 col-md-12">
                                                            <div className="form-group file-area">
                                                                <label className="control_label">
                                                                    Additional Documents{" "}
                                                                </label>


                                                                <div className="input_file__section" style={{ height: '38px' }}>
                                                                    {additionalDocNum < 5 && <div className="btn btn-primary" onClick={() => {
                                                                        handleAddAdditional();
                                                                    }}>Add</div>}

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>


                                                <div className="col-md-10 col-sm-12">
                                                    {
                                                        additionalDocumentsArray.map((x, i) => {
                                                            return (

                                                                <><div className="row">
                                                                    <div className="col-md-1 col-sm-12">
                                                                        {i + 1}
                                                                    </div>
                                                                    <div className="col-md-2 col-sm-12">
                                                                        <label className="control_label">
                                                                            Document Name{" "}<span
                                                                                aria-hidden="true"
                                                                                style={{
                                                                                    color: "red",
                                                                                    fontWeight: "bold",
                                                                                }}
                                                                            >
                                                                                *
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-md-3 col-sm-12">
                                                                        <div className="form-group">
                                                                            <input
                                                                                required
                                                                                type="text"
                                                                                placeholder="Enter Document Name Here"
                                                                                className={"form-control"}
                                                                                name="docName"
                                                                                onChange={(e) => handleOnBlur(e, i)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-1 col-sm-12">
                                                                        <label className="control_label">
                                                                            Upload{" "}<span
                                                                                aria-hidden="true"
                                                                                style={{
                                                                                    color: "red",
                                                                                    fontWeight: "bold",
                                                                                }}
                                                                            >
                                                                                *
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-md-4 col-sm-12">
                                                                        <div className="row">
                                                                            <div className="col-sm-12 col-md-12">
                                                                                <div className="form-group file-area">

                                                                                    <div className="input_file__section">
                                                                                        <input
                                                                                            required
                                                                                            className="career_post_form_input_file"
                                                                                            type="file"
                                                                                            name="docPath"
                                                                                            accept=".pdf,.doc,.docx,.png,.jpg,.jpeg,.PDF,.DOC,.DOCX,.PNG,.JPG,.JPEG"
                                                                                            onChange={(e) => additionalFileSelectedHandler(e, i)}
                                                                                        />

                                                                                        {ownerDBIDDocUrl !== null ? (
                                                                                            <>
                                                                                                <button
                                                                                                    className="career_input__cross_btn"
                                                                                                    title="Click to Remove Selected File."
                                                                                                    onClick={() => {
                                                                                                        setOwnerDBIDDocUrl(null);
                                                                                                    }}
                                                                                                >
                                                                                                    <span>X</span>
                                                                                                </button>
                                                                                            </>
                                                                                        ) : null}
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-1 col-sm-12">

                                                                        <div className="btn btn-danger" onClick={(e) => {
                                                                            handleRemoveAdditional(e, i);
                                                                        }}>Remove</div>
                                                                    </div>
                                                                </div> </>
                                                            );
                                                        }
                                                        )}
                                                </div>
                                            </div>

                                            <div className="form-footer ">
                                                <div className="form-group row">
                                                    <div className="text-center">
                                                        <div className="btn-group text-center">

                                                            {createSellerDataLoading ? <div style={{ textAlign: "center" }}>
                                                                <Loader />
                                                            </div> : <>
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-success"
                                                                    onClick={saveSeller}
                                                                >
                                                                    Create
                                                                </button>
                                                                <Link to="/Home">
                                                                    <button
                                                                        className="btn btn-danger"
                                                                        style={{ cursor: "pointer" }}
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                </Link>
                                                            </>}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default CreateNewSellerContainer