import { getDateWiseSalesSummaryNBRRecord } from "../../../store/actions/accountsAction";

export const dateWiseSalesSummaryNBRApiCall = (
  dispatch,
  startDate,
  endDate,
  paymentStatus,
  shopIds,
  paymentMethod
) => {
  dispatch(
    getDateWiseSalesSummaryNBRRecord(
      startDate ? startDate : "",
      endDate ? endDate : "",
      paymentStatus ? paymentStatus : "",
      // paymentMethod ? paymentMethod : "",
      shopIds ? shopIds : 0,
    )
  );
};

// export const dateWiseSalesDetailExcel = (dataFromAPI) => {
//   let formattedData = [];
//   dataFromAPI &&
//     dataFromAPI.map((data, i) => {
//       let singleData = {
//         "Vendor Name": data?.shopName ? data?.shopName.toString() : "-",
//         "Order Date": data?.orderDate
//           ? data?.orderDate.toString().substring(0, 10)
//           : "-",
//         "Order No": data?.orderNo ? data?.orderNo.toString() : "-",
//         "Product Title": data?.productTitle
//           ? data?.productTitle.toString()
//           : "-",
//         "Seller Product SKU": data?.sellerProductSku
//           ? data?.sellerProductSku.toString()
//           : "-",
//         Variants: data?.productVariant ? data?.productVariant.toString() : "-",
//         "DP Price": data?.dpPrice ? parseFloat(data?.dpPrice) : 0,
//         "Unit Price": data?.productPrice ? parseFloat(data?.productPrice) : 0,
//         Qty: data?.productQuantity ? parseInt(data?.productQuantity) : 0,
//         "Subtotal Amount":
//           data?.productPrice && data?.productQuantity
//             ? parseFloat(parseInt(data?.productQuantity) * data?.productPrice)
//             : 0,
//         "Discount (%)": data?.discountPercent ? data?.discountPercent : 0,
//         "Discount Amount": data?.discountAmount ? data?.discountAmount : 0,

//         "Voucher Code": data?.voucherCode ? data?.voucherCode.toString() : "-",
//         "Voucher (%)": data?.voucherPercentage
//           ? parseFloat(data?.voucherPercentage)
//           : 0,
//         "Voucher Amount": data?.voucherAmount
//           ? parseFloat(data?.voucherAmount)
//           : 0,
//         "Net Amount": data?.netAmount ? parseFloat(data?.netAmount) : 0,
//         "Commission (%)": data?.commissionPercentage
//           ? data?.commissionPercentage
//           : 0,
//         "Commission Amount": data?.commissionAmount
//           ? data?.commissionAmount
//           : 0,
//         Burn: data?.burnAmount ? data?.burnAmount : 0,
//         "Vendor Payable Amount": data?.vendorPayableAmount
//           ? data?.vendorPayableAmount
//           : 0,
//         "Shipping Charge": data?.totalShippingCharge
//           ? data?.totalShippingCharge
//           : 0,
//         "Payment Method": data?.paymentMethodName
//           ? data?.paymentMethodName.toString()
//           : "-",
//         "Payment Status": data?.paymentStatus
//           ? data?.paymentStatus.toString()
//           : "-",
//         "Transaction No.": data?.tranNo ? data?.tranNo.toString() : "-",
//         "Transaction Date": data?.paymentDate
//           ? data?.paymentDate.toString().substring(0, 10)
//           : "-",
//       };
//       formattedData.push(Object.assign({}, singleData));
//     });
//   return formattedData;
// };

export const dateWiseSalesSummaryNBRReportGrouping = (
  dataFromAPI,
  setIsDataFormatting
) => {
  setIsDataFormatting(true);
  const groupedData = {};
  for (const obj of dataFromAPI) {
    const fieldValue = obj.paymentMethodName;
    if (!groupedData[fieldValue]) {
      groupedData[fieldValue] = [];
    }
    groupedData[fieldValue].push(obj);
  }

  const uniqueValues = [
    ...new Set(dataFromAPI.map((obj) => obj.paymentMethodName)),
  ];

  const grandTotal = dataFromAPI.reduce(
    (accumulator, currentValue) =>
      accumulator + parseFloat(currentValue?.netAmount),
    0
  );

  setIsDataFormatting(false);
  return [groupedData, uniqueValues, grandTotal];
};