/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { Switch } from "antd";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import MultiSelectShop from "../../component/molecules/MultiSelectShop";
import baseUrl from "../../utils/baseUrl";
import { Paginator } from "../paginator/Paginator";
import LoadingCard from "../shared/LoadingCard";

const productStatusArr = [
  { status: "Active", code: "Y" },
  { status: "Inactive", code: "N" },
];

export const ProductListTable = ({
  currentLocation,
  product_status,
  product_list,
  setViewDetailsProductId,
  handleItemPerPage,
  handleCurrentPage,
  currentPage,
  setCurrentPage,
  totalPage,
  setTotalPage,
  itemPerPage,

  totalItems,
  setTotalItems,
  handleInActiveProduct,
  handleActiveProduct,
  currency,
  isApproved,
  setSearchKeyword,
  searchKeyword,

  categories,
  setCategory,
  category,
  brands,
  setBrand,
  brand,

  loading,

  setStatus,
  status,

  vendor,
  setVendor,

  setIsFilter,

}) => {

  const [productImageHoverImage, setProductImageHoverImage] = useState(null);
  const [urlStatus, setUrlStatus] = useState(null);

  const history = useHistory();

  const state = history?.location?.state;

  useEffect(() => {
    if (
      window.location.origin.toString().includes("salecenter.saralifestyle")
    ) {
      setUrlStatus("Live");
    } else if (
      window.location.origin.toString().includes("192.168.27.237:92")
    ) {
      setUrlStatus("Dev");
    } else {
      setUrlStatus("Local");
    }
  }, [window.location.origin]);

  const onIndexTemplate = (rowData, props) => {
    return props.rowIndex + 1;
  };
  const imageBodyTemplate = (rowData) => {
    const handleMouseEnter = (data) => {
      setProductImageHoverImage(data.thumbnailImage);
    };
    return (
      <div style={{ position: "relative" }}>
        <img
          onMouseEnter={() => handleMouseEnter(rowData)}
          className="product_list__img"
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "10px",
            cursor: "pointer",
            border: "1px solid #ddd",
            objectFit: "contain",
          }}
          src={baseUrl.concat(rowData.thumbnailImage)}
          alt="IMG"
        />
        <div className="product_list__tooltip">
          <div className="tooltip__img">
            <img
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "contain",
              }}
              src={baseUrl.concat(productImageHoverImage)}
              alt="product image"
            />
          </div>
        </div>
        <div className="image_tooltip__indicator"></div>
      </div>
    );
  };
  const detailsBodyTemplate = (rowData) => {
    return (
      <>
        <Button
          label="View Details"
          className="p-button-success"
          data-toggle="modal"
          data-target="#exampleModalCenter"
          onClick={() => setViewDetailsProductId(rowData.productId)}
        />
      </>
    );
  };


  const editBodyTemplate = (rowData) => {
    const handleEditProduct = () => {
      currentLocation === "/manage-products"
        ? history.push({
          pathname: `/EditProduct/${rowData.productId}`,
          state: {
            ...state?.state,
            id: rowData.productId,
            currency: currency,
            isApproved: isApproved,
            currentPage: currentPage,
            itemPerPage: itemPerPage,
            navigateBack: history?.location?.pathname + history.location.search,
          },
        })
        : history.push({
          pathname: `/EditSellerProduct/${rowData.productId}`,
          state: {
            ...state?.state,
            id: rowData.productId,
            currency: currency,
            isApproved: isApproved,
            currentPage: currentPage,
            itemPerPage: itemPerPage,
            navigateBack: history?.location?.pathname + history.location.search
          },
        });
    };
    return (
      <React.Fragment>
        <span className="p-buttonset verified-button">
          <Button
            onClick={() => handleEditProduct()}
            label="Edit"
            className="p-button-success"
          />
        </span>
      </React.Fragment>
    );
  };
  const productNameTemplate = (rowData) => {
    return (
      <>
        {rowData.productStatus === "Y" && rowData.isApprove === "Y" ? (
          <a
            href={
              urlStatus == "Live"
                ? `https://saralifestyle.com/product/${rowData.productId}/${rowData.productUrl}/`
                : urlStatus == "Dev"
                  ? `https://saraemart.com/product/${rowData.productId}/${rowData.productUrl}/`
                  : `http://localhost:3000/product/${rowData.productId}/${rowData.productUrl}/`
            }
            target="_blank"
          >
            {rowData.productName}
          </a>
        ) : (
          rowData.productName
        )}
      </>
    );
  };

  useEffect(() => {
    if (product_list?.headers?.pagination) {
      var paginated_data_to_parse = product_list?.headers?.pagination;
      const paginated_data = JSON.parse(paginated_data_to_parse);
      setTotalPage(paginated_data.totalPages);
      setTotalItems(paginated_data.totalItems);
    }
  }, [
    product_list?.headers?.pagination,
  ]);

  const changeStatusBodyTemplate = (rowData) => {
    return (
      <>
        <Switch
          style={{
            backgroundColor: rowData?.productStatus === 'Y' ? '#1f5da0' : '',
          }}
          checked={rowData?.productStatus === 'Y' ? true : false}
          onClick={rowData?.productStatus === 'Y' ? () => handleInActiveProduct(rowData) : () => handleActiveProduct(rowData)}
        />
      </>
    );
  }

  return (
    <>
      <div
        className="table-header"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space between",
          padding: "8px 16px 8px 16px",
          borderBottom: "1px solid #ddd",
          borderTop: "1px solid #ddd",
        }}
      >
        <p style={{ fontSize: "16px", fontWeight: "600", margin: "0" }}>
          {product_status === "pending" ? (
            <span>Pending</span>
          ) : product_status === "approved" ? (
            <span>Approved</span>
          ) : product_status === "lowstock" ? (
            <span>Low Stock</span>
          ) : (
            <span>Rejected</span>
          )}{" "}
          Products List
        </p>
        <div style={{ display: "flex" }}>
          <div
            style={{ margin: "0 5px", width: "240px", position: "relative" }}
          >
            <InputText
              style={{
                width: "100%",
              }}
              className="manage_product__search"
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
              placeholder="Keyword"
            />
            {searchKeyword !== "" ? (
              <button
                style={{
                  position: "absolute",
                  top: "6px",
                  right: "8px",
                  borderRadius: "100%",
                  border: "1px solid #ced4da",
                  height: "25px",
                  width: "25px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => {
                  setSearchKeyword("");
                  // setSearchKeywordApi("");
                }}
              >
                <i className="pi pi-times"></i>
              </button>
            ) : null}
          </div>
          <div
            style={{ margin: "0 5px", width: "300px", position: "relative" }}
          >
            <Dropdown
              style={{
                width: "100%",
              }}
              value={category}
              options={categories}
              filter
              onChange={(e) => setCategory(e.value)}
              optionLabel="breadcrumbCategory"
              placeholder="Select a Category"
            />
            {category !== null ? (
              <button
                style={{
                  position: "absolute",
                  top: "6px",
                  right: "8px",
                  borderRadius: "100%",
                  border: "1px solid #ced4da",
                  height: "25px",
                  width: "25px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => {
                  setCategory(null);
                  // setCategoryApi("");
                }}
              >
                <i className="pi pi-times"></i>
              </button>
            ) : null}
          </div>
          <div
            style={{ margin: "0 5px", width: "175px", position: "relative" }}
          >
            <Dropdown
              style={{
                width: "100%",
              }}
              value={brand}
              options={brands}
              filter
              showClear
              onChange={(e) => setBrand(e.value)}
              optionLabel="brandName"
              placeholder="Select a Brand"
            />
            {brand !== null ? (
              <button
                style={{
                  position: "absolute",
                  top: "6px",
                  right: "8px",
                  borderRadius: "100%",
                  border: "1px solid #ced4da",
                  height: "25px",
                  width: "25px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => {
                  setBrand(null);
                  // setBrandIdApi("");
                }}
              >
                <i className="pi pi-times"></i>
              </button>
            ) : null}
          </div>

          <div
            style={{
              margin: "0 2px",
              width: "200px",
              position: "relative",
            }}
          >
            <MultiSelectShop selectShop={vendor} setSelectShop={setVendor} />
          </div>

          <div
            style={{ margin: "0 5px", width: "175px", position: "relative" }}
          >
            <Dropdown
              style={{
                width: "100%",
              }}
              value={status}
              options={productStatusArr}
              onChange={(e) => setStatus(e.value)}
              optionLabel="status"
              placeholder="Select a Status"
            />
            {status !== null ? (
              <button
                style={{
                  position: "absolute",
                  top: "6px",
                  right: "8px",
                  borderRadius: "100%",
                  border: "1px solid #ced4da",
                  height: "25px",
                  width: "25px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => {
                  setStatus(null);
                  // setStatusCodeApi("");
                }}
              >
                <i className="pi pi-times"></i>
              </button>
            ) : null}
          </div>
          <Button
            // onClick={() => searchProductList()}
            onClick={() => {
              setIsFilter(true);
              setCurrentPage(1);
            }}
            style={{ marginLeft: "5px" }}
            label="Search"
          />
        </div>
      </div>
      {loading ? (
        <LoadingCard count={1} />
      ) : (
        <DataTable
          className="product_list_table"
          responsiveLayout="scroll"
          value={product_list.data}
        >
          <Column field="Index" header="SN" body={onIndexTemplate} />
          <Column
            field="productName"
            header="Product Name"
            body={productNameTemplate}
            style={{ maxWidth: '200px', wordWrap: "break-word", textAlign: 'justify' }}
            sortable
          />
          <Column field="shopName" header="Shop Name" sortable />
          <Column field="sku" header="Product Style / SKU" style={{ maxWidth: '200px', wordWrap: "break-word" }} sortable />
          <Column field="categoryName" header="Category" sortable />
          <Column field="brandName" header="Brand" sortable />
          <Column
            sortField="thumbnailImage"
            header="Image"
            body={imageBodyTemplate}
          />
          <Column field="maxPrice" header="Price" sortable />
          <Column field="view" header="Product Details" body={detailsBodyTemplate} style={{ minWidth: '150px' }} />
          {(product_status === "approved" || product_status === "lowstock") ? (
            <Column
              field="productStatus"
              header="Status"
              body={changeStatusBodyTemplate}
              sortable
            />
          ) : null}
          {(product_status === "pending" || product_status === "rejected") &&
            currentLocation === "/manage-products" ? null : (
            <Column field="Edit" header="Edit" body={editBodyTemplate} />
          )}
        </DataTable>
      )}
      <Paginator
        totalPage={totalPage}
        currentPage={currentPage}
        itemPerPage={itemPerPage}
        totalItems={totalItems}
        items={product_list.data}
        itemsPerPageOptions={[30, 60, 90, 100, 200, 300]}
        handleItemPerPage={handleItemPerPage}
        handleCurrentPage={handleCurrentPage}
      />
    </>
  );
};
