import React from "react";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";
import { Loader } from "../../containers";
import baseUrl from "../../utils/baseUrl";

const CreateCampaign = (props) => {
  return (
    <div className="page-wrapper">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="panel panel-success">
              <div className="panel-heading">
                {" "}
                Create Campaign{" "}
                <span style={{ float: "right" }}>
                  <Link to="/CampaignList?status=active">
                    <Icon.List className="text-light" />
                  </Link>
                </span>
              </div>
              <div className="panel-wrapper collapse in" aria-expanded="true">
                <div className="panel-body">
                  <form className="form-horizontal">
                    <div className="form-body">
                      <div className="row">
                        <div className="col-md-6 col-sm-12">
                          <div className="form-group">
                            <label className="control_label">
                              Campaign Name{" "}
                              <span
                                aria-hidden="true"
                                style={{
                                  color: "red",
                                  fontWeight: "bold",
                                }}
                              >
                                *
                              </span>
                            </label>
                            <input
                              type="text"
                              placeholder="Enter Campaign Name"
                              name="campaignName"
                              value={props.campaignName}
                              onChange={props.handleChange}
                              className={
                                props.errorCampaignName.length !== 0
                                  ? "errorClass form-control"
                                  : "form-control" && "form-control"
                              }
                            />
                            {props.errorCampaignName && (
                              <span className="error">
                                {props.errorCampaignName}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6 col-sm-12">
                          <div className="form-group">
                            <label className="control_label">
                              Display Order{" "}
                              <span
                                aria-hidden="true"
                                style={{
                                  color: "red",
                                  fontWeight: "bold",
                                }}
                              >
                                *
                              </span>
                            </label>
                            <input
                              type="number"
                              placeholder="Enter Display Order"
                              name="displayOrder"
                              value={props.displayOrder}
                              onChange={props.handleChange}
                              className={
                                props.errorDisplayOrder.length !== 0
                                  ? "errorClass form-control"
                                  : "form-control" && "form-control"
                              }
                            />
                            {props.errorDisplayOrder && (
                              <span className="error">
                                {props.errorDisplayOrder}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-3 col-sm-12">
                          <div className="form-group">
                            <label className="control_label">
                              Campaign Registration Start{" "}
                              <span
                                aria-hidden="true"
                                style={{
                                  color: "red",
                                  fontWeight: "bold",
                                }}
                              >
                                *
                              </span>
                            </label>
                            <input
                              id=""
                              type="datetime-local"
                              placeholder="Select End Date & Time"
                              className="form-control"
                              name="campaignRegStartDate"
                              value={props?.campaignRegStartDate}
                              onChange={props.handleChange}
                            // onChange={(e) => setDeadline(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                          <div className="form-group">
                            <label className="control_label">
                              Campaign Registration End{" "}
                              <span
                                aria-hidden="true"
                                style={{
                                  color: "red",
                                  fontWeight: "bold",
                                }}
                              >
                                *
                              </span>
                            </label>
                            <input
                              id=""
                              type="datetime-local"
                              placeholder="Select End Date & Time"
                              className="form-control"
                              name="campaignRegEndDate"
                              value={props?.campaignRegEndDate}
                              onChange={props.handleChange}
                            // onChange={(e) => setDeadline(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-md-3 col-sm-12">
                          <div className="form-group">
                            <label className="control_label">
                              Campaign Start Date{" "}
                              <span
                                aria-hidden="true"
                                style={{
                                  color: "red",
                                  fontWeight: "bold",
                                }}
                              >
                                *
                              </span>
                            </label>
                            <input
                              id=""
                              type="datetime-local"
                              placeholder="Select End Date & Time"
                              className="form-control"
                              name="campaignStartDate"
                              value={props?.campaignStartDate}
                              onChange={props.handleChange}
                            // onChange={(e) => setDeadline(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-md-3 col-sm-12">
                          <div className="form-group">
                            <label className="control_label">
                              Campaign End Date{" "}
                              <span
                                aria-hidden="true"
                                style={{
                                  color: "red",
                                  fontWeight: "bold",
                                }}
                              >
                                *
                              </span>
                            </label>
                            <input
                              id=""
                              type="datetime-local"
                              placeholder="Select End Date & Time"
                              className="form-control"
                              name="campaignEndDate"
                              value={props?.campaignEndDate}
                              onChange={props.handleChange}
                            // onChange={(e) => setDeadline(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-3 col-sm-12">
                          <div className="form-group">
                            <label className="control_label">
                              Campaign Minimum Discount Percent{" "}
                              <span
                                aria-hidden="true"
                                style={{
                                  color: "red",
                                  fontWeight: "bold",
                                }}
                              >
                                *
                              </span>
                            </label>
                            <input
                              type="number"
                              placeholder="Enter Minimum Discount Percent"
                              name="campaignMinDisPer"
                              value={props.campaignMinDisPer}
                              onChange={props.handleChange}
                              className={
                                props.errorCampaignMinDisPer.length !== 0
                                  ? "errorClass form-control"
                                  : "form-control" && "form-control"
                              }
                            />
                            {props.errorCampaignMinDisPer && (
                              <span className="error">
                                {props.errorCampaignMinDisPer}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-3 col-sm-12">
                          <div className="form-group">
                            <label className="control_label">
                              Campaign Maximum Discount Percent{" "}
                              <span
                                aria-hidden="true"
                                style={{
                                  color: "red",
                                  fontWeight: "bold",
                                }}
                              >
                                *
                              </span>
                            </label>
                            <input
                              type="number"
                              placeholder="Enter Maximum Discount Percent"
                              name="campaignMaxDisPer"
                              value={props.campaignMaxDisPer}
                              onChange={props.handleChange}
                              className={
                                props.errorCampaignMaxDisPer.length !== 0
                                  ? "errorClass form-control"
                                  : "form-control" && "form-control"
                              }
                            />
                            {props.errorCampaignMaxDisPer && (
                              <span className="error">
                                {props.errorCampaignMaxDisPer}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-3 col-sm-12">
                          <div className="form-group">
                            <label className="control_label">
                              Campaign Minimum Discount Amount{" "}
                              <span
                                aria-hidden="true"
                                style={{
                                  color: "red",
                                  fontWeight: "bold",
                                }}
                              >
                                *
                              </span>
                            </label>
                            <input
                              type="number"
                              placeholder="Enter Minimum Discount Amount"
                              name="campaignMinDisAmt"
                              value={props.campaignMinDisAmt}
                              onChange={props.handleChange}
                              className={
                                props.errorCampaignMinDisAmt.length !== 0
                                  ? "errorClass form-control"
                                  : "form-control" && "form-control"
                              }
                            />
                            {props.errorCampaignMinDisAmt && (
                              <span className="error">
                                {props.errorCampaignMinDisAmt}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-3 col-sm-12">
                          <div className="form-group">
                            <label className="control_label">
                              Campaign Maximum Discount Amount{" "}
                              <span
                                aria-hidden="true"
                                style={{
                                  color: "red",
                                  fontWeight: "bold",
                                }}
                              >
                                *
                              </span>
                            </label>
                            <input
                              type="number"
                              placeholder="Enter Maximum Discount Amount"
                              name="campaignMaxDisAmt"
                              value={props.campaignMaxDisAmt}
                              onChange={props.handleChange}
                              className={
                                props.errorCampaignMaxDisAmt.length !== 0
                                  ? "errorClass form-control"
                                  : "form-control" && "form-control"
                              }
                            />
                            {props.errorCampaignMaxDisAmt && (
                              <span className="error">
                                {props.errorCampaignMaxDisAmt}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6 col-sm-12">
                          <div className="form-group">
                            <label className="control_label">
                              Description
                            </label>
                            <textarea
                              rows={4}
                              type='text'
                              placeholder='Enter Campaign Description'
                              name='campaignDescription'
                              value={props.campaignDescription}
                              onChange={props.handleChange}
                              className={'form-control'}
                            />
                          </div>
                        </div>

                        {props.displayOrder != 0 && <div className="col-md-6 col-sm-12">
                          <div className="form-group file-area">
                            <label className="control_label">
                              Campaign Banner{" "}
                              <span
                                aria-hidden="true"
                                style={{
                                  color: "red",
                                  fontWeight: "bold",
                                }}
                              >
                                *
                              </span>
                            </label>
                            <div className="row">
                              <div className="col-sm-6">
                                <input
                                  type="file"
                                  accept="image/*"
                                  name="campaignBannerUrl"
                                  required="required"
                                  className="form-control"
                                  onChange={props.campaignBannerUrlHandler}
                                  disabled={props.displayOrder === "" || props.displayOrder == 0}
                                />

                                {props.campaignBannerUrl === "" ? (
                                  <div className="file-dummy">
                                    {props.displayOrder === "" || props.displayOrder == 0 ?
                                      <div className="default">
                                        Please Insert Display Order First
                                      </div> : props.displayOrder % 2 !== 0 ?
                                        <div className="default">
                                          Please Upload Campaign Banner <br />
                                          Resolution: ( 1611 x 300 )
                                        </div> : <div className="default">
                                          Please Upload Campaign Banner <br />
                                          Resolution: ( 596 x 596 )
                                        </div>}
                                  </div>
                                ) : (
                                  <div className="file-dummy">
                                    <div className="success">
                                      Campaign Banner Uploaded Successfully
                                    </div>
                                  </div>
                                )}
                              </div>

                              <div className="col-md-6 col-sm-6">
                                {props.showImage ? (
                                  <>
                                    {props.displayOrder % 2 !== 0 ? <img
                                      src={props.showImage}
                                      className="thumb-md product-image"
                                      style={{ width: "350px", height: "100px" }}
                                      alt="showImage"
                                    /> : <img
                                      src={props.showImage}
                                      className="thumb-md product-image"
                                      style={{ width: "100px", height: "100px" }}
                                      alt="showImage"
                                    />}
                                  </>

                                ) : (
                                  <img
                                    src={baseUrl.concat(props.campaignBannerUrl)}
                                    className="thumb-md product-image"
                                    style={{
                                      width: "100px",
                                      height: "100px",
                                      display: "none",
                                    }}
                                    alt="featureImage"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>}


                      </div>

                      <div className="row">
                        <div className="col-md-12 col-sm-12">
                          <div className="form-group">
                            <label className="control_label">Active </label>
                            <div className="checkbox checkbox-success">
                              <input
                                id="isActive"
                                type="checkbox"
                                name="isActive"
                                checked={props.isActive}
                                onChange={props.handleParentCheck}
                              />
                              <label htmlFor="isActive"> &nbsp;Yes </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-footer ">
                        <div className="form-group row">
                          <div className="text-center">
                            <div className="btn-group text-center">
                              {(props?.campaignLoading) ?
                                <div style={{ width: '65%', textAlign: 'center' }}>
                                  <Loader />
                                </div>
                                : <>
                                  <button
                                    type="submit"
                                    className="btn btn-success"
                                    onClick={props.saveCampaign}
                                  >
                                    Create
                                  </button>
                                  <Link to="/CampaignList?status=active">
                                    <button
                                      className="btn btn-danger"
                                      style={{ cursor: "pointer" }}
                                    >
                                      Cancel
                                    </button>
                                  </Link>
                                </>}

                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateCampaign