import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import takaSymbol from "../../../assets/images/taka_symbol.svg";
import "./SingleDisplayCard.css";

const SingleDisplayCard = ({
  isPrice,
  c1,
  c2,
  text,
  number,
  fontsz,
  linkNavigate = "/",
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <NavLink to={linkNavigate}>
      <div
        className="SingleDisplayCard_container"
        style={{
          background: isHovered
            ? `linear-gradient(63deg, ${c1} 0%, ${c2} 100%)`
            : `linear-gradient(117deg, ${c1} 0%, ${c2} 100%)`,
        }}
        onMouseEnter={handleHover}
        onMouseLeave={handleMouseLeave}
      >
        <div className="SingleDisplayCard_Text">{text}</div>
        <div>
          {isPrice ? (
            <div className="SingleDisplayCard_amount_container">
              <div className="SingleDisplayCard_amount_symbol">
                <img src={takaSymbol} alt="" />
              </div>
              <div
                className="SingleDisplayCard_amount"
                style={{ fontSize: `${fontsz}px` }}
              >
                {number?.toLocaleString("en-IN")}
              </div>
            </div>
          ) : (
            <div
              className="SingleDisplayCard_number"
              style={{ fontSize: `${fontsz}px` }}
            >
              {number?.toLocaleString("en-IN")}
            </div>
          )}
        </div>
      </div>
    </NavLink>
  );
};

export default SingleDisplayCard;
