import {
    GET_PO_PRODUCT_LIST,
    GET_PO_PRODUCT_LIST_ERROR,
    GET_PO_PRODUCT_LIST_SUCCESS,
    CREATE_PO,
    CREATE_PO_ERROR,
    CREATE_PO_RESET,
    CREATE_PO_SUCCESS,
    GET_PO_LIST,
    GET_PO_LIST_ERROR,
    GET_PO_LIST_SUCCESS,
} from "../actions/purchaseOrderAction"

const initialState = {
    getPOProductsData: {},
    POCreateData: {},
    getPOListData: {},

    getPOProductsDataLoading: false,
    getPOProductsDataLoaded: false,
    getPOProductsDataError: null,

    POCreateDataLoading: false,
    POCreateDataLoaded: false,
    POCreateDataError: null,

    getPOListDataLoading: false,
    getPOListDataLoaded: false,
    getPOListDataError: null,
}

export default function purchaseOrderReducer(state = initialState, action) {
    switch (action.type) {

        case GET_PO_PRODUCT_LIST:
            return {
                ...state,
                getPOProductsDataLoading: true,
                getPOProductsDataLoaded: false,
                getPOProductsDataError: null,
            }

        case GET_PO_PRODUCT_LIST_SUCCESS:
            return {
                ...state,
                getPOProductsDataLoading: false,
                getPOProductsDataLoaded: true,
                getPOProductsData: action.payload.success,
            }

        case GET_PO_PRODUCT_LIST_ERROR:
            return {
                ...state,
                getPOProductsDataLoading: false,
                getPOProductsDataLoaded: false,
                getPOProductsDataError: action.payload.error,
                getPOProductsData: [],
            }

            
        case CREATE_PO:
            return {
                ...state,
                POCreateDataLoading: true,
                POCreateDataLoaded: false,
                POCreateDataError: null,
            }

        case CREATE_PO_SUCCESS:
            return {
                ...state,
                POCreateDataLoading: false,
                POCreateDataLoaded: true,
                POCreateData: action.payload.success,
            }

        case CREATE_PO_ERROR:
            return {
                ...state,
                POCreateDataLoading: false,
                POCreateDataLoaded: false,
                POCreateDataError: action.payload.error,
                POCreateData: [],
            }

        case CREATE_PO_RESET:
            return {
                ...state,
                POCreateDataLoading: false,
                POCreateDataLoaded: false,
                POCreateDataError: null,
                POCreateData: {},
            }

        case GET_PO_LIST:
            return {
                ...state,
                getPOListDataLoading: true,
                getPOListDataLoaded: false,
                getPOListDataError: null,
            }

        case GET_PO_LIST_SUCCESS:
            return {
                ...state,
                getPOListDataLoading: false,
                getPOListDataLoaded: true,
                getPOListData: action.payload.success,
            }

        case GET_PO_LIST_ERROR:
            return {
                ...state,
                getPOListDataLoading: false,
                getPOListDataLoaded: false,
                getPOListDataError: action.payload.error,
                getPOListData: [],
            }

        default:
            return state
    }
}