import React, { Component } from "react";
import Resizer from "react-image-file-resizer";
import { connect } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import PersonalInfo from "../../components/seller/PersonalInfo";
import SellerCredential from "../../components/seller/SellerCredential";
import SellerShopInfo from "../../components/seller/SellerShopInfo";
import * as addressAction from "../../store/actions/addressAction";
import * as bussinessTypeAction from "../../store/actions/bussinessTypeAction";
import * as sellerAction from "../../store/actions/sellerAction";

export class StepForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 3, // 1

      // step 1
      sellerName: "",
      userContactNo: "",
      userEmail: "",
      acceptTerms: "",

      // step 2
      tokenId: "",
      tokenCode: "",

      // step 3
      sellerPassword: "",
      sellerTypeId: "",
      ownerName: "",
      shopName: "",
      shopDescription: "",
      binNo: "",
      shopCity: "",
      shopState: "",
      shopAddress: "",
      shopZipCode: "",

      ownerNidUrl: "",
      shopLogoUrl: "",
      bussinessDocUrl: "",
      isSellerDelivered: false,
      files: [],
      NIDFile: "", // to store the Single pictures in base64 format.
      ShopLogoFile: "", // to store the Single pictures in base64 format.
      BusDocFile: "", // to store the Single pictures in base64 format.

      country: "",
      countryId: "",
      countryList: "",

      city: "",
      cityId: 0,
      cityList: "",

      area: "",
      areaId: 0,
      areaName: "",
      areaList: "",

      bussinessType: "",
      bussinessTypeId: 0,
      bussinessTypeList: "",

      sellerCurrency: "BDT",
      showNIDFile: "",
      showShopLogoFile: "",

      redirect: false,
      businessTypesData: [],
      countries: [],
      //
      isEmailAvailable: true,
      isContactAvailable: true,

      bussinessTypeIdsMulti: 0,

      coverLetter: "",
      coverLetterName: ""
    };

    this.fileSelectedHandlerNid = this.fileSelectedHandlerNid.bind(this);
    this.fileSelectedHandlerLogo = this.fileSelectedHandlerLogo.bind(this);
    this.fileSelectedHandlerBusinessDoc =
      this.fileSelectedHandlerBusinessDoc.bind(this);
    this.clearData = this.clearData.bind(this);
  }

  componentDidMount = async () => {
    await this.props.getBussinessTypeRecord();
    this.setState({
      bussinessTypeList: this.props?.bussinessTypes.map(
        ({ bussinessTypeName: label, bussinessTypeId: value }) => ({
          label,
          value,
        })
      ),
    });
    await this.props.getAllCountryRecord();
    this.setState({
      countryList: this.props?.allCountries.map(
        ({ countryName: label, countryId: value }) => ({
          label,
          value,
        })
      ),
    });
  };

  fileSelectedHandlerNid = (e) => {
    const imageFile = e.target.files[0];
    //1)    CHECK IF IT'S A IMAGE
    let fileInput = false;
    if (imageFile) {
      if (!imageFile.name.match(/\.(jpg|jpeg|png|gif|webp)$/)) {
        toast.error("Select a valid image.");
        return false;
      }
      fileInput = true;
      if (fileInput) {
        try {
          Resizer.imageFileResizer(
            imageFile,
            640,
            480,
            "JPEG",
            100,
            0,
            (uri) => {
              this.setState({
                ownerNidUrl: uri,
                NIDFile: imageFile,
                showNIDFile: URL.createObjectURL(imageFile),
              });
              toast.success("NID Image Selected.");
            },
            "base64",
            640,
            480
          );
        } catch (err) {
          toast.error("Something went wrong!");
        }
      }
    }
  };

  fileSelectedHandlerLogo = (e) => {
    const ShopLogoImageFile = e.target.files[0];
    //1)    CHECK IF IT'S A IMAGE
    let ShopLogoFileInput = false;
    if (ShopLogoImageFile) {
      if (!ShopLogoImageFile.name.match(/\.(jpg|jpeg|png)$/)) {
        toast.error("Select a valid image.");
        return false;
      }
      ShopLogoFileInput = true;
      if (ShopLogoFileInput) {
        try {
          Resizer.imageFileResizer(
            ShopLogoImageFile,
            180,
            180,
            "JPEG",
            100,
            0,
            (uri) => {
              this.setState({
                shopLogoUrl: uri,
                ShopLogoFile: ShopLogoImageFile,
                showShopLogoFile: URL.createObjectURL(ShopLogoImageFile),
              });
              toast.success("Shop Logo Image Selected.");
            },
            "base64",
            180,
            180
          );
        } catch (err) {
          toast.error("Something went wrong!");
        }
      }
    }
  };

  fileSelectedHandlerBusinessDoc = (e) => {
    //////debugger;

    //---------------
    const BusDocImageFile = e.target.files[0];
    //1)    CHECK IF IT'S A IMAGE
    let BusDocFileInput = false;
    if (BusDocImageFile) {
      if (!BusDocImageFile.name.match(/\.(jpg|jpeg|png|gif|webp)$/)) {
        toast.error("Select a valid image.");
        return false;
      }
      BusDocFileInput = true;
      if (BusDocFileInput) {
        try {
          Resizer.imageFileResizer(
            BusDocImageFile,
            1000,
            1000,
            "JPEG",
            100,
            0,
            (uri) => {
              this.setState({
                bussinessDocUrl: uri,
                BusDocFile: BusDocImageFile,
                showBusDocFile: URL.createObjectURL(BusDocImageFile),
              });
              toast.success("Business Document Image Selected.");
            },
            "base64",
            1000,
            1000
          );
        } catch (err) {
          toast.error("Something went wrong!");
        }
      }
    }
  };

  handleChange = (e) => {
    const { target } = e;

    switch (target.name) {
      case "ownerName":
        this.setState({
          ownerName: target.value,
        });
        break;

      case "shopName":
        this.setState({
          shopName: target.value,
        });
        break;

      case "shopState":
        this.setState({
          shopState: target.value,
        });
        break;

      case "shopDescription":
        this.setState({
          shopDescription: target.value,
        });
        break;

      case "shopAddress":
        this.setState({
          shopAddress: target.value,
        });
        break;

      case "shopZipCode":
        this.setState({
          shopZipCode: target.value,
        });
        break;

      case "binNo":
        this.setState({
          binNo: target.value,
        });
        break;

      default:
    }
  };

  handleBusinessTypesChange = async (field, e) => {
    if (e.length) {
      var result = e?.map(function (item) {
          return item.value;
        })
        .join(",");
      // const result=e?.value;
    }
    if(typeof e === 'object'){
      var result= e?.value;
    }

    this.setState({
      bussinessTypeIdsMulti: result,
    });

    switch (field) {
      case "bussinessTypeList":
        this.setState({
          bussinessType: e,
        });
        // this.setState({
        //   bussinessTypeId: e.value,
        // });
        break;

      default:
        break;
    }
  };

  handleAddressChange = async (field, e) => {
    switch (field) {
      case "countryList":
        this.setState({
          country: e,
        });
        await this.props.getAllCityRecord(e.value);
        this.setState({
          countryId: e.value,
          area: "",
        });
        this.setState({
          cityList: this.props?.allCities.map(
            ({ cityName: label, cityId: value }) => ({
              label,
              value,
            })
          ),
        });
        break;

      case "cityList":
        this.setState({
          city: e,
          shopCity: e.label,
        });

        await this.props.getAllAreaRecord(e.value);
        this.setState({
          areaList: this.props?.allAreas.map(
            ({ areaName: label, areaId: value }) => ({
              label,
              value,
            })
          ),
        });
        this.setState({
          cityId: e.value,
          area: "",
        });
        break;

      case "areaList":
        this.setState({
          area: e,
          shopState: e.label,
        });
        this.setState({
          areaId: e.value,
          areaName: e.label,
        });
        break;

      default:
        break;
    }
  };

  clearData = (e) => {
    this.setState({
      sellerName: "",
      ownerName: "",
      shopName: "",
      shopDescription: "",
      binNo: "",
      shopCity: "",
      shopState: "",
      shopAddress: "",
      shopZipCode: "",
      bussinessTypeId: "",
      ownerNidUrl: "",
      shopLogoUrl: "",
      bussinessDocUrl: "",
      sellerCurrency: "",
    });
  };

  nextStep = async (e) => {
    const { step } = this.state;
    const sellerName = e.sellerName;
    const userContactNo = e.userContactNo;
    const userEmail = e.userEmail;
    const acceptTerms = e.acceptTerms;

    const data = {
      userContactNo: e.userContactNo,
      userEmail: e.userEmail,
      actionFor: "r",
      userType: "seller",
    };

    const response = await this.props.createSellerTokenRecord(data);

    if (
      response?.payload?.success?.result?.errors[0] ==
      "This email or contact no. is already used!"
    ) {
      toast.error("Seller Already Exists!");
      setTimeout(() => { }, 2500);
      return false;
    } else if (response?.payload?.success?.result?.succeed === false) {
      toast.error("Something Went Wrong!");
      setTimeout(() => { }, 2500);
      return false;
    }

    this.setState({
      step: step + 1,
      sellerName: sellerName,
      userContactNo: userContactNo,
      userEmail: userEmail,
      acceptTerms: acceptTerms,
      //
      isEmailAvailable: true,
      isContactAvailable: true,
    });
  };

  nextStepCredential = async (e) => {
    const { step } = this.state;
    const tokenId = localStorage.getItem("x-access-tokenId");
    const sellerName = this.state.sellerName;
    const userContactNo = this.state.userContactNo;
    const userEmail = this.state.userEmail;
    const acceptTerms = this.state.acceptTerms;

    const sellerPassword = e.sellerPassword;
    const rePassword = e.rePassword;

    const data = {
      tokenId: tokenId,
      tokenCode: e.tokenCode,
    };

    const response = await this.props.sellerTokenVerifyRecord(data);

    if (response?.payload?.success?.succeed === false) {
      toast.warning("Token code is not correct!");

      return false;
    }

    this.setState({
      step: step + 1,
      sellerName: sellerName,
      userContactNo: userContactNo,
      userEmail: userEmail,
      sellerPassword: sellerPassword,
      rePassword: rePassword,
      acceptTerms: acceptTerms,
    });
  };

  saveSellerInfo = async (e) => {
    e.preventDefault();

    const data = {
      sellerName: this.state.sellerName,
      sellerContactNo: this.state.userContactNo,
      sellerEmail: this.state.userEmail,
      sellerPassword: this.state.sellerPassword,
      shopName: this.state.shopName,
      sellerTypeId: 1,
      bussinessTypeId: this.state.bussinessTypeIdsMulti, // Take Multiple Id number in array
      shopDescription: this.state.shopDescription,
      binNo: this.state.binNo,
      shopCity: this.state.shopCity,
      shopState: this.state.shopState,
      shopZipCode: this.state.shopZipCode,
      shopAddress: this.state.shopAddress,
      ownerName: this.state.ownerName,
      isSellerDelivered: "N",
      sellerCurrency:
        this.state.countryId === 19
          ? "BDT"
          : this.state.countryId === 45
            ? "CNY"
            : this.state.countryId === 101
              ? "INR"
              : this.state.countryId === 232
                ? "GBP"
                : this.state.countryId === 233
                  ? "USD"
                  : "USD",

      shopLogoUrl: this.state.shopLogoUrl, // Image
      ownerNidUrl: this.state.ownerNidUrl, // Image or File
      nidBackDoc: "string", // Image or File (optional)
      bussinessDocUrl: this.state.bussinessDocUrl, // Image or File (optional)
      tradeLicenseDoc: "string", // Image or File
      tinNoDoc: "string", // Image or File (optional)
      binNoDoc: "string", // Image or File (optional)
      dbIdDoc: "string", // Image or File (optional)

      additionalDocuments: [ // Image or File (optional)
        {
          docName: "string", // Both required
          docPath: "string" // Both required
        }
      ]
    };

    // const response=''

    const response = await this.props.createSellerRecord(data);

    if (response && response?.payload?.success?.succeed === true) {
      if (response.type === "CREATE_SELLER_SUCCESS") {
        toast.info("Registration Successful! Redirection to Login");
        setTimeout(() => {
          this.props.history.push("/SellerLogin");
        }, 2500);
      } else {
        toast.error("Something went wrong, Please try again");
        setTimeout(() => {
          this.setState({
            step: 1,
          });
        }, 2500);
      }
    } else if (response && response?.payload?.success?.succeed === false) {
      toast.error("Seller Already Exists!");
      setTimeout(() => { }, 2500);
    }
    if (response.type === "CREATE_SELLER_ERROR") {
      toast.error("Something Went Wrong!! Try again later.");
      setTimeout(() => {
        this.setState({
          step: 1,
        });
      }, 2500);
    }

    if (response.payload.error) {
      toast.error("Something Went Wrong!!!Try again later.");
      setTimeout(() => {
        this.setState({
          step: 1,
        });
      }, 2500);
    }

    this.clearData();
  };

  CoverLetterHandler = () => {
    
  }

  showStep = () => {
    const { sellerName, tokenId, tokenCode, userContactNo, userEmail, step } =
      this.state;

    if (step === 1)
      return (
        <>
          <PersonalInfo
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            sellerName={sellerName}
            userContactNo={userContactNo}
            userEmail={userEmail}
            isEmailAvailable={this.state.isEmailAvailable}
            isContactAvailable={this.state.isContactAvailable}
            sellerLoadingInfoFromReducer={this.props?.sellerInfoFromReducer}
          />
          <ToastContainer autoClose={2500} />
        </>
      );
    if (step === 2)
      return (
        <>
          <SellerCredential
            nextStepCredential={this.nextStepCredential}
            handleChange={this.handleChange}
            tokenId={tokenId}
            tokenCode={tokenCode}
            userInfo={{
              userContactNo,
              userEmail,
              actionFor: "r",
              userType: "seller",
            }}
          />
          <ToastContainer autoClose={2500} />
        </>
      );

    if (step === 3)
      return (
        <>
          <SellerShopInfo
            name="Seller Shop Info"
            key="SellerShopInfo"
            saveSellerInfo={this.saveSellerInfo}
            {...this.state}
            values={this.values}
            businessTypes={this.props?.businessTypes}
            allCountries={this.props?.allCountries}
            handleChange={this.handleChange}
            fileSelectedHandlerNid={this.fileSelectedHandlerNid}
            fileSelectedHandlerLogo={this.fileSelectedHandlerLogo}
            fileSelectedHandlerBusinessDoc={this.fileSelectedHandlerBusinessDoc}
            clearData={this.clearData}
            handleAddressChange={this.handleAddressChange}
            handleBusinessTypesChange={this.handleBusinessTypesChange}
            CoverLetterHandler={this.CoverLetterHandler}
          />
          <ToastContainer autoClose={2500} />
        </>
      );
  };

 
  render() {
    return <>{this.showStep()}</>;
  }
}

const mapStateToProps = (state) => ({
  bussinessTypes: state.bussinessTypeReducer.bussinessTypes,
  sellerInfoFromReducer: state.sellerReducer.loading,
  allCountries: state.addressReducer.allCountries,
  allCities: state.addressReducer.allCities,
  allAreas: state.addressReducer.allAreas,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCountryRecord: () => dispatch(addressAction.getAllCountryRecord()),
    getAllCityRecord: (countryId) =>
      dispatch(addressAction.getAllCityRecord(countryId)),
    getAllAreaRecord: (cityId) =>
      dispatch(addressAction.getAllAreaRecord(cityId)),
    getBussinessTypeRecord: () =>
      dispatch(bussinessTypeAction.getBussinessTypeRecord()),
    createSellerTokenRecord: (data) =>
      dispatch(sellerAction.createSellerTokenRecord(data)),
    sellerTokenVerifyRecord: (data) =>
      dispatch(sellerAction.sellerTokenVerifyRecord(data)),
    createSellerRecord: (data) =>
      dispatch(sellerAction.createSellerRecord(data)),
    isEmailAvailable: (data) =>
      dispatch(sellerAction.sellerEmailAvailableRecord(data)),
    isContactAvailable: (data) =>
      dispatch(sellerAction.sellerMobileNumberAvailableRecord(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StepForm);
