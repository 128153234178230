import React, { Component } from "react";
import { connect } from "react-redux";
import HomeTest from "../../components/home/HomeTest";
import authService from "../../store/services/authService";

class homeContainer extends Component {

  componentDidMount = async () => {
    // For Login Check
    let userId = authService.getEmployeeId();

    if (userId != null) {
      this.setState({
        authenticated: true,
        loginSuccessful: true,
      });
    } else {
      this.setState({
        authenticated: false,
        loginSuccessful: false,
      });

      this.props.history.push("/Login");
    }
  };

  render() {
    return (
      <div id="wrapper">
        <HomeTest
          key="HomeTest"
          {...this.state}
        />
      </div>
    );
  }
}

export default connect()(homeContainer);
