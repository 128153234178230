/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import moment from "moment";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import {
    createOrderPaymentManualRecord,
    createOrderPaymentManualReset,
    getPaymentMethodRecord,
} from "../../store/actions/paymentMethodAction";
import toIsoString from "../../utils/Functions/toIsoString";
import "./CustomOrderPayment.css";

export default function OrderPaymentUpdate({ rowData, setPaymentUpdateVisible }) {

    const [trnxId, setTrnxId] = useState("");
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [paymentDate, setPaymentDate] = useState(null);
    const [paymentTime, setPaymentTime] = useState(null);
    const [receivingAccount, setReceivingAccount] = useState("");
    const [document, setDocument] = useState(null);
    const [remarks, setRemarks] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        setPaymentMethod(null)
        setDocument({
            doc: "",
        });
        setTrnxId("");
        setPaymentDate(null);
        setPaymentTime(null);
        setReceivingAccount("");
        setRemarks("");
        dispatch(createOrderPaymentManualReset())
    }, [rowData?.invoiceNo])

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 20,
                color: "#fff",
            }}
            spin
        />
    );

    const isoPaymentDate =
        paymentDate !== null ? toIsoString(paymentDate).substring(0, 10) : null;
    const isoPaymentTime =
        paymentTime !== null ? toIsoString(paymentTime).substring(10, 19) : null;
    const payment_date_time = isoPaymentDate + isoPaymentTime;

    const fileToBase64 = (file, cb) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(null, reader.result);
        };
        reader.onerror = function (error) {
            cb(error, null);
        };
    };

    const onUploadFileChange = ({ target }) => {
        if (
            !target.files[0].name.match(
                /\.(jpg|jpeg|png|gif|webp|pdf|JPG|JPEG|PNG|GIF|WEBP|PDF)$/
            )
        ) {
            target.value = null;
            toast.error("Select a valid file.");
        } else {
            if (target.files < 1 || !target.validity.valid) {
                return;
            }
            fileToBase64(target.files[0], (err, result) => {
                if (result) {
                    setDocument({
                        doc: result,
                    });
                }
            });
        }
    };
    useEffect(() => {
        if (paymentMethod?.paymentMethodId === 5) {
            setTrnxId("");
            setPaymentDate(null);
            setPaymentTime(null);
            setReceivingAccount("");
            setDocument({
                doc: "",
            });
            setRemarks("");
        }
    }, [paymentMethod]);

    useEffect(() => {
        dispatch(getPaymentMethodRecord());
    }, [dispatch]);
    const payment_method_name = useSelector(
        (state) => state?.paymentMethodReducer
    );
    const { paymentMethods } = payment_method_name;
    const onPaymentOptionChange = (e) => {
        setPaymentMethod(e.value);
    };
    const shipping_address = JSON.parse(rowData?.shippingAddress);

    const handlePayment = () => {
        const paymentData = {
            tranRefNo: trnxId,
            tranDate:
                paymentDate === null || paymentTime === null
                    ? toIsoString(new Date()).substring(0, 19)
                    : payment_date_time,
            orderProfileId: rowData?.orderProfileId,
            orderInvoiceNo: rowData?.invoiceNo,
            paymentMethodId: paymentMethod?.paymentMethodId,
            paymentMethod: paymentMethod?.methodName,
            totalAmmount: rowData.totalPayableAmount,
            accountNo: receivingAccount,
            paymentStatus:
                paymentMethod?.paymentMethodId === 5 ? "Pending" : "Success",
            paymentStatusId: paymentMethod?.paymentMethodId === 5 ? 2 : 3,
            remarks: remarks,
            fileAttachment: document?.doc,
            other: "",
        };
        dispatch(createOrderPaymentManualRecord(paymentData));
    };
    const order_payment_info = useSelector((state) => state.paymentMethodReducer);
    const { loading, data } = order_payment_info;

    useEffect(() => {
        data?.data?.succeed && dispatch(createOrderPaymentManualReset());
    }, [data?.data?.succeed]);

    useEffect(() => {
        if (data?.data?.succeed === true) {
            toast.success("Payment Method Updated Successfully!");
            setPaymentUpdateVisible(false)
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        }
    }, [data?.data?.succeed]);

    return (
        <>
            <div className="custom_order_table__section">
                <div className="order_get_payment_update__section">
                    <h6
                        style={{
                            background: "#f3f3f3",
                            padding: "5px",
                            fontWeight: "600"
                        }}
                    >
                        Payment Information
                    </h6>
                    <div className="input_field_row__section">
                        <div className="input_field__section" style={{ width: "49%" }}>
                            <label htmlFor="paymentOptions">
                                Select Payment Option{" "}
                                <span
                                    aria-hidden="true"
                                    style={{
                                        color: "red",
                                        fontWeight: "bold",
                                    }}
                                >
                                    *
                                </span>
                            </label>
                            <Dropdown
                                style={{ width: "100%" }}
                                id="paymentOptions"
                                value={paymentMethod}
                                options={paymentMethods}
                                onChange={onPaymentOptionChange}
                                optionLabel="methodName"
                                placeholder="Select a Payment Option"
                            />
                        </div>

                        <div className="input_field__section" style={{ width: "49%" }}>
                            <label htmlFor="amount">Amount</label>
                            <InputText
                                id="amount"
                                style={{ width: "100%" }}
                                value={rowData.totalPayableAmount}
                            />
                        </div>
                    </div>
                    <div className="input_field_row__section">
                        <div style={{ width: "49%" }}>
                            <label htmlFor="paymentDate">
                                Payment Date{" "}
                                {paymentMethod?.paymentMethodId !== 5 && (
                                    <span
                                        aria-hidden="true"
                                        style={{
                                            color: "red",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        *
                                    </span>
                                )}
                            </label>
                            <Calendar
                                style={{ width: "100%" }}
                                id="paymentDate"
                                value={paymentDate}
                                onChange={(e) => setPaymentDate(e.value)}
                                showIcon
                                placeholder="Select Payment Date"
                                readOnlyInput
                                disabled={paymentMethod?.paymentMethodId === 5}
                            />
                        </div>
                        <div className="input_field__section" style={{ width: "49%" }}>
                            <label htmlFor="time12">
                                Payment Time{" "}
                                {paymentMethod?.paymentMethodId !== 5 && (
                                    <span
                                        aria-hidden="true"
                                        style={{
                                            color: "red",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        *
                                    </span>
                                )}
                            </label>
                            <Calendar
                                style={{ width: "100%" }}
                                id="time12"
                                value={paymentTime}
                                onChange={(e) => setPaymentTime(e.value)}
                                timeOnly
                                hourFormat="12"
                                placeholder="Payment Time (12:00 AM)"
                                disabled={paymentMethod?.paymentMethodId === 5}
                            />
                        </div>
                    </div>
                    <div className="input_field_row__section">
                        <div className="input_field__section" style={{ width: "49%" }}>
                            <label htmlFor="trnx">Receiving Account No</label>
                            <InputText
                                id="trnx"
                                style={{ width: "100%" }}
                                value={receivingAccount}
                                onChange={(e) => setReceivingAccount(e.target.value)}
                                placeholder="Enter Receiving Account No"
                                disabled={paymentMethod?.paymentMethodId === 5}
                            />
                        </div>
                        <div className="input_field__section" style={{ width: "49%" }}>
                            <label htmlFor="trnx">Transaction ID.</label>
                            <InputText
                                id="trnx"
                                style={{ width: "100%" }}
                                value={trnxId}
                                onChange={(e) => setTrnxId(e.target.value)}
                                placeholder="Enter Transaction ID"
                                disabled={paymentMethod?.paymentMethodId === 5}
                            />
                        </div>
                    </div>
                    <div className="input_field_row__section">
                        <div className="input_field__section">
                            <label htmlFor="doc">Upload Document</label>
                            <input
                                className="input_file__field"
                                type="file"
                                name="filetobase64"
                                onChange={onUploadFileChange}
                            />
                        </div>
                    </div>
                    <div className="input_field_row__section">
                        <div className="input_field__section">
                            <label htmlFor="remarks">Remarks</label>
                            <InputTextarea
                                rows={2}
                                id="remarks"
                                style={{ width: "100%" }}
                                value={remarks}
                                onChange={(e) => setRemarks(e.target.value)}
                                placeholder="Enter Remarks Here"
                            />
                        </div>
                    </div>
                    <div className="input_field_row__section">
                        <div className="input_field__section">
                            <Button
                                label="Submit"
                                aria-label="Submit"
                                onClick={() => handlePayment()}
                                disabled={
                                    (paymentMethod?.paymentMethodId !== 5 &&
                                        (paymentDate === null || paymentTime === null)) ||
                                    loading
                                }
                            />
                            <span className="spinner__icon">
                                {loading ? <Spin indicator={antIcon} /> : null}
                            </span>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="custom_order_payment__section">
                        <div className="custom_order_payment__header_section">
                            <ul>
                                <li>
                                    <div className="custom_order_header__title">Invoice No:</div>
                                    {rowData?.invoiceNo}
                                </li>
                                <li>
                                    <div className="custom_order_header__title">Order Date:</div>
                                    {moment(rowData?.createDate).format("Do MMMM YYYY, h:mm A")}
                                </li>
                                <li>
                                    <div className="custom_order_header__title">&nbsp;</div>
                                </li>
                                <li>
                                    <div className="custom_order_header__title">Name:</div>
                                    {rowData?.customerName}
                                </li>
                                <li>
                                    <div className="custom_order_header__title">Contact No:</div>
                                    {rowData.cusContactNo}
                                </li>

                            </ul>
                        </div>
                        <div className="custom_order_payment__header_section">
                            <ul>
                                <li>
                                    <div className="custom_order_header__title">Sub-Total:</div>
                                    {rowData?.orderSubtotalAmt}
                                </li>
                                <li>
                                    <div className="custom_order_header__title">Discount:</div>
                                    {rowData.orderSubtotalDiscountAmt}
                                </li>
                                <li>
                                    <div className="custom_order_header__title">Coupon:</div>
                                    {rowData.voucherAmount}
                                </li>
                                <li>
                                    <div className="custom_order_header__title">Shipping Cost:</div>
                                    {rowData.totalShippingCharge}
                                </li>
                                <li>
                                    <div className="custom_order_header__title">Total:</div>
                                    {rowData.totalPayableAmount}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="custom_order_payment__section">
                        <div className="custom_order_payment__header_section">
                            <ul>
                                <li>
                                    <div className="custom_order_header__title">
                                        Address:
                                    </div>
                                    {`${shipping_address?.address}, ${shipping_address?.areaName}, ${shipping_address?.cityName}`}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
            <ToastContainer autoClose={1500} />
        </>
    );
}

