/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";

const PurchaseOrderAddedListTable = ({
    POAddedProduct,
    setPOAddedProduct,
    handlePOAddedProductInputChange
}) => {

    const onIndexTemplate = (rowData, props) => {
        return props.rowIndex + 1;
    };

    const removeActionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger"
                    onClick={() => confirmDeleteProduct(rowData)}
                    title={`Click to remove ${rowData?.productName} from list.`}
                />
            </React.Fragment>
        );
    };

    const POQtyInputTemplate = (rowData, props) => {

        return (
            <React.Fragment>
                <input
                    type="number"
                    value={rowData.productQuantity}
                    onChange={(e) => handlePOAddedProductInputChange
                        (props.rowIndex, e)}
                    placeholder="Input PO Qty"
                    name="productQuantity"
                    min="1"
                    style={{
                        maxWidth: '100px',
                        borderRadius: '5px',
                        paddingLeft: '5px',
                        textAlign: 'center'
                    }}
                    title="Edit to change quantity of the product"
                />
            </React.Fragment>
        );
    };

    const productAmountTemplate = (rowData) => {
        return (
            <>{Number(rowData.productQuantity) * rowData.price} </>
        );
    };
    const confirmDeleteProduct = (product) => {
        let _products = POAddedProduct.filter(
            (val) => val.sellerProductSku !== product.sellerProductSku
        );
        setPOAddedProduct(_products)
    };

    return (
        <>
            <div
                style={{
                    fontWeight: 700,
                    color: '#1f5da0',
                    fontSize: '16px',
                }}
            >Purchase Order Items:</div>

            <div style={{
                maxHeight: '25vh',
                overflow: 'auto',
                borderTop: "1px solid #808080"
            }}>
                <DataTable
                    className="product_list_table"
                    responsiveLayout="scroll"
                    value={POAddedProduct}
                    emptyMessage="No Product found!"
                >
                    <Column field="Index" header="SN" body={onIndexTemplate} />
                    <Column
                        field="productName"
                        header="Product Name"
                        style={{
                            maxWidth: '200px',
                            wordWrap: "break-word"
                        }}
                        sortable
                    />
                    <Column
                        field="sellerName"
                        header="Shop Name"
                        style={{
                            maxWidth: '160px',
                            wordWrap: "break-word"
                        }}
                        sortable
                    />
                    <Column
                        field="sellerProductSku"
                        header="Seller Product SKU"
                        style={{
                            maxWidth: '150px',
                            wordWrap: "break-word"
                        }}
                        sortable
                    />

                    <Column
                        field="productVariant"
                        header="Variant"
                        style={{
                            maxWidth: '150px',
                            wordWrap: "break-word"
                        }}
                    />

                    <Column
                        field="price"
                        header="Unit Price"
                        style={{
                            maxWidth: '100px',
                            wordWrap: "break-word"
                        }}
                        sortable
                    />

                    <Column
                        header="P.O Qty"
                        body={POQtyInputTemplate}
                    />

                    <Column
                        header="Amount"
                        body={productAmountTemplate}
                        style={{
                            maxWidth: '100px',
                            wordWrap: "break-word"
                        }}
                        sortable
                    />

                    <Column
                        field="Delete"
                        header="Remove"
                        body={removeActionBodyTemplate}
                    />
                </DataTable>
            </div>

            <div className="row" style={{ marginTop: '1vh', borderTop: "1px solid #808080" }}>
                <div className="col-md-8"></div>
                <div style={{ fontWeight: 500, color: '#000', fontSize: '16px' }}>Total:
                    <span style={{ marginLeft: '3vw' }}>
                        {POAddedProduct.reduce((acc, product) => acc + Number(product.productQuantity), 0)}
                    </span>
                    <span style={{ marginLeft: '6vw' }}>
                        {POAddedProduct.reduce((acc, product) => acc + (Number(product.productQuantity) * product.price), 0)}
                    </span>
                </div>
            </div>

        </>

    )
}

export default PurchaseOrderAddedListTable