/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React from "react";
import "../css/DateWisePaymentRealizationView.css";

const DateWisePaymentRealizationView = ({
  startDate,
  endDate,
  arrayDatas,
  printHandler,
}) => {

  return (
    <>
      <div className="accounts_DateWiseSalesSummary__report_buttons_container">
        <button
          onClick={() => printHandler(arrayDatas)}
          className="btn btn-primary"
        >
          Convert to PDF
        </button>
      </div>

      <div className="" id="accounts__report_items">
        <div className="accounts_DateWiseSalesSummary__report_header">
          <div style={{ width: "100%" }}>
            <div className="accounts_DateWiseSalesSummary__report_header_text1">
              Date Wise Payment Realization Report
            </div>
            <div className="accounts_DateWiseSalesSummary__report_header_text3">
              From {moment(startDate).format("D-MMM-YYYY")} to{" "}
              {moment(endDate).format("D-MMM-YYYY")}
            </div>
          </div>
        </div>

        <div className="accounts_DateWiseSalesSummary__report_body">
          <div className="accounts_DateWiseSalesSummary__report_body_sections">
            <div className="report_DateWiseSalesSummary_small_data_container">
              <div>
                <div className="report_DateWiseSalesSummary_small_data_container_items_header">
                  <div
                    style={{
                      minWidth: "5%",
                      maxWidth: "5%",
                      textAlign: "center",
                    }}
                  >
                    SL. No
                  </div>
                  <div
                    style={{
                      minWidth: "8%",
                      maxWidth: "8%",
                      textAlign: "center",
                    }}
                  >
                    MP Invoice
                  </div>
                  <div
                    style={{
                      minWidth: "8%",
                      maxWidth: "8%",
                      textAlign: "center",
                    }}
                  >
                    POS Invoice
                  </div>
                  <div
                    style={{
                      minWidth: "8%",
                      maxWidth: "8%",
                      textAlign: "center",
                    }}
                  >
                    Invoice / Order Date
                  </div>
                  <div
                    style={{
                      minWidth: "8%",
                      maxWidth: "8%",
                      textAlign: "center",
                    }}
                  >
                    Vendor Name
                  </div>
                  <div
                    style={{
                      minWidth: "8%",
                      maxWidth: "8%",
                      textAlign: "center",
                    }}
                  >
                    Payment Channel
                  </div>
                  <div
                    style={{
                      minWidth: "5%",
                      maxWidth: "5%",
                      textAlign: "center",
                    }}
                  >
                    Delivery Status
                  </div>
                  <div
                    style={{
                      minWidth: "8%",
                      maxWidth: "8%",
                      textAlign: "center",
                    }}
                  >
                    Delivery Date
                  </div>
                  <div
                    style={{
                      minWidth: "6%",
                      maxWidth: "6%",
                      textAlign: "center",
                    }}
                  >
                    Invoice Amount
                  </div>
                  <div
                    style={{
                      minWidth: "6%",
                      maxWidth: "6%",
                      textAlign: "center",
                    }}
                  >
                    Paid Amount
                  </div>
                  <div
                    style={{
                      minWidth: "8%",
                      maxWidth: "8%",
                      textAlign: "center",
                    }}
                  >
                    Remarks
                  </div>
                  <div
                    style={{
                      minWidth: "5%",
                      maxWidth: "5%",
                      textAlign: "center",
                    }}
                  >
                    Shipping Charge
                  </div>
                  <div
                    style={{
                      minWidth: "8%",
                      maxWidth: "8%",
                      textAlign: "center",
                    }}
                  >
                    Transaction No.
                  </div>
                  <div
                    style={{
                      minWidth: "8%",
                      maxWidth: "8%",
                      textAlign: "center",
                    }}
                  >
                    Transaction Date
                  </div>
                </div>
                {arrayDatas.map((info, i) => (
                  <div className="report_DateWiseSalesSummary_small_data_container_items">
                    <div
                      style={{
                        minWidth: "5%",
                        textAlign: "center",
                      }}
                    >
                      {i + 1}
                    </div>
                    <div
                      style={{
                        minWidth: "8%",
                        maxWidth: "8%",
                        textAlign: "center",
                        wordWrap: "break-word",
                      }}
                    >
                      {info?.invoiceNo ? info?.invoiceNo.toLocaleString() : "-"}
                    </div>
                    <div
                      style={{
                        minWidth: "8%",
                        maxWidth: "8%",
                        textAlign: "center",
                        wordWrap: "break-word",
                      }}
                    >
                      {info?.posInvoiceNo
                        ? info?.posInvoiceNo.toLocaleString()
                        : "-"}
                    </div>
                    <div
                      style={{
                        minWidth: "8%",
                        maxWidth: "8%",
                        textAlign: "center",
                        wordWrap: "break-word",
                      }}
                    >
                      {info?.orderDate
                        ? moment(info?.orderDate).format("DD-MMM-YY")
                        : "-"}
                    </div>
                    <div
                      style={{
                        minWidth: "8%",
                        maxWidth: "8%",
                        textAlign: "center",
                        wordWrap: "break-word",
                      }}
                    >
                      {info?.shopName ? info?.shopName.toLocaleString() : "-"}
                    </div>
                    <div
                      style={{
                        minWidth: "8%",
                        maxWidth: "8%",
                        textAlign: "center",
                        wordWrap: "break-word",
                      }}
                    >
                      {info?.paymentMethod
                        ? info?.paymentMethod.toLocaleString()
                        : "-"}
                    </div>
                    <div
                      style={{
                        minWidth: "5%",
                        maxWidth: "5%",
                        textAlign: "center",
                        wordWrap: "break-word",
                      }}
                    >
                      {info?.deliveryStatus
                        ? info?.deliveryStatus.toLocaleString()
                        : "-"}
                    </div>
                    <div
                      style={{
                        minWidth: "8%",
                        maxWidth: "8%",
                        textAlign: "center",
                        wordWrap: "break-word",
                      }}
                    >
                      {info?.deliveryDate
                        ? moment(info?.deliveryDate).format("DD-MMM-YY")
                        : "-"}
                    </div>
                    <div
                      style={{
                        minWidth: "6%",
                        maxWidth: "6%",
                        textAlign: "center",
                        wordWrap: "break-word",
                      }}
                    >
                      {info?.invoiceAmount
                        ? info?.invoiceAmount
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                        : "-"}
                    </div>
                    <div
                      style={{
                        minWidth: "6%",
                        maxWidth: "6%",
                        textAlign: "center",
                        wordWrap: "break-word",
                      }}
                    >
                      {info?.paidAmount
                        ? info?.paidAmount
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                        : "-"}
                    </div>
                    <div
                      style={{
                        minWidth: "8%",
                        maxWidth: "8%",
                        textAlign: "center",
                        wordWrap: "break-word",
                      }}
                    >
                      {info?.remarks ? info?.remarks.toLocaleString() : "-"}
                    </div>
                    <div
                      style={{
                        minWidth: "5%",
                        maxWidth: "5%",
                        textAlign: "center",
                        wordWrap: "break-word",
                      }}
                    >
                      {info?.shippingCharge
                        ? info?.shippingCharge
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                        : "-"}
                    </div>
                    <div
                      style={{
                        minWidth: "8%",
                        maxWidth: "8%",
                        textAlign: "center",
                        wordWrap: "break-word",
                      }}
                    >
                      {info?.tranNo ? info?.tranNo.toLocaleString() : "-"}
                    </div>
                    <div
                      style={{
                        minWidth: "8%",
                        maxWidth: "8%",
                        textAlign: "center",
                        wordWrap: "break-word",
                      }}
                    >
                      {info?.tranDate
                        ? moment(info?.tranDate).format("DD-MMM-YY")
                        : "-"}
                    </div>
                  </div>
                ))}
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DateWisePaymentRealizationView;
