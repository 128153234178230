import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../containers";
import { createTokenRecord } from "../../store/actions/sellerAction";

const TokenResend = ({ userInfo }) => {
  const [time, setTime] = useState(60);
  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.sellerReducer);

  const resendTokenHandler = async () => {
    dispatch(createTokenRecord({ ...userInfo, setTime }));
  };

  useEffect(() => {
    const timeLeft = setInterval(() => {
      setTime((prev) => prev - 1);
    }, 1000);
    if (time === 0) {
      clearInterval(timeLeft);
    }

    return () => clearInterval(timeLeft);
  }, [time]);

  return (
    <div style={{ textAlign: "center", padding: "10px 0px 0px" }}>
      {time ? (
        <span> Resend Request in {time}s</span>
      ) : (
        <>
          {loading ? (
            <Loader />
          ) : (
            <span
              style={{
                cursor: "pointer",
                color: "#1F5DA0",
                fontWeight: 600,
              }}
              onClick={() => resendTokenHandler()}
            >
              Resend Token
            </span>
          )}
        </>
      )}
    </div>
  );
};

export default TokenResend;
