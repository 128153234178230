/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import "moment-timezone";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useRef, useState } from "react";
import { FaPlusCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import LoadingCard from "../../components/shared/LoadingCard";
import Message from "../../components/shared/Message";
// import * as blogPostAction from "../../store/actions/blogPostAction";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { getBlogCategoryRecord } from "../../store/actions/blogCategoryAction";
import { getBlogPostRecord } from "../../store/actions/blogPostAction";
import authenticationService from "../../store/services/authenticationService";
import baseUrl from "../../utils/baseUrl";

const blogStatusArr = [
  { status: "Active", code: "Y" },
  { status: "Deactive", code: "N" },
];

const dateTypes = [
  { name: "Create", code: "cd" },
  { name: "Publish", code: "pd" },
];

const BlogPostListContainer = (props) => {
  const [statusInfo, setStatusInfo] = useState(null);
  const [keyword, setKeyword] = useState("");
  const [selectCategory, setSelectCategory] = useState(null);
  const [selectedDateType, setSelectedDateType] = useState(null);
  // const [dates, setDates] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [isFilter, setIsFilter] = useState(false);

  const toastRef = useRef(null);

  const dispatch = useDispatch();

  const { blogCategoryDetails } = useSelector(
    (state) => state.blogCategoryReducer
  );

  const { blogs, loading, error } = useSelector(
    (state) => state.blogPostReducer
  );

  const toIsoString = (date) => {
    var tzo = -date?.getTimezoneOffset(),
      dif = tzo >= 0 ? "+" : "-",
      pad = function (num) {
        return (num < 10 ? "0" : "") + num;
      };
    return (
      date?.getFullYear() +
      "-" +
      pad(date?.getMonth() + 1) +
      "-" +
      pad(date?.getDate()) +
      "T" +
      pad(date?.getHours()) +
      ":" +
      pad(date?.getMinutes()) +
      ":" +
      pad(date?.getSeconds()) +
      dif +
      pad(Math?.floor(Math?.abs(tzo) / 60)) +
      ":" +
      pad(Math?.abs(tzo) % 60)
    );
  };

  useEffect(() => {
    let roleId = authenticationService.getRoleId();

    if (roleId === "1") {
      // setAuthenticated(true);
      // setLoginSuccessful(true);
    } else {
      // setAuthenticated(false);
      // setLoginSuccessful(false);
      props.history.push("/Login");
    }
    // End Temporary Authentication
  }, []);

  useEffect(() => {
    dispatch(getBlogCategoryRecord());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getBlogPostRecord({
        isActive: statusInfo?.code,
        PostTitle: keyword,
        CategoryId: selectCategory?.blogCategoryId,
        searchDateType: selectedDateType?.name,
        // startDate: dates && toIsoString(dates[0]).substring(0, 10),
        // endDate: dates && toIsoString(dates[1]).substring(0, 10),
        startDate: startDate && toIsoString(startDate).substring(0, 10),
        endDate: endDate && toIsoString(endDate).substring(0, 10),
      })
    );
    return () => setIsFilter(false);
  }, [dispatch, isFilter]);

  const blogCategoryNameTemplate = (rowData) => {
    return (
      <React.Fragment>
        <div>{rowData?.blogCategoryName}</div>
      </React.Fragment>
    );
  };

  const postTitleTemplate = (rowData) => {
    return <React.Fragment>{rowData?.postTitle}</React.Fragment>;
  };

  const featureImagePathTemplate = (rowData) => {
    return (
      <React.Fragment>
        <img
          src={baseUrl.concat(rowData?.featureImagePath)}
          className="thumb-md product-image"
          alt="img"
          style={{ verticalAlign: "middle", objectFit: "contain" }}
        />
      </React.Fragment>
    );
  };

  const createDatetimeTemplate = (rowData) => {
    return (
      <React.Fragment>
        {moment(rowData?.createDate).format("Do MMMM, YYYY")}
      </React.Fragment>
    );
  };

  const publishDatetimeTemplate = (rowData) => {
    return (
      <React.Fragment>
        {moment(rowData?.publishDatetime).format("Do MMMM, YYYY")}
      </React.Fragment>
    );
  };

  const metaAuthorTemplate = (rowData) => {
    return <React.Fragment>{rowData?.metaAuthor}</React.Fragment>;
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span
          className={
            rowData?.isActive === "Y"
              ? "p-tag p-tag-primary"
              : "p-tag p-tag-warning"
          }
        >
          {rowData?.isActive === "Y" ? "ACTIVE" : "INACTIVE"}
        </span>
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Toast ref={toastRef} />
        <Link
          to={{
            pathname: `/EditBlogPost`,
            state: { rowData },
          }}
        >
          <Button
            icon="pi pi-pencil"
            className="p-button-rounded p-button-success p-mr-2"
          />
        </Link>
      </React.Fragment>
    );
  };

  const onIndexTemplate = (rowData, props) => {
    return props?.rowIndex + 1;
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Link to="/CreateBlogPost">
          <button
            type="button"
            className=""
            style={{
              border: "1px solid gray",
              borderRadius: 20,
              display: "flex",
              alignItems: "center",
              gap: 5,
              padding: "6px 10px",
            }}
          >
            <FaPlusCircle fontSize="1.9rem" />{" "}
            <span className="font-16">Add New Blog</span>
          </button>
        </Link>
      </React.Fragment>
    );
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="p-text-bold table-heading-style">List of Blog Post</div>
      </React.Fragment>
    );
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div className="datatable-doc-demo datatable-responsive-demo">
                  <div className="card">
                    <Toolbar
                      className="p-mb-4"
                      right={rightToolbarTemplate}
                      left={leftToolbarTemplate}
                    ></Toolbar>

                    <div
                      className="d-flex justify-end"
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        margin: "20px 20px",
                      }}
                    >
                      <div
                        style={{
                          margin: "0 5px",
                          width: "200px",
                          position: "relative",
                        }}
                      >
                        <InputText
                          style={{
                            width: "100%",
                          }}
                          className="manage_product__search"
                          value={keyword}
                          onChange={(e) => setKeyword(e.target.value)}
                          placeholder="Search Keyword"
                        />
                        {keyword !== "" ? (
                          <button
                            style={{
                              position: "absolute",
                              top: "6px",
                              right: "8px",
                              borderRadius: "100%",
                              border: "1px solid #ced4da",
                              height: "25px",
                              width: "25px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            onClick={() => {
                              setKeyword("");
                            }}
                          >
                            <i className="pi pi-times"></i>
                          </button>
                        ) : null}
                      </div>

                      <div
                        style={{
                          margin: "0 5px",
                          width: "210px",
                          position: "relative",
                        }}
                      >
                        <div
                          className="flex flex-column gap-15"
                          style={{
                            width: "100%",
                            border: "1px solid #ced4da",
                            padding: "6.5px 10px",
                            borderRadius: "6px",
                          }}
                        >
                          {dateTypes.map((d_type) => {
                            return (
                              <div
                                key={d_type?.code}
                                className="flex align-items-center"
                              >
                                <RadioButton
                                  inputId={d_type?.code}
                                  name="date_type"
                                  value={d_type}
                                  onChange={(e) => setSelectedDateType(e.value)}
                                  checked={
                                    selectedDateType?.code === d_type?.code
                                  }
                                />
                                <span
                                  htmlFor={d_type?.code}
                                  className="ml-2"
                                  style={{
                                    fontSize: "14px",
                                    color: "#495057",
                                    fontWeight: 400,
                                  }}
                                >
                                  {d_type.name}
                                </span>
                              </div>
                            );
                          })}
                        </div>

                        {selectedDateType !== null ? (
                          <button
                            style={{
                              position: "absolute",
                              top: "6px",
                              right: "8px",
                              borderRadius: "100%",
                              border: "1px solid #ced4da",
                              height: "25px",
                              width: "25px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            onClick={() => {
                              setSelectedDateType(null);
                             
                            }}
                          >
                            <i className="pi pi-times"></i>
                          </button>
                        ) : null}
                      </div>

                      {/* <div
                        style={{
                          margin: "0 5px",
                          width: "260px",
                          position: "relative",
                        }}
                      >
                        <Calendar
                          showIcon
                          value={dates}
                          onChange={(e) => setDates(e.value)}
                          selectionMode="range"
                          readOnlyInput
                          placeholder="Date Range"
                          style={{ width: "100%" }}
                          disabled={!selectedDateType}
                        />

                        {dates !== null ? (
                          <button
                            style={{
                              position: "absolute",
                              top: "6px",
                              right: "33px",
                              borderRadius: "100%",
                              border: "1px solid #ced4da",
                              height: "25px",
                              width: "25px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            onClick={() => {
                              setDates(null);
                            }}
                          >
                            <i className="pi pi-times"></i>
                          </button>
                        ) : null}
                      </div> */}

                      <div
                        style={{
                          margin: "0 5px",
                          width: "170px",
                          position: "relative",
                        }}
                      >
                        <Calendar
                          id="icon"
                          // maxDate={endDate}
                          value={startDate}
                          onChange={(e) => setStartDate(e.value)}
                          showIcon
                          placeholder="Start Date"
                          readOnlyInput
                          disabled={!selectedDateType}
                        />
                        {startDate !== null ? (
                          <button
                            style={{
                              position: "absolute",
                              top: "6px",
                              right: "33px",
                              borderRadius: "100%",
                              border: "1px solid #ced4da",
                              height: "25px",
                              width: "25px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            onClick={() => {
                              setStartDate(null);
                            }}
                          >
                            <i className="pi pi-times"></i>
                          </button>
                        ) : null}
                      </div>

                      <div
                        style={{
                          margin: "0 5px",
                          width: "170px",
                          position: "relative",
                        }}
                      >
                        <Calendar
                          id="icon"
                          // maxDate={endDate}
                          value={endDate}
                          onChange={(e) => setEndDate(e.value)}
                          showIcon
                          placeholder="End Date"
                          readOnlyInput
                          disabled={!selectedDateType}
                        />
                        {endDate !== null ? (
                          <button
                            style={{
                              position: "absolute",
                              top: "6px",
                              right: "33px",
                              borderRadius: "100%",
                              border: "1px solid #ced4da",
                              height: "25px",
                              width: "25px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            onClick={() => {
                              setEndDate(null);
                            }}
                          >
                            <i className="pi pi-times"></i>
                          </button>
                        ) : null}
                      </div>

                      <div
                        style={{
                          margin: "0 5px",
                          width: "160px",
                          position: "relative",
                        }}
                      >
                        <Dropdown
                          style={{
                            width: "100%",
                          }}
                          value={selectCategory}
                          options={blogCategoryDetails}
                          onChange={(e) => setSelectCategory(e.value)}
                          optionLabel="blogCategoryName"
                          placeholder="Select Category"
                        />
                        {selectCategory !== null ? (
                          <button
                            style={{
                              position: "absolute",
                              top: "6px",
                              right: "8px",
                              borderRadius: "100%",
                              border: "1px solid #ced4da",
                              height: "25px",
                              width: "25px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            onClick={() => {
                              setSelectCategory(null);
                            }}
                          >
                            <i className="pi pi-times"></i>
                          </button>
                        ) : null}
                      </div>

                      <div
                        style={{
                          margin: "0 5px",
                          width: "160px",
                          position: "relative",
                        }}
                      >
                        <Dropdown
                          style={{
                            width: "100%",
                          }}
                          value={statusInfo}
                          options={blogStatusArr}
                          onChange={(e) => setStatusInfo(e.value)}
                          optionLabel="status"
                          placeholder="Select a Status"
                        />
                        {statusInfo !== null ? (
                          <button
                            style={{
                              position: "absolute",
                              top: "6px",
                              right: "8px",
                              borderRadius: "100%",
                              border: "1px solid #ced4da",
                              height: "25px",
                              width: "25px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            onClick={() => {
                              setStatusInfo(null);
                            }}
                          >
                            <i className="pi pi-times"></i>
                          </button>
                        ) : null}
                      </div>
                      <Button
                        onClick={() => setIsFilter(true)}
                        style={{ marginLeft: "5px" }}
                        label="Search"
                      />
                    </div>

                    {loading ? (
                      <LoadingCard count={1} />
                    ) : error ? (
                      <Message variant="danger">{error}</Message>
                    ) : (
                      <DataTable
                        value={blogs}
                        className="p-datatable-customers p-datatable-responsive-demo"
                        dataKey="id"
                        rowHover
                        paginator
                        rows={10}
                        emptyMessage="No blog post found"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rowsPerPageOptions={[10, 25, 50]}
                        loading={loading}
                      >
                        <Column
                          field="Index"
                          header="SN"
                          body={onIndexTemplate}
                        />
                        <Column
                          sortField="postTitle"
                          filterField="postTitle"
                          header="Blog Title"
                          body={postTitleTemplate}
                          sortable
                        />
                        <Column
                          sortField="metaAuthor"
                          header="Author"
                          body={metaAuthorTemplate}
                          sortable
                        />
                        <Column
                          sortField="publishDatetime"
                          header="Create Date"
                          body={createDatetimeTemplate}
                          sortable
                        />
                        <Column
                          sortField="publishDatetime"
                          header="Publish Date"
                          body={publishDatetimeTemplate}
                          sortable
                        />
                        <Column
                          sortField="blogCategoryName"
                          filterField="blogCategoryName"
                          header="Category"
                          body={blogCategoryNameTemplate}
                          sortable
                        />

                        <Column
                          sortField="featureImagePath"
                          header="Feature Image"
                          body={featureImagePathTemplate}
                        />
                        <Column
                          sortField="isActive"
                          header="Status"
                          body={statusBodyTemplate}
                          sortable
                        />
                        <Column
                          field="action"
                          header="Edit"
                          body={actionBodyTemplate}
                        />
                      </DataTable>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={1500} />
    </>
  );
};

export default BlogPostListContainer;
