import moment from "moment";
import React from "react";
import "../css/PaymentTypeWiseSalesDetailsSmallData.css";

const PaymentTypeWiseSalesDetailsSmallData = ({ data, methodName }) => {
  const sumWithInitial = data.reduce(
    (accumulator, currentValue) =>
      accumulator + parseFloat(currentValue?.netAmount),
    0
  );

  const groupedData = {};
  for (const obj of data) {
    const fieldValue = obj.shopName;
    if (!groupedData[fieldValue]) {
      groupedData[fieldValue] = [];
    }
    groupedData[fieldValue].push(obj);
  }

  const uniqueValues = [...new Set(data.map((obj) => obj.shopName))];

  return (
    <div className="report_PaymentTypeWiseSalesDetails_small_data_container">
      <div className="report_PaymentTypeWiseSalesDetails_small_data_container_payment_method_container">
        <div className="report_PaymentTypeWiseSalesDetails_small_data_container_payment_method">
          {methodName}
        </div>
      </div>

      <div>
        {uniqueValues.map((dt) => (
          <>
            <div className="report_PaymentTypeWiseSalesDetails_small_data_container_shop_name_items_container">
              Shop:
              <p className="report_PaymentTypeWiseSalesDetails_small_data_container_shop_name_items">
                {" "}
                {dt}
              </p>
            </div>
            <>
              {groupedData[dt].map((data, i) => (
                <>
                  <div className="report_PaymentTypeWiseSalesDetails_small_data_container_items">
                    <div style={{ width: "5%", textAlign: "center" }}>
                      {i + 1}
                    </div>
                    <div style={{ width: "35%", textAlign: "center" }}>
                      {moment(data?.orderDate).format("D-MMMM-YYYY")}
                    </div>
                    <div style={{ width: "35%", textAlign: "center" }}>
                      {data?.cntDelivered.toLocaleString()}
                    </div>
                    <div style={{ width: "15%", textAlign: "center" }}>
                      {data?.netAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                    </div>
                  </div>
                </>
              ))}
              <div className="report_PaymentTypeWiseSalesDetails_small_data_container__shop_name_total_container">
                <div className="report_PaymentTypeWiseSalesDetails_small_data_container__shop_name_total">
                  {" "}
                  <div
                    style={{
                      width: "43%",
                      textAlign: "center",
                      fontWeight: "700",
                    }}
                  >
                    {dt}
                  </div>
                  <div style={{ width: "22%", textAlign: "center" }}>
                    Total:
                  </div>
                  <div style={{ width: "32%", textAlign: "center" }}>
                  {groupedData[dt]
                    .reduce(
                      (accumulator, currentValue) =>
                        accumulator + parseFloat(currentValue?.netAmount),
                      0
                    )
                    .toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                  </div>
                </div>
              </div>
            </>
          </>
        ))}
      </div>

      <div className="report_PaymentTypeWiseSalesDetails_small_data_container_payment_method_total_container">
        <div className="report_PaymentTypeWiseSalesDetails_small_data_container_payment_method_total">
          {" "}
          <div style={{ width: "43%", textAlign: "center", fontWeight: "700" }}>
            {methodName}
          </div>
          <div style={{ width: "22%", textAlign: "center" }}>Total:</div>
          <div style={{ width: "32%", textAlign: "center" }}>
            {sumWithInitial.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
          </div>
        </div>
      </div>
      <br />
    </div>
  );
};

export default PaymentTypeWiseSalesDetailsSmallData;
