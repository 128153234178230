/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Loader } from "..";
import ShowQuantity from "../../component/molecules/ShowQuantity";
import BasicInformation from "../../components/product/BasicInformation";
import CategoryChangeModal from "../../components/product/CategoryChangeModal";
import CreateProductAttribute from "../../components/product/CreateProductAttribute";
import EditSizeChart from "../../components/product/EditSizeChart";
import PriceAndStock from "../../components/product/PriceAndStock";
import ProductDetails from "../../components/product/ProductDetails";
import ReturnPolicy from "../../components/product/ReturnPolicy";
import ServiceAndDelivery from "../../components/product/ServiceAndDelivery";
import LoadingCard from "../../components/shared/LoadingCard";
import useProductQuantity from "../../hooks/useProductQuantity";
import { getActiveBreadcrumbsProductCategoryRecord } from "../../store/actions/activeBreadcrumbsCategoryAction";
import { getBrandRecord } from "../../store/actions/brandAction";
import {
  getProductDetailsRecord,
  updateProductRecord,
  updateProductReset,
} from "../../store/actions/productAction";
import { getProductVariantByCategoryIdRecord } from "../../store/actions/productVariantAction";
import { getApprovedReturnPolicyByShopIdRecord } from "../../store/actions/sellerAction";
import { getShopRecord } from "../../store/actions/shopAction";
import { getUnitRecord } from "../../store/actions/unitAction";
import authenticationService from "../../store/services/authenticationService";

const EditSellerProductContainer = (props) => {
  let { id } = useParams();
  const [currentPage, setCurrentPage] = useState(
    props?.location?.state?.currentPage
  );
  const [itemPerPage, setItemPerPage] = useState(
    props?.location?.state?.itemPerPage
  );
  const [activeTab, setActiveTab] = useState(1);
  const [productId, setProductId] = useState(id);
  const [shopId, setShopId] = useState(null);
  const [selectedShopId, setSelectedShopId] = useState(null);
  const [shopName, setShopName] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [categoryName, setCategoryName] = useState(null);
  const [brandId, setBrandId] = useState(null);
  const [selectedBrandId, setSelectedBrandId] = useState(null);
  const [brandName, setBrandName] = useState(null);
  const [unitId, setUnitId] = useState(null);
  const [selectedUnitId, setSelectedUnitId] = useState(null);
  const [unitName, setUnitName] = useState(null);
  const [productName, setProductName] = useState("");
  const [sku, setSku] = useState("");
  const [productVideoUrl, setProductVideoUrl] = useState("");
  const [productStatus, setProductStatus] = useState("Y");
  const [warrantyTypeId, setWarrantyTypeId] = useState(0);
  const [warrantyPeriodId, setWarrantyPeriodId] = useState(0);
  const [warrantyPolicy, setWarrantyPolicy] = useState("");
  const [packageWeight, setPackageWeight] = useState("");
  const [packageLength, setPackageLength] = useState("");
  const [packageWidth, setPackageWidth] = useState("");
  const [packageHeight, setPackageHeight] = useState("");
  const [boxInsideElement, setBoxInsideElement] = useState("");
  const [maxPrice, setMaxPrice] = useState(0);
  const [commissionPercentage, setCommissionPercentage] = useState(0);
  const [metaKeywords, setMetaKeywords] = useState("");
  const [thumbnailImage, setThumbnailImage] = useState("");
  const [thumbnailImage2, setThumbnailImage2] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productSpecification, setProductSpecification] = useState("");
  const [isReturnable, setIsReturnable] = useState(null);
  const [returnDuration, setReturnDuration] = useState("");
  const [returnPolicy, setReturnPolicy] = useState("");
  const [productDetails, setProductDetails] = useState("");
  const [productUrl, setProductUrl] = useState("");
  const [remarks, setRemarks] = useState("");
  const [selectedAttributes, setSelectedAttributes] = useState([]);

  const [selectedSizeChart, setSelectedSizeChart] = useState({});
  const [selectedSizeChartId, setSelectedSizeChartId] = useState("");
  const [isShopChanged, setIsShopChanged] = useState(true);
  const [sellerCommissionPercentage, setSellerCommissionPercentage] = useState(0);
  const [hasEMI, setHasEMI] = useState('');

  const [productVariantMap, setProductVariantMap] = useState([]);

  const [productVariantMap0, setProductVariantMap0] = useState([]);
  const [productVariantMap1, setProductVariantMap1] = useState([]);
  const [productVariantMap2, setProductVariantMap2] = useState([]);
  const [productVariantMap3, setProductVariantMap3] = useState([]);

  const [productVariantMapArray0, setProductVariantMapArray0] = useState([]);
  const [productVariantMapArray1, setProductVariantMapArray1] = useState([]);
  const [productVariantMapArray2, setProductVariantMapArray2] = useState([]);
  const [productVariantMapArray3, setProductVariantMapArray3] = useState([]);

  const [productVariantAdded, setProductVariantAdded] = useState(null);
  const [combineVariantImages, setCombineVariantImages] = useState(null);
  const [allProductVariants, setAllProductVariants] = useState(null);

  const [newProductDetails, setNewProductDetails] = useState("");
  const [newCategoryId, setNewCategoryId] = useState(null);
  const [visibleDialog, setVisibleDialog] = useState(false);

  const [variants, setVariants] = useState([]);

  const [burnOK, setBurnOK] = useState(true);
  const [discountOK, setDiscountOK] = useState(true);
  const [burnOKAdd, setBurnOKAdd] = useState(true);
  const [discountOKAdd, setDiscountOKAdd] = useState(true);

  const productQuantity = useProductQuantity();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    let roleId = authenticationService.getRoleId();
    if (roleId !== "2") {
      history.push("/SellerLogin");
    }
  }, [dispatch, history]);
  useEffect(() => {
    dispatch(getActiveBreadcrumbsProductCategoryRecord());
    dispatch(getUnitRecord());
    dispatch(getBrandRecord());
    dispatch(getShopRecord());
  }, [dispatch]);
  useEffect(() => {
    dispatch(
      getProductDetailsRecord(
        props?.location?.state?.id,
        props?.location?.state?.currency,
        props?.location?.state?.isApproved
      )
    );
  }, [
    dispatch,
    props.location.state.currency,
    props.location.state.id,
    props.location.state.isApproved,
  ]);
  const { activeBreadcrumbsProductCategories } = useSelector(
    (state) => state?.activeBreadcrumbsCategoryReducer
  );
  const { units } = useSelector((state) => state?.unitReducer);
  const { brands } = useSelector((state) => state?.brandReducer);
  const { shops } = useSelector((state) => state?.shopReducer);
  const productUpdate = useSelector((state) => state?.productReducer);
  const { updating, updatedData, getProductDetails, getDataLoaded } =
    productUpdate;
  const approvedReturnPolicyById = useSelector(
    (state) => state.sellerReducer.approvedReturnPolicyById
  );

  const navigateBackUrl = history?.location?.state?.navigateBack;

  useEffect(() => {
    if (updatedData?.data?.message === "success") {
      toast.success("Product Updated Successfully");
      dispatch(updateProductReset());
      setTimeout(() => {
        // eslint-disable-next-line no-unused-expressions
        history?.push(navigateBackUrl, {
          state: {
            ...props?.location?.state,
            currentPage,
            itemPerPage,
          },
        });
      }, 1000);
    } else if (updatedData?.data?.errors.length === 0) {
      toast.error("Something went wrong, Please try again");
    }
  }, [currentPage, dispatch, history, itemPerPage, navigateBackUrl, updatedData]);

  useEffect(() => {
    let data = getProductDetails?.data;
    data?.sizeCharts && setSelectedSizeChart(data?.sizeCharts[0])
    setShopId(data?.shopId);
    setShopName(data?.shopName);
    setCategoryId(data?.categoryId);
    setNewCategoryId(data?.categoryId);
    setCategoryName(data?.categoryName);
    setBrandId(data?.brandId);
    setBrandName(data?.brandName);
    setBoxInsideElement(data?.boxInsideElement);
    setMaxPrice(data?.maxPrice);
    setMetaKeywords(data?.metaKeywords);
    setPackageHeight(data?.packageHeight);
    setPackageLength(data?.packageLength);
    setPackageWeight(data?.packageWeight);
    setPackageWidth(data?.packageWidth);
    setProductDescription(data?.productDescription);
    setProductDetails(data?.productDetails);
    setProductId(data?.productId);
    setProductName(data?.productName);
    setProductSpecification(data?.productSpecification);
    setProductStatus(data?.productStatus);
    setSku(data?.sku);
    setProductUrl(data?.productUrl);
    setProductVideoUrl(data?.productVideoUrl);
    setThumbnailImage(data?.thumbnailImage);
    setThumbnailImage2(data?.thumbnailImage2);
    setUnitId(data?.unitId);
    setUnitName(data?.unitId === "1" ? "PCS" : "KG");
    setWarrantyPeriodId(data?.warrantyPeriodId);
    setWarrantyPolicy(data?.warrantyPolicy);
    setWarrantyTypeId(data?.warrantyTypeId);
    setHasEMI(data?.hasEmi === "Y" ? true : false);
    setIsReturnable(data?.isReturnable === "Y" ? true : false);
    setReturnDuration(data?.returnDuration);
    setReturnPolicy(data?.returnPolicy);
    setRemarks(data?.remarks ? data?.remarks : "");
    setSelectedAttributes(
      data?.productAttributes
        ?.map((att) =>
          att?.productAttributes?.map((att_item) => ({
            filterName: att?.variantName,
            label: att_item?.variantName,
            value: att_item?.variantOptionId,
          }))
        )
        .flatMap((att) => [...att])
    );
  }, [getProductDetails]);

  useEffect(() => {
    getProductDetails?.data?.shopId && shopId && getProductDetails?.data?.shopId !== shopId && toast.error("Updating Shop will reset return policy for the product.");
  }, [shopId])

  useEffect(() => {
    newCategoryId && categoryId !== newCategoryId && setVisibleDialog(true);
  }, [newCategoryId])

  const newVariantsCalculation = (dataAdded) => {
    const details = [];
    const variants = [];

    dataAdded.forEach((item) => {
      details.push({
        productDetailsId: 0,
        productId: Number(productId),
        shopId: Number(shopId),
        productPrice: Number(item.productPrice),
        productQuantity: Number(item.productQuantity),
        sellerProductSku: item.sellerProductSku,
        inventoryTypeId: Number(item.inventoryTypeId),
        shopProductSku: item.shopProductSku,
        productDetailsNote: "PROD DETAILS",
        productDetailsStatus: "Y",
        isAvailable: "Y",
        commissionPercentage: commissionPercentage,
        commissionAmount:
          (item.productPrice * 1 * commissionPercentage) / 100,
        discountPercentage: item.discountPercentage
          ? Number(item.discountPercentage)
          : 0,
        discountAmount: item.discountAmount ? Number(item.discountAmount) : 0,
        discountStartDate: item.discountStartDate,
        discountEndDate: item.discountEndDate,
        dpPricePercentage: item.dpPercentage
          ? Number(item.dpPercentage)
          : 0,
        dpPrice: item.dpAmount ? Number(item.dpAmount) : 0,
        burnAmountPercentage: item.burnPercentage
          ? Number(item.burnPercentage)
          : 0,
        burnAmount: item.burnAmount ? Number(item.burnAmount) : 0,
        burnStartDate: item.burnStartDate,
        burnEndDate: item.burnEndDate,
        isDpPriceFixManual: item.isDpPriceFixManual,
      });

      const variantOptionId0 = item.hasOwnProperty("variantOptionId0");
      const variantOptionId1 = item.hasOwnProperty("variantOptionId1");
      const variantOptionId2 = item.hasOwnProperty("variantOptionId2");
      const variantOptionId3 = item.hasOwnProperty("variantOptionId3");

      if (
        variantOptionId0 &&
        !variantOptionId1 &&
        !variantOptionId2 &&
        !variantOptionId3
      ) {
        variants.push({
          variationWiseProductId: 0,
          variantOptionId0: Number(item.variantOptionId0),
          sellerProductSku: item.sellerProductSku,
          shopProductSku: item.shopProductSku,
          productId: Number(productId),
          shopId: Number(shopId),
          status: "Y",
        });
      }

      if (
        variantOptionId0 &&
        variantOptionId1 &&
        !variantOptionId2 &&
        !variantOptionId3
      ) {
        variants.push({
          variationWiseProductId: 0,
          variantOptionId0: Number(item.variantOptionId0),
          variantOptionId1: Number(item.variantOptionId1),
          sellerProductSku: item.sellerProductSku,
          shopProductSku: item.shopProductSku,
          productId: Number(productId),
          shopId: Number(shopId),
          status: "Y",
        });
      }

      if (
        variantOptionId0 &&
        variantOptionId1 &&
        variantOptionId2 &&
        !variantOptionId3
      ) {
        variants.push({
          variationWiseProductId: 0,
          variantOptionId0: item.variantOptionId0 * 0,
          variantOptionId1: item.variantOptionId1 * 1,
          variantOptionId2: item.variantOptionId1 * 2,
          sellerProductSku: item.sellerProductSku,
          shopProductSku: item.shopProductSku,
          productId: Number(productId),
          shopId: Number(shopId),
          status: "Y",
        });
      }

      if (
        variantOptionId0 &&
        variantOptionId1 &&
        variantOptionId2 &&
        variantOptionId3
      ) {
        variants.push({
          variationWiseProductId: 0,
          variantOptionId0: item.variantOptionId0 * 0,
          variantOptionId1: item.variantOptionId1 * 1,
          variantOptionId2: item.variantOptionId1 * 2,
          variantOptionId3: item.variantOptionId1 * 3,
          sellerProductSku: item.sellerProductSku,
          shopProductSku: item.shopProductSku,
          productId: Number(productId),
          shopId: Number(shopId),
          status: "Y",
        });
      }
    });

    const variantOptionId0 = variants
      .map((o) => o.variantOptionId0 && true)
      .includes(true);
    const variantOptionId1 = variants
      .map((o) => o.variantOptionId1 && true)
      .includes(true);
    const variantOptionId2 = variants
      .map((o) => o.variantOptionId2 && true)
      .includes(true);
    const variantOptionId3 = variants
      .map((o) => o.variantOptionId3 && true)
      .includes(true);

    const multiVariants = [];
    const variantCombined = [];

    if (
      variantOptionId0 &&
      !variantOptionId1 &&
      !variantOptionId2 &&
      !variantOptionId3
    ) {
      variants.forEach((item) => {
        multiVariants.push({
          variationWiseProductId: item.variationWiseProductId,
          variantOptions: [{ variantOptionId: item.variantOptionId0 }],
          sellerProductSku: item.sellerProductSku,
          shopProductSku: item.shopProductSku,
          productId: Number(productId),
          shopId: Number(shopId),
          status: "Y",
        });
      });
      const flatten = (array) =>
        array.reduce(
          (results, item) => [
            ...results,
            ...item.variantOptions.map((data) => ({
              variationWiseProductId: item.variationWiseProductId,
              id: data,
              sellerProductSku: item.sellerProductSku,
              shopProductSku: item.shopProductSku,
              productId: Number(productId),
              shopId: Number(shopId),
              status: "Y",
            })),
          ],
          []
        );

      flatten(multiVariants).map((item) => {
        variantCombined.push({
          variationWiseProductId: item.variationWiseProductId,
          variantOptionId: item.id.variantOptionId,
          sellerProductSku: item.sellerProductSku,
          shopProductSku: item.shopProductSku,
          productId: Number(productId),
          shopId: Number(shopId),
          status: "Y",
        });
      });
    }

    if (
      variantOptionId0 &&
      variantOptionId1 &&
      !variantOptionId2 &&
      !variantOptionId3
    ) {
      variants.forEach((item) => {
        multiVariants.push({
          variationWiseProductId: item.variationWiseProductId,
          variantOptions: [
            { variantOptionId: item.variantOptionId0 },
            { variantOptionId: item.variantOptionId1 },
          ],
          sellerProductSku: item.sellerProductSku,
          shopProductSku: item.shopProductSku,
          productId: Number(productId),
          shopId: Number(shopId),
          status: "Y",
        });
      });
      const flatten = (array) =>
        array.reduce(
          (results, item) => [
            ...results,
            ...item.variantOptions.map((data) => ({
              variationWiseProductId: item.variationWiseProductId,
              id: data,
              sellerProductSku: item.sellerProductSku,
              shopProductSku: item.shopProductSku,
              productId: Number(productId),
              shopId: Number(shopId),
              status: "Y",
            })),
          ],
          []
        );

      flatten(multiVariants).map((item) => {
        variantCombined.push({
          variationWiseProductId: item.variationWiseProductId,
          variantOptionId: item.id.variantOptionId,
          sellerProductSku: item.sellerProductSku,
          shopProductSku: item.shopProductSku,
          productId: Number(productId),
          shopId: Number(shopId),
          status: "Y",
        });
      });
    }

    if (
      variantOptionId0 &&
      variantOptionId1 &&
      variantOptionId2 &&
      !variantOptionId3
    ) {
      variants.forEach((item) => {
        multiVariants.push({
          variationWiseProductId: item.variationWiseProductId,
          variantOptions: [
            { variantOptionId: item.variantOptionId0 },
            { variantOptionId: item.variantOptionId1 },
            { variantOptionId: item.variantOptionId2 },
          ],
          sellerProductSku: item.sellerProductSku,
          shopProductSku: item.shopProductSku,
          productId: Number(productId),
          shopId: Number(shopId),
          status: "Y",
        });
      });
      const flatten = (array) =>
        array.reduce(
          (results, item) => [
            ...results,
            ...item.variantOptions.map((data) => ({
              id: data,
              variationWiseProductId: item.variationWiseProductId,
              sellerProductSku: item.sellerProductSku,
              shopProductSku: item.shopProductSku,
              productId: Number(productId),
              shopId: Number(shopId),
              status: "Y",
            })),
          ],
          []
        );

      flatten(multiVariants).map((item) => {
        variantCombined.push({
          variationWiseProductId: item.variationWiseProductId,
          variantOptionId: item.id.variantOptionId,
          sellerProductSku: item.sellerProductSku,
          shopProductSku: item.shopProductSku,
          productId: Number(productId),
          shopId: Number(shopId),
          status: "Y",
        });
      });
    }

    if (
      variantOptionId0 &&
      variantOptionId1 &&
      variantOptionId2 &&
      variantOptionId3
    ) {
      variants.forEach((item) => {
        multiVariants.push({
          variationWiseProductId: item.variationWiseProductId,
          variantOptions: [
            { variantOptionId: item.variantOptionId0 },
            { variantOptionId: item.variantOptionId1 },
            { variantOptionId: item.variantOptionId2 },
            { variantOptionId: item.variantOptionId3 },
          ],
          sellerProductSku: item.sellerProductSku,
          shopProductSku: item.shopProductSku,
          productId: Number(productId),
          shopId: Number(shopId),
          status: "Y",
        });
      });
      const flatten = (array) =>
        array.reduce(
          (results, item) => [
            ...results,
            ...item.variantOptions.map((data) => ({
              id: data,
              variationWiseProductId: item.variationWiseProductId,
              sellerProductSku: item.sellerProductSku,
              shopProductSku: item.shopProductSku,
              productId: Number(productId),
              shopId: Number(shopId),
              status: "Y",
            })),
          ],
          []
        );

      flatten(multiVariants).map((item) => {
        variantCombined.push({
          variationWiseProductId: item.variationWiseProductId,
          variantOptionId: item.id.variantOptionId,
          sellerProductSku: item.sellerProductSku,
          shopProductSku: item.shopProductSku,
          productId: Number(productId),
          shopId: Number(shopId),
          status: "Y",
        });
      });
    }

    const variantWithImage = allProductVariants.map((variant) => {
      return {
        ...variant,
        image: combineVariantImages.find(
          (img) => img.variantOptionText === variant.variantOptionText0
        ),
      };
    });

    const flatten = (array) =>
      array.reduce(
        (results, item) => [
          ...results,
          ...item.image.images.map((data) => ({
            variantId: item.variantId,
            variantOptionId: item.variantOptionId0,
            variantOptionText: item.variantOptionText0,
            shopProductSku: item.shopProductSku,
            image: data,
            productId: Number(productId),
            shopId: Number(shopId),
          })),
        ],
        []
      );

    // variantImages create
    let variantImages = [];
    flatten(variantWithImage).map((item) => {
      variantImages.push({
        variantId: item.variantId,
        variantOptionId: item.variantOptionId,
        variantOptionText: item.variantOptionText,
        shopProductSku: item.shopProductSku,
        imageSeoName: item.image.name,
        imageUrl: item.image.path,
        productImageDetails: "productImageDetails",
        status: "Y",
        productId: Number(productId),
        shopId: Number(shopId),
      });
    });
    const productDetailsCreated = [...details];
    const newVariationWiseProducts = [...variantCombined];
    for (var i = 0; i < productDetailsCreated.length; i++) {
      for (var j = 0; j < selectedAttributes.length; j++) {
        const variant = {
          variantOptionId: selectedAttributes[j].value,
          sellerProductSku: productDetailsCreated[i]?.sellerProductSku,
          shopProductSku: productDetailsCreated[i]?.shopProductSku,
          productId: productId,
          shopId: shopId,
          status: "Y",
        };
        newVariationWiseProducts.push(variant);
      }
    }
    const newProductImages = [...variantImages];

    const modifiedNew = { productDetailsCreated, newProductImages, newVariationWiseProducts }

    return modifiedNew
  }

  const updateProduct = async (e) => {
    if (productName === "") {
      let msg = "Product Name is required!!!";
      toast.error(msg);
      setTimeout(() => { }, 3000);
      return;
    }
    if (sku === "") {
      let msg = "Product SKU/Style is required!!!";
      toast.error(msg);
      setTimeout(() => { }, 3000);
      return;
    }
    if (newCategoryId === null) {
      let msg = "Category is required!!!";
      toast.error(msg);
      setTimeout(() => { }, 3000);
      return;
    }
    if (brandId === null) {
      let msg = "Brand is required!!!";
      toast.error(msg);
      setTimeout(() => { }, 3000);
      return;
    }
    if (unitId === "") {
      let msg = "Unit is required!!!";
      toast.error(msg);
      setTimeout(() => { }, 3000);
      return;
    }
    if (maxPrice === "") {
      let msg = "Maximum Price is required!!!";
      toast.error(msg);
      setTimeout(() => { }, 3000);
      return;
    }
    if (boxInsideElement === "") {
      let msg = "Box Inside Element is required!!!";
      toast.error(msg);
      setTimeout(() => { }, 3000);
      return;
    }
    if (metaKeywords === "") {
      let msg = "Meta Keywords is required!!!";
      toast.error(msg);
      setTimeout(() => { }, 3000);
      return;
    }
    if (thumbnailImage === "") {
      let msg = "Product Image is required!!!";
      toast.error(msg);
      setTimeout(() => { }, 3000);
      return;
    }
    if (productDescription === "") {
      let msg = "Product Description is required!!!";
      toast.error(msg);
      setTimeout(() => { }, 3000);
      return;
    }

    if (warrantyPolicy === "") {
      let msg = "Warranty Policy is required!!!";
      toast.error(msg);
      setTimeout(() => { }, 3000);
      return;
    }
    if (packageWeight === "") {
      let msg = "Package Weight is required!!!";
      toast.error(msg);
      setTimeout(() => { }, 3000);
      return;
    }
    if (packageLength === "") {
      let msg = "Package Length is required!!!";
      toast.error(msg);
      setTimeout(() => { }, 3000);
      return;
    }
    if (packageWidth === "") {
      let msg = "Package Width is required!!!";
      toast.error(msg);
      setTimeout(() => { }, 3000);
      return;
    }
    if (packageHeight === "") {
      let msg = "Package Height is required!!!";
      toast.error(msg);
      setTimeout(() => { }, 3000);
      return;
    }

    let details = [];
    let variants = [];
    let images = [];

    if (newProductDetails.length != 0) {
      const calculated = newVariantsCalculation(newProductDetails)
      const { productDetailsCreated, newProductImages, newVariationWiseProducts } = calculated

      details = [...productDetailsCreated];
      variants = [...newVariationWiseProducts];
      images = [...newProductImages];
    }

    if (productDetails) {
      productDetails.forEach((items) => {
        details.push({
          productDetailsId: 0,
          productId: Number(productId),
          shopId: Number(shopId),
          productPrice: Number(items.productPrice),
          baseCurrency: "BDT",
          productQuantity: Number(items.productQuantity),
          sellerProductSku: items.sellerProductSku,
          inventoryTypeId: Number(items.inventoryTypeId),
          shopProductSku: items.shopProductSku,
          productDetailsNote: "PROD DETAILS",
          productDetailsStatus: "Y",
          isAvailable: "Y",
          commissionPercentage: commissionPercentage,
          commissionAmount: Number(
            (items.productPrice * 1 * commissionPercentage) / 100
          ),
          discountAmount: items.discountPercentage ? 0 : items.discountAmount ? Number(items.discountAmount) : 0,
          discountPercentage: items.discountPercentage
            ? Number(items.discountPercentage)
            : 0,
          discountStartDate: items.discountStartDate,
          discountEndDate: items.discountEndDate,
          dpPrice: items.dpPricePercentage
            ? 0
            : items.dpPrice
              ? Number(items.dpPrice)
              : 0,
          dpPricePercentage: items.dpPricePercentage
            ? Number(items.dpPricePercentage)
            : 0,
          burnAmount: items.burnAmountPercentage
            ? 0
            : items.burnAmount
              ? Number(items.burnAmount)
              : 0,
          burnAmountPercentage: items.burnAmountPercentage
            ? Number(items.burnAmountPercentage)
            : 0,
          burnStartDate: items.burnStartDate,
          burnEndDate: items.burnEndDate,
          isDpPriceFixManual: items.isDpPriceFixManual ? items.isDpPriceFixManual : 'M',
        });

        items.productVariants.forEach((item) => {
          variants.push({
            variationWiseProductId: item.variationWiseProductId,
            variantOptionId: item.variantOptionId,
            sellerProductSku: items.sellerProductSku,
            shopProductSku: item.shopProductSku,
            productId: Number(productId),
            shopId: Number(shopId),
            status: item.status,
            productVariantId: item.productVariantId,
            variantOptionText: item.variantOptionText,
            variantName: item.variantName,
          });
        });

        items.productImages.forEach((item) => {
          images.push({
            productImageId: item.productImageId,
            variantId: item.variantId,
            variantOptionId: item.variantOptionId,
            imageUrl: item.imageUrl,
            imageSeoName: item.imageSeoName,
            productImageDetails: item.productImageDetails,
            productId: Number(productId),
            shopId: Number(shopId),
            status: item.status,
            shopProductSku: item.shopProductSku,
          });
        });
      });
    }

    const newVariantArr = [];

    variants &&
      variants.forEach((v_item) =>
        selectedAttributes.forEach((s_atr_item) =>
          newVariantArr.push({
            variationWiseProductId: v_item?.variationWiseProductId,
            sellerProductSku: v_item?.sellerProductSku,
            shopProductSku: v_item?.shopProductSku,
            productId: Number(productId),
            shopId: Number(shopId),
            status: v_item?.status,
            productVariantId: v_item?.productVariantId,

            variantOptionId: s_atr_item?.value,
            variantOptionText: s_atr_item?.label,
            variantName: s_atr_item?.filterName,
            // ...variants
          })
        )
      );

    newVariantArr.push(...variants);

    if (details?.length === 0) {
      let msg = "There is no variant found. Please Add At least one.!!!";
      toast.error(msg);
      setTimeout(() => { }, 3000);
      return;
    }

    const isDuplicateSellerProductSku = new Set(details.map((v) => v.sellerProductSku));
    if (isDuplicateSellerProductSku.size < details.length) {
      let msg = "Duplicate Seller Product SKU Found!";
      toast.error(msg);
      setTimeout(() => { }, 3000);
      return;
    }

    let UpdatedData = {
      productId: productId,
      shopId: shopId,
      baseCurrency: "BDT",
      productName: productName,
      productTitle: productName?.slice(0, 50),
      sku: sku,
      productUrl: productUrl,
      categoryId: newCategoryId,
      productVideoUrl: productVideoUrl,
      productDescription: productDescription,
      productSpecification: productSpecification,
      boxInsideElement: boxInsideElement,
      brandId: brandId,
      unitId: unitId,
      warrantyTypeId: warrantyTypeId,
      warrantyPeriodId: warrantyPeriodId,
      warrantyPolicy: warrantyPolicy,
      packageWeight: Number(packageWeight),
      packageLength: Number(packageLength),
      packageWidth: Number(packageWidth),
      packageHeight: Number(packageHeight),
      productStatus: productStatus,
      maxPrice: Number(maxPrice),
      metaKeywords: metaKeywords,
      thumbnailImage: thumbnailImage,
      thumbnailImage2: thumbnailImage2,
      remarks: remarks,
      productDetails: details,
      variationWiseProducts: newVariantArr,
      productImages: images,
      hasEmi: hasEMI === true ? "Y" : "N",
      isReturnable: isReturnable === true ? "Y" : "N",
      returnDuration: Number(isReturnable === true ? returnDuration : 0),
      returnPolicy: isReturnable === true ? returnPolicy : "",
      sizeChartId: selectedSizeChart?.sizeChartId
    };
    dispatch(updateProductRecord(UpdatedData));
  };

  const handleCancel = () => {
    history.push(navigateBackUrl, {
      state: {
        currentPage,
        itemPerPage,
      },
    });
  };

  const onSizeChartOptionsChange = (e) => {
    setSelectedSizeChart(e.value);
    setSelectedSizeChartId(e.value.sizeChartId)
  };

  const productVariant = useSelector(
    (state) => state.productVariantReducer.variantsWithCategoryId
  );

  useEffect(() => {
    if (newCategoryId && newCategoryId !== undefined) {
      dispatch(getProductVariantByCategoryIdRecord(newCategoryId));
    }
  }, [dispatch, newCategoryId]);


  useEffect(() => {
    if (approvedReturnPolicyById.length !== 0 && getProductDetails) {
      if (isReturnable === false && getProductDetails) {
        setReturnDuration(approvedReturnPolicyById[0]?.duration);
        setReturnPolicy(approvedReturnPolicyById[0]?.returnPolicy);
      }
    }
  }, [isReturnable, approvedReturnPolicyById]);

  useEffect(() => {
    shopId !== null &&
      shopId !== undefined &&
      dispatch(getApprovedReturnPolicyByShopIdRecord(shopId));
    getProductDetails?.data && shopId != getProductDetails?.data?.shopId && setIsShopChanged(true)
    getProductDetails?.data && shopId != getProductDetails?.data?.shopId && setSelectedSizeChart({})
  }, [shopId]);

  useEffect(() => {
    const selectedShop = shops.filter((item) => item.shopId === shopId)
    shopId && setSellerCommissionPercentage(selectedShop[0]?.sellerCommissionPercentage)
  }, [shopId, shops])

  const handleMultiSelectChange = (action, value) => {
    switch (value.name) {
      case "field0":
        setProductVariantMap0(action)
        break;

      case "field1":
        setProductVariantMap1(action)
        break;

      case "field2":
        setProductVariantMap2(action)
        break;

      case "field3":
        setProductVariantMap3(action)
        break;

      default:
    }
  };

  const combineProductVariant = (e) => {
    e.preventDefault();

    const productVariant0 = productVariantMap0.map(
      ({ value, label }) => ({
        variantOptionId0: value,
        variantOptionText0: label,
      })
    );
    const productVariant1 = productVariantMap1.map(
      ({ value, label }) => ({
        variantOptionId1: value,
        variantOptionText1: label,
      })
    );
    const productVariant2 = productVariantMap2.map(
      ({ value, label }) => ({
        variantOptionId2: value,
        variantOptionText2: label,
      })
    );
    const productVariant3 = productVariantMap3.map(
      ({ value, label }) => ({
        variantOptionId3: value,
        variantOptionText3: label,
      })
    );

    if (shopId === "" || null) {
      let msg = "Select Shop Name!!!";
      toast.error(msg);
      setTimeout(() => { }, 3000);
      return;
    }

    if (shopName === "") {
      let msg = "Select Shop Name!!!";
      toast.error(msg);
      setTimeout(() => { }, 3000);
      return;
    }

    productVariantMap0.length > 0 && setProductVariantMapArray0(productVariant0)
    productVariantMap0.length > 0 && setProductVariantAdded(true)

    productVariantMap1.length > 0 && setProductVariantMapArray1(productVariant1)
    productVariantMap1.length > 0 && setProductVariantAdded(true)

    productVariantMap2.length > 0 && setProductVariantMapArray2(productVariant2)
    productVariantMap2.length > 0 && setProductVariantAdded(true)

    productVariantMap3.length > 0 && setProductVariantMapArray3(productVariant3)
    productVariantMap3.length > 0 && setProductVariantAdded(true)

    setProductVariantMap([
      ...productVariant0,
      ...productVariant1,
      ...productVariant2,
      ...productVariant3,
    ])
  };

  const getVariantImagesHandler = (data) => {
    combineVariantImageHandler(data);
  };

  const combineVariantImageHandler = (data) => {
    setCombineVariantImages([...data]);
  };

  const getVariantsHandler = (data) => {
    setAllProductVariants(data)
  };


  return (
    <>
      <div id="wrapper">
        <div className="page-wrapper">
          <div className="container-fluid">
            <>
              <div className="row">
                <div className="col-md-12">
                  <div className="panel panel-info">
                    <div className="white-box">
                      <div className="card">
                        <ul className="nav nav-tabs seller-tabs">
                          <li>
                            <Link to="/createProductSeller">Create Product</Link>
                          </li>
                          <li>
                            <Link to="/manage-seller-products?status=pending">
                              Pending Product <ShowQuantity currentQuantity={productQuantity[0]?.countStatus} />
                            </Link>
                          </li>
                          <li>
                            <Link to="/manage-seller-products?status=approved">
                              Approved Product <ShowQuantity currentQuantity={productQuantity[1]?.countStatus} />
                            </Link>
                          </li>
                          <li>
                            <Link to="/manage-seller-products?status=rejected">
                              Rejected Product <ShowQuantity currentQuantity={productQuantity[2]?.countStatus} />
                            </Link>
                          </li>
                          <li>
                            <Link to="/manage-seller-products?status=lowstock">
                              Low Stock Product <ShowQuantity currentQuantity={productQuantity[3]?.countStatus} />
                            </Link>
                          </li>
                        </ul>

                        <div
                          className="panel-wrapper collapse in"
                          aria-expanded="true"
                        >
                          <div className="panel-body crate_new_product">
                            <div>
                              <div className="form-body">
                                <h3 className="box-title add-product-title">
                                  Edit Product
                                </h3>
                                <ul className="nav nav-tabs">
                                  <li
                                    onClick={() => setActiveTab(1)}
                                    className={
                                      activeTab === 1
                                        ? `edit_tab__active`
                                        : `edit_tab`
                                    }
                                  >
                                    Basic Information
                                  </li>
                                  <li
                                    onClick={() => setActiveTab(2)}
                                    className={
                                      activeTab === 2
                                        ? `edit_tab__active`
                                        : `edit_tab`
                                    }
                                  >
                                    Product Details
                                  </li>
                                  <li
                                    onClick={() => setActiveTab(3)}
                                    className={
                                      activeTab === 3
                                        ? `edit_tab__active`
                                        : `edit_tab`
                                    }
                                  >
                                    Price & Stock
                                  </li>
                                  <li
                                    onClick={() => setActiveTab(7)}
                                    className={
                                      activeTab === 7
                                        ? `edit_tab__active`
                                        : `edit_tab`
                                    }
                                  >
                                    Attributes
                                  </li>
                                  <li
                                    onClick={() => setActiveTab(4)}
                                    className={
                                      activeTab === 4
                                        ? `edit_tab__active`
                                        : `edit_tab`
                                    }
                                  >
                                    Service & Delivery
                                  </li>
                                  <li
                                    onClick={() => setActiveTab(5)}
                                    className={
                                      activeTab === 5
                                        ? `edit_tab__active`
                                        : `edit_tab`
                                    }
                                  >
                                    Return Policy
                                  </li>
                                  <li
                                    onClick={() => setActiveTab(6)}
                                    className={
                                      activeTab === 6
                                        ? `edit_tab__active`
                                        : `edit_tab`
                                    }
                                  >
                                    Size Chart
                                  </li>
                                </ul>

                                {!getDataLoaded ? (
                                  <LoadingCard count={1} />
                                ) : (
                                  <div className="tab-content">
                                    {activeTab === 1 && (
                                      <BasicInformation
                                        accType="Seller"
                                        shops={shops}
                                        shopName={shopName}
                                        selectedShopId={selectedShopId}
                                        setSelectedShopId={setSelectedShopId}
                                        setShopId={setShopId}
                                        setShopName={setShopName}
                                        productName={productName}
                                        setProductName={setProductName}
                                        sku={sku}
                                        setSku={setSku}
                                        activeBreadcrumbsProductCategories={
                                          activeBreadcrumbsProductCategories
                                        }
                                        categoryName={categoryName}
                                        selectedCategoryId={selectedCategoryId}
                                        setSelectedCategoryId={
                                          setSelectedCategoryId
                                        }
                                        setCategoryId={setCategoryId}
                                        setNewCategoryId={setNewCategoryId}
                                        brands={brands}
                                        brandName={brandName}
                                        selectedBrandId={selectedBrandId}
                                        setSelectedBrandId={setSelectedBrandId}
                                        setBrandId={setBrandId}
                                        units={units}
                                        unitName={unitName}
                                        selectedUnitId={selectedUnitId}
                                        setSelectedUnitId={setSelectedUnitId}
                                        setUnitId={setUnitId}
                                        maxPrice={maxPrice}
                                        setMaxPrice={setMaxPrice}
                                        boxInsideElement={boxInsideElement}
                                        setBoxInsideElement={
                                          setBoxInsideElement
                                        }
                                        metaKeywords={metaKeywords}
                                        setMetaKeywords={setMetaKeywords}
                                        productVideoUrl={productVideoUrl}
                                        setProductVideoUrl={setProductVideoUrl}
                                        thumbnailImage={thumbnailImage}
                                        setThumbnailImage={setThumbnailImage}
                                        thumbnailImage2={thumbnailImage2}
                                        setThumbnailImage2={setThumbnailImage2}
                                        setSellerCommissionPercentage={setSellerCommissionPercentage}
                                        hasEMI={hasEMI}
                                        setHasEMI={setHasEMI}
                                        categoryId={categoryId}
                                      />
                                    )}

                                    {activeTab === 2 && (
                                      <ProductDetails
                                        productDescription={productDescription}
                                        setProductDescription={
                                          setProductDescription
                                        }
                                        productSpecification={
                                          productSpecification
                                        }
                                        setProductSpecification={
                                          setProductSpecification
                                        }
                                      />
                                    )}
                                    {activeTab === 3 && (
                                      <PriceAndStock
                                        productDetails={productDetails}
                                        setProductDetails={setProductDetails}
                                        isAdmin={false}
                                        sellerCommissionPercentage={sellerCommissionPercentage}
                                        productVariant={productVariant}
                                        productVariantMapArray0={productVariantMapArray0}
                                        productVariantMapArray1={productVariantMapArray1}
                                        productVariantMapArray2={productVariantMapArray2}
                                        productVariantMapArray3={productVariantMapArray3}
                                        handleMultiSelectChange={handleMultiSelectChange}
                                        combineProductVariant={combineProductVariant}
                                        getVariantsHandler={getVariantsHandler}
                                        getVariantImagesHandler={getVariantImagesHandler}
                                        selectedCategoryId={selectedCategoryId}
                                        productVariantAdded={productVariantAdded}
                                        setNewProductDetails={setNewProductDetails}
                                        setVariants={setVariants}
                                        variants={variants}
                                        setBurnOK={setBurnOK}
                                        burnOK={burnOK}
                                        setDiscountOK={setDiscountOK}
                                        discountOK={discountOK}
                                        setBurnOKAdd={setBurnOKAdd}
                                        burnOKAdd={burnOKAdd}
                                        setDiscountOKAdd={setDiscountOKAdd}
                                        discountOKAdd={discountOKAdd}
                                        />
                                    )}
                                    {activeTab === 4 && (
                                      <ServiceAndDelivery
                                        warrantyTypeId={warrantyTypeId}
                                        setWarrantyTypeId={setWarrantyTypeId}
                                        warrantyPeriodId={warrantyPeriodId}
                                        setWarrantyPeriodId={
                                          setWarrantyPeriodId
                                        }
                                        warrantyPolicy={warrantyPolicy}
                                        setWarrantyPolicy={setWarrantyPolicy}
                                        packageWeight={packageWeight}
                                        setPackageWeight={setPackageWeight}
                                        packageLength={packageLength}
                                        setPackageLength={setPackageLength}
                                        packageWidth={packageWidth}
                                        setPackageWidth={setPackageWidth}
                                        packageHeight={packageHeight}
                                        setPackageHeight={setPackageHeight}
                                      />
                                    )}
                                    {activeTab === 5 && (
                                      <ReturnPolicy
                                        isReturnable={isReturnable}
                                        setIsReturnable={setIsReturnable}
                                        returnDuration={returnDuration}
                                        setReturnDuration={setReturnDuration}
                                        returnPolicy={returnPolicy}
                                        setReturnPolicy={setReturnPolicy}
                                      />
                                    )}
                                    {activeTab === 6 && (
                                      <EditSizeChart
                                        selectedSizeChart={selectedSizeChart}
                                        onSizeChartOptionsChange={
                                          onSizeChartOptionsChange
                                        }
                                        shopId={shopId}
                                        newCategoryId={newCategoryId}
                                        isShopChanged={isShopChanged}
                                      />
                                    )}

                                    {activeTab === 7 && (
                                      <CreateProductAttribute
                                        newCategoryId={newCategoryId}
                                        selectedAttributes={selectedAttributes}
                                        setSelectedAttributes={
                                          setSelectedAttributes
                                        }
                                        productVariant={productVariant}
                                      // handleAttributeChanges={
                                      //   handleAttributeChange
                                      // }
                                      />
                                    )}
                                  </div>
                                )}

                                {/* SUBMIT BUTTON Start*/}
                                <div className="form-actions m-t-30 fixed-footer">
                                  {updating === false ? (
                                    <>
                                      <button
                                        type="submit"
                                        className="btn btn-success"
                                        onClick={() => updateProduct()}
                                        disabled={!burnOK || !discountOK}
                                      >
                                        <i className="fa fa-check"></i> Update
                                      </button>
                                      <button
                                        onClick={() => handleCancel()}
                                        className="btn btn-default"
                                        style={{ cursor: "pointer" }}
                                      >
                                        Cancel
                                      </button>
                                    </>
                                  ) : (
                                    <div style={{ textAlign: "center" }}>
                                      <Loader />
                                    </div>
                                  )}
                                </div>
                                {/* SUBMIT BUTTON End*/}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      </div>
      {/* <ToastContainer autoClose={1000} /> */}
      <Modal
        className="category_change_modal"
        // title="Confirm Category Change?"
        top
        open={visibleDialog}
        onOk={() => setNewCategoryId(categoryId)}
        width={'25%'}
        closeIcon={false}
      >
        <CategoryChangeModal
          setNewCategoryId={setNewCategoryId}
          setCategoryId={setCategoryId}
          newCategoryId={newCategoryId}
          categoryId={categoryId}
          setVisibleDialog={setVisibleDialog}
          setSelectedCategoryId={setSelectedCategoryId}
          setSelectedAttributes={setSelectedAttributes}
          setProductDetails={setProductDetails}
          setNewProductDetails={setNewProductDetails}
          setAllProductVariants={setAllProductVariants}
          setVariants={setVariants}
          setProductVariantMapArray0={setProductVariantMapArray0}
          setProductVariantMapArray1={setProductVariantMapArray1}
          setProductVariantMapArray2={setProductVariantMapArray2}
          setProductVariantMapArray3={setProductVariantMapArray3}
          setProductVariantAdded={setProductVariantAdded}
        />
      </Modal>
    </>
  );
};

export default EditSellerProductContainer;



