//////////////////////This is import for API Call/////////////
import campaignService from "../services/campaignService"

export const CREATE_OR_UPDATE_CAMPAIGN = "CREATE_OR_UPDATE_CAMPAIGN"
export const CREATE_OR_UPDATE_CAMPAIGN_SUCCESS = "CREATE_OR_UPDATE_CAMPAIGN_SUCCESS"
export const CREATE_OR_UPDATE_CAMPAIGN_ERROR = "CREATE_OR_UPDATE_CAMPAIGN_ERROR"
export const CREATE_OR_UPDATE_CAMPAIGN_RESET = "CREATE_OR_UPDATE_CAMPAIGN_RESET"

export const GET_CAMPAIGN = "GET_CAMPAIGN"
export const GET_CAMPAIGN_SUCCESS = "GET_CAMPAIGN_SUCCESS"
export const GET_CAMPAIGN_ERROR = "GET_CAMPAIGN_ERROR"

export const ADD_OR_EDIT_CAMPAIGN_PRODUCT_LIST = "ADD_OR_EDIT_CAMPAIGN_PRODUCT_LIST"
export const ADD_OR_EDIT_CAMPAIGN_PRODUCT_LIST_SUCCESS = "ADD_OR_EDIT_CAMPAIGN_PRODUCT_LIST_SUCCESS"
export const ADD_OR_EDIT_CAMPAIGN_PRODUCT_LIST_ERROR = "ADD_OR_EDIT_CAMPAIGN_PRODUCT_LIST_ERROR"
export const ADD_OR_EDIT_CAMPAIGN_PRODUCT_LIST_RESET = "ADD_OR_EDIT_CAMPAIGN_PRODUCT_LIST_RESET"

export const GET_ADDIBLE_CAMPAIGN_PRODUCTS = "GET_ADDIBLE_CAMPAIGN_PRODUCTS"
export const GET_ADDIBLE_CAMPAIGN_PRODUCTS_SUCCESS = "GET_ADDIBLE_CAMPAIGN_PRODUCTS_SUCCESS"
export const GET_ADDIBLE_CAMPAIGN_PRODUCTS_ERROR = "GET_ADDIBLE_CAMPAIGN_PRODUCTS_ERROR"

export const GET_PENDING_CAMPAIGN_PRODUCTS = "GET_PENDING_CAMPAIGN_PRODUCTS"
export const GET_PENDING_CAMPAIGN_PRODUCTS_SUCCESS = "GET_PENDING_CAMPAIGN_PRODUCTS_SUCCESS"
export const GET_PENDING_CAMPAIGN_PRODUCTS_ERROR = "GET_PENDING_CAMPAIGN_PRODUCTS_ERROR"

export const GET_APPROVED_CAMPAIGN_PRODUCTS = "GET_APPROVED_CAMPAIGN_PRODUCTS"
export const GET_APPROVED_CAMPAIGN_PRODUCTS_SUCCESS = "GET_APPROVED_CAMPAIGN_PRODUCTS_SUCCESS"
export const GET_APPROVED_CAMPAIGN_PRODUCTS_ERROR = "GET_APPROVED_CAMPAIGN_PRODUCTS_ERROR"

export const GET_STATUS_WISE_CAMPAIGN_PRODUCTS = "GET_STATUS_WISE_CAMPAIGN_PRODUCTS"
export const GET_STATUS_WISE_CAMPAIGN_PRODUCTS_SUCCESS = "GET_STATUS_WISE_CAMPAIGN_PRODUCTS_SUCCESS"
export const GET_STATUS_WISE_CAMPAIGN_PRODUCTS_ERROR = "GET_STATUS_WISE_CAMPAIGN_PRODUCTS_ERROR"

export const CAMPAIGN_PRODUCT_STATUS_CHANGE = "CAMPAIGN_PRODUCT_STATUS_CHANGE"
export const CAMPAIGN_PRODUCT_STATUS_CHANGE_SUCCESS = "CAMPAIGN_PRODUCT_STATUS_CHANGE_SUCCESS"
export const CAMPAIGN_PRODUCT_STATUS_CHANGE_ERROR = "CAMPAIGN_PRODUCT_STATUS_CHANGE_ERROR"
export const CAMPAIGN_PRODUCT_STATUS_CHANGE_RESET = "CAMPAIGN_PRODUCT_STATUS_CHANGE_RESET"

export function createOrUpdateCampaignRecord(campaignData) {
    return async (dispatch) => {
        dispatch(createOrUpdateCampaign())
        return await campaignService
            .createOrUpdateCampaign(campaignData)
            .then((response) => dispatch(createOrUpdateCampaignSuccess(response)))
            .catch((error) => dispatch(createOrUpdateCampaignError(error)))
    }
}

export function createOrUpdateCampaign() {
    return {
        type: CREATE_OR_UPDATE_CAMPAIGN,
    }
}

export function createOrUpdateCampaignSuccess(success) {
    return {
        type: CREATE_OR_UPDATE_CAMPAIGN_SUCCESS,
        payload: { success },
    }
}

export function createOrUpdateCampaignError(error) {
    return {
        type: CREATE_OR_UPDATE_CAMPAIGN_ERROR,
        payload: { error },
    }
}

export function createOrUpdateCampaignReset() {
    return {
        type: CREATE_OR_UPDATE_CAMPAIGN_RESET,
    }
}


export function getCampaignRecord(query) {
    return async (dispatch) => {
        dispatch(getCampaign())
        return await campaignService
            .getCampaign(query)
            .then((response) => dispatch(getCampaignSuccess(response)))
            .catch((error) => dispatch(getCampaignError(error)))
    }
}

export function getCampaign() {
    return {
        type: GET_CAMPAIGN,
    }
}

export function getCampaignSuccess(success) {
    return {
        type: GET_CAMPAIGN_SUCCESS,
        payload: { success },
    }
}

export function getCampaignError(error) {
    return {
        type: GET_CAMPAIGN_ERROR,
        payload: { error },
    }
}


export function addOrEditCampaignProductListRecord(campaignData) {
    return async (dispatch) => {
        dispatch(addOrEditCampaignProductList())
        return await campaignService
            .addOrEditCampaignProductList(campaignData)
            .then((response) => dispatch(addOrEditCampaignProductListSuccess(response)))
            .catch((error) => dispatch(addOrEditCampaignProductListError(error)))
    }
}

export function addOrEditCampaignProductList() {
    return {
        type: ADD_OR_EDIT_CAMPAIGN_PRODUCT_LIST,
    }
}

export function addOrEditCampaignProductListSuccess(success) {
    return {
        type: ADD_OR_EDIT_CAMPAIGN_PRODUCT_LIST_SUCCESS,
        payload: { success },
    }
}

export function addOrEditCampaignProductListError(error) {
    return {
        type: ADD_OR_EDIT_CAMPAIGN_PRODUCT_LIST_ERROR,
        payload: { error },
    }
}

export function addOrEditCampaignProductListReset() {
    return {
        type: ADD_OR_EDIT_CAMPAIGN_PRODUCT_LIST_RESET,
    }
}
export function getAddibleCampaignProductsRecord(query) {
    return async (dispatch) => {
        dispatch(getAddibleCampaignProducts())
        return await campaignService
            .getAddibleCampaignProducts(query)
            .then((response) => dispatch(getAddibleCampaignProductsSuccess(response)))
            .catch((error) => dispatch(getAddibleCampaignProductsError(error)))
    }
}

export function getAddibleCampaignProducts() {
    return {
        type: GET_ADDIBLE_CAMPAIGN_PRODUCTS,
    }
}

export function getAddibleCampaignProductsSuccess(success) {
    return {
        type: GET_ADDIBLE_CAMPAIGN_PRODUCTS_SUCCESS,
        payload: { success },
    }
}

export function getAddibleCampaignProductsError(error) {
    return {
        type: GET_ADDIBLE_CAMPAIGN_PRODUCTS_ERROR,
        payload: { error },
    }
}

export function getPendingCampaignProductsRecord(query) {
    return async (dispatch) => {
        dispatch(getPendingCampaignProducts())
        return await campaignService
            .getPendingCampaignProducts(query)
            .then((response) => dispatch(getPendingCampaignProductsSuccess(response)))
            .catch((error) => dispatch(getPendingCampaignProductsError(error)))
    }
}

export function getPendingCampaignProducts() {
    return {
        type: GET_PENDING_CAMPAIGN_PRODUCTS,
    }
}

export function getPendingCampaignProductsSuccess(success) {
    return {
        type: GET_PENDING_CAMPAIGN_PRODUCTS_SUCCESS,
        payload: { success },
    }
}

export function getPendingCampaignProductsError(error) {
    return {
        type: GET_PENDING_CAMPAIGN_PRODUCTS_ERROR,
        payload: { error },
    }
}

export function getApprovedCampaignProductsRecord(query) {
    return async (dispatch) => {
        dispatch(getApprovedCampaignProducts())
        return await campaignService
            .getApprovedCampaignProducts(query)
            .then((response) => dispatch(getApprovedCampaignProductsSuccess(response)))
            .catch((error) => dispatch(getApprovedCampaignProductsError(error)))
    }
}

export function getApprovedCampaignProducts() {
    return {
        type: GET_APPROVED_CAMPAIGN_PRODUCTS,
    }
}

export function getApprovedCampaignProductsSuccess(success) {
    return {
        type: GET_APPROVED_CAMPAIGN_PRODUCTS_SUCCESS,
        payload: { success },
    }
}

export function getApprovedCampaignProductsError(error) {
    return {
        type: GET_APPROVED_CAMPAIGN_PRODUCTS_ERROR,
        payload: { error },
    }
}

export function getStatusWiseCampaignProductsRecord(query) {
    return async (dispatch) => {
        dispatch(getStatusWiseCampaignProducts())
        return await campaignService
            .getStatusWiseCampaignProducts(query)
            .then((response) => dispatch(getStatusWiseCampaignProductsSuccess(response)))
            .catch((error) => dispatch(getStatusWiseCampaignProductsError(error)))
    }
}

export function getStatusWiseCampaignProducts() {
    return {
        type: GET_STATUS_WISE_CAMPAIGN_PRODUCTS,
    }
}

export function getStatusWiseCampaignProductsSuccess(success) {
    return {
        type: GET_STATUS_WISE_CAMPAIGN_PRODUCTS_SUCCESS,
        payload: { success },
    }
}

export function getStatusWiseCampaignProductsError(error) {
    return {
        type: GET_STATUS_WISE_CAMPAIGN_PRODUCTS_ERROR,
        payload: { error },
    }
}


export function campaignProductStatusChangeRecord(data) {
    return async (dispatch) => {
        dispatch(campaignProductStatusChange())
        return await campaignService
            .campaignProductStatusChange(data)
            .then((response) => dispatch(campaignProductStatusChangeSuccess(response)))
            .catch((error) => dispatch(campaignProductStatusChangeError(error)))
    }
}

export function campaignProductStatusChange() {
    return {
        type: CAMPAIGN_PRODUCT_STATUS_CHANGE,
    }
}

export function campaignProductStatusChangeSuccess(success) {
    return {
        type: CAMPAIGN_PRODUCT_STATUS_CHANGE_SUCCESS,
        payload: { success },
    }
}

export function campaignProductStatusChangeError(error) {
    return {
        type: CAMPAIGN_PRODUCT_STATUS_CHANGE_ERROR,
        payload: { error },
    }
}

export function campaignProductStatusChangeReset() {
    return {
        type: CAMPAIGN_PRODUCT_STATUS_CHANGE_RESET,
    }
}