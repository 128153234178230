import http from "../../utils/httpClient";
class refundService {
  postRefundData = async (data) => {
    return await http.post(`/api/Payment/CreateCustomerRefund`, data);
  };
  fetchRefundedData = async (
    data,
    currentPage,
    itemPerPage,
    invoiceNo,
    customerName,
    paymentMethod,
    tranNo
  ) => {
    return await http.get(
      `/api/Payment/GetPaymentCustomerRefund?requestType=${data}&tranNo=${
        tranNo || ""
      }&orderInvoiceNo=${invoiceNo || ""}&customerName=${
        customerName || ""
      }&methodname=${
        paymentMethod || ""
      }&currentPage=${currentPage}&itemsPerPage=${itemPerPage}`
    );
  };
}
export default new refundService();
