import React from 'react';
import { toast } from "react-toastify";

const CategoryChangeModal = ({
    setProductDetails,
    newCategoryId,
    setCategoryId,
    setSelectedAttributes,
    setSelectedCategoryId,
    setVisibleDialog,
    setNewCategoryId,
    categoryId,
    setNewProductDetails,
    setAllProductVariants,
    setVariants,
    setProductVariantMapArray0,
    setProductVariantMapArray1,
    setProductVariantMapArray2,
    setProductVariantMapArray3,
    setProductVariantAdded
}) => {

    const handleOk = () => {
        toast.error("Category Modified!");
        setProductDetails(null)
        setSelectedAttributes([])
        setCategoryId(newCategoryId)
        setNewProductDetails(null)
        setAllProductVariants(null)
        setVariants([])
        setProductVariantMapArray0([])
        setProductVariantMapArray1([])
        setProductVariantMapArray2([])
        setProductVariantMapArray3([])
        setProductVariantAdded(null)
        setVisibleDialog(false);
    };

    const handleCancel = () => {
        setNewCategoryId(categoryId)
        setSelectedCategoryId(categoryId)
        setVisibleDialog(false);
    };

    return (
        <div>
            <p style={{
                fontWeight: '600',
                fontSize: '19px',
                color: 'red'
            }}>Changing category will remove all previous variants and attributes. </p>
            <p style={{
                fontWeight: '600',
                fontSize: '15px'
            }}>Are you Confirm to change category ?</p>
            <div className='buttons-show'>
                <button className='btn btn-success' style={{ backgroundColor: "#449d44", borderColor: "#449d44" }} onClick={handleOk}>Confirm</button>
                <button className='btn btn-danger' onClick={handleCancel}>Cancel</button>
            </div>

        </div>
    )
}

export default CategoryChangeModal