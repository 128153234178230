import React from 'react'
import { BiBarcodeReader } from 'react-icons/bi'
import * as Icon from 'react-feather'
import './report.css'

const SalesReportFilterOptions = (props) => {
  const { handleOptionChange, option, order_filter_tab } = props

  return (
    <div className='option-container'>
      <>
        {/* Button for All */}
        {/* <button
          className={`option_button ${option === 0 ? 'option-active' : ''}`}
          onClick={() => handleOptionChange(0)}
        >
          <Icon.Home
            className={`icon-opt ${option === 0 ? 'icon-active' : ''}`}
          />
          <span className='option-text'>All</span>
        </button> */}

        {/* Order Date Button Summary*/}
        {order_filter_tab === 'sales_report' && (
          <button
            className={`option_button ${option === 5 ? 'option-active' : ''}`}
            onClick={() => handleOptionChange(5)}
          >
            <Icon.Calendar
              className={`icon-opt ${option === 5 ? 'icon-active' : ''}`}
            />
            <span className='option-text'>Date Wise Sales Summary (NBR)</span>
          </button>
        )}
        {order_filter_tab === 'sales_report' && (
          <button
            className={`option_button ${option === 1 ? 'option-active' : ''}`}
            onClick={() => handleOptionChange(1)}
          >
            <Icon.Calendar
              className={`icon-opt ${option === 1 ? 'icon-active' : ''}`}
            />
            <span className='option-text'>Date Wise Sales Summary</span>
          </button>
        )}

        {/* Order Date Button Details*/}
        {order_filter_tab === 'sales_report' && (
          <button
            className={`option_button ${option === 2 ? 'option-active' : ''}`}
            onClick={() => handleOptionChange(2)}
          >
            <Icon.Calendar
              className={`icon-opt ${option === 2 ? 'icon-active' : ''}`}
            />
            <span className='option-text'>Date Wise Sales Details</span>
          </button>
        )}

        {/* Payment Method Summary Button*/}
        {order_filter_tab === 'sales_report' && (
          <button
            className={`option_button ${option === 3 ? 'option-active' : ''}`}
            onClick={() => handleOptionChange(3)}
          >
            <Icon.DollarSign
              className={`icon-opt ${option === 3 ? 'icon-active' : ''}`}
            />
            <span className='option-text'>Payment Type Wise Sales Summary</span>
          </button>
        )}

        {/* Payment Method Details Button*/}
        {order_filter_tab === 'sales_report' && (
          <button
            className={`option_button ${option === 4 ? 'option-active' : ''}`}
            onClick={() => handleOptionChange(4)}
          >
            <Icon.DollarSign
              className={`icon-opt ${option === 4 ? 'icon-active' : ''}`}
            />
            <span className='option-text'>Payment Type Wise Sales Details</span>
          </button>
        )}

        {/* Payment Method Details Button*/}
        {order_filter_tab === 'sales_report' && (
          <button
            className={`option_button ${option === 6 ? 'option-active' : ''}`}
            onClick={() => handleOptionChange(6)}
          >
            <Icon.Calendar
              className={`icon-opt ${option === 6 ? 'icon-active' : ''}`}
            />
            <span className='option-text'>Date Wise Payment Realization</span>
          </button>
        )}

      </>
    </div>
  )
}

export default SalesReportFilterOptions
