import React, { Component } from "react";
import { connect } from "react-redux";
import SellerHomeNew from "../../components/home/SellerHomeNew";
import authService from "../../store/services/authService";

class sellerHomeContainer extends Component {
  componentDidMount = async () => {
    // For Login Check
    let userId = authService.getEmployeeId();

    if (userId != null) {
      this.setState({
        authenticated: true,
        loginSuccessful: true,
      });
    } else {
      this.setState({
        authenticated: false,
        loginSuccessful: false,
      });

      this.props.history.push("/SellerLogin");
    }
  };

  render() {
    return (
      <div id="wrapper">
        <SellerHomeNew key="SellerHome" {...this.state} />
      </div>
    );
  }
}

export default connect()(sellerHomeContainer);
