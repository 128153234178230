/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Calendar } from "primereact/calendar";
import { DataTable } from 'primereact/datatable'
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import LoadingCard from '../../components/shared/LoadingCard'
import { sellerStatusWiseOrderListRecord, getSellerOrderDetailsRecord, getCountOrderStatusRecord, getOrderStatusWiseRecord } from '../../store/actions/orderAction'
import { Modal } from 'antd'
import SellerOrderTracking from '../../components/seller/SellerOrderTracking'
import authenticationService from '../../store/services/authenticationService'
import moment from 'moment'
import { Paginator } from '../../components/paginator/Paginator';
import { getPaymentMethodRecord } from '../../store/actions/paymentMethodAction';
import { getAllSellerRecord } from '../../store/actions/sellerAction';
import toIsoString from '../../utils/Functions/toIsoString';
import { getBrandRecord } from '../../store/actions/brandAction';


const paymentStatusArr = [
  { paymentStatus: "Initial", code: 1 },
  { paymentStatus: "Pending", code: 2 },
  { paymentStatus: "Success", code: 3 },
  { paymentStatus: "Partial", code: 4 },
  { paymentStatus: "Failed", code: 5 },
  { paymentStatus: "Canceled", code: 6 },
];

export default function ManageOrderContainer_v2() {
  const [globalFilter, setGlobalFilter] = useState(null)
  const [orderInfo, setOrderInfo] = useState(null)
  const [visible, setVisible] = useState(false)
  const [counts, setCounts] = useState(1)
  const [selectedOrders, setSelectedOrders] = useState(null)
  const [statusId, setStatusId] = useState(null)
  const [statusName, setStatusName] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [itemPerPage, setItemPerPage] = useState(30)
  const [totalPage, setTotalPage] = useState(null)
  const [totalItems, setTotalItems] = useState(null)
  const [customerData, setCustomerData] = useState(null);


  const [searchKeyword, setSearchKeyword] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [brand, setBrand] = useState(null);
  // const [selectVendor, setSelectVendor] = useState(null);
  const [selectPaymentStatus, setSelectPaymentStatus] = useState(null);
  const [selectPaymentMethod, setSelectPaymentMethod] = useState(null);
  const [filterOrder, setFilterOrder] = useState(false);

  const [statusNoArray, setStatusNoArray] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
  let initialStatusNoArray = [0, 0, 0, 0, 0, 0, 0, 0];

  const brands = useSelector((state) => state.brandReducer.brands);
  // const vendorList = useSelector((state) => state.sellerReducer.sellerList);
  const paymentMethods = useSelector((state) => state.paymentMethodReducer.paymentMethods);

  const dispatch = useDispatch()
  let history = useHistory()


  const location = useHistory()
  const order_filter_tab = location?.location?.search?.substring(8)
  const order_status = order_filter_tab.replaceAll('_', ' ')
  // const status_arr = order_status.split(' ')
  // for (var i = 0; i < status_arr.length; i++) {
  //   status_arr[i] =
  //     status_arr[i].charAt(0).toUpperCase() + status_arr[i].slice(1)
  // }
  // const reformed_status = status_arr.join(' ')

  const orders_info = useSelector(
    (state) => state.orderReducer.sellerStatusWiseOrderList);
  const loading = useSelector((state) => state.orderReducer.loading)
  const {
    getCountOrderStatusData,
    getCountOrderStatusLoading,
    getCountOrderStatusError,
  } = useSelector((state) => state.orderReducer);
  const order_list = orders_info.data;

  const allStatusInfo = (allStatusData, statusNo) => {
    allStatusData &&
      allStatusData.map((data) => {
        if (data.statusName === "Pending") {
          statusNo[0] = data.countStatus;
        } else if (data.statusName === "Order Confirm") {
          statusNo[1] = data.countStatus;
        } else if (data.statusName === "Order Processing") {
          statusNo[2] = data.countStatus;
        } else if (data.statusName === "Ready to Ship") {
          statusNo[3] = data.countStatus;
        } else if (data.statusName === "Picked") {
          statusNo[4] = data.countStatus;
        } else if (data.statusName === "Delivered") {
          statusNo[5] = data.countStatus;
        } else if (data.statusName === "Delivery Failed") {
          statusNo[6] = data.countStatus;
        } else if (data?.statusName === "Order Placed") {
          statusNo[7] = data?.countStatus;
        }
        setStatusNoArray(statusNo);
      });
  };

  useEffect(() => {
    dispatch(getCountOrderStatusRecord());
  }, [dispatch, order_list]);

  // useEffect(() => {
  //   setInterval(async () => {
  //     dispatch(getCountOrderStatusRecord());
  //   }, 10000);
  // }, []);

  useEffect(() => {
    getCountOrderStatusData?.data &&
      allStatusInfo(getCountOrderStatusData?.data, initialStatusNoArray);
  }, [getCountOrderStatusData?.data, order_list]);

  const sellerOrderDetails = useSelector(
    (state) => state.orderReducer.sellerOrderDetails
  )
  useEffect(() => {
    sellerOrderDetails && setOrderInfo(sellerOrderDetails?.data)
  }, [sellerOrderDetails])

  useEffect(() => {
    if (orders_info?.headers?.pagination) {
      var paginated_data_to_parse = orders_info.headers.pagination
      const paginated_data = JSON.parse(paginated_data_to_parse)
      setCurrentPage(paginated_data.currentPage)
      setTotalPage(paginated_data.totalPages)
      setTotalItems(paginated_data.totalItems)
      setItemPerPage(paginated_data.itemsPerPage)
    }
  }, [orders_info?.headers?.pagination])


  const seller_info = useSelector(
    (state) => state.sellerProfileReducer.sellerProfileById
  )

  const handleItemPerPage = (pagePerItems) => {
    setCurrentPage(1)
    setItemPerPage(pagePerItems)
  }
  const handleCurrentPage = (currentPage) => {
    setCurrentPage(currentPage)
  }
  const handleSellerManageOrder = (orderProfileId) => {
    dispatch(getSellerOrderDetailsRecord(orderProfileId));
  };


  const invoiceType = 1


  useEffect(() => {
    let roleId = authenticationService.getRoleId()
    if (roleId === '2') {
    } else {
      history.push('/SellerLogin')
    }
  }, [])

  useEffect(() => {
    setStatusId(null)
    setStatusName(null)
  }, [visible]);

  useEffect(() => {
    const sellerOrders = {
      statusName: order_status,
      invoiceNumber: '',
      searchKeyword: '',
      // vendor: selectVendor?.shopId,
      brand:brand?.brandId,
      currentPage,
      itemsPerPage: itemPerPage
    }
    if (startDate) {
      sellerOrders.startDate = toIsoString(startDate).substring(0, 10)
    } if (endDate) {
      sellerOrders.endDate = toIsoString(endDate).substring(0, 10)
    }

    if (filterOrder) {
      dispatch(sellerStatusWiseOrderListRecord(sellerOrders));
    }


    return () => {
      setFilterOrder(false);
    };
  }, [
    dispatch,
    filterOrder,
    // counts,
    // order_filter_tab,
    currentPage,
    itemPerPage,
  ]);

  useEffect(() => {
    const sellerAllOrders = {
      statusName: order_status,
      currentPage,
      itemsPerPage: itemPerPage
    }

    dispatch(sellerStatusWiseOrderListRecord(sellerAllOrders));
  }, [dispatch, order_status, currentPage, itemPerPage])

  useEffect(() => {
    dispatch(getPaymentMethodRecord());
    // dispatch(getAllSellerRecord());
    dispatch(getBrandRecord());
  }, [dispatch]);

  const orderNoBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span>{rowData.orderNo}</span>
      </React.Fragment>
    )
  }
  const orderInvoiceNoBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span>{rowData.orderInvoiceNo}</span>
      </React.Fragment>
    )
  }
  const customerNameBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span>{rowData.customerName}</span>
      </React.Fragment>
    )
  }

  const orderDateTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span>
          {moment(rowData.createDate).format('Do MMMM YYYY, h:mm A')}{' '}
        </span>
      </React.Fragment>
    )
  }
  const paymentMethodTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span>
          {rowData.paymentMethodName
            ? rowData.paymentMethodName === 'COD'
              ? 'Cash On Delivery'
              : rowData.paymentMethodName
            : 'Not Selected Yet'}
        </span>
      </React.Fragment>
    )
  }
  const productPriceBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span>{rowData.totalPayableAmt}</span>
      </React.Fragment>
    )
  }

  const productDetailsTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          onClick={() => {
            handleSellerManageOrder(
              rowData?.orderProfileId
            );
            setCustomerData(rowData)
            setVisible(true)
          }}
        >
          Details
        </Button>
      </React.Fragment>
    )
  }

  return (
    <div className='page-wrapper'>
      <div className='container-fluid'>
        <div className='col-md-12'>
          <div className='white-box'>
            <div className='datatable-doc-demo datatable-responsive-demo'>
              <div className='card'>
                <ul class='nav nav-tabs seller-tabs'>
                  <li>
                    <Link
                      to={`/ManageOrder?status=order_placed`}
                      style={
                        order_filter_tab === "order_placed"
                          ? { color: "#1F5DA0", borderBottom: '2px solid #1F5DA0', fontWeight: "700" }
                          : null
                      }
                    >
                      New Order{" "}
                      <span style={{ fontWeight: "700", color: "#1F5DA0" }}>
                        {statusNoArray[7] === 0 ? null : (
                          <span>
                            (
                            <span style={{ color: "#D71110" }}>
                              {statusNoArray[7]}
                            </span>
                            )
                          </span>
                        )}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/ManageOrder?status=pending`}
                      style={
                        order_filter_tab === 'pending'
                          ? { color: "#1F5DA0", borderBottom: '2px solid #1F5DA0', fontWeight: "700" }
                          : null
                      }
                    >
                      Pending{" "}
                      <span style={{ fontWeight: "700", color: "#1F5DA0" }}>
                        {statusNoArray[0] === 0 ? null : (
                          <span>
                            (
                            <span style={{ color: "#D71110" }}>
                              {statusNoArray[0]}
                            </span>
                            )
                          </span>
                        )}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/ManageOrder?status=order_confirm`}
                      style={
                        order_filter_tab === 'order_confirm'
                          ? { color: "#1F5DA0", borderBottom: '2px solid #1F5DA0', fontWeight: "700" }
                          : null
                      }
                    >
                      Order Confirm{" "}
                      <span style={{ fontWeight: "700", color: "#1F5DA0" }}>
                        {statusNoArray[1] === 0 ? null : (
                          <span>
                            (
                            <span style={{ color: "#D71110" }}>
                              {statusNoArray[1]}
                            </span>
                            )
                          </span>
                        )}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/ManageOrder?status=order_processing`}
                      style={
                        order_filter_tab === 'order_processing'
                          ? { color: "#1F5DA0", borderBottom: '2px solid #1F5DA0', fontWeight: "700" }
                          : null
                      }
                    >
                      Order Processing{" "}
                      <span style={{ fontWeight: "700", color: "#1F5DA0" }}>
                        {statusNoArray[2] === 0 ? null : (
                          <span>
                            (
                            <span style={{ color: "#D71110" }}>
                              {statusNoArray[2]}
                            </span>
                            )
                          </span>
                        )}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/ManageOrder?status=ready_to_ship`}
                      style={
                        order_filter_tab === 'ready_to_ship'
                          ? { color: "#1F5DA0", borderBottom: '2px solid #1F5DA0', fontWeight: "700" }
                          : null
                      }
                    >
                      Ready to Ship{" "}
                      <span style={{ fontWeight: "700", color: "#1F5DA0" }}>
                        {statusNoArray[3] === 0 ? null : (
                          <span>
                            (
                            <span style={{ color: "#D71110" }}>
                              {statusNoArray[3]}
                            </span>
                            )
                          </span>
                        )}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/ManageOrder?status=picked`}
                      style={
                        order_filter_tab === 'picked'
                          ? { color: "#1F5DA0", borderBottom: '2px solid #1F5DA0', fontWeight: "700" }
                          : null
                      }
                    >
                      Picked{" "}
                      <span style={{ fontWeight: "700", color: "#1F5DA0" }}>
                        {statusNoArray[4] === 0 ? null : (
                          <span>
                            (
                            <span style={{ color: "#D71110" }}>
                              {statusNoArray[4]}
                            </span>
                            )
                          </span>
                        )}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/ManageOrder?status=delivered`}
                      style={
                        order_filter_tab === 'delivered'
                          ? { color: "#1F5DA0", borderBottom: '2px solid #1F5DA0', fontWeight: "700" }
                          : null
                      }
                    >
                      Delivered
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/ManageOrder?status=delivery_failed`}
                      style={
                        order_filter_tab === 'delivery_failed'
                          ? { color: "#1F5DA0", borderBottom: '2px solid #1F5DA0', fontWeight: "700" }
                          : null
                      }
                    >
                      Delivery Failed{" "}
                      <span style={{ fontWeight: "700", color: "#1F5DA0" }}>
                        {statusNoArray[6] === 0 ? null : (
                          <span>
                            (
                            <span style={{ color: "#D71110" }}>
                              {statusNoArray[6]}
                            </span>
                            )
                          </span>
                        )}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/ManageOrder?status=all`}
                      style={
                        order_filter_tab === "all"
                          ? { color: "#7391CD" }
                          : null
                      }
                    >
                      All Orders{" "}
                      {/* <span style={{ fontWeight: "700", color: "#1F5DA0" }}>
                        {statusNoArray[6] === 0 ? null : (
                          <span>
                            (
                            <span style={{ color: "#D71110" }}>
                              {statusNoArray[6]}
                            </span>
                            )
                          </span>
                        )}
                      </span> */}
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                style={{
                  display: 'flex',
                  gap: '10px',
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: '15px',
                }}
              >
                <p
                  style={{
                    display: 'flex',
                    fontWeight: '600',
                    fontSize: '16px',
                    margin: '0',
                    // background: 'red'
                  }}
                >
                  {/* <span style={{ marginRight: '4px' }}>
                    {order_filter_tab === 'order_placed' ? (
                      <p>New Order</p>
                    ) : order_filter_tab === 'pending' ? (
                      <p>Pending</p>
                    ) : order_filter_tab === 'order_confirm' ? (
                      <p>Order Confirm</p>
                    ) : order_filter_tab === 'order_processing' ? (
                      <p>Order Processing</p>
                    ) : order_filter_tab === 'ready_to_ship' ? (
                      <p>Ready to Ship</p>
                    ) : order_filter_tab === 'picked' ? (
                      <p>Picked</p>
                    ) : order_filter_tab === 'delivered' ? (
                      <p>Delivered</p>
                    ) : order_filter_tab === 'shipped' ? (
                      <p>Shipped</p>
                    ) : order_filter_tab === 'delivery_failed' ? (
                      <p>Failed Delivery</p>
                    ) : (
                      <p>Return</p>
                    )}
                  </span>
                  Products List */}
                  <Link
                    to={{
                      pathname: `/SellerInvoice`,
                      data: selectedOrders,
                      invoiceType,
                      order_filter_tab,
                    }}
                  >
                    <button
                      className='btn btn-info'
                      style={{
                        padding: '4px 20px',
                        fontSize: '13px ',
                        marginRight: '5px',
                        marginLeft: '10px',
                      }}
                      disabled={
                        selectedOrders === null || selectedOrders?.length === 0
                      }
                    >
                      Print
                    </button>
                  </Link>
                </p>

                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                  className="order_filter__section"
                >
                  <div
                    style={{
                      margin: "0 2px",
                      width: "250px",
                      position: "relative",
                    }}
                  >
                    <InputText
                      style={{
                        width: "100%",
                      }}
                      className="manage_product__search"
                      value={searchKeyword}
                      onChange={(e) => setSearchKeyword(e.target.value)}
                      placeholder="Keyword"
                    />
                    {searchKeyword !== "" ? (
                      <button
                        style={{
                          position: "absolute",
                          top: "6px",
                          right: "8px",
                          borderRadius: "100%",
                          border: "1px solid #ced4da",
                          height: "25px",
                          width: "25px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={() => {
                          setSearchKeyword("");
                        }}
                      >
                        <i className="pi pi-times"></i>
                      </button>
                    ) : null}
                  </div>
                  <div
                    style={{
                      margin: "0 2px",
                      width: "155px",
                      position: "relative",
                    }}
                  >
                    <Calendar
                      id="icon"
                      maxDate={endDate}
                      value={startDate}
                      onChange={(e) => setStartDate(e.value)}
                      showIcon
                      placeholder="Start Date"
                      readOnlyInput
                    />
                    {startDate !== null ? (
                      <button
                        style={{
                          position: "absolute",
                          top: "6px",
                          right: "33px",
                          borderRadius: "100%",
                          border: "1px solid #ced4da",
                          height: "25px",
                          width: "25px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={() => {
                          setStartDate(null);
                        }}
                      >
                        <i className="pi pi-times"></i>
                      </button>
                    ) : null}
                  </div>
                  <div
                    style={{
                      margin: "0 2px",
                      width: "155px",
                      position: "relative",
                    }}
                  >
                    <Calendar
                      id="icon disableddays"
                      minDate={startDate}
                      value={endDate}
                      onChange={(e) => setEndDate(e.value)}
                      showIcon
                      placeholder="End Date"
                      readOnlyInput
                    />
                    {endDate !== null ? (
                      <button
                        style={{
                          position: "absolute",
                          top: "6px",
                          right: "33px",
                          borderRadius: "100%",
                          border: "1px solid #ced4da",
                          height: "25px",
                          width: "25px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={() => {
                          setEndDate(null);
                        }}
                      >
                        <i className="pi pi-times"></i>
                      </button>
                    ) : null}
                  </div>
                  {/* <div
                    style={{
                      margin: "0 2px",
                      width: "155px",
                      position: "relative",
                    }}
                  >
                    <Dropdown
                      style={{
                        width: "100%",
                      }}
                      value={selectVendor}
                      options={vendorList.data}
                      onChange={(e) => setSelectVendor(e.value)}
                      optionLabel="shopName"
                      placeholder="Select a Seller"
                      filter
                      showClear
                    />
                    {selectVendor !== null ? (
                      <button
                        style={{
                          position: "absolute",
                          top: "6px",
                          right: "8px",
                          borderRadius: "100%",
                          border: "1px solid #ced4da",
                          height: "25px",
                          width: "25px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={() => {
                          setSelectVendor(null);
                        }}
                      >
                        <i className="pi pi-times"></i>
                      </button>
                    ) : null}
                  </div> */}

                  <div
                    style={{ margin: "0 5px", width: "175px", position: "relative" }}
                  >
                    <Dropdown
                      style={{
                        width: "100%",
                      }}
                      value={brand}
                      options={brands}
                      filter
                      showClear
                      onChange={(e) => setBrand(e.value)}
                      optionLabel="brandName"
                      placeholder="Select a Brand"
                    />
                    {brand !== null ? (
                      <button
                        style={{
                          position: "absolute",
                          top: "6px",
                          right: "8px",
                          borderRadius: "100%",
                          border: "1px solid #ced4da",
                          height: "25px",
                          width: "25px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={() => {
                          setBrand(null);
                        }}
                      >
                        <i className="pi pi-times"></i>
                      </button>
                    ) : null}
                  </div>

                  <div
                    style={{
                      margin: "0 2px",
                      width: "160px",
                      position: "relative",
                    }}
                  >
                    <Dropdown
                      style={{
                        width: "100%",
                      }}
                      value={selectPaymentStatus}
                      options={paymentStatusArr}
                      onChange={(e) => setSelectPaymentStatus(e.value)}
                      optionLabel="paymentStatus"
                      placeholder="Payment Status"
                    />
                    {selectPaymentStatus !== null ? (
                      <button
                        style={{
                          position: "absolute",
                          top: "6px",
                          right: "8px",
                          borderRadius: "100%",
                          border: "1px solid #ced4da",
                          height: "25px",
                          width: "25px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={() => {
                          setSelectPaymentStatus(null);
                        }}
                      >
                        <i className="pi pi-times"></i>
                      </button>
                    ) : null}
                  </div>
                  <div
                    style={{
                      margin: "0 2px",
                      width: "160px",
                      position: "relative",
                    }}
                  >
                    <Dropdown
                      style={{
                        width: "100%",
                      }}
                      value={selectPaymentMethod}
                      options={paymentMethods}
                      onChange={(e) => setSelectPaymentMethod(e.value)}
                      optionLabel="methodName"
                      placeholder="Payment Method"
                      filter
                      showClear
                    />
                    {selectPaymentMethod !== null ? (
                      <button
                        style={{
                          position: "absolute",
                          top: "6px",
                          right: "8px",
                          borderRadius: "100%",
                          border: "1px solid #ced4da",
                          height: "25px",
                          width: "25px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={() => {
                          setSelectPaymentMethod(null);
                        }}
                      >
                        <i className="pi pi-times"></i>
                      </button>
                    ) : null}
                  </div>
                  <div style={{ position: "relative" }}>
                    <Button
                      onClick={() => {
                        setFilterOrder(true);
                        setCurrentPage(1);
                      }}
                      style={{ marginLeft: "3px" }}
                      label="Search"
                      disabled={startDate?.getTime() > endDate?.getTime()}
                    />
                    {startDate?.getTime() > endDate?.getTime() ? (
                      <>
                        <div
                          style={{
                            position: "absolute",
                            width: "230px",
                            background: "#A596F1",
                            color: "#fff",
                            padding: "4px",
                            left: "-165px",
                            top: "-29px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Start Date Should Be Older Than End Date
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            height: "10px",
                            width: "10px",
                            background: "#A596F1",
                            transform: "rotate(45deg)",
                            top: "-13px",
                            right: "27px",
                          }}
                        ></div>
                      </>
                    ) : null}
                  </div>
                </div>



                {/* <div style={{ width: '20%' }}>
                  <InputText
                    className='form-control text-center'
                    type='search'
                    value={globalFilter}
                    onChange={(e) => setGlobalFilter(e.target.value)}
                    placeholder='Search'
                  />
                </div> */}
              </div>
              <div className='card'>
                {loading ? (
                  <LoadingCard count={1} />
                ) : (
                  <>
                    <DataTable
                      className='p-datatable-customers p-datatable-responsive-demo'
                      value={orders_info.data}
                      rowHover
                      globalFilter={globalFilter}
                      emptyMessage='No order(s) found'
                      selection={selectedOrders}
                      onSelectionChange={(e) => setSelectedOrders(e.value)}
                    >
                      <Column selectionMode='multiple' />
                      <Column
                        field='orderInvoiceNo'
                        filterField='orderInvoiceNo'
                        header='Invoice No'
                        body={orderInvoiceNoBodyTemplate}
                        sortable
                      />
                      <Column
                        field='orderNo'
                        filterField='orderNo'
                        header='Order No'
                        body={orderNoBodyTemplate}
                        sortable
                      />
                      <Column
                        field='customerName'
                        filterField='customerName'
                        header='Customer Name'
                        body={customerNameBodyTemplate}
                        sortable
                      />
                      <Column
                        field='productTitle'
                        filterField='productTitle'
                        header='Order Date'
                        body={orderDateTemplate}
                        sortable
                      />
                      <Column
                        field='methodName'
                        filterField='methodName'
                        header='Payment Method'
                        body={paymentMethodTemplate}
                        sortable
                      />
                      <Column
                        field='bdtTotalPayableAmount'
                        filterField='bdtTotalPayableAmount'
                        header='Price'
                        body={productPriceBodyTemplate}
                        sortable
                      />
                      <Column header='Action' body={productDetailsTemplate} />
                    </DataTable>
                    <Paginator
                      totalPage={totalPage}
                      currentPage={currentPage}
                      itemPerPage={itemPerPage}
                      totalItems={totalItems}
                      items={orders_info.data}
                      itemsPerPageOptions={[30, 60, 90, 100, 200, 300]}
                      handleItemPerPage={handleItemPerPage}
                      handleCurrentPage={handleCurrentPage}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title='Order Details'
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width={1500}
      >
        <SellerOrderTracking
          orderInfo={orderInfo}
          customerData={customerData}
          setVisible={setVisible}
          setCounts={setCounts}
          counts={counts}
          seller_info={seller_info}
          order_filter_tab={order_filter_tab}
          setStatusId={setStatusId}
          setStatusName={setStatusName}
          statusId={statusId}
          statusName={statusName}
        />
      </Modal>
    </div>
  )
}
