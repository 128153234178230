import React from "react";
import { Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Loader } from "../../containers";
import baseUrl from "../../utils/baseUrl";
import LoginHeader from "../shared/atoms/LoginHeader";
import './styles/sellerStyle.css';

const SellerShopInfo = (props) => {
  return (
    <div className="">
      <section
        id="wrapper"
        className="seller-login-register-detail"
      >
        <LoginHeader />
        <div className="container-reg">
          <div
            className="white-box-shop"
            style={{
              padding: "20px",
            }}
          >
            <div className="text-center">
              <h2>Seller Registration</h2>
            </div>

            <div className="row" style={{ marginTop: '15px' }}>
              <div className="col-md-12 col-sm-12">
                <form className="form-horizontal">
                  <div className="row">

                    <div className="col-md-3 col-sm-12">
                      <div className="form-group ">
                        <label>
                          Shop Name{" "}
                          <span
                            aria-hidden="true"
                            style={{
                              color: "red",
                              fontWeight: "bold",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Shop Name"
                          name="shopName"
                          value={props?.shopName}
                          onChange={props.handleChange}
                          className={"form-control"}
                        />
                      </div>
                    </div>

                    <div className="col-md-3 col-sm-12">
                      <div className="form-group ">
                        <label>
                          Shop Owner Name{" "}
                          <span
                            aria-hidden="true"
                            style={{
                              color: "red",
                              fontWeight: "bold",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Shop Owner Name"
                          name="ownerName"
                          value={props?.ownerName}
                          onChange={props.handleChange}
                          className={"form-control"}
                        />
                      </div>
                    </div>

                    <Col xs={12} md={3}>
                      <Form.Group controlId="bussinessType">
                        <Form.Label>Business Type(s){" "}
                          <span
                            aria-hidden="true"
                            style={{
                              color: "red",
                              fontWeight: "bold",
                            }}
                          >
                            *
                          </span></Form.Label>
                        <Select
                          options={props?.businessTypeList}
                          isMulti
                          name="bussinessType"
                          placeholder="Select Business Type(s)"
                          value={props?.bussinessType}
                          // onChange={(value) =>
                          //   props.handleBusinessTypesChange(
                          //     "bussinessTypeList",
                          //     value
                          //   )
                          // }
                          onChange={(value) =>
                            props.handleBusinessTypesMultiChange(
                              value
                            )
                          }
                          closeMenuOnSelect={false}
                        />
                      </Form.Group>
                    </Col>

                    <div className="col-md-3 col-sm-12">
                      <div className="form-group ">
                        <label>
                          BIN Number{" "}
                        </label>
                        <input
                          type="text"
                          placeholder="Enter BIN number"
                          name="binNo"
                          value={props?.binNo}
                          onChange={props.handleChange}
                          className={"form-control"}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <Col xs={12} md={3}>
                      <Form.Group controlId="country">
                        <Form.Label>Country{" "}<span
                          aria-hidden="true"
                          style={{
                            color: "red",
                            fontWeight: "bold",
                          }}
                        >
                          *
                        </span></Form.Label>
                        <Select
                          options={props?.countryList}
                          name="country"
                          value={props?.country}
                          placeholder="Select Country Name"
                          onChange={(value) =>
                            props.handleCountryChange("countryList", value)
                          }
                        />
                      </Form.Group>
                    </Col>

                    <Col xs={12} md={3}>
                      <Form.Group controlId="city">
                        <Form.Label>District/City{" "}<span
                          aria-hidden="true"
                          style={{
                            color: "red",
                            fontWeight: "bold",
                          }}
                        >
                          *
                        </span></Form.Label>
                        <Select
                          placeholder="Select District/City Name"
                          options={props?.cityList}
                          name="city"
                          value={props?.city}
                          onChange={(value) =>
                            props?.handleCityChange("cityList", value)
                          }
                          isLoading={props?.loading}
                        />
                      </Form.Group>
                    </Col>

                    <Col xs={12} md={3}>
                      <Form.Group controlId="country">
                        <Form.Label>Area/Thana/Upazila/State{" "}<span
                          aria-hidden="true"
                          style={{
                            color: "red",
                            fontWeight: "bold",
                          }}
                        >
                          *
                        </span></Form.Label>
                        <Select
                          placeholder="Select Area/Thana/Upazila/State"
                          options={props?.areaList}
                          name="area"
                          value={props?.area}
                          onChange={(value) =>
                            props.handleAreaChange("areaList", value)
                          }
                          isLoading={props?.loading}
                        />
                      </Form.Group>
                    </Col>

                    <div className="col-md-3 col-sm-12">
                      <div className="form-group ">
                        <label>
                          Zip Code{" "}
                          <span
                            aria-hidden="true"
                            style={{
                              color: "red",
                              fontWeight: "bold",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Zip Code"
                          name="shopZipCode"
                          value={props?.shopZipCode}
                          onChange={props.handleChange}
                          className={"form-control"}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4 col-sm-12">
                      <div className="form-group ">
                        <label>
                          Shop Description{" "}
                          <span
                            aria-hidden="true"
                            style={{
                              color: "red",
                              fontWeight: "bold",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <textarea
                          rows={3}
                          type="text"
                          placeholder="Enter Shop Description Here"
                          name="shopDescription"
                          value={props?.shopDescription}
                          onChange={props.handleChange}
                          className={"form-control"}
                        />
                      </div>
                    </div>

                    <div className="col-md-4 col-sm-12">
                      <div className="form-group ">
                        <label>
                          Shop Address{" "}
                          <span
                            aria-hidden="true"
                            style={{
                              color: "red",
                              fontWeight: "bold",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <textarea
                          rows={3}
                          type="text"
                          placeholder="Enter Shop Address Here"
                          name="shopAddress"
                          value={props?.shopAddress}
                          onChange={props.handleChange}
                          className={"form-control"}
                        />
                      </div>
                    </div>

                    <div className="col-md-4 col-sm-12">
                      <div className="row">
                        <div className="col-sm-12 col-md-9">
                          <div className="form-group file-area">
                            <label className="control_label">
                              Shop Logo{" "}
                              <span
                                aria-hidden="true"
                                style={{
                                  color: "red",
                                  fontWeight: "bold",
                                }}
                              >
                                *
                              </span>
                            </label>
                            <input
                              type="file"
                              name="shopLogoUrl"
                              required="required"
                              className="form-control"
                              onChange={props?.fileSelectedHandlerLogo}
                            />
                            {props?.shopLogoUrl === "" ? (
                              <div className="file-dummy-small">
                                <div className="default">
                                  Select Shop Logo for Upload
                                </div>
                              </div>
                            ) : (
                              <div className="file-dummy-small">
                                <div className="success">
                                  Shop Logo Selected
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                          {props?.showShopLogoFile ? (
                            <img
                              src={props?.showShopLogoFile}
                              style={{
                                height: "80px",
                                width: "80px",
                                marginTop: "20px",
                              }}
                              alt="showFile"
                            />
                          ) : (
                            <img
                              src={baseUrl.concat(props?.shopLogoUrl)}
                              style={{
                                height: "80px",
                                width: "80px",
                                marginTop: "20px",
                                display: "none",
                              }}
                              alt="ownerNidUrl"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4 col-sm-12">
                      <div className="row">
                        <div className="col-sm-12 col-md-12">
                          <div className="form-group file-area">
                            <label className="control_label">
                              Owner NID{" "}
                              <span
                                aria-hidden="true"
                                style={{
                                  color: "red",
                                  fontWeight: "bold",
                                }}
                              >
                                *
                              </span>
                            </label>
                            <div className="input_file__section">
                              <input
                                className="career_post_form_input_file"
                                type="file"
                                name="NIDFront"
                                title={
                                  props?.ownerNidUrl
                                    ? props?.ownerNidName
                                    : 'Select NID File to Upload'
                                }
                                accept=".pdf,.doc,.docx,.png,.jpg,.jpeg,.PDF,.DOC,.DOCX,.PNG,.JPG,.JPEG"
                                onChange={(e) => props.fileSelectedNIDHandler(e)}
                              />

                              {props?.ownerNidUrl !== null ? (
                                <>
                                  <button
                                    className="career_input__cross_btn"
                                    title="Click to Remove Selected File."
                                    onClick={() => {
                                      props.setOwnerNidUrl(null);
                                    }}
                                  >
                                    <span>X</span>
                                  </button>
                                </>
                              ) : null}
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>

                    <div className="col-md-4 col-sm-12">
                      <div className="row">
                        <div className="col-sm-12 col-md-12">
                          <div className="form-group file-area">
                            <label className="control_label">
                              BIN Document{" "}
                            </label>
                            <div className="input_file__section">
                              <input
                                className="career_post_form_input_file"
                                type="file"
                                name="BINDocument"
                                title={
                                  props?.ownerBINUrl
                                    ? props?.ownerBINName
                                    : 'Select BIN Document to Upload'
                                }
                                accept=".pdf,.doc,.docx,.png,.jpg,.jpeg,.PDF,.DOC,.DOCX,.PNG,.JPG,.JPEG"
                                onChange={(e) => props.fileSelectedBINHandler(e)}
                              />

                              {props?.ownerBINUrl !== null ? (
                                <>
                                  <button
                                    className="career_input__cross_btn"
                                    title="Click to Remove Selected File."
                                    onClick={() => {
                                      props.setOwnerBINUrl(null);
                                    }}
                                  >
                                    <span>X</span>
                                  </button>
                                </>
                              ) : null}
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>

                    <div className="col-md-4 col-sm-12">
                      <div className="row">
                        <div className="col-sm-12 col-md-12">
                          <div className="form-group file-area">
                            <label className="control_label">
                              Trade License{" "}
                              <span
                                aria-hidden="true"
                                style={{
                                  color: "red",
                                  fontWeight: "bold",
                                }}
                              >
                                *
                              </span>
                            </label>
                            <div className="input_file__section">
                              <input
                                className="career_post_form_input_file"
                                type="file"
                                name="TradeLicense"
                                title={
                                  props?.ownerTradeLicenseUrl
                                    ? props?.ownerTradeLicenseName
                                    : 'Select Trade License to Upload'
                                }
                                accept=".pdf,.doc,.docx,.png,.jpg,.jpeg,.PDF,.DOC,.DOCX,.PNG,.JPG,.JPEG"
                                onChange={(e) => props.fileSelectedTradeLicenseHandler(e)}
                              />

                              {props?.ownerTradeLicenseUrl !== null ? (
                                <>
                                  <button
                                    className="career_input__cross_btn"
                                    title="Click to Remove Selected File."
                                    onClick={() => {
                                      props.setOwnerTradeLicenseUrl(null);
                                    }}
                                  >
                                    <span>X</span>
                                  </button>
                                </>
                              ) : null}
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>

                  </div>

                  <div className="row">
                    <div className="col-md-4 col-sm-12">
                      <div className="row">
                        <div className="col-sm-12 col-md-12">
                          <div className="form-group file-area">
                            <label className="control_label">
                              Business Document{" "}
                              <span
                                aria-hidden="true"
                                style={{
                                  color: "red",
                                  fontWeight: "bold",
                                }}
                              >
                                *
                              </span>
                            </label>
                            <div className="input_file__section">
                              <input
                                className="career_post_form_input_file"
                                type="file"
                                name="businessDoc"
                                title={
                                  props?.businessDocUrl
                                    ? props?.businessDocName
                                    : 'Select Business Document to Upload'
                                }
                                accept=".pdf,.doc,.docx,.png,.jpg,.jpeg,.PDF,.DOC,.DOCX,.PNG,.JPG,.JPEG"
                                onChange={(e) => props.fileSelectedBusinessDocHandler(e)}
                              />

                              {props?.businessDocUrl !== null ? (
                                <>
                                  <button
                                    className="career_input__cross_btn"
                                    title="Click to Remove Selected File."
                                    onClick={() => {
                                      props.setBusinessDocUrl(null);
                                    }}
                                  >
                                    <span>X</span>
                                  </button>
                                </>
                              ) : null}
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>

                    <div className="col-md-4 col-sm-12">
                      <div className="row">
                        <div className="col-sm-12 col-md-12">
                          <div className="form-group file-area">
                            <label className="control_label">
                              TIN Document{" "}
                            </label>
                            <div className="input_file__section">
                              <input
                                className="career_post_form_input_file"
                                type="file"
                                name="TINDoc"
                                title={
                                  props?.ownerTINDocUrl
                                    ? props?.ownerTINDocName
                                    : 'Select TIN Document to Upload'
                                }
                                accept=".pdf,.doc,.docx,.png,.jpg,.jpeg,.PDF,.DOC,.DOCX,.PNG,.JPG,.JPEG"
                                onChange={(e) => props.fileSelectedTINHandler(e)}
                              />

                              {props?.ownerTINDocUrl !== null ? (
                                <>
                                  <button
                                    className="career_input__cross_btn"
                                    title="Click to Remove Selected File."
                                    onClick={() => {
                                      props.setOwnerTINDocUrl(null);
                                    }}
                                  >
                                    <span>X</span>
                                  </button>
                                </>
                              ) : null}
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>


                    <div className="col-md-4 col-sm-12">
                      <div className="row">
                        <div className="col-sm-12 col-md-12">
                          <div className="form-group file-area">
                            <label className="control_label">
                              DBID Document{" "}
                            </label>
                            <div className="input_file__section">
                              <input
                                className="career_post_form_input_file"
                                type="file"
                                name="DBIDDoc"
                                title={
                                  props?.ownerDBIDDocUrl
                                    ? props?.ownerDBIDDocName
                                    : 'Select DBID Document to Upload'
                                }
                                accept=".pdf,.doc,.docx,.png,.jpg,.jpeg,.PDF,.DOC,.DOCX,.PNG,.JPG,.JPEG"
                                onChange={(e) => props.fileSelectedDBIDHandler(e)}
                              />

                              {props?.ownerDBIDDocUrl !== null ? (
                                <>
                                  <button
                                    className="career_input__cross_btn"
                                    title="Click to Remove Selected File."
                                    onClick={() => {
                                      props.setOwnerDBIDDocUrl(null);
                                    }}
                                  >
                                    <span>X</span>
                                  </button>
                                </>
                              ) : null}
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-2 col-sm-12">
                      <div className="row">
                        <div className="col-sm-12 col-md-12">
                          <div className="form-group file-area">
                            <label className="control_label">
                              Additional Documents{" "}
                            </label>
                            <div className="input_file__section" style={{ height: '38px' }}>
                              {props.additionalDocNum < 5 && <div className="btn btn-primary" onClick={() => {
                                props.handleAddAdditional();
                              }}>Add</div>}

                            </div>
                          </div>
                        </div>

                      </div>
                    </div>

                    <div className="col-md-10 col-sm-12">
                      {
                        props?.additionalDocumentsArray.map((x, i) => {
                          return (

                            <><div className="row">
                              <div className="col-md-1 col-sm-12">
                                {i + 1}
                              </div>
                              <div className="col-md-2 col-sm-12">
                                <label className="control_label">
                                  Document Name{" "}<span
                                    aria-hidden="true"
                                    style={{
                                      color: "red",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    *
                                  </span>
                                </label>
                              </div>
                              <div className="col-md-3 col-sm-12">
                                <div className="form-group">
                                  <input
                                    required
                                    type="text"
                                    placeholder="Enter Document Name Here"
                                    className={"form-control"}
                                    name="docName"
                                    onChange={(e) => props.handleOnBlur(e, i)}
                                  />
                                </div>
                              </div>
                              <div className="col-md-1 col-sm-12">
                                <label className="control_label">
                                  Upload{" "}<span
                                    aria-hidden="true"
                                    style={{
                                      color: "red",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    *
                                  </span>
                                </label>
                              </div>
                              <div className="col-md-4 col-sm-12">
                                <div className="row">
                                  <div className="col-sm-12 col-md-12">
                                    <div className="form-group file-area">

                                      <div className="input_file__section">
                                        <input
                                          required
                                          className="career_post_form_input_file"
                                          type="file"
                                          name="docPath"
                                          accept=".pdf,.doc,.docx,.png,.jpg,.jpeg,.PDF,.DOC,.DOCX,.PNG,.JPG,.JPEG"
                                          onChange={(e) => props.additionalFileSelectedHandler(e, i)}
                                        />

                                        {props?.ownerDBIDDocUrl !== null ? (
                                          <>
                                            <button
                                              className="career_input__cross_btn"
                                              title="Click to Remove Selected File."
                                              onClick={() => {
                                                props.setOwnerDBIDDocUrl(null);
                                              }}
                                            >
                                              <span>X</span>
                                            </button>
                                          </>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </div>
                              <div className="col-md-1 col-sm-12">
                                <div className="btn btn-danger" onClick={(e) => {
                                  props.handleRemoveAdditional(e, i);
                                }}>Remove</div>
                              </div>
                            </div> </>
                          );
                        }
                        )}
                    </div>

                  </div>

                  <div className="form-footer ">
                    <div className="form-group row">
                      <div className="text-center">
                        <div className="btn-group text-center">
                          {props?.createSellerDataLoading ? <div style={{ textAlign: "center" }}>
                            <Loader />
                          </div> : <>
                            {/* Save button */}
                            <button
                              type="submit"
                              className="btn btn-success"
                              onClick={props.saveSellerInfo}
                            >
                              Submit
                            </button>
                            {/* Cancel button */}
                            <Link to="/SellerLogin">
                              <button
                                className="btn btn-danger"
                                style={{ cursor: "pointer" }}
                              >
                                Cancel
                              </button>
                            </Link>
                          </>}

                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SellerShopInfo;
