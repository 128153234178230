import http from "../../utils/httpClient";

class transactionService {
  // Accounts Transaction Service Start
  getOrderAccountsTransaction = async (
    keywords,
    startTranDate,
    endTranDate,
    paymentMethodName,
    paymentStatus,
    currentPage,
    itemsPerPage
  ) => {
    return await http.get(
      `/api/Payment/GetPaymentCustomerTransaction?keywords=${
        keywords || ""
      }&startTranDate=${startTranDate || ""}&endTranDate=${
        endTranDate || ""
      }&paymentMethodName=${paymentMethodName || ""}&paymentStatus=${
        paymentStatus || ""
      }&currentPage=${currentPage}&itemsPerPage=${itemsPerPage}`
    );
  };
  // Accounts Transaction Service End
}

export default new transactionService();
