/* eslint-disable react-hooks/exhaustive-deps */
import { Modal } from "antd";
import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { ImFilePdf } from "react-icons/im";
import { RiPrinterLine } from "react-icons/ri";
import { SiMicrosoftexcel } from "react-icons/si";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import XLSX from "xlsx";
import DateWisePaymentRealizationView from "../../components/accounts/view/DateWisePaymentRealizationView";
import DateWiseSalesSummaryNBRView from "../../components/accounts/view/DateWiseSalesSummaryNBRView";
import DateWiseSalesSummaryView from "../../components/accounts/view/DateWiseSalesSummaryView";
import PaymentTypeWiseSalesDetailsView from "../../components/accounts/view/PaymentTypeWiseSalesDetailsView";
import PaymentTypeWiseSalesSummaryView from "../../components/accounts/view/paymentTypeWiseSalesSummaryView";
import SalesReportFilterOptions from "../../components/report/salesReportFilterOption";
import SalesReportFilterProperties from "../../components/report/salesReportFilterProperties";
import SaRaLoader from "../../components/shared/SaRaLoader/SaRaLoader";
import { Loader } from "../../containers";
import authenticationService from "../../store/services/authenticationService";
import toIsoString from "../../utils/Functions/toIsoString";
import { dateWisePaymentRealizationApiCall, dateWisePaymentRealizationReportExcel } from "./salesReport/dateWisePaymentRealization";
import {
  dateWiseSalesDetailExcel,
  dateWiseSalesDetailsApiCall,
} from "./salesReport/dateWiseSalesDetails";
import {
  dateWiseSalesSummaryApiCall,
  dateWiseSalesSummaryReportExcel,
  dateWiseSalesSummaryReportGrouping,
} from "./salesReport/dateWiseSalesSummary";
import { dateWiseSalesSummaryNBRApiCall } from "./salesReport/dateWiseSalesSummaryNBRApiCall";
import {
  paymentTypeWiseSalesDetailsApiCall,
  paymentTypeWiseSalesDetailsReportExcel,
  paymentTypeWiseSalesDetailsReportGrouping,
} from "./salesReport/paymentTypeWiseSalesDetails";
import {
  paymentTypeWiseSalesSummaryApiCall,
  paymentTypeWiseSalesSummaryReportExcel,
  paymentTypeWiseSalesSummaryReportGrouping,
} from "./salesReport/paymentTypeWiseSalesSummary";

const SalesReport = () => {
  const [showQuickView, setShowQuickView] = useState(false); // false
  const [format, setFormat] = useState(1); // 1
  const [option, setOption] = useState(5); // 1
  const [disable, setDisable] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [paymentMethodNames, setPaymentMethodNames] = useState(null);
  const [paymentMethodIds, setPaymentMethodIds] = useState(0);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [orderSource, setOrderSource] = useState(null);
  const [orderStatus, setOrderStatus] = useState(null);
  const [shopNameIds, setShopNameIds] = useState(0);
  const [shopType, setShopType] = useState(null);
  const [dateType, setDateType] = useState(1);
  const [dateWiseSalesSummaryNBRDatas, setDateWiseSalesSummaryNBRDatas] = useState(
    []
  );
  const [dateWiseSalesSummaryDatas, setDateWiseSalesSummaryDatas] = useState(
    []
  );
  const [dateWiseSalesDetailsDatas, setDateWiseSalesDetailsDatas] = useState(
    []
  );
  const [
    paymentTypeWiseSalesSummaryDatas,
    setPaymentTypeWiseSalesSummaryDatas,
  ] = useState([]);
  const [
    paymentTypeWiseSalesDetailsDatas,
    setPaymentTypeWiseSalesDetailsDatas,
  ] = useState([]);
  const [dateWisePaymentRealizationDatas, setDateWisePaymentRealizationDatas] = useState(
    []
  );

  useState(null);
  const [isDataFormatting, setIsDataFormatting] = useState(false);

  const [
    paymentTypeWiseSalesSummaryNBRArrayDatas,
    setPaymentTypeWiseSalesSummaryNBRArrayDatas,
  ] = useState(null);
  const [
    paymentTypeWiseSalesSummaryArrayDatas,
    setPaymentTypeWiseSalesSummaryArrayDatas,
  ] = useState(null);
  const [dateWiseSalesSummaryArrayDatas, setDateWiseSalesSummaryArrayDatas] =
    useState(null);
  const [
    paymentTypeWiseSalesDetailsArrayDatas,
    setPaymentTypeWiseSalesDetailsArrayDatas,
  ] = useState(null);

  const [dateWisePaymentRealizationArrayDatas, setDateWisePaymentRealizationArrayDatas] =
    useState(null);

  let history = useHistory();
  const dispatch = useDispatch();

  const {
    dateWiseSalesSummaryData,
    dateWiseSalesSummaryNBRData,
    accountsTransactionReportData,
    paymentTypeWiseSalesSummaryData,
    paymentTypeWiseSalesDetailsData,
    dateWisePaymentRealizationData,
    loading,
    loaded,
    dateWiseSalesSummaryDataLoading,
    dateWiseSalesSummaryDataLoaded,
    dateWiseSalesSummaryNBRDataLoading,
    paymentTypeWiseSalesSummaryDataLoading,
    paymentTypeWiseSalesSummaryDataLoaded,
    paymentTypeWiseSalesDetailsDataLoading,
    paymentTypeWiseSalesDetailsDataLoaded,
    dateWisePaymentRealizationDataLoading,
    dateWisePaymentRealizationDataLoaded,
  } = useSelector((state) => state?.accountsReducer);

  useEffect(() => {
    let roleId = authenticationService?.getRoleId();
    if (roleId === "1") {
    } else {
      history.push("/Login");
    }
  }, [history]);

  let location = useHistory();
  const order_filter_tab = location?.location?.search?.substring(6);

  const handleOptionChange = (value) => {
    setOption(value);
  };

  const handleChange = (e) => {
    if (e.target.name === "paymentMethodName") {
      setPaymentMethod(e.value);
    }
    if (e.target.name === "paymentStatusName") {
      setPaymentStatus(e.value);
    }
    if (e.target.name === "orderSourceName") {
      setOrderSource(e.value);
    }
    if (e.target.name === "orderStatusName") {
      setOrderStatus(e.value);
    }
    if (e.target.name === "bussinessTypeName") {
      setShopType(e.value);
    }
  };

  const handleDateChange = (value, type) => {
    if (type === "start") {
      setStartDate(value);
    } else if (type === "end") {
      setEndDate(value);
    }
  };

  useEffect(() => {
    var result = "";
    if (paymentMethodNames) {
      result = paymentMethodNames
        .map(function (item) {
          return item.value;
        })
        .join(",");
    }
    setPaymentMethodIds(result.toString());
  }, [paymentMethodNames]);

  // Report generate button call - start
  const reportGenerate = () => {
    // Date Wise Sales Details
    if (startDate === null || endDate === null) {
      toast.error("Both Order Start and End Date Required!!!");
      return;
    }

    if (option === 1) {
      dateWiseSalesSummaryApiCall(
        dispatch,
        toIsoString(startDate).substring(0, 10),
        toIsoString(endDate).substring(0, 10),
        27,
        shopNameIds ? shopNameIds : 0
      );
    } else if (option === 2) {
      dateWiseSalesDetailsApiCall(
        dispatch,
        toIsoString(startDate).substring(0, 10),
        toIsoString(endDate).substring(0, 10),
        "",
        "",
        shopNameIds ? shopNameIds : ""
      );
    } else if (option === 3) {
      paymentTypeWiseSalesSummaryApiCall(
        dispatch,
        toIsoString(startDate).substring(0, 10),
        toIsoString(endDate).substring(0, 10),
        27,
        shopNameIds ? shopNameIds : 0,
        paymentMethodIds ? paymentMethodIds : 0
      );
    } else if (option === 4) {
      paymentTypeWiseSalesDetailsApiCall(
        dispatch,
        toIsoString(startDate).substring(0, 10),
        toIsoString(endDate).substring(0, 10),
        27,
        shopNameIds ? shopNameIds : 0,
        paymentMethodIds ? paymentMethodIds : 0
      );
    } else if (option === 5) {
      dateWiseSalesSummaryNBRApiCall(
        dispatch,
        toIsoString(startDate).substring(0, 10),
        toIsoString(endDate).substring(0, 10),
        27,
        shopNameIds ? shopNameIds : 0,
        paymentMethodIds ? paymentMethodIds : 0
      );
    } else if (option === 6) {
      dateWisePaymentRealizationApiCall(
        dispatch,
        toIsoString(startDate).substring(0, 10),
        toIsoString(endDate).substring(0, 10),
        paymentMethodIds ? paymentMethodIds : 0,
        shopNameIds ? shopNameIds : 0,
        dateType ? dateType : 1
      );
    }
  };
  // Report generate button call - end

  useEffect(() => {
    setDisable(true);
    if (option == 5) {
      setFormat(2);
    } else {
      setFormat(1);
    }
  }, [option]);

  useEffect(() => {
    if (option === 1 || option === 2) {
      if (startDate !== null && endDate !== null) {
        setDisable(false);
      } else {
        setDisable(true);
      }
    } else {
      if (startDate !== null && endDate !== null) {
        if (option === 3 || option === 4 || option === 5 || option === 6) {
          setDisable(false);
        } else {
          setDisable(true);
        }
      } else {
        setDisable(true);
      }
    }
  }, [option, startDate, endDate, paymentMethod]);

  const handleMultiPaymentMethodSelectChange = (paymentMethods) => {
    setPaymentMethodNames(paymentMethods);
  };

  useEffect(() => {
    if (order_filter_tab === "sales_report") {
      if (option === 1) {
        setDateWiseSalesSummaryDatas(dateWiseSalesSummaryData?.data);
      } else if (option === 2) {
        setDateWiseSalesDetailsDatas(accountsTransactionReportData?.data);
      } else if (option === 3) {
        setPaymentTypeWiseSalesSummaryDatas(
          paymentTypeWiseSalesSummaryData?.data
        );
      } else if (option === 4) {
        setPaymentTypeWiseSalesDetailsDatas(
          paymentTypeWiseSalesDetailsData?.data
        );
      } else if (option === 5) {
        if (dateWiseSalesSummaryNBRData && dateWiseSalesSummaryNBRData?.data && dateWiseSalesSummaryNBRData?.data[0]?.length === 0) {
          toast.error("Sorry, No Data Found!!");
          return;
        }
        setDateWiseSalesSummaryNBRDatas(dateWiseSalesSummaryNBRData?.data && dateWiseSalesSummaryNBRData?.data[0])
      } else if (option === 6) {
        setDateWisePaymentRealizationDatas(dateWisePaymentRealizationData?.data);
      }
    }
  }, [
    dateWiseSalesSummaryData,
    dateWiseSalesSummaryNBRData,
    accountsTransactionReportData,
    paymentTypeWiseSalesSummaryData,
    paymentTypeWiseSalesDetailsData,
    dateWisePaymentRealizationData,
    option,
    order_filter_tab,
  ]);

  // File Name format - start
  const formatFileName = () => {
    let optionalName = "";
    if (option === 1) {
      optionalName = `DateWise-Sales-Summary`;
    } else if (option === 2) {
      optionalName = `DateWise-Sales-Details`;
    } else if (option === 3) {
      optionalName = `PaymentTypeWise-Sales-Summary`;
    } else if (option === 4) {
      optionalName = `PaymentTypeWise-Sales-Details`;
    } else if (option === 6) {
      optionalName = `DateWise-Payment-Realization`;
    }

    let formattedName = `${optionalName}-of-${toIsoString(startDate).substring(
      0,
      10
    )}-to-${toIsoString(endDate).substring(0, 10)}`;

    return formattedName;
  };
  // File Name format - end

  // Generate Order Report for Excel Or CSV start
  const generateOrderReportExcel = (sheetData, name) => {
    let work_book = XLSX.utils.book_new();
    let work_sheet = XLSX.utils.json_to_sheet(sheetData);

    XLSX.utils.book_append_sheet(work_book, work_sheet, "Sheet1");

    XLSX.writeFile(work_book, `${name}.xlsx`);
  };
  // Generate Order Report for Excel Or CSV end

  useEffect(() => {
    const name = formatFileName();

    if (!disable) {
      if (option === 1) {
        if (dateWiseSalesSummaryDatas?.length === 0) {
          toast.error("Sorry, No Data Found!!");
          return;
        }
        if (format === 1) {
          const data = dateWiseSalesSummaryReportExcel(
            dateWiseSalesSummaryDatas
          );
          dateWiseSalesSummaryDataLoaded &&
            generateOrderReportExcel(data, name);
        } else if (format === 2) {
          let outData = dateWiseSalesSummaryReportGrouping(
            dateWiseSalesSummaryDatas,
            setIsDataFormatting
          );
          setShowQuickView(true);
          setDateWiseSalesSummaryArrayDatas(outData);
        } else if (format === 3) {
          let outData = dateWiseSalesSummaryReportGrouping(
            dateWiseSalesSummaryDatas,
            setIsDataFormatting
          );
          setShowQuickView(true);
          setDateWiseSalesSummaryArrayDatas(outData);
        }
      } else if (option === 2) {
        if (dateWiseSalesDetailsDatas?.length === 0) {
          toast.error("Sorry, No Data Found!!");
          return;
        }
        const data = dateWiseSalesDetailExcel(dateWiseSalesDetailsDatas);
        loaded && generateOrderReportExcel(data, name);
      } else if (option === 3) {
        if (paymentTypeWiseSalesSummaryDatas?.length === 0) {
          toast.error("Sorry, No Data Found!!");
          return;
        }
        if (format === 1) {
          const data = paymentTypeWiseSalesSummaryReportExcel(
            paymentTypeWiseSalesSummaryDatas
          );
          paymentTypeWiseSalesSummaryDataLoaded &&
            generateOrderReportExcel(data, name);
        } else if (format === 2) {
          let outData = paymentTypeWiseSalesSummaryReportGrouping(
            paymentTypeWiseSalesSummaryDatas,
            setIsDataFormatting
          );
          setShowQuickView(true);
          setPaymentTypeWiseSalesSummaryArrayDatas(outData);
        } else if (format === 3) {
          let outData = paymentTypeWiseSalesSummaryReportGrouping(
            paymentTypeWiseSalesSummaryDatas,
            setIsDataFormatting
          );
          setShowQuickView(true);
          setPaymentTypeWiseSalesSummaryArrayDatas(outData);
        }
      } else if (option === 4) {
        if (paymentTypeWiseSalesDetailsDatas?.length === 0) {
          toast.error("Sorry, No Data Found!!");
          return;
        }
        if (format === 1) {
          const data = paymentTypeWiseSalesDetailsReportExcel(
            paymentTypeWiseSalesDetailsDatas
          );
          paymentTypeWiseSalesDetailsDataLoaded &&
            generateOrderReportExcel(data, name);
        } else if (format === 2) {
          let outData = paymentTypeWiseSalesDetailsReportGrouping(
            paymentTypeWiseSalesDetailsDatas,
            setIsDataFormatting
          );
          setShowQuickView(true);
          setPaymentTypeWiseSalesDetailsArrayDatas(outData);
        } else if (format === 3) {
          let outData = paymentTypeWiseSalesDetailsReportGrouping(
            paymentTypeWiseSalesDetailsDatas,
            setIsDataFormatting
          );
          setShowQuickView(true);
          setPaymentTypeWiseSalesDetailsArrayDatas(outData);
        }
      } else if (option === 5) {
        if (dateWiseSalesSummaryNBRDatas?.length === 0) {
          toast.error("Sorry, No Data Found!!");
          return;
        }
        // if (format === 1) {
        //   const data = paymentTypeWiseSalesDetailsReportExcel(
        //     dateWiseSalesSummaryNBRDatas
        //   );
        //   paymentTypeWiseSalesDetailsDataLoaded &&
        //     generateOrderReportExcel(data, name);
        // } else
        if (format === 2) {
          // let outData = dateWiseSalesSummaryNBRReportGrouping(
          //   dateWiseSalesSummaryNBRDatas,
          //   setIsDataFormatting
          // );
          setShowQuickView(true);
          setPaymentTypeWiseSalesSummaryNBRArrayDatas(dateWiseSalesSummaryNBRDatas);
        } else if (format === 3) {
          // let outData = dateWiseSalesSummaryNBRReportGrouping(
          //   dateWiseSalesSummaryNBRDatas,
          //   setIsDataFormatting
          // );
          setShowQuickView(true);
          setPaymentTypeWiseSalesSummaryNBRArrayDatas(dateWiseSalesSummaryNBRDatas);
        }
      } else if (option === 6) {
        if (dateWisePaymentRealizationDatas?.length === 0) {
          toast.error("Sorry, No Data Found!!");
          return;
        }
        if (format === 1) {
          const data = dateWisePaymentRealizationReportExcel(
            dateWisePaymentRealizationDatas
          );
          dateWisePaymentRealizationDataLoaded &&
            generateOrderReportExcel(data, name);
        } else if (format === 2) {
          // setShowQuickView(true);
          // setDateWisePaymentRealizationArrayDatas(dateWisePaymentRealizationDatas);
          printHandler(dateWisePaymentRealizationDatas)
        } else if (format === 3) {
          setShowQuickView(true);
          setDateWisePaymentRealizationArrayDatas(dateWisePaymentRealizationDatas);
        }
      }
    }
  }, [
    dateWiseSalesSummaryDatas,
    dateWiseSalesDetailsDatas,
    paymentTypeWiseSalesSummaryDatas,
    paymentTypeWiseSalesDetailsDatas,
    dateWiseSalesSummaryNBRDatas,
    dateWisePaymentRealizationDatas
  ]);

  const printHandler = (data) => {
    history.push({
      pathname: `/PrintSalesReport`,
      arrayDatas: data,
      startDate,
      endDate,
      option
    });
  }

  return (
    <>
      {" "}
      <ToastContainer autoClose={2500} />
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="col-md-12">
            <div className="white-box white-box-title">
              <div className="datatable-row">
                <div className="report-head">
                  <span className="header-display">
                    <span>
                      <RiPrinterLine size="2rem" />
                    </span>
                    <span>
                      Report for{" "}
                      {order_filter_tab === "sales_report"
                        ? "Sales Report"
                        : ""}{" "}
                    </span>
                  </span>
                </div>

                <div className="option-body">
                  <div className="option-lebel">Choose Sales Report Option</div>
                  <div className="option-body-element">
                    <div style={{ width: "50%" }}>
                      {/* Report Filter Options */}
                      <SalesReportFilterOptions
                        handleOptionChange={handleOptionChange}
                        option={option}
                        order_filter_tab={order_filter_tab}
                      />

                      {/* Report Format Selector */}
                      <div className="format-container">
                        {option !== 5 && (
                          <button
                            className={`format_button ${format === 1 ? "format-active" : ""
                              }`}
                            onClick={() => setFormat(1)}
                          >
                            <SiMicrosoftexcel
                              className={`icon-opt ${format === 1 ? "icon-active" : ""
                                }`}
                            />
                            <span className="option-text">Excel</span>
                          </button>
                        )}


                        {option !== 2 && (
                          <button
                            className={`format_button ${format === 2 ? "format-active" : ""
                              }`}
                            onClick={() => setFormat(2)}
                          >
                            <ImFilePdf
                              className={`icon-opt ${format === 2 ? "icon-active" : ""
                                }`}
                            />
                            <span className="option-text">PDF</span>
                          </button>
                        )}

                        {option !== 2 && (
                          <button
                            className={`format_button ${format === 3 ? "format-active" : ""
                              }`}
                            onClick={() => setFormat(3)}
                          >
                            <FaEye
                              className={`icon-opt ${format === 3 ? "icon-active" : ""
                                }`}
                            />
                            <span className="option-text">Quick View</span>
                          </button>
                        )}
                      </div>

                      {/* Report Generator Button */}
                      <div className="button-container">

                        {(loading ||
                          dateWiseSalesSummaryDataLoading ||
                          dateWiseSalesSummaryNBRDataLoading ||
                          paymentTypeWiseSalesSummaryDataLoading ||
                          paymentTypeWiseSalesDetailsDataLoading ||
                          dateWisePaymentRealizationDataLoading ||
                          isDataFormatting) ?
                          <div style={{ width: '65%', textAlign: 'center' }}>
                            <Loader />
                          </div>
                          : <Button
                            disabled={disable}
                            className="generate-btn"
                            onClick={() => reportGenerate()}
                          >
                            Generate Report
                          </Button>}
                      </div>
                    </div>

                    <div style={{ width: "50%" }}>
                      {/* Report Filter Properties */}
                      <SalesReportFilterProperties
                        handleChange={handleChange}
                        option={option}
                        orderStatus={orderStatus}
                        orderSource={orderSource}
                        paymentStatus={paymentStatus}
                        paymentMethod={paymentMethod}
                        startDate={startDate}
                        endDate={endDate}
                        shopType={shopType}
                        handleDateChange={handleDateChange}
                        order_filter_tab={order_filter_tab}
                        handleMultiPaymentMethodSelectChange={
                          handleMultiPaymentMethodSelectChange
                        }
                        paymentMethodNames={paymentMethodNames}
                        setShopType={setShopType}
                        setShopNameIds={setShopNameIds}
                        dateType={dateType}
                        setDateType={setDateType}
                      />
                    </div>
                  </div>
                </div>

                {(loading ||
                  dateWiseSalesSummaryDataLoading ||
                  dateWiseSalesSummaryNBRDataLoading ||
                  paymentTypeWiseSalesSummaryDataLoading ||
                  paymentTypeWiseSalesDetailsDataLoading ||
                  dateWisePaymentRealizationDataLoading ||
                  isDataFormatting) && <SaRaLoader />}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        // className={`print-modal`}
        title="Quick View"
        style={{ top: 40 }}
        visible={showQuickView}
        onOk={() => setShowQuickView(false)}
        onCancel={() => setShowQuickView(false)}
        width={`85vw`}
      >
        {!disable && option === 1 && (format === 2 || format === 3) && (
          <DateWiseSalesSummaryView
            startDate={startDate}
            endDate={endDate}
            arrayDatas={dateWiseSalesSummaryArrayDatas}
            format={format}
            setShowQuickView={setShowQuickView}
            dateWiseSalesSummaryData={dateWiseSalesSummaryData}
          />
        )}
        {!disable && option === 3 && (format === 2 || format === 3) && (
          <PaymentTypeWiseSalesSummaryView
            startDate={startDate}
            endDate={endDate}
            arrayDatas={paymentTypeWiseSalesSummaryArrayDatas}
            format={format}
            setShowQuickView={setShowQuickView}
          />
        )}
        {!disable && option === 4 && (format === 2 || format === 3) && (
          <PaymentTypeWiseSalesDetailsView
            startDate={startDate}
            endDate={endDate}
            arrayDatas={paymentTypeWiseSalesDetailsArrayDatas}
            format={format}
            setShowQuickView={setShowQuickView}
          />
        )}
        {!disable && option === 5 && (format === 2 || format === 3) && (
          <DateWiseSalesSummaryNBRView
            startDate={startDate}
            endDate={endDate}
            arrayDatas={paymentTypeWiseSalesSummaryNBRArrayDatas}
            format={format}
            setShowQuickView={setShowQuickView}
            dateWiseSalesSummaryData={dateWiseSalesSummaryNBRData}
          />
        )}
        {!disable && option === 6 && (format === 3) && (
          <DateWisePaymentRealizationView
            startDate={startDate}
            endDate={endDate}
            arrayDatas={dateWisePaymentRealizationArrayDatas}
            format={format}
            setShowQuickView={setShowQuickView}
            printHandler={printHandler}
          />
        )}
      </Modal>
    </>
  );
};

export default SalesReport;
