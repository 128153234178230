import warrantyService from "../services/warrantyService";

export const GET_WARRANTY_PERIOD = "GET_WARRANTY_PERIOD";
export const GET_WARRANTY_PERIOD_SUCCESS = "GET_WARRANTY_PERIOD_SUCCESS";
export const GET_WARRANTY_PERIOD_ERROR = "GET_WARRANTY_PERIOD_ERROR";

export const GET_WARRANTY_TYPE = "GET_WARRANTY_TYPE";
export const GET_WARRANTY_TYPE_SUCCESS = "GET_WARRANTY_TYPE_SUCCESS";
export const GET_WARRANTY_TYPE_ERROR = "GET_WARRANTY_TYPE_ERROR";

// warranty type start
export function getWarrantyTypeRecord({ isActive, warrantyTypeName }) {
  return async (dispatch) => {
    dispatch(getWarrantyType());
    return await warrantyService
      .getWarrantyType(isActive, warrantyTypeName)

      .then((response) => dispatch(getWarrantyTypeSuccess(response)))
      .catch((error) => dispatch(getWarrantyTypeError(error)));
  };
}

export function getWarrantyType() {
  return {
    type: GET_WARRANTY_TYPE,
  };
}

export function getWarrantyTypeSuccess(success) {
  return {
    type: GET_WARRANTY_TYPE_SUCCESS,
    payload: { success },
  };
}

export function getWarrantyTypeError(error) {
  return {
    type: GET_WARRANTY_TYPE_ERROR,
    payload: { error },
  };
}

// warranty type end

// warranty period start
export function getWarrantyPeriodRecord({
  isActive = "",
  warrantyPeriodName = "",
}) {
  return async (dispatch) => {
    dispatch(getWarrantyPeriod());
    return await warrantyService
      .getWarrantyPeriod(isActive, warrantyPeriodName)

      .then((response) => dispatch(getWarrantyPeriodSuccess(response)))
      .catch((error) => dispatch(getWarrantyPeriodError(error)));
  };
}

export function getWarrantyPeriod() {
  return {
    type: GET_WARRANTY_PERIOD,
  };
}

export function getWarrantyPeriodSuccess(success) {
  return {
    type: GET_WARRANTY_PERIOD_SUCCESS,
    payload: { success },
  };
}

export function getWarrantyPeriodError(error) {
  return {
    type: GET_WARRANTY_PERIOD_ERROR,
    payload: { error },
  };
}

// warranty period end
