import http from "../../utils/httpClient"

class festivalService {

    getFestivalCategory = async (query) => {
        return await http.get("/api/Festival/GetFestivalCategory",{params: query})
    }

    createOrUpdateFestivalCategory = async (data) => {
        return await http.post("/api/Festival/CreateFestival", data)
    }
 
    addOrEditFestivalProductList = async (data) => {
        return await http.post(`/api/Festival/AddProductsInFestivalCategory?productIds=${data.productIds}&festivalId=${data.festivalId}&isActive=${data.isActive}`)
    }

    getAddibleFestivalProducts = async (query) => {
        return await http.get(
            '/api/Festival/GetFestivalProductListByCategory', { params: query }
        )
            .then((response) => {
                return response;
            })
            .catch((err) => Promise.reject("Failed!", err))
    }

    getFestivalProducts = async (query) => {
        return await http.get(
            '/api/Festival/GetFestivalProductListByCategory', { params: query }
        )
            .then((response) => {
                return response;
            })
            .catch((err) => Promise.reject("Failed!", err))
    }
    festivalProductStatusChange = async (data) => {
        return await http.post(`/api/Festival/FestivalCategoryProductStatusChange?festivalId=${data?.festivalId}&productId=${data?.productId}&isActive=${data?.isActive}`)
    }
}

export default new festivalService()