import React from "react";

const ShowQuantity = ({currentQuantity}) => {
  return (
    <span style={{ fontWeight: "700", color: "#1F5DA0" }}>
      {currentQuantity === 0 ? null : (
        <span>
          (<span style={{ color: "#D71110" }}>{currentQuantity}</span>)
        </span>
      )}
    </span>
  );
};

export default ShowQuantity;
