/* eslint-disable react-hooks/exhaustive-deps */
import { Modal } from "antd";
import { Button } from "primereact/button";
import React, { useState } from 'react';
import { FaEye } from "react-icons/fa";
import { ImFilePdf } from "react-icons/im";
import { RiPrinterLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import BankAdvisoryGenerateView from "../../components/accounts/view/BankAdvisoryGenerate";

const BankAdvisory = () => {
    let location = useHistory();
    const [showQuickView, setShowQuickView] = useState(false); // false
    const [format, setFormat] = useState(3);

    const order_filter_tab = location?.location?.search?.substring(6);

    const bankAdvisoryGenerate = () => {
        setShowQuickView(true);
    }

    return (
        <>
            <ToastContainer autoClose={2500} />
            <div className="page-wrapper">
                <div className="container-fluid">
                    <div className="col-md-12">
                        <div className="white-box white-box-title">
                            <div className="datatable-row">
                                <div className="report-head">
                                    <span className="header-display">
                                        <span>
                                            <RiPrinterLine size="2rem" />
                                        </span>
                                        <span>
                                            Generate{" "}
                                            {order_filter_tab === "bank_advisory"
                                                ? "Bank Advisory"
                                                : ""}{" "}
                                        </span>
                                    </span>
                                </div>

                                <div className="option-body">
                                    <div className="option-lebel">Choose Bank Advisory Option</div>
                                    <div className="option-body-element">
                                        <div style={{ width: "50%" }}>
                                            {/* Report Filter Options */}
                                            {/* <SalesReportFilterOptions
                                                handleOptionChange={handleOptionChange}
                                                option={option}
                                                order_filter_tab={order_filter_tab}
                                            /> */}

                                            {/* Report Format Selector */}
                                            <div className="format-container">

                                                <button
                                                    className={`format_button ${format === 2 ? "format-active" : ""
                                                        }`}
                                                    onClick={() => setFormat(2)}
                                                >
                                                    <ImFilePdf
                                                        className={`icon-opt ${format === 2 ? "icon-active" : ""
                                                            }`}
                                                    />
                                                    <span className="option-text">PDF</span>
                                                </button>



                                                <button
                                                    className={`format_button ${format === 3 ? "format-active" : ""
                                                        }`}
                                                    onClick={() => setFormat(3)}
                                                >
                                                    <FaEye
                                                        className={`icon-opt ${format === 3 ? "icon-active" : ""
                                                            }`}
                                                    />
                                                    <span className="option-text">Quick View</span>
                                                </button>

                                            </div>

                                            {/* Report Generator Button */}
                                            <div className="button-container">
                                                <Button
                                                    //   disabled={disable}
                                                    className="generate-btn"
                                                    onClick={() => bankAdvisoryGenerate()}
                                                >
                                                    Generate Bank Advisory
                                                </Button>
                                            </div>
                                        </div>

                                        {/* <div style={{ width: "50%" }}>
                                            <SalesReportFilterProperties
                                                handleChange={handleChange}
                                                option={option}
                                                orderStatus={orderStatus}
                                                orderSource={orderSource}
                                                paymentStatus={paymentStatus}
                                                paymentMethod={paymentMethod}
                                                startDate={startDate}
                                                endDate={endDate}
                                                shopType={shopType}
                                                handleDateChange={handleDateChange}
                                                order_filter_tab={order_filter_tab}
                                                handleMultiPaymentMethodSelectChange={
                                                    handleMultiPaymentMethodSelectChange
                                                }
                                                paymentMethodNames={paymentMethodNames}
                                                setShopType={setShopType}
                                                setShopNameIds={setShopNameIds}
                                            />
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                // className={`print-modal`}
                title="Quick View"
                style={{ top: 40 }}
                visible={showQuickView}
                onOk={() => setShowQuickView(false)}
                onCancel={() => setShowQuickView(false)}
                width={1500}
            >
                <BankAdvisoryGenerateView
                    format={format}
                    setShowQuickView={setShowQuickView}
                />
            </Modal>
        </>

    )
}

export default BankAdvisory