import http from "../../utils/httpClient";

class customerService {
  customerContactAvailable = async (contactNo) => {
    return await http
      .post("/api/Customer/ContactAvailable", {
        contactNo: contactNo,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => Promise.reject("Failed!", err));
  };

  getCustomerDetails = async (contactNo) => {
    return await http.get(`/api/Customer/Get?contactNo=${contactNo}`);
  };

  createNewCustomer = async (data) => {
    return await http.post("/api/Customer/CreateOrUpdate", {
      customerId: data.customerId,
      roleId: data.roleId,
      cusName: data.cusName,
      cusContactNo: data.cusContactNo,
      isActive: data.isActive,
      cusPassword: data.cusPassword,
    });
  };

  createCustomerAddress = async (data) => {
    return await http.post(
      "/api/Customer/AddOrEditAddress",
      data.dataToSendApi
    );
  };

  getAddressOneApi = async () => {
    return await http.get("/api/Customer/GetAddressOneApi");
  };

  getProductWithSku = async (sku) => {
    const query={sku:sku}
    return await http.get('/api/Product/GetProductBySku',{params:query})
  };

  getCustomerAddress = async (id) => {
    return await http.get(`/api/Customer/GetAddressByCusId?customerId=${id}`);
  };

  createOrder = async (data) => {
    return await http.post("/api/v2/Order/CreateAdminOrder", data);
  };

  // createOrder = async (data) => {
  //   return await http.post("/api/v2/Order/CreateAdminOrder", data);
  // };

  deleteCustomerAddress = async (customerAddressId,customerId) => {
    return await http.post(
      `/api/Customer/DeletedCustomerAddress?customerAddressId=${customerAddressId}&customerId=${customerId}`
    );
  };
}

export default new customerService();