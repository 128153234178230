import http from "../../utils/httpClient";

class commissionService {
  getCommissionType = async (isActive, commissionTypeName) => {
    return await http.get(
      `/api/Commission/GetSellerCommisionType?isActive=${
        isActive || ""
      }&commissionTypeName=${commissionTypeName || ""}&getAll=Y`
    );
  };
}

export default new commissionService();
