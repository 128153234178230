/* eslint-disable no-loop-func */
import { Button } from "primereact/button";
import React, { useState } from "react";
import Resizer from "react-image-file-resizer";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { ToastContainer, toast } from "react-toastify";
import baseUrl from "../../utils/baseUrl";
import AddProductVariantTable from "./AddProductVariantTable";

const PriceAndStock = (props) => {
  const animatedComponents = makeAnimated();
  const {
    productDetails,
    setProductDetails,
    isDpPriceFixManual,
    setNewProductDetails,
    setVariants,
    variants,
    setBurnOK,
    setDiscountOK
  } = props;

  const [showDiscount, setShowDiscount] = useState(false);
  const [showDP, setShowDP] = useState(false);
  const [showBurn, setShowBurn] = useState(false);
  const [status, setStatus] = useState(1);

  const discount_inputStyle = {
    border: "1px solid rgb(201, 201, 201)",
    borderRadius: "5px",
    padding: "5px",
  };
  const dp_radioStyle = {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: '10px'
  };

  const handleVariantInputChange = (index, event) => {
    let value = event.target.value;

    const newData = productDetails.map((obj, idx) => {
      if (index === idx) {
        if (event.target.name === "productPrice") {
          return {
            ...obj,
            productPrice: value.trim()
          };
        }
        if (event.target.name === "productQuantity") {
          return {
            ...obj,
            productQuantity: value.trim()
          };
        }
        if (event.target.name === "sellerProductSku") {
          return {
            ...obj,
            sellerProductSku: value.trim()
          };
        }
        if (event.target.name === "discountAmount") {
          return {
            ...obj,
            discountAmount: value.trim(),
            discountPercentage: 0,
          };
        }
        if (event.target.name === "discountPercentage") {
          return {
            ...obj,
            discountPercentage: value.trim(),
            discountAmount: 0,
          };
        }
        if (event.target.name === "discountStartDate") {
          return {
            ...obj,
            discountStartDate: value.trim()
          };
        }
        if (event.target.name === "discountEndDate") {
          return {
            ...obj,
            discountEndDate: value.trim()
          };
        }
        if (event.target.name === "burnAmount") {
          return {
            ...obj,
            burnAmount: value.trim(),
            burnAmountPercentage: 0,
          };
        }
        if (event.target.name === "burnAmountPercentage") {
          return {
            ...obj,
            burnAmountPercentage: value.trim(),
            burnAmount: 0,
          };
        }
        if (event.target.name === "burnStartDate") {
          return {
            ...obj,
            burnStartDate: value.trim()
          };
        }
        if (event.target.name === "burnEndDate") {
          return {
            ...obj,
            burnEndDate: value.trim()
          };
        }
        if (event.target.name === "dpPrice") {
          return {
            ...obj,
            dpPrice: value.trim(),
            dpPricePercentage: 0,
          };
        }
        if (event.target.name === "dpPricePercentage") {
          return {
            ...obj,
            dpPricePercentage: value.trim(),
            dpPrice: 0,
          };
        }
        if (event.target.name === "isDpPriceFixManual") {
          if (value.trim() == 'F') {
            return {
              ...obj,
              dpPricePercentage: props?.sellerCommissionPercentage,
              dpPrice: 0,
              isDpPriceFixManual: value.trim(),
            };
          } else {
            return {
              ...obj,
              isDpPriceFixManual: value.trim(),
            };
          }
        }
      }
      return obj;
    });
    setProductDetails(newData);
  };

  const handleVariantImageDelete = (mainIndex, insideIndex) => {
    const filteredItems2 = productDetails[mainIndex].productImages.filter(
      function (element, index) {
        return index != insideIndex;
      }
    );
    const newData = productDetails.map((obj, idx) => {
      if (idx === mainIndex) {
        return {
          ...obj,
          productImages: filteredItems2
        };
      }
      return obj;
    });
    setProductDetails(newData);
  };

  const fileUploadAndResize = (e, index) => {
    let files = e.target.files;

    if (files) {
      for (let i = 0; i < files.length; i++) {
        const name = files[i].name;

        if (files[i].name.match(/\.(gif|GIF)$/)) {
          // Ensure the selected file is a GIF
          if (files[i].size / 1024 > 3585) {
            toast.error(`GIf Image size must be within 3.5Mb.`);
            return false;
          } else {
            if (files[i].type === "image/gif") {
              const reader = new FileReader();
              reader.onload = function (e) {
                let newImages = {};
                productDetails.map((d, idx) => {
                  if (index === idx) {
                    newImages = {
                      imageSeoName: name,
                      imageUrl: e.target.result,
                      productId: d.productId,
                      productImageDetails: d.productImages[index]
                        ?.productImageDetails
                        ? d.productImages[index]?.productImageDetails
                        : "product details",
                      shopId: d.shopId,
                      shopProductSku: d.shopProductSku,
                      status: d.productImages[index]?.status,
                      variantId: 0, // variantId will work for future
                    };
                  }
                });

                const newData = productDetails.map((obj, idx) => {
                  if (index === idx) {
                    productDetails[index].productImages.push(newImages);
                  }
                  return obj;
                });
                setProductDetails(newData);
                toast.success("GIF Product Image Selected.");
              };

              reader.readAsDataURL(files[i]);
            }
          }
        } else {
          const reader = new FileReader();
          reader.readAsDataURL(files[i]);
          reader.addEventListener("load", (event) => {
            const _loadedImageUrl = event.target.result;
            const image = document.createElement("img");
            image.src = _loadedImageUrl;

            image.addEventListener("load", () => {
              const { width, height } = image;
              if (width / height !== 1) {
                toast.error(
                  <>
                    <p
                      style={{
                        fontSize: "18px",
                        padding: "0",
                        margin: "0"
                      }}
                    >
                      Size Ratio must be 1:1
                    </p>{" "}
                    <span style={{ fontSize: "14px" }}>
                      Example: 1000x1000, 720x720 , 500x500
                    </span>
                  </>
                );
                return false;
              } else if (
                (width > 1000 && height > 1000) ||
                (width < 500 && height < 500)
              ) {
                toast.error(
                  `Image resolution must be (500x500)px to (1000x1000)px.`
                );
                return false;
              } else if (files[i].size / 1024 > 300) {
                toast.error(`Image size must be within 300Kb.`);
                return false;
              } else {
                if (files[i]) {
                  try {
                    Resizer.imageFileResizer(
                      files[i],
                      1000,
                      1000,
                      "JPEG",
                      100,
                      0,
                      (path) => {
                        let newImages = {};
                        productDetails.map((d, idx) => {
                          if (index === idx) {
                            newImages = {
                              imageSeoName: name,
                              imageUrl: path,
                              productId: d.productId,
                              productImageDetails: d.productImages[index]
                                ?.productImageDetails
                                ? d.productImages[index]?.productImageDetails
                                : "product details",
                              shopId: d.shopId,
                              shopProductSku: d.shopProductSku,
                              status: d.productImages[index]?.status,
                              variantId: 0, // variantId will work for future
                            };
                          }
                        });

                        const newData = productDetails.map((obj, idx) => {
                          if (index === idx) {
                            productDetails[index].productImages.push(
                              newImages
                            );
                          }
                          return obj;
                        });
                        setProductDetails(newData);
                      },
                      "base64",
                      1000,
                      1000
                    );
                  } catch (err) {
                    toast.error("Something went wrong!");
                  }
                }
              }
            });
          });
        }
      }
    }
  };

  const handleVariantDelete = (e, shopProductSku) => {
    e.preventDefault();
    for (var i = 0; i < productDetails.length; i++) {
      if (productDetails[i]?.shopProductSku === shopProductSku) {
        productDetails.splice(i, 1);
      }
    }
  };

  const burnCloseHandle = (burnAmount, burnPercent, burnStartDate, burnEndDate) => {
    burnAmount = isNaN(burnAmount)
      ? 0
      : burnAmount;
    burnPercent = isNaN(burnPercent)
      ? 0
      : burnPercent;

    setBurnOK(true);
    // Check if either burnAmount or burnPercent is non-zero
    if (
      burnAmount
      || burnPercent
    ) {
      // Check if either burnStartDate or burnEndDate is missing or empty
      if (
        !burnStartDate
        || !burnEndDate
      ) {
        setBurnOK(false)
        toast.error("Both Burn Start and End Date are Required!!");
      } else {
        setBurnOK(true)
        setShowBurn("");
      }
    } else {
      setBurnOK(true)
      setShowBurn("");
    }
  };

  const discountCloseHandle = (discountAmount, discountPercent, discountStartDate, discountEndDate) => {
    discountAmount = isNaN(discountAmount)
      ? 0
      : discountAmount;
    discountPercent = isNaN(discountPercent)
      ? 0
      : discountPercent;

    setDiscountOK(true)
    // Check if either discountAmount or discountPercent is non-zero
    if (
      discountAmount
      || discountPercent
    ) {
      // Check if either discountStartDate or discountEndDate is missing or empty
      if (
        !discountStartDate
        || !discountEndDate
      ) {
        setDiscountOK(false)
        toast.error("Both Discount Start and End Date are Required!!");
      } else {
        setDiscountOK(true)
        setShowDiscount("");
      }
    } else {
      setDiscountOK(true)
      setShowDiscount("");
    }
  };


  return (
    <div className="product_variants__section">
      <>
        {props.selectedCategoryId === 1076 ? null : (
          <form>
            <div className="price_and_stock">
              <div className="form-group">
                <div className="row">
                  <div className="col-md-12">
                    <table
                      className="table mt-30"
                      id="tableImg"
                    >
                      <tbody>
                        <tr
                          style={
                            props.productVariant.filter(
                              (variantData) =>
                                variantData.variantSetupTempleteId ===
                                1
                            ).length > 2
                              ? {
                                display: "grid",
                                gridTemplateColumns:
                                  "repeat(3, minmax(0, 1fr))",
                              }
                              : props.productVariant.filter(
                                (variantData) =>
                                  variantData.variantSetupTempleteId ===
                                  1
                              ).length > 1
                                ? {
                                  display: "grid",
                                  gridTemplateColumns:
                                    "repeat(2, minmax(0, 1fr))",
                                }
                                : {
                                  display: "grid",
                                  gridTemplateColumns:
                                    "repeat(1, minmax(0, 1fr))",
                                }
                          }
                        >
                          {props.productVariant
                            .filter(
                              (variantData) =>
                                variantData.variantSetupTempleteId ===
                                1
                            )
                            .map((variant, index) => {
                              return (
                                <td
                                  key={index}
                                  style={{
                                    width:
                                      "calc(100%-84px)",
                                  }}
                                >
                                  <Select
                                    name={`field${index}`}
                                    placeholder={`Select ${variant.variantName}`}
                                    options={variant.productVariantOptions.map(
                                      (data) => ({
                                        label:
                                          data.variantOptionText,
                                        value:
                                          data.variantOptionId,
                                      })
                                    )}
                                    components={
                                      animatedComponents
                                    }
                                    isMulti
                                    isSearchable
                                    onChange={
                                      props.handleMultiSelectChange
                                    }
                                    value={
                                      props.productVariantMap
                                    }
                                    closeMenuOnSelect={false}
                                  />
                                </td>
                              );
                            })}
                        </tr>
                      </tbody>
                    </table>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderBottom: "1px solid #eeeeee",
                        paddingBottom: "1rem",
                        marginTop: "-1.5rem",
                      }}
                    >
                      <button
                        style={{ width: "32.4%" }}
                        type="submit"
                        className="btn btn-info"
                        onClick={
                          props.combineProductVariant
                        }
                      >
                        <i className="fa fa-check"></i>
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
        <AddProductVariantTable
          category_id={props.selectedCategoryId}
          productVariants={props}
          duplicate_SKU_Entry={[]}
          checkDuplicateProduct=''
          productSku=''
          price_validation_count=''
          qty_validation_count=''
          isAdmin={props.isAdmin}
          sellerCommissionPercentage={props?.sellerCommissionPercentage}
          productDetails={productDetails}
          setNewProductDetails={setNewProductDetails}
          setVariants={setVariants}
          variants={variants}
          setBurnOK={props?.setBurnOKAdd}
          burnOK={props?.burnOKAdd}
          setDiscountOK={props?.setDiscountOKAdd}
          discountOK={props?.discountOKAdd}
        />
        {productDetails &&
          productDetails.map((prodDetails, idx) => {
            return (
              <React.Fragment key={idx}>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      {prodDetails?.productVariants.map((pv, idx) => {
                        return (
                          <th
                            style={{
                              fontWeight: "900",
                              width: idx === 0
                                ? "10%"
                                : "8%",
                            }}
                            key={idx}
                          >
                            {pv.variantName}
                          </th>
                        );
                      })}
                      <th
                        style={{
                          width: "8%",
                        }}
                      >
                        Price
                      </th>
                      <th
                        style={{
                          width: "8%",
                        }}
                      >
                        Quantity
                      </th>
                      <th>Seller Product SKU</th>
                      <th>Shop Product SKU</th>
                      <th>DP</th>
                      {props.isAdmin && <th>Burn</th>}
                      <th>Discount</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {prodDetails?.productVariants.map((pv, index) => {
                        return (
                          <td key={index}>
                            <select
                              className="form-control"
                              data-placeholder="Select"
                              tabIndex="1"
                              disabled
                            >
                              <option defaultValue>
                                {pv.variantOptionText}
                              </option>
                            </select>
                          </td>
                        );
                      })}
                      <td>
                        <input
                          type="number"
                          className={"form-control"}
                          name="productPrice"
                          onChange={(e) => handleVariantInputChange(idx, e)}
                          value={prodDetails?.productPrice}
                        ></input>
                      </td>
                      <td>
                        <input
                          type="number"
                          className={"form-control"}
                          name="productQuantity"
                          onChange={(e) => handleVariantInputChange(idx, e)}
                          value={prodDetails?.productQuantity}
                        ></input>
                      </td>
                      <td>
                        <input
                          type="text"
                          className={"form-control"}
                          name="sellerProductSku"
                          onChange={(e) => handleVariantInputChange(idx, e)}
                          value={prodDetails?.sellerProductSku}
                        ></input>
                      </td>
                      <td>{prodDetails?.shopProductSku}</td>


                      <td
                        className="action__table-section dp_section"
                        style={{
                          width: "12%"
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            justifyContent: "flexStart",
                          }}
                        >
                          <div
                            className="action_discount__btn-section"
                            style={{
                              position: "relative",
                              display: 'flex',
                              flexDirection: 'column'
                            }}
                          >
                            {prodDetails?.productPrice ?
                              <span
                                style={{
                                  color: 'rgb(219, 27, 27)',
                                  textWrap: 'nowrap'
                                }}
                              >
                                {prodDetails?.dpPricePercentage
                                  ? `DP ${prodDetails?.dpPricePercentage}% (${(prodDetails?.productPrice - (prodDetails?.discountAmount
                                    ? prodDetails?.discountAmount
                                    : ((prodDetails?.discountPercentage / 100) * prodDetails?.productPrice))) - ((prodDetails?.dpPricePercentage / 100) * (prodDetails?.productPrice - (prodDetails?.discountAmount
                                      ? prodDetails?.discountAmount
                                      : ((prodDetails?.discountPercentage / 100) * prodDetails?.productPrice)))).toFixed(2)})`
                                  : prodDetails?.dpPrice
                                    ? `Amt: ${prodDetails?.dpPrice} (${((((prodDetails?.productPrice - (prodDetails?.discountAmount
                                      ? prodDetails?.discountAmount
                                      : ((prodDetails?.discountPercentage / 100) * prodDetails?.productPrice))) - prodDetails?.dpPrice) / (prodDetails?.productPrice - (prodDetails?.discountAmount
                                        ? prodDetails?.discountAmount
                                        : ((prodDetails?.discountPercentage / 100) * prodDetails?.productPrice)))) * 100).toFixed(2)}%)`
                                    : "Empty DP value"}
                              </span>
                              : <span>{' '}</span>}
                            <Button
                              className="action_discount__btn"
                              onClick={(e) => {
                                setShowDP(idx + 1);
                                setShowBurn("")
                                setShowDiscount("")
                                e.preventDefault();
                              }}
                              disabled={
                                !prodDetails?.productPrice
                                || showDP
                                || showDiscount
                                || showBurn
                              }
                            >
                              Edit DP
                            </Button>
                            {showDP === (idx + 1)
                              && (
                                <section
                                  style={{
                                    zIndex: 50,
                                    background: "#fff",
                                    boxShadow: "0px 0px 2px 1px #c7c7c7",
                                    borderRadius: "5px",
                                    padding: "15px",
                                    right: "155px",
                                    top: "-123px",
                                    position: "absolute",
                                  }}
                                >
                                  <div className="flex">
                                    <button
                                      type="button"
                                      className="border-0 bg-none py-0"
                                      style={{
                                        color: "red",
                                        marginLeft: "auto",
                                      }}
                                      onClick={() => setShowDP("")}
                                    >
                                      X
                                    </button>
                                  </div>
                                  {props?.sellerCommissionPercentage !== 0
                                    && <div style={dp_radioStyle}>
                                      <section
                                        className="dp_radio_form__section"
                                        style={{ marginTop: '10px' }}
                                      >
                                        <input
                                          type="radio"
                                          id="Fixed"
                                          name="isDpPriceFixManual"
                                          value="F"
                                          onChange={(e) =>
                                            handleVariantInputChange(idx, e)
                                          }
                                          checked={
                                            prodDetails?.isDpPriceFixManual == 'F'
                                            || isDpPriceFixManual == 'F'
                                          } />
                                        <label
                                          for="Fixed"
                                          style={{ marginLeft: '10px' }}
                                        >Fixed</label>
                                      </section>
                                      <section className="dp_radio_form__section">
                                        <input
                                          type="radio"
                                          id="Manual"
                                          name="isDpPriceFixManual"
                                          value="M"
                                          onChange={(e) =>
                                            handleVariantInputChange(idx, e)
                                          }
                                          checked={
                                            prodDetails?.isDpPriceFixManual == 'M'
                                            || isDpPriceFixManual == 'M'
                                          } />
                                        <label
                                          for="Manual"
                                          style={{ marginLeft: '10px' }}
                                        >Manual</label>
                                      </section>
                                    </div>}
                                  {prodDetails?.isDpPriceFixManual == 'F'
                                    && <>
                                      <section className="discount_form__section">
                                        <label style={{ fontSize: "14px" }}>
                                          DP Price
                                        </label>
                                        <input
                                          style={discount_inputStyle}
                                          name="dpPrice"
                                          type="number"
                                          onChange={(e) =>
                                            handleVariantInputChange(idx, e)
                                          }
                                          value={
                                            prodDetails?.dpPricePercentage
                                              ? 0
                                              : prodDetails?.dpPrice
                                          }
                                          disabled={
                                            (prodDetails?.dpPricePercentage
                                              ? true
                                              : false)
                                            || (props?.sellerCommissionPercentage !== 0
                                              && prodDetails?.isDpPriceFixManual == 'F')
                                          }
                                        />
                                      </section>
                                      <section className="discount_form__section">
                                        <label style={{ fontSize: "14px" }}>
                                          DP Percent
                                        </label>
                                        <input
                                          style={discount_inputStyle}
                                          type="number"
                                          name="dpPricePercentage"
                                          onChange={(e) =>
                                            handleVariantInputChange(idx, e)
                                          }
                                          defaultValue={
                                            prodDetails?.dpPricePercentage
                                          }
                                          disabled={
                                            (props?.sellerCommissionPercentage !== 0
                                              && prodDetails?.isDpPriceFixManual == 'F')
                                          }
                                        />
                                      </section>
                                    </>}
                                  {(prodDetails?.isDpPriceFixManual == 'M'
                                    || prodDetails?.isDpPriceFixManual == null)
                                    && <>
                                      <section className="discount_form__section">
                                        <label style={{ fontSize: "14px" }}>
                                          DP Price
                                        </label>
                                        <input
                                          style={discount_inputStyle}
                                          name="dpPrice"
                                          type="number"
                                          onChange={(e) =>
                                            handleVariantInputChange(idx, e)
                                          }

                                          value={
                                            prodDetails?.dpPricePercentage
                                              ? 0
                                              : prodDetails?.dpPrice
                                          }
                                          disabled={
                                            (prodDetails?.dpPricePercentage
                                              ? true
                                              : false)
                                            || (props?.sellerCommissionPercentage !== 0
                                              && prodDetails?.isDpPriceFixManual == 'F')
                                          }
                                        />
                                      </section>
                                      <section className="discount_form__section">
                                        <label style={{ fontSize: "14px" }}>
                                          DP Percent
                                        </label>
                                        <input
                                          style={discount_inputStyle}
                                          type="number"
                                          name="dpPricePercentage"
                                          onChange={(e) =>
                                            handleVariantInputChange(idx, e)
                                          }
                                          defaultValue={
                                            prodDetails?.dpPricePercentage
                                          }
                                          disabled={
                                            (props?.sellerCommissionPercentage !== 0
                                              && prodDetails?.isDpPriceFixManual == 'F')
                                          }
                                        />
                                      </section>
                                    </>}
                                </section>
                              )}
                          </div>
                        </div>
                      </td>

                      {props.isAdmin &&
                        <td
                          className="action__table-section burn_section"
                          style={{
                            width: "12%"
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              justifyContent: "flexStart",
                            }}
                          >
                            <div
                              className="action_discount__btn-section"
                              style={{
                                position: "relative",
                                display: 'flex',
                                flexDirection: 'column'
                              }}
                            >
                              {prodDetails?.productPrice
                                ? <span
                                  style={{
                                    color: 'rgb(219, 27, 27)',
                                    textWrap: 'nowrap'
                                  }}
                                >
                                  {prodDetails?.burnAmountPercentage
                                    ? `Burn ${prodDetails?.burnAmountPercentage}% (${((prodDetails?.burnAmountPercentage / 100) * prodDetails?.productPrice).toFixed(2)})`
                                    : prodDetails?.burnAmount
                                      ? `Amt: ${prodDetails?.burnAmount} (${((prodDetails?.burnAmount / prodDetails?.productPrice) * 100).toFixed(2)}%)`
                                      : "Empty Burn value"}
                                </span>
                                : <span>{' '}</span>}
                              <Button
                                className="action_discount__btn"
                                onClick={(e) => {
                                  setShowBurn(idx + 1);
                                  setShowDP("");
                                  setShowDiscount("")
                                  setBurnOK(false)
                                  e.preventDefault();
                                }}
                                disabled={
                                  !prodDetails?.productPrice
                                  || showDP
                                  || showDiscount
                                  || showBurn
                                }
                              >
                                Edit Burn
                              </Button>
                              {showBurn === (idx + 1)
                                && (
                                  <section
                                    style={{
                                      zIndex: 50,
                                      background: "#fff",
                                      boxShadow: "0px 0px 2px 1px #c7c7c7",
                                      borderRadius: "5px",
                                      padding: "15px",
                                      right: "155px",
                                      top: "-123px",
                                      position: "absolute",
                                    }}
                                  >
                                    <div className="flex">
                                      <button
                                        type="button"
                                        className="border-0 bg-none py-0"
                                        style={{
                                          color: "red",
                                          marginLeft: "auto",
                                        }}
                                        onClick={() => burnCloseHandle(prodDetails?.burnAmount, prodDetails?.burnAmountPercentage, prodDetails?.burnStartDate, prodDetails?.burnEndDate)}
                                      >
                                        X
                                      </button>
                                    </div>
                                    {(!props?.burnOK
                                      && (prodDetails?.burnAmount
                                        || prodDetails?.burnAmountPercentage))
                                      ? <span
                                        style={{
                                          color: 'rgb(219, 27, 27)',
                                          textWrap: 'nowrap',
                                          fontStyle: 'italic'
                                        }}
                                      >
                                        Must fill Burn Start and End date
                                      </span>
                                      : <></>
                                    }
                                    <section className="discount_form__section">
                                      <label style={{ fontSize: "14px" }}>
                                        Burn Amount
                                      </label>
                                      <input
                                        style={discount_inputStyle}
                                        name="burnAmount"
                                        type="number"
                                        onChange={(e) =>
                                          handleVariantInputChange(idx, e)
                                        }
                                        value={
                                          prodDetails?.burnAmountPercentage
                                            ? 0
                                            : prodDetails?.burnAmount
                                        }
                                        disabled={
                                          prodDetails?.burnAmountPercentage
                                            ? true
                                            : false
                                        }
                                      />
                                    </section>
                                    <section className="discount_form__section">
                                      <label style={{ fontSize: "14px" }}>
                                        Burn Percent
                                      </label>
                                      <input
                                        style={discount_inputStyle}
                                        type="number"
                                        name="burnAmountPercentage"
                                        onChange={(e) =>
                                          handleVariantInputChange(idx, e)
                                        }
                                        defaultValue={
                                          prodDetails?.burnAmountPercentage
                                        }
                                      />
                                    </section>
                                    <section className="discount_form__section">
                                      <label style={{ fontSize: "14px" }}>
                                        Start Date <span
                                          aria-hidden="true"
                                          style={{
                                            color: "red",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          *
                                        </span>
                                      </label>
                                      <input
                                        style={discount_inputStyle}
                                        type="datetime-local"
                                        name="burnStartDate"
                                        onChange={(e) =>
                                          handleVariantInputChange(idx, e)
                                        }
                                        defaultValue={
                                          prodDetails?.burnStartDate
                                        }
                                      />
                                    </section>
                                    <section className="discount_form__section">
                                      <label style={{ fontSize: "14px" }}>
                                        End Date <span
                                          aria-hidden="true"
                                          style={{
                                            color: "red",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          *
                                        </span>
                                      </label>
                                      <input
                                        style={discount_inputStyle}
                                        type="datetime-local"
                                        name="burnEndDate"
                                        onChange={(e) =>
                                          handleVariantInputChange(idx, e)
                                        }
                                        defaultValue={prodDetails?.burnEndDate}
                                      />
                                    </section>
                                  </section>
                                )}
                            </div>
                          </div>
                        </td>}

                      <td
                        className="action__table-section discount_section"
                        style={{
                          width: props.isAdmin
                            ? "12%"
                            : "20%"
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            justifyContent: "flexStart",
                          }}
                        >
                          <div
                            className="action_discount__btn-section"
                            style={{
                              position: "relative",
                              display: 'flex',
                              flexDirection: 'column'
                            }}
                          >
                            {prodDetails?.productPrice
                              ? <span
                                style={{
                                  color: 'rgb(219, 27, 27)',
                                  textWrap: 'nowrap'
                                }}>
                                {prodDetails?.discountPercentage
                                  ? `Discount ${prodDetails?.discountPercentage}% (${((prodDetails?.discountPercentage / 100) * prodDetails?.productPrice).toFixed(2)})`
                                  : prodDetails?.discountAmount
                                    ? `Amt: ${prodDetails?.discountAmount} (${((prodDetails?.discountAmount / prodDetails?.productPrice) * 100).toFixed(2)}%)`
                                    : "Empty Discount value"}
                              </span>
                              : <span>{' '}</span>}
                            <Button
                              className="action_discount__btn"
                              onClick={(e) => {
                                setShowDiscount(idx + 1);
                                setShowDP("");
                                setShowBurn("")
                                setDiscountOK(false)
                                e.preventDefault();
                              }}
                              disabled={
                                !prodDetails?.productPrice
                                || showDP
                                || showDiscount
                                || showBurn
                              }
                            >
                              Edit Discount
                            </Button>
                            {showDiscount === (idx + 1) && (
                              <section
                                style={{
                                  zIndex: 50,
                                  background: "#fff",
                                  boxShadow: "0px 0px 2px 1px #c7c7c7",
                                  borderRadius: "5px",
                                  padding: "15px",
                                  right: "155px",
                                  top: "-123px",
                                  position: "absolute",
                                }}
                              >
                                <div className="flex">
                                  <button
                                    type="button"
                                    className="border-0 bg-none py-0"
                                    style={{
                                      color: "red",
                                      marginLeft: "auto",
                                    }}
                                    onClick={() => discountCloseHandle(prodDetails?.discountAmount, prodDetails?.discountPercentage, prodDetails?.discountStartDate, prodDetails?.discountEndDate)}
                                  >
                                    X
                                  </button>
                                </div>
                                {(!props?.discountOK
                                  && (prodDetails?.discountAmount
                                    || prodDetails?.discountPercentage))
                                  ? <span style={{
                                    color: 'rgb(219, 27, 27)',
                                    textWrap: 'nowrap',
                                    fontStyle: 'italic',
                                    marginBottom: '3px'
                                  }}>
                                    Must fill Discount Start and End date
                                  </span>
                                  : <></>
                                }

                                <p
                                  style={{
                                    color: "rgb(219, 27, 27)",
                                    fontSize: "12px",
                                    marginTop: 5,
                                  }}
                                >
                                  Combine Flat & Percentage Discount not Allowed
                                </p>

                                <section className="discount_form__section">
                                  <label style={{ fontSize: "14px" }}>
                                    Flat Discount
                                  </label>
                                  <input
                                    style={discount_inputStyle}
                                    name="discountAmount"
                                    type="number"
                                    onChange={(e) =>
                                      handleVariantInputChange(idx, e)
                                    }
                                    value={
                                      prodDetails?.discountPercentage
                                        ? 0
                                        : prodDetails?.discountAmount
                                    }
                                    disabled={
                                      prodDetails?.discountPercentage
                                        ? true
                                        : false
                                    }
                                  />
                                </section>
                                <section className="discount_form__section">
                                  <label style={{ fontSize: "14px" }}>
                                    Percent Discount
                                  </label>
                                  <input
                                    style={discount_inputStyle}
                                    type="number"
                                    name="discountPercentage"
                                    onChange={(e) =>
                                      handleVariantInputChange(idx, e)
                                    }
                                    defaultValue={
                                      prodDetails?.discountPercentage
                                    }
                                  />
                                </section>
                                <section className="discount_form__section">
                                  <label style={{ fontSize: "14px" }}>
                                    Start Date <span
                                      aria-hidden="true"
                                      style={{
                                        color: "red",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <input
                                    style={discount_inputStyle}
                                    type="datetime-local"
                                    name="discountStartDate"
                                    onChange={(e) =>
                                      handleVariantInputChange(idx, e)
                                    }
                                    defaultValue={
                                      prodDetails?.discountStartDate
                                    }
                                  />
                                </section>
                                <section className="discount_form__section">
                                  <label style={{ fontSize: "14px" }}>
                                    End Date <span
                                      aria-hidden="true"
                                      style={{
                                        color: "red",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <input
                                    style={discount_inputStyle}
                                    type="datetime-local"
                                    name="discountEndDate"
                                    onChange={(e) =>
                                      handleVariantInputChange(idx, e)
                                    }
                                    defaultValue={prodDetails?.discountEndDate}
                                  />
                                </section>
                              </section>
                            )}
                          </div>
                        </div>
                      </td>

                      <td>
                        <button
                          className="action_delete__btn"
                          onClick={(e) => {
                            handleVariantDelete(e, prodDetails.shopProductSku);
                            setStatus(status + 1);
                          }}
                          disabled={
                            showDiscount
                            || showBurn
                          }
                        >
                          <i
                            className="pi pi-trash"
                            style={{
                              fontSize: "1em",
                              color: "red"
                            }}
                          ></i>
                        </button>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan="100%">
                        {prodDetails.productImages.map((pv, index) => {
                          return (
                            <>
                              <div>
                                <div
                                  style={{
                                    float: "left",
                                  }}
                                >
                                  <div
                                    className="parent"
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    {pv.imageUrl.length < 500
                                      ? (
                                        <img
                                          src={baseUrl.concat(pv.imageUrl)}
                                          alt="img"
                                          key={index}
                                          height="98px"
                                          width="98px"
                                          style={{
                                            borderRadius: "10px",
                                            marginLeft: "10px",
                                            marginRight: "10px",
                                            cursor: "pointer",
                                          }}
                                        />
                                      ) : (
                                        <img
                                          src={pv.imageUrl}
                                          alt="img"
                                          key={index}
                                          height="98px"
                                          width="98px"
                                          style={{
                                            borderRadius: "10px",
                                            marginLeft: "10px",
                                            marginRight: "10px",
                                            cursor: "pointer",
                                          }}
                                        />
                                      )}

                                    <div
                                      className="child"
                                      style={{
                                        position: "absolute",
                                        top: "5px",
                                        right: "20px",
                                        fontSize: "23px",
                                        color: "#ff0000",
                                        cursor: "pointer",
                                      }}
                                      onClick={(e) =>
                                        handleVariantImageDelete(idx, index, e)
                                      }
                                    >
                                      <i className="fas fa-trash-alt "></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                        <div
                          style={{
                            textAlign: "right",
                          }}
                        >
                          <button
                            style={{
                              marginTop: "20px",
                              marginRight: "0px",
                              border: "0px",
                              cursor: "pointer",
                              background: " #fff",
                            }}
                          >
                            <div className="input-file-container file-area">
                              <input
                                type="file"
                                accept="image/*"
                                name=""
                                id="my-file"
                                multiple
                                onChange={(e) => fileUploadAndResize(e, idx)}
                              />
                              <div
                                className="file-dummy"
                                style={{
                                  padding: "20px",
                                  width: "330px",
                                }}
                              >
                                <div
                                  className="success"
                                  style={{
                                    color: "#000",
                                    cursor: "pointer",
                                    fontWeight: 500
                                  }}
                                >
                                  Click or Drop to Add More Image(s)
                                </div>
                              </div>

                            </div>
                          </button>
                          <div>
                            <span
                              style={{
                                fontSize: "12px",
                                color: "#db1b1b",
                              }}
                            >
                              *Image size must be within 300Kb and only 1:1
                              Ratio Allowed.
                            </span>
                            <br />
                            <span
                              style={{
                                fontSize: "12px",
                                color: "#db1b1b",
                              }}
                            >
                              *Image resolution must be (500x500)px to
                              (1000x1000)px.
                            </span>
                            <br />
                            <span
                              style={{
                                fontSize: "12px",
                                color: "#db1b1b",
                              }}
                            >
                              *GIF Image size must be within 3.5Mb.
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </React.Fragment>
            );
          })}
      </>
      <ToastContainer autoClose={2500} />
    </div>
  );
};

export default PriceAndStock;



