import http from "../../utils/httpClient";

class adminDashboardService {
  // Admin Order Summary Dashboard Data Service Start
  getAdminOrderSummaryDashboardData = async (
    startDate,
    endDate,
    shopNameIds
  ) => {
    return await http.get(
      `/api/Dashboard/GetOrderSummary?startDate=${startDate}&endDate=${endDate}&shopId=${shopNameIds}`
    );
  };
  // Admin Order Summary Dashboard Data Service End

  // Admin Amount Summary Dashboard Data Service Start
  getAdminAmountSummaryDashboardData = async (
    startDate,
    endDate,
    shopNameIds
  ) => {
    return await http.get(
      `/api/Dashboard/GetOrderAmountSummary?startDate=${startDate}&endDate=${endDate}&shopId=${shopNameIds}`
    );
  };
  // Admin Amount Summary Dashboard Data Service End

  // Admin Vendor Summary Dashboard Data Service Start
  getAdminVendorSummaryDashboardData = async (
    startDate,
    endDate,
    shopNameIds
  ) => {
    return await http.get(
      `/api/Dashboard/GetVendorSummary?startDate=${startDate}&endDate=${endDate}&shopId=${shopNameIds}`
    );
  };
  // Admin Vendor Summary Dashboard Data Service End

  // Admin Product Summary Dashboard Data Service Start
  getAdminProductSummaryDashboardData = async (
    startDate,
    endDate,
    shopNameIds
  ) => {
    return await http.get(
      `/api/Dashboard/GetProductSummary?startDate=${startDate}&endDate=${endDate}&shopId=${shopNameIds}`
    );
  };
  // Admin Product Summary Dashboard Data Service End

  // Admin Cancel Dashboard Data Service Start
  getAdminCancelDashboardData = async (
    startDate,
    endDate,
    shopNameIds
  ) => {
    return await http.get(
      `/api/Dashboard/GetCancelSummary?startDate=${startDate}&endDate=${endDate}&shopId=${shopNameIds}`
    );
  };
  // Admin Cancel Dashboard Data Service End

  // Admin Return Dashboard Data Service Start
  getAdminReturnDashboardData = async (
    startDate,
    endDate,
    shopNameIds
  ) => {
    return await http.get(
      `/api/Dashboard/GetReturnSummary?startDate=${startDate}&endDate=${endDate}&shopId=${shopNameIds}`
    );
  };
  // Admin Return Dashboard Data Service End

  // Admin Refund Dashboard Data Service Start
  getAdminRefundDashboardData = async (
    startDate,
    endDate,
    shopNameIds
  ) => {
    return await http.get(
      `/api/Dashboard/GetRefundSummary?startDate=${startDate}&endDate=${endDate}&shopId=${shopNameIds}`
    );
  };
  // Admin Refund Dashboard Data Service End

  // Get All Summaries Dashboard Data Service Start
  getAllSummariesDashboardData = async (
    startDate,
    endDate,
    shopNameIds
  ) => {
    return await http.get(
      `/api/Dashboard/GetAllSummaries?startDate=${startDate}&endDate=${endDate}&shopId=${shopNameIds}`
    );
  };
  // Get All Summaries Dashboard Data Service End
}

export default new adminDashboardService();
