import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import PurchaseOrderNew from "../../components/purchaseOrder/view/PurchaseOrderNew";
import authenticationService from "../../store/services/authenticationService";

const CreatePurchaseOrderContainer = () => {
  let history = useHistory();
  useEffect(() => {
    let roleId = authenticationService?.getRoleId();
    if (roleId === "1") {
    } else {
      history.push("/Login");
    }
  }, [history]);

  return (
    <>
      <PurchaseOrderNew />
    </>
  );
};

export default CreatePurchaseOrderContainer;
