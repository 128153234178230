import moment from "moment";
import { Chip } from "primereact/chip";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import baseUrl from "../../utils/baseUrl";
import LoadingCard from "../shared/LoadingCard";
import "./Order.css";

export default function OrderTrakingSummary({ orderSummary, loading, order_filter_tab }) {
  const onIndexTemplate = (rowData, props) => {
    return props.rowIndex + 1;
  };

  const imageBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <img
          src={baseUrl.concat(rowData.productImage)}
          alt={rowData.productUrl}
          className="product-image"
          style={{
            height: "50px",
            width: "50px",
            cursor: "pointer",
          }}
        ></img>
      </React.Fragment>
    );
  };
  const productTitleBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span>{rowData.productTitle}</span>
      </React.Fragment>
    );
  };
  const styleSkuBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span>{rowData.sku === null ? "N/A" : rowData.sku}</span>
      </React.Fragment>
    );
  };
  const sellerProductSkuBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span>
          {rowData.sellerProductSku === null ? "N/A" : rowData.sellerProductSku}
        </span>
      </React.Fragment>
    );
  };
  const sellerProductVariantsBodyTemplate = (rowData) => {
    const variant = rowData?.productVariant?.split(",");

    let final = [];
    variant &&
      variant.map((v, i) => {
        const single = v.split(": ");
        final.push(single);
      });


    return (
      <React.Fragment>
        <span>
          {final.map((item, i) => (
            <>
              {item[0] === " null" || item[0] === " undefined" ? (
                <></>
              ) : (
                <p style={{ marginBottom: "0px" }}>
                  {item[0]}: <strong>{item[1]}</strong>
                </p>
              )}
            </>
          ))}
          {/* {rowData.productVariant === null ? 'N/A' : rowData.productVariant} */}
        </span>
      </React.Fragment>
    );
  };
  const productPriceBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span>{rowData.productPrice}</span>
      </React.Fragment>
    );
  };
  const productQuantityBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span>{rowData.productQuantity}</span>
      </React.Fragment>
    );
  };
  const productAmountBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span>
          {/* {rowData.productQuantity * rowData.productPrice} */}
          {rowData?.amount}
        </span>
      </React.Fragment>
    );
  };

  const productDiscountBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span>{rowData?.discountFaltAmt + rowData?.burnAmount}</span>
      </React.Fragment>
    );
  };

  const totalAmountBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span>{rowData?.netAmount ? rowData?.netAmount : 0}</span>
      </React.Fragment>
    );
  };
  const updateDateTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span>
          {rowData?.updateDate ? moment(rowData?.updateDate).format("Do MMMM YYYY, h:mm A") : '--'}
        </span>
      </React.Fragment>
    );
  };
  const headerTemplate = (rowData) => {
    return (
      <React.Fragment>
        <div className="table-header">
          <div className="p-d-flex p-jc-between">
            <div>
              <strong
                style={{
                  color: "#0283d4",
                  fontSize: "14px",
                  fontWeight: "700",
                }}
              >
                {rowData.shopName}
              </strong>
              <strong
                style={{
                  marginLeft: "5px",
                }}
              >
                <Chip
                  label={rowData.orderNo}
                  className="p-mr-2 p-mb-2"
                  style={{
                    color: "#0283d4",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                />
              </strong>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const address = JSON.parse(orderSummary?.shippingAddress);

  return (
    <>
      {loading ? (
        <LoadingCard count={1} />
      ) : (
        <div className="row mx-0">
          <div className="col-md-4 px-0">
            <div className="table-responsive">
              <strong>Customer Details</strong>
              <table style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <th style={{ padding: "2px" }}>Name:</th>
                    <td style={{ padding: "2px" }}>{orderSummary?.customerName}</td>
                  </tr>
                  <tr>
                    <th style={{ padding: "2px" }}>Contact No:</th>
                    <td style={{ padding: "2px" }}>
                      {JSON.parse(orderSummary?.shippingAddress)?.contactNumber}
                    </td>
                  </tr>
                  <tr>
                    <th style={{ padding: "2px" }}>Order Date:</th>
                    <td style={{ padding: "2px" }}>
                      {moment(orderSummary?.createDate).format(
                        "Do MMMM YYYY, h:mm A"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th style={{ padding: "2px", verticalAlign: "top" }}>
                      Shipping Address:
                    </th>
                    <td style={{ padding: "2px" }}>
                      {address?.address}, {address?.areaName}, {address?.cityName}
                    </td>
                  </tr>
                  <tr>
                    <th style={{ padding: "2px" }}>
                      Order Source:
                    </th>
                    <td style={{ padding: "2px" }}>
                      {orderSummary?.orderSourch}
                    </td>
                  </tr>
                  <tr>
                    <th style={{ padding: "2px" }}>
                      Remarks:
                    </th>
                    <td style={{ padding: "2px" }}>
                      {orderSummary.customerOrderNote}
                    </td>
                  </tr>
                </tbody>
              </table>

              <br />
              <strong>Payment Info</strong>
              <table style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <th style={{ padding: "2px" }}>Tran. No:</th>
                    <td style={{ padding: "2px" }}>{orderSummary?.tranNo}</td>
                  </tr>
                  <tr>
                    <th style={{ padding: "2px" }}>Payment Date:</th>
                    <td style={{ padding: "2px" }}>
                      {orderSummary?.paymentDate &&
                        moment(orderSummary?.paymentDate).format(
                          "Do MMMM YYYY, h:mm A"
                        )}
                    </td>
                  </tr>
                  <tr>
                    <th style={{ padding: "2px" }}>Account No:</th>
                    <td style={{ padding: "2px" }}>{orderSummary?.payAccountNo}</td>
                  </tr>
                  <tr>
                    <th style={{ padding: "2px" }}>Method:</th>
                    <td style={{ padding: "2px" }}>
                      {orderSummary?.paymentMethodId > 0 && orderSummary?.paymentMethodName
                        ? orderSummary?.paymentMethodName === "COD"
                          ? "Cash On Delivery"
                          : orderSummary?.paymentMethodName
                        : "Not Selected Yet"}
                    </td>
                  </tr>
                  <tr>
                    <th style={{ padding: "2px" }}>Status:</th>
                    <td style={{ padding: "2px" }}>
                      {orderSummary?.paymentStatus}
                    </td>
                  </tr>
                </tbody>
              </table>

              {orderSummary?.emiBankTenure ?
                <>
                  <br />
                  <strong>EMI Info</strong>
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <th style={{ padding: "2px" }}>Bank Name:</th>
                        <td style={{ padding: "2px" }}>{orderSummary?.emiBankName}</td>
                      </tr>
                      <tr>
                        <th style={{ padding: "2px" }}>Tenure:</th>
                        <td style={{ padding: "2px" }}>{orderSummary?.emiBankTenure} Months</td>
                      </tr>

                    </tbody>
                  </table>
                </> : null}

              <table style={{ width: "100%" }}>
                <tbody>
                  <tr style={{
                    borderTop: "1px solid #dee2e6",
                  }}>
                    <th style={{ padding: "2px", color: "#0283D4" }}>Sub-Total:</th>
                    <td style={{ padding: "2px", fontWeight: "600" }}>{orderSummary?.orderSubtotalAmt}</td>
                  </tr>

                  <tr>
                    <th style={{ padding: "2px", color: "#E74A4F" }}>Discount:</th>
                    <td style={{ padding: "2px", fontWeight: "600" }}>{orderSummary?.orderSubtotalDiscountAmt}</td>
                  </tr>
                  {orderSummary?.voucherAmount !== 0 && (
                    <tr>
                      <th style={{ padding: "2px", color: "#E74A4F" }}>
                        Voucher/Coupon:
                        <span
                          style={{
                            padding: "2px",
                            fontWeight: "500",
                            color: "#E74A4F",
                            display: "block",
                          }}
                        >
                          ({orderSummary?.voucherCode})
                        </span>
                      </th>
                      <td style={{ padding: "2px", fontWeight: "600", verticalAlign: 'top' }}>
                        {orderSummary?.voucherAmount}
                      </td>
                    </tr>
                  )}

                  <tr>
                    <th style={{ padding: "2px", color: "#0283D4" }}>VAT:</th>
                    <td style={{ padding: "2px", fontWeight: "600" }}>{orderSummary?.totalVatFlatAmt}</td>
                  </tr>
                  <tr>
                    <th style={{ padding: "2px", color: "#0283D4" }}>TAX:</th>
                    <td style={{ padding: "2px", fontWeight: "600" }}>{orderSummary?.taxAmount}</td>
                  </tr>
                  <tr>
                    <th style={{ padding: "2px", color: "#0283D4" }}>Shipping Cost:</th>
                    <td style={{ padding: "2px", fontWeight: "600" }}>{orderSummary?.totalShippingCharge}</td>
                  </tr>
                  <tr style={{ borderTop: "1px solid #dee2e6" }}>
                    <th style={{ padding: "2px", color: "#E74A4F" }}>Grand Total:</th>
                    <td style={{ padding: "2px", fontWeight: "600" }}>{orderSummary?.totalPayableAmount}</td>
                  </tr>
                </tbody>
              </table>

              {/* {order_filter_tab === 'all' && <table className="payment_summary__table">
              <tbody>
                <tr>
                  <th>Order Status</th>
                  <td>{orderSummary?.statusName}</td>
                </tr>
              </tbody>
            </table>} */}
            </div>
          </div>
          <div className="col-md-8 px-0">
            <div>
              <strong>Order Details</strong>
              {orderSummary?.shopWiseOrders?.map((data, index) => (
                <div className="datatable-responsive-demo">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "10px 5px",
                      backgroundColor: "#F8F9FA",
                      borderTop: "1px solid #dee2e6",
                      borderBottom: "1px solid #dee2e6",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p
                        style={{
                          color: "#0283d4",
                          fontSize: "14px",
                          fontWeight: "700",
                        }}
                      >
                        {data?.shopName}
                      </p>
                      <p
                        style={{
                          marginLeft: "5px",
                          backgroundColor: "#DEE2E6",
                          color: "#0283d4",
                          fontSize: "14px",
                          fontWeight: "600",
                          borderRadius: "20px",
                          padding: "2px 10px",
                        }}
                      >
                        {data?.orderNo}
                      </p>
                    </div>
                  </div>

                  <div key={Math.random()}
                    style={{
                      maxHeight: "60vh",
                      overflowY: "auto",
                      position: "relative",
                      width: "100%",
                      overflowX: "hidden",
                    }}>
                    <DataTable
                      value={data?.orderDetails}
                      className="p-datatable-customers p-datatable-responsive-demo"
                      dataKey="orderNo"
                      rowHover
                      emptyMessage="No order(s) found"
                      key={data?.shopWiseOrderId}
                      responsiveLayout="scroll"
                    >
                      <Column
                        field="Index"
                        header="SN"
                        body={onIndexTemplate}
                        className="listSL"
                      />
                      <Column
                        field="productImage"
                        header="Image"
                        body={imageBodyTemplate}
                      />
                      <Column
                        field="productTitle"
                        filterField="productTitle"
                        header="Product Name"
                        body={productTitleBodyTemplate}
                        sortable
                      />
                      <Column
                        field="sellerProductSku"
                        filterField="sellerProductSku"
                        header="SKU/Style"
                        body={styleSkuBodyTemplate}
                        sortable
                      />
                      <Column
                        field="sellerProductSku"
                        filterField="sellerProductSku"
                        header="Seller SKU"
                        body={sellerProductSkuBodyTemplate}
                        sortable
                      />
                      <Column
                        field="productVariant"
                        filterField="productVariant"
                        header="Variants"
                        body={sellerProductVariantsBodyTemplate}
                        sortable
                      />
                      <Column
                        field="productPrice"
                        filterField="productPrice"
                        header="Rate"
                        body={productPriceBodyTemplate}
                        sortable
                      />
                      <Column
                        field="productQuantity"
                        filterField="productQuantity"
                        header="Qty"
                        body={productQuantityBodyTemplate}
                        sortable
                      />
                      <Column
                        field="productAmount"
                        header="Amount"
                        body={productAmountBodyTemplate}
                        sortable
                      />

                      <Column
                        field="productDiscount"
                        header="Discount"
                        body={productDiscountBodyTemplate}
                        sortable
                      />

                      <Column
                        field="totalPayableAmt"
                        header="Total"
                        body={totalAmountBodyTemplate}
                        sortable
                      />
                      {order_filter_tab === 'all' &&
                        <Column
                          field="statusName"
                          header="Status"
                          //  body={totalAmountBodyTemplate}
                          sortable
                        />}
                      <Column
                        field="updateDate"
                        header="Last Updated"
                        body={updateDateTemplate}
                        sortable
                        headerStyle={{ width: '20%' }}
                      />
                    </DataTable>
                  </div>
                </div>


              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

