import Axios from "axios";
import React, { useState } from "react";
import * as Icon from "react-feather";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import baseUrl from "../../utils/baseUrl";

const CommissionType = () => {
  const locationState = useLocation().state;

  const [commissionTypeData, setCommissionTypeData] = useState({});
  const [isChecked, setIsChecked] = useState(
    locationState?.data
      ? locationState?.data?.isActive === "Y"
        ? true
        : false
      : true
  );

  const navigateTo = useHistory();
  const token = localStorage.getItem("x-access-token");

  const handleBlur = (e) => {
    const { name, value } = e.target;

    const newArr = { ...commissionTypeData };
    newArr[name] = value;
    setCommissionTypeData(newArr);

  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      ...locationState?.data,
      ...commissionTypeData,
      isActive: isChecked ? "Y" : "N",
      status: "N",
      // isApprove: 'Y'
    };

    const response = await Axios.post(
      `${baseUrl}/api/Commission/CreateOrUpdateSellerCommisionType`,
      data,
      { headers: { Authorization: `Bearer ${token}` } }
    );

    if (response?.data?.succeed) {
      navigateTo.push("/commissionTypeList");
      if(locationState?.data){
        toast.success("Updated Successfully");
      }else{
        toast.success("Added Successfully");
       
      }
    } else {
      toast.error(response?.data?.errors[0]);
    }
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="panel panel-success">
                <div className="panel-heading">
                  Create Product Commission Type
                  <span style={{ float: "right" }}>
                    <Link to="/commissionTypeList">
                      <Icon.List className="text-light" />
                    </Link>
                  </span>
                </div>
                <div className="panel-wrapper collapse in" aria-expanded="true">
                  <div className="panel-body">
                    <form className="form-horizontal" onSubmit={handleSubmit}>
                      <div className="form-body">
                        <div className="row">
                          <div className="col-md-12 col-sm-12">
                            <div className="form-group">
                              <label className="control_label">
                                Commission Type Name
                                <span
                                  aria-hidden="true"
                                  style={{
                                    color: "red",
                                    fontWeight: "bold",
                                  }}
                                >
                                  *
                                </span>
                              </label>
                              <input
                                type="text"
                                placeholder="Enter Commission Type Name"
                                name="commissionTypeName"
                                onBlur={(e) => handleBlur(e)}
                                defaultValue={
                                  locationState?.data?.commissionTypeName
                                }
                                className="form-control"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="p-field p-fluid">
                          <div className="form-group">
                            <label className="control_label">
                              Remark
                            </label>
                            <textarea
                              rows="4"
                              placeholder="Enter your Remarks"
                              name="remarks"
                              onBlur={(e) => handleBlur(e)}
                              defaultValue={
                                locationState?.data?.remarks
                              }
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12 col-sm-12">
                            <div className="form-group">
                              <label className="control_label">Active </label>
                              <div className="checkbox checkbox-success">
                                <input
                                  id="isActive"
                                  type="checkbox"
                                  name="isActive"
                                  defaultChecked={isChecked}
                                  onClick={() => setIsChecked((prev) => !prev)}
                                />
                                <label htmlFor="isActive"> &nbsp;Yes </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="form-footer ">
                          <div className="form-group row">
                            <div className="text-center">
                              <div className="btn-group text-center">
                                <button className="btn btn-success">
                                  {locationState?.data ? "Update" : "Create"}
                                </button>
                                <Link to="/commissionTypeList">
                                  <button
                                    className="btn btn-danger"
                                    style={{ cursor: "pointer" }}
                                  >
                                    Cancel
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={1000} />
    </>
  );
};

export default CommissionType;
