import { GET_WARRANTY_PERIOD, GET_WARRANTY_PERIOD_ERROR, GET_WARRANTY_PERIOD_SUCCESS, GET_WARRANTY_TYPE, GET_WARRANTY_TYPE_ERROR, GET_WARRANTY_TYPE_SUCCESS } from "../actions/warrantyAction"

  const initialState = {
    warrantyPeriodData: [], 
    warrantyTypeData: [],
    loading: false,
    loaded: false,
    error: null,
  }

  export default function (state = initialState, action) {
    switch (action.type) {
     
      case GET_WARRANTY_TYPE:
        return {
          ...state,
          loading: true,
          loaded: false,
          error: null,
        }
  
      case GET_WARRANTY_TYPE_SUCCESS:
        return {
          ...state,
          loading: false,
          loaded: true,
          warrantyTypeData: action.payload.success,
        }
  
      case GET_WARRANTY_TYPE_ERROR:
        return {
          ...state,
          loading: false,
          loaded: false,
          error: action.payload.error,
          warrantyTypeData: [],
        }

        case GET_WARRANTY_PERIOD:
        return {
          ...state,
          loading: true,
          loaded: false,
          error: null,
        }

        case GET_WARRANTY_PERIOD_SUCCESS:
        return {
          ...state,
          loading: false,
          loaded: true,
          warrantyPeriodData: action.payload.success,
        }

        case GET_WARRANTY_PERIOD_ERROR:
        return {
          ...state,
          loading: false,
          loaded: false,
          error: action.payload.error,
          warrantyPeriodData: [],
        }
  
      default:
        return state
    }
  }