import React from "react";
import PaymentTypeWiseSalesSummaryView from "../view/paymentTypeWiseSalesSummaryView";

const AccountsReportPrint = () => {
  return (
    <div className="page-wrapper">
      <div className="container-fluid">
        <PaymentTypeWiseSalesSummaryView />
      </div>
    </div>
  );
};

export default AccountsReportPrint;
