/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-use-before-define */
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from "react-router-dom";
import LoadingCard from "../../components/shared/LoadingCard";
import Message from "../../components/shared/Message";
import { getShopDetailsBySellerIdRecord } from "../../store/actions/sellerProfileAction";
import { getStoreBannerByShopIdRecord } from '../../store/actions/storeAction';
import sellerService from "../../store/services/sellerService";
import baseUrl from "../../utils/baseUrl";

const AllStoreBannerListSellerContainer = () => {
  const dispatch = useDispatch();
  const navigateTo = useHistory();

  const [globalFilter, setGlobalFilter] = useState('')
  const [productImageHoverImage, setProductImageHoverImage] = useState(null);

  const sellerProfile = useSelector((state) => state.sellerProfileReducer.shopDetails);
  const { getShopWiseBannerData, loading, error } = useSelector(
    (state) => state.storeReducer
  );

  useEffect(() => {
    let userId = sellerService.getEmployeeId();
    dispatch(getShopDetailsBySellerIdRecord(userId));
  }, [dispatch]);


  useEffect(() => {
    sellerProfile && sellerProfile[0] && dispatch(getStoreBannerByShopIdRecord(sellerProfile[0]?.shopId));
  }, [dispatch, sellerProfile]);

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="p-text-bold table-heading-style">
          All Banner List
        </div>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Link to="/CreateSellerStoreBanner">
          <div className="button-demo">
            <Button
              icon="pi pi-times"
              className="p-button-rounded p-button-danger p-button-outlined"
            />
          </div>
        </Link>
      </React.Fragment>
    );
  };

  const onIndexTemplate = (rowData, props) => {
    return props.rowIndex + 1;
  };
  const imageBodyTemplate = (rowData) => {
    const handleMouseEnter = (data) => {
      setProductImageHoverImage(data.sellerStoreBannerImageUrl);
    };
    return (
      <div style={{ position: "relative" }}>
        <img
          onMouseEnter={() => handleMouseEnter(rowData)}
          className="product_list__img"
          style={{
            width: '100px',
            height: '50px',
            borderRadius: "10px",
            cursor: "pointer",
            // border: "1px solid #ddd",
            objectFit: "contain",
          }}
          src={baseUrl.concat(rowData.sellerStoreBannerImageUrl)}
          alt="IMG"
        />
        <div className="product_list__tooltip_banner">
          <div className="tooltip__img">
            <img
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "contain",
              }}
              src={baseUrl.concat(productImageHoverImage)}
              alt="product image"
            />
          </div>
        </div>
        <div className="image_tooltip__indicator"></div>
      </div>
    );
  };

  const editActionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          onClick={() => {
            navigateTo.push({
              pathname: "/UpdateSellerStoreBanner",
              state: { data: rowData },
            });
          }}
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success p-mr-2"
        />
      </React.Fragment>
    );
  };


  const renderHeader = () => {
    return (
      <>
        <div className="table-header">
          <span className="p-input-icon-left">
            <InputText
              type="search"
              className="form-control text-center text-field"
              onInput={(e) => setGlobalFilter(e.target.value)}
              placeholder="Search by Store Banner"
            />
          </span>
        </div>
      </>
    )
  }
  return (
    <div className="page-wrapper">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="white-box">
              <div className="datatable-doc-demo datatable-responsive-demo">
                <div className="card">
                  <Toolbar
                    className="p-mb-4"
                    left={leftToolbarTemplate}
                    right={rightToolbarTemplate}
                  />
                  {loading ? (
                    <LoadingCard count={1} />
                  ) : error ? (
                    <Message variant="danger">{error}</Message>
                  ) : (
                    <DataTable
                      header={renderHeader}
                      value={getShopWiseBannerData?.data}
                      className="p-datatable-customers p-datatable-responsive-demo"
                      dataKey="id"
                      rowHover
                      globalFilter={globalFilter}
                      paginator
                      rows={10}
                      rowsPerPageOptions={[10, 25, 50]}
                      emptyMessage="No Store Banner found"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                      loading={loading}
                    >
                      <Column
                        field="Index"
                        header="SN"
                        name=""
                        body={onIndexTemplate}
                      />

                      <Column
                        sortField="shopName"
                        filterField="shopName"
                        header="Shop Name"
                        field="shopName"
                        sortable
                      />

                      <Column
                        sortField="sellerStoreBannerName"
                        filterField="sellerStoreBannerName"
                        header="Banner Name"
                        field="sellerStoreBannerName"
                        sortable
                      />

                      <Column
                        sortField='sellerStoreBannerImageUrl'
                        header='Banner Image'
                        body={imageBodyTemplate}
                        sortable
                      />

                      <Column
                        sortField="displayOrder"
                        filterField="displayOrder"
                        header="Display Order"
                        field="displayOrder"
                        sortable
                      />

                      <Column
                        sortField="isActive"
                        header="Status"
                        body={(rowData, props) => {
                          return rowData.isActive == "Y"
                            ? <Tag
                              className="mr-2"
                              severity="success"
                              value="Active"
                              rounded
                              fontSize="1rem"
                            /> :
                            <Tag
                              className="mr-2"
                              severity="danger"
                              value="In-Active"
                              rounded
                              fontSize="1rem"
                            />;
                        }}
                        sortable
                      />

                      <Column
                        field="action"
                        header="Edit"
                        body={editActionBodyTemplate}
                      />
                    </DataTable>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AllStoreBannerListSellerContainer