import http from "../../utils/httpClient";

class warrantyPeriodService {
  getWarrantyPeriod = async (isActive, warrantyPeriodName) => {
    return await http.get(
      `/api/Warranty/GetWarrantyPeriod?isActive=${
        isActive || ""
      }&warrantyPeriodName=${warrantyPeriodName || ""}&status=N&getAll=Y`
    );
  };

  getWarrantyType = async (isActive, warrantyTypeName) => {
    return await http.get(
      `/api/Warranty/GetWarrantyType?isActive=${
        isActive || ""
      }&warrantyTypeName=${warrantyTypeName || ""}&status=N&getAll=Y`
    );
  };
}

export default new warrantyPeriodService();
