/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { getShopRecord } from "../../../store/actions/shopAction";

const MultipleShopSelect = ({ setShopNameIds }) => {
  const [shopNames, setShopNames] = useState(null);
  const [shopLists, setShopLists] = useState([]);
  const [modifiedOptions, setModifiedOptions] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getShopRecord());
  }, [dispatch]);

  const { shops } = useSelector((state) => state?.shopReducer);

  useEffect(() => {
    var result = "";
    if (shopNames) {
      result = shopNames
        .map(function (item) {
          return item.value;
        })
        .join(",");
    }
    setShopNameIds(result.toString());
  }, [setShopNameIds, shopNames]);

  useEffect(() => {
    setModifiedOptions(shopLists);
  }, [shopLists]);

  useEffect(() => {
    shops &&
      setShopLists([
        {
          label: "All Stores Except SaRa",
          value: -2,
        },
        ...shops.map((data) => ({
          label: data?.shopName,
          value: data?.shopId,
        })),
      ]);
  }, [shops]);

  useEffect(() => {
    if (shopNames && shopNames[0].value === -2) {
      setModifiedOptions(
        shopLists.map((option) => ({
          ...option,
          isDisabled:
            shopNames && shopNames[0].value === -2 && option.value !== -2,
        }))
      );
    } else if (shopNames && shopNames[0].value !== -2) {
      setModifiedOptions(
        shopLists.map((option) => ({
          ...option,
          isDisabled:
            shopNames && shopNames[0].value !== -2 && option.value === -2,
        }))
      );
    } else {
      setModifiedOptions(
        shopLists.map((option) => ({
          ...option,
          isDisabled: false,
        }))
      );
    }
  }, [shopNames]);
  
  const handleShopMultiSelectChange = (shopNames) => {
    setShopNames(shopNames);
  };

  const animatedComponents = makeAnimated();

  return (
    <>
      <Select
        style={{
          width: "100%",
          color: "#000000",
        }}
        options={modifiedOptions}
        components={animatedComponents}
        placeholder={"Select Shop Name"}
        isMulti
        isSearchable
        onChange={handleShopMultiSelectChange}
        value={shopNames}
        closeMenuOnSelect={false}
        isDisabled={shops?.length === 1 ? true : false}
      />
    </>
  );
};

export default MultipleShopSelect;
