import outletService from "../services/outletService"

export const CREATE_OR_UPDATE_OUTLET = "CREATE_OR_UPDATE_OUTLET"
export const CREATE_OR_UPDATE_OUTLET_SUCCESS = "CREATE_OR_UPDATE_OUTLET_SUCCESS"
export const CREATE_OR_UPDATE_OUTLET_ERROR = "CREATE_OR_UPDATE_OUTLET_ERROR"
export const CREATE_OR_UPDATE_OUTLET_RESET = "CREATE_OR_UPDATE_OUTLET_RESET"

export const GET_OUTLET = "GET_OUTLET"
export const GET_OUTLET_SUCCESS = "GET_OUTLET_SUCCESS"
export const GET_OUTLET_ERROR = "GET_OUTLET_ERROR"

export function createOrUpdateOutletRecord(festivalData) {
    return async (dispatch) => {
        dispatch(createOrUpdateOutlet())
        return await outletService
            .createOrUpdateOutlet(festivalData)

            .then((response) => dispatch(createOrUpdateOutletSuccess(response)))
            .catch((error) => dispatch(createOrUpdateOutletError(error)))
    }
}

export function createOrUpdateOutlet() {
    return {
        type: CREATE_OR_UPDATE_OUTLET,
    }
}

export function createOrUpdateOutletSuccess(success) {
    return {
        type: CREATE_OR_UPDATE_OUTLET_SUCCESS,
        payload: { success },
    }
}

export function createOrUpdateOutletError(error) {
    return {
        type: CREATE_OR_UPDATE_OUTLET_ERROR,
        payload: { error },
    }
}

export function createOrUpdateOutletReset() {
    return {
        type: CREATE_OR_UPDATE_OUTLET_RESET,
    }
}


export function getAllOutletRecord() {
    return async (dispatch) => {
        dispatch(getAllOutlet())
        return await outletService.getAllOutlet()
            .then((response) => dispatch(getAllOutletSuccess(response)))
            .catch((error) => dispatch(getAllOutletError(error)))
    }
}

export function getAllOutlet() {
    return {
        type: GET_OUTLET,
    }
}

export function getAllOutletSuccess(success) {
    return {
        type: GET_OUTLET_SUCCESS,
        payload: { success },
    }
}

export function getAllOutletError(error) {
    return {
        type: GET_OUTLET_ERROR,
        payload: { error },
    }
}