import { useEffect, useState } from 'react';

const useDisabled = () => {
    const [disabled, setDisabled] = useState(false);
    const [time, setTime] = useState(0);

    useEffect(() => {
        let timeLeft;

        if (time > 0) {
            timeLeft = setInterval(() => {
                setTime((prev) => prev - 1);
            }, 1000);
        }

        if (time === 0) {
            clearInterval(timeLeft);
            setDisabled(false); // Re-enable the button after 5 seconds
        }

        return () => clearInterval(timeLeft);
    }, [time]);

    return { disabled, setDisabled, time, setTime }
};

export default useDisabled;