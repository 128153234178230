import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import React, { Component } from "react";
import Resizer from "react-image-file-resizer";
import { connect } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import EditCategory from "../../../components/settings/category/editCateogry";
import * as activeBreadcrumbsCategoryAction from "../../../store/actions/activeBreadcrumbsCategoryAction";
import * as categoryAction from "../../../store/actions/categoryAction";
import authenticationService from "../../../store/services/authenticationService";

class editCategoryContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryId: 0,
      categoryName: "",
      localCmsnPercentage: "",
      localCmsnFlatAmt: "",
      globalCmsnPercentage: "",
      globalCmsnFlatAmt: "",
      metaKeywords: "",
      metaTitle: "",
      displayOrder: "",
      description: "",
      metaDescription: "",
      parentCategoryId: 0,
      selectedParentCategoryId: "",
      productImagePath: "",
      imageType: "",
      showFile: "",
      file: "", // to store the Single pictures in base64 format.
      files: [],
      fileName: "",
      showOnHomepage: true,
      includeInTopMenu: true,
      isActive: true,
      isProduct: true,
      editorState: EditorState.createEmpty(),
      uploadedImages: [],
      pageSize: 0,
      isDeleted: "N",
      errorDescription: "",
      errorCategoryName: "",
      errorMetaTitle: "",
      errorDisplayOrder: "",
      errorMetaKeywords: "",
      errorMetaDescription: "",
      isReturnable: false,
      isPopular: false,
      isNewArrival: false,
      isTopCategory: false,
    };
    this.onBasicUpload = this.onBasicUpload.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.saveCategory = this.saveCategory.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.categoryLogoUrlHandler = this.categoryLogoUrlHandler.bind(this);
    this._uploadImageCallBack = this._uploadImageCallBack.bind(this);
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
    this.handleParentCheck = this.handleParentCheck.bind(this);
  }

  componentDidMount = async () => {
    //Begin Temporary Authentication
    let roleId = authenticationService.getRoleId();
    if (roleId === "1") {
      this.setState({
        authenticated: true,
        loginSuccessful: true,

        categoryId: this.props?.location?.state?.rowData?.categoryId,
        categoryName: this.props?.location?.state?.rowData?.categoryName,
        breadcrumbCategory:
          this.props?.location?.state?.rowData?.breadcrumbCategory,
        description: this.props?.location?.state?.rowData?.description,
        displayOrder: this.props?.location?.state?.rowData?.displayOrder,
        includeInTopMenu:
          this.props?.location?.state?.rowData?.includeInTopMenu === "Y"
            ? true
            : false,
        isActive:
          this.props?.location?.state?.rowData?.isActive === "Y" ? true : false,
        isReturnable:
          this.props?.location?.state?.rowData?.isReturnable === "Y"
            ? true
            : false,
        showOnHomepage:
          this.props?.location?.state?.rowData?.showOnHomepage === "Y"
            ? true
            : false,
        isProduct:
          this.props?.location?.state?.rowData?.isProduct === "Y"
            ? true
            : false,
        isDeleted: this.props?.location?.state?.rowData?.isDeleted,
        levelNo: this.props?.location?.state?.rowData?.levelNo,
        metaDescription: this.props?.location?.state?.rowData?.metaDescription,

        metaKeywords: this.props?.location?.state?.rowData?.metaKeywords,
        metaTitle: this.props?.location?.state?.rowData?.metaTitle,
        pageSize: this.props?.location?.state?.rowData?.pageSize,
        parentCategoryId:
          this.props?.location?.state?.rowData?.parentCategoryId,

        pictureId: this.props?.location?.state?.rowData?.pictureId,
        productImagePath:
          this.props?.location?.state?.rowData?.productImagePath,
        localCmsnFlatAmt:
          this.props?.location?.state?.rowData?.localCmsnFlatAmt,
        localCmsnPercentage:
          this.props?.location?.state?.rowData?.localCmsnPercentage,
        globalCmsnFlatAmt:
          this.props?.location?.state?.rowData?.globalCmsnFlatAmt,
        globalCmsnPercentage:
          this.props?.location?.state?.rowData?.globalCmsnPercentage,
        selectedParentCategoryId: this.props?.location?.state?.rowData?.breadcrumbCategory,
        isPopular:
          this.props?.location?.state?.rowData?.isPopular === "Y"
            ? true
            : false,
        isNewArrival:
          this.props?.location?.state?.rowData?.isNewArrival === "Y"
            ? true
            : false,
        isTopCategory:
          this.props?.location?.state?.rowData?.isTopCategory === "Y"
            ? true
            : false,
      });
    } else {
      this.setState({
        authenticated: false,
        loginSuccessful: false,
      });
      this.props.history.push("/Login");
    }
    //End Temporary Authentication
    await this.props.getActiveBreadcrumbsCategoryRecord();

    const html = this.state.description;
    const blocksFromHTML = convertFromHTML(html);
    const content = ContentState.createFromBlockArray(blocksFromHTML);
    this.setState({
      editorState: EditorState.createWithContent(content),
    });
  };

  categoryLogoUrlHandler = (event) => {
    const imageFile = event.target.files[0];
    let fileInput = false;

    const reader = new FileReader();
    reader.readAsDataURL(imageFile);
    reader.addEventListener("load", (event) => {
      const _loadedImageUrl = event.target.result;
      const image = document.createElement("img");
      image.src = _loadedImageUrl;

      image.addEventListener("load", () => {
        const { width, height } = image;
        if (width / height !== 1) {
          toast.error(
            <>
              <p style={{ fontSize: "18px", padding: "0", margin: "0" }}>
                Size Ratio must be 1:1
              </p>{" "}
              <span style={{ fontSize: "14px" }}>
                Image resolution must be (452x452)px
              </span>
            </>
          );
          return false;
        } else {
          if (imageFile) {
            fileInput = true;
            if (fileInput) {
              try {
                Resizer.imageFileResizer(
                  imageFile,
                  452,
                  452,
                  "JPEG",
                  100,
                  0,
                  (uri) => {
                    this.setState({
                      productImagePath: uri,
                      categoryLogoUrlFileName: imageFile.name,
                      showFile: URL.createObjectURL(imageFile),
                    });
                    toast.success("Image Selected.");
                  },
                  "base64",
                  452,
                  452
                );
              } catch (err) {
                toast.error("Something went wrong!");
              }
            }
          }
        }
      });
    });
  };

  handleParentCheck = (e) => {
    const { target } = e;
    switch (target.name) {
      case "showOnHomepage":
        this.setState({ showOnHomepage: !this.state?.showOnHomepage });
        break;
      case "includeInTopMenu":
        this.setState({ includeInTopMenu: !this.state?.includeInTopMenu });
        break;
      case "isActive":
        this.setState({ isActive: !this.state?.isActive });
        break;
      case "isProduct":
        this.setState({ isProduct: !this.state?.isProduct });
        break;
      case "isReturnable":
        this.setState({ isReturnable: !this.state?.isReturnable });
        break;
      case "isPopular":
        this.setState({ isPopular: !this.state.isPopular });
        break;
      case "isNewArrival":
        this.setState({ isNewArrival: !this.state.isNewArrival });
        break;
      case "isTopCategory":
        this.setState({ isTopCategory: !this.state.isTopCategory });
        break;
      default:
    }
  };

  keyPressed = (e) => {
    if (e.key === "Enter") {
      this.saveCategory(e);
    }
  };

  onBasicUpload(event) {
    this.growl.show({
      severity: "info",
      summary: "Success",
      detail: "File Uploaded with Basic Mode",
    });
  }

  onEditorStateChange = (editorState) => {
    let value = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    this.setState({
      editorState,
      description: value,
      //
      errorDescription:
        value.length < 12 ? "At least 4 characters required" : "",
    });
  };

  _uploadImageCallBack(file) {
    let uploadedImages = this.state?.uploadedImages;

    const imageObject = {
      file: file,
      localSrc: URL.createObjectURL(file),
    };

    uploadedImages.push(imageObject);

    this.setState({ uploadedImages: uploadedImages });
    return new Promise((resolve, reject) => {
      resolve({ data: { link: imageObject?.localSrc } });
    });
  }

  handleChange = (e) => {
    e.preventDefault();
    this.setState({ value: e.target.value });
    const { value } = e.target;
    const { target } = e;
    switch (target.name) {
      case "categoryId":
        this.setState({ categoryId: target.value });
        break;
      case "categoryName":
        this.setState({
          categoryName: target.value,
          errorCategoryName:
            value.length < 2 ? "At least 2 characters required required" : "",
        });
        break;
      case "description":
        this.setState({ description: target.value });
        break;

      case "localCmsnFlatAmt":
        this.setState({ localCmsnFlatAmt: target.value });
        break;

      case "localCmsnPercentage":
        this.setState({ localCmsnPercentage: target.value });
        break;

      case "globalCmsnFlatAmt":
        this.setState({ globalCmsnFlatAmt: target.value });
        break;

      case "globalCmsnPercentage":
        this.setState({ globalCmsnPercentage: target.value });
        break;

      case "metaTitle":
        this.setState({
          metaTitle: target.value,
          errorMetaTitle:
            value.length < 2 ? "At least 2 characters required required" : "",
        });
        break;
      case "metaDescription":
        this.setState({
          metaDescription: target.value,
          errorMetaDescription:
            value.length < 4 ? "At least 4 characters required required" : "",
        });
        break;
      case "displayOrder":
        this.setState({
          displayOrder: target.value < 0 ? 0 : target.value,
          errorDisplayOrder:
            value.length < 1 ? "Display order is required" : "",
        });
        break;
      case "metaKeywords":
        this.setState({
          metaKeywords: target.value,
          errorMetaKeywords:
            value.length < 2 ? "At least 2 characters required required" : "",
        });
        break;
      case "selectedParentCategoryId":


        this.setState({
          selectedParentCategoryId: target.value?.breadcrumbCategory,
          parentCategoryId: target.value?.categoryId,
        });
        break;
      case "showOnHomePage1":
        this.setState({
          showOnHomePage1: !this.state?.showOnHomePage1,
        });
        break;
      case "includeInTopMenu1":
        this.setState({
          includeInTopMenu1: !this.state?.includeInTopMenu1,
        });
        break;
      case "isActive1":
        this.setState({
          isActive1: !this.state?.isActive1,
        });
        break;
      case "isProduct1":
        this.setState({
          isProduct1: !this.state?.isProduct1,
        });
        break;
      default:
    }
  };

  saveCategory = async (e) => {
    e.preventDefault();
    if (this.state?.categoryName === "") {
      let msg = "Category Name is required!!!";
      toast.error(msg);
      setTimeout(() => { }, 3000);
      return;
    }

    if (this.state?.description === "") {
      let msg = "Category Details is required!!!";
      toast.error(msg);
      setTimeout(() => { }, 3000);
      return;
    }

    if (this.state?.metaTitle === "") {
      let msg = "Meta Title is required!!!";
      toast.error(msg);
      setTimeout(() => { }, 3000);
      return;
    }

    if (this.state?.displayOrder === "") {
      let msg = "Display Order is required!!!";
      toast.error(msg);
      setTimeout(() => { }, 3000);
      return;
    }

    if (this.state?.metaKeywords === "") {
      let msg = "Meta Keywords is required!!!";
      toast.error(msg);
      setTimeout(() => { }, 3000);
      return;
    }

    if (this.state?.metaDescription === "") {
      let msg = "Meta Description is required!!!";
      toast.error(msg);
      setTimeout(() => { }, 3000);
      return;
    }

    if (this.state?.productImagePath === "") {
      let msg = "Upload an Image!!!";
      toast.error(msg);
      setTimeout(() => { }, 3000);
      return;
    }

    const data = {
      categoryId: this.state?.categoryId,
      categoryName: this.state?.categoryName,
      localCmsnFlatAmt: this.state.localCmsnFlatAmt || 0,
      localCmsnPercentage: this.state.localCmsnPercentage || 0,
      globalCmsnFlatAmt: this.state.globalCmsnFlatAmt || 0,
      globalCmsnPercentage: this.state.globalCmsnPercentage || 0,
      metaKeywords: this.state?.metaKeywords,
      metaTitle: this.state?.metaTitle,
      displayOrder: this.state?.displayOrder,
      description: this.state?.description,
      metaDescription: this.state?.metaDescription,
      parentCategoryId: this.state?.parentCategoryId,
      productImagePath: this.state?.productImagePath,
      pageSize: this.state?.pageSize,
      showOnHomepage: this.state?.showOnHomepage === true ? "Y" : "N",
      includeInTopMenu: this.state?.includeInTopMenu === true ? "Y" : "N",
      isDeleted: this.state?.isDeleted,
      isActive: this.state?.isActive === true ? "Y" : "N",
      isReturnable: this.state?.isReturnable === true ? "Y" : "N",
      isProduct: this.state?.isProduct === true ? "Y" : "N",
      isPopular: this.state.isPopular === true ? "Y" : "N",
      isNewArrival: this.state.isNewArrival === true ? "Y" : "N",
      isTopCategory: this.state.isTopCategory === true ? "Y" : "N",
    };

    const result = await this.props?.createCategoryRecord(data);

    if (result.type === "CREATE_CATEGORY_SUCCESS") {
      toast.success("Category Updated Successfully");
      setTimeout(() => {
        this.props.history.push("CategoryList");
      }, 2500);
      this.resetForm();
    } else {
      toast.error("Something went wrong, Please try again");
      setTimeout(() => {
        this.resetForm();
      }, 2500);
    }
  };

  resetForm = () => {
    this.setState({
      categoryId: 0,
      categoryName: "",
      localCmsnPercentage: "",
      localCmsnFlatAmt: "",
      globalCmsnPercentage: "",
      globalCmsnFlatAmt: "",
      metaKeywords: "",
      metaTitle: "",
      displayOrder: "",
      description: "",
      metaDescription: "",
      parentCategoryId: 0,
      selectedParentCategoryId: "",
      productImagePath: "",
      imageType: "",
      file: "", // to store the Single pictures in base64 format.
      files: [],
      fileName: "",
      showOnHomepage: "Y",
      includeInTopMenu: "Y",
      isActive: "Y",
      isProduct: "Y",
      isReturnable: "N",
      editorState: EditorState.createEmpty(),
      uploadedImages: [],
      pageSize: 0,
      isDeleted: "Y",
      isError: {
        categoryName: "",
        description: "",
        metaTitle: "",
        displayOrder: "",
        metaKeywords: "",
        metaDescription: "",
      },
    });
  };

  render() {
    return (
      <div id="wrapper">
        <ToastContainer autoClose={1500} />
        <EditCategory
          key="EditCategory"
          name="Edit Category"
          {...this.state}
          handleChange={this.handleChange}
          values={this.values}
          resetForm={this.resetForm}
          categoryLogoUrlHandler={this.categoryLogoUrlHandler}
          saveCategory={this.saveCategory}
          onEditorStateChange={this.onEditorStateChange}
          _uploadImageCallBack={this._uploadImageCallBack}
          activeBreadcrumbsCategories={this.props?.activeBreadcrumbsCategories}
          handleParentCheck={this.handleParentCheck}
          value={this.props?.location?.state?.rowData}
          categorySaving={this.props.saving}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activeBreadcrumbsCategories:
    state.activeBreadcrumbsCategoryReducer?.activeBreadcrumbsCategories,
    saving: state.categoryReducer.saving,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getActiveBreadcrumbsCategoryRecord: () =>
      dispatch(
        activeBreadcrumbsCategoryAction.getActiveBreadcrumbsCategoryRecord()
      ),
    createCategoryRecord: (data) =>
      dispatch(categoryAction.createCategoryRecord(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(editCategoryContainer);
