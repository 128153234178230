import { useEffect, useState } from 'react';
import axios from 'axios';
import baseUrl from '../utils/baseUrl';

const useProductQuantity = () => {
  const [productQuantity, setProductQuantity] = useState([]);

  useEffect(() => {
    const fetchProductCount = async () => {
      const token = localStorage.getItem('x-access-token');

      try {
        
        const result = await axios.get(`${baseUrl}/api/Product/GetCountProductStatus`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (result.status === 200) {
          setProductQuantity(result.data);
        }
      } catch (error) {
        // Handle any errors here if needed
       
      }
    };

    fetchProductCount();
  }, []);

  return productQuantity;
};

export default useProductQuantity;
