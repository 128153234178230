/* eslint-disable react-hooks/exhaustive-deps */
import { Modal } from 'antd'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { Calendar } from "primereact/calendar";
import { DataTable } from 'primereact/datatable'
import { Dropdown } from "primereact/dropdown";
import { InputText } from 'primereact/inputtext'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import FetchCancelOrderTrackingSeller from '../../components/orders/FetchCancelOrderTrackingSeller'
import { fetchCancelOrderByIdRecord } from '../../store/actions/orderAction'
import authenticationService from '../../store/services/authenticationService'
import { Tag } from 'primereact/tag'
import LoadingCard from '../../components/shared/LoadingCard'
import CancelRefundModal from '../../components/refund/CancelRefundModal'
import moment from 'moment'
import { fetchRefundedDataRecord } from '../../store/actions/refundActions'
import RefundedOrdersModal from '../../components/orders/RefundedOrdersModal'
import { Paginator } from '../../components/paginator/Paginator'
import { getPaymentMethodRecord } from '../../store/actions/paymentMethodAction';
import { getAllSellerRecord } from '../../store/actions/sellerAction';
import { getBrandRecord } from '../../store/actions/brandAction';
import toIsoString from '../../utils/Functions/toIsoString';

export default function CancelSellerOrdersListContainer() {
  const [globalFilter, setGlobalFilter] = useState(null)
  const [visible, setVisible] = useState(false)
  const [refundVisible, setRefundVisible] = useState(false)
  const [refundedVisible, setRefundedVisible] = useState(false)
  const [orderInfo, setOrderInfo] = useState(null)
  const [counts, setCounts] = useState(1)
  const [selectedOrders, setSelectedOrders] = useState(null)
  const [pathnameOptions, setPathnameOptions] = useState(null)
  const [totalRefundAmount, setTotalRefundAmount] = useState(null)
  const [statusId, setStatusId] = useState(null)
  const [statusName, setStatusName] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [itemPerPage, setItemPerPage] = useState(30)
  const [totalPage, setTotalPage] = useState(null)
  const [totalItems, setTotalItems] = useState(null)


  const [searchKeyword, setSearchKeyword] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [brand, setBrand] = useState(null);
  const [selectVendor, setSelectVendor] = useState(null);
  const [selectPaymentStatus, setSelectPaymentStatus] = useState(null);
  const [selectPaymentMethod, setSelectPaymentMethod] = useState(null);
  const [filterOrder, setFilterOrder] = useState(false);

  const dispatch = useDispatch();
  let history = useHistory();

  // const vendorList = useSelector((state) => state.sellerReducer.sellerList);
  const brands = useSelector((state) => state.brandReducer.brands);
  const paymentMethods = useSelector((state) => state.paymentMethodReducer.paymentMethods);

  useEffect(() => {
    let roleId = authenticationService.getRoleId()
    if (roleId === '2') {
    } else {
      history.push('/SellerLogin')
    }
  }, [])

  useEffect(() => {
    setStatusId(null)
    setStatusName(null)
  }, [visible])

  const location = useHistory()
  const order_filter_tab = location?.location?.search?.substring(8)
  const status_for_api_call = order_filter_tab.replaceAll('_', ' ')
  const status_arr = status_for_api_call.split(' ')
  for (var i = 0; i < status_arr.length; i++) {
    status_arr[i] =
      status_arr[i].charAt(0).toUpperCase() + status_arr[i].slice(1)
  }
  const reformed_status = status_arr.join(' ')
  const cancel_orders = useSelector(
    (state) => state.orderReducer.fetchCancelOrderById
  )

  useEffect(() => {
    if (cancel_orders?.headers?.pagination) {
      var paginated_data_to_parse = cancel_orders.headers.pagination
      const paginated_data = JSON.parse(paginated_data_to_parse)
      setCurrentPage(paginated_data.currentPage)
      setTotalPage(paginated_data.totalPages)
      setTotalItems(paginated_data.totalItems)
      setItemPerPage(paginated_data.itemsPerPage)
    }
  }, [cancel_orders?.headers?.pagination])

  const sellerProfile = useSelector(
    (state) => state.sellerProfileReducer.shopDetails
  )
  const loading = useSelector((state) => state.orderReducer.loading)
  const handleItemPerPage = (pagePerItems) => {
    setCurrentPage(1)
    setItemPerPage(pagePerItems)
  }
  const handleCurrentPage = (currentPage) => {
    setCurrentPage(currentPage)
  }

  // useEffect(() => {
  //   if (sellerProfile[0]?.shopId) {
  //     dispatch(
  //       fetchCancelOrderByIdRecord(
  //         status_for_api_call,
  //         sellerProfile[0]?.shopId,
  //         currentPage,
  //         itemPerPage
  //       )
  //     )
  //   }
  // }, [
  //   status_for_api_call,
  //   counts,
  //   sellerProfile[0]?.shopId,
  //   currentPage,
  //   itemPerPage,
  // ])

  useEffect(() => {
    const sellerOrders = {
      status: status_for_api_call,
      invoiceNumber: '',
      searchKeyword: '',
      brand:brand?.brandId,
      paymentMethodName:selectPaymentMethod?.methodName,
      currentPage,
      itemsPerPage: itemPerPage
    }
    if (startDate) {
      sellerOrders.startDate = toIsoString(startDate).substring(0, 10)
    } if (endDate) {
      sellerOrders.endDate = toIsoString(endDate).substring(0, 10)
    }

    if (filterOrder) {
      dispatch(fetchCancelOrderByIdRecord(sellerOrders));
    }


    return () => {
      setFilterOrder(false);
    };
  }, [
    dispatch,
    filterOrder,
    // counts,
    // order_filter_tab,
    currentPage,
    itemPerPage,
  ]);

  useEffect(() => {
    const sellerCancelOrders = {
      status: status_for_api_call,
      currentPage,
      itemsPerPage: itemPerPage
    }

    dispatch(fetchCancelOrderByIdRecord(sellerCancelOrders));
  }, [dispatch, status_for_api_call, currentPage, itemPerPage])

  useEffect(() => {
    dispatch(getPaymentMethodRecord());
    // dispatch(getAllSellerRecord());
    dispatch(getBrandRecord());
  }, [dispatch]);

  const invoiceType = 2

  const cancelInvoiceNoBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span>{rowData.cancelInvoiceNo}</span>
      </React.Fragment>
    )
  }
  const orderNoBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span>{rowData.orderInvoiceNo}</span>
      </React.Fragment>
    )
  }
  const cancelOrderDateTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span>{moment(rowData.cancelDate).format('Do MMMM YYYY, h:mm A')}</span>
      </React.Fragment>
    )
  }
  const refundTateBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span>{moment(rowData.refundDate).format('Do MMMM YYYY, h:mm A')}</span>
      </React.Fragment>
    )
  }
  const shopNameBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span>{rowData.shopName}</span>
      </React.Fragment>
    )
  }
  const paymentMethodTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span>
          {rowData.paymentMethodName
            ? rowData.paymentMethodName === 'COD'
              ? 'Cash On Delivery'
              : rowData.paymentMethodName
            : 'Not Selected Yet'}
        </span>
      </React.Fragment>
    )
  }
  const productPriceBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span>{rowData.totalAmount}</span>
      </React.Fragment>
    )
  }
  const paymentStatusBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {rowData.paymentStatus === 'Pending' ? (
          <Tag severity='danger' value={rowData.paymentStatus} rounded></Tag>
        ) : (
          <Tag severity='success' value={rowData.paymentStatus} rounded></Tag>
        )}
      </React.Fragment>
    )
  }
  const productDetailsTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span>
          <Button
            onClick={() => {
              setOrderInfo(rowData)
              setVisible(true)
            }}
            style={{
              fontSize: '14px',
              padding: '4px 10px',
              marginRight: '5px',
            }}
          >
            Details
          </Button>
        </span>
      </React.Fragment>
    )
  }
  const refundDetailsBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span>
          <Button
            onClick={() => {
              setOrderInfo(rowData)
              setRefundedVisible(true)
            }}
            style={{
              fontSize: '14px',
              padding: '4px 10px',
              marginRight: '5px',
            }}
          >
            Details
          </Button>
        </span>
      </React.Fragment>
    )
  }
  const refundTotalBodyTateBodyTemplate = (rowData) => {
    const total_amount = rowData.productDetails.reduce(
      (total, currentValue) => (total = total + currentValue.amount),
      0
    )
    setTotalRefundAmount(total_amount)
    return (
      <React.Fragment>
        <span>{total_amount}</span>
      </React.Fragment>
    )
  }

  useEffect(() => {
    if (location.location.pathname === '/CancelSellerOrders')
      setPathnameOptions('cancel')
  }, [location.location.pathname])
  useEffect(() => {
    if (pathnameOptions !== null && order_filter_tab === 'refund') {
      dispatch(
        fetchRefundedDataRecord(pathnameOptions, currentPage, itemPerPage)
      )
    }
  }, [pathnameOptions, order_filter_tab, currentPage, itemPerPage])
  const refunded_data = useSelector(
    (state) => state.refundReducer.get_refunded_data
  )
  useEffect(() => {
    if (refunded_data?.headers?.pagination) {
      var paginated_data_to_parse = refunded_data.headers.pagination
      const paginated_data = JSON.parse(paginated_data_to_parse)
      setCurrentPage(paginated_data.currentPage)
      setTotalPage(paginated_data.totalPages)
      setTotalItems(paginated_data.totalItems)
      setItemPerPage(paginated_data.itemsPerPage)
    }
  }, [refunded_data?.headers?.pagination])

  const refunded_loading = useSelector((state) => state.refundReducer.loading)

  return (
    <div className='page-wrapper'>
      <div className='container-fluid'>
        <div className='col-md-12'>
          <div className='white-box'>
            <div className='datatable-doc-demo datatable-responsive-demo'>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
                className='card'
              >
                <ul class='nav nav-tabs seller-tabs'>
                  <li>
                    <Link
                      to={`/CancelSellerOrders?status=cancel_request`}
                      style={
                        order_filter_tab === 'cancel_request'
                          ? { color: "#1F5DA0", borderBottom: '2px solid #1F5DA0', fontWeight: "700" }
                          : null
                      }
                    >
                      Cancel Request
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/CancelSellerOrders?status=refund_processing`}
                      style={
                        order_filter_tab === 'refund_processing'
                          ? { color: "#1F5DA0", borderBottom: '2px solid #1F5DA0', fontWeight: "700" }
                          : null
                      }
                    >
                      Refund Processing
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/CancelSellerOrders?status=refund`}
                      style={
                        order_filter_tab === 'refund'
                          ? { color: "#1F5DA0", borderBottom: '2px solid #1F5DA0', fontWeight: "700" }
                          : null
                      }
                    >
                      Refund
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/CancelSellerOrders?status=canceled`}
                      style={
                        order_filter_tab === 'canceled'
                          ? { color: "#1F5DA0", borderBottom: '2px solid #1F5DA0', fontWeight: "700" }
                          : null
                      }
                    >
                      Canceled
                    </Link>
                  </li>
                </ul>
                {/* <InputText
                  style={{ width: '25%' }}
                  className='form-control text-center'
                  type='search'
                  value={globalFilter}
                  onChange={(e) => setGlobalFilter(e.target.value)}
                  placeholder='Search'
                /> */}
              </div>

              <div
                style={{
                  display: 'flex',
                  gap: '10px',
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: '15px',
                }}
              >
                <p
                  style={{
                    display: 'flex',
                    fontWeight: '600',
                    fontSize: '16px',
                    margin: '0',
                    // background: 'red'
                  }}
                >
                  <Link
                    to={{
                      pathname: `/SellerInvoice`,
                      selectedOrders,
                      invoiceType,
                      order_filter_tab,
                    }}
                  >
                    <button
                      className='btn btn-info'
                      style={{
                        padding: '4px 20px',
                        fontSize: '13px ',
                        marginRight: '5px',
                      }}
                      disabled={
                        selectedOrders === null || selectedOrders?.length === 0
                      }
                    >
                      Print
                    </button>
                  </Link>
                </p>

                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                  className="order_filter__section"
                >
                  <div
                    style={{
                      margin: "0 2px",
                      width: "250px",
                      position: "relative",
                    }}
                  >
                    <InputText
                      style={{
                        width: "100%",
                      }}
                      className="manage_product__search"
                      value={searchKeyword}
                      onChange={(e) => setSearchKeyword(e.target.value)}
                      placeholder="Keyword"
                    />
                    {searchKeyword !== "" ? (
                      <button
                        style={{
                          position: "absolute",
                          top: "6px",
                          right: "8px",
                          borderRadius: "100%",
                          border: "1px solid #ced4da",
                          height: "25px",
                          width: "25px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={() => {
                          setSearchKeyword("");
                        }}
                      >
                        <i className="pi pi-times"></i>
                      </button>
                    ) : null}
                  </div>
                  <div
                    style={{
                      margin: "0 2px",
                      width: "155px",
                      position: "relative",
                    }}
                  >
                    <Calendar
                      id="icon"
                      maxDate={endDate}
                      value={startDate}
                      onChange={(e) => setStartDate(e.value)}
                      showIcon
                      placeholder="Start Date"
                      readOnlyInput
                    />
                    {startDate !== null ? (
                      <button
                        style={{
                          position: "absolute",
                          top: "6px",
                          right: "33px",
                          borderRadius: "100%",
                          border: "1px solid #ced4da",
                          height: "25px",
                          width: "25px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={() => {
                          setStartDate(null);
                        }}
                      >
                        <i className="pi pi-times"></i>
                      </button>
                    ) : null}
                  </div>
                  <div
                    style={{
                      margin: "0 2px",
                      width: "155px",
                      position: "relative",
                    }}
                  >
                    <Calendar
                      id="icon disableddays"
                      minDate={startDate}
                      value={endDate}
                      onChange={(e) => setEndDate(e.value)}
                      showIcon
                      placeholder="End Date"
                      readOnlyInput
                    />
                    {endDate !== null ? (
                      <button
                        style={{
                          position: "absolute",
                          top: "6px",
                          right: "33px",
                          borderRadius: "100%",
                          border: "1px solid #ced4da",
                          height: "25px",
                          width: "25px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={() => {
                          setEndDate(null);
                        }}
                      >
                        <i className="pi pi-times"></i>
                      </button>
                    ) : null}
                  </div>
                  <div
                    style={{ margin: "0 5px", width: "175px", position: "relative" }}
                  >
                    <Dropdown
                      style={{
                        width: "100%",
                      }}
                      value={brand}
                      options={brands}
                      filter
                      showClear
                      onChange={(e) => setBrand(e.value)}
                      optionLabel="brandName"
                      placeholder="Select a Brand"
                    />
                    {brand !== null ? (
                      <button
                        style={{
                          position: "absolute",
                          top: "6px",
                          right: "8px",
                          borderRadius: "100%",
                          border: "1px solid #ced4da",
                          height: "25px",
                          width: "25px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={() => {
                          setBrand(null);
                        }}
                      >
                        <i className="pi pi-times"></i>
                      </button>
                    ) : null}
                  </div>

                  {/* <div
                    style={{
                      margin: "0 2px",
                      width: "155px",
                      position: "relative",
                    }}
                  >
                    <Dropdown
                      style={{
                        width: "100%",
                      }}
                      value={selectVendor}
                      options={vendorList.data}
                      onChange={(e) => setSelectVendor(e.value)}
                      optionLabel="shopName"
                      placeholder="Select a Seller"
                      filter
                      showClear
                    />
                    {selectVendor !== null ? (
                      <button
                        style={{
                          position: "absolute",
                          top: "6px",
                          right: "8px",
                          borderRadius: "100%",
                          border: "1px solid #ced4da",
                          height: "25px",
                          width: "25px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={() => {
                          setSelectVendor(null);
                        }}
                      >
                        <i className="pi pi-times"></i>
                      </button>
                    ) : null}
                  </div> */}
                  {/* <div
                    style={{
                      margin: "0 2px",
                      width: "160px",
                      position: "relative",
                    }}
                  >
                    <Dropdown
                      style={{
                        width: "100%",
                      }}
                      value={selectPaymentStatus}
                      options={paymentStatusArr}
                      onChange={(e) => setSelectPaymentStatus(e.value)}
                      optionLabel="paymentStatus"
                      placeholder="Payment Status"
                    />
                    {selectPaymentStatus !== null ? (
                      <button
                        style={{
                          position: "absolute",
                          top: "6px",
                          right: "8px",
                          borderRadius: "100%",
                          border: "1px solid #ced4da",
                          height: "25px",
                          width: "25px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={() => {
                          setSelectPaymentStatus(null);
                        }}
                      >
                        <i className="pi pi-times"></i>
                      </button>
                    ) : null}
                  </div> */}
                  <div
                    style={{
                      margin: "0 2px",
                      width: "160px",
                      position: "relative",
                    }}
                  >
                    <Dropdown
                      style={{
                        width: "100%",
                      }}
                      value={selectPaymentMethod}
                      options={paymentMethods}
                      onChange={(e) => setSelectPaymentMethod(e.value)}
                      optionLabel="methodName"
                      placeholder="Payment Method"
                      filter
                      showClear
                    />
                    {selectPaymentMethod !== null ? (
                      <button
                        style={{
                          position: "absolute",
                          top: "6px",
                          right: "8px",
                          borderRadius: "100%",
                          border: "1px solid #ced4da",
                          height: "25px",
                          width: "25px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={() => {
                          setSelectPaymentMethod(null);
                        }}
                      >
                        <i className="pi pi-times"></i>
                      </button>
                    ) : null}
                  </div>
                  <div style={{ position: "relative" }}>
                    <Button
                      onClick={() => {
                        setFilterOrder(true);
                        setCurrentPage(1);
                      }}
                      style={{ marginLeft: "3px" }}
                      label="Search"
                      disabled={startDate?.getTime() > endDate?.getTime()}
                    />
                    {startDate?.getTime() > endDate?.getTime() ? (
                      <>
                        <div
                          style={{
                            position: "absolute",
                            width: "230px",
                            background: "#A596F1",
                            color: "#fff",
                            padding: "4px",
                            left: "-165px",
                            top: "-29px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Start Date Should Be Older Than End Date
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            height: "10px",
                            width: "10px",
                            background: "#A596F1",
                            transform: "rotate(45deg)",
                            top: "-13px",
                            right: "27px",
                          }}
                        ></div>
                      </>
                    ) : null}
                  </div>
                </div>



                {/* <div style={{ width: '20%' }}>
                  <InputText
                    className='form-control text-center'
                    type='search'
                    value={globalFilter}
                    onChange={(e) => setGlobalFilter(e.target.value)}
                    placeholder='Search'
                  />
                </div> */}
              </div>

              {order_filter_tab === 'refund' ? (
                <div className='card'>
                  {refunded_loading ? (
                    <LoadingCard count={1} />
                  ) : (
                    <>
                      <DataTable
                        className='p-datatable-customers p-datatable-responsive-demo'
                        value={refunded_data.data}
                        rowHover
                        globalFilter={globalFilter}
                        emptyMessage='No order(s) found'
                        selection={selectedOrders}
                        onSelectionChange={(e) => setSelectedOrders(e.value)}
                      >
                        <Column selectionMode='multiple' />
                        <Column
                          field='refundTranNo'
                          filterField='refundTranNo'
                          header='Transaction No'
                          sortable
                        />
                        <Column
                          field='refundDate'
                          filterField='refundDate'
                          header='Transaction Date'
                          body={refundTateBodyTemplate}
                          sortable
                        />
                        <Column
                          field='orderInvoiceNo'
                          filterField='orderInvoiceNo'
                          header='Invoice No'
                          sortable
                        />
                        <Column
                          field='customerName'
                          filterField='customerName'
                          header='Customer'
                          sortable
                        />
                        <Column
                          field='methodName'
                          filterField='methodName'
                          header='Payment Method'
                          body={paymentMethodTemplate}
                          sortable
                        />
                        <Column
                          field='accountNo'
                          filterField='accountNo'
                          header='Account No.'
                          sortable
                        />
                        <Column
                          header='Amount'
                          body={refundTotalBodyTateBodyTemplate}
                        />
                        <Column
                          header='Action'
                          body={refundDetailsBodyTemplate}
                        />
                      </DataTable>
                      <Paginator
                        totalPage={totalPage}
                        currentPage={currentPage}
                        itemPerPage={itemPerPage}
                        totalItems={totalItems}
                        items={refunded_data.data}
                        itemsPerPageOptions={[30, 60, 90, 100, 200, 300]}
                        handleItemPerPage={handleItemPerPage}
                        handleCurrentPage={handleCurrentPage}
                      />
                    </>
                  )}
                </div>
              ) : (
                <div className='card'>
                  {loading ? (
                    <LoadingCard count={1} />
                  ) : (
                    <>
                      <DataTable
                        className='p-datatable-customers p-datatable-responsive-demo'
                        value={cancel_orders?.data}
                        rowHover
                        globalFilter={globalFilter}
                        emptyMessage='No order(s) found'
                        selection={selectedOrders}
                        onSelectionChange={(e) => setSelectedOrders(e.value)}
                      >
                        <Column selectionMode='multiple' />
                        <Column
                          field='cancelInvoiceNo'
                          filterField='cancelInvoiceNo'
                          header='Cancel Invoice No'
                          body={cancelInvoiceNoBodyTemplate}
                          sortable
                        />
                        <Column
                          field='cancelDate'
                          filterField='cancelDate'
                          header='Cancel Date'
                          body={cancelOrderDateTemplate}
                          sortable
                        />
                        <Column
                          field='orderInvoiceNo'
                          filterField='orderInvoiceNo'
                          header='Invoice No'
                          body={orderNoBodyTemplate}
                          sortable
                        />
                        <Column
                          field='shopName'
                          filterField='shopName'
                          header='Shop Name'
                          body={shopNameBodyTemplate}
                          sortable
                        />
                        <Column
                          field='paymentMethodName'
                          filterField='paymentMethodName'
                          header='Payment Method'
                          body={paymentMethodTemplate}
                          sortable
                        />
                        <Column
                          field='paymentStatus'
                          filterField='paymentStatus'
                          header='Payment Status'
                          body={paymentStatusBodyTemplate}
                          sortable
                        />
                        <Column
                          field='totalAmount'
                          filterField='totalAmount'
                          header='Amount'
                          body={productPriceBodyTemplate}
                          sortable
                        />
                        <Column header='Action' body={productDetailsTemplate} />
                      </DataTable>
                      <Paginator
                        totalPage={totalPage}
                        currentPage={currentPage}
                        itemPerPage={itemPerPage}
                        totalItems={totalItems}
                        items={cancel_orders?.data}
                        itemsPerPageOptions={[30, 60, 90, 100, 200, 300]}
                        handleItemPerPage={handleItemPerPage}
                        handleCurrentPage={handleCurrentPage}
                      />
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        title='Cancel Order Details'
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width={1500}
      >
        <FetchCancelOrderTrackingSeller
          orderInfo={orderInfo}
          setVisible={setVisible}
          counts={counts}
          setCounts={setCounts}
          order_filter_tab={order_filter_tab}
          page={history.location.pathname}
          setStatusId={setStatusId}
          setStatusName={setStatusName}
          statusId={statusId}
          statusName={statusName}
        />
      </Modal>
      {/* <Modal
        title="Refund Cancel Orders"
        centered
        visible={refundVisible}
        onOk={() => setRefundVisible(false)}
        onCancel={() => setRefundVisible(false)}
        width={1500}
      >
        <CancelRefundModal
          orderInfo={orderInfo}
          counts={counts}
          setCounts={setCounts}
          setRefundVisible={setRefundVisible}
        />
      </Modal> */}
      <Modal
        title='Refunded Cancel Orders'
        centered
        visible={refundedVisible}
        onOk={() => setRefundedVisible(false)}
        onCancel={() => setRefundedVisible(false)}
        width={1500}
      >
        <RefundedOrdersModal
          orderInfo={orderInfo}
          totalRefundAmount={totalRefundAmount}
        />
      </Modal>
    </div>
  )
}
