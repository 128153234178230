import {
  GET_ADMIN_ORDER_SUMMARY_DASHBOARD_DATA,
  GET_ADMIN_ORDER_SUMMARY_DASHBOARD_DATA_ERROR,
  GET_ADMIN_ORDER_SUMMARY_DASHBOARD_DATA_SUCCESS,
  GET_ADMIN_AMOUNT_SUMMARY_DASHBOARD_DATA,
  GET_ADMIN_AMOUNT_SUMMARY_DASHBOARD_DATA_ERROR,
  GET_ADMIN_AMOUNT_SUMMARY_DASHBOARD_DATA_SUCCESS,
  GET_ADMIN_PRODUCTS_SUMMARY_DASHBOARD_DATA,
  GET_ADMIN_PRODUCTS_SUMMARY_DASHBOARD_DATA_ERROR,
  GET_ADMIN_PRODUCTS_SUMMARY_DASHBOARD_DATA_SUCCESS,
  GET_ADMIN_VENDOR_SUMMARY_DASHBOARD_DATA,
  GET_ADMIN_VENDOR_SUMMARY_DASHBOARD_DATA_ERROR,
  GET_ADMIN_VENDOR_SUMMARY_DASHBOARD_DATA_SUCCESS,
  GET_ADMIN_CANCEL_DASHBOARD_DATA,
  GET_ADMIN_CANCEL_DASHBOARD_DATA_ERROR,
  GET_ADMIN_CANCEL_DASHBOARD_DATA_SUCCESS,
  GET_ADMIN_RETURN_DASHBOARD_DATA,
  GET_ADMIN_RETURN_DASHBOARD_DATA_ERROR,
  GET_ADMIN_RETURN_DASHBOARD_DATA_SUCCESS,
  GET_ADMIN_REFUND_DASHBOARD_DATA,
  GET_ADMIN_REFUND_DASHBOARD_DATA_ERROR,
  GET_ADMIN_REFUND_DASHBOARD_DATA_SUCCESS,
  GET_ALL_SUMMARIES_DASHBOARD_DATA,
  GET_ALL_SUMMARIES_DASHBOARD_DATA_ERROR,
  GET_ALL_SUMMARIES_DASHBOARD_DATA_SUCCESS
} from "../actions/adminDashboardAction";

const initialState = {
  adminOrderSummaryData: [], 
  adminAmountSummaryData: [], 
  adminVendorSummaryData: [], 
  adminProductSummaryData: [], 
  adminCancelData: [], 
  adminReturnData: [], 
  adminRefundData: [], 
  adminOrderSummaryLoading: false,
  adminOrderSummaryLoaded: false,
  adminOrderSummaryError: null,
  adminAmountSummaryLoading: false,
  adminAmountSummaryLoaded: false,
  adminAmountSummaryError: null,
  adminVendorSummaryLoading: false,
  adminVendorSummaryLoaded: false,
  adminVendorSummaryError: null,
  adminProductSummaryLoading: false,
  adminProductSummaryLoaded: false,
  adminProductSummaryError: null,
  adminCancelLoading: false,
  adminCancelLoaded: false,
  adminCancelError: null,
  adminReturnLoading: false,
  adminReturnLoaded: false,
  adminReturnError: null,
  adminRefundLoading: false,
  adminRefundLoaded: false,
  adminRefundError: null,

  allSummariesData: [], 
  allSummariesLoading: false,
  allSummariesLoaded: false,
  allSummariesError: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ADMIN_ORDER_SUMMARY_DASHBOARD_DATA:
      return {
        ...state,
        adminOrderSummaryLoading: true,
        adminOrderSummaryLoaded: false,
        error: null,
      };

    case GET_ADMIN_ORDER_SUMMARY_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        adminOrderSummaryLoading: false,
        adminOrderSummaryLoaded: true,
        adminOrderSummaryData: action.payload.success,
      };

    case GET_ADMIN_ORDER_SUMMARY_DASHBOARD_DATA_ERROR:
      return {
        ...state,
        adminOrderSummaryLoading: false,
        adminOrderSummaryLoaded: false,
        adminOrderSummaryError: action.payload.error,
        adminOrderSummaryData: [],
      };


    case GET_ADMIN_AMOUNT_SUMMARY_DASHBOARD_DATA:
      return {
        ...state,
        adminAmountSummaryLoading: true,
        adminAmountSummaryLoaded: false,
        error: null,
      };

    case GET_ADMIN_AMOUNT_SUMMARY_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        adminAmountSummaryLoading: false,
        adminAmountSummaryLoaded: true,
        adminAmountSummaryData: action.payload.success,
      };

    case GET_ADMIN_AMOUNT_SUMMARY_DASHBOARD_DATA_ERROR:
      return {
        ...state,
        adminAmountSummaryLoading: false,
        adminAmountSummaryLoaded: false,
        adminAmountSummaryError: action.payload.error,
        adminAmountSummaryData: [],
      };


    case GET_ADMIN_VENDOR_SUMMARY_DASHBOARD_DATA:
      return {
        ...state,
        adminVendorSummaryLoading: true,
        adminVendorSummaryLoaded: false,
        error: null,
      };

    case GET_ADMIN_VENDOR_SUMMARY_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        adminVendorSummaryLoading: false,
        adminVendorSummaryLoaded: true,
        adminVendorSummaryData: action.payload.success,
      };

    case GET_ADMIN_VENDOR_SUMMARY_DASHBOARD_DATA_ERROR:
      return {
        ...state,
        adminVendorSummaryLoading: false,
        adminVendorSummaryLoaded: false,
        adminVendorSummaryError: action.payload.error,
        adminVendorSummaryData: [],
      };


    case GET_ADMIN_PRODUCTS_SUMMARY_DASHBOARD_DATA:
      return {
        ...state,
        adminProductSummaryLoading: true,
        adminProductSummaryLoaded: false,
        error: null,
      };

    case GET_ADMIN_PRODUCTS_SUMMARY_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        adminProductSummaryLoading: false,
        adminProductSummaryLoaded: true,
        adminProductSummaryData: action.payload.success,
      };

    case GET_ADMIN_PRODUCTS_SUMMARY_DASHBOARD_DATA_ERROR:
      return {
        ...state,
        adminProductSummaryLoading: false,
        adminProductSummaryLoaded: false,
        adminProductSummaryError: action.payload.error,
        adminProductSummaryData: [],
      };


    case GET_ADMIN_CANCEL_DASHBOARD_DATA:
      return {
        ...state,
        adminCancelLoading: true,
        adminCancelLoaded: false,
        error: null,
      };

    case GET_ADMIN_CANCEL_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        adminCancelLoading: false,
        adminCancelLoaded: true,
        adminCancelData: action.payload.success,
      };

    case GET_ADMIN_CANCEL_DASHBOARD_DATA_ERROR:
      return {
        ...state,
        adminCancelLoading: false,
        adminCancelLoaded: false,
        adminCancelError: action.payload.error,
        adminCancelData: [],
      };


    case GET_ADMIN_RETURN_DASHBOARD_DATA:
      return {
        ...state,
        adminReturnLoading: true,
        adminReturnLoaded: false,
        error: null,
      };

    case GET_ADMIN_RETURN_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        adminReturnLoading: false,
        adminReturnLoaded: true,
        adminReturnData: action.payload.success,
      };

    case GET_ADMIN_RETURN_DASHBOARD_DATA_ERROR:
      return {
        ...state,
        adminReturnLoading: false,
        adminReturnLoaded: false,
        adminReturnError: action.payload.error,
        adminReturnData: [],
      };


    case GET_ADMIN_REFUND_DASHBOARD_DATA:
      return {
        ...state,
        adminRefundLoading: true,
        adminRefundLoaded: false,
        error: null,
      };

    case GET_ADMIN_REFUND_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        adminRefundLoading: false,
        adminRefundLoaded: true,
        adminRefundData: action.payload.success,
      };

    case GET_ADMIN_REFUND_DASHBOARD_DATA_ERROR:
      return {
        ...state,
        adminRefundLoading: false,
        adminRefundLoaded: false,
        adminRefundError: action.payload.error,
        adminRefundData: [],
      };
    

    case GET_ALL_SUMMARIES_DASHBOARD_DATA:
      return {
        ...state,
        allSummariesLoading: true,
        allSummariesLoaded: false,
        error: null,
      };

    case GET_ALL_SUMMARIES_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        allSummariesLoading: false,
        allSummariesLoaded: true,
        allSummariesData: action.payload.success,
      };

    case GET_ALL_SUMMARIES_DASHBOARD_DATA_ERROR:
      return {
        ...state,
        allSummariesLoading: false,
        allSummariesLoaded: false,
        allSummariesError: action.payload.error,
        allSummariesData: [],
      };
    
      default:
      return state;
  }
}
