import adminDashboardService from "../services/adminDashboardService";

export const GET_ADMIN_ORDER_SUMMARY_DASHBOARD_DATA =
  "GET_ADMIN_ORDER_SUMMARY_DASHBOARD_DATA";
export const GET_ADMIN_ORDER_SUMMARY_DASHBOARD_DATA_SUCCESS =
  "GET_ADMIN_ORDER_SUMMARY_DASHBOARD_DATA_SUCCESS";
export const GET_ADMIN_ORDER_SUMMARY_DASHBOARD_DATA_ERROR =
  "GET_ADMIN_ORDER_SUMMARY_DASHBOARD_DATA_ERROR";

export const GET_ADMIN_AMOUNT_SUMMARY_DASHBOARD_DATA =
  "GET_ADMIN_AMOUNT_SUMMARY_DASHBOARD_DATA";
export const GET_ADMIN_AMOUNT_SUMMARY_DASHBOARD_DATA_SUCCESS =
  "GET_ADMIN_AMOUNT_SUMMARY_DASHBOARD_DATA_SUCCESS";
export const GET_ADMIN_AMOUNT_SUMMARY_DASHBOARD_DATA_ERROR =
  "GET_ADMIN_AMOUNT_SUMMARY_DASHBOARD_DATA_ERROR";

export const GET_ADMIN_VENDOR_SUMMARY_DASHBOARD_DATA =
  "GET_ADMIN_VENDOR_SUMMARY_DASHBOARD_DATA";
export const GET_ADMIN_VENDOR_SUMMARY_DASHBOARD_DATA_SUCCESS =
  "GET_ADMIN_VENDOR_SUMMARY_DASHBOARD_DATA_SUCCESS";
export const GET_ADMIN_VENDOR_SUMMARY_DASHBOARD_DATA_ERROR =
  "GET_ADMIN_VENDOR_SUMMARY_DASHBOARD_DATA_ERROR";

export const GET_ADMIN_PRODUCTS_SUMMARY_DASHBOARD_DATA =
  "GET_ADMIN_PRODUCTS_SUMMARY_DASHBOARD_DATA";
export const GET_ADMIN_PRODUCTS_SUMMARY_DASHBOARD_DATA_SUCCESS =
  "GET_ADMIN_PRODUCTS_SUMMARY_DASHBOARD_DATA_SUCCESS";
export const GET_ADMIN_PRODUCTS_SUMMARY_DASHBOARD_DATA_ERROR =
  "GET_ADMIN_PRODUCTS_SUMMARY_DASHBOARD_DATA_ERROR";

export const GET_ADMIN_CANCEL_DASHBOARD_DATA =
  "GET_ADMIN_CANCEL_DASHBOARD_DATA";
export const GET_ADMIN_CANCEL_DASHBOARD_DATA_SUCCESS =
  "GET_ADMIN_CANCEL_DASHBOARD_DATA_SUCCESS";
export const GET_ADMIN_CANCEL_DASHBOARD_DATA_ERROR =
  "GET_ADMIN_CANCEL_DASHBOARD_DATA_ERROR";

export const GET_ADMIN_RETURN_DASHBOARD_DATA =
  "GET_ADMIN_RETURN_DASHBOARD_DATA";
export const GET_ADMIN_RETURN_DASHBOARD_DATA_SUCCESS =
  "GET_ADMIN_RETURN_DASHBOARD_DATA_SUCCESS";
export const GET_ADMIN_RETURN_DASHBOARD_DATA_ERROR =
  "GET_ADMIN_RETURN_DASHBOARD_DATA_ERROR";

export const GET_ADMIN_REFUND_DASHBOARD_DATA =
  "GET_ADMIN_REFUND_DASHBOARD_DATA";
export const GET_ADMIN_REFUND_DASHBOARD_DATA_SUCCESS =
  "GET_ADMIN_REFUND_DASHBOARD_DATA_SUCCESS";
export const GET_ADMIN_REFUND_DASHBOARD_DATA_ERROR =
  "GET_ADMIN_REFUND_DASHBOARD_DATA_ERROR";

  
export const GET_ALL_SUMMARIES_DASHBOARD_DATA =
"GET_ALL_SUMMARIES_DASHBOARD_DATA";
export const GET_ALL_SUMMARIES_DASHBOARD_DATA_SUCCESS =
"GET_ALL_SUMMARIES_DASHBOARD_DATA_SUCCESS";
export const GET_ALL_SUMMARIES_DASHBOARD_DATA_ERROR =
"GET_ALL_SUMMARIES_DASHBOARD_DATA_ERROR";

// Admin Order Summary Dashboard Data start
export function getAdminOrderSummaryDashboardDataRecord(
  startDate,
  endDate,
  shopNameIds
) {
  return async (dispatch) => {
    dispatch(getAdminOrderSummaryDashboardData());
    return await adminDashboardService
      .getAdminOrderSummaryDashboardData(startDate, endDate, shopNameIds)

      .then((response) =>
        dispatch(getAdminOrderSummaryDashboardDataSuccess(response))
      )
      .catch((error) =>
        dispatch(getAdminOrderSummaryDashboardDataError(error))
      );
  };
}

export function getAdminOrderSummaryDashboardData() {
  return {
    type: GET_ADMIN_ORDER_SUMMARY_DASHBOARD_DATA,
  };
}

export function getAdminOrderSummaryDashboardDataSuccess(success) {
  return {
    type: GET_ADMIN_ORDER_SUMMARY_DASHBOARD_DATA_SUCCESS,
    payload: { success },
  };
}

export function getAdminOrderSummaryDashboardDataError(error) {
  return {
    type: GET_ADMIN_ORDER_SUMMARY_DASHBOARD_DATA_ERROR,
    payload: { error },
  };
}
// Admin Order Summary Dashboard Data End

// Admin Amount Summary Dashboard Data start
export function getAdminAmountSummaryDashboardDataRecord(
  startDate,
  endDate,
  shopNameIds
) {
  return async (dispatch) => {
    dispatch(getAdminAmountSummaryDashboardData());
    return await adminDashboardService
      .getAdminAmountSummaryDashboardData(startDate, endDate, shopNameIds)

      .then((response) =>
        dispatch(getAdminAmountSummaryDashboardDataSuccess(response))
      )
      .catch((error) =>
        dispatch(getAdminAmountSummaryDashboardDataError(error))
      );
  };
}

export function getAdminAmountSummaryDashboardData() {
  return {
    type: GET_ADMIN_AMOUNT_SUMMARY_DASHBOARD_DATA,
  };
}

export function getAdminAmountSummaryDashboardDataSuccess(success) {
  return {
    type: GET_ADMIN_AMOUNT_SUMMARY_DASHBOARD_DATA_SUCCESS,
    payload: { success },
  };
}

export function getAdminAmountSummaryDashboardDataError(error) {
  return {
    type: GET_ADMIN_AMOUNT_SUMMARY_DASHBOARD_DATA_ERROR,
    payload: { error },
  };
}
// Admin Amount Summary Dashboard Data End

// Admin Vendor Summary Dashboard Data start
export function getAdminVendorSummaryDashboardDataRecord(
  startDate,
  endDate,
  shopNameIds
) {
  return async (dispatch) => {
    dispatch(getAdminVendorSummaryDashboardData());
    return await adminDashboardService
      .getAdminVendorSummaryDashboardData(startDate, endDate, shopNameIds)

      .then((response) =>
        dispatch(getAdminVendorSummaryDashboardDataSuccess(response))
      )
      .catch((error) =>
        dispatch(getAdminVendorSummaryDashboardDataError(error))
      );
  };
}

export function getAdminVendorSummaryDashboardData() {
  return {
    type: GET_ADMIN_VENDOR_SUMMARY_DASHBOARD_DATA,
  };
}

export function getAdminVendorSummaryDashboardDataSuccess(success) {
  return {
    type: GET_ADMIN_VENDOR_SUMMARY_DASHBOARD_DATA_SUCCESS,
    payload: { success },
  };
}

export function getAdminVendorSummaryDashboardDataError(error) {
  return {
    type: GET_ADMIN_VENDOR_SUMMARY_DASHBOARD_DATA_ERROR,
    payload: { error },
  };
}
// Admin Amount Summary Dashboard Data End

// Admin Product Summary Dashboard Data start
export function getAdminProductSummaryDashboardDataRecord(
  startDate,
  endDate,
  shopNameIds
) {
  return async (dispatch) => {
    dispatch(getAdminProductSummaryDashboardData());
    return await adminDashboardService
      .getAdminProductSummaryDashboardData(startDate, endDate, shopNameIds)

      .then((response) =>
        dispatch(getAdminProductSummaryDashboardDataSuccess(response))
      )
      .catch((error) =>
        dispatch(getAdminProductSummaryDashboardDataError(error))
      );
  };
}

export function getAdminProductSummaryDashboardData() {
  return {
    type: GET_ADMIN_PRODUCTS_SUMMARY_DASHBOARD_DATA,
  };
}

export function getAdminProductSummaryDashboardDataSuccess(success) {
  return {
    type: GET_ADMIN_PRODUCTS_SUMMARY_DASHBOARD_DATA_SUCCESS,
    payload: { success },
  };
}

export function getAdminProductSummaryDashboardDataError(error) {
  return {
    type: GET_ADMIN_PRODUCTS_SUMMARY_DASHBOARD_DATA_ERROR,
    payload: { error },
  };
}
// Admin Product Summary Dashboard Data End

// Admin Cancel Dashboard Data start
export function getAdminCancelDashboardDataRecord(
  startDate,
  endDate,
  shopNameIds
) {
  return async (dispatch) => {
    dispatch(getAdminCancelDashboardData());
    return await adminDashboardService
      .getAdminCancelDashboardData(startDate, endDate, shopNameIds)

      .then((response) =>
        dispatch(getAdminCancelDashboardDataSuccess(response))
      )
      .catch((error) =>
        dispatch(getAdminCancelDashboardDataError(error))
      );
  };
}

export function getAdminCancelDashboardData() {
  return {
    type: GET_ADMIN_CANCEL_DASHBOARD_DATA,
  };
}

export function getAdminCancelDashboardDataSuccess(success) {
  return {
    type: GET_ADMIN_CANCEL_DASHBOARD_DATA_SUCCESS,
    payload: { success },
  };
}

export function getAdminCancelDashboardDataError(error) {
  return {
    type: GET_ADMIN_CANCEL_DASHBOARD_DATA_ERROR,
    payload: { error },
  };
}
// Admin Cancel Dashboard Data End

// Admin Return Dashboard Data start
export function getAdminReturnDashboardDataRecord(
  startDate,
  endDate,
  shopNameIds
) {
  return async (dispatch) => {
    dispatch(getAdminReturnDashboardData());
    return await adminDashboardService
      .getAdminReturnDashboardData(startDate, endDate, shopNameIds)

      .then((response) =>
        dispatch(getAdminReturnDashboardDataSuccess(response))
      )
      .catch((error) =>
        dispatch(getAdminReturnDashboardDataError(error))
      );
  };
}

export function getAdminReturnDashboardData() {
  return {
    type: GET_ADMIN_RETURN_DASHBOARD_DATA,
  };
}

export function getAdminReturnDashboardDataSuccess(success) {
  return {
    type: GET_ADMIN_RETURN_DASHBOARD_DATA_SUCCESS,
    payload: { success },
  };
}

export function getAdminReturnDashboardDataError(error) {
  return {
    type: GET_ADMIN_RETURN_DASHBOARD_DATA_ERROR,
    payload: { error },
  };
}
// Admin Return Dashboard Data End

// Admin Refund Dashboard Data start
export function getAdminRefundDashboardDataRecord(
  startDate,
  endDate,
  shopNameIds
) {
  return async (dispatch) => {
    dispatch(getAdminRefundDashboardData());
    return await adminDashboardService
      .getAdminRefundDashboardData(startDate, endDate, shopNameIds)

      .then((response) =>
        dispatch(getAdminRefundDashboardDataSuccess(response))
      )
      .catch((error) =>
        dispatch(getAdminRefundDashboardDataError(error))
      );
  };
}

export function getAdminRefundDashboardData() {
  return {
    type: GET_ADMIN_REFUND_DASHBOARD_DATA,
  };
}

export function getAdminRefundDashboardDataSuccess(success) {
  return {
    type: GET_ADMIN_REFUND_DASHBOARD_DATA_SUCCESS,
    payload: { success },
  };
}

export function getAdminRefundDashboardDataError(error) {
  return {
    type: GET_ADMIN_REFUND_DASHBOARD_DATA_ERROR,
    payload: { error },
  };
}
// Admin Refund Dashboard Data End


// Get All Summaries Dashboard Data start
export function getAllSummariesDashboardDataRecord(
  startDate,
  endDate,
  shopNameIds
) {
  return async (dispatch) => {
    dispatch(getAllSummariesDashboardData());
    return await adminDashboardService
      .getAllSummariesDashboardData(startDate, endDate, shopNameIds)

      .then((response) =>
        dispatch(getAllSummariesDashboardDataSuccess(response))
      )
      .catch((error) =>
        dispatch(getAllSummariesDashboardDataError(error))
      );
  };
}

export function getAllSummariesDashboardData() {
  return {
    type: GET_ALL_SUMMARIES_DASHBOARD_DATA,
  };
}

export function getAllSummariesDashboardDataSuccess(success) {
  return {
    type: GET_ALL_SUMMARIES_DASHBOARD_DATA_SUCCESS,
    payload: { success },
  };
}

export function getAllSummariesDashboardDataError(error) {
  return {
    type: GET_ALL_SUMMARIES_DASHBOARD_DATA_ERROR,
    payload: { error },
  };
}
// Get All Summaries Dashboard Data End