/* eslint-disable react-hooks/exhaustive-deps */
import html2pdf from "html2pdf.js";
import moment from "moment";
import React, { useEffect } from "react";
import "../css/PaymentTypeWiseSalesSummaryView.css";
import logo from "./../../../assets/images/Report_Logo.jpg";
import PaymentTypeWiseSalesSummarySmallData from "./PaymentTypeWiseSalesSummarySmallData";

const PaymentTypeWiseSalesSummaryView = ({
  startDate,
  endDate,
  arrayDatas,
  format,
  setShowQuickView,
}) => {
  const convertToPDF = () => {
    setShowQuickView(true);
    const element = document.getElementById("accounts__report_items"); 

    // Get the current date and time
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString();
    const formattedTime = currentDate.toLocaleTimeString();

    html2pdf()
    .set({
      html2canvas: {
        scale: 3,
      },
      margin: [10, 10, 18, 10],
    })
      .from(element)
      .toPdf()
      .get("pdf")
      .then(function (pdf) {
        var totalPages = pdf.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(11);
          pdf.setTextColor(100);
          pdf.text(
            "Page " + i + " of " + totalPages,
            pdf.internal.pageSize.getWidth() / 1.18,
            pdf.internal.pageSize.getHeight() / 1.02
          );
          if (i === 1) {
            pdf.setFontSize(8);
            pdf.text(
              `Print Date: ${moment(formattedDate).format("DD-MMM-YYYY")}`,
              pdf.internal.pageSize.getWidth() / 1.195,
              35
            );
            pdf.text(
              `Print Time: ${formattedTime}`,
              pdf.internal.pageSize.getWidth() / 1.2,
              40
            );
          }
        }
      })
      // .save()
      .output("datauristring")
      .then((pdfDataUrl) => {
        const newTab = window.open();
        newTab.document.body.innerHTML = `<embed src="${pdfDataUrl}" width="100%" height="100%" type="application/pdf">`;
      });
  };

  useEffect(() => {
    if (format === 2) {
      convertToPDF();
      setShowQuickView(false);
    }
  }, [format, arrayDatas]);

  return (
    <>
      <div className="accounts_PaymentTypeWiseSalesSummary__report_buttons_container">
        <button onClick={convertToPDF} className="btn btn-primary">
          Convert to PDF
        </button>
      </div>

      <div
        className="accounts_PaymentTypeWiseSalesSummary__report_container"
        id="accounts__report_items"
      >
        <div className="accounts_PaymentTypeWiseSalesSummary__report_header">
          <div style={{ width: "20%" }}>
            <img style={{ width: "100%" }} src={logo} alt="logo" />
          </div>

          <div style={{ width: "70%" }}>
            <div className="accounts_PaymentTypeWiseSalesSummary__report_header_text1">
              SaRa Lifestyle Ltd
            </div>
            <div className="accounts_PaymentTypeWiseSalesSummary__report_header_text2">
              Plot No # Shilpa/01, Section #07, Milk Vita Road; Mirpur, Dhaka –
              1216
            </div>
            <br />
            <div className="accounts_PaymentTypeWiseSalesSummary__report_header_text3">
              Payment Type Summary Report between{" "}
              {moment(startDate).format("D-MMMM-YY")} to{" "}
              {moment(endDate).format("D-MMMM-YY")}
            </div>
            <br />
            <br />
          </div>
          <div style={{ width: "10%" }}></div>
        </div>
        <div className="accounts_PaymentTypeWiseSalesSummary__report_body">
          <div className="accounts_PaymentTypeWiseSalesSummary__report_body_header">
            <div
              style={{ width: "5%", textAlign: "center" }}
              className="accounts_PaymentTypeWiseSalesSummary__report_body_header_text"
            >
              SL.
            </div>
            <div
              style={{ width: "50%", textAlign: "center" }}
              className="accounts_PaymentTypeWiseSalesSummary__report_body_header_text"
            >
              SHOP NAME
            </div>
            <div
              style={{ width: "25%", textAlign: "center" }}
              className="accounts_PaymentTypeWiseSalesSummary__report_body_header_text"
            >
              AMOUNT
            </div>
          </div>
          <div className="accounts_PaymentTypeWiseSalesSummary__report_body_sections">
            {arrayDatas[1].map((dt) => (
              <PaymentTypeWiseSalesSummarySmallData
                methodName={dt}
                data={arrayDatas[0][dt]}
              />
            ))}
          </div>
          <div className="report_PaymentTypeWiseSalesSummary_data_container_payment_method_grand_total_container">
            <div className="report_PaymentTypeWiseSalesSummary_data_container_payment_method_grand_total">
              {" "}
              <div style={{ width: "65%", textAlign: "center" }}>
                Grand Total:
              </div>
              <div style={{ width: "35%", textAlign: "center" }}>
                {arrayDatas[2].toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentTypeWiseSalesSummaryView;
