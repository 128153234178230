import transactionService from "../services/transactionService";

export const GET_ORDER_ACCOUNTS_TRANSACTION = "GET_ORDER_ACCOUNTS_TRANSACTION";
export const GET_ORDER_ACCOUNTS_TRANSACTION_SUCCESS =
  "GET_ORDER_ACCOUNTS_TRANSACTION_SUCCESS";
export const GET_ORDER_ACCOUNTS_TRANSACTION_ERROR =
  "GET_ORDER_ACCOUNTS_TRANSACTION_ERROR";

// Accounts Transaction start
export function getOrderAccountsTransactionRecord(
  keywords,
  startTranDate,
  endTranDate,
  paymentMethodName,
  paymentStatus,
  currentPage,
  itemsPerPage
) {
  return async (dispatch) => {
    dispatch(getOrderAccountsTransaction());
    return await transactionService
      .getOrderAccountsTransaction(
        keywords,
        startTranDate,
        endTranDate,
        paymentMethodName,
        paymentStatus,
        currentPage,
        itemsPerPage
      )

      .then((response) =>
        dispatch(getOrderAccountsTransactionSuccess(response))
      )
      .catch((error) => dispatch(getOrderAccountsTransactionError(error)));
  };
}

export function getOrderAccountsTransaction() {
  return {
    type: GET_ORDER_ACCOUNTS_TRANSACTION,
  };
}

export function getOrderAccountsTransactionSuccess(success) {
  return {
    type: GET_ORDER_ACCOUNTS_TRANSACTION_SUCCESS,
    payload: { success },
  };
}

export function getOrderAccountsTransactionError(error) {
  return {
    type: GET_ORDER_ACCOUNTS_TRANSACTION_ERROR,
    payload: { error },
  };
}

// Accounts Transaction End
