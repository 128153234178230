import React from "react";
import LoaderCreate from "../../components/Loader/LoaderCreate";

const LoaderContainer = ({ type = "TailSpin", color = "#0078bc", height = "70", width = "70", }) => {
  return (
    <div><LoaderCreate
      type={type}
      color={color}
      height={height}
      width={width}
    /></div>
  )
}

export default LoaderContainer