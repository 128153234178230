/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Resizer from "react-image-file-resizer";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateBrand from "../../../components/settings/brand/CreateBrand";
import * as activeBreadcrumbsCategoryAction from "../../../store/actions/activeBreadcrumbsCategoryAction";
// import * as brandAction from "../../../store/actions/brandAction";
import authenticationService from "../../../store/services/authenticationService";
import { useHistory } from "react-router-dom";
import { createBrandRecord } from "../../../store/actions/brandAction";

const CreateBrandContainer = () => {
  const [brandId, setBrandId] = useState(0);
  const [brandName, setBrandName] = useState("");
  const [brandUrl, setBrandUrl] = useState("");
  const [displayOrder, setDisplayOrder] = useState("");
  const [brandDetails, setBrandDetails] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [shownHomePage, setShownHomePage] = useState(true);
  const [productBrandCategoryMapId, setProductBrandCategoryMapId] = useState(0);
  const [categories, setCategories] = useState([]);
  const [productBrandCategoryMap, setProductBrandCategoryMap] = useState([]);
  const [brandLogoUrl, setBrandLogoUrl] = useState("");
  const [imageType, setImageType] = useState("");
  const [file, setFile] = useState("");
  const [files, setFiles] = useState([]);
  const [fileName, setFileName] = useState("");
  const [errorBrandUrl, setErrorBrandUrl] = useState("");
  const [errorBrandName, setErrorBrandName] = useState("");
  const [showFile, setShowFile] = useState("");

  const history=useHistory();
  const dispatch = useDispatch();
  const activeBreadcrumbsCategories = useSelector(
    (state) => state.activeBreadcrumbsCategoryReducer.activeBreadcrumbsCategories
  );
  const { saving } = useSelector(
    (state) => state.brandReducer
  );

  useEffect(() => {
    dispatch(activeBreadcrumbsCategoryAction.getActiveBreadcrumbsCategoryRecord());
  }, [dispatch]);

  useEffect(() => {
    let roleId = authenticationService.getRoleId();
    if (roleId !== "1") {
      history.push("/Login");
    }
    setCategories(activeBreadcrumbsCategories);
  }, [activeBreadcrumbsCategories]);

  const categoriesOptions = () => {
    return categories.map((data) => ({
      label: data?.breadcrumbCategory,
      value: data?.categoryId,
    }));
  };

  const brandLogoUrlHandler = (event) => {
    const imageFile = event.target.files[0];
    var fileInput = false;
    if (imageFile) {
      if (!imageFile.name.match(/\.(jpg|jpeg|png|gif|webp|svg)$/)) {
        toast.error("Select a valid image.");
        return false;
      }
      fileInput = true;
      if (fileInput) {
        try {
          Resizer.imageFileResizer(
            imageFile,
            120,
            120,
            "JPEG",
            100,
            0,
            (uri) => {
              setBrandLogoUrl(uri);
              setFileName(imageFile?.name);
              setShowFile(URL.createObjectURL(imageFile));
              toast.success("Image Selected.");
            },
            "base64",
            120,
            120
          );
        } catch (err) {
          toast.error("Something went wrong!");
        }
      }
    }
  };

  const handleChange = (e) => {
    const { target } = e;
    const { value } = e.target;

    switch (target.name) {
      case "brandName":
        setBrandName(value);
        setErrorBrandName(value.length < 2 ? "At least 2 characters required" : "");
        break;
      case "brandUrl":
        setBrandUrl(value);
        setErrorBrandUrl(value.length === 0 ? "Brand URL required" : "");
        break;
      case "displayOrder":
        setDisplayOrder(value);
        break;
      case "brandDetails":
        setBrandDetails(value);
        break;
      default:
        break;
    }
  };

  const handleMultiSelectChange = (productBrandCategoryMap) => {
    setProductBrandCategoryMap(productBrandCategoryMap);
  };

  const handleParentCheck = (e) => {
    const { name, checked } = e?.target;
    switch (name) {
      case "isActive":
        setIsActive(checked);
        break;
      case "shownHomePage":
        setShownHomePage(checked);
        break;
      default:
        break;
    }
  };

  const saveBrand = async (e) => {
    e.preventDefault();

    if (!brandName) {
      let msg = "Select a Brand Name";
      toast.error(msg);
      return;
    }

    if (!brandUrl) {
      let msg = "Select a Brand Url";
      toast.error(msg);
      return;
    }

    if (!productBrandCategoryMap || productBrandCategoryMap.length === 0) {
      let msg = "Select a Category";
      toast.error(msg);
      return;
    }

    if (brandLogoUrl === "") {
      let msg = "Upload an Image!!!";
      toast.error(msg);
      return;
    }

    const transformed = productBrandCategoryMap.map(({ value }) => ({
      categoryId: value,
    }));

    const finalArray = transformed.map((obj) => ({
      ...obj,
      brandId,
      productBrandCategoryMapId,
    }));

    const data = {
      brandName,
      brandUrl,
      displayOrder: displayOrder || 0,
      brandDetails,
      isActive: isActive ? "Y" : "N",
      shownHomePage: shownHomePage ? "Y" : "N",
      brandLogoUrl,
      productBrandCategoryMap: [...finalArray],
    };

    try {
      let result = await dispatch(createBrandRecord(data));

      // let result = await brandAction.createBrandRecord(data);

    if (result && result?.payload?.success?.data?.succeed === true) {
      toast.success("Brand Created Successfully");
      setTimeout(() => {
        history.push("BrandList");
      }, 2500);
      resetForm();
    } else if (result && result?.payload?.success?.data?.succeed === false) {
      toast.error("Brand Already Exists!");
    } else {
      toast.error("Something went wrong, Please try again");
    }
    } catch (error) {
      toast.error("Something went wrong, Please try again");
    }

  };

  const resetForm = () => {
    setBrandName("");
    setBrandUrl("");
    setDisplayOrder("");
    setBrandDetails("");
    setIsActive(true);
    setShownHomePage(true);
    setProductBrandCategoryMapId(0);
    setCategories([]);
    setProductBrandCategoryMap([]);
    setBrandLogoUrl("");
    setImageType("");
    setFile("");
    setFiles([]);
    setFileName("");
    setErrorBrandName("");
    setErrorBrandUrl("");
    setShowFile("");
  };

  return (
    <div id="wrapper">
      <CreateBrand
        key="CreateBrand"
        name="Add Brand"
        brandId={brandId}
        brandName={brandName}
        brandUrl={brandUrl}
        displayOrder={displayOrder}
        brandDetails={brandDetails}
        isActive={isActive}
        shownHomePage={shownHomePage}
        productBrandCategoryMapId={productBrandCategoryMapId}
        categories={categoriesOptions()}
        selectedCategories={productBrandCategoryMap}
        brandLogoUrl={brandLogoUrl}
        showFile={showFile}
        imageType={imageType}
        file={file}
        files={files}
        fileName={fileName}
        errorBrandUrl={errorBrandUrl}
        errorBrandName={errorBrandName}
        handleChange={handleChange}
        handleMultiSelectChange={handleMultiSelectChange}
        saveBrand={saveBrand}
        handleParentCheck={handleParentCheck}
        brandLogoUrlHandler={brandLogoUrlHandler}
        activeBreadcrumbsCategories={activeBreadcrumbsCategories}
        brandSaving={saving}
      />
      <ToastContainer autoClose={1500} />
    </div>
  );
};

export default CreateBrandContainer;
