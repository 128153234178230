/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Resizer from 'react-image-file-resizer';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import EditStoreBanner from '../../components/store/EditStoreBanner';
import { getShopRecord } from '../../store/actions/shopAction';
import { createOrUpdateStoreBannerRecord, createOrUpdateStoreBannerReset } from '../../store/actions/storeAction';

const EditSellerStoreBannerContainer = () => {
    const locationState = useLocation().state;

    const [sellerStoreBannerId, setSellerStoreBannerId] = useState(0)
    const [shopName, setShopName] = useState('')
    const [shopId, setShopId] = useState('')
    const [sellerId, setSellerId] = useState('')
    const [bannerName, setBannerName] = useState('')
    const [bannerTitle, setBannerTitle] = useState('')
    const [bannerLink, setBannerLink] = useState('')
    const [bannerLocationId, setBannerLocationId] = useState('')
    const [bannerDisplayOrder, setBannerDisplayOrder] = useState('')
    const [bannerImage, setBannerImage] = useState('')
    const [bannerImageShowFile, setBannerImageShowFile] = useState('')
    const [bannerImageIsActive, setBannerImageIsActive] = useState(true)

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(getShopRecord());
    }, [dispatch]);

    useEffect(() => {
        locationState.data && setSellerStoreBannerId(locationState?.data?.sellerStoreBannerId)
        locationState.data && setShopId(locationState?.data?.sellerShopId)
        locationState.data && setSellerId(locationState?.data?.sellerId)
        locationState.data && setShopName({
            shopName: locationState?.data?.shopName,
            sellerId: locationState?.data?.sellerId,
            shopId: locationState?.data?.sellerShopId,
        })
        locationState.data && setBannerName(locationState?.data?.sellerStoreBannerName)
        locationState.data && setBannerTitle(locationState?.data?.sellerStoreBannerTitle)
        locationState.data && setBannerLink(locationState?.data?.sellerStoreBannerLink)
        locationState.data && setBannerLocationId(locationState?.data?.sellerStoreBannerPosition)
        locationState.data && setBannerDisplayOrder(locationState?.data?.displayOrder)
        locationState.data && setBannerImageIsActive(locationState?.data?.isActive === 'Y' ? true : false)
        locationState.data && setBannerImage(locationState?.data?.sellerStoreBannerImageUrl)
    }, [locationState.data])

    const shops = useSelector((state) => state.shopReducer.shops);
    const { createOrUpdateBannerData, loading } = useSelector(
        (state) => state.storeReducer
    );

    const handleChange = (e) => {
        e.preventDefault()
        const { target } = e
        switch (target.name) {
            case 'bannerName':
                setBannerName(target.value)
                break
            case 'bannerTitle':
                setBannerTitle(target.value)
                break
            case 'bannerLink':
                setBannerLink(target.value)
                break
            case 'bannerLocationId':
                setBannerLocationId(target.value * 1)
                break
            case 'bannerDisplayOrder':
                setBannerDisplayOrder(target.value)
                break
            case "shopName":
                setShopName(target.value)
                setShopId(target.value.shopId)
                setSellerId(target.value.sellerId)
                break;
            default:
        }
    }

    const bannerFileSelectedHandler = (event) => {
        const imageFile = event.target.files[0]
        var fileInput = false
        if (imageFile) {
            if (!imageFile.name.match(/\.(jpg|jpeg|png|gif|webp)$/)) {
                toast.error('Select a valid image.')
                return false
            }
            fileInput = true
            if (fileInput) {
                try {
                    Resizer.imageFileResizer(
                        imageFile,
                        1614,
                        444,
                        'JPEG',
                        100,
                        0,
                        (uri) => {
                            setBannerImage(uri)
                            setBannerImageShowFile(URL.createObjectURL(imageFile))
                            toast.success('Store Banner Image Selected.')
                        },
                        'base64',
                        1614,
                        444
                    )
                } catch (err) {
                    toast.error('Something went wrong!')
                }
            }
        }
    }

    const handleIsActiveCheck = (e) => {
        setBannerImageIsActive(e.target.checked)
    }

    const updateStoreBanner = async (e) => {
        e.preventDefault()
        if (shopId == '' || sellerId == '') {
            toast.error('Please Select Shop Name')
            return
        }
        if (bannerName == '') {
            toast.error('Please Enter Store Banner Name')
            return
        }
        if (bannerLink == '') {
            toast.error('Please Enter Store Banner Link')
            return
        }
        if (bannerDisplayOrder == '') {
            toast.error('Please Enter Display Order')
            return
        }
        if (bannerLocationId == '') {
            toast.error('Please Select Store Banner Location')
            return
        }
        if (bannerImage == '') {
            toast.error('Please Select Store Banner Image')
            return
        }
        const data = {
            sellerStoreBannerId: sellerStoreBannerId,
            sellerStoreBannerName: bannerName,
            sellerStoreBannerTitle: bannerTitle,
            sellerStoreBannerPosition: bannerLocationId,
            sellerStoreBannerLink: bannerLink,
            displayOrder: bannerDisplayOrder,
            sellerId: sellerId,
            sellerShopId: shopId,
            sellerStoreBannerImageUrl: bannerImage,
            isActive: bannerImageIsActive === true ? 'Y' : 'N',
            status: "",
        }
        dispatch(createOrUpdateStoreBannerRecord(data));
    }

    useEffect(() => {
        if (createOrUpdateBannerData?.data?.message === "Save Success") {
            createOrUpdateBannerData?.data && toast.success("Store Banner Updated Successfully");

            setTimeout(() => {
                dispatch(createOrUpdateStoreBannerReset());
                history.push("/SellerStoreBannerList");
            }, 1500);
        }
        else if (createOrUpdateBannerData?.data?.succeed === true) {
            createOrUpdateBannerData?.data && toast.success("Store Banner Updated Successfully");
            setTimeout(() => {
                dispatch(createOrUpdateStoreBannerReset());
                history.push("/SellerStoreBannerList");
            }, 1500);
        }
        else {
            createOrUpdateBannerData?.data && toast.error("Something Went Wrong!");
            dispatch(createOrUpdateStoreBannerReset());
        }

    }, [createOrUpdateBannerData.data])

    return (
        <>
            <EditStoreBanner
                shops={shops}
                shopName={shopName}
                bannerName={bannerName}
                bannerTitle={bannerTitle}
                bannerLink={bannerLink}
                bannerLocationId={bannerLocationId}
                bannerDisplayOrder={bannerDisplayOrder}
                bannerImage={bannerImage}
                bannerImageShowFile={bannerImageShowFile}
                bannerImageIsActive={bannerImageIsActive}
                handleChange={handleChange}
                bannerFileSelectedHandler={bannerFileSelectedHandler}
                handleIsActiveCheck={handleIsActiveCheck}
                updateStoreBanner={updateStoreBanner}
                isSellerPanel={true}
                storeLoading={loading}
            />
            <ToastContainer autoClose={1500} />
        </>
    )
}

export default EditSellerStoreBannerContainer