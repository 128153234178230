/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import moment from "moment";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from 'primereact/dialog';
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Paginator } from "../../components/paginator/Paginator";
import LoadingCard from '../../components/shared/LoadingCard';
import { getPOListRecord } from "../../store/actions/purchaseOrderAction";
import { getShopRecord } from "../../store/actions/shopAction";
import toIsoString from "../../utils/Functions/toIsoString";

const PurchaseOrderListContainer = () => {
    let history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const prevState = location?.state?.state;

    const urlSearchParams = new URLSearchParams(location.search);
    const pageNo = Number(urlSearchParams.get('page'));

    const [selectedPOProductsList, setSelectedPOProductsList] = useState([]);

    const [currentPage, setCurrentPage] = useState(pageNo ? pageNo : 1);
    const [totalPage, setTotalPage] = useState(null);
    const [itemPerPage, setItemPerPage] = useState(prevState?.itemPerPage ? prevState?.itemPerPage : 30);
    const [totalItems, setTotalItems] = useState(null);

    const [isFilter, setIsFilter] = useState(false);

    const [searchKeyword, setSearchKeyword] = useState(prevState?.searchKeyword ? prevState?.searchKeyword : "");

    const [shop, setShop] = useState(prevState?.shop ? prevState?.shop : null);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [dialogVisible, setDialogVisible] = useState(false);
    const [productDataItems, setProductDataItems] = useState([]);
    const [PONumber, setPONumber] = useState(null);

    const shops = useSelector((state) => state.shopReducer.shops);
    const { getPOListData, getPOListDataLoading } = useSelector(
        (state) => state.purchaseOrderReducer
    );

    useEffect(() => {
        dispatch(getShopRecord());
    }, []);

    const handleItemPerPage = (pagePerItems) => {
        setCurrentPage(1);
        setItemPerPage(pagePerItems);
    };

    const handleCurrentPage = (currentPage) => {
        if (isFilter) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage);
        }
    };

    useEffect(() => {
        if (getPOListData?.headers?.pagination) {
            var paginated_data_to_parse = getPOListData?.headers?.pagination;
            const paginated_data = JSON.parse(paginated_data_to_parse);
            setTotalPage(paginated_data.totalPages);
            setTotalItems(paginated_data.totalItems);
        }
    }, [getPOListData?.headers?.pagination]);

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="p-text-bold table-heading-style">
                    View Purchase Order List
                </div>
            </React.Fragment>
        );
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Link to={"/CreatePurchaseOrder"}>
                    <div className="button-demo">
                        <Button
                            icon="pi pi-times"
                            className="p-button-rounded p-button-danger p-button-outlined"
                        />
                    </div>
                </Link>
            </React.Fragment>
        );
    }

    const PODateTemplate = (rowData) => {
        return (
            <>{moment(rowData?.poDate).format("Do MMMM YYYY, h:mm A")}</>
        );
    };

    const POApprove1Template = (rowData) => {
        return (
            <div>
                <p>{rowData.approval1}</p>
                <p style={{ color: `${rowData.approval1Status == 'Approved' ? '#008000' : '#ff0000'}` }}>({rowData.approval1Status})</p>
            </div>
        );
    };

    const POApprove2Template = (rowData) => {
        return (
            <div>
                <p>{rowData.approval2}</p>
                <p style={{ color: `${rowData.approval2Status == 'Approved' ? '#008000' : '#ff0000'}` }}>({rowData.approval2Status})</p>
            </div>
        );
    };

    const PODetailTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button label="Details"
                    onClick={() => detailButtonHandler(rowData)}
                />
            </React.Fragment>
        );
    };

    const detailButtonHandler = (data) => {
        setPONumber(data?.poNo)
        setProductDataItems(data?.items)
        setDialogVisible(true)
    }

    const dialogFooterTemplate = () => {
        return <Button label="Close" icon="pi pi-close" onClick={() => setDialogVisible(false)} />;
    };

    const printHandler = () => {
        history.push({
            pathname: `/PrintPO`,
            selectedPOProductsList,
            previousPath: location
        });
    }

    useEffect(() => {

        const productInfo = {
            shopId: shop?.shopId,
            itemsPerPage: itemPerPage,
            currentPage: currentPage,
        }
        if (startDate) {
            productInfo.startDate = toIsoString(startDate).substring(0, 10);
        }
        if (endDate) {
            productInfo.endDate = toIsoString(endDate).substring(0, 10);
        }
        if (searchKeyword) {
            productInfo.keyword = searchKeyword;
            productInfo.currentPage = 1;
        }

        dispatch(getPOListRecord(productInfo))

        if (currentPage) {
            history.push(`/PurchaseOrderList?page=${currentPage}`)
        }

        if (isFilter) {
            const url = `/PurchaseOrderList?page=${currentPage}`;
            history.push(url, { state: { searchKeyword, startDate, endDate, shop } })
        }
        return () => setIsFilter(false)

    }, [dispatch, isFilter, currentPage, itemPerPage])

    return (
        <div className="page-wrapper">
            <div className="container-fluid">
                <div className="col-md-12">
                    <div className="white-box">
                        <div className="datatable-doc-demo datatable-responsive-demo">
                            <div className="card">
                                <Toolbar
                                    className="p-mb-4"
                                    right={rightToolbarTemplate}
                                    left={leftToolbarTemplate}
                                ></Toolbar>
                                <>
                                    <div
                                        className="table-header"
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            padding: "8px",
                                            borderBottom: "1px solid #ddd",
                                        }}
                                    >

                                        <div className="button-demo">
                                            <Button
                                                disabled={selectedPOProductsList.length == 0}
                                                label="Print"
                                                className="p-button-info"
                                                onClick={printHandler}
                                                style={{ width: '4vw' }}
                                            />
                                        </div>

                                        <div style={{ display: "flex" }}>
                                            <div
                                                style={{ margin: "0 5px", width: "240px", position: "relative" }}
                                            >
                                                <InputText
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                    className="manage_product__search"
                                                    value={searchKeyword}
                                                    onChange={(e) => setSearchKeyword(e.target.value)}
                                                    placeholder="Keyword"
                                                />
                                                {searchKeyword !== "" ? (
                                                    <button
                                                        style={{
                                                            position: "absolute",
                                                            top: "6px",
                                                            right: "8px",
                                                            borderRadius: "100%",
                                                            border: "1px solid #ced4da",
                                                            height: "25px",
                                                            width: "25px",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                        }}
                                                        onClick={() => {
                                                            setSearchKeyword("");
                                                        }}
                                                    >
                                                        <i className="pi pi-times"></i>
                                                    </button>
                                                ) : null}
                                            </div>

                                            <div
                                                style={{
                                                    margin: "0 2px",
                                                    width: "13vw",
                                                    position: "relative",
                                                }}
                                            >
                                                <Calendar
                                                    id="icon"
                                                    maxDate={endDate}
                                                    value={startDate}
                                                    onChange={(e) => setStartDate(e.value)}
                                                    showIcon
                                                    placeholder="Start Date"
                                                    readOnlyInput
                                                    className="admin_dash-calendar"
                                                />
                                                {startDate !== null ? (
                                                    <button
                                                        style={{
                                                            position: "absolute",
                                                            top: "6px",
                                                            right: "38px",
                                                            borderRadius: "100%",
                                                            border: "1px solid #ced4da",
                                                            height: "25px",
                                                            width: "25px",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                        }}
                                                        onClick={() => {
                                                            setStartDate(null);
                                                        }}
                                                    >
                                                        <i className="pi pi-times"></i>
                                                    </button>
                                                ) : null}
                                            </div>

                                            <div
                                                style={{
                                                    margin: "0 2px",
                                                    width: "13vw",
                                                    position: "relative",
                                                }}
                                            >
                                                <Calendar
                                                    id="icon disableddays"
                                                    minDate={startDate}
                                                    value={endDate}
                                                    onChange={(e) => setEndDate(e.value)}
                                                    showIcon
                                                    placeholder="End Date"
                                                    readOnlyInput
                                                    className="admin_dash-calendar"
                                                />
                                                {endDate !== null ? (
                                                    <button
                                                        style={{
                                                            position: "absolute",
                                                            top: "6px",
                                                            right: "38px",
                                                            borderRadius: "100%",
                                                            border: "1px solid #ced4da",
                                                            height: "25px",
                                                            width: "25px",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                        }}
                                                        onClick={() => {
                                                            setEndDate(null);
                                                        }}
                                                    >
                                                        <i className="pi pi-times"></i>
                                                    </button>
                                                ) : null}
                                            </div>

                                            <div
                                                style={{ margin: "0 5px", width: "175px", position: "relative" }}
                                            >
                                                <Dropdown
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                    value={shop}
                                                    options={shops}
                                                    filter
                                                    showClear
                                                    onChange={(e) => setShop(e.value)}
                                                    optionLabel="shopName"
                                                    placeholder="Select a Shop"
                                                />
                                                {shop !== null ? (
                                                    <button
                                                        style={{
                                                            position: "absolute",
                                                            top: "6px",
                                                            right: "8px",
                                                            borderRadius: "100%",
                                                            border: "1px solid #ced4da",
                                                            height: "25px",
                                                            width: "25px",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                        }}
                                                        onClick={() => {
                                                            setShop(null);
                                                        }}
                                                    >
                                                        <i className="pi pi-times"></i>
                                                    </button>
                                                ) : null}
                                            </div>

                                            <Button
                                                onClick={() => {
                                                    setIsFilter(true);
                                                    setCurrentPage(1);
                                                }}
                                                style={{ marginLeft: "5px", width: '4vw' }}
                                                // disabled={!shop}
                                                label="Search"
                                            />
                                        </div>
                                    </div>

                                    {
                                        getPOListDataLoading ? (
                                            <LoadingCard count={1} />
                                        ) :
                                            (
                                                <DataTable
                                                    className="product_list_table"
                                                    responsiveLayout="scroll"
                                                    value={getPOListData.data}
                                                    selection={selectedPOProductsList}
                                                    onSelectionChange={(e) => setSelectedPOProductsList(e.value)}
                                                    emptyMessage="No Product found!"
                                                    showGridlines
                                                >
                                                    <Column selectionMode="multiple" />

                                                    <Column
                                                        field="poDate"
                                                        header="Date & Time"
                                                        body={PODateTemplate}
                                                        style={{
                                                            maxWidth: '120px',
                                                            wordWrap: "break-word",
                                                            textAlign: "center"
                                                        }}
                                                        sortable
                                                    />

                                                    <Column
                                                        field="poNo"
                                                        header="Purchase Order No."
                                                        style={{
                                                            maxWidth: '150px',
                                                            wordWrap: "break-word",
                                                            textAlign: "center"
                                                        }}
                                                        sortable
                                                    />

                                                    <Column
                                                        field="createBy"
                                                        header="Created By"
                                                        style={{
                                                            maxWidth: '120px',
                                                            wordWrap: "break-word",
                                                            textAlign: "center"
                                                        }}
                                                        sortable
                                                    />

                                                    <Column
                                                        field="sellerName"
                                                        header="Shop Name"
                                                        style={{
                                                            maxWidth: '150px',
                                                            wordWrap: "break-word",
                                                            textAlign: "center"
                                                        }}
                                                        sortable
                                                    />

                                                    {/* <Column
                                                        field="price"
                                                        header="Approval 1"
                                                        body={POApprove1Template}
                                                        style={{
                                                            maxWidth: '130px',
                                                            wordWrap: "break-word",
                                                            textAlign: "center"
                                                        }}
                                                        sortable
                                                    /> */}

                                                    {/* <Column
                                                        field="price"
                                                        header="Approval 2"
                                                        body={POApprove2Template}
                                                        style={{
                                                            maxWidth: '130px',
                                                            wordWrap: "break-word",
                                                            textAlign: "center"
                                                        }}
                                                        sortable
                                                    /> */}

                                                    <Column
                                                        field="paymentMethodName"
                                                        header="Payment Method"
                                                        style={{
                                                            maxWidth: '120px',
                                                            wordWrap: "break-word",
                                                            textAlign: "center"
                                                        }}
                                                        sortable
                                                    />

                                                    <Column
                                                        field="poAmount"
                                                        header="Amount"
                                                        style={{
                                                            maxWidth: '100px',
                                                            wordWrap: "break-word",
                                                            textAlign: "center"
                                                        }}
                                                        sortable
                                                    />

                                                    <Column
                                                        header="Action"
                                                        body={PODetailTemplate}
                                                    />

                                                    <Column
                                                        field="remarks"
                                                        header="Remarks"
                                                        style={{
                                                            maxWidth: '300px',
                                                            wordWrap: "break-word"
                                                        }}
                                                    />

                                                </DataTable>
                                            )}

                                    <Paginator
                                        totalPage={totalPage}
                                        currentPage={currentPage}
                                        itemPerPage={itemPerPage}
                                        totalItems={totalItems}
                                        items={getPOListData.data}
                                        itemsPerPageOptions={[]}
                                        handleItemPerPage={handleItemPerPage}
                                        handleCurrentPage={handleCurrentPage}
                                    />
                                </>

                                <Dialog
                                    header={`Details of P.O No. ${PONumber}`}
                                    visible={dialogVisible}
                                    style={{ width: '75vw' }}
                                    contentStyle={{ maxHeight: '400px' }}
                                    onHide={() => setDialogVisible(false)}
                                    footer={dialogFooterTemplate}
                                >
                                    <DataTable
                                        value={productDataItems}
                                        scrollable
                                        scrollHeight="flex"
                                        tableStyle={{ minWidth: '50rem' }}
                                    >
                                        <Column
                                            field="productName"
                                            header="Product Name"
                                            style={{ maxWidth: '450px', wordWrap: "break-word" }}
                                        />
                                        <Column
                                            field="sellerProductSku"
                                            header="Seller Product SKU"
                                        />
                                        <Column
                                            field="productVariant"
                                            header="Variant"
                                        />
                                        <Column
                                            field="productPrice"
                                            header="Unit Price"
                                        />
                                        <Column
                                            field="productQuantity"
                                            header="Purchase Order Qty"
                                        />
                                        <Column
                                            field="amount"
                                            header="Total Price"
                                        />
                                    </DataTable>
                                </Dialog>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PurchaseOrderListContainer
