import React, { useEffect, useState } from "react";
import Resizer from "react-image-file-resizer";
import { connect, useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditBrand from "../../../components/settings/brand/EditBrand";

import authenticationService from "../../../store/services/authenticationService";
import { createBrandRecord } from "../../../store/actions/brandAction";
import { getActiveBreadcrumbsCategoryRecord } from "../../../store/actions/activeBreadcrumbsCategoryAction";
import { useHistory } from "react-router-dom";

const EditBrandContainer = () => {
  const [brandId, setBrandId] = useState(0);
  const [brandName, setBrandName] = useState("");
  const [brandUrl, setBrandUrl] = useState("");
  const [displayOrder, setDisplayOrder] = useState("");
  const [brandDetails, setBrandDetails] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [shownHomePage, setShownHomePage] = useState(false);
  const [productBrandCategoryMapId, setProductBrandCategoryMapId] = useState(0);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [productBrandCategoryMap, setProductBrandCategoryMap] = useState([]);
  const [brandLogoUrl, setBrandLogoUrl] = useState("");
  const [showFile, setShowFile] = useState("");
  const [imageType, setImageType] = useState("");
  const [file, setFile] = useState("");
  const [files, setFiles] = useState([]);
  const [fileName, setFileName] = useState("");
  const [errorBrandName, setErrorBrandName] = useState("");
  const [errorBrandUrl, setErrorBrandUrl] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();

  const { activeBreadcrumbsCategories } = useSelector(
    (state) => state.activeBreadcrumbsCategoryReducer
  );
  const { saving } = useSelector(
    (state) => state.brandReducer
  );

  useEffect(() => {
    dispatch(getActiveBreadcrumbsCategoryRecord());
  }, [dispatch]);

  useEffect(() => {
    const roleId = authenticationService.getRoleId();
    const rowData = history?.location?.state?.rowData;
    if (roleId === "1") {
      setBrandId(rowData?.brandId);
      setBrandName(rowData?.brandName);
      setBrandUrl(rowData?.brandUrl);
      setDisplayOrder(rowData?.displayOrder);
      setBrandDetails(rowData?.brandDetails);
      setIsActive(rowData?.isActive === "Y");
      setShownHomePage(rowData?.shownHomePage === "Y");
      setBrandLogoUrl(rowData?.brandLogo);
    } else {
      history.push("/Login");
    }

    if (activeBreadcrumbsCategories) {
      setCategories(activeBreadcrumbsCategories);
    }

    setSelectedCategories(
      rowData?.productCategories?.map((data) => ({
        label: data?.name,
        value: data?.categoryId,
      })) || []
    );
  }, [activeBreadcrumbsCategories]);

  const categoriesOptions = () => {
    return (
      categories &&
      categories.map((data) => ({
        label: data?.breadcrumbCategory,
        value: data.categoryId,
      }))
    );
  };

  const brandLogoUrlHandler = (event) => {
    const imageFile = event.target.files[0];

    var fileInput = false;
    if (imageFile) {
      if (!imageFile.name.match(/\.(jpg|jpeg|png|gif|webp)$/)) {
        toast.error("Select a valid image.");
        return false;
      }
      fileInput = true;
      if (fileInput) {
        try {
          Resizer.imageFileResizer(
            imageFile,
            120,
            120,
            "JPEG",
            100,
            0,
            (uri) => {
              setBrandLogoUrl(uri);
              setShowFile(URL.createObjectURL(imageFile));
              setFileName(imageFile.name);
              toast.success("Image Selected.");
            },
            "base64",
            120,
            120
          );
        } catch (err) {
          toast.error("Something went wrong!");
        }
      }
    }
  };

  const handleChange = (e) => {
    const { target } = e;
    const { value } = e.target;
    switch (target.name) {
      case "brandName":
        setBrandName(value);
        setErrorBrandName(
          value.length < 2 ? "At least 2 characters required" : ""
        );
        break;
      case "brandUrl":
        setBrandUrl(value);
        setErrorBrandUrl(value.length === 0 ? "Brand URL required" : "");
        break;
      case "displayOrder":
        setDisplayOrder(value);
        break;
      case "brandDetails":
        setBrandDetails(value);
        break;
      default:
        break;
    }
  };

  const handleMultiSelectChange = (selectedCategories) => {
    setSelectedCategories(selectedCategories);
  };

  const handleParentCheck = (e) => {
    const { name, checked } = e?.target;

    switch (name) {
      case "isActive":
        setIsActive(checked);
        break;
      case "shownHomePage":
        setShownHomePage(checked);
        break;
      default:
        break;
    }
  };

  const updateBrand = async (e) => {
    e.preventDefault();

    if (!brandName) {
      let msg = "Select a Brand Name";
      toast.error(msg);
      return;
    }

    if (!brandUrl) {
      let msg = "Select a Brand Url";
      toast.error(msg);
      return;
    }

    if (!selectedCategories || !selectedCategories.length) {
      let msg = "Select a Category";
      toast.error(msg);
      return;
    }

    if (brandLogoUrl === "") {
      let msg = "Upload an Image!!!";
      toast.error(msg);
      return;
    }

    const transformed = selectedCategories.map(({ value }) => ({
      categoryId: value,
    }));

    const finalArray = transformed.map((obj) => ({
      ...obj,
      brandId,
      productBrandCategoryMapId,
    }));

    const data = {
      brandId,
      brandName,
      brandUrl,
      displayOrder: displayOrder || 0,
      brandDetails,
      isActive: isActive ? "Y" : "N",
      shownHomePage: shownHomePage ? "Y" : "N",
      brandLogoUrl,
      productBrandCategoryMap: [...finalArray],
    };

    try {
      let result = await dispatch(createBrandRecord(data));

      if (result && result?.payload?.success?.data?.succeed === true) {
        toast.success("Brand Updated Successfully");
        setTimeout(() => {
          history.push("BrandList");
        }, 2500);
        resetForm();
      } else if (result && result?.payload?.success?.data?.succeed === false) {
        toast.error("Brand Already Exists!");
      } else {
        toast.error("Something went wrong, Please try again");
      }
    } catch (error) {
      toast.error("Something went wrong, Please try again");
    }
  };

  const resetForm = () => {
    setBrandName("");
    setBrandUrl("");
    setDisplayOrder("");
    setBrandDetails("");
    setIsActive(false);
    setShownHomePage(false);
    setProductBrandCategoryMapId(0);
    setCategories("");
    setProductBrandCategoryMap([]);
    setSelectedCategories([]);
    setBrandLogoUrl("");
    setImageType("");
    setFile("");
    setFiles("");
    setFileName("");
    setErrorBrandName("");
    setErrorBrandUrl("");
  };

  return (
    <div id="wrapper">
      <EditBrand
        key="EditBrand"
        name="Edit Brand"
        brandId={brandId}
        brandName={brandName}
        brandUrl={brandUrl}
        displayOrder={displayOrder}
        brandDetails={brandDetails}
        isActive={isActive}
        shownHomePage={shownHomePage}
        productBrandCategoryMapId={productBrandCategoryMapId}
        categories={categoriesOptions()}
        selectedCategories={selectedCategories}
        brandLogoUrl={brandLogoUrl}
        showFile={showFile}
        imageType={imageType}
        file={file}
        files={files}
        fileName={fileName}
        errorBrandName={errorBrandName}
        errorBrandUrl={errorBrandUrl}
        handleChange={handleChange}
        handleMultiSelectChange={handleMultiSelectChange}
        updateBrand={updateBrand}
        handleParentCheck={handleParentCheck}
        brandLogoUrlHandler={brandLogoUrlHandler}
        activeBreadcrumbsCategories={activeBreadcrumbsCategories}
        brandSaving={saving}
      />
      <ToastContainer autoClose={1500} />
    </div>
  );
};

export default EditBrandContainer;
