import React from 'react'

const ADContentUpload = ({ props, errors, register, width, height, typeName }) => {
    return (
        <div className="p-field p-fluid">
            <div className="form-group">
                <label className="control_label">
                    Content Images{" "}
                    <span
                        aria-hidden="true"
                        style={{ color: "red", fontWeight: "bold" }}
                    >
                        *
                    </span>
                </label>
                <div>
                    <form id="to-do-form" onSubmit={props?.addItem}>
                        <div className="field p-fluid p-formgrid p-grid">
                            <div className="p-fluid p-col-12 p-md-6 p-lg-2">
                                <div className="form-group">
                                    <input
                                        id="adsImageSeoName"
                                        className="form-control"
                                        type="text"
                                        placeholder="Enter Image SEO Name"
                                        name="adsImageSeoName"
                                        value={props?.adsItems?.adsImageSeoName}
                                        onChange={props?.handleInput}
                                        ref={register}
                                    />
                                    {errors?.adsImageSeoName && (
                                        <span className="error">
                                            {errors?.adsImageSeoName?.message}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="p-fluid p-col-12 p-md-6 p-lg-3">
                                <div className="form-group">
                                    <input
                                        id="adsLink"
                                        className="form-control"
                                        type="text"
                                        placeholder="Enter Content Link"
                                        name="adsLink"
                                        value={props?.adsItems?.adsLink}
                                        onChange={props?.handleInput}
                                        ref={register}
                                    />
                                    {errors?.adsLink && (
                                        <span className="error">
                                            {errors?.adsLink?.message}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="p-fluid p-col-12 p-md-6 p-lg-3">
                                <div className="form-group input-file-container file-area">
                                    <input
                                        type="file"
                                        accept="image/*,video/mp4"
                                        name="adsImageUrl"
                                        id="my-file"
                                        required="required"
                                        onChange={(event) =>
                                            props?.imageBannerFileUploadHandler(event, width, height)}
                                    />
                                    <div className="file-dummy">
                                        <div className="success">
                                            {props?.adsImageUrlFileName}
                                        </div>
                                        <div className="default">
                                            {`Upload ${typeName}`} <br />
                                            {`(${width} x ${height}) px`}

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-fluid p-col-12 p-md-6 p-lg-1">
                                <div className="form-group">
                                    <div className="checkbox checkbox-success">
                                        <input
                                            id="checkbox33"
                                            type="checkbox"
                                            name="isChecked"
                                            checked={props?.adsItems?.isActive}
                                            onChange={props?.handleInput}
                                        />
                                        <label htmlFor="checkbox33">
                                            {" "}
                                            &nbsp;Active{" "}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='p-fluid p-col-12 p-md-6 p-lg-2'>
                                <div className="form-group">
                                    <input
                                        id="displayOrder"
                                        className="form-control"
                                        type="number"
                                        placeholder="Enter Display Order"
                                        name="displayOrder"
                                        value={props?.adsItems?.displayOrder}
                                        onChange={props?.handleInput}
                                        ref={register}
                                    />
                                    {errors?.displayOrder && (
                                        <span className="error">
                                            {errors?.displayOrder?.message}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div
                                className="p-fluid p-col-12 p-md-6 p-lg-1"
                                style={{ textAlign: "right" }}
                            >
                                <div className="form-group">
                                    <button
                                        id="addAdsImages"
                                        type="submit"
                                        className="btn btn-primary"
                                    >
                                        Add
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ADContentUpload