/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import moment from "moment";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Paginator } from "../../components/paginator/Paginator";
import LoadingCard from "../../components/shared/LoadingCard";
import Message from "../../components/shared/Message";
import { getFestivalCategoryRecord } from "../../store/actions/festiveAction";
import authenticationService from "../../store/services/authenticationService";
import baseUrl from "../../utils/baseUrl";

const FestiveListContainer = () => {

    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const urlSearchParams = new URLSearchParams(location.search);
    const selectedFestivalCategoryListStatus = urlSearchParams.get('status');

    const prevState = location?.state?.state;

    const [globalFilter, setGlobalFilter] = useState('')

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(null);
    const [itemPerPage, setItemPerPage] = useState(prevState?.itemPerPage ? prevState?.itemPerPage : 30);
    const [totalItems, setTotalItems] = useState(null);

    const { festivalCategoryData, festivalCategoryLoading, festivalCategoryError } = useSelector((state) => state.festivalReducer);

    const [festivalStatus, setFestivalStatus] = useState('');
    const [getAll, setGetAll] = useState('');

    useEffect(() => {
        let roleId = authenticationService.getRoleId();
        if (roleId !== "1") {
            history.push("/Login");
        }
        if (history?.location?.state) {
            setCurrentPage(history.location?.state.state.currentPage);
            setItemPerPage(history.location?.state.state.itemPerPage);
        }
    }, [dispatch, history]);

    useEffect(() => {
        if (selectedFestivalCategoryListStatus === "active") {
            dispatch(getFestivalCategoryRecord({
                isActive: 'Y',
                itemsPerPage: itemPerPage,
                currentPage: currentPage,
            }))
        } else if (selectedFestivalCategoryListStatus === "inactive") {
            dispatch(getFestivalCategoryRecord({
                isActive: 'N',
                itemsPerPage: itemPerPage,
                currentPage: currentPage,
            }))
        } else if (selectedFestivalCategoryListStatus === "all") {
            dispatch(getFestivalCategoryRecord({
                getAll: 'Y',
                itemsPerPage: itemPerPage,
                currentPage: currentPage,
            }))
        }
    }, [
        selectedFestivalCategoryListStatus,
        festivalStatus,
        getAll,
        itemPerPage,
        currentPage
    ])

    useEffect(() => {
        if (festivalCategoryData?.headers?.pagination) {
            var paginated_data_to_parse = festivalCategoryData?.headers?.pagination;
            const paginated_data = JSON.parse(paginated_data_to_parse);
            setTotalPage(paginated_data.totalPages);
            setTotalItems(paginated_data.totalItems);
        }
    }, [festivalCategoryData?.headers?.pagination]);

    const handleItemPerPage = (pagePerItems) => {
        setCurrentPage(1);
        setItemPerPage(pagePerItems);
    };

    const handleCurrentPage = (currentPage) => {
        setCurrentPage(currentPage);
    };

    const festivalCategoryNameBodyTemplate = (rowData) => {
        return <React.Fragment>
            <div>
                {rowData?.festivalCategoryName}
            </div>
        </React.Fragment>;
    };

    const breadcrumbFestivalCategoryBodyTemplate = (rowData) => {
        return <React.Fragment>
            <div>
                {rowData?.breadcrumbFestivalCategory}
            </div>
        </React.Fragment>;
    };

    const displayOrderBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {rowData?.displayOrder !== null && rowData?.displayOrder !== undefined ? rowData?.displayOrder : "N/A"}
                </div>
            </React.Fragment>
        );
    }

    const festivalStartDateBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {rowData?.festivalStartDate && rowData.festivalStartDate !== null
                    ? moment(rowData?.festivalStartDate).format("D MMM, YYYY, h:mm A")
                    : "N/A"}
            </React.Fragment>
        );
    }

    const festivalEndDateBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {rowData?.festivalEndDate && rowData.festivalEndDate !== null
                    ? moment(rowData?.festivalEndDate).format("D MMM, YYYY, h:mm A")
                    : "N/A"}
            </React.Fragment>
        );
    }

    const festivalImagePathTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {rowData.festivalImagePath ? (
                        <img
                            src={baseUrl.concat(rowData?.festivalImagePath)}
                            className="product-image"
                            alt="img"
                            style={{
                                verticalAlign: "middle",
                                objectFit: "contain",
                                width: '50px',
                                height: '50px'
                            }}
                        />
                    ) : (
                        "N/A"
                    )}
                </div>
            </React.Fragment>
        );
    };

    const AddProductsBodyTemplate = (rowData) => {
        return (
            <Link to={`/Add-Festival-ProductsList?festivalCatId=${rowData?.festivalCategoryId}`}>
                <div className="button-demo">
                    <Button
                        label="Add to List"
                        className="p-button-primary"
                    />
                </div>
            </Link>
        );
    };

    const viewProductsBodyTemplate = (rowData) => {
        return (
            <Link to={`/View-Festival-ProductsList?festivalCatId=${rowData?.festivalCategoryId}`}>
                <div className="button-demo">
                    <Button
                        label="View List"
                        className="p-button-primary"
                    />
                </div>
            </Link>
        );
    };

    const statusBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span
                    className={
                        rowData?.isActive === "Y"
                            ? "p-tag p-tag-success"
                            : "p-tag p-tag-danger"
                    }
                >
                    {rowData?.isActive === "Y" ? "ACTIVE" : "INACTIVE"}
                </span>
            </React.Fragment>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Link
                    to={{
                        pathname: `/EditFestive`,
                        state: { rowData },
                    }}
                >
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded p-button-help p-mr-2"
                    />
                </Link>
            </React.Fragment>
        );
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Link to="/CreateFestive">
                    <div className="button-demo">
                        <Button
                            icon="pi pi-times"
                            className="p-button-rounded p-button-danger p-button-outlined"
                        />
                    </div>
                </Link>
            </React.Fragment>
        );
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="p-text-bold table-heading-style">
                    List of Festivals
                </div>
            </React.Fragment>
        );
    }

    const renderHeader = () => {
        return (
            <div className="table-header">
                <span className="p-input-icon-left">
                    <InputText
                        type="search"
                        className="form-control text-center text-field"
                        onInput={(e) => setGlobalFilter(e.target.value)}
                        placeholder="Search In Festival List"
                    />
                </span>
            </div>
        );
    }

    return (
        <>
            <ToastContainer autoClose={1500} />
            <div className="page-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                                <div className="datatable-responsive-demo">
                                    <div className="card">
                                        <Toolbar
                                            className="p-mb-4"
                                            right={rightToolbarTemplate}
                                            left={leftToolbarTemplate}
                                        ></Toolbar>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between'
                                        }}>
                                            <ul className="nav nav-tabs seller-tabs">
                                                <li
                                                    onClick={() => {
                                                        setFestivalStatus("Y");
                                                        setGetAll('');
                                                    }}
                                                >
                                                    <Link to={`/FestiveList?status=active`}
                                                        style={
                                                            selectedFestivalCategoryListStatus === "active"
                                                                ? { color: "#1F5DA0", borderBottom: '2px solid #1F5DA0', fontWeight: "700" }
                                                                : null
                                                        }>
                                                        Active Festival
                                                    </Link>
                                                </li>
                                                <li
                                                    onClick={() => {
                                                        setFestivalStatus("N");
                                                        setGetAll('');
                                                    }}
                                                >
                                                    <Link to={`/FestiveList?status=inactive`}
                                                        style={
                                                            selectedFestivalCategoryListStatus === "inactive"
                                                                ? { color: "#1F5DA0", borderBottom: '2px solid #1F5DA0', fontWeight: "700" }
                                                                : null
                                                        }>
                                                        Inactive Festival
                                                    </Link>
                                                </li>
                                                <li
                                                    onClick={() => {
                                                        setFestivalStatus("");
                                                        setGetAll('Y');
                                                    }}
                                                >
                                                    <Link to={`/FestiveList?status=all`}
                                                        style={
                                                            selectedFestivalCategoryListStatus === "all"
                                                                ? { color: "#1F5DA0", borderBottom: '2px solid #1F5DA0', fontWeight: "700" }
                                                                : null
                                                        }>
                                                        All Festivals
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>

                                        {festivalCategoryLoading ? (
                                            <LoadingCard count={1} />
                                        ) : festivalCategoryError ? (
                                            <Message variant="danger">{festivalCategoryError}</Message>
                                        ) :
                                            <DataTable
                                                header={renderHeader}
                                                value={festivalCategoryData?.data}
                                                dataKey="festivalCategoryId"
                                                rowHover
                                                globalFilter={globalFilter}
                                                emptyMessage="No Festival found!"
                                            >
                                                <Column
                                                    header="Festival Category"
                                                    sortField="festivalCategoryName"
                                                    filterField="festivalCategoryName"
                                                    body={festivalCategoryNameBodyTemplate}
                                                    sortable
                                                />

                                                <Column
                                                    header="Festival Breadcrumb"
                                                    sortField="breadcrumbFestivalCategory"
                                                    filterField="breadcrumbFestivalCategory"
                                                    body={breadcrumbFestivalCategoryBodyTemplate}
                                                    sortable
                                                />

                                                <Column
                                                    header="Image"
                                                    sortField="festivalImagePath"
                                                    body={festivalImagePathTemplate}
                                                />

                                                <Column
                                                    header="Festival Start Date"
                                                    sortField="festivalStartDate"
                                                    body={festivalStartDateBodyTemplate}
                                                    sortable
                                                />

                                                <Column
                                                    header="Festival End Date"
                                                    sortField="festivalEndDate"
                                                    body={festivalEndDateBodyTemplate}
                                                    sortable
                                                />

                                                <Column
                                                    header="Display Order"
                                                    sortField="displayOrder"
                                                    filterField="displayOrder"
                                                    body={displayOrderBodyTemplate}
                                                    sortable
                                                />
                                                
                                                {selectedFestivalCategoryListStatus === "all" && <Column
                                                    sortField="isActive"
                                                    header="Status"
                                                    body={statusBodyTemplate}
                                                    sortable
                                                />}

                                                <Column
                                                    header="View Products"
                                                    body={viewProductsBodyTemplate}
                                                />

                                                <Column
                                                    header="Add Product"
                                                    body={AddProductsBodyTemplate}
                                                />

                                                <Column
                                                    field="action"
                                                    header="Edit"
                                                    body={actionBodyTemplate}
                                                />

                                            </DataTable>
                                        }

                                        <Paginator
                                            totalPage={totalPage}
                                            currentPage={currentPage}
                                            itemPerPage={itemPerPage}
                                            totalItems={totalItems}
                                            items={festivalCategoryData?.data}
                                            itemsPerPageOptions={[30, 60, 90]}
                                            handleItemPerPage={handleItemPerPage}
                                            handleCurrentPage={handleCurrentPage}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FestiveListContainer