export const accountsData = [
  {
    accountsEcommerce: [
      {
        categoryName: "DHEU",
        placed: 0,
        cancelled: 0,
        picked: 0,
        delivered: 0,
        salesQty: 0,
        totalMRP: 0,
        discountAmount: 0,
        exchangeAmount: 0,
        grossSales: 0,
        vat75: 0,
        netSales: 0,
      },
      {
        categoryName: "SaRa",
        placed: 0,
        cancelled: 0,
        picked: 0,
        delivered: 0,
        salesQty: 0,
        totalMRP: 0,
        discountAmount: 0,
        exchangeAmount: 0,
        grossSales: 0,
        vat75: 0,
        netSales: 0,
      },
      {
        categoryName: "Total",
        placed: 0,
        cancelled: 0,
        picked: 0,
        delivered: 0,
        salesQty: 0,
        totalMRP: 0,
        discountAmount: 0,
        exchangeAmount: 0,
        grossSales: 0,
        vat75: 0,
        netSales: 0,
      },
    ],
  },
];
