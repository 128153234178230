import { useEffect, useState } from "react";

const useViewPort = () => {
  const [viewportWidth, setViewportWidth] = useState(0);
  const [viewportHeight, setViewportHeight] = useState(0);

  useEffect(() => {
    function handleResize() {
      setViewportWidth(window.innerWidth);

      setViewportHeight(window.innerHeight);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return { viewportWidth, setViewportWidth, viewportHeight, setViewportHeight };
};

export default useViewPort;
