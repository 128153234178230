/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Resizer from "react-image-file-resizer";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import CreateOrUpdateOutlet from '../../components/outlet/CreateOrUpdateOutlet';
import { createOrUpdateOutletRecord, createOrUpdateOutletReset } from '../../store/actions/outletAction';
import authenticationService from '../../store/services/authenticationService';

const EditOutletContainer = () => {

    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const [outletId, setOutletId] = useState('')

    const [outletName, setOutletName] = useState('')
    const [errorOutletName, setErrorOutletName] = useState('')

    const [businessHours, setBusinessHours] = useState('')
    const [businessHoursNote, setBusinessHoursNote] = useState('')

    const [contactNumOne, setContactNumOne] = useState('')
    const [contactNumTwo, setContactNumTwo] = useState('')
    const [contactNumThree, setContactNumThree] = useState('')

    const [outletAddress, setOutletAddress] = useState('')
    const [outletDirection, setOutletDirection] = useState('')

    const [isActive, setIsActive] = useState(true)
    const [outletImage, setOutletImage] = useState('')
    const [showImage, setShowImage] = useState('')

    useEffect(() => {
        let roleId = authenticationService.getRoleId();
        if (roleId === "1") {
            setOutletId(location.state.rowData.outletId);
            setOutletName(location.state.rowData.outletName);
            setBusinessHours(location.state.rowData.businessHours);
            setBusinessHoursNote(location.state.rowData.businessHoursNote);
            setContactNumOne(location.state.rowData.contactNumOne);
            setContactNumTwo(location.state.rowData.contactNumTwo);
            setContactNumThree(location.state.rowData.contactNumThree);

            setOutletAddress(location.state.rowData.address)
            setOutletDirection(location.state.rowData.outletDirection)
            setIsActive(location.state.rowData.isActive === "Y" ? true : false)
            setOutletImage(location.state.rowData.outletImage)
        } else {
            history.push("/Login");
        }
        dispatch(createOrUpdateOutletReset())

    }, [dispatch, history]);

    const { outletCreateOrUpdateData, outletCreateOrUpdateDataLoading } = useSelector((state) => state.outletReducer);

    useEffect(() => {
        if (outletCreateOrUpdateData.data) {
            if (outletCreateOrUpdateData && outletCreateOrUpdateData?.data?.succeed === true) {
                toast.info('Outlet Updated Successfully')
                dispatch(createOrUpdateOutletReset())
                setTimeout(() => {
                    history.push('/OutletList')
                }, 2500)
                resetForm()
            } else if (outletCreateOrUpdateData && outletCreateOrUpdateData?.data?.message === "Save Success") {
                toast.info('Outlet Updated Successfully')
                dispatch(createOrUpdateOutletReset())
                setTimeout(() => {
                    history.push('/OutletList')
                }, 2500)
                resetForm()
            } else if (outletCreateOrUpdateData && outletCreateOrUpdateData?.data?.succeed === false) {
                toast.error('Something went wrong, Please try again')
                dispatch(createOrUpdateOutletReset())
                setTimeout(() => {
                    resetForm()
                }, 2500)
            }
        }
    }, [outletCreateOrUpdateData.data])

    const resetForm = () => {
        setOutletName('')
        setErrorOutletName('')
        setBusinessHours('')
        setBusinessHoursNote('')

        setContactNumOne('')
        setContactNumTwo('')
        setContactNumThree('')

        setOutletAddress('')
        setOutletDirection('')

        setIsActive(true)
        setOutletImage('')
        setShowImage('')
    }

    const handleChange = (e) => {
        const { target } = e
        const { value } = e.target

        switch (target.name) {
            case 'outletName':
                setOutletName(target.value)
                setErrorOutletName(value.length < 5 ? 'At least 5 characters required' : '')
                break
            case 'businessHours':
                setBusinessHours(target.value)
                break
            case 'businessHoursNote':
                setBusinessHoursNote(target.value)
                break
            case 'contactNumOne':
                setContactNumOne(target.value)
                break
            case 'contactNumTwo':
                setContactNumTwo(target.value)
                break
            case 'contactNumThree':
                setContactNumThree(target.value)
                break

            case 'outletAddress':
                setOutletAddress(target.value)
                break
            case 'outletDirection':
                setOutletDirection(target.value)
                break

            case 'outletImage':
                setOutletImage(target.value)
                break
            default:
        }
    }

    const handleIsActiveCheck = (e) => {
        setIsActive(e.target.checked)
    }

    const outletImageHandler = (event) => {
        var width = 650;
        var height = 650;

        const imageFile = event.target.files[0];
        //1)    CHECK IF IT'S A IMAGE
        var fileInput = false;
        if (imageFile) {
            if (!imageFile.name.match(/\.(jpg|jpeg|png|gif|webp)$/)) {
                toast.error("Select a valid image.");
                return false;
            }
            fileInput = true;
            if (fileInput) {
                try {
                    Resizer.imageFileResizer(
                        imageFile,
                        width,
                        height,
                        "JPEG",
                        100,
                        0,
                        (uri) => {
                            setOutletImage(uri)
                            setShowImage(URL.createObjectURL(imageFile))
                            toast.success("Outlet Image Selected.");
                        },
                        "base64",
                        width,
                        height
                    );
                } catch (err) {
                    toast.error("Something went wrong!");
                }
            }
        }
    };

    const saveOutlet = async (e) => {
        e.preventDefault()

        if (outletName === '') {
            let msg = 'Outlet Name is Required!!!'
            toast.error(msg)
            setTimeout(() => { }, 3000)
            return
        }
        if (businessHours === '') {
            let msg = 'Business Hours is Required!!!'
            toast.error(msg)
            setTimeout(() => { }, 3000)
            return
        }
        if (businessHoursNote === '') {
            let msg = 'Business Hours Note is Required!!!'
            toast.error(msg)
            setTimeout(() => { }, 3000)
            return
        }

        if (contactNumOne === '') {
            let msg = 'Contact No. 1 Required!!!'
            toast.error(msg)
            setTimeout(() => { }, 3000)
            return
        }

        if (outletAddress === '') {
            let msg = 'Outlet Address is Required!!!'
            toast.error(msg)
            setTimeout(() => { }, 3000)
            return
        }
        if (outletDirection === '') {
            let msg = 'Outlet Direction is Required!!!'
            toast.error(msg)
            setTimeout(() => { }, 3000)
            return
        }

        if (outletImage === '') {
            let msg = 'Outlet Image is Required!!!'
            toast.error(msg)
            setTimeout(() => { }, 3000)
            return
        }

        const data = {
            outletId: outletId,
            outletName: outletName,
            address: outletAddress,
            businessHours: businessHours,
            businessHoursNote: businessHoursNote,
            contactNumOne: contactNumOne,
            contactNumTwo: contactNumTwo,
            contactNumThree: contactNumThree,
            outletImage: outletImage,
            outletDirection: outletDirection,
            isActive: isActive === true ? 'Y' : 'N',
        }

        dispatch(createOrUpdateOutletRecord(data))
    }
    return (
        <div id='wrapper'>
            <CreateOrUpdateOutlet
                type={2}
                key='EditOutlet'
                name='Edit Outlet'
                saveOutlet={saveOutlet}
                handleChange={handleChange}
                outletName={outletName}
                errorOutletName={errorOutletName}

                businessHours={businessHours}
                businessHoursNote={businessHoursNote}
                contactNumOne={contactNumOne}
                contactNumTwo={contactNumTwo}
                contactNumThree={contactNumThree}

                outletAddress={outletAddress}
                outletDirection={outletDirection}

                handleIsActiveCheck={handleIsActiveCheck}
                isActive={isActive}

                outletImage={outletImage}
                showImage={showImage}
                outletImageHandler={outletImageHandler}

                outletCreateOrUpdateDataLoading={outletCreateOrUpdateDataLoading}
            />
            <ToastContainer autoClose={1500} />
        </div>
    )
}

export default EditOutletContainer