import React from 'react';
import "../css/POPrintAdmin.css";
import PurchaseOrderGeneratePDFView from './PurchaseOrderGeneratePDF';

const POPrintAdmin = (props) => {
    const navigate = () => {
        window.location.href = `${props?.location?.previousPath?.pathname ? `${props?.location?.previousPath?.pathname}${props?.location?.previousPath?.search}` : '/PurchaseOrderList?page=1'}`;
    };

    return (
        <>
            <span
                className="seller_invoice__print_btn2"
            >
                <button className="btn btn-primary" onClick={() => window.print()}>
                    Print
                </button>
                <button className="btn btn-danger"
                    onClick={navigate}
                >
                    Close
                </button>
            </span>
            <div
                className="seller__print"
                style={{ marginRight: '1vw' }}
            >
                {props.location.selectedPOProductsList && props.location.selectedPOProductsList.map((data, index) => (
                    <>
                        <PurchaseOrderGeneratePDFView data={data} key={index} />
                    </>
                ))}
            </div>
        </>
    )
}

export default POPrintAdmin