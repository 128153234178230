/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { ToastContainer } from "react-toastify";

const CreateProductAttribute = ({
  categoryId = 0,
  productVariant = [],
  handleAttributeChanges,
  selectedAttributes = [],
  setSelectedAttributes,
}) => {
  const [attribute, setAttribute] = useState([]);
  const animatedComponents = makeAnimated();

  const handleAttributeChange = (selectOption, event, variantName) => {
    if (
      event?.action === "select-option" &&
      typeof setSelectedAttributes === "function"
    ) {
      setSelectedAttributes([...selectedAttributes, event?.option]);
    }
    if (
      event?.action === "remove-value" &&
      typeof setSelectedAttributes === "function"
    ) {
      setSelectedAttributes(
        selectedAttributes?.filter(
          (ar) => ar.label !== event?.removedValue?.label
        )
      );
    }

    if (
      event.action === "clear" &&
      typeof setSelectedAttributes === "function"
    ) {
      setSelectedAttributes(
        selectedAttributes?.filter((ar) => ar.filterName !== variantName)
      );
    }

    var newArray = [];

    for (var i = 0; i < attribute.length; i++) {
      if (attribute[i].filterName !== variantName) {
        newArray.push(attribute[i]);
      }
    }

    if (selectOption) {
      newArray = newArray.concat(selectOption);
    }
    if (newArray.length === 0) {
      setAttribute([]);
    } else {
      setAttribute(newArray);
    }
  };

  useEffect(() => {
    if (typeof handleAttributeChanges === "function") {
      handleAttributeChanges(attribute);
    }
  }, [attribute, handleAttributeChanges]);

  const defaultSelected = (variant) => {
    const d_select =
      selectedAttributes &&
      selectedAttributes.filter((s_arr) => s_arr.filterName === variant);

    return d_select;
  };


  return (
    <div>
      <label
        style={{
          width: "100%",
        }}
        className="control_label"
      >
        Attributes
      </label>
      {categoryId === 1076 ? null : (
        <form>
          <div className="price_and_stock">
            <div className="form-group">
              <div className="row">
                <div className="col-md-12">
                  <table className="table mt-30" id="tableImg">
                    <tbody>
                      <tr
                        style={
                          productVariant.filter(
                            (variantData) =>
                              variantData.variantSetupTempleteId === 2
                          ).length > 2
                            ? {
                                display: "grid",
                                gridTemplateColumns:
                                  "repeat(3, minmax(0, 1fr))",
                              }
                            : productVariant.filter(
                                (variantData) =>
                                  variantData.variantSetupTempleteId === 2
                              ).length > 1
                            ? {
                                display: "grid",
                                gridTemplateColumns:
                                  "repeat(2, minmax(0, 1fr))",
                              }
                            : {
                                display: "grid",
                                gridTemplateColumns:
                                  "repeat(1, minmax(0, 1fr))",
                              }
                        }
                      >
                        {productVariant
                          .filter(
                            (variantData) =>
                              variantData.variantSetupTempleteId === 2
                          )
                          .map((variant, index) => {
                            return (
                              <td key={index}>
                                <Select
                                  name={`field${index}`}
                                  placeholder={`Select ${variant.variantName}`}
                                  defaultValue={defaultSelected(
                                    variant.variantName
                                  )}
                                  options={variant.productVariantOptions.map(
                                    (data) => ({
                                      filterName: variant.variantName,
                                      label: data.variantOptionText,
                                      value: data.variantOptionId,
                                    })
                                  )}
                                  components={animatedComponents}
                                  isMulti
                                  isSearchable
                                  onChange={(selectTag, e) =>
                                    handleAttributeChange(
                                      selectTag,
                                      e,
                                      variant.variantName
                                    )
                                  }
                                />
                              </td>
                            );
                          })}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
      <ToastContainer autoClose={1500} />
    </div>
  );
};

export default CreateProductAttribute;
