import http from "../../utils/httpClient";

class storeService {

  //   getContentType = async () => {
  //     return await http.get(`/api/Content/GetContentType?getAll=Y`);
  //   };
  createOrUpdateStoreBanner = async (data) => {
    return await http.post("/api/Seller/CreateOrUpdateSellerStoreBannerSetup", data)
  };

  getStoreBanner = async () => {
    return await http.get(`/api/Seller/GetSellerStoreBannerSetup?getAll=Y`);
  };

  getStoreBannerByShopId = async (shopId) => {
    return await http.get(`/api/Seller/GetSellerStoreBannerSetup?sellerShopId=${shopId}`);
  };

}
export default new storeService();
