/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { FaList, FaPlusCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import AddCircular from "../../components/career/AddCircular";
import ListOfCareer from "../../components/career/ListOfCareer";
import {
  POST_JOB_CIRCULAR_RESET,
  listOfJobCircularRecord,
  postJobCircularRecord,
  singleJobCircularRecord,
} from "../../store/actions/careerAction";
import toIsoString from "../../utils/Functions/toIsoString";
import "./Career.css";

const job_nature = [
  { id: 1, type: "Full-Time" },
  { id: 2, type: "Part-Time" },
  { id: 3, type: "Contractual" },
];
const company_name = [
  { id: 1, name: "SaRa Lifestyle Ltd." },
  { id: 2, name: "Snowtex" },
];

const Career = () => {
  const { state } = useLocation();
  const history = useHistory();

  const [goPage, setGoPage] = useState(state?.goToPage);
  const [isJobFilter, setJobFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    isJobFilter ? 1 : goPage ? goPage : 1
  );

  const [careerPageOptions, setCareerPageOptions] = useState(1);

  const [title, setTitle] = useState("");
  const [position, setPosition] = useState("");
  const [noOfVacancy, setNoOfVacancy] = useState("");
  const [jobNature, setJobNature] = useState("");
  const [experience, setExperience] = useState("");
  const [company, setCompany] = useState("");
  const [salary, setSalary] = useState("");
  const [isLocation, setLocation] = useState("");
  const [deadLine, setDeadline] = useState("");
  const [requirements, setRequirements] = useState("");
  const [additionalRequirements, setAdditionalRequirements] = useState("");
  const [benefits, setBenefits] = useState("");
  const [status, setStatus] = useState(true);
  const [circularId, setCircularId] = useState(null);
  const [isActive, setIsActive] = useState("Y");
  const [careerEditModal, setCareerEditModal] = useState(false);
  const [itemPerPage, setItemPerPage] = useState(20);

  const [totalPage, setTotalPage] = useState(null);
  const [totalItems, setTotalItems] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [positionFilter, setPositionFilter] = useState("");
  const [isActiveFilter, setIsActiveFilter] = useState(null);

  const dispatch = useDispatch();

  var current_date = new Date();
  const currentDate =
    current_date !== null ? toIsoString(current_date).substring(0, 10) : null;
  const currentTime =
    current_date !== null ? toIsoString(current_date).substring(10, 19) : null;
  var current_date_time = currentDate + currentTime;

  const handleJobCircularSubmit = () => {
    var data_to_post = {
      position: position,
      circulationTitle: title,
      noOfVecency: Number(noOfVacancy),
      experience: experience,
      jobNature: jobNature.type,
      salary: salary,
      publishDateTime: current_date_time,
      deadLine: deadLine,
      requirement: requirements,
      additionalReq: additionalRequirements,
      benefit: benefits,
      companyName: company.name,
      location: isLocation,
      isActive: isActive,
    };
    dispatch(postJobCircularRecord(data_to_post));
  };
  const handleJobCircularUpdate = () => {
    var data_to_update = {
      careerCirculationId: circularId,
      position: position,
      circulationTitle: title,
      noOfVecency: Number(noOfVacancy),
      experience: experience,
      jobNature: jobNature.type ? jobNature.type : jobNature,
      salary: salary,
      publishDateTime: current_date_time,
      // deadLine: deadLine?._d ? deadLine : deadLine,
      deadLine: deadLine,
      requirement: requirements,
      additionalReq: additionalRequirements,
      benefit: benefits,
      companyName: company.name ? company.name : company,
      location: isLocation,
      isActive: isActive,
    };
    dispatch(postJobCircularRecord(data_to_update));
  };
  const {
    postJobCircular,
    loading,
    error,
    jobCircularList,
    singleJobCircular,
  } = useSelector((state) => state.careerReducer);

  useEffect(() => {
    if (postJobCircular?.data?.message === "Update Success") {
      toast.success("Circular Updated");
      dispatch({ type: POST_JOB_CIRCULAR_RESET });
      setTimeout(() => {
        setTitle("");
        setPosition("");
        setNoOfVacancy("");
        setJobNature("");
        setExperience("");
        setCompany("");
        setSalary("");
        setLocation("");
        setDeadline("");
        setRequirements("");
        setAdditionalRequirements("");
        setBenefits("");
        setCareerPageOptions(1);
        setStatus(!status);
        setIsActive("Y");
        setCareerEditModal(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 1500);
    } else if (postJobCircular?.data?.message === "Save Success") {
      toast.success("New Circular Posted");
      dispatch({ type: POST_JOB_CIRCULAR_RESET });
      setTimeout(() => {
        setTitle("");
        setPosition("");
        setNoOfVacancy("");
        setJobNature("");
        setExperience("");
        setCompany("");
        setSalary("");
        setLocation("");
        setDeadline("");
        setRequirements("");
        setAdditionalRequirements("");
        setBenefits("");
        setCareerPageOptions(1);
        setStatus(!status);
        setIsActive("Y");
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 1500);
    } else if (error !== null && careerPageOptions === 2) {
      toast.error("Something Went Wrong Please Try Again");
      dispatch({ type: POST_JOB_CIRCULAR_RESET });
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 1500);
    }
  }, [postJobCircular, error]);

  useEffect(() => {
    const data = {
      startDate: startDate && toIsoString(startDate).substring(0, 10),
      endDate: endDate && toIsoString(endDate).substring(0, 10),
      isActive: isActiveFilter?.code,
      currentPage,
      itemsPerPage: itemPerPage,
    };

    if (positionFilter) {
      data.positionFilter = positionFilter;
    }

    dispatch(listOfJobCircularRecord(data));
    return () => setJobFilter(false);
  }, [dispatch, isJobFilter, currentPage, itemPerPage]);

  const handleSinglePost = (id) => {
    setCircularId(id);
    dispatch(singleJobCircularRecord(id));
  };

  useEffect(() => {
    if (goPage) {
      setCurrentPage(goPage);
      history.push({ state: {} });
    }
  }, []);

  return (
    <div className="page-wrapper">
      <div className="container-fluid">
        <div className="panel panel-success">
          <div className="panel-heading panel_heading__section">
            <span>Career</span>
            <span className="career__options_btn">
              {careerPageOptions === 1 ? (
                <button onClick={() => setCareerPageOptions(2)}>
                  <FaPlusCircle fontSize="1.9rem" /> <span>Add Circular</span>
                </button>
              ) : (
                <button onClick={() => setCareerPageOptions(1)}>
                  <FaList fontSize="1.9rem" /> <span>Circular List</span>
                </button>
              )}
            </span>
          </div>
          <div className="panel-wrapper collapse in" aria-expanded="true">
            <div className="panel-body">
              {careerPageOptions === 2 ? (
                <AddCircular
                  title={title}
                  setTitle={setTitle}
                  position={position}
                  setPosition={setPosition}
                  noOfVacancy={noOfVacancy}
                  setNoOfVacancy={setNoOfVacancy}
                  jobNature={jobNature}
                  setJobNature={setJobNature}
                  experience={experience}
                  setExperience={setExperience}
                  company={company}
                  setCompany={setCompany}
                  salary={salary}
                  setSalary={setSalary}
                  location={isLocation}
                  setLocation={setLocation}
                  deadLine={deadLine}
                  setDeadline={setDeadline}
                  requirements={requirements}
                  setRequirements={setRequirements}
                  additionalRequirements={additionalRequirements}
                  setAdditionalRequirements={setAdditionalRequirements}
                  benefits={benefits}
                  setBenefits={setBenefits}
                  handleJobCircularSubmit={handleJobCircularSubmit}
                  loading={loading}
                  job_nature={job_nature}
                  company_name={company_name}
                />
              ) : (
                <>
                  <ListOfCareer
                    loading={loading}
                    jobCircularList={jobCircularList}
                    handleSinglePost={handleSinglePost}
                    singleJobCircular={singleJobCircular}
                    setTitle={setTitle}
                    handleJobCircularUpdate={handleJobCircularUpdate}
                    setPosition={setPosition}
                    setNoOfVacancy={setNoOfVacancy}
                    job_nature={job_nature}
                    company_name={company_name}
                    jobNature={jobNature}
                    setJobNature={setJobNature}
                    setExperience={setExperience}
                    company={company}
                    setCompany={setCompany}
                    setSalary={setSalary}
                    setLocation={setLocation}
                    setDeadline={setDeadline}
                    deadLine={deadLine}
                    setRequirements={setRequirements}
                    setAdditionalRequirements={setAdditionalRequirements}
                    setBenefits={setBenefits}
                    setIsActive={setIsActive}
                    careerEditModal={careerEditModal}
                    setCareerEditModal={setCareerEditModal}
                    title={title}
                    position={position}
                    noOfVacancy={noOfVacancy}
                    salary={salary}
                    location={isLocation}
                    requirements={requirements}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    itemPerPage={itemPerPage}
                    setItemPerPage={setItemPerPage}
                    totalPage={totalPage}
                    setTotalPage={setTotalPage}
                    totalItems={totalItems}
                    setTotalItems={setTotalItems}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    setPositionFilter={setPositionFilter}
                    setIsActiveFilter={setIsActiveFilter}
                    startDate={startDate}
                    endDate={endDate}
                    positionFilter={positionFilter}
                    isActiveFilter={isActiveFilter}
                    // handleCircularFilter={handleCircularFilter}
                    setJobFilter={setJobFilter}
                    isJobFilter={isJobFilter}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={1500} />
    </div>
  );
};

export default Career;
