/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { getBrandRecord } from "../../store/actions/brandAction";
import { getCategoryRecord } from "../../store/actions/categoryAction";
import authenticationService from "../../store/services/authenticationService";
import { 
    addOrEditFestivalProductListRecord, 
    addOrEditFestivalProductListReset, 
    getAddibleFestivalProductsRecord 
} from "../../store/actions/festiveAction";
import { FestivalAddProductListTable } from "../../components/festival/FestivalAddProductListTable";

export const AddFestivalProductListContainer = () => {

    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const urlSearchParams = new URLSearchParams(location.search);
    const selectedFestivalCatId = Number(urlSearchParams.get('festivalCatId'));

    const prevState = location?.state?.state;

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(null);
    const [itemPerPage, setItemPerPage] = useState(prevState?.itemPerPage ? prevState?.itemPerPage : 30);
    const [totalItems, setTotalItems] = useState(null);

    const [searchKeyword, setSearchKeyword] = useState(prevState?.searchKeyword ? prevState?.searchKeyword : "");

    const [category, setCategory] = useState(prevState?.category ? prevState?.category : null);
    const [brand, setBrand] = useState(prevState?.brand ? prevState?.brand : null);
    const [vendor, setVendor] = useState(prevState?.vendor ? prevState?.vendor : null);
    const [isFilter, setIsFilter] = useState(false);

    const [selectedAddFestivalProducts, setSelectedAddFestivalProducts] = useState([]);
    const [selectedAddFestivalProductIds, setSelectedAddFestivalProductIds] = useState([]);

    const categories = useSelector((state) => state.categoryReducer.categories);
    const brands = useSelector((state) => state.brandReducer.brands);
   
    const { 
        getAddibleFestivalProductsListData, 
        getAddibleFestivalProductsListDataLoading,
        festivalProductListData 
    } = useSelector((state) => state.festivalReducer);
    
    useEffect(() => {
        if (history?.location?.state) {
            setCurrentPage(history.location?.state.state.currentPage);
            setItemPerPage(history.location?.state.state.itemPerPage);
        }
    }, []);

    useEffect(() => {
        const selectedShopId = vendor?.map(item => item?.value).join(',');

        const productInfo = {
            categoryId: category?.categoryId,
            shopId: selectedShopId,
            brandId: brand?.brandId,
            productStatus: 'Y',
            isApprove: 'Y',
            isDeleted: 'N',
            currency: 'BDT',
            itemsPerPage: itemPerPage,
            currentPage: currentPage,
            festivalId: selectedFestivalCatId
        }
        if (searchKeyword) {
            productInfo.keywords = searchKeyword;
            productInfo.currentPage = 1;
        }

        dispatch(getAddibleFestivalProductsRecord(productInfo))

        if (isFilter) {
            const url = `/Add-Festival-ProductsList?festivalCatId=${selectedFestivalCatId}`;
            history.push(url, { state: { searchKeyword, brand, category, vendor } })
        }
        return () => setIsFilter(false)

    }, [dispatch, isFilter, currentPage, itemPerPage, festivalProductListData])

    useEffect(() => {
        if (festivalProductListData.data) {
            if (festivalProductListData && festivalProductListData?.data?.succeed === true) {
                toast.success('Product Added to Festival Successfully')
                dispatch(addOrEditFestivalProductListReset())
                setSelectedAddFestivalProducts([])
                setTimeout(() => {
                    history.push(`/Add-Festival-ProductsList?festivalCatId=${selectedFestivalCatId}`)
                }, 2500)
            } else if (festivalProductListData && festivalProductListData?.data?.message === "Save Success") {
                toast.success('Product Added to Festival Successfully')
                dispatch(addOrEditFestivalProductListReset())
                setSelectedAddFestivalProducts([])
                setTimeout(() => {
                    history.push(`/Add-Festival-ProductsList?festivalCatId=${selectedFestivalCatId}`)
                }, 2500)
            } else if (festivalProductListData && festivalProductListData?.data?.succeed === false) {
                toast.error('Something went wrong, Please try again')
                dispatch(addOrEditFestivalProductListReset())
                setSelectedAddFestivalProducts([])
                setTimeout(() => {
                    history.push(`/Add-Festival-ProductsList?festivalCatId=${selectedFestivalCatId}`)
                }, 2500)
            }
        }
    }, [festivalProductListData.data])

    const handleItemPerPage = (pagePerItems) => {
        setCurrentPage(1);
        setItemPerPage(pagePerItems);
    };

    const handleCurrentPage = (currentPage) => {
        if (isFilter) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage);
        }
    };

    useEffect(() => {
        dispatch(getCategoryRecord());
        dispatch(getBrandRecord());
    }, []);

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="p-text-bold table-heading-style">
                    Add Products in Festival
                </div>
            </React.Fragment>
        );
    }

    const rightToolbarTemplate = () => {
        let roleId = authenticationService.getRoleId();
        return (
            <React.Fragment>
                <Link to={roleId == 1 ? "/FestiveList?status=active" : "/FestiveList?status=active"}>
                    <div className="button-demo">
                        <Button
                            icon="pi pi-times"
                            className="p-button-rounded p-button-danger p-button-outlined"
                        />
                    </div>
                </Link>
            </React.Fragment>
        );
    }

    useEffect(() => {
        let tempProductIdString = ''
        if (selectedAddFestivalProducts.length > 0) {
            selectedAddFestivalProducts.map(data => {
                tempProductIdString = tempProductIdString + data?.productId + ','
            })
            setSelectedAddFestivalProductIds(tempProductIdString.substring(0, tempProductIdString.length - 1))
        } else {
            setSelectedAddFestivalProductIds('')
        }
    }, [selectedAddFestivalProducts])

    const UpdateProductListHandler = () => {
        const data = {
            festivalId: selectedFestivalCatId,
            productIds: selectedAddFestivalProductIds,
            isActive: 'Y',
        }
        dispatch(addOrEditFestivalProductListRecord(data))
    }

    return (
        <>
            <ToastContainer autoClose={2000} />
            <div className="page-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="white-box">
                                <div className="datatable-doc-demo datatable-responsive-demo">
                                    <div className="card">
                                        <Toolbar
                                            className="p-mb-4"
                                            right={rightToolbarTemplate}
                                            left={leftToolbarTemplate}
                                        ></Toolbar>
                                        <FestivalAddProductListTable
                                            product_list={getAddibleFestivalProductsListData}
                                            handleItemPerPage={handleItemPerPage}
                                            handleCurrentPage={handleCurrentPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            totalPage={totalPage}
                                            setTotalPage={setTotalPage}
                                            itemPerPage={itemPerPage}
                                            totalItems={totalItems}
                                            setTotalItems={setTotalItems}
                                            setSearchKeyword={setSearchKeyword}
                                            searchKeyword={searchKeyword}
                                            categories={categories}
                                            setCategory={setCategory}
                                            category={category}
                                            brands={brands}
                                            setBrand={setBrand}
                                            brand={brand}
                                            vendor={vendor}
                                            setVendor={setVendor}
                                            loading={getAddibleFestivalProductsListDataLoading}
                                            setIsFilter={setIsFilter}
                                            selectedAddFestivalProducts={selectedAddFestivalProducts}
                                            setSelectedAddFestivalProducts={setSelectedAddFestivalProducts}
                                            UpdateProductListHandler={UpdateProductListHandler}
                                            isAdmin={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

