import React, { Component } from "react";
import { connect } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import SellerLogin from "../../components/seller/SellerLogin";
import * as sellerAction from "../../store/actions/sellerAction";
import authenticationService from "../../store/services/authenticationService";

class sellerLoginContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userEmail: "",
      userPass: "",
      chkRemember: false,
      user: {
        userEmail: "",
        employeeId: "",
      },
    };

    this.submitLogin = this.submitLogin.bind(this);
  }

  componentDidMount = async () => {
    //Begin Temporary Authentication
    let check = localStorage.getItem("seller-remember");
    // this.props.history.push("/SellerHome")

    if (check && check === "Y") {
      this.props.history.push("/SellerHome");
    }
    //End Temporary Authentication

    //Begin Temporary Authentication
    let roleId = authenticationService.getRoleId();
    if (roleId === "1") {
      this.props.history.push("/Home");
    } else if (roleId === "2") {
      this.props.history.push("/SellerHome");
    }
  };

  // This checks ENTER key (13), then checks if next node is an INPUT
  // Then focuses next input box
  handleKeyPress(event) {
    if (event.keyCode === "ENTER") {
      event.preventDefault(); // Prevent form submission if button present
      this.refs[event].focus();
    }
  }

  submitLogin = async (e) => {
    // event.preventDefault();

    this.setState({ submiting: true });

    const data = {
      email: e.email,
      password: e.password,
      check: e.check,
    };

    const response = await this.props.SellerLogInAuth(data);

    if (response.type === "SELLER_LOGIN_ERROR") {
      toast.error("Invalid Email or Password!");
    } else if (
      response.payload.success &&
      response.payload.success?.data?.token &&
      response.payload.success?.data?.user?.employeeId
    ) {
      toast.success("Login successful! Redirecting to Home,now...");
     
      localStorage.setItem('x-access-roleId', response.payload.success?.data?.user?.roleId)
      localStorage.setItem('x-access-employeeId', response.payload.success?.data?.user?.employeeId)
      localStorage.setItem('x-access-token', response.payload.success?.data?.token)
      localStorage.setItem(
        'x-access-token-expiration',
        Date.now() + 2 * 60 * 60 * 1000 //expires in 2 hours
      )

      this.props.history.push("/SellerHome");
      window.location.reload();
    } else {
      if (response?.payload?.success?.data?.errors[0] == 'Too many attempts. Your account is temporarily locked. Please try again in a few moments.') {
        toast.error(response?.payload?.success?.data?.errors[0]);
      }
      else {
        toast.error("Something went wrong! Try again later");
      }
    }
  };

  render() {
    return (
      <div id="wrapper">
        <SellerLogin
          key="SellerLogin"
          {...this.state}
          submitLogin={this.submitLogin}
          loadingLogin={this.props.loadingLogin}
        />
        <ToastContainer autoClose={1500} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loadingLogin: state.sellerReducer.loading
});

const mapDispatchToProps = (dispatch) => {
  return {
    SellerLogInAuth: (userData) =>
      dispatch(sellerAction.SellerLogInAuth(userData)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(sellerLoginContainer);
