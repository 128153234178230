/* eslint-disable react-hooks/exhaustive-deps */
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { getPaymentMethodRecord } from "../../store/actions/paymentMethodAction";
import { getShopRecord } from "../../store/actions/shopAction";
import "./report.css";
import MultipleShopSelect from "../shared/common_used/MultipleShopSelection";

const TransactionFilterProperties = (props) => {
  const animatedComponents = makeAnimated();

  const {
    handleChange,
    sku,
    orderStatus,
    orderSource,
    paymentStatus,
    paymentMethod,
    startDate,
    endDate,
    handleDateChange,
    order_filter_tab,
    handleMultiPaymentMethodSelectChange,
    paymentMethodNames,
    setShopNameIds
  } = props;

  const dispatch = useDispatch();
  const [paymentMethodLists, setPaymentMethodLists] = useState([]);

  let year = new Date().toLocaleDateString("en-us", {
    year: "numeric",
  });

  const orderSourceOptions = [
    {
      OrderSource: "Website",
    },
    {
      OrderSource: "Facebook",
    },
    {
      OrderSource: "Instagram",
    },
    {
      OrderSource: "Email",
    },
    {
      OrderSource: "Whats App",
    },
    {
      OrderSource: "Over Phone Call",
    },
    {
      OrderSource: "SaRa Office Employee",
    },
    {
      OrderSource: "Snowtex Office Employee",
    },
    {
      OrderSource: "Shop Employee",
    },
    {
      OrderSource: "Other",
    },
  ];

  const allOrderStatus = [
    {
      OrderStatusNameId: 1,
      OrderStatusName: "Pending",
    },
    {
      OrderStatusNameId: 2,
      OrderStatusName: "Order Confirm",
    },
    {
      OrderStatusNameId: 3,
      OrderStatusName: "Order Processing",
    },
    {
      OrderStatusNameId: 4,
      OrderStatusName: "Ready to Ship",
    },
    {
      OrderStatusNameId: 5,
      OrderStatusName: "Picked",
    },
    {
      OrderStatusNameId: 6,
      OrderStatusName: "Delivered",
    },
    {
      OrderStatusNameId: 7,
      OrderStatusName: "Delivery Failed",
    },
    {
      OrderStatusNameId: 8,
      OrderStatusName: "Cancel Request",
    },
    {
      OrderStatusNameId: 9,
      OrderStatusName: "Cancel Confirm",
    },
    {
      OrderStatusNameId: 10,
      OrderStatusName: "Return Request",
    },
    {
      OrderStatusNameId: 11,
      OrderStatusName: "Parcel Pickup Pending",
    },
    {
      OrderStatusNameId: 12,
      OrderStatusName: "Parcel Returning",
    },
    {
      OrderStatusNameId: 13,
      OrderStatusName: "Returned",
    },
    {
      OrderStatusNameId: 14,
      OrderStatusName: "Refund Processing",
    },
    {
      OrderStatusNameId: 15,
      OrderStatusName: "Refund",
    },
    {
      OrderStatusNameId: 17,
      OrderStatusName: "Canceled",
    },
  ];

  const { paymentMethods } = useSelector(
    (state) => state?.paymentMethodReducer
  );
 
  useEffect(() => {
    dispatch(getPaymentMethodRecord());

  }, [dispatch]);

  useEffect(() => {
    setPaymentMethodLists(
      paymentMethods.map((data) => ({
        label: data?.methodName,
        value: data?.paymentMethodId,
      }))
    );
  }, [paymentMethods]);

  return (
    <>
      <div className="option-attribute-container">
        {/* Start Date Picker */}
        <div className="option-attributes">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="form-group">
                <label className="control_label" htmlFor="basic">
                  {order_filter_tab === "transaction_history"
                    ? "Transaction "
                    : ""}
                  Start Date{" "}
                  <span
                    aria-hidden="true"
                    style={{
                      color: "red",
                      fontWeight: "bold",
                    }}
                  >
                    *
                  </span>
                </label>
                <div className="calender-container">
                  <Calendar
                    monthNavigator
                    yearNavigator
                    yearRange={`2016:${year}`}
                    id="basic"
                    maxDate={endDate}
                    value={startDate}
                    onChange={(e) => handleDateChange(e.value, "start")}
                    showIcon
                    placeholder={`Pick ${order_filter_tab === "transaction_history"
                      ? "Transaction "
                      : ""
                      }Start Date`}
                  />
                  {startDate !== null ? (
                    <button
                      style={{
                        position: "absolute",
                        top: "6px",
                        right: "33px",
                        borderRadius: "100%",
                        border: "1px solid #ced4da",
                        height: "25px",
                        width: "25px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => {
                        handleDateChange(null, "start");
                      }}
                    >
                      <i className="pi pi-times"></i>
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* End Date Picker */}
        <div className="option-attributes">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="form-group">
                <label className="control_label">
                  {order_filter_tab === "transaction_history"
                    ? "Transaction "
                    : ""}
                  End Date{" "}
                  <span
                    aria-hidden="true"
                    style={{
                      color: "red",
                      fontWeight: "bold",
                    }}
                  >
                    *
                  </span>
                </label>
                <div className="calender-container">
                  <Calendar
                    monthNavigator
                    yearNavigator
                    yearRange={`2016:${year}`}
                    id="icon"
                    minDate={startDate}
                    value={endDate}
                    onChange={(e) => handleDateChange(e.value, "end")}
                    showIcon
                    placeholder={`Pick ${order_filter_tab === "transaction_history"
                      ? "Transaction "
                      : ""
                      }End Date`}
                  />

                  {endDate !== null ? (
                    <button
                      style={{
                        position: "absolute",
                        top: "6px",
                        right: "33px",
                        borderRadius: "100%",
                        border: "1px solid #ced4da",
                        height: "25px",
                        width: "25px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => {
                        handleDateChange(null, "end");
                      }}
                    >
                      <i className="pi pi-times"></i>
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Shop Name Dropdown */}
        <div className="option-attributes">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="form-group">
                <label className="control_label">Shop Name </label>
                <div>
                   <MultipleShopSelect setShopNameIds={setShopNameIds} />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Payment Method Dropdown */}
        <div className="option-attributes">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="form-group">
                <label className="control_label">Payment Method Name </label>
                <div>
                  <Select
                    options={paymentMethodLists}
                    components={animatedComponents}
                    placeholder={"Select Payment Method Name"}
                    isMulti
                    isSearchable
                    onChange={handleMultiPaymentMethodSelectChange}
                    value={paymentMethodNames}
                    closeMenuOnSelect={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Payment Status Dropdown */}
        {/* <div className="option-attributes">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="form-group">
                <label className="control_label">Payment Status </label>
                <div>
                  <Dropdown
                    value={paymentStatus}
                    options={allPaymentStatus}
                    onChange={handleChange}
                    optionLabel="PaymentStatusName"
                    className="drop-control"
                    name="paymentStatusName"
                    placeholder={"Select Payment Status"}
                    filter
                    filterBy="PaymentStatusName"
                  />
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* Order Source Dropdown */}
        {order_filter_tab === "order" && (
          <div className="option-attributes">
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="form-group">
                  <label className="control_label">Order Source </label>
                  <div>
                    <Dropdown
                      value={orderSource}
                      options={orderSourceOptions}
                      onChange={handleChange}
                      optionLabel="OrderSource"
                      className="drop-control"
                      name="orderSourceName"
                      placeholder={"Select Order Source"}
                      filter
                      filterBy="OrderSource"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Order Status Dropdown */}
        {order_filter_tab === "order" && (
          <div className="option-attributes">
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="form-group">
                  <label className="control_label">Order Status </label>
                  <div>
                    <Dropdown
                      value={orderStatus}
                      options={allOrderStatus}
                      onChange={handleChange}
                      optionLabel="OrderStatusName"
                      className="drop-control"
                      name="orderStatusName"
                      placeholder={"Select Order Status"}
                      filter
                      filterBy="OrderStatusName"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TransactionFilterProperties;
