const capitalizeWords = (inputString) => {
    // Split the string into an array of words
    let wordsArray = inputString.split(' ');

    // Capitalize the first letter of each word
    let capitalizedWordsArray = wordsArray.map(word => word.charAt(0).toUpperCase() + word.slice(1));

    // Join the words back into a single string
    let resultString = capitalizedWordsArray.join(' ');

    return resultString;
}

export default capitalizeWords;