import purchaseOrderService from "../services/purchaseOrderService"

export const GET_PO_PRODUCT_LIST = "GET_PO_PRODUCT_LIST"
export const GET_PO_PRODUCT_LIST_SUCCESS = "GET_PO_PRODUCT_LIST_SUCCESS"
export const GET_PO_PRODUCT_LIST_ERROR = "GET_PO_PRODUCT_LIST_ERROR"

export const CREATE_PO = "CREATE_PO"
export const CREATE_PO_SUCCESS = "CREATE_PO_SUCCESS"
export const CREATE_PO_ERROR = "CREATE_PO_ERROR"
export const CREATE_PO_RESET = "CREATE_PO_RESET"

export const GET_PO_LIST = "GET_PO_LIST"
export const GET_PO_LIST_SUCCESS = "GET_PO_LIST_SUCCESS"
export const GET_PO_LIST_ERROR = "GET_PO_LIST_ERROR"



export function getPOProductListRecord(query) {
    return async (dispatch) => {
        dispatch(getPOProductList())
        return await purchaseOrderService
            .getPOProductList(query)
            .then((response) => dispatch(getPOProductListSuccess(response)))
            .catch((error) => dispatch(getPOProductListError(error)))
    }
}

export function getPOProductList() {
    return {
        type: GET_PO_PRODUCT_LIST,
    }
}

export function getPOProductListSuccess(success) {
    return {
        type: GET_PO_PRODUCT_LIST_SUCCESS,
        payload: { success },
    }
}

export function getPOProductListError(error) {
    return {
        type: GET_PO_PRODUCT_LIST_ERROR,
        payload: { error },
    }
}

export function createPORecord(POData) {
    return async (dispatch) => {
        dispatch(createPO())
        return await purchaseOrderService
            .createPO(POData)

            .then((response) => dispatch(createPOSuccess(response)))
            .catch((error) => dispatch(createPOError(error)))
    }
}

export function createPO() {
    return {
        type: CREATE_PO,
    }
}

export function createPOSuccess(success) {
    return {
        type: CREATE_PO_SUCCESS,
        payload: { success },
    }
}

export function createPOError(error) {
    return {
        type: CREATE_PO_ERROR,
        payload: { error },
    }
}

export function createPOReset() {
    return {
        type: CREATE_PO_RESET,
    }
}


export function getPOListRecord(query) {
    return async (dispatch) => {
        dispatch(getPOList())
        return await purchaseOrderService
            .getPOList(query)
            .then((response) => dispatch(getPOListSuccess(response)))
            .catch((error) => dispatch(getPOListError(error)))
    }
}

export function getPOList() {
    return {
        type: GET_PO_LIST,
    }
}

export function getPOListSuccess(success) {
    return {
        type: GET_PO_LIST_SUCCESS,
        payload: { success },
    }
}

export function getPOListError(error) {
    return {
        type: GET_PO_LIST_ERROR,
        payload: { error },
    }
}