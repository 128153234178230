function convertNumberToWords(value) {
    let f_text = "";

    const fraction = Math.round((value % 1) * 100);
    if (fraction > 0) {
        f_text = "And " + convert_number(fraction) + ` ${fraction > 1 ? 'paise' : 'paisa'} `;
    }

    return convert_number(value) + ` Taka${value > 1 ? 's ' : ' '}` + f_text + " Only";
}

function convert_number(number) {
    let res = "";

    if ((number < 0) || (number > 9999999999)) {
        return "NUMBER OUT OF RANGE!";
    }

    const Gn = Math.floor(number / 10000000);  /* Crore */
    number -= Gn * 10000000;
    const kn = Math.floor(number / 100000);     /* lakhs */
    number -= kn * 100000;
    const Hn = Math.floor(number / 1000);      /* thousand */
    number -= Hn * 1000;
    const Dn = Math.floor(number / 100);       /* Tens (deca) */
    number = number % 100;                  /* Ones */
    const tn = Math.floor(number / 10);
    const one = Math.floor(number % 10);

    if (Gn > 0) {
        res += (convert_number(Gn) + ` Crore${Gn > 1 ? 's' : ''}`);
    }
    if (kn > 0) {
        res += (((res === "") ? "" : " ") +
            convert_number(kn) + ` Lakh${kn > 1 ? 's' : ''}`);
    }
    if (Hn > 0) {
        res += (((res === "") ? "" : " ") +
            convert_number(Hn) + " Thousand");
    }
    if (Dn) {
        res += (((res === "") ? "" : " ") +
            convert_number(Dn) + " Hundred");
    }

    const ones = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten", 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen ', 'Seventeen', 'Eighteen', 'Nineteen'];

    const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

    if (tn > 0 || one > 0) {
        if (!(res === "")) {
            res += " And ";
        }
        if (tn < 2) {
            res += ones[tn * 10 + one];
        }
        else {
            res += tens[tn];
            if (one > 0) {
                res += ("-" + ones[one]);
            }
        }
    }

    if (res === "") {
        res = "Zero";
    }
    return res;
}

export default convertNumberToWords;