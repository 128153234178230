import moment from "moment";
import React from "react";
import "../css/DateWiseSalesSummarySmallData.css";

const DateWiseSalesSummarySmallData = ({ data, vendorName }) => {
  return (
    <div className="report_DateWiseSalesSummary_small_data_container">
      <div className="report_DateWiseSalesSummary_small_data_container_vendor_name">
        {" "}
        Vendor:&nbsp;&nbsp;&nbsp;&nbsp;{vendorName}
      </div>
      <div>
        <div className="report_DateWiseSalesSummary_small_data_container_items_header">
          <div
            style={{
              width: "8%",
              textAlign: "center",
            }}
          >
            Sl. No.
          </div>
          <div style={{ width: "10%", textAlign: "center" }}>Invoice Date</div>
          <div style={{ width: "10%", textAlign: "center" }}>
            No. of Invoice
          </div>
          <div style={{ width: "8%", textAlign: "center" }}>Sales Qty.</div>
          <div style={{ width: "10%", textAlign: "center" }}>Total MRP</div>
          <div style={{ width: "10%", textAlign: "center" }}>
            Discount Amount
          </div>
          <div style={{ width: "10%", textAlign: "center" }}>Burn Amount</div>
          <div style={{ width: "10%", textAlign: "center" }}>
            Voucher Amount
          </div>
          <div style={{ width: "10%", textAlign: "center" }}>Gross Sales</div>
          <div style={{ width: "12%", textAlign: "center" }}>Commission</div>
          <div style={{ width: "12%", textAlign: "center" }}>Net Payable</div>
        </div>
        {data.map((info, i) => (
          <div className="report_DateWiseSalesSummary_small_data_container_items">
            <div
              style={{
                width: "8%",
                textAlign: "center",
              }}
            >
              {i + 1}
            </div>
            <div style={{ width: "10%", textAlign: "center" }}>
              {moment(info?.orderDate).format("DD-MMM-YY")}
            </div>
            <div style={{ width: "10%", textAlign: "center" }}>
              {info?.cntDelivered.toLocaleString()}
            </div>
            <div style={{ width: "8%", textAlign: "center" }}>
              {info?.deliveredQty.toLocaleString()}
            </div>
            <div style={{ width: "10%", textAlign: "center" }}>
              {info?.deliveredAmount
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
            </div>
            <div style={{ width: "10%", textAlign: "center" }}>
              {info?.discountAmount
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
            </div>
            <div style={{ width: "10%", textAlign: "center" }}>
              {info?.burnAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
            </div>
            <div style={{ width: "10%", textAlign: "center" }}>
              {info?.voucherAmount
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
            </div>
            <div style={{ width: "10%", textAlign: "center" }}>
              {info?.grossAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
            </div>
            <div style={{ width: "12%", textAlign: "center" }}>
              {info?.commissionAmount
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
            </div>
            <div style={{ width: "12%", textAlign: "center" }}>
              {info?.netAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
            </div>
          </div>
        ))}
      </div>
      <div className="report_DateWiseSalesSummary_small_data_container_payment_method_total_container">
        <div className="report_DateWiseSalesSummary_small_data_container_payment_method_total">
          {" "}
          <div style={{ width: "18%", textAlign: "center" }}>
            {vendorName} Total:
          </div>
          <div style={{ width: "10%", textAlign: "center" }}>
            {data
              .reduce(
                (accumulator, currentValue) =>
                  accumulator + parseFloat(currentValue?.cntDelivered),
                0
              )
              .toLocaleString()}
          </div>
          <div style={{ width: "8%", textAlign: "center" }}>
            {data
              .reduce(
                (accumulator, currentValue) =>
                  accumulator + parseFloat(currentValue?.deliveredQty),
                0
              )
              .toLocaleString()}
          </div>
          <div style={{ width: "10%", textAlign: "center" }}>
            {data
              .reduce(
                (accumulator, currentValue) =>
                  accumulator + parseFloat(currentValue?.deliveredAmount),
                0
              )
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
          </div>
          <div style={{ width: "10%", textAlign: "center" }}>
            {data
              .reduce(
                (accumulator, currentValue) =>
                  accumulator + parseFloat(currentValue?.discountAmount),
                0
              )
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
          </div>
          <div style={{ width: "10%", textAlign: "center" }}>
            {data
              .reduce(
                (accumulator, currentValue) =>
                  accumulator + parseFloat(currentValue?.burnAmount),
                0
              )
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
          </div>
          <div style={{ width: "10%", textAlign: "center" }}>
            {data
              .reduce(
                (accumulator, currentValue) =>
                  accumulator + parseFloat(currentValue?.voucherAmount),
                0
              )
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
          </div>
          <div style={{ width: "10%", textAlign: "center" }}>
            {data
              .reduce(
                (accumulator, currentValue) =>
                  accumulator + parseFloat(currentValue?.grossAmount),
                0
              )
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
          </div>
          <div style={{ width: "12%", textAlign: "center" }}>
            {data
              .reduce(
                (accumulator, currentValue) =>
                  accumulator + parseFloat(currentValue?.commissionAmount),
                0
              )
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
          </div>
          <div style={{ width: "12%", textAlign: "center" }}>
            {data
              .reduce(
                (accumulator, currentValue) =>
                  accumulator + parseFloat(currentValue?.netAmount),
                0
              )
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
          </div>
        </div>
      </div>
      <br />
    </div>
  );
};

export default DateWiseSalesSummarySmallData;
