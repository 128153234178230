import moment from "moment";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";

import { Tag } from "primereact/tag";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { Paginator } from "../../components/paginator/Paginator";
import LoadingCard from "../../components/shared/LoadingCard";
import useViewPort from "../../hooks/useViewPort";
import { getPaymentMethodRecord } from "../../store/actions/paymentMethodAction";
import { getOrderAccountsTransactionRecord } from "../../store/actions/transactionAction";

const paymentStatusArr = [
  { paymentStatus: "Initial", code: 1 },
  { paymentStatus: "Pending", code: 2 },
  { paymentStatus: "Success", code: 3 },
  { paymentStatus: "Partial", code: 4 },
  { paymentStatus: "Failed", code: 5 },
  { paymentStatus: "Canceled", code: 6 },
];

const CustomerOrderPayment = () => {
  const { viewportWidth } = useViewPort();
  const [keyword, setKeyword] = useState("");
  const [tranStartDate, setTranStartDate] = useState(null);
  const [tranEndDate, setTranEndDate] = useState(null);
  const [selectPaymentStatus, setSelectPaymentStatus] = useState(null);
  const [selectPaymentMethod, setSelectPaymentMethod] = useState(null);
  const [expandedRows, setExpandedRows] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(30);
  const [totalPage, setTotalPage] = useState(null);
  const [totalItems, setTotalItems] = useState(null);

  const [filterData, setFilterData] = useState(false);

  const dispatch = useDispatch();

  const { accountsTransactionReportData, loading } = useSelector(
    (state) => state.transactionReducer
  );

  const paymentMethods = useSelector(
    (state) => state.paymentMethodReducer.paymentMethods
  );

  const onPaymentMethodChange = (e) => {
    setSelectPaymentMethod(e.value);
  };
  const onPaymentStatusChange = (e) => {
    setSelectPaymentStatus(e.value);
  };

  const handleItemPerPage = (pagePerItems) => {
    setCurrentPage(1);
    setItemPerPage(pagePerItems);
  };
  const handleCurrentPage = (currentPage) => {
    if (filterData) {
      setCurrentPage(1);
    } else {
      setCurrentPage(currentPage);
    }
  };

  const tranDateBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span>
          {moment(rowData?.tranDate).format("Do MMMM YYYY, h:mm:s A")}
        </span>
      </React.Fragment>
    );
  };

  const paymentStatusTemplate = (rowData) => {
    return (
      <React.Fragment>
        {rowData.paymentStatus === "Pending" ? (
          <Tag
            style={{ width: "80px" }}
            severity="navy"
            value={rowData.paymentStatus}
            rounded
          ></Tag>
        ) : rowData.paymentStatus === "Failed" ? (
          <Tag
            style={{ width: "80px" }}
            severity="danger"
            value={rowData.paymentStatus}
            rounded
          ></Tag>
        ) : rowData.paymentStatus === "Canceled" ? (
          <Tag
            style={{ width: "80px" }}
            severity="warning"
            value={rowData.paymentStatus}
            rounded
          ></Tag>
        ) : rowData.paymentStatus === "Initial" ? (
          <Tag
            style={{ width: "80px" }}
            severity="info"
            value={rowData.paymentStatus}
            rounded
          ></Tag>
        ) : rowData.paymentStatus === "Partial" ? (
          <Tag
            style={{ width: "80px" }}
            severity="info"
            value={rowData.paymentStatus}
            rounded
          ></Tag>
        ) : (
          <Tag
            style={{ width: "80px" }}
            severity="success"
            value="Done"
            rounded
          ></Tag>
        )}
      </React.Fragment>
    );
  };

  // const expandAll = () => {
  //   let _expandedRows = {};
  //   accountsTransactionReportData.data.forEach(
  //     (p) => (_expandedRows[`${p.orderInvoiceNo}`] = true)
  //   );
  //   setExpandedRows(_expandedRows);
  // };
  // const collapseAll = () => {
  //   setExpandedRows(null);
  // };

  const rowExpansionTemplate = (data) => {
    // setOrderSummary(data);
    return (
      <div className="orders-subtable">
        <h5>
          Orders for {data?.orderInvoiceNo}
          {/* <Link
            to={{
              pathname: `/invoiceAdmin`,
              selectedOrders: order,
              invoiceType,
              order_filter_tab,
            }}
          > */}
          {/* <button
            onClick={() => handleMultiplePrint(data.invoiceNo, data.statusName)}
            className="btn btn-info"
            style={{
              padding: "4px 20px",
              fontSize: "13px ",
              marginRight: "5px",
              marginLeft: "10px",
            }}
            disabled={selectedOrders === null || selectedOrders?.length === 0}
          >
            Print
          </button> */}
          {/* </Link> */}
        </h5>

        <DataTable
          value={data?.transactionHistory}
          responsiveLayout="scroll"
          emptyMessage="No order(s) found"
          rowHover
          // scrollable
          
          // selection={selectedOrders}
          // onSelectionChange={(e) => setSelectedOrders(e.value)}
        >
          {/* <Column selectionMode="multiple" /> */}
          <Column
            field="tranNo"
            filterField="tranNo"
            header="Tran No"
            sortable
            style={{ width: "15%" }}
            // headerStyle={{ width: "15%" }}
            // bodyStyle={{ width: "15%", maxWidth: "5rem", overflow: "auto" }}
          />
          <Column
            field="tranDate"
            filterField="tranDate"
            header="Tran Date & Time"
            body={tranDateBodyTemplate}
            sortable
            style={{ width: "15%" }}
            // headerStyle={{ width: "15%" }}
            // bodyStyle={{ width: "15%", maxWidth: "5rem", overflow: "auto" }}
          />
          <Column
            field="tranRefNo"
            filterField="tranRefNo"
            header="Tran Ref. No."
            sortable
            style={{ width: "15%" }}
            // headerStyle={{ width: "15%" }}
            bodyStyle={{ width: "15%", maxWidth: "5rem", overflow: "auto"}}
          />
          <Column
            field="accountNo"
            filterField="accountNo"
            header="Account No."
            sortable
            style={{ width: "10%" }}
            // headerStyle={{ width: "10%" }}
            // bodyStyle={{ width: "10%", maxWidth: "4rem", overflow: "auto" }}
          />
          <Column
            field="paymentMethodName"
            filterField="paymentMethodName"
            header="Payment Method"
            sortable
            style={{ width: "10%" }}
            // headerStyle={{ width: "10%" }}
            // bodyStyle={{ width: "10%", maxWidth: "4rem", overflow: "auto" }}
          />
          <Column
            field="paymentStatus"
            filterField="paymentStatus"
            header="Payment Status"
            body={paymentStatusTemplate}
            sortable
            style={{ width: "10%" }}
            // headerStyle={{ width: "10%" }}
            // bodyStyle={{ width: "10%", maxWidth: "4rem", overflow: "auto" }}
          />
          <Column
            field="remarks"
            filterField="remarks"
            header="Remark"
            bodyStyle={{ width: "20%", maxWidth: "8rem", overflow: "auto" }}
          />
        </DataTable>
      </div>
    );
  };

  const toIsoString = (date) => {
    var tzo = -date?.getTimezoneOffset(),
      dif = tzo >= 0 ? "+" : "-",
      pad = function (num) {
        return (num < 10 ? "0" : "") + num;
      };
    return (
      date?.getFullYear() +
      "-" +
      pad(date?.getMonth() + 1) +
      "-" +
      pad(date?.getDate()) +
      "T" +
      pad(date?.getHours()) +
      ":" +
      pad(date?.getMinutes()) +
      ":" +
      pad(date?.getSeconds()) +
      dif +
      pad(Math?.floor(Math?.abs(tzo) / 60)) +
      ":" +
      pad(Math?.abs(tzo) % 60)
    );
  };

  useEffect(() => {
   
    dispatch(
      getOrderAccountsTransactionRecord(
        keyword,
        tranStartDate ? toIsoString(tranStartDate).substring(0, 10) : null,
        tranEndDate ? toIsoString(tranEndDate).substring(0, 10) : null,
        selectPaymentMethod?.methodName,
        selectPaymentStatus?.paymentStatus,
        currentPage,
        itemPerPage
      )
    );

    return () => {
      setFilterData(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentPage, itemPerPage, filterData]);
  // currentPage, itemPerPage, filterData

  useEffect(() => {
   
    if (accountsTransactionReportData?.headers?.pagination && !filterData) {
      var paginated_data_to_parse =
        accountsTransactionReportData.headers.pagination;
      const paginated_data = JSON.parse(paginated_data_to_parse);
      // setCurrentPage(paginated_data.currentPage);
      setTotalPage(paginated_data.totalPages);
      setTotalItems(paginated_data.totalItems);
      // setItemPerPage(paginated_data.itemsPerPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountsTransactionReportData?.headers?.pagination]);

  useEffect(() => {
    dispatch(getPaymentMethodRecord());
  }, [dispatch]);

  return (
    <>
      <ToastContainer autoClose={2500} />
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="col-md-12">
            <div className="white-box white-box-title">
              <div className="datatable-row" style={{ padding: "20px" }}>
                <div className="card">
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      padding: "10px 20px",
                    }}
                    className="order_filter__section "
                  >
                    <div
                      style={{
                        margin: "0 2px",
                        width: viewportWidth < 992 ? "190px" : "250px",
                        position: "relative",
                      }}
                    >
                      <InputText
                        style={{
                          width: "100%",
                        }}
                        className="manage_product__search"
                        value={keyword}
                        onChange={(e) => {
                          setKeyword(e.target.value);
                        }}
                        placeholder="Keyword"
                      />
                      {keyword !== "" ? (
                        <button
                          style={{
                            position: "absolute",
                            top: "6px",
                            right: "8px",
                            borderRadius: "100%",
                            border: "1px solid #ced4da",
                            height: "25px",
                            width: "25px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onClick={() => {
                            // setSearchKeyword("");
                            setKeyword("");
                            // setCurrentPage(1);
                            // setSearchKeywordApi("");
                          }}
                        >
                          <i className="pi pi-times"></i>
                        </button>
                      ) : null}
                    </div>

                    <div
                      style={{
                        margin: "0 2px",
                        width: "155px",
                        position: "relative",
                      }}
                    >
                      <Calendar
                        id="icon"
                        // maxDate={endDate}
                        value={tranStartDate}
                        onChange={(e) => setTranStartDate(e.value)}
                        showIcon
                        placeholder="Tran Start Date"
                        readOnlyInput
                      />
                      {tranStartDate !== null ? (
                        <button
                          style={{
                            position: "absolute",
                            top: "6px",
                            right: "33px",
                            borderRadius: "100%",
                            border: "1px solid #ced4da",
                            height: "25px",
                            width: "25px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onClick={() => {
                            setTranStartDate(null);
                            // setStartDateApi(null);
                            // setCurrentPage(1);
                          }}
                        >
                          <i className="pi pi-times"></i>
                        </button>
                      ) : null}
                    </div>

                    <div
                      style={{
                        margin: "0 2px",
                        width: "155px",
                        position: "relative",
                      }}
                    >
                      <Calendar
                        id="icon"
                        // maxDate={endDate}
                        value={tranEndDate}
                        onChange={(e) => setTranEndDate(e.value)}
                        showIcon
                        placeholder="Tran End Date"
                        readOnlyInput
                      />
                      {tranEndDate !== null ? (
                        <button
                          style={{
                            position: "absolute",
                            top: "6px",
                            right: "33px",
                            borderRadius: "100%",
                            border: "1px solid #ced4da",
                            height: "25px",
                            width: "25px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onClick={() => {
                            setTranEndDate(null);
                            // setStartDateApi(null);
                            // setCurrentPage(1);
                          }}
                        >
                          <i className="pi pi-times"></i>
                        </button>
                      ) : null}
                    </div>

                    <div
                      style={{
                        margin: "0 2px",
                        width: "160px",
                        position: "relative",
                      }}
                    >
                      <Dropdown
                        style={{
                          width: "100%",
                        }}
                        value={selectPaymentMethod}
                        options={paymentMethods}
                        onChange={onPaymentMethodChange}
                        optionLabel="methodName"
                        placeholder="Payment Method"
                        filter
                        showClear
                      />
                      {selectPaymentMethod !== null ? (
                        <button
                          style={{
                            position: "absolute",
                            top: "6px",
                            right: "8px",
                            borderRadius: "100%",
                            border: "1px solid #ced4da",
                            height: "25px",
                            width: "25px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onClick={() => {
                            setSelectPaymentMethod(null);
                            // setCurrentPage(1);
                          }}
                        >
                          <i className="pi pi-times"></i>
                        </button>
                      ) : null}
                    </div>

                    <div
                      style={{
                        margin: "0 2px",
                        width: "160px",
                        position: "relative",
                      }}
                    >
                      <Dropdown
                        style={{
                          width: "100%",
                        }}
                        value={selectPaymentStatus}
                        options={paymentStatusArr}
                        onChange={onPaymentStatusChange}
                        optionLabel="paymentStatus"
                        placeholder="Payment Status"
                      />
                      {selectPaymentStatus !== null ? (
                        <button
                          style={{
                            position: "absolute",
                            top: "6px",
                            right: "8px",
                            borderRadius: "100%",
                            border: "1px solid #ced4da",
                            height: "25px",
                            width: "25px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onClick={() => {
                            setSelectPaymentStatus(null);
                            // setCurrentPage(1);
                          }}
                        >
                          <i className="pi pi-times"></i>
                        </button>
                      ) : null}
                    </div>
                    <div style={{ position: "relative" }}>
                      <Button
                        onClick={() => {
                          setCurrentPage(1);
                          setFilterData(true);
                        }}
                        style={{ marginLeft: "3px" }}
                        label="Search"
                        disabled={
                          tranStartDate?.getTime() > tranEndDate?.getTime()
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="card">
                  {loading ? (
                    <LoadingCard count={1} />
                  ) : (
                    <>
                      <DataTable
                        value={accountsTransactionReportData?.data}
                        expandedRows={expandedRows}
                        onRowToggle={(e) => setExpandedRows(e.data)}
                        responsiveLayout="scroll"
                        rowExpansionTemplate={rowExpansionTemplate}
                        dataKey="orderInvoiceNo"
                        rowHover
                        //   header={header}
                        emptyMessage="No order(s) found"
                      >
                        <Column expander style={{ width: "3em" }} />
                        <Column
                          field="orderInvoiceNo"
                          filterField="orderInvoiceNo"
                          header="Invoice No"
                          sortable
                        />

                        <Column
                          field="tranDate"
                          filterField="tranDate"
                          header="Tran Date & Time"
                          body={tranDateBodyTemplate}
                          sortable
                        />
                        <Column
                          field="customerName"
                          filterField="customerName"
                          header="Customer Name"
                          sortable
                        />
                        <Column
                          field="customerContactNo"
                          filterField="customerContactNo"
                          header="Contact No."
                          sortable
                        />

                        <Column
                          field="paymentMethodName"
                          filterField="paymentMethodName"
                          header="Payment Method"
                          sortable
                        />
                        <Column
                          field="paymentStatus"
                          filterField="paymentStatus"
                          header="Payment Status"
                          body={paymentStatusTemplate}
                          sortable
                        />
                        <Column
                          field="amount"
                          filterField="amount"
                          header="Amount"
                        />
                      </DataTable>

                      <Paginator
                        totalPage={totalPage}
                        currentPage={currentPage}
                        itemPerPage={itemPerPage}
                        totalItems={totalItems}
                        items={accountsTransactionReportData?.data}
                        itemsPerPageOptions={[30, 60, 90, 100, 200, 300]}
                        handleItemPerPage={handleItemPerPage}
                        handleCurrentPage={handleCurrentPage}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Modal
        title="Payment Modal"
        centered
        visible={openModal}
        onOk={() => setOpenModal(false)}
        onCancel={() => setOpenModal(false)}
        width={1500}
      >
       
      </Modal> */}
    </>
  );
};

export default CustomerOrderPayment;
