import accountsService from "../services/accountsService";

export const GET_EXCEL_ACCOUNTS_TRANSACTION = "GET_EXCEL_ACCOUNTS_TRANSACTION";
export const GET_EXCEL_ACCOUNTS_TRANSACTION_SUCCESS =
  "GET_EXCEL_ACCOUNTS_TRANSACTION_SUCCESS";
export const GET_EXCEL_ACCOUNTS_TRANSACTION_ERROR =
  "GET_EXCEL_ACCOUNTS_TRANSACTION_ERROR";

export const GET_ACCOUNTS_DASHBOARD_DATA = "GET_ACCOUNTS_DASHBOARD_DATA";
export const GET_ACCOUNTS_DASHBOARD_DATA_SUCCESS =
  "GET_ACCOUNTS_DASHBOARD_DATA_SUCCESS";
export const GET_ACCOUNTS_DASHBOARD_DATA_ERROR =
  "GET_ACCOUNTS_DASHBOARD_DATA_ERROR";

export const GET_DATE_WISE_SALES_SUMMARY_NBR_DATA =
  "GET_DATE_WISE_SALES_SUMMARY_NBR_DATA";
export const GET_DATE_WISE_SALES_SUMMARY_NBR_DATA_SUCCESS =
  "GET_DATE_WISE_SALES_SUMMARY_NBR_DATA_SUCCESS";
export const GET_DATE_WISE_SALES_SUMMARY_NBR_DATA_ERROR =
  "GET_DATE_WISE_SALES_SUMMARY_NBR_DATA_ERROR";

export const GET_DATE_WISE_SALES_SUMMARY_DATA =
  "GET_DATE_WISE_SALES_SUMMARY_DATA";
export const GET_DATE_WISE_SALES_SUMMARY_DATA_SUCCESS =
  "GET_DATE_WISE_SALES_SUMMARY_DATA_SUCCESS";
export const GET_DATE_WISE_SALES_SUMMARY_DATA_ERROR =
  "GET_DATE_WISE_SALES_SUMMARY_DATA_ERROR";

export const GET_PAYMENT_TYPE_WISE_SALES_SUMMARY_DATA =
  "GET_PAYMENT_TYPE_WISE_SALES_SUMMARY_DATA";
export const GET_PAYMENT_TYPE_WISE_SALES_SUMMARY_DATA_SUCCESS =
  "GET_PAYMENT_TYPE_WISE_SALES_SUMMARY_DATA_SUCCESS";
export const GET_PAYMENT_TYPE_WISE_SALES_SUMMARY_DATA_ERROR =
  "GET_PAYMENT_TYPE_WISE_SALES_SUMMARY_DATA_ERROR";

export const GET_PAYMENT_TYPE_WISE_SALES_DETAILS_DATA =
  "GET_PAYMENT_TYPE_WISE_SALES_DETAILS_DATA";
export const GET_PAYMENT_TYPE_WISE_SALES_DETAILS_DATA_SUCCESS =
  "GET_PAYMENT_TYPE_WISE_SALES_DETAILS_DATA_SUCCESS";
export const GET_PAYMENT_TYPE_WISE_SALES_DETAILS_DATA_ERROR =
  "GET_PAYMENT_TYPE_WISE_SALES_DETAILS_DATA_ERROR";

export const GET_DATE_WISE_PAYMENT_REALIZATION_DATA =
  "GET_DATE_WISE_PAYMENT_REALIZATION_DATA";
export const GET_DATE_WISE_PAYMENT_REALIZATION_DATA_SUCCESS =
  "GET_DATE_WISE_PAYMENT_REALIZATION_DATA_SUCCESS";
export const GET_DATE_WISE_PAYMENT_REALIZATION_DATA_ERROR =
  "GET_DATE_WISE_PAYMENT_REALIZATION_DATA_ERROR";

// Accounts Transaction start
export function getExcelAccountsTransactionRecord(
  startDate,
  endDate,
  paymentStatus,
  paymentMethod,
  shopIds
) {
  return async (dispatch) => {
    dispatch(getExcelAccountsTransaction());
    return await accountsService
      .getExcelAccountsTransaction(
        startDate,
        endDate,
        paymentStatus,
        paymentMethod,
        shopIds
      )

      .then((response) =>
        dispatch(getExcelAccountsTransactionSuccess(response))
      )
      .catch((error) => dispatch(getExcelAccountsTransactionError(error)));
  };
}

export function getExcelAccountsTransaction() {
  return {
    type: GET_EXCEL_ACCOUNTS_TRANSACTION,
  };
}

export function getExcelAccountsTransactionSuccess(success) {
  return {
    type: GET_EXCEL_ACCOUNTS_TRANSACTION_SUCCESS,
    payload: { success },
  };
}

export function getExcelAccountsTransactionError(error) {
  return {
    type: GET_EXCEL_ACCOUNTS_TRANSACTION_ERROR,
    payload: { error },
  };
}

// Accounts Transaction End

// Accounts Dashboard Data start
export function getAccountsDashboardDataRecord(startDate, endDate, shopNumber) {
  return async (dispatch) => {
    dispatch(getAccountsDashboardData());
    return await accountsService
      .getAccountsDashboardData(startDate, endDate, shopNumber)

      .then((response) => dispatch(getAccountsDashboardDataSuccess(response)))
      .catch((error) => dispatch(getAccountsDashboardDataError(error)));
  };
}

export function getAccountsDashboardData() {
  return {
    type: GET_ACCOUNTS_DASHBOARD_DATA,
  };
}

export function getAccountsDashboardDataSuccess(success) {
  return {
    type: GET_ACCOUNTS_DASHBOARD_DATA_SUCCESS,
    payload: { success },
  };
}

export function getAccountsDashboardDataError(error) {
  return {
    type: GET_ACCOUNTS_DASHBOARD_DATA_ERROR,
    payload: { error },
  };
}

// Accounts Dashboard Data End

// Accounts DATE WISE SALES SUMMARY NBR DATA start
export function getDateWiseSalesSummaryNBRRecord(
  startDate,
  endDate,
  statusId,
  shopIds
) {
  return async (dispatch) => {
    dispatch(getDateWiseSalesSummaryNBR());
    return await accountsService
      .getDateWiseSalesSummaryNBR(startDate, endDate, statusId, shopIds)

      .then((response) => dispatch(getDateWiseSalesSummaryNBRSuccess(response)))
      .catch((error) => dispatch(getDateWiseSalesSummaryNBRError(error)));
  };
}

export function getDateWiseSalesSummaryNBR() {
  return {
    type: GET_DATE_WISE_SALES_SUMMARY_NBR_DATA,
  };
}

export function getDateWiseSalesSummaryNBRSuccess(success) {
  return {
    type: GET_DATE_WISE_SALES_SUMMARY_NBR_DATA_SUCCESS,
    payload: { success },
  };
}

export function getDateWiseSalesSummaryNBRError(error) {
  return {
    type: GET_DATE_WISE_SALES_SUMMARY_NBR_DATA_ERROR,
    payload: { error },
  };
}

// Accounts DATE WISE SALES SUMMARY NBR DATA End

// Accounts DATE WISE SALES SUMMARY DATA start
export function getDateWiseSalesSummaryRecord(
  startDate,
  endDate,
  statusId,
  shopIds
) {
  return async (dispatch) => {
    dispatch(getDateWiseSalesSummary());
    return await accountsService
      .getDateWiseSalesSummary(startDate, endDate, statusId, shopIds)

      .then((response) => dispatch(getDateWiseSalesSummarySuccess(response)))
      .catch((error) => dispatch(getDateWiseSalesSummaryError(error)));
  };
}

export function getDateWiseSalesSummary() {
  return {
    type: GET_DATE_WISE_SALES_SUMMARY_DATA,
  };
}

export function getDateWiseSalesSummarySuccess(success) {
  return {
    type: GET_DATE_WISE_SALES_SUMMARY_DATA_SUCCESS,
    payload: { success },
  };
}

export function getDateWiseSalesSummaryError(error) {
  return {
    type: GET_DATE_WISE_SALES_SUMMARY_DATA_ERROR,
    payload: { error },
  };
}

// Accounts DATE WISE SALES SUMMARY DATA End

// Accounts Payment Type WISE SALES SUMMARY DATA start
export function getPaymentTypeWiseSalesSummaryRecord(
  startDate,
  endDate,
  statusId,
  shopIds,
  paymentMethodIds
) {
  return async (dispatch) => {
    dispatch(getPaymentTypeWiseSalesSummary());
    return await accountsService
      .getPaymentTypeWiseSalesSummary(
        startDate,
        endDate,
        statusId,
        shopIds,
        paymentMethodIds
      )

      .then((response) =>
        dispatch(getPaymentTypeWiseSalesSummarySuccess(response))
      )
      .catch((error) => dispatch(getPaymentTypeWiseSalesSummaryError(error)));
  };
}

export function getPaymentTypeWiseSalesSummary() {
  return {
    type: GET_PAYMENT_TYPE_WISE_SALES_SUMMARY_DATA,
  };
}

export function getPaymentTypeWiseSalesSummarySuccess(success) {
  return {
    type: GET_PAYMENT_TYPE_WISE_SALES_SUMMARY_DATA_SUCCESS,
    payload: { success },
  };
}

export function getPaymentTypeWiseSalesSummaryError(error) {
  return {
    type: GET_PAYMENT_TYPE_WISE_SALES_SUMMARY_DATA_ERROR,
    payload: { error },
  };
}

// Accounts Payment Type WISE SALES SUMMARY DATA End

// Accounts Payment Type WISE SALES Details DATA start
export function getPaymentTypeWiseSalesDetailsRecord(
  startDate,
  endDate,
  statusId,
  shopIds,
  paymentMethodIds
) {
  return async (dispatch) => {
    dispatch(getPaymentTypeWiseSalesDetails());
    return await accountsService
      .getPaymentTypeWiseSalesDetails(
        startDate,
        endDate,
        statusId,
        shopIds,
        paymentMethodIds
      )

      .then((response) =>
        dispatch(getPaymentTypeWiseSalesDetailsSuccess(response))
      )
      .catch((error) => dispatch(getPaymentTypeWiseSalesDetailsError(error)));
  };
}

export function getPaymentTypeWiseSalesDetails() {
  return {
    type: GET_PAYMENT_TYPE_WISE_SALES_DETAILS_DATA,
  };
}

export function getPaymentTypeWiseSalesDetailsSuccess(success) {
  return {
    type: GET_PAYMENT_TYPE_WISE_SALES_DETAILS_DATA_SUCCESS,
    payload: { success },
  };
}

export function getPaymentTypeWiseSalesDetailsError(error) {
  return {
    type: GET_PAYMENT_TYPE_WISE_SALES_DETAILS_DATA_ERROR,
    payload: { error },
  };
}

// Accounts Payment Type WISE SALES Details DATA End


// Accounts DATE WISE PAYMENT Realization DATA start
export function getDateWisePaymentRealizationRecord(
  dataPost
) {
  return async (dispatch) => {
    dispatch(getDateWisePaymentRealization());
    return await accountsService
      .getDateWisePaymentRealization(dataPost)

      .then((response) => dispatch(getDateWisePaymentRealizationSuccess(response)))
      .catch((error) => dispatch(getDateWisePaymentRealizationError(error)));
  };
}

export function getDateWisePaymentRealization() {
  return {
    type: GET_DATE_WISE_PAYMENT_REALIZATION_DATA,
  };
}

export function getDateWisePaymentRealizationSuccess(success) {
  return {
    type: GET_DATE_WISE_PAYMENT_REALIZATION_DATA_SUCCESS,
    payload: { success },
  };
}

export function getDateWisePaymentRealizationError(error) {
  return {
    type: GET_DATE_WISE_PAYMENT_REALIZATION_DATA_ERROR,
    payload: { error },
  };
}

// Accounts DATE WISE PAYMENT Realization DATA End