/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { CampaignManageProductListTable } from "../../components/promotion/CampaignManageProductListTable";
import LoadingCard from "../../components/shared/LoadingCard";
import { addOrEditCampaignProductListRecord, addOrEditCampaignProductListReset, campaignProductStatusChangeRecord, campaignProductStatusChangeReset, getStatusWiseCampaignProductsRecord } from "../../store/actions/campaignAction";
import authenticationService from "../../store/services/authenticationService";
import { getCategoryRecord } from "../../store/actions/categoryAction";
import { getBrandRecord } from "../../store/actions/brandAction";

const ManageSellerCampaignProductsContainer = () => {
  const history = useHistory();
  const location = useLocation();
  const prevState = location?.state?.state;
  const dispatch = useDispatch();

  const urlSearchParams = new URLSearchParams(location.search);
  const selectedCampaignId = Number(urlSearchParams.get('campId'));
  const selectedCampaignStatus = urlSearchParams.get('campStatus');

  const [isApproved, setIsApproved] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(null);
  const [itemPerPage, setItemPerPage] = useState(prevState?.itemPerPage ? prevState?.itemPerPage : 20);
  const [totalItems, setTotalItems] = useState(null);

  const [searchKeyword, setSearchKeyword] = useState(prevState?.searchKeyword ? prevState?.searchKeyword : "");

  const [category, setCategory] = useState(prevState?.category ? prevState?.category : null);
  const [brand, setBrand] = useState(prevState?.brand ? prevState?.brand : null);
  const [vendor, setVendor] = useState(prevState?.vendor ? prevState?.vendor : null);
  const [isFilter, setIsFilter] = useState(false);

  const [selectedManageCampaignProducts, setSelectedManageCampaignProducts] = useState([]);
  const [selectedCampaignProductIds, setSelectedCampaignProductIds] = useState([]);
  const [isPending, setIsPending] = useState(true);

  const categories = useSelector((state) => state.categoryReducer.categories);
  const brands = useSelector((state) => state.brandReducer.brands);

  const {
    campaignProductListData,
    campaignProductListLoading,
    getStatusWiseCampaignProductsListData,
    getStatusWiseCampaignProductsListLoading,
    campaignProductStatusChangeData,
    campaignProductStatusChangeLoading
  } = useSelector((state) => state.campaignReducer);

  const [loaderCall, setLoaderCall] = useState(false);
  useEffect(() => {
    setLoaderCall(true)
    const timeoutId = setTimeout(async () => {
      setLoaderCall(false);
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, [selectedCampaignStatus, isFilter]);

  useEffect(() => {
    dispatch(getCategoryRecord());
    dispatch(getBrandRecord());
  }, []);

  const handleItemPerPage = (pagePerItems) => {
    setCurrentPage(1);
    setItemPerPage(pagePerItems);
  };

  const handleCurrentPage = (currentPage) => {
    if (isFilter) {
      setCurrentPage(1)
    } else {
      setCurrentPage(currentPage);
    }
  };

  useEffect(() => {
    if (history?.location?.state) {
      setCurrentPage(history.location?.state.state.currentPage);
      setItemPerPage(history.location?.state.state.itemPerPage);
    }
  }, []);

  useEffect(() => {
    if (selectedCampaignStatus === "pending") {
      setIsApproved("N");
      setIsPending(true);
      setItemPerPage(20)
    } else if (selectedCampaignStatus === "approved") {
      setIsApproved("Y");
      setIsPending(false);
      setItemPerPage(20)
    }
  }, [selectedCampaignStatus]);

  useEffect(() => {
    const selectedShopId = vendor?.map(item => item?.value).join(',');

    const productInfo = {
      itemsPerPage: itemPerPage,
      currentPage: currentPage,
      campaignId: selectedCampaignId,
      campaignProductStatus: isApproved,
      categoryId: category?.categoryId,
      shopId: selectedShopId,
      brandId: brand?.brandId,
    }

    if (searchKeyword) {
      productInfo.keywords = searchKeyword;
      productInfo.currentPage = 1;
    }
    isApproved && dispatch(getStatusWiseCampaignProductsRecord(productInfo))
    
    if (isFilter) {
      const url = `/Manage-Seller-Campaign-ProductsList?campId=${selectedCampaignId}&campStatus=${selectedCampaignStatus}`;
      history.push(url, { state: { searchKeyword, brand, category, vendor } })
    }
    return () => setIsFilter(false)
  }, [isFilter, isApproved, campaignProductListData, itemPerPage, currentPage, campaignProductStatusChangeData])

  useEffect(() => {
    let tempProductIdString = ''
    if (selectedManageCampaignProducts.length > 0) {
      selectedManageCampaignProducts.map(data => {
        tempProductIdString = tempProductIdString + data?.productId + ','
      })
      setSelectedCampaignProductIds(tempProductIdString.substring(0, tempProductIdString.length - 1))
    } else {
      setSelectedCampaignProductIds('')
    }
  }, [selectedManageCampaignProducts])

  const ApproveProductListHandler = () => {
    const data = {
      selectedCampaignId: selectedCampaignId,
      selectedCampaignProductIds: selectedCampaignProductIds,
      isActive: 'Y',
      approveSellerRequest: 'Y'
    }
    dispatch(addOrEditCampaignProductListRecord(data))
  }

  useEffect(() => {
    if (campaignProductListData.data) {
      if (campaignProductListData && campaignProductListData?.data?.succeed === true) {
        toast.success('Product Added to Campaign Successfully')
        setSelectedManageCampaignProducts([])
        dispatch(addOrEditCampaignProductListReset())
        setTimeout(() => {
          history.push(`/Manage-Campaign-ProductsList?campId=${selectedCampaignId}&campStatus=pending`)
        }, 2500)
      } else if (campaignProductListData && campaignProductListData?.data?.message === "Save Success") {
        toast.success('Product Added to Campaign Successfully')
        setSelectedManageCampaignProducts([])
        dispatch(addOrEditCampaignProductListReset())
        setTimeout(() => {
          history.push(`/Manage-Campaign-ProductsList?campId=${selectedCampaignId}&campStatus=pending`)
        }, 2500)
      } else if (campaignProductListData && campaignProductListData?.data?.succeed === false) {
        toast.error('Something went wrong, Please try again')
        setSelectedManageCampaignProducts([])
        dispatch(addOrEditCampaignProductListReset())
        setTimeout(() => {
          history.push('/CampaignList?status=active')
        }, 2500)
      }
    }
  }, [campaignProductListData.data])

  const handleStatusChange = (data) => {
    const dataStatus = {
      productId: data.productId,
      campaignId: selectedCampaignId,
      targetStatus: data.isActive === 'Y' ? 'N' : 'Y'
    }
    dispatch(campaignProductStatusChangeRecord(dataStatus))
  }

  useEffect(() => {
    if (campaignProductStatusChangeData.data) {
      if (campaignProductStatusChangeData && campaignProductStatusChangeData?.data?.succeed === true) {
        toast.info('Product Status Changed Successfully')
        dispatch(campaignProductStatusChangeReset())
      } else if (campaignProductStatusChangeData && campaignProductStatusChangeData?.data?.message === "Data updated Successfully") {
        toast.info('Product Status Changed Successfully')
        dispatch(campaignProductStatusChangeReset())
      } else if (campaignProductStatusChangeData && campaignProductStatusChangeData?.data?.succeed === false) {
        toast.error('Something went wrong, Please try again')
        dispatch(campaignProductStatusChangeReset())
      }
    }
  }, [campaignProductStatusChangeData.data])

  return (
    <>
      <ToastContainer autoClose={2000} />
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="white-box">
                <div className="datatable-doc-demo datatable-responsive-demo">
                  <div className="card">
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}>
                      <ul className="nav nav-tabs seller-tabs">
                        <li
                          onClick={() => {
                            setIsApproved("N");
                            setCurrentPage(1);
                            setItemPerPage(20);
                          }}
                        >
                          <Link to={authenticationService.getRoleId() == 1 ? `/Manage-Campaign-ProductsList?campId=${selectedCampaignId}&campStatus=pending` : `/Manage-Seller-Campaign-ProductsList?campId=${selectedCampaignId}&campStatus=pending`}
                            style={
                              selectedCampaignStatus === "pending"
                                ? { color: "#1F5DA0", borderBottom: '2px solid #1F5DA0', fontWeight: "700" }
                                : null
                            }>
                            Pending Product
                          </Link>
                        </li>
                        <li
                          onClick={() => {
                            setIsApproved("Y");
                            setCurrentPage(1);
                            setItemPerPage(20);
                          }}
                        >
                          <Link to={authenticationService.getRoleId() == 1 ? `/Manage-Campaign-ProductsList?campId=${selectedCampaignId}&campStatus=approved` : `/Manage-Seller-Campaign-ProductsList?campId=${selectedCampaignId}&campStatus=approved`}
                            style={
                              selectedCampaignStatus === "approved"
                                ? { color: "#1F5DA0", borderBottom: '2px solid #1F5DA0', fontWeight: "700" }
                                : null
                            }>
                            Approved Product
                          </Link>
                        </li>
                      </ul>
                      <div>
                        <Link to={authenticationService.getRoleId() == 1 ? "/CampaignList?status=active" : "/SellerCampaignList?status=active"}>
                          <div className="button-demo">
                            <Button
                              icon="pi pi-times"
                              className="p-button-rounded p-button-danger p-button-outlined"
                            />
                          </div>
                        </Link>
                      </div>

                    </div>

                    {
                      <CampaignManageProductListTable
                        product_list={getStatusWiseCampaignProductsListData}
                        handleItemPerPage={handleItemPerPage}
                        handleCurrentPage={handleCurrentPage}
                        currentPage={currentPage}
                        totalPage={totalPage}
                        setTotalPage={setTotalPage}
                        itemPerPage={itemPerPage}
                        totalItems={totalItems}
                        setTotalItems={setTotalItems}
                        
                        setSearchKeyword={setSearchKeyword}
                        searchKeyword={searchKeyword}
                        categories={categories}
                        setCategory={setCategory}
                        category={category}
                        brands={brands}
                        setBrand={setBrand}
                        brand={brand}
                        vendor={vendor}
                        setVendor={setVendor}
                        setIsFilter={setIsFilter}
                        setCurrentPage={setCurrentPage}

                        loading={loaderCall}
                        selectedManageCampaignProducts={selectedManageCampaignProducts}
                        setSelectedManageCampaignProducts={setSelectedManageCampaignProducts}
                        ApproveProductListHandler={ApproveProductListHandler}
                        isPending={isPending}
                        isAdmin={false}
                        handleStatusChange={handleStatusChange}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ManageSellerCampaignProductsContainer