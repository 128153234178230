/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import { RiPrinterLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import XLSX from "xlsx";
import TransactionFilterProperties from "../../components/report/transactionFilterProperties";
import SaRaLoader from "../../components/shared/SaRaLoader/SaRaLoader";
import { getExcelAccountsTransactionRecord } from "../../store/actions/accountsAction";
import authenticationService from "../../store/services/authenticationService";
import toIsoString from "../../utils/Functions/toIsoString";

const TransactionHistoryContainer = () => {
  const [apiData, setApiData] = useState(null);
  const [sku, setSku] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [paymentMethodNames, setPaymentMethodNames] = useState(null);
  const [paymentMethodIds, setPaymentMethodIds] = useState(0);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [orderSource, setOrderSource] = useState(null);
  const [orderStatus, setOrderStatus] = useState(null);
  const [shopNameIds, setShopNameIds] = useState(0);
  const [disable, setDisable] = useState(true);
  const [clickStatus, setClickStatus] = useState(false);

  const dispatch = useDispatch();

  let history = useHistory();
  useEffect(() => {
    let roleId = authenticationService?.getRoleId();
    if (roleId === "1") {
    } else {
      history.push("/Login");
    }
  }, [history]);

  let location = useHistory();
  const order_filter_tab = location?.location?.search?.substring(6);

  const { accountsTransactionReportData, loaded, loading } = useSelector(
    (state) => state?.accountsReducer
  );

  const reportReset = () => {
    setStartDate(null);
    setEndDate(null);
    setPaymentStatus(null);
    setPaymentMethod(null);
    setPaymentMethodNames(null);
    setApiData(null);
  };

  useEffect(() => {
    setApiData(null);
    setClickStatus(false);
  }, [
    startDate,
    endDate,
    paymentStatus,
    paymentMethod,
    paymentMethodNames,
  ]);

  // Report generate button call - start
  const reportGenerate = () => {
    if (startDate === null || endDate === null) {
      toast.error("Both Order Start and End Date Required!!!");
      return;
    }

    dispatch(
      getExcelAccountsTransactionRecord(
        startDate ? toIsoString(startDate).substring(0, 10) : "",
        endDate ? toIsoString(endDate).substring(0, 10) : "",
        paymentStatus?.PaymentStatusName
          ? paymentStatus?.PaymentStatusName
          : "",
        paymentMethodIds ? paymentMethodIds : "",
        shopNameIds ? shopNameIds : ""
      )
    );
  };
  // Report generate button call - end

  const handleChange = (e) => {
    setClickStatus(false);
    if (e.target.name === "sku") {
      setSku(e.target.value);
    }
    if (e.target.name === "paymentMethodName") {
      setPaymentMethod(e.value);
    }
    if (e.target.name === "paymentStatusName") {
      setPaymentStatus(e.value);
    }
    if (e.target.name === "orderSourceName") {
      setOrderSource(e.value);
    }
    if (e.target.name === "orderStatusName") {
      setOrderStatus(e.value);
    }
  };

  const handleDateChange = (value, type) => {
    setClickStatus(false);
    if (type === "start") {
      setStartDate(value);
    } else if (type === "end") {
      setEndDate(value);
    }
  };

  useEffect(() => {
    if (startDate !== null && endDate !== null) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (order_filter_tab === "transaction_history") {
      setApiData(accountsTransactionReportData?.data);
    }
  }, [accountsTransactionReportData, order_filter_tab]);

  // Get Data and Format for Order Report for Excel Or CSV - Start
  const formatDataForOrderReportExcelOrCSV = (dataFromAPI) => {
    let formattedData = [];
    dataFromAPI &&
      dataFromAPI.map((data, i) => {
        let singleData = {
          "Vendor Name": data?.shopName ? data?.shopName.toString() : "-",
          "Order Date": data?.orderDate
            ? data?.orderDate.toString().substring(0, 10)
            : "-",
          "Order No": data?.orderNo ? data?.orderNo.toString() : "-",
          "Product Title": data?.productTitle
            ? data?.productTitle.toString()
            : "-",
          "Seller Product SKU": data?.sellerProductSku
            ? data?.sellerProductSku.toString()
            : "-",
          Variants: data?.productVariant
            ? data?.productVariant.toString()
            : "-",
          "Unit Price": data?.productPrice ? parseFloat(data?.productPrice) : 0,
          Qty: data?.productQuantity ? parseInt(data?.productQuantity) : 0,
          "Subtotal Amount":
            data?.productPrice && data?.productQuantity
              ? parseFloat(parseInt(data?.productQuantity) * data?.productPrice)
              : 0,
          "Discount (%)": data?.discountPercent ? data?.discountPercent : 0,
          "Discount Amount": data?.discountAmount ? data?.discountAmount : 0,

          "Voucher Code": data?.voucherCode
            ? data?.voucherCode.toString()
            : "-",
          "Voucher (%)": data?.voucherPercentage
            ? parseFloat(data?.voucherPercentage)
            : 0,
          "Voucher Amount": data?.voucherAmount
            ? parseFloat(data?.voucherAmount)
            : 0,
          "Net Amount": data?.netAmount ? parseFloat(data?.netAmount) : 0,
          "Commission (%)": data?.commissionPercentage
            ? data?.commissionPercentage
            : 0,
          "Commission Amount": data?.commissionAmount
            ? data?.commissionAmount
            : 0,
          Burn: data?.burnAmount ? data?.burnAmount : 0,
          "Vendor Payable Amount": data?.vendorPayableAmount
            ? data?.vendorPayableAmount
            : 0,
          "Shipping Charge": data?.totalShippingCharge
            ? data?.totalShippingCharge
            : 0,
          "Payment Method": data?.paymentMethodName
            ? data?.paymentMethodName.toString()
            : "-",
          "Payment Status": data?.paymentStatus
            ? data?.paymentStatus.toString()
            : "-",
          "Transaction No.": data?.tranNo ? data?.tranNo.toString() : "-",
          "Transaction Date": data?.paymentDate
            ? data?.paymentDate.toString().substring(0, 10)
            : "-",
        };
        formattedData.push(Object.assign({}, singleData));
      });
    return formattedData;
  };
  // Get Data and Format for Order Report for Excel Or CSV - End

  // File Name format - start
  const formatFileName = () => {
    let optionalName = "";
    if (sku) {
      optionalName = `-by-${sku}`;
    } else if (paymentMethod?.methodName) {
      optionalName = `-by-${paymentMethod?.methodName}`;
    } else if (paymentStatus?.PaymentStatusName) {
      optionalName = `-by-${paymentStatus?.PaymentStatusName}`;
    } else if (orderSource?.OrderSource) {
      optionalName = `-by-${orderSource?.OrderSource}`;
    } else if (orderStatus?.OrderStatusName) {
      optionalName = `-by-${orderStatus?.OrderStatusName}`;
    }

    let formattedName = `from-${toIsoString(startDate).substring(
      0,
      10
    )}-to-${toIsoString(endDate).substring(0, 10)}`;

    return formattedName;
  };
  // File Name format - end

  useEffect(() => {
    if (apiData !== null && apiData !== undefined) {
      if (apiData?.length === 0 && clickStatus) {
        toast.error("Sorry, No Data Found!!");
        return;
      }
      // Format file name according to properties
      const name = formatFileName();
      if (
        order_filter_tab === "transaction_history" &&
        apiData?.length !== 0 &&
        loaded &&
        clickStatus
      ) {
        const data = formatDataForOrderReportExcelOrCSV(apiData);
        clickStatus &&
          apiData &&
          !loading &&
          loaded &&
          generateOrderReportExcelOrCSV(data, name);
        setClickStatus(false);
      }
    }
  }, [apiData]);

  // Generate Order Report for Excel Or CSV start
  const generateOrderReportExcelOrCSV = (sheetData, name) => {
    let work_book = XLSX.utils.book_new();
    let work_sheet = XLSX.utils.json_to_sheet(sheetData);

    XLSX.utils.book_append_sheet(work_book, work_sheet, "Sheet1");

    XLSX.writeFile(work_book, `Accounts Transaction Report-${name}.xlsx`);
  };
  // Generate Order Report for Excel Or CSV end

  useEffect(() => {
    var result = "";
    if (paymentMethodNames) {
      result = paymentMethodNames
        .map(function (item) {
          return item.value;
        })
        .join(",");
    }
    setPaymentMethodIds(result.toString());
  }, [paymentMethodNames]);

  const handleMultiPaymentMethodSelectChange = (paymentMethods) => {
    setPaymentMethodNames(paymentMethods);
  };

  return (
    <>
      <ToastContainer autoClose={2500} />
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="col-md-12">
            <div className="white-box white-box-title">
              <div className="datatable-row">
                <div className="report-head">
                  <span className="header-display">
                    <span>
                      <RiPrinterLine size="2rem" />
                    </span>
                    <span>
                      Report for{" "}
                      {order_filter_tab === "transaction_history"
                        ? "Transaction History"
                        : ""}{" "}
                    </span>
                  </span>
                </div>
                <div className="option-body">
                  <div className="option-lebel">
                    Choose{" "}
                    {order_filter_tab === "transaction_history"
                      ? "Transaction"
                      : ""}{" "}
                    Option
                  </div>
                  <div className="option-body-element">
                    <div style={{ minWidth: "50%", marginLeft: "25%" }}>
                      {/* Report Filter Properties */}
                      <TransactionFilterProperties
                        handleChange={handleChange}
                        sku={sku}
                        orderStatus={orderStatus}
                        orderSource={orderSource}
                        paymentStatus={paymentStatus}
                        paymentMethod={paymentMethod}
                        startDate={startDate}
                        endDate={endDate}
                        handleDateChange={handleDateChange}
                        order_filter_tab={order_filter_tab}
                        setClickStatus={setClickStatus}
                        handleMultiPaymentMethodSelectChange={
                          handleMultiPaymentMethodSelectChange
                        }
                        paymentMethodNames={paymentMethodNames}
                        setShopNameIds={setShopNameIds}
                      />

                      {/* Report Generator Button */}
                      <div className="button-container">
                        <Button
                          disabled={disable}
                          className="generate-btn"
                          onClick={() => {
                            reportGenerate();
                            setClickStatus(true);
                          }}
                        >
                          Generate Report
                        </Button>
                        <Button
                          className="reset-btn"
                          onClick={() => {
                            reportReset();
                            setClickStatus(false);
                          }}
                        >
                          Reset
                        </Button>
                      </div>
                    </div>

                    <div style={{ width: "50%" }}></div>

                    {loading && <SaRaLoader />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransactionHistoryContainer;
